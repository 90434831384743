import { render, staticRenderFns } from "./NotificationsNew.vue?vue&type=template&id=f8a7fbec&"
import script from "./NotificationsNew.vue?vue&type=script&lang=js&"
export * from "./NotificationsNew.vue?vue&type=script&lang=js&"
import style0 from "./NotificationsNew.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCheckbox,VDivider,VListItem,VSelect})
