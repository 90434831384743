<template>
    <div class="d-flex justify-space-between align-center">
        <span class="consignee-display text-ellipsis-overflow" v-if="isCustom">
            {{ getDataValue(data) }}
        </span>
        <span class="text-uppercase" v-else>
            {{ data.value }}
        </span>

        <button class="button-copy-container d-flex align-center" v-if="isShowButton"
            @click="copyToClipboard(data, field)">
            <img src="@/assets/icons/copy-clipboard.svg" id="i-icon-copy"
                width="18px" height="18px" alt="Copy" />
        </button>
    </div>
</template>

<script>
export default {
    name: "CommonTemplates",
    props: ["data", "field", "isCommon", "isCustom"],
    computed: {        
        isShowButton() {
            if (this.field === "Consignee") {
                if (this.getDataValue(this.data) === "N/A") {
                    return false
                }
            } else if (this.field === "Driver") {
                if (this.getDataValue(this.data) === "Unassigned") {
                    return false
                }
            }
            return true
        }
    },
    methods: {
        copyToClipboard(data, field) {
            this.$emit('copyFunction', data, field)
        },
        getDataValue(e) {
            if (e.value !== '' && e.text === '') {
                return e.value
            } else {
                if (e.text !== '') {
                    return e.text
                }
            }
        },
    }
}
</script>