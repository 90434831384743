<template>
    <div class="supplier-container customer-wrapper" v-resize="onResize">
		<!-- DESKTOP -->

        <ConsigneeDesktopTable
            :reloadcomponent = "reloadcomponent"
            v-if="renderComponent"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ConsigneeDesktopTable from '../components/Tables/Consignee/ConsigneeDetailsDesktopTable.vue'

export default {
    name: "Customers",
	components: {
        ConsigneeDesktopTable,
	},
    data: () => ({
        add:'New customer has been added',
        edit:'Customer successfully edited',
        msgcontent: '',
        renderComponent: true,
        tellparent:0,
        isdeleted:0,
        isaddedCustomer:0,
        notifyupdate:0,
        dialogEdit: false,
		page: 1,
        pageCount: 0,
        itemsPerPage: 35,
        dialog: false,
        dialogDelete: false,
        reloadcomponent: 0,
        editedIndex: -1,
        editedItem: {
            company_name: "",
            phone: "",
            address: "",
            emails: null,
            central_customer_key: ""
        },
        defaultItem: {
            company_name: "",
            phone: "",
            address: "",
            emails: null,
            central_customer_key: ""
        },
		search: '',
		isMobile: false
    }),
    computed: {
        ...mapGetters({
            //  getCustomers: 'customers/getCustomers',
            //  getCustomerdata: 'customers/getcustomerdetails',
             getCustomersLoading: 'customers/getCustomerLoading'
        }),
    },
    watch: {
        // code ...
    },
    created() {},
    methods: {
        ...mapActions({
            // fetchCustomers: 'customers/fetchCustomers',
            // fetchCustomersdetails: 'customers/fetchCustomerDetails'
        }),
        addCustomer() {
            this.dialog = true;
        },
		onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        setToDefault() {
            this.editedItem = this.defaultItem
            this.close()
            this.dialog = true
        }
    },
    async mounted() {
        this.$store.dispatch("page/setPage", "customers");

    }
};
</script>

<style lang="scss">
@import '../assets/scss/pages_scss/customers/customers.scss';
@import '../assets/scss/pages_scss/customers/customer2.scss';
@import '../assets/scss/buttons.scss';
@import '../assets/css/dialog_styles/dialogHeader.css';
@import '../assets/css/dialog_styles/dialogBody.css';
@import '../assets/css/dialog_styles/dialogFooter.css';
</style>
