<template>
  <div class="text-center">
    <v-dialog
      persistent
      v-model="dialogEdit"
      max-width="590px"
      content-class="add-supplier-dialog add-driver-dialog  dialogYard"
      :retain-focus="false"
    >
      <v-card class="add-supplier-card">
        <v-form
          id="createdriver"
          ref="form"
          v-model="valid"
          action="#"
          @submit.prevent=""
        >
          <v-card-title style="caret-color: transparent">
            <span class="headline">Update Yard </span>

            <button icon dark class="btn-close" @click="close">
              <v-icon>mdi-close</v-icon>
            </button>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-row>
              <v-col
                style="color: red"
                v-if="errorFound"
                cols="12"
                sm="12"
                md="12"
                class="pb-3 pt-1"
              >
                {{ errorMessage }}
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-3 pt-1">
                <label class="text-item-label">Name</label>
                <v-text-field
                  outlined
                  dense
                  :rules="inputRules"
                  v-model="editYardItemList.name"
                  class="DialogInputfield"
                  name="name"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="12" class="pt-0 pb-2" md="12">
                <label class="text-item-label">Address</label>
                <v-autocomplete
                  :loading="loadingLocationPlaces"
                  v-model="editYardItemList.map_location"
                  :search-input.sync="searchPlace"
                  :label="editYardItemList.map_location"
                  :items="places"
                  item-text="place_name"
                  :rules="inputRules"
                  no-filter
                  clearable
                  filled
                  @click:clear="clearSearchPlaces"
                  @change="changeDeliveryLocationState"
                  hide-no-data
                  class="custom-border"
                ></v-autocomplete>
                <!-- <label class="text-item-label">Address</label>
                <v-textarea
                  outlined
                  :rules="inputRules"
                  v-model="editYardItemList.address"
                  class="DialogInputfield"
                  name="address"
                  hide-details="auto"
                >
                </v-textarea> -->
              </v-col>
            </v-row>
          </v-card-text>

          <!-- <v-card-actions
            style="
              padding-left: 4px;
              padding-left: 11px;
              margin-top: 5px;
              border-top: 3px solid #ebf2f5;
            "
          >
            <v-col class="text-left buttonActions" style="padding-right: 0px">
              <v-btn class="btn-blue" text @click="save">
                <span class="btn-color">
                  <span color="#fff">
                    <span>
                      <v-progress-circular
                        :size="15"
                        color="#fff"
                        :width="2"
                        indeterminate
                        v-if="getUpdateYardsLoading"
                        style="margin-right: 3px"
                      >
                      </v-progress-circular>
                      Submit
                    </span>
                  </span>
                </span>
              </v-btn>

              <v-btn class="btn-white" text @click="close"> Cancel </v-btn>
            </v-col>
          </v-card-actions> -->
          <div class="text-right buttonActions" style="padding:0px">
              <div class="row ma-0">
                <div class="col-md-1 ml-3">
                  <v-btn class="btn-blue" text @click="save">
                    <span class="btn-color">
                      <span color="#fff">
                        <span>
                          <v-progress-circular
                            :size="15"
                            color="#fff"
                            :width="2"
                            indeterminate
                            v-if="getUpdateYardsLoading"
                            style="margin-right: 3px"
                          >
                          </v-progress-circular>
                          Submit
                        </span>
                      </span>
                    </span>
                  </v-btn>
                </div>
                <div class="col-md-1 ml-8">
                  <v-btn class="btn-white" text @click="close"> Cancel </v-btn>
                </div>
              </div>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["dialogEdit", "editYardItemList"],
  data: () => {
    return {
      mapLocation: "",
      searchPlace: "",
      places: [],
      loadingLocationPlaces: false,
      errorFound: 0,
      errorMessage: "",
      errorContent: "",
      errorSaving: 0,
      valid: true,
      callApiForDeliveryLoc: true,
      inputRules: [
        (v) => !!v || "This field is required",
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
    };
  },
  computed: {
    ...mapGetters({
      getUpdateYardsLoading: "yards/getUpdateYardsLoading",
      get_alert_box_text: "yards/get_alert_box_text",
      getdetails: "yards/getdetails",
    }),
  },
  watch: {
    dialogEdit(c) {
      if (!c || c) {
        this.errorFound = 0;
      }
    },
    getdetails(newValue) {
      if (newValue.status === 1) {
        this.$emit("close");
      }
    },
    searchPlace(val) {
      if (val && this.callApiForDeliveryLoc) {
        this.getDeliveryLocation(val);
      }
    },
  },
  methods: {
    ...mapActions("yards", ["editYardItem"]),
    close() {
      this.$emit("close");
    },
    async save() {
      if (this.searchPlace) {
        var selectedPlace = this.places.find(
          (element) => element.place_name === this.editYardItemList.map_location
        );
      }

      if (this.$refs.form.validate()) {
        const updatedYardItem = {};
        updatedYardItem["id"] = this.editYardItemList.id;
        updatedYardItem["name"] = this.editYardItemList.name;
        updatedYardItem["address"] = this.editYardItemList.map_location;
        updatedYardItem["map_location"] = this.editYardItemList.map_location;
        if (this.searchPlace) {
          if (selectedPlace.geometry) {
            var longitude = selectedPlace.geometry.coordinates[0];
            var latitude = selectedPlace.geometry.coordinates[1];
          } else {
            longitude = null;
            latitude = null;
          }
        } else {
          longitude = this.editYardItemList.longitude;
          latitude = this.editYardItemList.latitude;
        }
        updatedYardItem["longitude"] = longitude;
        updatedYardItem["latitude"] = latitude;
        await this.editYardItem(updatedYardItem);
        if (this.getdetails.status) {
          this.close();
          this.$emit("syncAction", {
            created: 0,
            msg: this.get_alert_box_text,
          });
        } else {
          this.errorFound = 1;
          this.errorMessage = this.getdetails.msg;
        }
      }
    },
    async getDeliveryLocation(val) {
      if (val.length >= 3) {
        this.loadingLocationPlaces = true;

        await fetch(
          `${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/` +
            val +
            `.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}&country=US`
        )
          .then((response) => response.json())
          .then((json) => {
            this.loadingLocationPlaces = false;
            // const customInput = [
            //   {
            //     place_name: val,
            //     id: Date.now(),
            //   },
            // ];

            this.places = [...json.features];
            // this.places = [...json.features, ...customInput];
          })
          .catch((e) => {
            this.loadingLocationPlaces = false;
            // const customInput = [
            //   {
            //     place_name: val,
            //     id: Date.now(),
            //   },
            // ];

            // this.places = customInput;
            console.log(e);
          });
      }
    },
    changeDeliveryLocationState() {
      this.callApiForDeliveryLoc = !this.callApiForDeliveryLoc;
    },
    clearSearchPlaces() {
      this.places = [];
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #b4cfe0;
  border-width: 1px;
}
</style>
