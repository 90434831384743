<template>
  <div class="py-5 px-5" style="background-color: white">
    <p class="sub-heading">Tracking Notification</p>

    <!-- ****************************  Container Available ************************* -->

    <div class="item-main mt-4">
      <div class="item">
        <div>
          <p class="item-heading">Container Available</p>
        </div>
        <div class="item-right-section">
          <v-checkbox
            class="notify-type mr-3"
            label="Email"
            v-model="getCompanyProfile.email_container_available"
            @click="
              showEmaiList(
                'container_available',
                getCompanyProfile.email_container_available
              )
            "
          ></v-checkbox>
          <div class="vertical-separtor" />

          <v-checkbox
            class="ml-3 notify-type"
            :input-value="
              getCompanyProfile.notifi_container_available !== 'OFF'
            "
            label="Push Notification"
            @click="containerAvailableMenu()"
          />
          <v-select
            ref="containerAvai"
            class="item-notif-settings"
            v-model="containerAvailableNotifi"
            :items="stateData.containerAvailable"
            :class="
              getCompanyProfile.notifi_container_available !== 'OFF'
                ? 'active'
                : ''
            "
            :menu-props="{ contentClass: 'notifi-menu-list' }"
            append-icon="ic-chevron-down"
            @click="setContainerAvailableTime"
            @change="updateContainerAvailble"
          >
            <template v-slot:item="{ item, on, attrs }">
              <v-list-item
                class="d-flex justify-start"
                style="border: none; min-height: 45px; height: 45px"
                v-on="on"
                v-bind="attrs"
              >
                <div
                  v-if="item == 'Custom Time'"
                  @click="openCustomTimeDialog('container_available')"
                >
                  <p class="name font-regular" style="color: #4a4a4a">
                    {{ item }}
                  </p>
                </div>
                <div v-else>
                  <p class="name font-regular" style="color: #4a4a4a">
                    {{ item }}
                  </p>
                </div>
              </v-list-item>
            </template>
          </v-select>
        </div>
      </div>
      <div
        class="item-description"
        v-if="getCompanyProfile.email_container_available"
      >
        <p class="email-text">Send Email to:</p>
        <div
          class="email-descp"
          v-for="(data, index) in JSON.parse(
            getCompanyProfile.recip_container_available
          )"
          :key="index"
        >
          <p>{{ data }}</p>
        </div>
        <button
          @click="
            showDailog(
              getCompanyProfile.recip_container_available,
              'Container Available'
            )
          "
        >
          <p>Edit</p>
        </button>
      </div>
    </div>

    <!-- ****************************  Container Discharged ************************* -->

    <div class="item-main mt-4">
      <div class="item">
        <div>
          <p class="item-heading">Container Discharged</p>
        </div>
        <div class="item-right-section">
          <v-checkbox
            class="notify-type mr-3"
            v-model="getCompanyProfile.email_container_discharged"
            label="Email"
            @click="
              showEmaiList(
                'container_discharged',
                getCompanyProfile.email_container_discharged
              )
            "
          ></v-checkbox>
          <div class="vertical-separtor" />

          <v-checkbox
            class="ml-3 notify-type"
            :input-value="
              getCompanyProfile.notifi_container_discharged !== 'OFF'
            "
            label="Push Notification"
            @click="containerDischargeMenu()"
          />
          <v-select
            ref="contDischargeSelect"
            class="item-notif-settings"
            v-model="containerDischargedNotifi"
            :items="stateData.containerDischarged"
            :class="
              getCompanyProfile.notifi_container_discharged !== 'OFF'
                ? 'active'
                : ''
            "
            :menu-props="{ contentClass: 'notifi-menu-list' }"
            append-icon="ic-chevron-down"
            @click="setdischargeContainerTime()"
            @change="updateDischargeContainer"
          >
            <template v-slot:item="{ item, on, attrs }">
              <v-list-item
                class="d-flex justify-start"
                style="border: none; min-height: 45px; height: 45px"
                v-on="on"
                v-bind="attrs"
              >
                <div
                  v-if="item == 'Custom Time'"
                  @click="openCustomTimeDialog('container_discharged')"
                >
                  <p class="name font-regular" style="color: #4a4a4a">
                    {{ item }}
                  </p>
                </div>
                <div v-else>
                  <p class="name font-regular" style="color: #4a4a4a">
                    {{ item }}
                  </p>
                </div>
              </v-list-item>
            </template>
          </v-select>

          <!-- old -->
        </div>
      </div>
      <div
        class="item-description"
        v-if="getCompanyProfile.email_container_discharged"
      >
        <p class="email-text">Send Email to:</p>
        <div
          class="email-descp"
          v-for="(data, index) in JSON.parse(
            getCompanyProfile.recip_container_discharged
          )"
          :key="index"
        >
          <p>{{ data }}</p>
        </div>
        <button
          @click="
            showDailog(
              getCompanyProfile.recip_container_discharged,
              'Container Discharged'
            )
          "
        >
          <p>Edit</p>
        </button>
      </div>
    </div>

    <!-- ***************************** LFD Issued  ******************************* -->

    <div class="item-main mt-4">
      <div class="item">
        <div>
          <p class="item-heading">LFD Issued</p>
        </div>
        <div class="item-right-section">
          <v-checkbox
            class="notify-type mr-3"
            v-model="getCompanyProfile.email_lfd_issued"
            @click="
              showEmaiList('LFD_issued', getCompanyProfile.email_lfd_issued)
            "
            label="Email"
          ></v-checkbox>
          <div class="vertical-separtor" />

          <v-checkbox
            class="ml-3 notify-type"
            :input-value="getCompanyProfile.notifi_lfd_issued !== 'OFF'"
            label="Push Notification"
            @click="LFDIssuedMenu()"
          />
          <v-select
            ref="LFDIssuedSelect"
            class="item-notif-settings"
            v-model="LFDIssuedNotifi"
            :items="stateData.LFDIssued"
            :class="
              getCompanyProfile.notifi_lfd_issued !== 'OFF' ? 'active' : ''
            "
            :menu-props="{ contentClass: 'notifi-menu-list' }"
            append-icon="ic-chevron-down"
            @click="setLFDIssuedTime()"
            @change="updateLFDIssued"
          >
            <template v-slot:item="{ item, on, attrs }">
              <v-list-item
                class="d-flex justify-start"
                style="border: none; min-height: 45px; height: 45px"
                v-on="on"
                v-bind="attrs"
              >
                <div
                  v-if="item == 'Custom Time'"
                  @click="openCustomTimeDialog('lfd_issued')"
                >
                  <p class="name font-regular" style="color: #4a4a4a">
                    {{ item }}
                  </p>
                </div>
                <div v-else>
                  <p class="name font-regular" style="color: #4a4a4a">
                    {{ item }}
                  </p>
                </div>
              </v-list-item>
            </template>
          </v-select>

          <!-- old -->
        </div>
      </div>
      <div class="item-description" v-if="getCompanyProfile.email_lfd_issued">
        <p class="email-text">Send Email to:</p>
        <div
          class="email-descp"
          v-for="(data, index) in JSON.parse(
            getCompanyProfile.recip_lfd_issued
          )"
          :key="index"
        >
          <p>{{ data }}</p>
        </div>
        <button
          @click="showDailog(getCompanyProfile.recip_lfd_issued, 'LFD Issued')"
        >
          <p>Edit</p>
        </button>
      </div>
    </div>

    <!-- ****************************** ETA Changed Alert  ************************* -->

    <div class="item-main mt-4">
      <div class="item">
        <div>
          <p class="item-heading">ETA Changed Alert</p>
        </div>
        <div class="item-right-section">
          <v-checkbox
            class="notify-type mr-3"
            label="Email"
            v-model="getCompanyProfile.email_eta_changed_alert"
            @click="
              showEmaiList(
                'eta_changed_alert',
                getCompanyProfile.email_eta_changed_alert
              )
            "
          ></v-checkbox>
          <div class="vertical-separtor" />

          <v-checkbox
            class="ml-3 notify-type"
            :input-value="getCompanyProfile.notifi_eta_changed_alert !== 'OFF'"
            label="Push Notification"
            @click="ETAChangeMenu()"
          />
          <v-select
            ref="etaSelect"
            class="item-notif-settings"
            v-model="ETAChangeAlertNotifi"
            :items="stateData.ETAChangeAlert"
            :class="
              getCompanyProfile.notifi_eta_changed_alert !== 'OFF'
                ? 'active'
                : ''
            "
            :menu-props="{ contentClass: 'notifi-menu-list' }"
            append-icon="ic-chevron-down"
            @click="setETAChangeAlertTime()"
            @change="updateETAChange"
          >
            <template v-slot:item="{ item, on, attrs }">
              <v-list-item
                class="d-flex justify-start"
                style="border: none; min-height: 45px; height: 45px"
                v-on="on"
                v-bind="attrs"
              >
                <div
                  v-if="item == 'Custom Time'"
                  @click="openCustomTimeDialog('eta_changed_alert')"
                >
                  <p class="name font-regular" style="color: #4a4a4a">
                    {{ item }}
                  </p>
                </div>
                <div v-else>
                  <p class="name font-regular" style="color: #4a4a4a">
                    {{ item }}
                  </p>
                </div>
              </v-list-item>
            </template>
          </v-select>
        </div>
      </div>
      <div
        class="item-description"
        v-if="getCompanyProfile.email_eta_changed_alert"
      >
        <p class="email-text">Send Email to:</p>
        <div
          class="email-descp"
          v-for="(data, index) in JSON.parse(
            getCompanyProfile.recip_eta_changed_alert
          )"
          :key="index"
        >
          <p>{{ data }}</p>
        </div>
        <button
          @click="
            showDailog(
              getCompanyProfile.recip_eta_changed_alert,
              'ETA Changed Alert'
            )
          "
        >
          <p>Edit</p>
        </button>
      </div>
    </div>

    <!-- ******************************* LFDReminder      ********************** -->

    <div class="item-main mt-4">
      <div class="item">
        <div>
          <p class="item-heading">LFD Reminder</p>
        </div>
        <div class="item-right-section">
          <v-checkbox
            class="notify-type mr-3"
            label="Email"
            v-model="getCompanyProfile.email_lfd_reminder"
            @click="
              showEmaiList('LFD_reminder', getCompanyProfile.email_lfd_reminder)
            "
          ></v-checkbox>
          <div class="vertical-separtor" />

          <v-checkbox
            class="ml-3 notify-type"
            :input-value="getCompanyProfile.notifi_lfd_reminder !== 'OFF'"
            label="Push Notification"
            @click="LFDReminderMenu()"
          />
          <!-- @click="LFDReminderMenu()" -->
          <v-select
            ref="LFDReminderSelect"
            class="item-notif-settings"
            v-model="LFDReminderNotifi"
            :items="stateData.LFDReminder"
            :class="
              getCompanyProfile.notifi_lfd_reminder !== 'OFF' ? 'active' : ''
            "
            :menu-props="{ contentClass: 'notifi-menu-list' }"
            append-icon="ic-chevron-down"
            @click="setLFDReminderTime"
            @change="updateLFDReminder"
          >
            <template v-slot:item="{ item, on, attrs }">
              <v-list-item
                class="d-flex justify-start"
                style="border: none; min-height: 45px; height: 45px"
                v-on="on"
                v-bind="attrs"
              >
                <div
                  v-if="item == 'Custom Time'"
                  @click="openCustomTimeDialog('lfd_reminder')"
                >
                  <p class="name font-regular" style="color: #4a4a4a">
                    {{ item }}
                  </p>
                </div>
                <div v-else>
                  <p class="name font-regular" style="color: #4a4a4a">
                    {{ item }}
                  </p>
                </div>
              </v-list-item>
            </template>
          </v-select>
          <!-- old -->
        </div>
      </div>
      <div class="item-description" v-if="getCompanyProfile.email_lfd_reminder">
        <p class="email-text">Send Email to:</p>
        <div
          class="email-descp"
          v-for="(data, index) in JSON.parse(
            getCompanyProfile.recip_lfd_reminder
          )"
          :key="index"
        >
          <p>{{ data }}</p>
        </div>
        <button
          @click="
            showDailog(getCompanyProfile.recip_lfd_reminder, 'LFD Reminder')
          "
        >
          <p>Edit</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { stateData } from "./customStaticData";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      stateData,
      showContainerEmail: false,
      showContDischargeEmail: false,
      showLFDIssuedEmail: false,
      showETAEmail: false,
      showLFDReminderEmail: false,
    };
  },

  computed: {
    ...mapGetters("profile", ["getCompanyProfile"]),
    ...mapGetters("notifications", ["getBoxOpen"]),
    containerAvailableNotifi: {
      get() {
        if (this.getCompanyProfile.notifi_container_available == "OFF") {
          return "Push Notification Turned Off";
        } else if (this.getCompanyProfile.notifi_container_available == "AA") {
          return "Active (Anytime)";
        } else if (this.getCompanyProfile.notifi_container_available == "WH") {
          return "Working Hours (8AM-5PM)";
        } else {
          return "Custom Time";
        }
      },
      set(value) {
        return value;
      },
    },
    containerDischargedNotifi: {
      get() {
        if (this.getCompanyProfile.notifi_container_discharged == "OFF") {
          return "Push Notification Turned Off";
        } else if (this.getCompanyProfile.notifi_container_discharged == "AA") {
          return "Active (Anytime)";
        } else if (this.getCompanyProfile.notifi_container_discharged == "WH") {
          return "Working Hours (8AM-5PM)";
        } else {
          return "Custom Time";
        }
      },
      set(value) {
        return value;
      },
    },
    LFDIssuedNotifi: {
      get() {
        if (this.getCompanyProfile.notifi_lfd_issued == "OFF") {
          return "Push Notification Turned Off";
        } else if (this.getCompanyProfile.notifi_lfd_issued == "AA") {
          return "Active (Anytime)";
        } else if (this.getCompanyProfile.notifi_lfd_issued == "WH") {
          return "Working Hours (8AM-5PM)";
        } else {
          return "Custom Time";
        }
      },
      set(value) {
        return value;
      },
    },

    ETAChangeAlertNotifi: {
      get() {
        if (this.getCompanyProfile.notifi_eta_changed_alert == "OFF") {
          return "Push Notification Turned Off";
        } else if (this.getCompanyProfile.notifi_eta_changed_alert == "AA") {
          return "Active (Anytime)";
        } else if (this.getCompanyProfile.notifi_eta_changed_alert == "WH") {
          return "Working Hours (8AM-5PM)";
        } else {
          return "Custom Time";
        }
      },
      set(value) {
        return value;
      },
    },
    LFDReminderNotifi: {
      get() {
        if (this.getCompanyProfile.notifi_lfd_reminder == "OFF") {
          return "Push Notification Turned Off";
        } else if (this.getCompanyProfile.notifi_lfd_reminder == "AA") {
          return "Active (Anytime)";
        } else if (this.getCompanyProfile.notifi_lfd_reminder == "WH") {
          return "Working Hours (8AM-5PM)";
        } else {
          return "Custom Time";
        }
      },
      set(value) {
        return value;
      },
    },
  },

  methods: {
    ...mapActions("notifications", [
      "updateNotificationTime",
      "updateEmailSettings",
    ]),
    ...mapMutations("notifications", [
      "SET_CURRENT_EDIT_ITEM",
      "SET_CUSTOM_TIME_DAILOG",
      "CURRENT_EMAIL_RECPIENTS",
      "SET_EMAIL_DAILOG",
      "SET_BOX_OPEN",
    ]),
    showEmaiList(type, status) {
      this.$emit("showEmaiList", type, status);
    },

    showDailog(emails, item) {
      this.SET_CURRENT_EDIT_ITEM(item);
      this.SET_EMAIL_DAILOG(true);
      this.CURRENT_EMAIL_RECPIENTS(emails);
    },

    openCustomTimeDialog(notifi) {
      switch (notifi) {
        case "container_available":
          if (this.getCompanyProfile.notifi_container_available == "CT") {
            this.SET_CURRENT_EDIT_ITEM("Container Available");
            this.SET_CUSTOM_TIME_DAILOG(true);
          }
          break;
        case "container_discharged":
          if (this.getCompanyProfile.notifi_container_discharged == "CT") {
            this.SET_CURRENT_EDIT_ITEM("Container Discharged");
            this.SET_CUSTOM_TIME_DAILOG(true);
          }
          break;
        case "lfd_issued":
          if (this.getCompanyProfile.notifi_lfd_issued == "CT") {
            this.SET_CURRENT_EDIT_ITEM("LFD Issued");
            this.SET_CUSTOM_TIME_DAILOG(true);
          }
          break;
        case "eta_changed_alert":
          if (this.getCompanyProfile.notifi_eta_changed_alert == "CT") {
            this.SET_CURRENT_EDIT_ITEM("ETA Changed Alert");
            this.SET_CUSTOM_TIME_DAILOG(true);
          }
          break;
        default:
          if (this.getCompanyProfile.notifi_lfd_reminder == "CT") {
            this.SET_CURRENT_EDIT_ITEM("LFD Reminder");
            this.SET_CUSTOM_TIME_DAILOG(true);
          }
          break;
      }
    },

    generalClose() {
      if (!this.getBoxOpen.ref) return;
      const menuComponent = this.$refs[this.getBoxOpen.ref];
      menuComponent.blur();
      setTimeout(() => {
        if (this.stateData[this.getBoxOpen.state].length == 3) {
          const newItem = "Push Notification Turned Off";
          this.stateData[this.getBoxOpen.state].unshift(newItem);
        }
        let payload = {
          show: false,
          ref: null,
          state: null,
          property: null,
        };
        this.SET_BOX_OPEN(payload);
      }, 150);
    },

    containerAvailableMenu() {
      const menuComponent = this.$refs.containerAvai;
      if (!this.getBoxOpen.show) {
        let payload = {
          show: true,
          ref: "containerAvai",
          state: "containerAvailable",
          property: "notifi_container_available",
        };
        this.SET_BOX_OPEN(payload);

        if (this.containerAvailableNotifi !== "Push Notification Turned Off") {
          menuComponent.blur();
          if (this.stateData.containerAvailable.length == 3) {
            const newItem = "Push Notification Turned Off";
            this.stateData.containerAvailable.unshift(newItem);
          }
          this.updateNotification("Container Available", "OFF");
          return;
        }
        if (this.containerAvailableNotifi == "Push Notification Turned Off") {
          if (this.stateData.containerAvailable.length == 4) {
            this.stateData.containerAvailable.shift();
          }
          menuComponent.activateMenu();
        }
        return;
      }
      this.generalClose();
    },

    setContainerAvailableTime() {
      // console.log(e.target.innerText);
      // console.log(e.target);
      const menuComponent = this.$refs.containerAvai;

      if (
        this.stateData.containerAvailable.length == 4 &&
        this.getCompanyProfile.notifi_container_available !== "OFF"
      ) {
        this.stateData.containerAvailable.shift();
      }
      if (this.getCompanyProfile.notifi_container_available == "OFF") {
        menuComponent.blur();
        return;
      }
    },
    updateContainerAvailble(selectedValue) {
      if (selectedValue == "Custom Time") {
        this.SET_CURRENT_EDIT_ITEM("Container Available");
        this.SET_CUSTOM_TIME_DAILOG(true);
        return;
      }
      this.updateNotification("Container Available", selectedValue);
    },
    containerDischargeMenu() {
      const menuComponent = this.$refs.contDischargeSelect;
      if (!this.getBoxOpen.show) {
        let payload = {
          show: true,
          ref: "contDischargeSelect",
          state: "containerDischarged",
          property: "notifi_container_discharged",
        };
        this.SET_BOX_OPEN(payload);
        if (this.containerDischargedNotifi !== "Push Notification Turned Off") {
          menuComponent.blur();
          if (this.stateData.containerDischarged.length == 3) {
            const newItem = "Push Notification Turned Off";
            this.stateData.containerDischarged.unshift(newItem);
          }
          this.updateNotification("Container Discharged", "OFF");
          return;
        }
        if (this.containerDischargedNotifi == "Push Notification Turned Off") {
          if (this.stateData.containerDischarged.length == 4) {
            this.stateData.containerDischarged.shift();
          }
          menuComponent.activateMenu();
        }
        return;
      }
      this.generalClose();
    },
    setdischargeContainerTime() {
      const menuComponent = this.$refs.contDischargeSelect;

      if (
        this.stateData.containerDischarged.length == 4 &&
        this.getCompanyProfile.notifi_container_discharged !== "OFF"
      ) {
        this.stateData.containerDischarged.shift();
      }
      if (this.getCompanyProfile.notifi_container_discharged == "OFF") {
        menuComponent.blur();
        return;
      }
    },
    updateDischargeContainer(selectedValue) {
      if (selectedValue == "Custom Time") {
        this.SET_CURRENT_EDIT_ITEM("Container Discharged");
        this.SET_CUSTOM_TIME_DAILOG(true);
        return;
      }
      this.updateNotification("Container Discharged", selectedValue);
    },
    LFDIssuedMenu() {
      const menuComponent = this.$refs.LFDIssuedSelect;
      if (!this.getBoxOpen.show) {
        let payload = {
          show: true,
          ref: "LFDIssuedSelect",
          state: "LFDIssued",
          property: "notifi_lfd_issued",
        };
        this.SET_BOX_OPEN(payload);
        if (this.LFDIssuedNotifi !== "Push Notification Turned Off") {
          if (this.stateData.LFDIssued.length == 3) {
            const newItem = "Push Notification Turned Off";
            this.stateData.LFDIssued.unshift(newItem);
          }
          menuComponent.blur();
          this.updateNotification("LFD Issued", "OFF");
          return;
        }
        if (this.LFDIssuedNotifi == "Push Notification Turned Off") {
          if (this.stateData.LFDIssued.length == 4) {
            this.stateData.LFDIssued.shift();
          }
          menuComponent.activateMenu();
        }
        return;
      }
      this.generalClose();
    },
    setLFDIssuedTime() {
      const menuComponent = this.$refs.LFDIssuedSelect;
      if (
        this.stateData.LFDIssued.length == 4 &&
        this.getCompanyProfile.notifi_lfd_issued !== "OFF"
      ) {
        this.stateData.LFDIssued.shift();
      }
      if (this.getCompanyProfile.notifi_lfd_issued == "OFF") {
        menuComponent.blur();
        return;
      }

      //
    },

    updateLFDIssued(selectedValue) {
      if (selectedValue == "Custom Time") {
        this.SET_CURRENT_EDIT_ITEM("LFD Issued");
        this.SET_CUSTOM_TIME_DAILOG(true);
        return;
      }
      this.updateNotification("LFD Issued", selectedValue);
    },

    ETAChangeMenu() {
      const menuComponent = this.$refs.etaSelect;
      if (!this.getBoxOpen.show) {
        let payload = {
          show: true,
          ref: "etaSelect",
          state: "ETAChangeAlert",
          property: "notifi_eta_changed_alert",
        };
        this.SET_BOX_OPEN(payload);
        if (this.ETAChangeAlertNotifi !== "Push Notification Turned Off") {
          menuComponent.blur();
          if (this.stateData.ETAChangeAlert.length == 3) {
            const newItem = "Push Notification Turned Off";
            this.stateData.ETAChangeAlert.unshift(newItem);
          }
          this.updateNotification("ETA Changed Alert", "OFF");
          return;
        }
        if (this.ETAChangeAlertNotifi == "Push Notification Turned Off") {
          if (this.stateData.ETAChangeAlert.length == 4) {
            this.stateData.ETAChangeAlert.shift();
          }
          menuComponent.activateMenu();
        }
        return;
      }
      this.generalClose();
      //
    },

    setETAChangeAlertTime() {
      const menuComponent = this.$refs.etaSelect;
      if (
        this.stateData.ETAChangeAlert.length == 4 &&
        this.getCompanyProfile.notifi_eta_changed_alert !== "OFF"
      ) {
        this.stateData.ETAChangeAlert.shift();
      }

      if (this.getCompanyProfile.notifi_eta_changed_alert == "OFF") {
        menuComponent.blur();
        return;
      }

      //
    },
    updateETAChange(selectedValue) {
      if (selectedValue == "Custom Time") {
        this.SET_CURRENT_EDIT_ITEM("ETA Changed Alert");
        this.SET_CUSTOM_TIME_DAILOG(true);
        return;
      }
      this.updateNotification("ETA Changed Alert", selectedValue);
    },

    LFDReminderMenu() {
      const menuComponent = this.$refs.LFDReminderSelect;

      if (!this.getBoxOpen.show) {
        let payload = {
          show: true,
          ref: "LFDReminderSelect",
          state: "LFDReminder",
          property: "notifi_lfd_reminder",
        };
        this.SET_BOX_OPEN(payload);

        if (this.LFDReminderNotifi !== "Push Notification Turned Off") {
          menuComponent.blur();
          if (this.stateData.LFDReminder.length == 3) {
            const newItem = "Push Notification Turned Off";
            this.stateData.LFDReminder.unshift(newItem);
          }
          this.updateNotification("LFD issued", "OFF");
          return;
        }
        if (this.LFDReminderNotifi == "Push Notification Turned Off") {
          if (this.stateData.LFDReminder.length == 4) {
            this.stateData.LFDReminder.shift();
          }
          menuComponent.activateMenu();
        }
        return;
      }
      this.generalClose();
    },
    setLFDReminderTime() {
      const menuComponent = this.$refs.LFDReminderSelect;
      if (
        this.stateData.LFDReminder.length == 4 &&
        this.getCompanyProfile.notifi_lfd_reminder !== "OFF"
      ) {
        this.stateData.LFDReminder.shift();
      }
      if (this.getCompanyProfile.notifi_lfd_reminder == "OFF") {
        menuComponent.blur();
        return;
      }
      // console.log(e);
    },

    updateLFDReminder(selectedValue) {
      if (selectedValue == "Custom Time") {
        this.SET_CURRENT_EDIT_ITEM("LFD Reminder");
        this.SET_CUSTOM_TIME_DAILOG(true);
        return;
      }
      this.updateNotification("LFD Reminder", selectedValue);
    },

    updateNotification(notification, period) {
      let form = new FormData();
      switch (notification) {
        case "Container Available":
          form.append("type", "container_available");
          if (period == "Active (Anytime)") {
            form.append("active_anytime", true);
          } else if (period == "Working Hours (8AM-5PM)") {
            form.append("working_hours", true);
          } else if (period == "OFF") {
            form.append("off_notification", true);
          } else {
            form.append("custom_time", true);
          }

          break;
        case "Container Discharged":
          form.append("type", "container_discharged");
          if (period == "Active (Anytime)") {
            form.append("active_anytime", true);
          } else if (period == "Working Hours (8AM-5PM)") {
            form.append("working_hours", true);
          } else if (period == "OFF") {
            form.append("off_notification", true);
          } else {
            form.append("custom_time", true);
          }

          break;
        case "LFD Issued":
          form.append("type", "LFD_issued");
          if (period == "Active (Anytime)") {
            form.append("active_anytime", true);
          } else if (period == "Working Hours (8AM-5PM)") {
            form.append("working_hours", true);
          } else if (period == "OFF") {
            form.append("off_notification", true);
          } else {
            form.append("custom_time", true);
          }

          break;
        case "ETA Changed Alert":
          form.append("type", "eta_changed_alert");

          if (period == "Active (Anytime)") {
            form.append("active_anytime", true);
          } else if (period == "Working Hours (8AM-5PM)") {
            form.append("working_hours", true);
          } else if (period == "OFF") {
            form.append("off_notification", true);
          } else {
            form.append("custom_time", true);
          }

          break;

        default:
          form.append("type", "lfd_reminder");
          if (period == "Active (Anytime)") {
            form.append("active_anytime", true);
          } else if (period == "Working Hours (8AM-5PM)") {
            form.append("working_hours", true);
          } else if (period == "OFF") {
            form.append("off_notification", true);
          } else {
            form.append("custom_time", true);
          }

          break;
      }

      this.updateNotificationTime(form);
    },
  },
  created() {},
};
</script>

<style></style>
