<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            width="1100"
            
        >
            <v-card class="bkgd-faded-blue">
                <v-card-title>
                    <span class="text-h5">Add Delivery Location</span>
                </v-card-title>

                <div class="px-6 pt-6">
                    <label class="section-text">General Information</label>
                </div>
                <v-form ref="form" v-model="customerForm" id="createDeliveryLocation" action="#" @submit.prevent="">
                    <v-container class="px-8 py-8">
                        <v-row class="bkgd-white px-2 py-2">
                            <v-col
                                cols="12"
                                sm="6"
                                md="6"
                                class="py-0"
                            >
                                <label class="text-item-label">
                                    Location Name
                                </label>
                                <div>
                                    <v-text-field
                                        type="text"
                                        placeholder="Enter Location Name"
                                        outlined
                                        class="text-fields"
                                        :rules="requiredFields"
                                        v-model="location_name"
                                    />
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                md="6"
                                class="py-0"
                            >
                                <label class="text-item-label">Type</label>
                                <div class="qbCustomerField">
                                    <v-autocomplete
                                        v-model="type"
                                        :search-input.sync="searchType"
                                        :items="types"
                                        placeholder="Select Type"
                                        background-color="white"
                                        item-text="FullyQualifiedName"
                                        clearable
                                        outlined
                                        hide-details="auto"
                                        dense
                                        hide-no-data
                                    ></v-autocomplete>
                                </div>
                            </v-col>
                            <v-col 
                                cols="12"
                                sm="6"
                                md="6"
                                class="py-0"
                            >
                                <label class="text-item-label">Address</label>
                                <v-autocomplete
                                    v-model="address"
                                    :items="getPlaces"
                                    :search-input.sync="searchPlaces"
                                    placeholder="Please input a street address"
                                    background-color="white"
                                    item-text="place_name"
                                    class="search-place"
                                    hide-details="auto"
                                    clearable
                                    outlined
                                    dense
                                    hide-no-data
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col 
                                cols="12"
                                sm="6"
                                md="6"
                                class="py-0"
                            >
                                <label class="text-item-label">Special Delivery Instruction</label>
                                <textarea
                                    rows="5"
                                    cols="56"
                                    style="resize: none"
                                    class="textarea-field"
                                    placeholder="Enter Special Delivery Instruction"
                                    v-model="special_delivery_instruction"
                                >
                                </textarea>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="3"
                                md="3"
                                class="py-0"
                            >
                                <label class="text-item-label">Country</label>
                                <div>
                                    <v-text-field
                                        type="text"
                                        placeholder="Enter Location Name"
                                        outlined
                                        class="text-fields"
                                        :rules="requiredFields"
                                        v-model="country"
                                    />
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="3"
                                md="3"
                                class="py-0"
                            >
                                <label class="text-item-label">State</label>
                                <div class="qbCustomerField">
                                    <v-text-field
                                        type="text"
                                        placeholder="Enter Location Name"
                                        outlined
                                        class="text-fields"
                                        :rules="requiredFields"
                                        v-model="state"
                                    />
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                md="6"
                                class="py-0"
                            >
                                <label class="text-item-label"></label>
                                <div 
                                    class="flex bg-gray mt-6 px-3 py-2 qbCustomerField rounded" 
                                    style="padding: 10px 12px !important;"
                                >
                                    <input
                                        class="empty-checkbox my-checkbox"
                                        type="checkbox"
                                        :checked="appointment_required"
                                    />
                                    <span class="ml-2 chbox-label">Facility Requires Appointment</span>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="3"
                                md="3"
                                class="py-0"
                            >
                                <label class="text-item-label">City</label>
                                <div>
                                    <v-text-field
                                        type="text"
                                        placeholder="Enter City"
                                        outlined
                                        class="text-fields"
                                        :rules="requiredFields"
                                        v-model="city"
                                    />
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="3"
                                md="3"
                                class="py-0"
                            >
                                <label class="text-item-label">Zip Code</label>
                                <div class="qbCustomerField">
                                    <v-text-field
                                        type="text"
                                        placeholder="Enter Zip Code"
                                        outlined
                                        class="text-fields"
                                        :rules="zip_code !== '' ? zipCodeRules : requiredFields"
                                        v-model="zip_code"
                                    />
                                    <!-- @change="handleZipCode($event)" -->
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                md="6"
                                class="py-0"
                                v-if="consignee === null"
                            >
                                <label class="text-item-label">Link To Consignee</label>
                                <div class="qbCustomerField">
                                    <v-autocomplete
                                        v-model="consignee_id"
                                        :search-input.sync="searchConsignee"
                                        :items="[]"
                                        placeholder="Select Consignee"
                                        background-color="white"
                                        item-value="id"
                                        item-text="name"
                                        clearable
                                        outlined
                                        hide-details="auto"
                                        dense
                                        hide-no-data
                                    ></v-autocomplete>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>

                    <div class="px-6 pt-6">
                        <label class="section-text">Contacts</label>
                    </div>
                    <v-container class="px-6 py-6">
                        <div class="px-4 py-4 bkgd-white">
                            <v-data-table
                                class="add-contacts-table"
                                :headers="headers"
                                :items="contacts"
                                item-key="email"
                                hide-default-footer
                                dense
                            >
                                <template v-slot:body="{ items }">
                                    <tr v-for="(item, index) in items" :key="index">
                                        <td
                                            class="text-start"
                                            style="border: 1px solid #ebf2f5; width: 200px"
                                        >
                                            <v-text-field
                                                type="text"
                                                dense
                                                hide-details="auto"
                                                placeholder="Enter Contact Name"
                                                class="text-fields qbContacts container-nbr-ph"
                                                flat
                                                solo
                                                validate-on-blur
                                                v-model="item.contact_name"
                                                @change="handleChange('contact_name', $event, index)"
                                            />
                                        </td>
                                        <td
                                            class="text-end"
                                            style="border: 1px solid #ebf2f5; border-left: none"
                                        >
                                            <vue-tel-input
                                                mode="international"
                                                defaultCountry="us"
                                                validCharactersOnly
                                                :autoDefaultCountry="true"
                                                :inputOptions="vueTelInputOptions"
                                                v-model="item.phone_number"
                                                @input="(s, p) => handlePhoneNumber(s, p, index)"
                                            >
                                                <template v-slot:arrow-icon>
                                                    <v-icon class="ml-1">mdi-chevron-down</v-icon>
                                                </template>
                                            </vue-tel-input>
                                        </td>
                                        <td
                                            class="text-end"
                                            style="border: 1px solid #ebf2f5; border-left: none"
                                        >
                                            <v-text-field
                                                flat
                                                solo
                                                type="email"
                                                placeholder="Enter Email Address"
                                                class="text-fields qbContacts inlinefield text-end"
                                                dense
                                                hide-details="message"
                                                full-width
                                                :rules="emailRules"
                                                v-model="item.email"
                                                @change="handleChange('email', $event, index)"
                                            >
                                            </v-text-field>
                                        </td>
                                        <td
                                            class="text-end"
                                            style="border: 1px solid #ebf2f5; border-left: none"
                                        >
                                            <div class="flex justify-center">
                                                <input
                                                    class="empty-checkbox my-checkbox"
                                                    type="checkbox"
                                                    :checked="item.primary"
                                                    @change="handleChange('primary', $event, index)"
                                                />
                                            </div>
                                        </td>
                                        <td
                                            class="text-end"
                                            style="border: 1px solid #ebf2f5; border-left: none"
                                        >
                                            <v-text-field
                                                flat
                                                solo
                                                type="text"
                                                placeholder="Enter Role"
                                                class="text-fields qbContacts text-end"
                                                dense
                                                hide-details="auto"
                                                full-width
                                                v-model="item.role"
                                                @change="handleChange('role', $event, index)"
                                            />
                                        </td>
                                        <td
                                            class="text-center"
                                            style="border: 1px solid #ebf2f5; border-left: none"
                                            @click="handleRemoveContact(index)"
                                        >
                                            <v-icon
                                                size="19"
                                                :style="'color:red'"
                                                style="cursor: pointer"
                                            >mdi-close</v-icon>
                                        </td>
                                    </tr>
                                    <!-- <tr v-show="index === (contacts.length-1)"> -->
                                    <tr
                                        class="px-8 "
                                    >
                                        <td 
                                            colspan="6" 
                                            class="px-3 py-2"
                                            style="border-top: 1px solid #B4CFE0 !important;"
                                        >
                                            <span class="add-more-btn" @click="handleAddContact">+ Add More Contact</span>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </div>
                    </v-container>
                </v-form>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="buttonActions d-flex justify-start align-center pr-0" style="width: 100%;">
                    <v-btn :disabled="getIsAddingLocation" class="btn-blue" text @click="save">
                        <span>
                            <span>
                                <v-progress-circular
                                    :size="15"
                                    color="#fff"
                                    :width="2"
                                    indeterminate
                                    v-if="getIsAddingLocation"
                                    style="margin-right:3px" >
                                </v-progress-circular>
                                Add Delivery Location
                            </span>
                        </span>
                    </v-btn>
                    <v-btn class="btn-white" text @click="handleClose">
                        Cancel
                    </v-btn>
                </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
// import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import { VueTelInput } from "vue-tel-input";
import globalMethods from '../../../../utils/globalMethods';
// import jQuery from 'jquery';
// const APIBaseUrl = process.env.VUE_APP_PO_URL;

export default {
    name: 'AddDeliveryLocationDialog',
    props: ['dialog', 'consignee'],
    components: {
        // components ...
        VueTelInput,
    },
    data: () => ({
        arrayNotEmptyRules: [
            () => this.optionsFiltered.length > 0 || "Make sure to supply at least 1 email."
        ],
        requiredFields: [(v) => !!v || v === 0 || "This field is required"],
        tagsValidation: [
            {
                classes: 't-new-tag-input-text-error',
                rule: (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
                disableAdd: true
            }
        ],
        numberRules: [
            //(v) => !!v || "Input is required.",
            (v) => !v || /^(?=.*[0-9])[- +()0-9]+$/.test(v) || "Letters are not allowed."
        ],
        emailRules:[
             //(v) => !!v || "Email is required.",
             v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
        ],
        zipCodeRules: [
            v => !v || /^[0-9]{5}(?:-[0-9]{4})?$/.test(v) || 'Zip code is required and must be valid'
        ],
        tagsInput: {
            touched: false,
            hasError: false,
            errorMessage: 'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.'
        },
        documentProto: document,
        vueTelInputOptions: {
			autocomplete: false,
			placeholder: "Type Phone Number",
			styleClasses: "tel-input-class",
			required: false,
		},
        headers: [
            {
                text: "Name",
                align: "start",
                sortable: false,
                value: "name",
                fixed: true,
                width: "20%",
            },
            {
                text: "Phone Number",
                align: "start",
                sortable: false,
                value: "phone_number",
                fixed: true,
                width: "20%",
            },
            {
                text: "Email",
                align: "start",
                sortable: false,
                value: "email",
                fixed: true,
                width: "20%",
            },
            {
                text: "Primary",
                align: "center",
                sortable: false,
                value: "primary",
                fixed: true,
                width: "10%",
            },
            {
                text: "Role",
                align: "start",
                sortable: false,
                value: "role",
                fixed: true,
                width: "15%",
            },
            {
                text: "",
                sortable: false,
                value: "",
                fixed: true,
                width: "4%",
            },
        ],
        types: [
            'Amazon Fulfillment',
            'Company Owned Warehouse',
            '3PL Warehouse',
            'Retail Warehouse'
        ],
        rawContactFields: {
            contact_name: "",
            phone_number: "",
            email: "",
            primary: false,
            role: ""
        },
        startinglocationplaces: [],
        customerForm: true,
        isFetching: false,
        numberHasError: false,
        hasEmailerror: false,
        zipCodeHasError: false,
        searchType: "",
        searchConsignee: "",
        searchPlaces: "",
        location_name: "",
        type: "",
        address: "",
        special_delivery_instruction: "",
        country: "",
        state: "",
        city: "",
        zip_code: "",
        appointment_required: false,
        consignee_id: "",
        contacts: [],
    }),
    computed: {
        ...mapGetters({
            getIsAddingLocation: 'customers/getIsAddingLocation'
        }),
        getContacts: {
            get() {
                return this.contacts
            }
        },
        getConnected: {
            get() {
                if(typeof this.getQBCustomers !== "string") {
                    return "Connected"
                } else {
                    return "Disconnected"
                }
            }
        },
        getClass: {
            get() {
                if(typeof this.getQBCustomers !== "string") {
                    return "sub-text-green"
                } else {
                    return "sub-text-gray"
                }
            }
        },
        getPlaces: {
            get() {
                return this.startinglocationplaces
            }
        }
    },
    watch: {
        getIsFetching(nv) {
            if(!nv) {
                this.$refs['form'].resetValidation()
                this.$emit('update:dialog', false)
                this.dispatchNotifcation('Consignee added successfully!')
            }
        },
        searchPlaces(nv) {
            if(nv !== "") {
                this.getStartingLocation(nv)
            }
        },
        address(nv) {
            if(nv !== "") {
                let address = nv.split(',')
                let state = address[2].split(" ")

                console.log('state >>>', state)

                this.country = address[address.length-1]
                this.state = state.length >= 4 ? state[1]+" "+state[2] : state[1]
                this.city = address[1]

                let check = /^[0-9]{5}(?:-[0-9]{4})?$/.test(state[state.length-1])
                this.zip_code = check ? state[state.length-1] : ""
            }
        },
        startinglocationplaces(nv) {
            console.log('places >>>', nv)
        },
        getIsAddingLocation(nv) {
            if(!nv) {
                this.startinglocationplaces = []
                this.customerForm = true
                this.isFetching = false
                this.numberHasError = false
                this.hasEmailerror = false
                this.searchType = ""
                this.searchConsignee = ""
                this.searchPlaces = ""
                this.location_name = ""
                this.type = ""
                this.address = ""
                this.special_delivery_instruction = ""
                this.country = ""
                this.state = ""
                this.city = ""
                this.zip_code = ""
                this.appointment_required = false
                this.consignee_id = ""
                this.contacts = []
                this.$refs['form'].resetValidation()
                this.$emit('update:dialog', false)
                this.dispatchNotifcation('Delivery Location has been added successfully!')
            }
        }
    },
    methods: {
        ...mapActions({
            storeDeliveryLocation: 'customers/addDeliveryLocation'
        }),
        ...globalMethods,
        onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        handleClose() {
            this.startinglocationplaces = []
            this.customerForm = true
            this.isFetching = false
            this.numberHasError = false
            this.hasEmailerror = false
            this.searchType = ""
            this.searchConsignee = ""
            this.searchPlaces = ""
            this.location_name = ""
            this.type = ""
            this.address = ""
            this.special_delivery_instruction = ""
            this.country = ""
            this.state = ""
            this.city = ""
            this.zip_code = ""
            this.appointment_required = false
            this.consignee_id = ""
            this.contacts = []
            this.$refs['form'].resetValidation()
            this.$emit('update:dialog', false)
        },
        async save() {
            this.$refs.form.validate()
            setTimeout(async () => {
                if(this.$refs.form.validate() && !this.hasEmailerror && !this.numberHasError) {
                    await this.addDeliveryLocation();
                }
            }, 200)
        },
        async addDeliveryLocation() {
            let filteredContacts = this.contacts.filter(item => {
                if(item.contact_name !== "") {
                    return item
                }
            })

            console.log('filtered contacts >>>', filteredContacts)

            let formData = new FormData(document.getElementById('createDeliveryLocation'))
            formData.append('consignee_id', this.consignee.id)
            formData.append('customer_id', this.consignee.customer_id)
            formData.append('location_name', this.location_name)
            formData.append('type', this.type)
            formData.append('address', this.address)
            formData.append('country', this.country)
            formData.append('state', this.state)
            formData.append('city', this.city)
            formData.append('zip_code', this.zip_code)
            formData.append('note', this.special_delivery_instruction)
            formData.append('contacts', JSON.stringify(filteredContacts))
            
            this.storeDeliveryLocation(formData)
        },
        handleAddContact() {
            // this.contacts.splice(this.contacts.length+1, 0, this.rawContactFields)
            this.contacts.push(this.rawContactFields)
        },  
        handleRemoveContact(index) {
            console.log('index >>>', index)
            if(this.contacts.length > 1) {
                this.contacts.splice(index, 1)
            }
        },
        handlePrimaryChange(index) {
            this.contacts[index].primary = 1
        },
        handleChange(model, el, index) {
            if(model !== "primary" && model !== "email") {
                this.contacts[index][model] = el
            }
            else if(model === "email") {
                let validate = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(el)
                if(!validate) {
                    this.isValidEmail = true
                    this.notificationRedError('Email must be valid')
                } else {
                    this.isValidEmail = false
                    this.contacts[index][model] = el
                }
            } 
            else {
                this.contacts[index][model] = el.target.checked
            }
        },
        handlePhoneNumber(s, p, index) {
            let validate = /^(?=.*[0-9])[- +()0-9]+$/.test(s)
            if(!validate && s !== "") {
                this.numberHasError = true
                this.notificationRedError('Letters are not allowed')
            } else {
                this.numberHasError = false
            }

            this.contacts[index].phone_number = s
        },
        async getStartingLocation(val) {
            if (val !== null) {
                await fetch(`${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/`+val+`.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}&country=US`)
                    .then((response) => response.json())
                    .then((json) => {
                        this.startinglocationplaces = [...json.features]
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        },
        clearSearchPlaces() {
            this.places = [];
        },
        // handleZipCode(el) {
        //     if(el !== "") {
        //         let check = /^[0-9]{5}(?:-[0-9]{4})?$/.test(el)

        //         this.zipCodeHasError = 
        //     }
        // }
    },
}
</script>
<style lang="scss" scoped>
#addsinglecustomerheader {
    border-bottom: 2px solid #EBF2F5;

    .v-toolbar__content {
        .v-toolbar__title {
            font-size: 24px;
            font-weight: 600;
            line-height: 34px;
            letter-spacing: 0em;
            text-align: left;
        }
    }
}

.v-data-table.add-contacts-table {
    border: 1px solid #B4CFE0;
}

.qbCustomerField {
    .v-text-field {
        .v-input__control {
            max-height: 40px;
            padding: 0;
        }
    }
}

.bkgd-faded-blue {
    .container {
        .bkgd-white {
            div {
                .textarea-field {
                    flex-direction: column;
                    align-items: flex-start;
                    height: 76px;
                    background: #FFFFFF;
                    border: 1px solid #B4CFE0;
                    border-radius: 4px;
                    font-size: 14px;
                    padding: 10px 11px;
                    color: #4A4A4A;
                    caret-color: #0171A1 !important;
                    width: 100% !important;
                }
            }
        }
    }
}

.v-input {
    &.Goodsdeliverylocation {
        .v-input__control {
            height: 100%;

            .v-input__slot{
                height: 100%;
                padding: 8px 12px !important;

                .v-select__slot {
                    min-height: 60px !important;

                    .v-select__selections {
                        align-self: flex-start;
                        flex-wrap: wrap;
                    }

                    input {
                        align-self: flex-start;
                        padding: 0;
                    }

                    .v-label {
                        color: #4a4a4a!important;
                        font-family: "Inter-Regular", sans-serif;
                        font-size: 14px !important;
                        position: unset !important;
                        overflow: unset !important;
                        white-space: normal !important;
                        height: 100% !important;
                        padding: 0;
                    }

                    .v-input__append-inner{
                        display: block !important;

                        .v-input__icon {
                            height: 20px;

                            i {
                                transition: none !important;
                                transform: none !important;

                                &.mdi-menu-down {
                                    width: 26px !important;

                                    &::before {
                                        content: '' !important;
                                        background-image: url('/icons/search-address.svg') !important;
                                        background-position: center !important;
                                        background-repeat: no-repeat !important;
                                        background-size: cover !important;
                                        width: 16px !important;
                                        height: 18px !important;
                                        opacity: .9;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.container-starting-location {
            .v-menu__content {
                .v-list {
                    padding: 0 !important;

                    .v-list-item {
                        border-bottom: 1px solid #EBF2F5;
                        padding: 6px 16px;

                        .listDropdown {
                            color: #4a4a4a !important;
                            line-height: 20px !important;
                        }
                    }
                }
            }
        }
    }
}

.vti__input {
    caret-color: black !important;
    border: none;
    border-radius: 0 2px 2px 0;
    width: 100%;
    outline: none;
    padding-left: 68px;
}
.vti__dropdown {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: absolute;
    padding: 7px;
    cursor: pointer;
}
.vti__dropdown-list {
    z-index: 9999 !important;
    padding: 0;
    margin: 0;
    text-align: left;
    list-style: none;
    max-height: 200px;
    overflow-y: scroll;
    position: absolute;
    left: -1px;
    background-color: #fff;
    border: 1px solid #ccc;
}

.mdi-close {
    font-size: 24px;
}

.section-text {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.section-text-black {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #4A4A4A;
}

.bkgd-faded-blue {
    background: #F5F9FC;
}
.bkgd-white {
    background: #FFF;
}
.sub-text-green {
    color: #16B442;
    text-transform: capitalize;
}
.sub-text-gray {
    color: #6D858F;
    text-transform: capitalize;
}
</style>

<style>
.qbCustomerField > .v-text-field.v-text-field--solo .v-input__control {
    min-height: 40px !important;
    padding: 0;
}

.qbCustomerField > .v-text-field.v-text-field--outlined .v-input__control {
    min-height: 40px !important;
    padding: 0;
    font-size: 14px !important;
}

div.qbContacts.text-fields>div.v-input__control>div.v-input__slot {
    min-height: 40px!important;
    max-height: 40px!important;
    width: 100%;
    font-size: 14px !important;
}

.qbContacts.v-text-field.v-text-field--solo .v-input__control .v-text-field__details {
    margin-left: 12px;
}

.add-contacts-table div table tbody tr td.text-end {
    border-top: 1px solid #B4CFE0;
    border-right: 1px solid #B4CFE0;
    border-bottom: 1px solid #B4CFE0;
    border-left: none;
    border-image: initial;
    padding: 0 !important;
    height: 40px !important;
}
.add-contacts-table div table tr td.text-start {
    border: 1px solid #B4CFE0;
    width: 100%;
    padding: 0 !important;
}
.add-contacts-table div table tbody tr td.text-end .vue-tel-input {
    /* border: 1px solid rgb(235, 242, 245) !important; */
    border: 1px solid #B4CFE0 !important;
    width: 100%;
    padding: 0 !important;
}
.add-contacts-table div table tbody tr td.text-end .vue-tel-input:focus {
    /* outline: 1px solid rgb(235, 242, 245) !important; */
    outline: 1px solid #B4CFE0 !important;
    box-shadow: #FFF;
    width: 100%;
    padding: 0 !important;
}
.add-contacts-table div table tbody tr:hover {
    background: #FFF !important;
}
.add-contacts-table.v-data-table .v-data-table__wrapper table thead tr th {
    text-transform: uppercase !important;
    color: #6D858F !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    /* border-bottom: none !important; */
    border-bottom: 1px solid #B4CFE0 !important;
    background: #fff !important;
}
.add-contacts-table .v-data-table__wrapper table tbody tr td {
    color: #6D858F !important;
    font-size: 12px !important;
}
.add-contacts-table .v-data-table__wrapper table tr td.text-start {
    /* border-top: 1px solid #B4CFE0 !important; */
    border-right: 1px solid #B4CFE0 !important;
}
.add-contacts-table .v-data-table__wrapper table tr td.text-end {
    /* border-top: 1px solid #B4CFE0 !important; */
    border-right: 1px solid #B4CFE0 !important;
}
/* .add-contacts-table .v-data-table__wrapper table tr td.text-center {
    border-top: 1px solid #B4CFE0 !important;
} */
.v-text-field {
    padding-top: 12px;
    margin-top: 0px !important;
}
.add-more-btn {
    color: #0171A1;
    cursor: pointer;
    font-size: 14px;
}
.add-more-btn:hover {
    color: #035273 !important;
    cursor: pointer;
}
.v-text-fields.select .vs__dropdown-toggle, .text-end .vue-tel-input {
    min-height: 40px;
    border: none !important;
    font-size: 14px;
    background-color: #fff !important;
}
.vti__input {
    caret-color: black !important;
    border: none;
    border-radius: 0 2px 2px 0;
    width: 100%;
    outline: none;
    padding-left: 7px;
}
.vue-tel-input:focus-within {
    /* box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6); */
    box-shadow: none;
    /* border-color: #66afe9; */
}
.v-input.text-fields .v-text-field__details {
    display: inherit;
}
div.ti-input {
    border-radius: 4px;
    border: 1px solid #B4CFE0 !important;
    width: 100% !important;
    min-height: 40px;
}
.v-text-field--outlined > div > div[role="combobox"] > fieldset {
    border-color: #b4cfe0 !important;
    border-width: 1px;
    min-height: 45px !important;
    max-height: 45px !important;
}
.v-text-field--outlined.search-place > div > div[role="combobox"] > fieldset {
    border-color: #b4cfe0 !important;
    border-width: 1px;
    min-height: 81px !important;
    max-height: 81px !important;
}
.v-text-field--outlined.search-place > div > div[role="combobox"] > .v-select__slot > input {
    font-size: 14px !important;
}

.v-text-field--outlined.search-place > div > div[role="combobox"] 
    > .v-select__slot > .v-input__append-inner > .v-input__icon > .mdi-menu-down::before {
    content: '' !important;
    background-image: url('/icons/search-address.svg') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 16px !important;
    height: 18px !important;
    opacity: .9;
}
.vue-tel-input .vti__dropdown.open .vti__dropdown-list.below {
    z-index: 99999 !important;
    padding-left: 0;
}
.vti__dropdown {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: absolute !important;
    padding: 7px;
    cursor: pointer;
}
.vti__input {
    caret-color: black !important;
    border: none;
    border-radius: 0 2px 2px 0;
    width: 100%;
    outline: none;
    padding-left: 70px !important;
}
.chbox-label {
    font-size: 14px;
    font-weight: 400;
    color: #4A4A4A;
}
</style>