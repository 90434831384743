<template>
	<div class="driver-archived-tab-page-wrapper pa-6">
        <div class="driver-archived-header mb-4">
            <div class="driver-archived-header-title">
                <button icon dark class="btn-back-report font-medium" style="font-size: 12px; color: #4a4a4a;"> 
                    Drivers
                </button>
                <v-icon class="px-1" style="font-size: 14px;">mdi-chevron-right</v-icon>
                <span style="font-size: 12px; color: #6d858f;" class="font-medium">Archived</span>
            </div>
        </div>

		<div class="driver-archived-body">
            <div class="d-flex justify-space-between align-center px-4 py-3" style="border-bottom: 2px solid #EBF2F5 !important;">
                <div class="driver-body-header d-flex justify-start align-center">
                    <button icon dark class="btn-white mr-3" style="height: 35px !important; width: 35px !important;" @click="goback"> 
                        <v-icon style="color: #0171A1; font-size: 16px;">mdi-arrow-left</v-icon>
                    </button>

                    <h2 style="font-family: 'Inter-Medium', sans-serif !important; font-weight: unset; font-size: 20px;">
                        Archived Drivers
                    </h2>
                </div>

                <Search
                    style="width: 320px !important"
                    placeholder="Search Archived Drivers"
                    className="search custom-search search-component-wrapper"
                    :inputData.sync="search"
                    class="searchDos"
                />
            </div>

            <v-data-table
                :headers="headers"
                mobile-breakpoint="400"
                :items="driversData"
                class="new-driver-table"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                @page-count="pageCount = $event"
                hide-default-footer
                fixed-header>

                <template v-slot:[`header.name`]="{ header }">
                    {{ header.text }}<i class="classsort"></i>
                </template>

                <template v-slot:[`item.name`]="{ item }">
                    <p class="mb-0">
                        {{
                            (item.name !== null ? item.name : "") +
                            " " +
                            (item.last_name !== null ? item.last_name : "")
                        }}
                    </p>
                </template>

                <template v-slot:[`item.phone_number`]="{ item }">
                    <p class="mb-0">
                        {{
                            item.phone_number !== null && item.phone_number !== ""
                                ? item.phone_number
                                : "-"
                        }}
                    </p>
                </template>

                <template v-slot:[`item.email`]="{ item }">
                    <p class="mb-0">
                        {{ item.email !== "" ? item.email : "-" }}
                    </p>
                </template>

                <template v-slot:[`item.updated_at`]="{ }">
                    <div>
                        <p class="mb-0" style="color: #6d858f !important">
                            <!-- {{ lastseendate(item.driver.updated_at) }} -->
                            Not Active
                        </p>
                    </div>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <div class="action-button-wrapper-driver">
                        <button class="reinvite-invite btn-white" @click="actionDriver(item, 'activate')"
                            :disabled="isLoadingAction && currentDriverSelected !== null && currentDriverSelected.id === item.id" >
                            <span> 
                                {{  isLoadingAction && 
                                    (currentDriverSelected !== null && currentDriverSelected.id === item.id) ? 
                                    'Activating...' : 'Activate' }} 
                            </span>
                        </button>

                        <v-menu
                            offset-y
                            bottom
                            left
                            min-width="140px"
                            content-class="driver-table-menu trucking-menu"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" class="btn-white">
                                    <img src="@/assets/icons/more-blue.svg" alt="" />
                                </v-btn>
                            </template>

                            <v-list class="py-1">
                                <v-list-item class="driver-item-wrapper" @click="viewDriver(item, item.id)">
                                    <v-list-item-title class="driver-list-item">
                                        <span style="padding-right:3px">
                                            <svg style="position: relative; top: 2px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_9327_10539)">
                                                    <path d="M1.20537 9.66212L0.800803 10.0621L1.20537 9.66212ZM1.20537 6.33788L0.800803 5.93787L1.20537 6.33788ZM14.7946 9.66212L15.1992 10.0621L14.7946 9.66212ZM14.7946 6.33788L15.1992 5.93787L14.7946 6.33788ZM14.3901 9.26209C12.9464 10.8423 10.5898 12.8387 8 12.8387V14C11.0891 14 13.7319 11.6681 15.1992 10.0621L14.3901 9.26209ZM8 12.8387C5.41023 12.8387 3.0536 10.8423 1.60993 9.26209L0.800803 10.0621C2.26806 11.6681 4.91087 14 8 14V12.8387ZM1.60993 6.73791C3.0536 5.1577 5.41023 3.16129 8 3.16129V2C4.91087 2 2.26806 4.33186 0.800803 5.93787L1.60993 6.73791ZM8 3.16129C10.5898 3.16129 12.9464 5.1577 14.3901 6.73791L15.1992 5.93787C13.7319 4.33186 11.0891 2 8 2V3.16129ZM1.60993 9.26209C0.951732 8.54163 0.951732 7.45837 1.60993 6.73791L0.800803 5.93787C-0.266934 7.10658 -0.266934 8.89342 0.800803 10.0621L1.60993 9.26209ZM15.1992 10.0621C16.2669 8.89342 16.2669 7.10658 15.1992 5.93787L14.3901 6.73791C15.0483 7.45837 15.0483 8.54163 14.3901 9.26209L15.1992 10.0621ZM9.67442 8C9.67442 8.96201 8.92473 9.74194 8 9.74194V10.9032C9.54129 10.9032 10.7907 9.60343 10.7907 8H9.67442ZM8 9.74194C7.07528 9.74194 6.32558 8.96201 6.32558 8H5.2093C5.2093 9.60343 6.45874 10.9032 8 10.9032V9.74194ZM6.32558 8C6.32558 7.03799 7.07528 6.25806 8 6.25806V5.09677C6.45874 5.09677 5.2093 6.39659 5.2093 8H6.32558ZM8 6.25806C8.92473 6.25806 9.67442 7.03799 9.67442 8H10.7907C10.7907 6.39659 9.54129 5.09677 8 5.09677V6.25806Z" fill="#6D858F"/>
                                                </g>
                                            </svg>
                                        </span> <span class="ml-1">View</span>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item class="driver-item-wrapper" @click="editDriver(item)">
                                    <v-list-item-title class="driver-list-item">
                                        <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.4165 0.58353C12.1945 1.36157 12.1945 2.62302 11.4165 3.40106L4.05846 10.7591C3.73929 11.0782 3.33939 11.3047 2.9015 11.4141L0.619328 11.9847C0.254548 12.0759 -0.0758704 11.7455 0.0153245 11.3807L0.585868 9.0985C0.69534 8.66061 0.921762 8.2607 1.24092 7.94154L8.59893 0.58353C9.37697 -0.19451 10.6384 -0.19451 11.4165 0.58353ZM7.89447 2.69657L1.94531 8.64592C1.75381 8.83742 1.61796 9.07736 1.55227 9.3401L1.18306 10.8169L2.6599 10.4477C2.92263 10.382 3.16258 10.2462 3.35407 10.0547L9.30303 4.10513L7.89447 2.69657ZM9.30332 1.28791L8.59875 1.9923L10.0073 3.40085L10.7121 2.69668C11.1011 2.30766 11.1011 1.67693 10.7121 1.28791C10.3231 0.898893 9.69234 0.898893 9.30332 1.28791Z" fill="#6D858F"/>
                                        </svg> <span style="margin-left: 10px;">Edit</span>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>

                <template v-slot:no-data>
                    <div
                        class="no-data-preloader mt-4"
                        v-if="startfetching"
                        style="background-color: #fff !important"
                    >
                        <v-progress-circular
                            :size="30"
                            color="#0171a1"
                            indeterminate
                        >
                        </v-progress-circular>
                    </div>

                    <div
                        style="width: 100%"
                        v-if="!startfetching && driversData.length === 0"
                        class="pt-4"
                    >
                        <div style="width: 100%">
                            <div style="text-align: center">
                                <div>
                                    <div
                                        class="wrapno-matching"
                                        style="
                                            padding-top: 70px;
                                            display: inline-block;
                                        "
                                    >
                                        <p
                                            class="mb-0"
                                            style="
                                                font-size: 14px !important;
                                                color: #4a4a4a;
                                            "
                                        >
                                            No Data
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </v-data-table>
        </div>

		<Pagination
			v-if="typeof driversData !== 'undefined' && driversData.length > 0"
			:pageData.sync="page"
			:lengthData.sync="lastPage"
			:isMobile="isMobile"
		/>

        <DriverEditDialog
            :notifyupdate.sync="notifyupdate"
            :editedIndexData.sync="editedIndex"
            :dialogData.sync="dialogEdit"
            :editedItemData.sync="editedItem"
            :edititem="updatelisting"
            :idrow="currentEditrow"
        />
	</div>
</template>

<script>
import { mapActions } from "vuex";
import Pagination from "../../../Pagination.vue";
import Search from "../../../Search.vue";
import DriverEditDialog from '../../../DriverComponents/DriverEditDialog.vue'
// import Tab from "./tab.vue";
import globalMethods from "../../../../utils/globalMethods";
import axios from "axios";
const poBaseUrl = process.env.VUE_APP_PO_URL;

var cancel;
var CancelToken = axios.CancelToken;

export default {
	name: "DriverArchivedPage",
	props: [
		"items",
		"isMobile",
		"itemData",
		"reloadcomponent",
		"allcount",
		"bus",
		"countTab",
	],
	components: {
		Pagination,
		Search,
        DriverEditDialog,
		// Tab,
	},
	data: () => ({
		DriverData: [],
		//
		search: "",
		page: 1,
		pageCount: 0,
		itemsPerPage: 158,
        headers: [
            {
                text: "Name",
                align: "start",
                value: "name",
                sortable: true,
                width: "25%",
                fixed: true,
            },
            {
                text: "Phone Number",
                align: "start",
                value: "phone_number",
                sortable: false,
                width: "15%",
                fixed: true,
            },
            {
                text: "Email",
                align: "start",
                value: "email",
                sortable: false,
                width: "15%",
                fixed: true,
            },
            {
                text: "",
                align: "start",
                value: "actions",
                sortable: false,
                width: "15%",
                fixed: true,
            },
        ],
		startfetching: false,
        driversData: [],
        currnetDriverInvitationLoadingId: null,
        isShowArchivedWarning: false,
        archivedLoading: false,
        currentDriverSelected: null,
        pageCurrent: 1,
        isLoadingAction: false,
        notifyupdate: 0,
        editedIndex: 0,
        dialogEdit: false,
        editedItem: {
			company_name: "",
			phone_number: "",
			address: "",
			emails: null
		},
		defaultItem: {
			company_name: "",
			phone_number: "",
			address: "",
			emails: null
		},
        updatelisting: [],
        currentEditrow: '',
        typingTimeout: 0
	}),
	computed: {
		countData: {
			get() {
				return this.allcount;
			},
		},
		drivers: {
			get() {
				return this.items;
			},
			set(value) {
				this.$emit("items", value);
			},
		},
	},
	watch: {
		// page(nv) {
		// 	this.$emit("update:page", nv);
		// },
        page(ov, nv) {
            if (ov !== nv) {
                this.fetchAllDriversArchived()
            }
		},
        notifyupdate(v) {
            if (v) {
                this.fetchAllDriversArchived(this.page)
            }
        },
        search() {
            if (cancel !== undefined) {
                cancel("cancel_previous_request")
            }
            this.startfetching = true
            clearTimeout(this.typingTimeout)
            this.typingTimeout = setTimeout(() => {
                this.page = 1;
                this.fetchAllDriversArchived()
            }, 300)
        }
	},
	created() {},
	methods: {
		...mapActions({
			passData: "drivers/passData",
			passLocFrom: "drivers/passLocFrom",
			detailNotification: "drivers/detailNotification",
		}),
		...globalMethods,
        goback() {
			this.$router.push("/drivers/01")
		},
		actionDriver(item, type) {
            this.isLoadingAction = true
            this.currentDriverSelected = item

			let url = "";
			if (type == "activate") {
				url = "activateDriver";
			} else if (type == "deactivate") {
				url = "deactivateDriver";
			} else {
				url = "disconnectDriver";
			}
			axios
				.put(`${poBaseUrl}/` + url + "/" + item.id)
				.then((response) => {
					if (response) {
						this.notificationCustom('Driver has been successfully activated.');
                        this.fetchAllDriversArchived()
                        this.isLoadingAction = false
                        this.currentDriverSelected = null
					}
					// this.$emit("reloadpage", 2);
				})
				.catch(() => {
					// console.log(error);
                    this.isLoadingAction = false
				});
		},
		sendEmail(item, id) {
			this.$emit("sendEmail", id);
		},
		currenttab(cur) {
			this.$emit("currentPage", cur);
		},
		async viewDriver(item, id) {
			if (item) {
                localStorage.setItem("fromArchived", true)
				await this.passData(item);
				await this.detailNotification(item);
				await this.passLocFrom("notactive");
				this.$router
					.push("/drivers-details/" + id + "/03")
					.catch(() => {});
			}
		},
		editDriver(data) {            
            this.currentEditrow = data.id
			this.editedIndex = this.driversData.indexOf(data);
			this.editedItem = Object.assign({}, data);
			this.dialogEdit = true;
		},
        async fetchAllDriversArchived() {
            let dis = this;
            dis.startfetching = true
            dis.driversData = []

            let passedData = {
				method: "get",
				url: `${poBaseUrl}/getNotActiveDriver`,
				params: {
					page: dis.page,
				},
				cancelToken: new CancelToken(function executor(c) {
					cancel = c
				}),
			}

            if (this.search !== "") {
                passedData.params.search = dis.search
            }

            await axios(passedData)
            .then(function (response) {
                if (response) {
                    dis.startfetching = false
                    dis.driversData = response.data.drivers.data
                    dis.pageCurrent = dis.page;
                    dis.lastPage = response.data.drivers.last_page;
                    dis.pageCount = response.data.drivers.last_page;
                    dis.counTab = JSON.parse(response.data.count);
                    dis.notifyupdate = 0
                }
            })
            .catch(() => {
                dis.startfetching = false
                // console.log(error);
            });
        },
	},
	async mounted() {
		//set current page
		this.$store.dispatch("page/setPage", "drivers");
        this.fetchAllDriversArchived()
	},
	updated() {},
};
</script>

<style lang="scss">
@import "@/assets/scss/buttons.scss";

.driver-archived-tab-page-wrapper {
    height: 100%;

    .driver-archived-header {
        .driver-archived-header-title {
            display: flex;
            align-items: center;
        }
    }

    .search-component-wrapper {
        .v-input {
			margin-right: 0 !important;

            .v-input__control {
                .v-input__slot {
                    width: 320px !important;
                    margin-bottom: 0;

					input {
						font-family: "Inter-Regular", sans-serif !important;
					}
                }
            }
        }
    }

    .driver-archived-body {
        height: calc(100% - 92px);
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid #ebf2f5;

        .v-data-table.new-driver-table {
            box-shadow: none !important;
            // border: 1px solid #ebf2f5;

            .driver-header-tabs {
                height: 54px !important;

                .v-toolbar__content {
                    height: 54px !important;

                    .v-tabs {
                        .v-tab {
                            padding: 0 20px!important;
                            letter-spacing: 0!important;
                            font-family: "Inter-SemiBold", sans-serif !important;
                            font-weight: unset!important;
                            color: #4a4a4a!important;
                            text-transform: capitalize!important;

                            &.v-tab--active {
                                color: #0171a1 !important;
                            }
                        }
                    }
                }
            }

            .searching {
                display: flex;
                justify-content: flex-end !important;
                align-items: center;

                .search-component-wrapper {
                    .v-input {
                        .v-input__control {
                            .v-input__slot {
                                width: 320px !important;

                                input {
                                    font-family: "Inter-Regular", sans-serif !important;
                                }
                            }
                        }
                    }
                }
            }

            .v-data-table__wrapper {
                height: calc(100vh - 272px);
                overflow-y: auto;

                table {
                    thead {
                        tr {
                            th {
                                box-shadow: none !important;
                                border-bottom: 2px solid #EBF2F5 !important;
                                height: 40px;
                                padding: 8px 10px !important;
                                text-transform: capitalize;
                                font-size: 14px !important;
                                color: #6D858F !important;

                                span {
                                    font-size: 14px !important;
                                    color: #6D858F !important;
                                }

                                &:first-child {
                                    padding-left: 16px !important;
                                }
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                border-bottom: 1px solid #EBF2F5 !important;
                                padding: 10px !important;
                                font-size: 14px;

                                &:first-child {
                                    padding-left: 16px !important;
                                }

                                .action-button-wrapper-driver {
                                    display: flex;
                                    justify-content: flex-end;
                                    align-items: center;
                                    padding-right: 8px;

                                    .btn-white {
                                        height: 34px !important;
                                        min-width: 34px !important;
                                        padding: 8px !important;
                                        margin-left: 8px !important;

                                        &.reinvite-invite {
                                            padding: 8px 12px !important;
                                            font-size: 12px !important;
                                        }
                                    }
                                }
                            }

                            &.v-data-table__empty-wrapper {
                                cursor: unset;

                                &:hover {
                                    background-color: #fff !important;
                                }

                                td {
                                    border-bottom: none !important;
                                }
                            }
                        }
                    }
                }

                &::-webkit-scrollbar {
                    width: 8px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #d3d3d3;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #d3d3d3;
                }
            }
        }
    }

    .pagination-wrapper {
        border: 1px solid #EBF2F5 !important;
        border-top: none !important;
    }
}

.v-menu__content {
	&.driver-table-menu {
		min-width: 150px !important;

		.driver-item-wrapper {
			min-height: 38px;
			cursor: pointer;
			
			.v-list-item__title {
				font-size: 14px !important;
				display: flex;
				justify-content: flex-start;
				align-items: center;				
			}
		}
	}
}
</style>
