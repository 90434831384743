<template>
  <div class="dos-table-wrapper-content">
    <div class="driverHeader">
      <h2
        style="
          font-family: 'Inter-Medium', sans-serif !important;
          font-weight: unset;
        "
      >
        Delivery Orders
      </h2>
      <div class="d-flex align-center">
        <v-btn
          style="margin-right: 5px"
          color="primary"
          class="btn-white add-supplier addCustomer"
          @click="openCreatekey"
        >
          Share DO Form
        </v-btn>
        <v-btn
          color="primary"
          class="btn-blue add-supplier addCustomer"
          @click="openCreateDialog"
        >
          + Create Delivery Order
        </v-btn>
      </div>
    </div>

    <v-data-table
      :headers="headers"
      mobile-breakpoint="400"
      :items="itemsData"
      class="driver-table elevation-1 DOSWRAPPER"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      hide-default-footer
      fixed-header
    >
      <!-- v-if="hidewhow" -->

      <template v-slot:top>
        <v-toolbar class="headerClass DosHeadertabs" style="" flat>
          <v-tabs class="btabsclass" v-model="tabsmodel">
            <v-tab value="1">Incoming DO</v-tab>
            <v-tab value="2">Opened</v-tab>
            <!-- <v-tab value="3">Archived</v-tab> -->
          </v-tabs>

          <div class="d-flex align-center">
            <img
              src="../../../assets/icons/archived.svg"
              alt=""
              width="16px"
              height="16px"
            />
            <button
              class="btn-black archived"
              @click="toArchivedDO"
              style="
                color: #4a4a4a !important;
                font-family: 'Inter-Medium', sans-serif !important;
              "
            >
              Archived
            </button>
          </div>
        </v-toolbar>

        <v-toolbar
          class="searching"
          style="
            border-bottom: 2px solid #ebf2f5;
            border-top: 2px solid #ebf2f5;
          "
          flat
        >
          <Search
            style="width: 320px !important"
            :placeholder="textsearchLabel"
            className="search custom-search search-component-wrapper"
            :inputData.sync="search"
            class="searchDos"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <div class="mb-0">
          <span v-if="tabsmodel == 0" v-html="pastLfdIcon(item)"> </span>
          <span> {{ item.shifl_ref }} </span>
          <span class="spanContainer">
            <svg
              style="position: relative; top: 2px"
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 4.97506L8.11376 0.918178C8.44185 0.754134 8.80873 0.683478 9.17428 0.713941L16 1.28275L7.38462 5.59044L0 4.97506ZM16 1.89813L7.38464 6.20582V14.8212L14.8945 11.0663C15.572 10.7275 16 10.035 16 9.27745V1.89813ZM6.76923 6.20582L0 5.59044V12.3794C0 13.4138 0.788769 14.2775 1.81893 14.3712L6.76923 14.8212V6.20582ZM14.7693 3.7443L14.1539 4.05199V10.2058L14.7693 9.89814V3.7443ZM12.3077 4.97506L12.9231 4.66737V10.8212L12.3077 11.1289V4.97506ZM11.077 5.59045L10.4616 5.89814V12.052L11.077 11.7443V5.59045ZM9.23074 6.51352L8.61536 6.82121V12.9751L9.23074 12.6674V6.51352ZM3.07695 7.4366H3.69233V12.9751H3.07695V7.4366Z"
                fill="#6D858F"
              />
            </svg>
            <span
              style="
                padding-left: 4px;
                font-size: 14px !important;
                color: #6d858f;
              "
              >{{ getcontainercount(item) }}</span
            >
          </span>
          <br />
          <p
            class="Mbl_number"
            style="
              text-transform: uppercase;
              font-family: 'Inter-Medium', sans-serif;
            "
            v-if="item.mbl_number !== null"
          >
            MBL {{ item.mbl_number }}
          </p>
        </div>
      </template>

      <template v-slot:[`item.closed_at`]="{ item }">
        <span
          class="rejected"
          v-if="item.cancelled && item.reviewStatus !== 'Rejected'"
          >Cancelled</span
        >
        <span class="rejected" v-if="item.reviewStatus == 'Rejected'"
          >Rejected</span
        >
        <span
          class="Accepted"
          v-if="!item.cancelled && item.reviewStatus == 'Accepted'"
          >Closed</span
        >
        {{ convertToUserLocalTimezone(item.closed_at || item.created_at) }}
      </template>

      <template v-slot:[`item.opened_at`]="{ item }">
        <!-- {{ convertToUserLocalTimezone(item.opened_at || item.created_at) }} -->
        <div>
          <div>
            {{
              convertToUserLocalTimezone(
                item.opened_at || item.created_at,
                "date"
              )
            }}
          </div>
          <div style="color: #6d858f">
            {{
              convertToUserLocalTimezone(
                item.opened_at || item.created_at,
                "time"
              )
            }}
          </div>
        </div>
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        <div class="mb-0">
          <p v-if="tabtype == 2" style="margin-bottom: 0px">
            <span v-if="item.cancelled == 0 && item.archived == 1">
              <span :class="item.reviewStatus">{{ item.reviewStatus }}</span>
            </span>
            <span
              class="rejected"
              v-if="item.cancelled && item.reviewStatus == 'Rejected'"
              >Rejected</span
            >
            <span
              class="cancelled"
              v-if="item.cancelled && item.reviewStatus !== 'Rejected'"
              >Cancelled</span
            >
          </p>
          <p class="mb-0">{{ createditem(item) }}</p>
          <p class="mb-0" style="color: #6d858f" v-if="tabsmodel === 0">
            {{ getCounter(item) }}
          </p>
        </div>
      </template>

      <template v-slot:[`item.terminal_name`]="{ item }">
        <p class="mb-0" style="line-height: 20px">
          {{ terminal_name(item) }}
        </p>
      </template>

      <template v-slot:[`item.customerdelivery`]="{ item }">
        <div>
          <p class="mb-0">{{ customer_name(item) }}</p>
          <p class="mb-0" style="color: #6d858f">
            {{ getConsigneeName(item) }}
          </p>
        </div>
      </template>

      <template v-slot:[`item.location_to`]="{ item }">
        <p class="mb-0">{{ truncateString(location(item), 50) }}</p>
      </template>

      <!-- <template v-slot:[`item.eta`]="{ item }">
				<p class="mb-0">
					{{ eta(item) }}
				</p> 
				<p class="mb-0" style="color: #6D858F;">{{ getETACounter(item) }}</p>
			</template> -->

      <template v-slot:[`item.eta`]="{ item }">
        <p class="mb-0">{{ eta(item) }}</p>
        <p class="mb-0" v-if="tabsmodel !== 2" style="color: #6d858f">
          {{ getETACounter(item) }}
        </p>
      </template>

      <template v-slot:[`item.source_of_creation`]="{ item }">
        <p class="mb-0">{{ item.source_of_creation }}</p>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="action-button-wrapper-do">
          <button
            v-if="tabsmodel == 0"
            class="reviewbutton btn-white"
            @click="openReviewDialog(item)"
          >
            Review
          </button>

          <v-menu
            offset-y
            bottom
            left
            min-width="140px"
            content-class="dos-table-menu trucking-menu"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="truckerMoreBtn2">
                <img src="@/assets/icons/more-blue.svg" alt="" />
              </v-btn>
            </template>
            <v-list class="py-1" v-if="tabsmodel == 0">
              <v-list-item class="trucking-archived-wrapper">
                <v-list-item-title
                  class="trucking-archived-item"
                  @click="viewDosDetail(item)"
                >
                  <span style="padding-right: 3px">
                    <svg
                      style="position: relative; top: 2px"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_9327_10539)">
                        <path
                          d="M1.20537 9.66212L0.800803 10.0621L1.20537 9.66212ZM1.20537 6.33788L0.800803 5.93787L1.20537 6.33788ZM14.7946 9.66212L15.1992 10.0621L14.7946 9.66212ZM14.7946 6.33788L15.1992 5.93787L14.7946 6.33788ZM14.3901 9.26209C12.9464 10.8423 10.5898 12.8387 8 12.8387V14C11.0891 14 13.7319 11.6681 15.1992 10.0621L14.3901 9.26209ZM8 12.8387C5.41023 12.8387 3.0536 10.8423 1.60993 9.26209L0.800803 10.0621C2.26806 11.6681 4.91087 14 8 14V12.8387ZM1.60993 6.73791C3.0536 5.1577 5.41023 3.16129 8 3.16129V2C4.91087 2 2.26806 4.33186 0.800803 5.93787L1.60993 6.73791ZM8 3.16129C10.5898 3.16129 12.9464 5.1577 14.3901 6.73791L15.1992 5.93787C13.7319 4.33186 11.0891 2 8 2V3.16129ZM1.60993 9.26209C0.951732 8.54163 0.951732 7.45837 1.60993 6.73791L0.800803 5.93787C-0.266934 7.10658 -0.266934 8.89342 0.800803 10.0621L1.60993 9.26209ZM15.1992 10.0621C16.2669 8.89342 16.2669 7.10658 15.1992 5.93787L14.3901 6.73791C15.0483 7.45837 15.0483 8.54163 14.3901 9.26209L15.1992 10.0621ZM9.67442 8C9.67442 8.96201 8.92473 9.74194 8 9.74194V10.9032C9.54129 10.9032 10.7907 9.60343 10.7907 8H9.67442ZM8 9.74194C7.07528 9.74194 6.32558 8.96201 6.32558 8H5.2093C5.2093 9.60343 6.45874 10.9032 8 10.9032V9.74194ZM6.32558 8C6.32558 7.03799 7.07528 6.25806 8 6.25806V5.09677C6.45874 5.09677 5.2093 6.39659 5.2093 8H6.32558ZM8 6.25806C8.92473 6.25806 9.67442 7.03799 9.67442 8H10.7907C10.7907 6.39659 9.54129 5.09677 8 5.09677V6.25806Z"
                          fill="#6D858F"
                        />
                      </g>
                    </svg>
                  </span>
                  <span class="ml-1">View</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item class="trucking-archived-wrapper">
                <v-list-item-title
                  class="trucking-archived-item"
                  @click="editDO(item)"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.4165 0.58353C12.1945 1.36157 12.1945 2.62302 11.4165 3.40106L4.05846 10.7591C3.73929 11.0782 3.33939 11.3047 2.9015 11.4141L0.619328 11.9847C0.254548 12.0759 -0.0758704 11.7455 0.0153245 11.3807L0.585868 9.0985C0.69534 8.66061 0.921762 8.2607 1.24092 7.94154L8.59893 0.58353C9.37697 -0.19451 10.6384 -0.19451 11.4165 0.58353ZM7.89447 2.69657L1.94531 8.64592C1.75381 8.83742 1.61796 9.07736 1.55227 9.3401L1.18306 10.8169L2.6599 10.4477C2.92263 10.382 3.16258 10.2462 3.35407 10.0547L9.30303 4.10513L7.89447 2.69657ZM9.30332 1.28791L8.59875 1.9923L10.0073 3.40085L10.7121 2.69668C11.1011 2.30766 11.1011 1.67693 10.7121 1.28791C10.3231 0.898893 9.69234 0.898893 9.30332 1.28791Z"
                      fill="#6D858F"
                    />
                  </svg>
                  <span style="margin-left: 10px">Edit</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>

            <v-list v-if="tabsmodel == 1" class="py-1">
              <!-- <v-list-item class="trucking_DropDown">
								<v-list-item-title
										style=""
										class="d-flex align-center"
										@click="viewDosDetail(item)"
										>
										<span style="padding-right:3px">
															<svg  style="position: relative;
																	top: 2px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<g clip-path="url(#clip0_9327_10539)">
																		<path d="M1.20537 9.66212L0.800803 10.0621L1.20537 9.66212ZM1.20537 6.33788L0.800803 5.93787L1.20537 6.33788ZM14.7946 9.66212L15.1992 10.0621L14.7946 9.66212ZM14.7946 6.33788L15.1992 5.93787L14.7946 6.33788ZM14.3901 9.26209C12.9464 10.8423 10.5898 12.8387 8 12.8387V14C11.0891 14 13.7319 11.6681 15.1992 10.0621L14.3901 9.26209ZM8 12.8387C5.41023 12.8387 3.0536 10.8423 1.60993 9.26209L0.800803 10.0621C2.26806 11.6681 4.91087 14 8 14V12.8387ZM1.60993 6.73791C3.0536 5.1577 5.41023 3.16129 8 3.16129V2C4.91087 2 2.26806 4.33186 0.800803 5.93787L1.60993 6.73791ZM8 3.16129C10.5898 3.16129 12.9464 5.1577 14.3901 6.73791L15.1992 5.93787C13.7319 4.33186 11.0891 2 8 2V3.16129ZM1.60993 9.26209C0.951732 8.54163 0.951732 7.45837 1.60993 6.73791L0.800803 5.93787C-0.266934 7.10658 -0.266934 8.89342 0.800803 10.0621L1.60993 9.26209ZM15.1992 10.0621C16.2669 8.89342 16.2669 7.10658 15.1992 5.93787L14.3901 6.73791C15.0483 7.45837 15.0483 8.54163 14.3901 9.26209L15.1992 10.0621ZM9.67442 8C9.67442 8.96201 8.92473 9.74194 8 9.74194V10.9032C9.54129 10.9032 10.7907 9.60343 10.7907 8H9.67442ZM8 9.74194C7.07528 9.74194 6.32558 8.96201 6.32558 8H5.2093C5.2093 9.60343 6.45874 10.9032 8 10.9032V9.74194ZM6.32558 8C6.32558 7.03799 7.07528 6.25806 8 6.25806V5.09677C6.45874 5.09677 5.2093 6.39659 5.2093 8H6.32558ZM8 6.25806C8.92473 6.25806 9.67442 7.03799 9.67442 8H10.7907C10.7907 6.39659 9.54129 5.09677 8 5.09677V6.25806Z" fill="#6D858F"/>
																	</g>
															</svg>
										</span>
										&nbsp; View
								</v-list-item-title>
									<v-list-item-title
											class="trucking_DropDownEdit"
											@click="editDO(item)"
											style=""
											>
											<span style="vertical-align:middle">
													<svg width="13" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M11.4165 0.58353C12.1945 1.36157 12.1945 2.62302 11.4165 3.40106L4.05846 10.7591C3.73929 11.0782 3.33939 11.3047 2.9015 11.4141L0.619328 11.9847C0.254548 12.0759 -0.0758704 11.7455 0.0153245 11.3807L0.585868 9.0985C0.69534 8.66061 0.921762 8.2607 1.24092 7.94154L8.59893 0.58353C9.37697 -0.19451 10.6384 -0.19451 11.4165 0.58353ZM7.89447 2.69657L1.94531 8.64592C1.75381 8.83742 1.61796 9.07736 1.55227 9.3401L1.18306 10.8169L2.6599 10.4477C2.92263 10.382 3.16258 10.2462 3.35407 10.0547L9.30303 4.10513L7.89447 2.69657ZM9.30332 1.28791L8.59875 1.9923L10.0073 3.40085L10.7121 2.69668C11.1011 2.30766 11.1011 1.67693 10.7121 1.28791C10.3231 0.898893 9.69234 0.898893 9.30332 1.28791Z" fill="#6D858F"/>
													</svg>
											</span>
											<span class="mt-1"> &nbsp; Edit</span>
									</v-list-item-title>

									<v-list-item-title style="border-bottom:2px solid #eee;" @click="actionDriver(item)"
											class=""
											>
											<span style="vertical-align:middle">
														<svg style="position: relative;top: 2px" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M13.8498 0.800781C14.5954 0.800781 15.1998 1.4052 15.1998 2.15078V3.95078C15.1998 4.53834 14.8244 5.03824 14.3004 5.22374L14.2998 12.0508C14.2998 13.7905 12.8895 15.2008 11.1498 15.2008H4.84981C3.11011 15.2008 1.69981 13.7905 1.69981 12.0508L1.70007 5.22406C1.17559 5.03881 0.799805 4.53868 0.799805 3.95078V2.15078C0.799805 1.4052 1.40422 0.800781 2.14981 0.800781H13.8498ZM13.3998 5.30078H2.59981V12.0508C2.59981 13.2934 3.60717 14.3008 4.84981 14.3008H11.1498C12.3924 14.3008 13.3998 13.2934 13.3998 12.0508V5.30078ZM6.64981 7.10078H9.34981C9.59834 7.10078 9.79981 7.30225 9.79981 7.55078C9.79981 7.7717 9.64062 7.95543 9.4307 7.99353L9.34981 8.00078H6.64981C6.40128 8.00078 6.19981 7.79931 6.19981 7.55078C6.19981 7.32987 6.35899 7.14613 6.56892 7.10803L6.64981 7.10078H9.34981H6.64981ZM13.8498 1.70078H2.14981C1.90128 1.70078 1.69981 1.90225 1.69981 2.15078V3.95078C1.69981 4.19931 1.90128 4.40078 2.14981 4.40078H13.8498C14.0983 4.40078 14.2998 4.19931 14.2998 3.95078V2.15078C14.2998 1.90225 14.0983 1.70078 13.8498 1.70078Z" fill="#6D858F"/>
														</svg>
											</span>
											<span class=""> &nbsp; Close & Archive</span>
									</v-list-item-title>

									<v-list-item-title v-if="item.reviewStatus !=='Rejected'" @click="actionDoCancel(item)">
											<div style="padding: 5px 0px 5px 0px">
													<span class="text-center" style="vertical-align:middle;padding-right:1px">
														<svg width="14" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M11.1528 0.84639C11.3933 1.08689 11.3933 1.47681 11.1528 1.71731L6.87076 5.99935L11.1528 10.2814C11.3933 10.5219 11.3933 10.9118 11.1528 11.1523C10.9123 11.3928 10.5224 11.3928 10.2819 11.1523L5.99984 6.87027L1.7178 11.1523C1.4773 11.3928 1.08738 11.3928 0.846878 11.1523C0.606379 10.9118 0.606379 10.5219 0.846878 10.2814L5.12892 5.99935L0.846879 1.71731C0.60638 1.47681 0.60638 1.08689 0.846879 0.84639C1.08738 0.605891 1.4773 0.605891 1.7178 0.84639L5.99984 5.12843L10.2819 0.84639C10.5224 0.605891 10.9123 0.605891 11.1528 0.84639Z" fill="#F93131"/>
																</svg>
													</span>
													&nbsp; Cancel & Archive
											</div>
									</v-list-item-title>
							</v-list-item> -->

              <v-list-item class="trucking-archived-wrapper">
                <v-list-item-title
                  class="trucking-archived-item"
                  @click="viewDosDetail(item)"
                >
                  <span style="padding-right: 3px">
                    <svg
                      style="position: relative; top: 2px"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_9327_10539)">
                        <path
                          d="M1.20537 9.66212L0.800803 10.0621L1.20537 9.66212ZM1.20537 6.33788L0.800803 5.93787L1.20537 6.33788ZM14.7946 9.66212L15.1992 10.0621L14.7946 9.66212ZM14.7946 6.33788L15.1992 5.93787L14.7946 6.33788ZM14.3901 9.26209C12.9464 10.8423 10.5898 12.8387 8 12.8387V14C11.0891 14 13.7319 11.6681 15.1992 10.0621L14.3901 9.26209ZM8 12.8387C5.41023 12.8387 3.0536 10.8423 1.60993 9.26209L0.800803 10.0621C2.26806 11.6681 4.91087 14 8 14V12.8387ZM1.60993 6.73791C3.0536 5.1577 5.41023 3.16129 8 3.16129V2C4.91087 2 2.26806 4.33186 0.800803 5.93787L1.60993 6.73791ZM8 3.16129C10.5898 3.16129 12.9464 5.1577 14.3901 6.73791L15.1992 5.93787C13.7319 4.33186 11.0891 2 8 2V3.16129ZM1.60993 9.26209C0.951732 8.54163 0.951732 7.45837 1.60993 6.73791L0.800803 5.93787C-0.266934 7.10658 -0.266934 8.89342 0.800803 10.0621L1.60993 9.26209ZM15.1992 10.0621C16.2669 8.89342 16.2669 7.10658 15.1992 5.93787L14.3901 6.73791C15.0483 7.45837 15.0483 8.54163 14.3901 9.26209L15.1992 10.0621ZM9.67442 8C9.67442 8.96201 8.92473 9.74194 8 9.74194V10.9032C9.54129 10.9032 10.7907 9.60343 10.7907 8H9.67442ZM8 9.74194C7.07528 9.74194 6.32558 8.96201 6.32558 8H5.2093C5.2093 9.60343 6.45874 10.9032 8 10.9032V9.74194ZM6.32558 8C6.32558 7.03799 7.07528 6.25806 8 6.25806V5.09677C6.45874 5.09677 5.2093 6.39659 5.2093 8H6.32558ZM8 6.25806C8.92473 6.25806 9.67442 7.03799 9.67442 8H10.7907C10.7907 6.39659 9.54129 5.09677 8 5.09677V6.25806Z"
                          fill="#6D858F"
                        />
                      </g>
                    </svg>
                  </span>
                  <span class="ml-1">View</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item class="trucking-archived-wrapper">
                <v-list-item-title
                  class="trucking-archived-item"
                  @click="editDO(item)"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.4165 0.58353C12.1945 1.36157 12.1945 2.62302 11.4165 3.40106L4.05846 10.7591C3.73929 11.0782 3.33939 11.3047 2.9015 11.4141L0.619328 11.9847C0.254548 12.0759 -0.0758704 11.7455 0.0153245 11.3807L0.585868 9.0985C0.69534 8.66061 0.921762 8.2607 1.24092 7.94154L8.59893 0.58353C9.37697 -0.19451 10.6384 -0.19451 11.4165 0.58353ZM7.89447 2.69657L1.94531 8.64592C1.75381 8.83742 1.61796 9.07736 1.55227 9.3401L1.18306 10.8169L2.6599 10.4477C2.92263 10.382 3.16258 10.2462 3.35407 10.0547L9.30303 4.10513L7.89447 2.69657ZM9.30332 1.28791L8.59875 1.9923L10.0073 3.40085L10.7121 2.69668C11.1011 2.30766 11.1011 1.67693 10.7121 1.28791C10.3231 0.898893 9.69234 0.898893 9.30332 1.28791Z"
                      fill="#6D858F"
                    />
                  </svg>
                  <span style="margin-left: 10px">Edit</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                class="trucking-archived-wrapper"
                style="border-bottom: 1px solid #d8e7f0"
              >
                <v-list-item-title
                  class="trucking-archived-item"
                  @click="actionDriver(item)"
                >
                  <span style="padding-right: 3px">
                    <svg
                      style="position: relative; top: 2px"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.8498 0.800781C14.5954 0.800781 15.1998 1.4052 15.1998 2.15078V3.95078C15.1998 4.53834 14.8244 5.03824 14.3004 5.22374L14.2998 12.0508C14.2998 13.7905 12.8895 15.2008 11.1498 15.2008H4.84981C3.11011 15.2008 1.69981 13.7905 1.69981 12.0508L1.70007 5.22406C1.17559 5.03881 0.799805 4.53868 0.799805 3.95078V2.15078C0.799805 1.4052 1.40422 0.800781 2.14981 0.800781H13.8498ZM13.3998 5.30078H2.59981V12.0508C2.59981 13.2934 3.60717 14.3008 4.84981 14.3008H11.1498C12.3924 14.3008 13.3998 13.2934 13.3998 12.0508V5.30078ZM6.64981 7.10078H9.34981C9.59834 7.10078 9.79981 7.30225 9.79981 7.55078C9.79981 7.7717 9.64062 7.95543 9.4307 7.99353L9.34981 8.00078H6.64981C6.40128 8.00078 6.19981 7.79931 6.19981 7.55078C6.19981 7.32987 6.35899 7.14613 6.56892 7.10803L6.64981 7.10078H9.34981H6.64981ZM13.8498 1.70078H2.14981C1.90128 1.70078 1.69981 1.90225 1.69981 2.15078V3.95078C1.69981 4.19931 1.90128 4.40078 2.14981 4.40078H13.8498C14.0983 4.40078 14.2998 4.19931 14.2998 3.95078V2.15078C14.2998 1.90225 14.0983 1.70078 13.8498 1.70078Z"
                        fill="#6D858F"
                      />
                    </svg>
                  </span>
                  <span class="ml-1">Close & Archive</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                class="trucking-archived-wrapper"
                v-if="item.reviewStatus !== 'Rejected'"
              >
                <v-list-item-title
                  class="trucking-archived-item"
                  @click="actionDoCancel(item)"
                >
                  <svg
                    width="14"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.1528 0.84639C11.3933 1.08689 11.3933 1.47681 11.1528 1.71731L6.87076 5.99935L11.1528 10.2814C11.3933 10.5219 11.3933 10.9118 11.1528 11.1523C10.9123 11.3928 10.5224 11.3928 10.2819 11.1523L5.99984 6.87027L1.7178 11.1523C1.4773 11.3928 1.08738 11.3928 0.846878 11.1523C0.606379 10.9118 0.606379 10.5219 0.846878 10.2814L5.12892 5.99935L0.846879 1.71731C0.60638 1.47681 0.60638 1.08689 0.846879 0.84639C1.08738 0.605891 1.4773 0.605891 1.7178 0.84639L5.99984 5.12843L10.2819 0.84639C10.5224 0.605891 10.9123 0.605891 11.1528 0.84639Z"
                      fill="#F93131"
                    />
                  </svg>
                  <span style="margin-left: 9px">Cancel & Archive</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>

      <template v-slot:no-data>
        <div
          class="no-data-preloader mt-4"
          v-if="startfetching"
          style="background-color: #fff !important"
        >
          <v-progress-circular :size="30" color="#0171a1" indeterminate>
          </v-progress-circular>
        </div>

        <div v-else>
          <div
            style="width: 100%"
            v-if="!startfetching && itemsData.length === 0"
            class="pt-4"
          >
            <div style="width: 100%">
              <!-- <span style="color: #4a4a4a;">No data</span> -->
              <div style="text-align: center">
                <div>
                  <div
                    class="wrapno-matching"
                    style="padding-top: 70px; display: inline-block"
                  >
                    <!-- <img src="@/assets/icons/truckicon.svg" /> -->
                    <p
                      class="mb-0"
                      style="font-size: 14px !important; color: #4a4a4a"
                    >
                      No Data
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </v-data-table>

    <div>
      <ModalDetails
        :items="itemsdetails"
        :tabtype="tabtype"
        @datastatus="hideshow"
        @archivetype="archivetype"
        @editDO="editDO"
        :terminals="terminals"
        :carriers="carriers"
        :customers="customers"
        :defaultmodal.sync="defaultmodal"
      />
    </div>

    <Pagination
      v-if="typeof itemsData !== 'undefined' && itemsData.length > 0"
      :pageData.sync="page"
      :lengthData.sync="updatedPageCount"
      :isMobile="isMobile"
    />

    <Archivedo
      :items="theitems"
      :dialogData.sync="showarchivedo"
      @close="closearchive"
      @confirmCancel="confirmArchive"
      :customer="customer"
    />

    <Unarchivedo
      :items="theitemsunarchive"
      :dialogData.sync="showunarchivedo"
      @close="closeunarchive"
      @confirmCancel="confirmunArchive"
    />

    <Uncanceldo
      :items="uncancelData"
      :dialogData.sync="showuncanceldo"
      @close="closeuncancel"
      @confirmUncancel="confirmUncancel"
    />

    <!-- <AddContainerFormDialog
		:openDialog="createDialog"
		@closeDialog="closeDialog('createDialog')"
		/> -->

    <CreateCustomerLink
      :dialogData="createDialogKey"
      :datalink="datalink"
      @close="closeDialog('createDialogKey')"
    />

    <!-- <EditFormDialog
		:openDialog="editDialog"
		:item="theviewdata"
		@closeDialog="closeDialog('editDialog')"
		/> -->

    <AcceptedEditFormDialog
      :openDialog.sync="AcceptedreviewDialog"
      :item="theviewdata"
      @refresh="refreshAccepted"
      @closeDialog="closeDialog('AcceptedreviewDialog')"
      :pageClass="'do-table-page'"
      :tabsmodel="tabsmodel"
    />

    <ReviewFormDialog
      :acceptedmsg.sync="isaccepted"
      :rejectedmsg.sync="isrejected"
      :openDialogreview.sync="reviewDialog"
      :item="theviewdata"
      :datadialog="fetchincomingData"
      @closeDialog="closeDialog('reviewDialog')"
    />

    <CreateDOFormDialog
      :openDialogCreate.sync="createFormDialog"
      @closeDialog="closeDialog('createFormDialog')"
      :pageClass="'do-table-page'"
    />

    <AcceptedNotification
      :dialogData.sync="isacceptedshow"
      :item="theviewdata"
      :closeDialog.sync="iscloseacceptednotification"
    />

    <RejectedNotification
      :dialogData.sync="isrejectedshow"
      :item="theviewdata"
      :closeDialog.sync="iscloserejectnotification"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Pagination from "../../Pagination.vue";
import Search from "../../Search.vue";
import ModalDetails from "./modaldetails";
import Archivedo from "./dialog/Archivedo.vue";
import Uncanceldo from "./dialog/Uncanceldo.vue";
import Unarchivedo from "./dialog/Unarchivedo.vue";
import globalMethods from "../../../utils/globalMethods";
//import Tab from "./tab.vue";
import axios from "axios";
import moment from "moment";
//import ReviewFormDialog from './dialog/FormDialog.vue';
import ReviewFormDialog from "./dialog/ReviewFormDialog.vue";
import AcceptedEditFormDialog from "./dialog/AcceptedEditFormDialog.vue";
import AcceptedNotification from "./dialog/NotificationAccepted.vue";
import RejectedNotification from "./dialog/Notificationrejected.vue";
import CreateDOFormDialog from "./dialog/CreateDOFormDialog.vue";
import CreateCustomerLink from "./dialog/CreateCustomerLink.vue";
//import AddContainerFormDialog from './dialog/AddContainerFormDialog.vue';
//import EditFormDialog from './dialog/EditFormDialog.vue';
// import AcceptDODialog from './dialog/AcceptDODialog.vue';
var cancel;
var CancelToken = axios.CancelToken;
const poBaseUrl = process.env.VUE_APP_PO_URL;

export default {
  name: "Connected",
  props: [
    "items",
    "isMobile",
    "itemData",
    "reloadcomponent",
    "pageCurrent",
    "allcount",
    "bus",
    "countTab",
  ],
  components: {
    Pagination,
    Search,
    AcceptedNotification,
    RejectedNotification,
    ModalDetails,
    Archivedo,
    Unarchivedo,
    ReviewFormDialog,
    // AddContainerFormDialog,
    // EditFormDialog,
    CreateCustomerLink,
    CreateDOFormDialog,
    AcceptedEditFormDialog,
    Uncanceldo,
    // Tab
  },
  data() {
    return {
      terminals: [],
      customers: [],
      carriers: [],
      iscloserejectnotification: false,
      iscloseacceptednotification: false,
      isacceptedshow: false,
      isrejectedshow: false,
      isaccepted: false,
      isrejected: false,
      customer: [],
      tabsmodel: [],
      createFormDialog: false,
      datalink: "",
      createDialogKey: false,
      fetchincomingData: [],
      reviewDialog: false,
      AcceptedreviewDialog: false,
      createDialog: false,
      editDialog: false,
      isAccepting: false,
      hidewhow: 1,
      pageCounter: 1,
      thepagename: "",
      textsearchLabel: "",
      isHidemodal: 0,
      theviewdata: [],
      theitemsunarchive: [],
      theitems: [],
      showarchivedo: 0,
      showunarchivedo: 0,
      itemsdetails: [], // items
      defaultmodal: false,
      tabtype: 0,
      startfetching: true,
      loading: false,
      dataresult: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      updatedPageCount: false,
      search: "",
      lastPage: 1,
      acceptData: [],
      istabchange: 0,
      uncancelData: [],
      showuncanceldo: false,
      timeout: null,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "DO ID/Containers/MBL",
          align: "start",
          value: "name",
          sortable: false,
          width: "18%",
          fixed: true,
        },
        {
          text:
            this.tabtype == 0
              ? "Received"
              : this.tabtype === 1
              ? "Opened On"
              : "Closed at",
          align: "start",
          value:
            this.tabtype == 0
              ? "created_at"
              : this.tabtype === 1
              ? "opened_at"
              : "closed_at",
          sortable: false,
          width: "160px",
          // width: this.tabtype === 1 ? "120px" : "190px",
          fixed: true,
        },
        {
          text: "ETA",
          align: "start",
          value: "eta",
          sortable: false,
          // width: "11%",
          width: "120px",
          fixed: true,
        },
        // {
        // 	text: "SOURCE",
        // 	align: "start",
        // 	value: "source_of_creation",
        // 	sortable: false,
        // 	width: "2%",
        // 	fixed: true,
        // },
        {
          text: "Customer",
          align: "start",
          value: "customerdelivery",
          sortable: false,
          width: "15%",
          fixed: true,
        },
        {
          text: "Pickup",
          align: "start",
          value: "terminal_name",
          sortable: false,
          width: "16%",
          fixed: true,
        },
        {
          text: "Delivery",
          align: "start",
          value: "location_to",
          sortable: false,
          width: "16%",
          fixed: true,
          class: "delivery-location-to-td",
        },
        {
          text: "",
          align: "center",
          value: "actions",
          sortable: false,
          width: "10%",
          fixed: true,
        },
      ];
    },
    itemsData: {
      get() {
        return this.dataresult;
      },
    },
    countData: {
      get() {
        return this.allcount;
      },
    },
    drivers: {
      get() {
        return this.items;
      },
      set(value) {
        this.$emit("items", value);
      },
    },
  },
  watch: {
    iscloserejectnotification(c) {
      if (c) {
        this.isrejectedshow = false;
        this.reviewDialog = false;
        this.showIncoming();
      }
    },
    iscloseacceptednotification(c) {
      if (c) {
        this.isacceptedshow = false;
        this.showIncoming();
      }
    },
    isrejected(c) {
      if (c) {
        // show the accepted box confirmation
        this.reviewDialog = false;
        this.isrejectedshow = true;
      }
    },
    isaccepted(c) {
      if (c) {
        // show the accepted box confirmation
        this.isacceptedshow = true;
      }
    },
    tabsmodel(c) {
      this.thepagename = "";
      this.pageCounter = 1;
      if (c || !c) {
        this.tabtype = c;
        if (c == 1) {
          this.thepagename = "opened";
        } else if (c == 0) {
          this.thepagename = "incoming";
        }

        this.callpager();
      }
      this["search"] = "";
    },
    page(nv, ov) {
      if (nv !== ov) {
        this.pageCounter = nv;
        this.callpager();
      }
    },
    search() {
      if (cancel !== undefined) {
        cancel("cancel_previous_request");
      }

      this.pageCounter = 1;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.callSearchedPager();
      }, 300);
    },
    pageCount: {
      handler(newValue, oldValue) {
        if (this.search !== "") {
          //console.log(newValue, oldValue);
          if (newValue !== 0) {
            this.updatedPageCount = 1;
          } else if (oldValue > this.lastPage) {
            //console.log("2");
            this.updatedPageCount = 1;
          } else if (this.lastPage > oldValue) {
            //console.log("3");
            this.updatedPageCount = this.lastPage;
          } else {
            //console.log("4");
            this.updatedPageCount = 1;
          }
        } else {
          if (newValue > 1) {
            this.updatedPageCount = newValue;
          } else this.updatedPageCount = this.lastPage;
        }
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    getConsigneeName(item) {
      let name = "";
      if (item !== null && item.consignee !== null) {
        name = item.consignee.name;
      }
      return name;
    },
    toArchivedDO() {
      this.$router.push("/Dos/archived");
    },
    refreshAccepted(c) {
      if (c) {
        // console.log(c)
      }
    },
    ...mapActions({
      //passData: "drivers/passData",
      //passLocFrom: "drivers/passLocFrom",
      //detailNotification: "drivers/detailNotification",
    }),
    truncateString(str, num) {
      if (str.length > num) {
        return str.slice(0, num) + "...";
      } else {
        return str;
      }
    },
    pastLfdIcon(item) {
      let html = "";
      let redIcon = `<svg style="position:relative;top:3.5px; margin-right: 2px" width="17" height="17" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 -5.20937e-07 9 -5.20937e-07C4.02944 -5.20937e-07 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM9 16.6502C4.77491 16.6502 1.34979 13.2251 1.34979 9C1.34979 4.7749 4.77491 1.34979 9 1.34979C13.2251 1.34979 16.6502 4.7749 16.6502 9C16.6502 13.2251 13.2251 16.6502 9 16.6502ZM8.99672 10.3507C9.3384 10.3509 9.62093 10.0972 9.66584 9.76778L9.67206 9.67621L9.6753 4.72554C9.67554 4.3528 9.37358 4.05045 9.00084 4.0502C8.65917 4.04998 8.37663 4.30369 8.33173 4.63308L8.32551 4.72466L8.32227 9.67532C8.32202 10.0481 8.62399 10.3504 8.99672 10.3507ZM9.0004 13.4982C9.49672 13.4982 9.89906 13.0958 9.89906 12.5995C9.89906 12.1032 9.49672 11.7008 9.0004 11.7008C8.50409 11.7008 8.10175 12.1032 8.10175 12.5995C8.10175 13.0958 8.50409 13.4982 9.0004 13.4982Z" fill="#F93131"/>
                    </svg>`;
      let yellowIcon = `<svg style="position:relative;top:3.5px; margin-right: 2px" width="17" height="17" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM9 16.6502C4.77491 16.6502 1.34979 13.2251 1.34979 9C1.34979 4.7749 4.77491 1.34979 9 1.34979C13.2251 1.34979 16.6502 4.7749 16.6502 9C16.6502 13.2251 13.2251 16.6502 9 16.6502ZM8.99672 10.3507C9.3384 10.3509 9.62093 10.0972 9.66584 9.76778L9.67206 9.67621L9.6753 4.72554C9.67554 4.35281 9.37358 4.05045 9.00084 4.0502C8.65917 4.04998 8.37663 4.30369 8.33173 4.63308L8.32551 4.72466L8.32227 9.67532C8.32202 10.0481 8.62399 10.3504 8.99672 10.3507ZM9.0004 13.4982C9.49672 13.4982 9.89906 13.0958 9.89906 12.5995C9.89906 12.1032 9.49672 11.7008 9.0004 11.7008C8.50409 11.7008 8.10175 12.1032 8.10175 12.5995C8.10175 13.0958 8.50409 13.4982 9.0004 13.4982Z" fill="#D68A1A"/>
                        </svg>`;
      let today = moment(new Date()).utc(true).startOf("day");
      if (item.source_of_creation == "CF") {
        if (item.containers) {
          try {
            let containers = JSON.parse(item.containers);
            containers.forEach((cont) => {
              if (html == redIcon) return;
              if (cont.lfd) {
                if (moment(cont.lfd).utc(true).startOf("day").isBefore(today)) {
                  html = redIcon;
                } else if (
                  moment(cont.lfd).utc(true).startOf("day").isSame(today)
                ) {
                  html = yellowIcon;
                }
              }
            });
          } catch (e) {
            console.log(e);
          }
        }
      } else if (item.source_of_creation == "SD") {
        if (item.containers_group_bookings) {
          try {
            let containers_group_bookings = JSON.parse(
              item.containers_group_bookings
            );
            containers_group_bookings.forEach((cont) => {
              if (html != "") return;
              if (cont.lfd) {
                if (moment(cont.lfd).utc(true).startOf("day").isBefore(today)) {
                  html = redIcon;
                } else if (
                  moment(cont.lfd).utc(true).startOf("day").isSame(today)
                ) {
                  html = yellowIcon;
                }
              }
            });
          } catch (e) {
            console.log(e);
          }
        }
      }
      return html;
    },
    getcontainercount(item) {
      if (item.source_of_creation == "CF") {
        if (item.reviewStatus == "Pending" || item.reviewStatus == "Rejected") {
          if (item.containers != null) {
            let cont = JSON.parse(item.containers);
            return cont.length;
          }
          //return 999;
        } else if (item.reviewStatus == "Accepted") {
          return item.shipments.length;
        }
      }
      if (item.source_of_creation == "BO") {
        if (item.reviewStatus == "Pending" || item.reviewStatus == "Rejected") {
          if (item.containers != null) {
            let cont = JSON.parse(item.containers);
            return cont.length;
          }
        } else {
          return item.shipments.length;
        }
      }
      if (item.source_of_creation == "FE") {
        return item.shipments.length;
      }
      if (item.source_of_creation == "SD") {
        if (item.reviewStatus == "Pending" || item.reviewStatus == "Rejected") {
          if (item.containers_group_bookings != null) {
            let cont = JSON.parse(item.containers_group_bookings);
            return cont.length;
          }
        } else {
          return item.shipments.length;
        }
      }
      if (item.source_of_creation === null) {
        if (item.reviewStatus === "Accepted") {
          return item.shipments.length;
        }
      }
      return 0;
    },
    terminal_name(item) {
      if (this.tabtype == 0) {
        if (item.source_of_creation == "SD") {
          if (typeof this.terminals != "undefined") {
            let findterminal = this.terminals.find(
              (find) => find.firms_code == item.central_firms_code
            );
            if (findterminal != null) {
              if (findterminal.map_location != "") {
                return findterminal.map_location;
              }
            }
          }
          return "-";
        }
      }

      if(item.is_custom_starting_location){
        return item.starting_location_address
      }else{
        return item.terminal?.name ?? "-";
      }
    },
    archivetype(type) {
      this.isHidemodal = 1;
      if (type == "unarchive") {
        this.theitemsunarchive = this.itemsdetails;
        this.showunarchivedo = 1;
      } else if (type == "archive") {
        this.theitems = this.itemsdetails;
        this.showarchivedo = 1;
      } else {
        this.actionDoCancel(this.itemsdetails);
      }
      //alert(type)
    },
    confirmunArchive(item) {
      if (item.result) {
        //run archive
        axios
          .put(`${poBaseUrl}/unarchive-do/` + item.item.id)
          .then((response) => {
            if (response) {
              this.countTab = response.count;
              this.dispatchNotifcation(
                '<i class="ic-check-mark"></i>&nbsp;<span class="text-message">' +
                  "Delivery Order has been successfully Unarchived" +
                  "</span>"
              );
              this.showArchived();
              this.showunarchivedo = 0;
              if (this.isHidemodal) {
                this.defaultmodal = false;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    confirmArchive(item) {
      if (item.result) {
        //run archive
        axios
          .put(`${poBaseUrl}/archive-do/` + item.item.id)
          .then((response) => {
            if (response) {
              this.countTab = response.count;
              this.dispatchNotifcation(
                '<i class="ic-check-mark"></i>&nbsp;<span class="text-message">' +
                  "Delivery Order has been successfully Archived" +
                  "</span>"
              );
              this.showOpened();
              this.showarchivedo = 0;
              if (this.isHidemodal) {
                this.defaultmodal = false;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    closeunarchive() {
      this.showunarchivedo = 0;
    },
    closearchive() {
      this.showarchivedo = 0;
    },
    customer_name(item) {
      if (this.tabtype == 0) {
        if (item.source_of_creation == "SD") {
          if (item.reviewStatus === "Pending") {
            return "Shifl DO";
          }
          return item.customerdelivery?.company_name ?? "-";
        } else if (item.source_of_creation == "CF") {
          if (
            typeof item.customerdelivery !== "undefined" &&
            item.customerdelivery !== null
          ) {
            return item.customerdelivery?.company_name ?? "-";
          } else {
            return item.company_name ?? "-";
          }
        } else if (item.source_of_creation == null) {
          return "-";
        }
        let f = this.customers.find((find) => find.id == item.customer);
        return f?.company_name ?? "-";
      } else {
        return item.customerdelivery?.company_name ?? "-";
      }
    },
    viewDosDetail(item) {
      // console.log(item)
      this.itemsdetails = item;
      //this.theviewdata = item;
      this.defaultmodal = true;
    },
    hideshow(status) {
      if (!status) this.defaultmodal = false;
    },
    clicknow(item) {
      console.log(item);
    },
    createditem(item) {
      // -- old code
      // if(this.tabtype == 2) {
      // 	return item.date_of_changed_status != null ? moment(item.date_of_changed_status).utc(false).format("h:mm A, MMM DD, YYYY") : '---'
      // }
      // return moment(item.created_at).utc(false).format("h:mm A, MMM DD, YYYY")
      // old code --
      // updated code (converting date to user local timezone)
      let date = item.created_at;
      let utcTime = "";
      var local_date = "";
      if (this.tabtype == 2) {
        date =
          item.date_of_changed_status != null
            ? item.date_of_changed_status
            : null;
      }
      if (date !== null) {
        utcTime = moment(item.created_at)
          .utc(false)
          .format("h:mm A, MMM DD, YYYY");
        local_date = moment.utc(utcTime).local().format("h:mm A, MMM DD, YYYY");
      }
      return local_date;
    },
    location(item) {
      if (item.source_of_creation == "SD") {
        return item.delivery_location || item.description || "-";
      }
      return item.delivery_location || "-";
      //console.log(item.source_of_creation);
    },
    eta(item) {
      /**
       * ETA should show the date only
       */
      // return moment(item.eta).utc(false).format("h:mm A, MMM DD, YYYY")
      return moment(item.eta).utc(false).format("MMM DD, YYYY");
    },
    movetoopen(item) {
      let url = "move-archived-to-opened/" + item.id;
      axios
        .put(`${poBaseUrl}/` + url)
        .then((response) => {
          if (response) {
            console.log(response);
            this.dispatchNotifcation(
              '<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M11.1528 0.84639C11.3933 1.08689 11.3933 1.47681 11.1528 1.71731L6.87076 5.99935L11.1528 10.2814C11.3933 10.5219 11.3933 10.9118 11.1528 11.1523C10.9123 11.3928 10.5224 11.3928 10.2819 11.1523L5.99984 6.87027L1.7178 11.1523C1.4773 11.3928 1.08738 11.3928 0.846878 11.1523C0.606379 10.9118 0.606379 10.5219 0.846878 10.2814L5.12892 5.99935L0.846879 1.71731C0.60638 1.47681 0.60638 1.08689 0.846879 0.84639C1.08738 0.605891 1.4773 0.605891 1.7178 0.84639L5.99984 5.12843L10.2819 0.84639C10.5224 0.605891 10.9123 0.605891 11.1528 0.84639Z" fill="#F93131"/>' +
                '</svg>&nbsp;<span class="text-message">' +
                response.data.result +
                "</span>"
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // Move to Open
    movetoincoming(item) {
      let url = "move-archived-to-incoming-opened/" + item.id;
      axios
        .put(`${poBaseUrl}/` + url)
        .then((response) => {
          if (response) {
            console.log(response);
            this.dispatchNotifcation(
              '<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M11.1528 0.84639C11.3933 1.08689 11.3933 1.47681 11.1528 1.71731L6.87076 5.99935L11.1528 10.2814C11.3933 10.5219 11.3933 10.9118 11.1528 11.1523C10.9123 11.3928 10.5224 11.3928 10.2819 11.1523L5.99984 6.87027L1.7178 11.1523C1.4773 11.3928 1.08738 11.3928 0.846878 11.1523C0.606379 10.9118 0.606379 10.5219 0.846878 10.2814L5.12892 5.99935L0.846879 1.71731C0.60638 1.47681 0.60638 1.08689 0.846879 0.84639C1.08738 0.605891 1.4773 0.605891 1.7178 0.84639L5.99984 5.12843L10.2819 0.84639C10.5224 0.605891 10.9123 0.605891 11.1528 0.84639Z" fill="#F93131"/>' +
                '</svg>&nbsp;<span class="text-message">' +
                response.data.result +
                "</span>"
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async showIncoming() {
      this.thepagename = "incoming";
      this.textsearchLabel = "Search Incoming Delivery Orders";
      this.tabtype = 0;
      this.loading = true;
      // this.dataresult = [];
      this.startfetching = true;

      let passedData = {
        method: "get",
        url: `${poBaseUrl}/index-do/pending`,
        params: {
          page: this.pageCounter,
          search: this.search,
        },
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      };
      await axios(passedData)
        .then((response) => {
          if (response) {
            this.loading = false;
            this.startfetching = false;
            this.terminals = response.data.terminal;
            this.customers = response.data.customer;
            this.page = this.pageCounter;
            this.lastPage = response.data.result.last_page;
            if (response.data.result.last_page > 1) {
              this.pageCount = response.data.result.last_page;
            } else {
              this.pageCount = 1;
            }
            this.hidewhow = 0;
            // this.$nextTick(() => {
            this.dataresult = response.data.result.data;
            this.customer = response.data.customer.data;
            this.hidewhow = 1;
            // });
          }
        })
        .catch((err) => {
          // console.log(error); - pass error param
          // this.startfetching = false;
          if (err.message !== "cancel_previous_request") {
            this.startfetching = false;
          }
        });
    },
    async showOpened() {
      this.thepagename = "opened";
      this.textsearchLabel = "Search Opened Delivery Orders";
      this.tabtype = 1;
      this.loading = true;
      this.startfetching = true;

      let passedData = {
        method: "get",
        url: `${poBaseUrl}/index-do/opened`,
        params: {
          page: this.pageCounter,
          search: this.search,
        },
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      };
      await axios(passedData)
        .then((response) => {
          if (response) {
            //this.dataresult = response.data.result.data;
            this.loading = false;
            this.startfetching = false;
            this.page = this.pageCounter;
            this.lastPage = response.data.result.last_page;
            if (response.data.result.last_page > 1) {
              this.pageCount = response.data.result.last_page;
            }
            //console.log(response.data.results.data);
            this.dataresult = response.data.result.data;
            this.customer = response.data.customer.data;
            this.terminals = response.data.terminal;
            this.carriers = response.data.carrier;
          }
        })
        .catch((err) => {
          if (err.message !== "cancel_previous_request") {
            this.startfetching = false;
          }
        });
    },
    async showArchived() {
      this.thepagename = "archived";
      this.textsearchLabel = "Search Archived DO";
      this.loading = true;
      // this.dataresult = [];
      this.startfetching = true;
      await axios
        .get(`${poBaseUrl}/index-do/archived`, {
          params: {
            page: this.pageCounter,
            search: this.search,
          },
        })
        .then((response) => {
          if (response) {
            this.loading = false;
            this.startfetching = false;
            this.page = this.pageCounter;
            this.lastPage = response.data.result.last_page;
            if (response.data.result.last_page > 1) {
              this.pageCount = response.data.result.last_page;
            }
            this.dataresult = response.data.result.data;
            this.customer = response.data.customer.data;
            this.terminals = response.data.terminal;
            this.carriers = response.data.carrier;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async callpager() {
      if (cancel !== undefined) {
        cancel("cancel_previous_request");
      }
      this.dataresult = [];
      this.startfetching = true;

      if (this.thepagename == "opened") {
        setTimeout(async () => {
          await this.showOpened();
        }, 10);
      } else if (this.thepagename == "incoming") {
        setTimeout(async () => {
          await this.showIncoming();
        }, 10);
      }
    },
    async callSearchedPager() {
      this.dataresult = [];
      this.startfetching = true;

      if (this.thepagename == "opened") {
        await this.showOpened();
      } else if (this.thepagename == "incoming") {
        await this.showIncoming();
      }
    },
    actionDoCancel(item) {
      axios
        .put(`${poBaseUrl}/cancell-do/` + item.id)
        .then((response) => {
          if (response) {
            this.countTab = response.count;
            this.dispatchNotifcation(
              '<i class="ic-check-mark"></i>&nbsp;<span class="text-message">' +
                "Delivery Order has been successfully Cancelled " +
                "</span>"
            );
          }
          if (this.tabtype == 1) {
            this.showOpened();
          }
          if (this.isHidemodal) {
            this.defaultmodal = false;
          }
          // this.$emit('reloadpage',0)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    unactionDriver(item) {
      this.theitemsunarchive = item;
      this.showunarchivedo = 1;
    },
    actionDriver(item) {
      if (item) {
        this.theitems = item;
        this.showarchivedo = 1;
      }
    },
    openReviewDialog(item) {
      //delivery-order/review
      //this.$router.push("/delivery-order/review/"+item.id).catch(()=>{});
      this.theviewdata = item;
      this.reviewDialog = true;
    },
    openCreateDialog() {
      this.createFormDialog = true;
    },
    async openCreatekey() {
      await axios
        .get(`${poBaseUrl}/customer-link`)
        .then((response) => {
          this.datalink =
            document.location.origin + "/do-form/" + response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.createDialogKey = true;
    },
    editDO(item) {
      this.theviewdata = item;
      this.AcceptedreviewDialog = true;
    },
    closeDialog(data) {
      this[data] = false;
    },
    acceptDO(item) {
      console.log(item);
      this.acceptData = item;
      this.isAccepting = true;
    },
    uncancelDO(item) {
      this.uncancelData = item;
      this.showuncanceldo = true;
    },
    closeuncancel() {
      this.showuncanceldo = false;
    },
    confirmUncancel(item) {
      if (item.result) {
        //run uncancel
        axios
          .put(`${poBaseUrl}/uncancel-do/` + item.item.id)
          .then((response) => {
            if (response) {
              this.showOpened();
              this.tabsmodel = 1;
              this.countTab = response.count;
              this.dispatchNotifcation(
                '<i class="ic-check-mark"></i>&nbsp;<span class="text-message">' +
                  "Delivery Order has been successfully Uncancelled" +
                  "</span>"
              );
              this.showuncanceldo = false;
              if (this.isHidemodal) {
                this.defaultmodal = false;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getCounter(item) {
      let humanize = moment.duration(moment().diff(item.created_at)).humanize();
      return humanize + " ago";
    },
    getETACounter(item) {
      let now = moment(moment().format("YYYY-MM-DD") + "T00:00:00.000000Z");
      let eta = moment(item.eta).format("YYYY-MM-DD");
      let countDay = moment.duration(moment(item.eta).diff(moment()));
      let isPast = moment(item.eta).isBefore(moment());
      let humanize = moment
        .duration(moment(item.eta).diff(moment()))
        .humanize();
      // console.log('count day >>>', countDay.days())
      // console.log('eta >>>', eta)
      // console.log('now >>>', now.format('YYYY-MM-DD'))
      if (now.format("YYYY-MM-DD") === eta) {
        return "Today";
      } else {
        if (countDay.days() === 1) {
          if (now.isBefore(eta)) {
            return "1 day to";
          } else {
            return "1 day ago";
          }
        } else if (countDay.days() === 0) {
          return "1 day ago";
        }
        return humanize + (isPast ? " ago" : " to");
      }
    },
    convertToUserLocalTimezone(value, isFor) {
      if (isFor === "date") {
        return moment.utc(value).local().format("MMM DD, YYYY");
      } else if (isFor === "time") {
        return moment.utc(value).local().format("h:mm A");
      } else {
        return moment.utc(value).local().format("h:mm A, MMM DD, YYYY");
      }
    },
    ...globalMethods,
  },
  async mounted() {
    this.showIncoming();
    this.$store.dispatch("page/setPage", "Dos");
  },
  updated() {},
};
</script>

<style lang="scss">
.dos-table-wrapper-content {
  position: relative;

  .loading-overlay {
    position: absolute;
    z-index: 1000;
    left: 0;
    right: 0;
    bottom: 0;
    top: 112px;
    background-color: #fff;
    padding: 24px;
    display: flex;
    justify-content: center;
    border: 1px solid #ebf2f5;
    height: calc(100% - 108px);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .v-data-table.DOSWRAPPER {
    box-shadow: none !important;
    border: 1px solid #ebf2f5;

    .DosHeadertabs {
      height: 54px !important;

      .v-toolbar__content {
        height: 54px !important;

        .v-tabs {
          .v-tab {
            padding: 0 20px !important;
            letter-spacing: 0 !important;
            font-family: "Inter-SemiBold", sans-serif !important;
            font-weight: unset !important;
            color: #4a4a4a !important;
            text-transform: capitalize !important;

            &.v-tab--active {
              color: #0171a1 !important;
            }
          }
        }
      }
    }

    .v-data-table__wrapper {
      height: calc(100vh - 345px);
      overflow-y: auto;

      table {
        thead {
          tr {
            th {
              box-shadow: none !important;
              border-bottom: 2px solid #ebf2f5 !important;
              height: 40px;
              padding: 8px !important;
              text-transform: capitalize;
              font-size: 14px !important;

              span {
                font-size: 14px !important;
              }

              &:first-child {
                padding-left: 20px !important;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              border-bottom: 1px solid #ebf2f5 !important;
              padding: 10px 8px !important;
              font-size: 14px;

              &:first-child {
                padding-left: 20px !important;
              }

              &:nth-child(5),
              &:nth-child(6) {
                vertical-align: top;
                padding: 14px 8px !important;
              }

              .Mbl_number {
                font-size: 14px !important;
                color: #6d858f;
                margin-bottom: 0px;
                margin-top: 6px;
                // position: relative;
                // top: 4px
              }

              .spanContainer {
                padding-left: 6px;
                padding-top: 2px;
                padding-right: 5px;
                padding-bottom: 1px;
                border: 2px solid #d8e7f0;
                border-radius: 3px;
                margin-left: 3px !important;
              }

              .action-button-wrapper-do {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .truckerMoreBtn2 {
                  height: 30px !important;
                  width: 30px;
                  min-width: 30px !important;
                  min-width: 30px !important;
                  background-color: #fff !important;
                  border: 1px solid #b4cfe0;
                  box-shadow: none;
                }

                .btn-white.reviewbutton {
                  height: 30px !important;
                  font-size: 12px !important;
                  margin-right: 6px;
                  padding: 4px 10px !important;
                }
              }
            }

            &.v-data-table__empty-wrapper {
              cursor: unset;

              &:hover {
                background-color: #fff !important;
              }

              td {
                border-bottom: none !important;
              }
            }
          }
        }
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #d3d3d3;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #d3d3d3;
      }
    }

    .btn-black.archived {
      min-width: 75px !important;
      padding: 0 !important;
    }

    .searching {
      display: flex;
      justify-content: flex-end !important;
      align-items: center;

      .search-component-wrapper {
        .v-input {
          .v-input__control {
            .v-input__slot {
              width: 320px !important;

              input {
                font-family: "Inter-Regular", sans-serif !important;
              }
            }
          }
        }
      }
    }
  }

  .pagination-wrapper {
    border: 1px solid #ebf2f5 !important;
    border-top: none !important;
  }
}

.v-menu__content {
  &.dos-table-menu {
    min-width: 150px !important;

    .trucking-archived-wrapper {
      min-height: 38px;
      cursor: pointer;

      .v-list-item__title {
        font-size: 14px !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .trucking_DropDown > div {
      width: 100% !important;
    }
  }
}
.nomatching {
  font-size: 20px;
  font-weight: 600;
  padding-top: 5px;
  color: #6d858f !important;
}
</style>
