<template>
  <div class="text-center notification-user-dailog">
    <v-dialog v-model="getEmailDailog" max-width="590px" :retain-focus="false">
      <v-card class="notification-emails">
        <v-form
          id="createdriver"
          ref="form"
          v-model="valid"
          action="#"
          @submit.prevent=""
        >
          <v-card-title style="caret-color: transparent">
            <span class="headline">Send Email to</span>

            <button icon dark class="btn-close" @click="close">
              <v-icon>mdi-close</v-icon>
            </button>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-row>
              <v-col class="selectBox" cols="12" md="12">
                <label class="text-item-label">Email Notification for</label>
                <p class="notifi-type">{{ getCurrentEditItem }}</p>
              </v-col>

              <v-col
                class="selectBox"
                cols="12"
                md="12"
                style="padding-top: 3px; margin-top: -10px"
              >
                <label class="text-item-label"> Email Recipients </label>
                <div class="tags-email-wrapper">
                  <vue-tags-input
                    hide-details="auto"
                    :rules="arrayNotEmptyRules"
                    :tags="options"
                    :add-on-blur="true"
                    :class="myclass"
                    class="DialogInputfield"
                    :add-on-key="[13, ',']"
                    :validation="tagsValidation"
                    v-model="emailRecip"
                    placeholder="Enter email address"
                    @tags-changed="
                      (newTags) => {
                        this.myclass = 'noclass';
                        this.options = newTags;
                        this.tagsInput.touched = true;
                        this.tagsInput.hasError =
                          this.options.length > 0 ? false : true;
                        let el =
                          this.documentProto.getElementsByClassName(
                            'ti-input'
                          )[0];
                        if (typeof el !== 'udnefined') {
                          /*if (this.tagsInput.hasError)
                                el.classList.add('ti-new-tag-input-error')
                            else
                                el.classList.remove('ti-new-tag-input-error')*/
                        }
                      }
                    "
                  />
                </div>
                <div
                  v-if="tagsInput.touched && tagsInput.hasError"
                  class="v-text-field__details"
                >
                  <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                      <div
                        v-if="options.length > 0 && emailRecip !== ''"
                        class="v-messages__message"
                      >
                        {{ tagsInput.errorMessage }}
                      </div>
                      <div
                        v-if="options.length == 0 && emailRecip !== ''"
                        class="v-messages__message"
                      >
                        {{ tagsInput.errorMessage }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="showError" class="v-text-field__details mt-1">
                  <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                      <div class="v-messages__message">
                        Please provide at least 1 valid email address.
                      </div>
                    </div>
                  </div>
                </div>
                <span
                  style="font-size: 12px; color: #819fb2; font-style: italic"
                >
                  Separate multiple email addresses with comma
                </span>
              </v-col>

              <v-col cols="12" sm="12" md="12" style="margin-top: -40px">
                <v-checkbox
                  class="email-users"
                  label="Same emails for all notifications"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions style="border-top: 2px solid #ebf2f5">
            <div class="buttonActions" style="padding: 0px">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6" style="padding-left: 8px !important">
                      <v-btn class="btn-blue" text @click="save">
                        <span>
                          <span color="#fff">
                            <v-progress-circular
                              :size="15"
                              color="#fff"
                              :width="2"
                              indeterminate
                              v-if="getLoading"
                              style="margin-right: 3px"
                            >
                            </v-progress-circular>
                            Save Email
                          </span>
                        </span>
                      </v-btn>
                    </div>
                    <div class="col-md-4">
                      <v-btn class="btn-white" text @click="close">
                        Cancel
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card-actions>
        </v-form>

        <v-row style="margin-top: 0px"> </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  props: ["dialogData", "dialog", "currentEditItem"],
  components: {
    VueTagsInput,
  },
  data: () => {
    return {
      errorMessage: "",
      myclass: "",
      options: [],
      valid: false,
      documentProto: document,
      tagsInput: {
        touched: false,
        hasError: false,
        errorMessage:
          'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.',
      },
      tagsValidation: [
        {
          classes: "t-new-tag-input-text-error",
          rule: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
          disableAdd: true,
        },
      ],
      arrayNotEmptyRules: [
        //(v) => !!v || "Email is required",
        () =>
          this.optionsFiltered.length > 0 ||
          "Make sure to supply at least 1 email.",
      ],
      emailRecip: "",
      showError: false,
    };
  },

  computed: {
    ...mapGetters("notifications", [
      "getEmailDailog",
      "getLoading",
      "getCurrentEditItem",
      "getCurrentEmailRecpients",
    ]),
  },
  watch: {
    dialog(c) {
      if (!c) {
        this.$refs.form.resetValidation();
      } else {
        this.errorMessage = "";
      }
    },
    getEmailDailog(currState) {
      if (currState) {
        this.fillFormField();
      }
    },
  },

  methods: {
    ...mapActions("yards", ["addNewYard"]),
    ...mapActions("notifications", ["addRecipients"]),
    ...mapMutations("notifications", [
      "SET_EMAIL_DAILOG",
      "CURRENT_EMAIL_RECPIENTS",
    ]),
    fillFormField() {
      if (this.getCurrentEmailRecpients) {
        let validEmailAddress = [];
        JSON.parse(this.getCurrentEmailRecpients).forEach((value) => {
          validEmailAddress.push({
            text: value,
            tiClasses: ["ti-valid"],
          });
        });

        this.options = validEmailAddress;
        return;
      }
      this.options = [];
    },
    close() {
      this.CURRENT_EMAIL_RECPIENTS("");
      this.showError = false;
      this.SET_EMAIL_DAILOG(false);
    },
    async save() {
      if (!this.options.length) {
        this.showError = true;
        return;
      }
      let form = new FormData();

      let finalEmailAddress =
        this.options.length > 0
          ? this.options.map((o) => {
              return o.text;
            })
          : [];

      form.append("email_recipients[]", finalEmailAddress);
      switch (this.getCurrentEditItem) {
        case "Driver Accept Load":
          form.append("type", "sc_accepted");

          break;
        case "Driver Reject Load":
          form.append("type", "sc_rejected");

          break;
        case "Low Customer Activity":
          form.append("type", "low_customer_activity");

          break;
        case "DO Recived From Customer":
          form.append("type", "do_recived_from_customer");

          break;
        case "DO Recived From Shifl":
          form.append("type", "do_recived_from_shifl");

          break;
        case "Container Available":
          form.append("type", "container_available");

          break;
        case "Container Discharged":
          form.append("type", "container_discharged");

          break;
        case "LFD Issued":
          form.append("type", "LFD_issued");

          break;
        case "ETA Changed Alert":
          form.append("type", "eta_changed_alert");

          break;

        default:
          form.append("type", "lfd_reminder");

          break;
      }
      this.addRecipients(form);
      this.options = [];
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #b4cfe0;
  border-width: 1px;
}
</style>
<style lang="scss">
.notification-emails.v-card.v-sheet {
  overflow: hidden !important;
}

.notification-user-dailog {
  .email-users .v-input__slot .v-label {
    color: #4a4a4a;
    font-size: 14px;
    font-family: "Inter-Regular";
  }
}
.notifi-type {
  font-size: 14px;
  color: #4a4a4a;
  font-family: "Inter-Medium";
}
</style>
