<template>
  <div class="text-center notification-user-dailog">
    <v-dialog
      persistent
      v-model="getCustomTimeDailog"
      max-width="590px"
      :retain-focus="false"
    >
      <v-card class="add-supplier-card">
        <v-form
          id="createdriver"
          ref="form"
          v-model="valid"
          action="#"
          @submit.prevent=""
        >
          <v-card-title style="caret-color: transparent">
            <span class="headline">Custom Time</span>

            <button icon dark class="btn-close" @click="close">
              <v-icon>mdi-close</v-icon>
            </button>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label class="text-item-label"
                  >Push Notification Time for</label
                >
                <p class="notifi-type">{{ getCurrentEditItem }}</p>
              </v-col>
              <v-col
                style="margin-top: -20px !important"
                class="selectBox"
                cols="12"
                md="12"
              >
                <label class="text-item-label">From</label>
                <DxDateBox
                  :input-attr="{ 'aria-label': 'Time' }"
                  placeholder="Select Time"
                  :openOnFieldClick="true"
                  type="time"
                  v-model="fromTime"
                />
              </v-col>
              <v-col class="selectBox" cols="12" md="12">
                <div class="datetime-field-groups">
                  <label class="text-item-label">To</label>

                  <DxDateBox
                    placeholder="Select Time"
                    :openOnFieldClick="true"
                    type="time"
                    v-model="toTime"
                  />
                  <div v-if="showError" class="custom-error-text">
                    <div>
                      <span> Please Provide Start time and end time </span>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" style="margin-top: -30px">
                <v-checkbox
                  class="email-users"
                  label="Same time for all notifications"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions style="border-top: 2px solid #ebf2f5">
            <div class="text-right buttonActions" style="padding: 0px">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6" style="padding-left: 8px !important">
                      <v-btn class="btn-blue" text @click="updateNotification">
                        <span>
                          <span color="#fff">
                            <v-progress-circular
                              :size="15"
                              color="#fff"
                              :width="2"
                              indeterminate
                              v-if="getCreateYardLoading"
                              style="margin-right: 3px"
                            >
                            </v-progress-circular>
                            Set Time
                          </span>
                        </span>
                      </v-btn>
                    </div>
                    <div class="col-md-4">
                      <v-btn class="btn-white" text @click="close">
                        Cancel
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";
import DxDateBox from "devextreme-vue/date-box";

export default {
  props: ["dialogData", "currentEditItem"],
  components: {
    DxDateBox,
  },
  data: () => {
    return {
      myclass: "",
      firstTime: null,
      secondTime: null,
      valid: false,
      showError: false,
    };
  },

  computed: {
    ...mapGetters("notifications", [
      "getCurrentEditItem",
      "getCustomTimeDailog",
    ]),
    ...mapGetters("profile", ["getCompanyProfile"]),
    ...mapGetters({
      getCreateYardLoading: "yards/getCreateYardLoading",
      get_alert_box_text: "yards/get_alert_box_text",
      getdetails: "yards/getdetails",
    }),
    fromTime: {
      get() {
        let data = null;
        switch (this.getCurrentEditItem) {
          case "Driver Accept Load":
            if (this.getCompanyProfile.time_sc_accepted) {
              let init = JSON.parse(this.getCompanyProfile.time_sc_accepted);
              const timeMoment = moment(init[0], "h:mm A");
              data = timeMoment;
              // return timeMoment;
            }
            break;
          case "Driver Reject Load":
            if (this.getCompanyProfile.time_sc_rejected) {
              let init = JSON.parse(this.getCompanyProfile.time_sc_rejected);
              const timeMoment = moment(init[0], "h:mm A");
              data = timeMoment;
            }
            break;
          case "Low Customer Activity":
            if (this.getCompanyProfile.time_low_customer_activity) {
              let init = JSON.parse(
                this.getCompanyProfile.time_low_customer_activity
              );
              const timeMoment = moment(init[0], "h:mm A");
              data = timeMoment;
            }
            break;
          case "DO Recived From Customer":
            if (this.getCompanyProfile.time_do_recived_from_customer) {
              let init = JSON.parse(
                this.getCompanyProfile.time_do_recived_from_customer
              );
              const timeMoment = moment(init[0], "h:mm A");
              data = timeMoment;
            }
            break;
          case "DO Recived From Shifl":
            if (this.getCompanyProfile.time_do_recived_from_shifl) {
              let init = JSON.parse(
                this.getCompanyProfile.time_do_recived_from_shifl
              );
              const timeMoment = moment(init[0], "h:mm A");
              data = timeMoment;
            }

            break;
          case "Container Available":
            if (this.getCompanyProfile.time_container_available) {
              let init = JSON.parse(
                this.getCompanyProfile.time_container_available
              );
              const timeMoment = moment(init[0], "h:mm A");
              data = timeMoment;
            }

            break;
          case "Container Discharged":
            if (this.getCompanyProfile.time_container_discharged) {
              let init = JSON.parse(
                this.getCompanyProfile.time_container_discharged
              );
              const timeMoment = moment(init[0], "h:mm A");
              data = timeMoment;
            }

            break;
          case "LFD Issued":
            if (this.getCompanyProfile.time_lfd_issued) {
              let init = JSON.parse(this.getCompanyProfile.time_lfd_issued);
              const timeMoment = moment(init[0], "h:mm A");
              data = timeMoment;
              // return timeMoment;
            }

            break;
          case "ETA Changed Alert":
            if (this.getCompanyProfile.time_eta_changed_alert) {
              let init = JSON.parse(
                this.getCompanyProfile.time_eta_changed_alert
              );
              const timeMoment = moment(init[0], "h:mm A");
              data = timeMoment;
            }
            break;
          default:
            if (this.getCompanyProfile.time_lfd_reminder) {
              let init = JSON.parse(this.getCompanyProfile.time_lfd_reminder);
              const timeMoment = moment(init[0], "h:mm A");
              data = timeMoment;
            }
            break;
        }
        return data;
      },

      set(value) {
        this.updateTime("firstTime", value);
        return value;
      },
    },
    toTime: {
      get() {
        let data = null;
        switch (this.getCurrentEditItem) {
          case "Driver Accept Load":
            if (this.getCompanyProfile.time_sc_accepted) {
              let init = JSON.parse(this.getCompanyProfile.time_sc_accepted);
              const timeMoment = moment(init[1], "h:mm A");
              data = timeMoment;
              // return timeMoment;
            }
            break;
          case "Driver Reject Load":
            if (this.getCompanyProfile.time_sc_rejected) {
              let init = JSON.parse(this.getCompanyProfile.time_sc_rejected);
              const timeMoment = moment(init[1], "h:mm A");
              data = timeMoment;
              // return timeMoment;
            }
            break;
          case "Low Customer Activity":
            if (this.getCompanyProfile.time_low_customer_activity) {
              let init = JSON.parse(
                this.getCompanyProfile.time_low_customer_activity
              );
              const timeMoment = moment(init[1], "h:mm A");
              data = timeMoment;
              // return timeMoment;
            }
            break;
          case "DO Recived From Customer":
            if (this.getCompanyProfile.time_do_recived_from_customer) {
              let init = JSON.parse(
                this.getCompanyProfile.time_do_recived_from_customer
              );
              const timeMoment = moment(init[1], "h:mm A");
              data = timeMoment;
            }
            break;
          case "DO Recived From Shifl":
            if (this.getCompanyProfile.time_do_recived_from_shifl) {
              let init = JSON.parse(
                this.getCompanyProfile.time_do_recived_from_shifl
              );
              const timeMoment = moment(init[1], "h:mm A");
              data = timeMoment;
            }

            break;
          case "Container Available":
            if (this.getCompanyProfile.time_container_available) {
              let init = JSON.parse(
                this.getCompanyProfile.time_container_available
              );
              const timeMoment = moment(init[1], "h:mm A");
              data = timeMoment;
            }

            break;
          case "Container Discharged":
            if (this.getCompanyProfile.time_container_discharged) {
              let init = JSON.parse(
                this.getCompanyProfile.time_container_discharged
              );
              const timeMoment = moment(init[1], "h:mm A");
              data = timeMoment;
            }

            break;
          case "LFD Issued":
            if (this.getCompanyProfile.time_lfd_issued) {
              let init = JSON.parse(this.getCompanyProfile.time_lfd_issued);
              const timeMoment = moment(init[1], "h:mm A");
              data = timeMoment;
            }

            break;
          case "ETA Changed Alert":
            if (this.getCompanyProfile.time_eta_changed_alert) {
              let init = JSON.parse(
                this.getCompanyProfile.time_eta_changed_alert
              );
              const timeMoment = moment(init[1], "h:mm A");
              return timeMoment;
            }
            break;
          default:
            if (this.getCompanyProfile.time_lfd_reminder) {
              let init = JSON.parse(this.getCompanyProfile.time_lfd_reminder);
              const timeMoment = moment(init[1], "h:mm A");
              data = timeMoment;
            }
            break;
        }
        return data;
      },
      set(value) {
        this.updateTime("secondTime", value);
        return value;
      },
    },
  },

  methods: {
    ...mapActions("notifications", ["updateNotificationTime"]),
    ...mapMutations("notifications", ["SET_CUSTOM_TIME_DAILOG"]),
    updateTime(item, val) {
      this[item] = val;
    },
    close() {
      this.SET_CUSTOM_TIME_DAILOG(false);
      // this.$emit("close");
    },
    closeForm() {
      if (this.getCreateYardLoading) {
        this.SET_CUSTOM_TIME_DAILOG(false);
        // this.$emit("close");
      }
    },

    updateNotification() {
      if (!this.fromTime && !this.toTime) {
        if (!this.firstTime || !this.secondTime) {
          this.showError = true;
          return;
        }
      }
      let fromHr = moment(
        this.firstTime ? this.firstTime : this.fromTime
      ).format("hh:mm A");
      let toHr = moment(this.secondTime ? this.secondTime : this.toTime).format(
        "hh:mm A"
      );

      let form = new FormData();
      form.append("period", JSON.stringify([fromHr, toHr]));
      form.append("custom_time", true);

      switch (this.getCurrentEditItem) {
        case "Driver Accept Load":
          form.append("type", "sc_accepted");

          break;
        case "Driver Reject Load":
          form.append("type", "sc_rejected");

          break;
        case "Low Customer Activity":
          form.append("type", "low_customer_activity");

          break;
        case "DO Recived From Customer":
          form.append("type", "do_recived_from_customer");

          break;
        case "DO Recived From Shifl":
          form.append("type", "do_recived_from_shifl");

          break;
        case "Container Available":
          form.append("type", "container_available");

          break;
        case "Container Discharged":
          form.append("type", "container_discharged");

          break;
        case "LFD Issued":
          form.append("type", "LFD_issued");
          break;
        case "ETA Changed Alert":
          form.append("type", "eta_changed_alert");
          break;
        default:
          form.append("type", "lfd_reminder");
          break;
      }
      this.updateNotificationTime(form);
      this.fromTime = null;
      this.toTime = null;
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #b4cfe0;
  border-width: 1px;
}
</style>
<style lang="scss">
.notification-user-dailog {
  .email-users .v-input__slot .v-label {
    color: #4a4a4a;
    font-size: 14px;
    font-family: "Inter-Regular";
  }
}
</style>
