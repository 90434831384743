import axios from "axios";
import CustomStore from 'devextreme/data/custom_store';
// import DataSource from 'devextreme/data/data_source';
import 'whatwg-fetch';
const baseUrl = process.env.VUE_APP_PO_URL;
import globalMethods from '../../../../../utils/globalMethods'
import moment from "moment";
import jQuery, {  } from "jquery";
import { on } from "devextreme/events";
import _, { } from 'lodash'

const compactViewMethods = {
    copyToClipboardMethod: (data, isFor) => {
        // let text = data.value
        let text = data.column.caption === "Customer" ? data.text : data.value
        navigator.clipboard.writeText(text).then(() => {
            let textFor = isFor
            globalMethods.notificationCustom(`${textFor} has been copied.`)
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
        });
    },
    displayDataValueMethod(e) {
        if (e.value !== '' && e.text === '') {
            return e.value
        } else {
            if (e.text !== '') {
                return e.text
            }
        }
    },
}

/** Variables Here */
let pendingClick;
let cellCounter = 0;
let cellUpdateFieldValue = {
    Container: {
        field: 'trucker_container',
        model: '',
        display_name: 'Container'
    },
    MBL: {
        field: 'mbl_num',
        model: '',
        display_name: 'MBL'
    },
    SizeID: {
        field: 'container_size_id',
        model: '',
        display_name: 'Size'
    },
    CustomerID: {
        field: 'customer_id',
        model: '',
        display_name: 'Customer'
    },
    CarrierID: {
        field: 'carrier_id',
        model: '',
        display_name: 'Carrier'
    },
    Eta: {
        field: 'eta',
        model: '',
        display_name: 'ETA'
    },
    Lfdp: {
        field: 'last_free_date',
        model: '',
        display_name: 'LFD P'
    },
    Lfdr: {
        field: 'per_diem_date',
        model: '',
        display_name: 'LFD R'
    },
    LfdpSched: {
        field: 'last_free_date',
        model: '',
        display_name: 'LFD P'
    },
    LfdrSched: {
        field: 'per_diem_date',
        model: '',
        display_name: 'LFD R'
    },
    ConsigneeName: {
        field: 'consignee_id',
        model: '',
        display_name: 'Consignee'
    },
    discharged: {
        field: 'discharged',
        model: '',
        display_name: 'Discharged'
    },
    released: {
        field: 'fully_released',
        model: '',
        display_name: 'Released'
    },
    gate_fees: {
        field: 'pier_pass_paid',
        model: '',
        display_name: 'Gate Fees'
    },
    Location: {
        field: 'terminal_id', // or location_at?
        model: '',
        display_name: 'Current Location'
    },
    EmptyNotified: {
        field: '',
        model: 'App\\Shipment',
        display_name: 'Empty Notified'
    },
    Scheduled: {
        field: '',
        model: "App\\Shipment",
        display_name: 'Scheduled'
    },
    LocationDateTime: {
        field: 'pickup_time',
        model: '',
        display_name: 'Pickup Time'
    },
    DeliveryDateTime: {
        field: 'delivery_time',
        model: '',
        display_name: 'Delivery Time'
    },
    Driver: {
        field: 'driver',
        model: '',
        display_name: 'Driver'
    }
};

// common functions - start
export function getDateFormatFunction(date) {
    return convertDateFormatDefault(date)
}

export function convertDateFormatDefault(date) {
    if (date !== '' && date !== null) {
        return moment(date).format('MM/DD/YY')
    } else {
        return 'N/A'
    }
}

export function moveArrayIndex(arr, from, to) {
    return arr.map((item, i) => i === to ? arr[from] :
        (i >= Math.min(from, to) && i <= Math.max(from, to) ?
        arr[i + Math.sign(to - from)] : item));
}

export function addDays(dateTime, count_days) { // for adding days to date passed in params method
    return new Date(new Date(dateTime).setDate(dateTime.getDate() + count_days));
}

export function subtractDays(dateTime, count_days) {
    return new Date(new Date(dateTime).setDate(dateTime.getDate() - count_days));
}

export function timeConvertFunction(dateString) {
    const d = dateString;
    const validHHMMstring = (str) => /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(str);

    if (validHHMMstring(d)) {
        const timeString12hr = new Date("1970-01-01T" + d + "Z").toLocaleTimeString("en-US", {
            timeZone: "UTC",
            hour12: true,
            hour: "numeric",
            minute: "numeric",
        });
        return timeString12hr.replace(/\s/g, "");
    } else {
        return "";
    }
}

export function getDateTimeFinalDisplay(date, time) {
    let finalDisplay = null

    if (date !== '') {
        if (time !== '' || time !== null) {
            finalDisplay = date + ' ' + time
        } else {
            finalDisplay = date
        }
    } else {
        finalDisplay = null
    }

    return finalDisplay
}

function formatDateISO(date) {
    var datetime = new Date(date);
    var now = datetime.toISOString().replace('Z', ' ').replace('T', ' ');
    return moment(now).format("MM/DD/YY")
}

export function getPrepullDataStatus(data) {
    let dispatch_schedule = null;
    try {
        dispatch_schedule = JSON.parse(data.dispatch_schedule);
    } catch (err) {
        dispatch_schedule = null;
    }
    let legs = dispatch_schedule ? dispatch_schedule.legs || [] : [];
    if (legs.length > 0) {
        if (legs[0].type) {
            if (legs[0].type == 'Customer') return 'Direct'
            else return 'Prepull'
        }
    } else {
        return "N/A"
    }
    return "N/A";
}

export function getContainerMode(data, prop) {
    if (data !== null) {
        if (prop.currentSelectTab === "Scheduled") {
            if (typeof data.dispatch !== "undefined") {
                // let getfirst = _.first(data.dispatch.legs);
                let getfirst = data.dispatch.first_unarrived_leg;
                let fobject = getfirst;  

                return fobject.mode ? (fobject.mode == "Dropped" ? "Drop" : fobject.mode) : "";	
            }
        } else {
            let scheds = null;
            let mode = "N/A";

            try {
                scheds = JSON.parse(data.dispatch_schedule);
            } catch (err) {
                scheds = null;
            }
            if (scheds !== null) {
                scheds.legs.map((value) => {
                if (value.completed) {
                    mode = value.mode === "Live" ? "Live" : "Drop";
                }
                });
            }
            return mode;
        }
    }				
    return ""
}

export function getTypeDelivery(item, prop) {
    if (item !== null) {
        if (prop.currentSelectTab === "Scheduled") {
            if (typeof item.dispatch !== "undefined") {
                let getfirst = _.first(item.dispatch.legs);
                let fobject = getfirst;  

                return fobject.type == "Empty Return" ? "To Port" : `To ${fobject.type}`;
            }
        }
        return ""
    }
}

export function getAllTabStatus(item, prop) {
    let status = ""
    if (prop.currentSelectTab === "All") {
        if (item !== null && (typeof item.tab_status !== "undefined" && item.tab_status !== null)) {
            if (item.tab_status !== "") {
                status = item.tab_status.replace(/[_-]/g, x => ({'_': ' ', '-': ' - '})[x]).replace("the", '');
            }
        }
    }
    return status
}

export function formatTabStatus(status) {
    let statusFormat = "";
    if (status !== "") {
        statusFormat = status.replace(/[_-]/g, x => ({'_': ' ', '-': ' - '})[x]).replace("the", '');
    }
    return statusFormat
}

export function getLastArrivedLegCustomer(item) {
    let dispatch_schedule = null;
    try {
        dispatch_schedule = JSON.parse(item.dispatch_schedule);
    } catch (err) {
        dispatch_schedule = null;
    }

    let legs = dispatch_schedule ? dispatch_schedule.legs || [] : [];

    if (legs.length > 0) {
        for (let i = legs.length - 1; i >= 0; i--) {
            if (legs[i].completed && legs[i].type === "Customer") {
                return legs[i];
            }
        }
    }

    return null;
}

export function getFirstLegArrivalPickup(item) {
    let dispatch_schedule = null;
    try {
        dispatch_schedule = JSON.parse(item.dispatch_schedule);
    } catch (err) {
        dispatch_schedule = null;
    }

    let legs = dispatch_schedule ? dispatch_schedule.legs || [] : [];

    if (legs.length > 0) {
        for (let i = 0; i < legs.length; i++) {
            if (legs[i].completed && (legs[i].type === "Port" || legs[i].type === "Empty Return")) {
                return legs[i];
            }
        }
    }

    return null;
}

export function getClearnDispatch(item) {
    var decodeleg = typeof item === 'string' ? JSON.parse(item) : item;
    let filtered_array = _.filter(decodeleg.legs, function(o) { return !o.completed; });
    let c = _.first(filtered_array)
    return c;
}
// common functions - end

export function GetStore(url, prop) { // calling API using devExtreme Custom Store
	var data = {
		key: 'id',
		load(loadOptions) {
			let params = '';
			[   'skip',
				'take',
				'requireTotalCount',
				'requireGroupCount',
				'sort',
				'filter',
				'totalSummary',
				'group',
				'groupSummary',
                'isFilter',
                'compact_view',
                'search',
                'filterData',
                "tabStatus"
			].forEach((i) => {
				if (typeof loadOptions['filter'] !== "undefined" && loadOptions['filter'] !== null) {
					if (loadOptions['filter'].length > 0) {
						loadOptions['filter'].filter(removeValue);
                        loadOptions['isFilter'] = true

                        let filterData = loadOptions['filter'];

                        if (loadOptions['filter'].length === 1) {
                            filterData.map(v => {
                                if (v.field === "deliveryorder.shifl_ref") {
                                    if (v.value.toLowerCase().includes("do")) {
                                        let newStr = v.value.substr(2, v.value.length);
                                        v.value = newStr;
                                    }
                                }

                                if (v.field === "is_export") {
                                    if (v.value.toString().toLowerCase().includes("im")) {
                                        v.value = 0;
                                    } else if (v.value.toString().toLowerCase().includes("ex")) {
                                        v.value = 1;
                                    }
                                }

                                if (v.field === "dispatch.firstUnarrivedLeg.pickup_scheduled_date_time") {
                                    // let convertVal = moment(v.value).format("YYYY-MM-DD HH:mm:ss"); // date and time
                                    let convertVal = moment(v.value).format("YYYY-MM-DD");
                                    v.value = convertVal;
                                }

                                if (v.field === "dispatch.firstUnarrivedLeg.delivery_scheduled_date_time") {
                                    // let convertVal = moment(v.value).format("YYYY-MM-DD HH:mm:ss"); // date and time
                                    let convertVal = moment(v.value).format("YYYY-MM-DD");
                                    v.value = convertVal;
                                }

                                if (v.field === "prepull_status") {
                                    if (v.value === "Prepull") {
                                        let portArr = [{
                                            field: "dispatch.legs.type",
                                            operation: "contains",
                                            value: "Empty Return"
                                        }]
    
                                        let yardArr = [{
                                            field: "dispatch.legs.type",
                                            operation: "contains",
                                            value: "Yard"
                                        }]
                                        
                                        filterData = [...portArr, ...yardArr];
                                    } else {
                                        v.field = "dispatch.legs.type";
                                    }
                                }

                                /* eslint-disable */
                                if (v.field === "arrived_at") {
                                    let date = moment(v.value).format("YYYY-MM-DD");
                                    // let time = moment(v.value).format("HH:mm");
                                    let dateArr = [{
                                        field: "dispatch_schedule",
                                        operation: "contains",
                                        value: `\"arrival_at_delivery_location_date\":\"${date}`
                                    }]
                                    // let timeArr = [{
                                    //     field: "dispatch_schedule",
                                    //     operation: "contains",
                                    //     value: `\"arrival_at_delivery_location_time\":\"${time}`
                                    // }]
                                    // do not include the field arrived at
                                    // filterData = [...dateArr, ...timeArr ]
                                    filterData = [...dateArr ]
                                }

                                if (v.field === "picked_up") {
                                    let date = moment(v.value).format("YYYY-MM-DD");
                                    let dateArr = []
                                    
                                    if (prop.currentSelectTab !== "Completed") {
                                        // let time = moment(v.value).format("HH:mm");
                                        dateArr.push({
                                            field: "dispatch_schedule",
                                            operation: "contains",
                                            value: `\"pickup_scheduled_date_time\":\"${date}`
                                        })
                                        // let timeArr = [{
                                        //     field: "dispatch_schedule",
                                        //     operation: "contains",
                                        //     value: `\"arrival_at_delivery_location_time\":\"${time}`
                                        // }]
                                        // do not include the field arrived at
                                        // filterData = [...dateArr, ...timeArr ]
                                    } else {
                                        dateArr.push({
                                            field: "dispatch_schedule",
                                            operation: "contains",
                                            value: `\"arrival_at_pickup_location_date_time\":\"${date}`
                                        })
                                    }

                                    filterData = [...dateArr ]
                                }

                                if (v.field === "delivered_on") {
                                    let date = moment(v.value).format("YYYY-MM-DD");
                                    let dateArr = []
                                    
                                    if (prop.currentSelectTab !== "Completed") {
                                        // let time = moment(v.value).format("HH:mm");
                                        dateArr.push({
                                            field: "dispatch_schedule",
                                            operation: "contains",
                                            value: `\"delivery_scheduled_date_time\":\"${date}`
                                        })
                                        // let timeArr = [{
                                        //     field: "dispatch_schedule",
                                        //     operation: "contains",
                                        //     value: `\"arrival_at_delivery_location_time\":\"${time}`
                                        // }]
                                        // do not include the field arrived at
                                        // filterData = [...dateArr, ...timeArr ]
                                    } else {
                                        dateArr.push({
                                            field: "dispatch_schedule",
                                            operation: "contains",
                                            value: `\"arrival_at_delivery_location_date_time\":\"${date}`
                                        })
                                    }

                                    filterData = [...dateArr ]
                                }

                                if (v.field === "empty_returned") {
                                    let date = moment(v.value).format("YYYY-MM-DD");
                                    let dateArr = []
                                    
                                    if (prop.currentSelectTab !== "Completed") {
                                        // let time = moment(v.value).format("HH:mm");
                                        dateArr.push({
                                            field: "dispatch_schedule",
                                            operation: "contains",
                                            value: `\"container_empty_date\":\"${date}`
                                        })
                                        // let timeArr = [{
                                        //     field: "dispatch_schedule",
                                        //     operation: "contains",
                                        //     value: `\"arrival_at_delivery_location_time\":\"${time}`
                                        // }]
                                        // do not include the field arrived at
                                        // filterData = [...dateArr, ...timeArr ]
                                    } else {
                                        dateArr.push({
                                            field: "dispatch_schedule",
                                            operation: "contains",
                                            value: `\"arrival_at_delivery_location_date\":\"${date}`
                                        })
                                    }

                                    filterData = [...dateArr ]
                                }

                                if (v.field === "tabStatus") {
                                    loadOptions['tabStatus'] = v.value;
                                    loadOptions['filter'] = null;
                                    loadOptions['isFilter'] = null;
                                    filterData = null;
                                }
                            })
                            loadOptions['filterData'] = filterData;
                        } else {
                            if (loadOptions['filter'].length > 1) {
                                let newArray = []

                                filterData.map(v => {
                                    v.map(a => {
                                        if (a.field === "deliveryorder.shifl_ref") {
                                            if (a.value.toLowerCase().includes("do")) {
                                                let newStr = a.value.substr(2, a.value.length);
                                                a.value = newStr
                                            }
                                        }
        
                                        if (a.field === "is_export") {
                                            if (a.value.toString().toLowerCase().includes("im")) {
                                                a.value = 0
                                            } else if (a.value.toString().toLowerCase().includes("ex")) {
                                                a.value = 1
                                            }
                                        }

                                        if (a.field === "dispatch.firstUnarrivedLeg.pickup_scheduled_date_time") {
                                            let convertVal = moment(a.value).format("YYYY-MM-DD HH:mm:ss");
                                            a.value = convertVal;
                                        }
        
                                        if (a.field === "dispatch.firstUnarrivedLeg.delivery_scheduled_date_time") {
                                            let convertVal = moment(a.value).format("YYYY-MM-DD HH:mm:ss");
                                            a.value = convertVal;
                                        }

                                        if (a.field === "prepull_status") {
                                            if (a.value === "Prepull") {
                                                a.field = "dispatch.legs.type";
                                                a.value = "Yard";
                                                v.push({
                                                    field: "dispatch.legs.type",
                                                    operation: "contains",
                                                    value: "Empty Return"
                                                })
                                            } else {
                                                a.field = "dispatch.legs.type";
                                            }
                                        }

                                        /* eslint-disable */
                                        if (a.field === "arrived_at") {
                                            let date = moment(a.value).format("YYYY-MM-DD");
                                            // let time = moment(a.value).format("HH:mm");
                                            // let dateArr = [{
                                            //     field: "dispatch_schedule",
                                            //     operation: "contains",
                                            //     value: `\"arrival_at_delivery_location_date\":\"${date}`
                                            // }]
                                            // a.field = "dispatch_schedule";
                                            // a.operation = "contains";
                                            // a.value = `\"arrival_at_delivery_location_time\":\"${time}`;

                                            a.field = "dispatch_schedule";
                                            a.operation = "contains";
                                            a.value = `\"arrival_at_delivery_location_date\":\"${date}`;
                                            // newArray = [...newArray, ...dateArr];
                                        }

                                        if (a.field === "picked_up") { // if includes time, same as arrived at
                                            let date = moment(a.value).format("YYYY-MM-DD");
                                            a.field = "dispatch_schedule";
                                            a.operation = "contains";
                                            
                                            if (prop.currentSelectTab !== "Completed") {                                                
                                                a.value = `\"pickup_scheduled_date_time\":\"${date}`;
                                            } else {
                                                a.value = `\"arrival_at_pickup_location_date_time\":\"${date}`;
                                            }
                                        }

                                        if (a.field === "delivered_on") { // if includes time, same as arrived at
                                            let date = moment(a.value).format("YYYY-MM-DD");
                                            a.field = "dispatch_schedule";
                                            a.operation = "contains";
                                            
                                            if (prop.currentSelectTab !== "Completed") {                                                
                                                a.value = `\"delivery_scheduled_date_time\":\"${date}`;
                                            } else {
                                                a.value = `\"arrival_at_delivery_location_date_time\":\"${date}`;
                                            }
                                        }

                                        if (a.field === "empty_returned") {
                                            let date = moment(a.value).format("YYYY-MM-DD");
                                            a.field = "dispatch_schedule";
                                            a.operation = "contains";

                                            if (prop.currentSelectTab !== "Completed") {                                                
                                                a.value = `\"container_empty_date\":\"${date}`;
                                            } else {
                                                a.value = `\"arrival_at_delivery_location_date\":\"${date}`;
                                            }
                                        }

                                        if (a.field === "tabStatus") {
                                            loadOptions['tabStatus'] = a.value;
                                        }

                                        if (a.field !== "tabStatus") {
                                            newArray.push(a)
                                        }                                        
                                    })
                                })

                                loadOptions['filterData'] = newArray;
                            }
                        }
					}
				}

                loadOptions['compact_view'] = true;

				if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                    // params += `${i}=${JSON.stringify(loadOptions[i])}&`; 
                    params += `${i}=${(i !== 'tabStatus' ? JSON.stringify(loadOptions[i]) : loadOptions[i])}&`;
				}
			});
			params = params.slice(0, -1);
            
			return axios.get(`${url}&${params}`)
			.then(res => {
				if (res.data) {
                    let tabStatus = { hasTabStatus: false, status: "" };
                    prop.previousTab = prop.currentSelectTab;

                    if (typeof loadOptions['tabStatus'] !== "undefined") {
                        tabStatus.hasTabStatus = true;
                        tabStatus.status = loadOptions['tabStatus'];
                    }

                    let processedData = processData(res.data.results.data, prop, tabStatus);

                    return {
						data: processedData,
						totalCount: res.data.results.total,
						summary: res.data.summary,
						groupCount: res.data.last_page,
					}

                    // if(typeof res.data.components !== "undefined") {
					// 	prop.magdropterminal = res.data.components.loccationfilter;
					// }

					// if (typeof res.data.driversDropdown !== "undefined") {
					// 	prop.magdriverFilter = res.data.driversDropdown;
					// }
                    
					// // prop.page = page;
					// prop.lastPage = res.data.results.last_page;

					// if (prop.pageCount !== res.data.results.last_page) {
					// 	prop.pageCount = res.data.results.last_page;
					// }

                    // /* Added by - Praisey */
					// let datum = typeof prop.loadedContainersData[tab] !== 'undefined' ? prop.loadedContainersData[tab].data : []					
					// let pages = typeof prop.loadedContainersData[tab] !== 'undefined' ? prop.loadedContainersData[tab].page : []
					// let index = -1

					// if (pages.length > 0) {
					// 	index = pages.findIndex(p => p === page)
					// }

					// if (index > -1) {
					// 	// replace the existing data from newly fetched data in the API
					// 	datum[index] = res.data.results.data
					// 	pages[index] = page
					// } else {
					// 	// pushed data if not yet saved
					// 	datum.push(res.data.results.data)
					// 	pages.push(page)
					// }
					// /* Added by - Praisey */

					// prop.loadedContainersData[tab] = {
					// 	data: datum,
					// 	page: pages,
					// 	pageCount: res.data.results.last_page,
					// 	lastPage: res.data.results.last_page
					// }
					// prop.newContainersData = res.data.results.data;

					// return {
					// 	data: processData(res.data.results.data, prop),
					// 	totalCount: res.data.results.total,
					// 	summary: res.data.summary,
					// 	groupCount: res.data.results.last_page,
					// }
				}
			})
            .catch(() => {
				throw new Error('Data Loading Error');
			});
		},
        update: (key, values) => {
            if (key !== null && values !== null) {
                let { for_dispatch, ...otherVal } = prop.containerEditedData
                prop.isUpdatingCells = true

                if (!for_dispatch) {
                    return axios.put(`${baseUrl}/edit-index-view`, otherVal)
                    .then(res => {
                        if (res.status === 200) {
                            globalMethods.notificationMessageCustomSuccess(`Container has been updated successfully.`)
                        }
                        prop.isShowLoadingUI = false;
                        prop.shouldLoad = false;
                    })
                    .catch(err => {
                        throw new Error(err);
                    })
                } else {
                    return axios.post(`${baseUrl}/edit-dispach-legs`, otherVal)
                    .then(res => {
                        if (res.status === 200) {
                            globalMethods.notificationMessageCustomSuccess(`Container has been updated successfully.`)
                        }
                        prop.isShowLoadingUI = false;
                        prop.shouldLoad = false;
                    })
                    .catch(err => {
                        throw new Error(err);
                    })
                }
            }
        }
	}

	return new CustomStore(data)
}

export function processData(v, prop, tabStatus) { // function to process data after fetching
    if (v.length > 0) {
        v.map(val => {
            let consingee_name = ''
            let consignee_id = ''
            let do_id = ''
            let schedule_date = ''
            let arrived_at = ''
            let emptyNotified = ''
            let driver = ''
            let driver_id = ''
            let pickupTime = null
            let deliveryTime = null
            let prepullStatus = ""
            let mode = ''
            let typeDelivery = ''
            let status = ''

            consingee_name = val.deliveryorder !== null && val.deliveryorder.consignee !== null
                ? val.deliveryorder.consignee.name : ''

            consignee_id = val.deliveryorder !== null && val.deliveryorder.consignee_id !== null
                ? val.deliveryorder.consignee_id : null

            do_id = val.deliveryorder !== null && val.deliveryorder.shifl_ref !== null
                ? val.deliveryorder.shifl_ref : 'N/A'

            if (prop.currentSelectTab === 'At the Customer') {
                schedule_date = prop.getAtTheCustomerScheduledDate(val)
                arrived_at = prop.getATCTimeArrived(val)
            } else {
                schedule_date = prop.getDefaultScheduledDate(val)
                arrived_at = prop.getTimeArrived(val)
            }

            // for delivery time
            if (prop.currentSelectTab !== 'Completed') {
                deliveryTime = prop.deliveryon(val, 'time')
                emptyNotified = prop.getEmptyNotified(val)
                pickupTime = prop.pickupOn(val, val, 'time')
            } else {
                let lastArrivedLeg = prop.getLastArrivedLeg(val)
                let lastArrivedToCustomer = getLastArrivedLegCustomer(val)
                let firstArrivedLeg = getFirstLegArrivalPickup(val)

                if (lastArrivedLeg !== null) { // for empty return
                    let date = getDateFormatFunction(lastArrivedLeg.arrival_at_delivery_location_date)
                    let time = prop.convertDefaultTime(lastArrivedLeg.arrival_at_delivery_location_time)
                    let finalDisplay = getDateTimeFinalDisplay(date, time)
                    emptyNotified = finalDisplay
                }

                if (lastArrivedToCustomer !== null) { // for delivered on
                    let date = getDateFormatFunction(lastArrivedToCustomer.arrival_at_delivery_location_date)
                    let time = prop.convertDefaultTime(lastArrivedToCustomer.arrival_at_delivery_location_time)
                    let finalDisplay = getDateTimeFinalDisplay(date, time)
                    deliveryTime = finalDisplay
                }

                if (firstArrivedLeg !== null) { // for pickup time
                    let date = getDateFormatFunction(firstArrivedLeg.arrival_at_pickup_location_date)
                    let time = prop.convertDefaultTime(firstArrivedLeg.arrival_at_pickup_location_time)
                    let finalDisplay = getDateTimeFinalDisplay(date, time)
                    pickupTime = finalDisplay
                }							
            }
            
            driver = prop.getAssignedToDriver(val, val).driver_name
            driver_id = prop.getAssignedToDriver(val, val).driver_id
            // prepullStatus = prop.getDirectPrepullStatus(val)
            prepullStatus = getPrepullDataStatus(val)
            mode = getContainerMode(val, prop)
            typeDelivery = getTypeDelivery(val, prop)
            status = tabStatus.hasTabStatus ? formatTabStatus(tabStatus.status) : getAllTabStatus(val, prop)
                
            val.ID = val.id;
            val.Container = val.trucker_container !== null ? val.trucker_container : 'N/A';
            val.SizeID = val.container_size_id !== null && val.container_size_id !== 0 ? val.container_size_id : '';
            val.MBL = val.mbl_num !== null ? val.mbl_num : 'N/A';
            val.DO = do_id;
            val.Ref = val.shifl_ref !== null ? val.shifl_ref : 'N/A';
            val.CustomerID = val.customer_id !== null ? val.customer_id : '';
            val.CarrierID = val.carrier_id !== null ? val.carrier_id : 'N/A';
            val.ConsigneeName = consingee_name;
            val.ConsigneeID = consignee_id;
            val.Eta = val.eta !== null ? val.eta : '';
            val.Lfdr = val.per_diem_date !== null && prop.checkifValid(val.per_diem_date) ? getDateFormatFunction(val.per_diem_date): '';
            val.Lfdp = val.last_free_date !== null && prop.checkifValid(val.last_free_date) ? getDateFormatFunction(val.last_free_date) : '';
            val.discharged = val.discharged == 1 ? true : false;
            val.released = val.fully_released == 1 ? true : false;
            val.gate_fees = val.pier_pass_paid == 1 ? true : false;
            val.Type = val.is_export === 1 ? 'Export' : 'Import';
            val.Scheduled = schedule_date;
            val.ArrivedAt = arrived_at;
            val.EmptyNotified = emptyNotified;
            val.DeliveryLocation = '';
            val.Driver = driver;
            val.DriverID = driver_id;
            val.Status = '';
            val.LocationDateTime = pickupTime;
            val.DeliveryDateTime = deliveryTime;
            val.DirectPrepull = prepullStatus;
            // val.Opened = val.opened_at !== null ? getDateFormatFunction(val.opened_at) : "";
            // val.Closed = val.closed_at !== null ? getDateFormatFunction(val.closed_at) : "";
            val.Opened = val.opened_at !== null ? formatDateISO(val.opened_at) : "";
            val.Closed = val.closed_at !== null ? formatDateISO(val.closed_at) : "";
            val.Mode = mode;
            val.TypeDelivery = typeDelivery;
            val.AllTabStatus = status;
        })
    }
    return v
}

export function isNotEmpty(value) { // check for empty filter data parameter
    return value !== undefined && value !== null && value !== '';
}

export function removeValue(value, index, arr) { // to remove the AND in filter data parameter
    // If the value at the current array index matches the specified value (2)
    if (value === "and") {        
        arr.splice(index, 1); // Removes the value from the original array
        return true;
    }
    return false;
}

// function for customizing filter data
export function calculateFilterExpressionFunction(filterValue, selectedFilterOperations, target, fieldName, dataFromTable) {
    if (target === "filterRow") {
        var filterExpression = [
            { 
                "field": fieldName,
                "operation": selectedFilterOperations,
                "value": filterValue
            },
        ];
        return filterExpression;
    }
    return dataFromTable.defaultCalculateFilterExpression.apply(dataFromTable, arguments);
}

/** DATAGRID FUNCTIONS STARTS HERE */
export function onCellPreparedFunction(e, prop) {
    if (e.rowType !== "data") {
        return;
    } else if (e.rowType === "data") {
        var self = prop;

        if (e.column.dataField !== "Ref" && e.column.dataField !== "DO" && e.column.caption !== 'Action' &&
            e.column.dataField !== 'Type' && e.column.caption !== "Picked Up" && e.column.caption !== "Delivered On" && 
            e.column.caption !== "Empty Returned" && e.column.dataField !== "DirectPrepull" && e.column.caption !== "Opened" &&
            e.column.caption !== "Closed" && e.column.caption !== "Mode" && e.column.caption !== "Status" && 
            e.column.caption !== "Pickup Location" && e.column.caption !== "Pickup Time" && e.column.caption !== "Delivery On" &&
            e.column.caption !== "Delivery Time") {
                
            let today = new Date()
            let todayConvert = getDateFormatFunction(today)

            let defaultBg = "#FFFFFF"
            let color = "#4a4a4a"
            let border = ""
            let className = 'is-default'

            // Start - for changing background colors
            if (e.column.dataField === "Lfdp" || e.column.dataField === "Lfdr") {
                if (!prop.isTabLFDCaption) {
                    let data = e.data
                    let dispatch_schedule = data.dispatch_schedule !== null ? JSON.parse(data.dispatch_schedule) : null

                    if (dispatch_schedule !== null) {
                        let legs = dispatch_schedule.legs || []
                        let leg = null
                        let legIndex = null

                        try {
                            if (legs.length > 0) {
                                leg = legs.find(leg => !leg.completed)

                                if (leg !== undefined) {
                                    let index = _.findIndex(legs, e => e.id === leg.id)
                                    legIndex = index + 1
                                }
                            }
                        } catch (e) {
                            console.log(e, 'error');
                        }

                        let lfdDate = e.column.dataField === "Lfdp" ? e.data.Lfdp : e.data.Lfdr

                        if (e.column.dataField === "Lfdp") {
                            if (legIndex === 1) {
                                if (moment(lfdDate).isSame(todayConvert)) {
                                    defaultBg = "#D68A1A"
                                    color = "#ffffff"
                                    border = "#EBF2F5"
                                    className = 'is-yellow-bg'
                                } else if (moment(todayConvert).isAfter(lfdDate)) {
                                    defaultBg = "#F93131"
                                    color = "#ffffff"
                                    border = "#EBF2F5"
                                    className = 'is-red-bg'
                                }
                            }
                        } else {
                            if (legIndex > 1) {
                                if (moment(lfdDate).isSame(todayConvert)) {
                                    defaultBg = "#D68A1A"
                                    color = "#ffffff"
                                    border = "#EBF2F5"
                                    className = 'is-yellow-bg'
                                } else if (moment(todayConvert).isAfter(lfdDate)) {
                                    defaultBg = "#F93131"
                                    color = "#ffffff"
                                    border = "#EBF2F5"
                                    className = 'is-red-bg'
                                }
                            }
                        }
                    }

                    e.cellElement.bgColor = defaultBg // set cell background
                    e.cellElement.style.color = color
                    e.cellElement.style.borderColor = border
                    e.cellElement.classList.add(className);
                }
            }

            if (e.column.dataField === "ArrivedAt") {
                var arrivedDate = getDateFormatFunction(e.data.ArrivedAt)
                let result = ""
                // var new_date = moment(moment(date).fromNow()) - shows days ago

                if (arrivedDate !== "" && arrivedDate !== "N/A") {
                    result = moment(todayConvert).diff(moment(arrivedDate), 'd')

                    if (result > 0) {
                        if (result >= 7 && result <= 14 ) {
                            defaultBg = "#D68A1A"
                            color = "#FFFFFF"
                            border = "#EBF2F5"
                            className = 'is-yellow-bg'
                        } else if (result > 14) {
                            defaultBg = "#F93131"
                            color = "#FFFFFF"
                            border = "#EBF2F5"
                            className = 'is-red-bg'
                        }
                    }
                }
                e.cellElement.bgColor = defaultBg // set cell background
                e.cellElement.style.color = color
                e.cellElement.style.borderColor = border
                e.cellElement.classList.add(className);

                on(e.cellElement, "click", arg => {
                    if (pendingClick) {
                        clearTimeout(pendingClick);
                    }

                    if (arg.originalEvent.detail == 1) {
                        // single click
                        self.$refs[prop.tooltipRefGlobal].instance.hide();
                    } else if (arg.originalEvent.detail == 2) {
                        // double click
                        // self.$refs[prop.tooltipRefNameNoEdit].instance.show(arg.target);
                        self.tooltipMessage = "Non Editable"
                        self.$refs[prop.tooltipRefGlobal].instance.show(arg.target);
                    }
                });
            }

            if (e.column.dataField === "EmptyNotified") {
                if (!prop.isTabLFDCaption) {
                    var notifiedDate = getDateFormatFunction(e.data.EmptyNotified)
                    let result = ""

                    if (notifiedDate !== "") {
                        result = moment(todayConvert).diff(moment(notifiedDate), 'd')

                        if (result > 0) {
                            if (result <= 4) {
                                defaultBg = "#D68A1A"
                                color = "#FFFFFF"
                                border = "#EBF2F5"
                                className = 'is-yellow-bg'
                            } else if (result >= 5) {
                                defaultBg = "#F93131"
                                color = "#FFFFFF"
                                border = "#EBF2F5"
                                className = 'is-red-bg'
                            }
                        }
                    }
                    e.cellElement.bgColor = defaultBg // set cell background
                    e.cellElement.style.color = color
                    e.cellElement.style.borderColor = border
                    e.cellElement.classList.add(className);
                }
            }
            // End - for changing background colors

            if (e.column.dataField === 'discharged' || e.column.dataField === 'released') {
                jQuery(e.cellElement).addClass('checkboxes-td') // set cell class
            }

            if (e.column.dataField === 'gate_fees') {
                jQuery(e.cellElement).addClass('checkboxes-td gate') // set cell class
            }

            if (e.column.dataField === "Scheduled") {
                if (e.value === 'Not Scheduled' || e.text === 'Not Scheduled') {
                    jQuery(e.cellElement).addClass('not-scheduled') // set cell class
                }

                let first_unarrived_leg = prop.getFirstUnArrivedLeg(e.data);

                if (first_unarrived_leg === null) {
                    e.column.allowEditing = false
                    e.cellElement.bgColor = "#F5F9FC" // set cell background

                    if (!e.component._isCellEditing) {
                        e.component._isCellEditing = false;
                        return;
                    }
                } else {
                    on(e.cellElement, "click", arg => {
                        if (pendingClick) {
                            clearTimeout(pendingClick);
                        }

                        if (arg.originalEvent.detail == 1) {
                            // single click
                            // self.$refs[prop.tooltipRefNameNoEdit].instance.hide();
                            self.$refs[prop.tooltipRefGlobal].instance.hide();
                        } else if (arg.originalEvent.detail == 2) {
                            // double click
                            self.tooltipMessage = "Non Editable";
                            self.$refs[prop.tooltipRefGlobal].instance.show(arg.target);
                        }
                    });
                }
            }

            if (e.column.dataField === "Driver") {
                if (e.text === "Unassigned" || e.value === "Unassigned") {
                    jQuery(e.cellElement).addClass('unassigned-driver-td') // set cell class
                }
            }

            if (prop.currentSelectTab === "At Yard" && e.column.caption === "Current Location") {
                on(e.cellElement, "click", arg => {
                    if (pendingClick) {
                        clearTimeout(pendingClick);
                    }

                    if (arg.originalEvent.detail == 1) {
                        // single click
                        // self.$refs[prop.tooltipRefNameNoEdit].instance.hide();
                        self.$refs[prop.tooltipRefGlobal].instance.hide();
                    } else if (arg.originalEvent.detail == 2) {
                        // double click
                        // self.$refs[prop.tooltipRefNameNoEdit].instance.show(arg.target);
                        self.tooltipMessage = "Non Editable";
                        self.$refs[prop.tooltipRefGlobal].instance.show(arg.target);
                    }
                });
            }

            // on click
            // on(e.cellElement, "click", async (arg) => {
            //     if (pendingClick) {
            //         clearTimeout(pendingClick);
            //     }

            //     if (arg.target.id !== 'i-icon-copy') {
            //         if (prop.currentSelectedCell === null) {
            //             prop.currentSelectedCell = e
            //             cellCounter+=1
            //         } else {
            //             if (prop.currentSelectedCell.columnIndex === e.columnIndex &&
            //                 prop.currentSelectedCell.rowIndex === e.rowIndex) {
            //                 let countUntil = 2

            //                 if (cellCounter < countUntil) {
            //                     cellCounter+=1
            //                 }
            //             } else {
            //                 cellCounter = 1
            //                 prop.currentSelectedCell = e
            //             }
            //         }
            //     } else {
            //         cellCounter = 0
            //     }

            //     if (cellCounter === 1) {
            //         if (arg.target.id !== 'i-icon-copy') {
            //             if (e.column.allowEditing) {
            //                 if (e.column.dataField !== 'discharged' && e.column.dataField !== 'released' &&
            //                     e.column.dataField !== 'gate_fees') {
            //                     self.tooltipMessage = "Click To Edit";
            //                     self.$refs[prop.tooltipRefGlobal].instance.show(arg.target);
            //                 } else 
            //                     self.$refs[prop.tooltipRefGlobal].instance.hide();
            //             } else 
            //                 self.$refs[prop.tooltipRefGlobal].instance.hide();
            //         } else 
            //             self.$refs[prop.tooltipRefGlobal].instance.hide();

            //     } else if (cellCounter === 2) {
            //             self.$refs[prop.tooltipRefGlobal].instance.hide();

            //         if (e.column.allowEditing) {
            //             if (!e.component._isCellEditing) {
            //                 if (cellCounter === 2) {
            //                     e.component._isCellEditing = true;
            //                     e.component.editCell(e.rowIndex, e.column.dataField);
            //                     console.log(e, 'hereeeeeee');
            //                 }

            //                 e.component._isCellEditing = false;
            //                 return;
            //             }
            //         }
            //     }
            // });

            on(e.cellElement, "click", async (arg) => {
                if (pendingClick) {
                    clearTimeout(pendingClick);
                }

                if (arg.target.id !== 'i-icon-copy') {
                    if (prop.currentSelectedCell === null) {
                        prop.currentSelectedCell = e
                        cellCounter+=1
                    } else {
                        if (prop.currentSelectedCell.columnIndex === e.columnIndex &&
                            prop.currentSelectedCell.rowIndex === e.rowIndex) {
                            let countUntil = 2

                            if (cellCounter < countUntil) {
                                cellCounter+=1
                            }
                        } else {
                            cellCounter = 1
                            prop.currentSelectedCell = e
                        }
                    }
                } else {
                    cellCounter = 0
                }

                if (cellCounter === 1) {
                    if (arg.target.id !== 'i-icon-copy') {
                        if (e.column.allowEditing) {
                            if (e.column.dataField !== 'discharged' && e.column.dataField !== 'released' &&
                                e.column.dataField !== 'gate_fees') {
                                self.tooltipMessage = "Click To Edit";
                                self.$refs[prop.tooltipRefGlobal].instance.show(arg.target);
                            } else 
                                self.$refs[prop.tooltipRefGlobal].instance.hide();
                        } else 
                            self.$refs[prop.tooltipRefGlobal].instance.hide();
                    } else 
                        self.$refs[prop.tooltipRefGlobal].instance.hide();

                } else if (cellCounter === 2) {
                        self.$refs[prop.tooltipRefGlobal].instance.hide();

                    // if (e.column.allowEditing) {
                    //     if (!e.component._isCellEditing) {
                    //         if (cellCounter === 2) {
                    //             e.component._isCellEditing = true;
                    //             e.component.editCell(e.rowIndex, e.column.dataField);
                    //             console.log(e, 'hereeeeeee');
                    //         }

                    //         e.component._isCellEditing = false;
                    //         return;
                    //     }
                    // }
                }
            });
        } else {
            if (e.column.caption !== 'Action') {
                on(e.cellElement, "click", arg => {
                    if (pendingClick) {
                        clearTimeout(pendingClick);
                    }

                    if (arg.originalEvent.detail == 1) {
                        // single click
                        // self.$refs[prop.tooltipRefNameNoEdit].instance.hide();
                        self.$refs[prop.tooltipRefGlobal].instance.hide();
                    } else if (arg.originalEvent.detail == 2) {
                        // double click
                        // self.$refs[prop.tooltipRefNameNoEdit].instance.show(arg.target);
                        self.tooltipMessage = "Non Editable";
                        self.$refs[prop.tooltipRefGlobal].instance.show(arg.target);
                    }
                });
            }
        }
    }
}

export function onContextMenuPreparingFunction(e, prop) {
    let items = []

    if (e !== null && e.row !== undefined) {
        e.component.selectRows = e
        let currentItem = e.row
        let text = !prop.isAnyLegAvailable(e) ? "Add Leg" : "Edit Leg"
        let textMarkEmpty = prop.selected_sub_tab.name === 'full' ? 'Mark Empty' : 'Unmark as Empty'

        if (prop.isTabLFDCaption) {
            items.push({
                text: "View Details",
                onClick: () => {
                    prop.goToDetailsPage(currentItem)
                }
            })
        } else if (prop.currentSelectTab === "Scheduled") {
            items.push(
                {
                    text: text,
                    onClick: () => {
                        if (text === "Add Leg") {
                            prop.addFirstLeg(e)
                        } else {
                            prop.editFirstLeg(e)
                        }
                    }
                },
                {
                    text: "View Details",
                    onClick: () => {
                        prop.goToDetailsPage(currentItem)
                    }
                },
                {
                    text: "Mark as Arrived",
                    onClick: () => {
                        prop.markAsArrivedFirstLeg(e)
                    }
                },
            )
        } else if (prop.currentSelectTab === "At the Customer") {
            items.push(
                {
                    text: text,
                    onClick: () => {
                        if (text === "Add Leg") {
                            prop.addFirstLeg(e)
                        } else {
                            prop.editFirstLeg(e)
                        }
                    }
                },
                {
                    text: "View Details",
                    onClick: () => {
                        prop.goToDetailsPage(currentItem)
                    }
                },
                {
                    text: textMarkEmpty,
                    onClick: () => {
                        prop.openDialog(e)
                    }
                },
            )
        } else {
            items.push(
                {
                    text: text,
                    onClick: () => {
                        if (text === "Add Leg") {
                            prop.addFirstLeg(e)
                        } else {
                            prop.editFirstLeg(e)
                        }
                    }
                },
                {
                    text: "View Details",
                    onClick: () => {
                        prop.goToDetailsPage(currentItem)
                    }
                },
            )
        }

        e.items = items;

        e.items.forEach((x) => {
            if (x.text === "View Details") {
                x.icon = "eye";
            }

            if (x.text === "Mark Empty" || x.text === "Unmark as Empty" || x.text === "Mark as Arrived") {
                x.icon = "archived";
            }

            if (x.text === "Add Leg") {
                x.icon = "adding";
            }

            if (x.text === "Edit Leg") {
                x.icon = "pencil";
            }
        });
    }
}

export function onFocusedCellChangingFunction(e, prop) {
    if (prop.currentSelectTab === 'All' || prop.currentSelectTab === 'Pending billing') {
        let exlcudedColumnIndexes = [3, 4, 5, 12]
        let isColumnExcluded = exlcudedColumnIndexes.find(c => c === e.newColumnIndex)

        if (isColumnExcluded !== undefined) { // DO and Ref
            e.isHighlighted = false;
        } else {
            e.isHighlighted = true;
        }
    } else if (prop.currentSelectTab === 'Scheduled') {
        let exlcudedColumnIndexes = [3, 4, 5, 12, 13, 14, 15, 16, 17, 18]
        let isColumnExcluded = exlcudedColumnIndexes.find(c => c === e.newColumnIndex)

        if (isColumnExcluded !== undefined) { // DO and Ref
            e.isHighlighted = false;
        } else {
            e.isHighlighted = true;
        }
    } else if (prop.currentSelectTab === 'At Port') {
        let exlcudedColumnIndexes = [3, 4, 5, 12, 13, 14, 15]
        let isColumnExcluded = exlcudedColumnIndexes.find(c => c === e.newColumnIndex)

        if (isColumnExcluded !== undefined) { // DO and Ref
            e.isHighlighted = false;
        } else {
            e.isHighlighted = true;
        }
    } else if (prop.currentSelectTab === 'At Yard' || prop.currentSelectTab === 'At the Customer') {
        let exlcudedColumnIndexes = prop.currentSelectTab === 'At Yard' ?
            [3, 4, 5, 9, 10, 12, 13] : [3, 4, 5, 10, 11, 13, 14]
        let isColumnExcluded = exlcudedColumnIndexes.find(c => c === e.newColumnIndex)

        if (isColumnExcluded !== undefined) { // DO and Ref
            e.isHighlighted = false;
        } else {
            e.isHighlighted = true;
        }
    } else if (prop.currentSelectTab === 'Cancelled') {
        let exlcudedColumnIndexes = [3, 4, 5, 10, 11, 12]
        let isColumnExcluded = exlcudedColumnIndexes.find(c => c === e.newColumnIndex)

        if (isColumnExcluded !== undefined) { // DO and Ref
            e.isHighlighted = false;
        } else {
            e.isHighlighted = true;
        }
    } else if (prop.currentSelectTab === 'Completed') {
        let exlcudedColumnIndexes = [3, 4, 5, 11, 12, 14, 15, 16]
        let isColumnExcluded = exlcudedColumnIndexes.find(c => c === e.newColumnIndex)

        if (isColumnExcluded !== undefined) { // DO and Ref
            e.isHighlighted = false;
        } else {
            e.isHighlighted = true;
        }
    } else {
        e.isHighlighted = true;
    }
}

export function onCellHoverChangedFunction(e, prop) {
    if (e.rowType === 'data') {
        var self = prop;

        let columnNames = ['Container', 'MBL', 'DO', 'CustomerID', 'Ref', 'ConsigneeName', 'Driver']
        let isColumnExcluded = columnNames.find(c => c === e.column.name)

        if (isColumnExcluded !== undefined) {
            if (e.eventType === 'mouseover') {
                jQuery(e.cellElement).addClass('hovered')

                if (e.event.target.id === 'i-icon-copy') {
                    self.tooltipMessage = "Click to Copy"
                    self.$refs[prop.tooltipRefGlobal].instance.show(e.event.target)
                }
            } else {
                jQuery(e.cellElement).removeClass('hovered')
                self.$refs[prop.tooltipRefGlobal].instance.hide();
            }
        }

        if (e.column.dataField === "Scheduled") { // show tooltip message to add a leg first
            let first_unarrived_leg = prop.getFirstUnArrivedLeg(e.data);

            if (first_unarrived_leg === null) {
                if (e.eventType === 'mouseover') {
                    self.tooltipMessage = "Cannot be edited. Please schedule a leg first."
                    self.$refs[prop.tooltipRefGlobal].instance.show(e.event.target);
                } else {
                    self.$refs[prop.tooltipRefGlobal].instance.hide();
                }
            } else {
                self.$refs[prop.tooltipRefGlobal].instance.hide();
            }
        }

        // for dates
        // if (e.column.dataField === "Lfdr") {
        // 	if (e.cellElement.bgColor !== "") {
        // 		let lfdrDate = moment(e.value).format("MM-DD-YYYY")
        // 		var new_date = moment(lfdrDate).fromNow()

        // 		if (e.eventType === 'mouseover') {
        // 			self.$refs[prop.tooltipDateInfo].instance._$popupContent[0].innerText = new_date
        // 			self.$refs[prop.tooltipDateInfo].instance.show(e.event.target);
        // 		} else {
        // 			self.$refs[prop.tooltipDateInfo].instance.hide();
        // 		}
        // 	}
        // }
    }

    // tooltip for header
    if (e.rowType === "header") {
        var headerSelf = prop;
        let text = ''

        let columns = ['discharged', 'released', 'gate_fees']
        let isColumnExcluded = columns.find(c => c === e.column.name)

        if (isColumnExcluded !== undefined) {
            if (e.eventType === 'mouseover') {
                if (e.column.name === 'discharged') {
                    text = 'Discharged'
                } else if (e.column.name === 'released') {
                    text = 'Released'
                } else if (e.column.name === 'gate_fees') {
                    text = 'Gate Fees'
                }

                headerSelf.tooltipMessage = text
                headerSelf.$refs[prop.tooltipRefGlobal].instance.show(e.event.target)
            } else {
                headerSelf.$refs[prop.tooltipRefGlobal].instance.hide();
            }
        }
    }
}

export function onContentReadyFunction(e, prop) {
    if (prop.shouldLoad) {
        prop.isShowPagination = false
        prop.isShowLoadingUI = true
    } else {
        // e.component.endCustomLoading()
        // e.component.getScrollable().scrollTo(0) // reset scrollbar position on click tabs

        var dataGridHeaders = document.querySelector(".dx-datagrid-headers");
        var noData = document.querySelector(".dx-datagrid-nodata");
        if (noData) {
            if (prop.dataSource.length === 0) {
                // noData.classList.remove('dx-hidden')
                noData.innerText = 'No available data'
                dataGridHeaders.classList.add('dx-hidden')
            } else if (e.component.getVisibleRows().length === 0) {
                noData.innerText = 'No available data'
            }
        }

        var gridData = document.querySelector("#gridContainer");
        if (e.component.getVisibleRows().length > 0) {
            prop.isShowLoadingUI = false
            dataGridHeaders.classList.remove('dx-hidden')

            if (prop.lastPage > 1) {
                setTimeout(() => {
                    gridData.classList.add('has-multiple-pages')
                    prop.isShowPagination = true
                }, 50);
            } else {
                gridData.classList.remove('has-multiple-pages')
                prop.isShowPagination = false
            }
        } else {
            gridData.classList.remove('has-multiple-pages')
            prop.isShowPagination = false

            if (!prop.shouldLoad) {
                prop.isShowLoadingUI = false
            }
        }
    }

    var isEditing = e.component.getVisibleRows().filter(function(r) {
        return r.isEditing == true;
    });

    if (isEditing.length == 0) {
        // outside click cancelled content
        isEditing = false
        prop.isCellEditing = false
    }
}

let editorInstance;

export function onEditorPreparingFunction(e, prop) {
    if (e.parentType == "dataRow") {
        e.editorOptions.onFocusOut = function() {
            setTimeout(function() {
                // check with a delay
                var isEditing = e.component.getVisibleRows().filter(function(r) {
                    return r.isEditing == true;
                });
                if (isEditing.length == 0) {
                    // outside click cancelled
                    isEditing = false
                    prop.isCellEditing = false
                }
            }, 300);
        }

        // show min date for lfd and pdd
        if (e.dataField === "Lfdp") {
            // set min and max date for lfd field
            e.editorOptions.allowClearing = true; // allow accept no value
            let minDate = getDateFormatFunction(e.row.data.Eta)
            let maxDate = getDateFormatFunction(e.row.data.Lfdr)
            let minDateAddDays = minDate !== 'N/A' || minDate !== null ? new Date(minDate) : '';
            let maxDateAddDays = maxDate !== 'N/A' || maxDate !== null ? new Date(maxDate) : '';
            e.editorOptions.min = minDate !== '' ? addDays(minDateAddDays, 1) : '';
            e.editorOptions.max = maxDate !== '' ? subtractDays(maxDateAddDays, 1) : '';
        }

        if (e.dataField === "Lfdr") {
            e.editorOptions.allowClearing = true; // allow accept no value
            // let minDate = e.row.data.Lfdp !== '' ? prop.getDateFormatFunction(e.row.data.Lfdp) : null
            // let minDateAddDays = minDate !== 'N/A' || minDate !== null ? new Date(minDate) : '';

            // let minDateETA = prop.getDateFormatFunction(e.row.data.Eta)
            // let minDateAddDaysETA = minDateETA !== 'N/A' || minDateETA !== null ? new Date(minDateETA) : '';

            // e.editorOptions.min = minDate !== '' ? addDays(minDateAddDays, 1) : 
            // 	(minDateAddDaysETA !== '' ? addDays(minDateAddDaysETA, 1) : '');

            // if lfd is null or empty, get ETA value
            let minDate = e.row.data.Lfdp !== '' ? getDateFormatFunction(e.row.data.Lfdp) : getDateFormatFunction(e.row.data.Eta)
            let minDateAddDays = minDate !== 'N/A' || minDate !== null ? new Date(minDate) : '';
            e.editorOptions.min = minDate !== '' ? addDays(minDateAddDays, 1) : '';
        }

        if (e.dataField === 'ConsigneeName') {
            e.editorOptions.allowClearing = true;
            e.editorOptions.dropDownOptions = {
                toolbarItems: [{
                    toolbar:'bottom',
                    location: 'left',
                    widget: 'dxButton',
                    options: {
                        text: '+ Add New',
                        onClick: () => {
                            prop.addNewConsigneeItem(e)
                        }
                    }
                }]
            };
        }

        if (e.dataField === 'CustomerID') {
            e.editorOptions.dropDownOptions = {
                toolbarItems: [{
                    toolbar:'bottom',
                    location: 'left',
                    widget: 'dxButton',
                    options: {
                        text: '+ Add New',
                        onClick: () => {
                            prop.addNewCustomer(e)
                        }
                    }
                }]
            };
        }

        if (e.dataField === 'discharged' || e.dataField === 'released' || e.dataField === 'gate_fees') {
            e.editorOptions.elementAttr = {
                "class": 'custom-checkbox-class'
            }
        }
        
        if (e.editorName === 'dxSelectBox' || e.editorName === 'dxDateBox') { // auto show dropdown on edit mode
            e.editorOptions.onFocusIn = function (args) {
                args.component.open();
            }

            e.editorOptions.onValueChanged = function (arg) {
                e.setValue(arg.value);
                // e.component.saveEditData(); -- directly update data on click item
            }
            // e.editorOptions.closeOnOutsideClick = true;
        }

        if (e.dataField === 'CarrierID' || e.dataField === 'Location') {
            e.editorOptions.dropDownOptions = {
                width: "300px"
            };
        }

        // for calling API
        // e.editorOptions.onValueChanged = function () {
        // 	prop.isUpdatingCells = true

        // 	console.log("should update here");
        //     // e.component.cellValue(e.row.rowIndex, "FirstName", "AnotherID");  
        // }
    }

    if (e.parentType === "filterRow") {
        e.editorOptions.showClearButton = true;
        
        if (e.editorName === "dxDateBox") {
            e.editorOptions.openOnFieldClick = true;
        }

        if (e.dataField === 'CarrierID') {
            e.editorOptions.dropDownOptions = {
                width: "300px"
            };
        }

        if (e.dataField === 'CustomerID') {
            e.editorOptions.onValueChanged = function (arg) {
                e.setValue(arg.value);
                editorInstance.option('value', null);

                let newDataSource = []
                if (arg.value !== null) {
                    // update the consignee dropdown based on the selected customer id
                    // let newDataSource = prop.generalConsigneeLists.filter(s => s.customer_id === arg.value);
                    newDataSource = prop.generalConsigneeLists.filter(s => (s !== null && s.customer_id) === arg.value);
                } else {
                    newDataSource = prop.generalConsigneeLists;
                    editorInstance.option('value', null);
                }
                
                // newDataSource.unshift(null); // add All option
                editorInstance.option('dataSource', newDataSource);
            }
        }

        if (e.dataField === "ConsigneeName" && e.editorOptions) {
            // update the consignee dropdown based on the selected customer id
            let filter = e.component.getDataSource().items().map(a => a.CustomerID);
            let newDataSource = prop.generalConsigneeLists.filter(s => filter.includes(s.customer_id));
            e.editorOptions.onInitialized = function(x) {
                editorInstance = x.component;
            }
            // newDataSource.unshift(null); // add All option
            e.editorOptions.dataSource = newDataSource;
        }

        if (e.dataField === "Location") {
            e.editorOptions.dropDownOptions = {
                width: "345px"
            };
        }

        if (e.dataField === "Status" && e.editorOptions) {
            e.editorName = "dxSelectBox";
            let options = [
                {
                    value: null,
                    name: "(All)"
                },
                {
                    value: "pending",
                    name: "Pending"
                },
                {
                    value: "assigned",
                    name: "Assigned"
                },
                {
                    value: "accepted",
                    name: "Accepted"
                },
                {
                    value: "started",
                    name: "Started"
                },
                {
                    value: "arrived_at_pickup",
                    name: "Arrived At Pickup"
                },
                {
                    value: "outgated_from_pickup",
                    name: "Outgated From Pickup"
                },
                {
                    value: "arrived_at_delivery",
                    name: "Arrived At Delivery"
                },
                // {
                //     value: "outgated_from_delivery",
                //     name: "Outgated From Delivery"
                // },
                {
                    value: "declined",
                    name: "Declined"
                },
            ];
            e.editorOptions.dataSource = options;
            e.editorOptions.searchEnabled = true;
            e.editorOptions.displayExpr = "name";  
            e.editorOptions.valueExpr = "value";  
            e.editorOptions.dropDownOptions = {
                width: "175px"
            };         
        }

        if (e.dataField === "Mode" && e.editorOptions) {
            e.editorName = "dxSelectBox";
            let options = [
                {
                    value: null,
                    name: "(All)"
                },
                {
                    value: "Dropped",
                    name: "Drop"
                },
                {
                    value: "Live",
                    name: "Live"
                },
            ];
            e.editorOptions.dataSource = options;
            e.editorOptions.displayExpr = "name";  
            e.editorOptions.valueExpr = "value";            
        }

        if (e.dataField === "TypeDelivery" && e.editorOptions) {
            e.editorName = "dxSelectBox";
            let options = [
                {
                    value: null,
                    name: "(All)"
                },
                {
                    value: "Customer",
                    name: "To Customer"
                },
                {
                    value: "Yard",
                    name: "To Yard"
                },
                {
                    value: "Empty Return",
                    name: "To Port"
                }
            ];
            e.editorOptions.dataSource = options;
            e.editorOptions.displayExpr = "name";  
            e.editorOptions.valueExpr = "value";            
        }

        if (e.dataField === "DirectPrepull" && e.editorOptions) {
            e.editorName = "dxSelectBox";
            let options = [
                {
                    value: null,
                    name: "(All)"
                },
                {
                    value: "Customer",
                    name: "Direct"
                },
                {
                    value: "Prepull",
                    name: "Prepull"
                },
            ];
            e.editorOptions.dataSource = options;
            e.editorOptions.displayExpr = "name";
            e.editorOptions.valueExpr = "value";  
        }

        if (e.dataField === "AllTabStatus" && e.editorOptions) {
            e.editorName = "dxSelectBox";
            e.editorOptions.dropDownOptions = {
                width: "180px"
            }; 

            let options = [
                {
                    value: null,
                    name: "(All)"
                },
                {
                    value: "at_port-pending",
                    name: "At Port - Pending"
                },
                {
                    value: "at_port-available",
                    name: "At Port - Available"
                },
                {
                    value: "at_yard-full",
                    name: "At Yard - Full"
                },
                {
                    value: "at_yard-empty",
                    name: "At Yard - Empty"
                },
                {
                    value: "at_the_customer-full",
                    name: "At Customer - Full"
                },
                {
                    value: "at_the_customer-empty",
                    name: "At Customer - Empty"
                },
                {
                    value: "pending_billing",
                    name: "Pending Billing"
                },
                {
                    value: "completed",
                    name: "Completed"
                },
                {
                    value: "cancelled",
                    name: "Cancelled"
                },
            ];
            e.editorOptions.dataSource = options;
            e.editorOptions.displayExpr = "name";
            e.editorOptions.valueExpr = "value";  
        }
    }
}

export function onRowUpdatingFunction(e, prop) {
    prop.isUpdatingCells = true
    let { newData, oldData } = e

    if (newData !== null) {
        let entries = Object.entries(newData)

        if (entries.length > 0) {
            let findFieldName = entries[0][0]
            let fieldName = cellUpdateFieldValue[findFieldName].field
            let displayName = cellUpdateFieldValue[findFieldName].display_name

            let fieldValue = entries[0][1]

            let payload = {
                id: oldData.ID,
                field: fieldName,
                value: fieldValue,
                displayName,
                for_dispatch: false,
            }

            let excludeFromSavingFields = ['LFD P', 'LFD R', 'ETA', 'Discharged', 'Released', 'Gate Fees']
            let isFieldExcluded = excludeFromSavingFields.find(c => c === displayName)

            if (isFieldExcluded === undefined) {
                if (displayName === 'Consignee') {
                    payload.model = "App\\DeliveryOrder"
                    payload.id = oldData.delivery_order_id

                    if (typeof newData.ConsigneeName === 'string') {
                        // get and assign the new consignee id
                        payload.value = oldData.NewConsigneeID
                    }
                } if (displayName === 'Customer') {
                    if (typeof newData.CustomerID === 'string') {
                        // get and assign the new customer id
                        let new_cid = parseInt(newData.CustomerID) // parse cid from string format to int
                        // if new_cid is NaN, get oldData.NewCustomerId, else assign new_cid
                        payload.value = isNaN(new_cid) ? oldData.NewCustomerId : new_cid
                        // payload.value = oldData.NewCustomerId
                    }
                } else if (displayName === 'Empty Notified') {
                    if (payload.value !== null) {
                        let convertValue = moment(payload.value).format('YYYY-MM-DD hh:mm'); // convert date and time to prop format
                        let splitValue = convertValue.split(' '); // split date and time
                        let date = moment(splitValue[0]).format('YYYY-MM-DD');
                        let time = splitValue[1];

                        let newPayload = {
                            type: 'multiple',
                            data: [{
                                model: 'App\\Shipment',
                                id: oldData.ID,
                                fields: [
                                    {
                                        field: 'container_empty_date',
                                        value: date
                                    },
                                    {
                                        field: 'container_empty_time',
                                        value: time
                                    }
                                ]
                            }],
                            displayName
                        }

                        payload = newPayload
                    }
                } else if (displayName === 'Scheduled') {
                    if (payload.value !== null) {
                        let convertValue = moment(payload.value).format('YYYY-MM-DD hh:mm'); // convert date and time to prop format
                        let splitValue = convertValue.split(' '); // split date and time
                        let date = moment(splitValue[0]).format('YYYY-MM-DD');
                        let time = splitValue[1];

                        let scheds = null;
                        let leg_id = null
                        let legs = [];

                        try {
                            scheds = JSON.parse(oldData.dispatch_schedule);
                        } catch (err) {
                            scheds = null;
                        }

                        // get the leg id
                        if (prop.currentSelectTab === 'At the Customer') {
                            legs = scheds.legs[scheds.legs.length - 1]
                            leg_id = legs.id
                        } else {
                            legs = scheds ? scheds.legs || [] : [];

                            if (["Available"].includes(prop.currentSelectTab) ||
                                ["Available"].includes(prop.selected_sub_tab.label) ) {
                                leg_id = legs[0].id ? legs[0].id : null
                            } else if (
                                ["Dropped empty", "Yard full", "At Yard"].includes(prop.currentSelectTab) ||
                                ["Dropped empty", "Yard full", "At Yard"].includes(prop.selected_sub_tab.label)) {

                                let first_unarrived_leg = prop.getFirstUnArrivedLeg(oldData);
                                console.log(first_unarrived_leg, 'first_unarrived_leg');
                            }
                        }

                        let newPayload = {
                            shipment_id: oldData.ID,
                            leg_id,
                            fields: { from_date: date, to_time: time },
                            displayName,
                            for_dispatch: true
                        }

                        payload = newPayload
                    }
                } else if (displayName === 'Driver') {
                    if (payload.value !== null) {
                        let scheds = null
                        let leg_id = null

                        try {
                            scheds = JSON.parse(oldData.dispatch_schedule);
                        } catch (err) {
                            scheds = null;
                        }

                        // get the first not completed leg
                        let getSchedule = scheds !== null ? getClearnDispatch(scheds) : null;
                        if (getSchedule !== null) {
                            leg_id = getSchedule.id
                        }
                                                    
                        let newPayload = {
                            shipment_id: oldData.ID,
                            leg_id,
                            fields: { driver: payload.value, driver_status: "Pending" },
                            displayName,
                            for_dispatch: true
                        }
                        payload = newPayload
                    }
                }

                // prop.callUpdateIndexAPI(payload)
            } else {
                if (fieldValue !== null) {
                    if (isFieldExcluded !== 'Discharged' && isFieldExcluded !== 'Released' &&
                        isFieldExcluded !== 'Gate Fees') {
                        let fieldValueConvert = moment(fieldValue).format('YYYY-MM-DD')
                        let oldDataDateConvert = moment(oldData[fieldName]).format('YYYY-MM-DD')

                        if (fieldValueConvert !== oldDataDateConvert) {
                            payload.value = fieldValueConvert
                            // prop.callUpdateIndexAPI(payload)
                        }
                    } else {
                        // prop.updateMilestone(payload)
                    }
                } else {
                    if (isFieldExcluded === 'LFD P' || isFieldExcluded === 'LFD R') {
                        let oldDataValue = isFieldExcluded === 'LFD P' ? oldData.Lfdp : oldData.Lfdr
                        let compareOldDataVal = oldDataValue === "" ? null : oldDataValue

                        if (payload.value !== compareOldDataVal) {
                            // prop.callUpdateIndexAPI(payload)
                        }
                    }
                }
            }

            prop.containerEditedData = payload
        }
    }

}

export function editorPreparedFunction(e) {
    // this is for showing the buttons soon for saving or cancelling - pass e and prop as params above

    // if (e.parentType === 'dataRow' && e.editorName === 'dxTextBox') {
    // 	const CustomButtonsApp = createApp({
    // 		setup() {
    // 			return () => h(CustomButtons, {type: 'Primary', dataGrid: e.component})
    // 		}
    // 	});

    // 	const wrapper = document.createElement("div");
    // 	wrapper.style.position = 'absolute';
    // 	CustomButtonsApp.mount(wrapper);
    // 	e.editorElement.appendChild(wrapper);
    // }

    // console.log(e, 'editor prepared');

    // if (e.parentType === 'dataRow' && (e.editorName === 'dxTextBox' || e.editorName === 'dxSelectBox')) {
    // 	// working code but issue with style
    // 	var ComponentClass = Vue.extend(CustomButtons)
    // 	var instance = new ComponentClass({
    // 		props: { type: 'primary', dataGrid: e.component }
    // 		// props: { type: 'primary' }
    // 	})
    // 	instance.$mount()
    // 	e.editorElement.appendChild(instance.$el);
    // }

    // if (options.parentType == 'dataRow' && options.dataField === 'ConsigneeName') {
    // 	// options.editorElement.dxSelectBox('instance').option('onValueChanged', function (e) {
    // 	// 	//your actions here
    // 	// 	console.log(e);
    // 	// });

    // 	console.log(options.editorElement.dxSelectBox('instance'));
    // }
}

export function validationErrorsFunction(e, field) {
    if (field === "Container") {
        let columnName = e.column.name
        let value = e.value

        if (columnName === 'Container') { // for container
            let result = false
            e.rule.message = "The first 4 characters are alpha and followed by 7 digits."

            if (value !== '' && value.length === 11) {
                if (/^[a-zA-Z]*$/.test(value.substring(0, 4)) &&
                    /^[0-9]*$/.test(value.substring(4, value.length))) {
                    result = true
                }
            } else {
                result = false
            }

            return result
        }

        return true

    } else if (field === "ETA") {
        let currentValue = e.data.Eta
        let lfdpValue = e.data.Lfdp
        let lfdrValue = e.data.Lfdr

        if (currentValue !== null) {
            let valueConvert = moment(currentValue).format('YYYY/MM/DD')
            let lfdpConvert = lfdpValue !== "" && lfdpValue !== null ? moment(lfdpValue).format('YYYY/MM/DD') : null
            let lfdrConvert = lfdrValue !== "" && lfdrValue !== null ? moment(lfdrValue).format('YYYY/MM/DD') : null

            if (lfdpConvert !== null) {
                if (moment(valueConvert).isBefore(lfdpConvert)) {
                    return true
                } else {
                    e.rule.message = "ETA must be earlier than Last Free Date"
                    return false
                }
            } else {
                if (lfdrConvert !== null) {
                    if (moment(valueConvert).isBefore(lfdrConvert)) {
                        return true
                    } else {
                        e.rule.message = "ETA must be earlier than Per Diem Date"
                        return false
                    }
                }
            }
        }

        return true
    } else if (field === "LFD") {
        // let currentValue = e.data.Lfdp
        // let lfdrValue = e.data.Lfdr
        // // let etaValue = e.data.Eta

        // if (currentValue !== null) {
        // 	let valueConvert = moment(currentValue).format('YYYY/MM/DD')
        // 	let lfdrConvert = lfdrValue !== "" && lfdrValue !== null ? moment(lfdrValue).format('YYYY/MM/DD') : null
        // 	// let etaConvert = moment(etaValue).format('YYYY-MM-DD')

        // 	if (lfdrConvert !== null) {
        // 		if (moment(valueConvert).isAfter(lfdrConvert) ||
        // 			moment(valueConvert).isSame(lfdrConvert)) {
        // 			e.rule.message = "Last Free Date must be earlier than Per Diem Date"
        // 			return false
        // 		} else {
        // 			return true
        // 		}
        // 	} else {
        // 		return true 
        // 	}
        // }

        return true
    } else if (field === "PDD") {
        // let currentValue = e.data.Lfdr
        // let ldfpValue = e.data.Lfdp

        // if (currentValue !== null) {
        // 	let valueConvert = moment(currentValue).format('YYYY/MM/DD')
        // 	let ldfpConvert = ldfpValue !== "" && ldfpValue !== null ? moment(ldfpValue).format('YYYY/MM/DD') : null

        // 	if (ldfpConvert !== null) {
        // 		if (moment(valueConvert).isBefore(ldfpConvert) ||
        // 			moment(valueConvert).isSame(ldfpConvert)) {
        // 			e.rule.message = "Per Diem Date must be later than Last Free Date"
        // 			return false
        // 		} else if (moment(valueConvert).isAfter(ldfpConvert)) {
        // 			return true
        // 		} else {
        // 			return true
        // 		}
        // 	} else {
        // 		return true
        // 	}
        // }

        return true
    } else if (field === "Scheduled") {
        // console.log(e);
        // if (this.currentSelectTab === 'Scheduled') {
        // 	let currentValue = e.data.LfdpSched
        // 	let ldfrValue = e.data.LfdrSched
        // }
        // let currentValue = e.data.Lfdp
        // let ldfrValue = e.data.Lfdr
        // let etaValue = e.data.Eta

        // if (currentValue !== null) {
        // 	let valueConvert = moment(currentValue).format('YYYY-MM-DD')
        // 	let ldfrConvert = ldfrValue !== "" || ldfrValue !== null ? moment(ldfrValue).format('YYYY-MM-DD') : null
        // 	let etaConvert = moment(etaValue).format('YYYY-MM-DD')

        // 	if (moment(valueConvert).isAfter(etaConvert)) {
        // 		if (ldfrConvert !== null) {
        // 			if (moment(valueConvert).isBefore(ldfrConvert)) {
        // 				return true
        // 			} else {
        // 				e.rule.message = "LFD P should be earlier than LFD R"
        // 				return false
        // 			}
        // 		} else {
        // 			return true
        // 		}
        // 	} else if (moment(valueConvert).isBefore(etaConvert) ||
        // 		moment(valueConvert).isSame(etaConvert)) {
        // 		e.rule.message = "LFD P should be later than ETA"
        // 		return false
        // 	}
        // }

        return true
    }
}
/** DATAGRID FUNCTIONS ENDS HERE */

export function getCombinedFilterFunction(e) {
    console.log(e, 'combined filter');
}

export function callUpdateIndexAPI() { // method for calling update index API backup
    // let { for_dispatch, ...otherData } = payload
    // prop.isUpdatingCells = true

    // try {
    // 	prop.updateTableIndexes(payload)		
    // 	// prop.detectchangesReloadchanges();

    // 	prop.notificationMessageCustomSuccess(`Container has been updated successfully.`)
    // 	prop.selectedTabData(prop.currentSelectTab, prop.selected_sub_tab, prop.page);
        
    // 	prop.isShowLoadingUI = false
    // 	prop.shouldLoad = false
    // } catch (error) {
    // 	prop.notificationCustom(error)
    // }	
}

export default compactViewMethods;