var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-5 px-5",staticStyle:{"background-color":"white"}},[_c('p',{staticClass:"sub-heading"},[_vm._v("Tracking Notification")]),_c('div',{staticClass:"item-main mt-4"},[_c('div',{staticClass:"item"},[_vm._m(0),_c('div',{staticClass:"item-right-section"},[_c('v-checkbox',{staticClass:"notify-type mr-3",attrs:{"label":"Email"},on:{"click":function($event){return _vm.showEmaiList(
              'container_available',
              _vm.getCompanyProfile.email_container_available
            )}},model:{value:(_vm.getCompanyProfile.email_container_available),callback:function ($$v) {_vm.$set(_vm.getCompanyProfile, "email_container_available", $$v)},expression:"getCompanyProfile.email_container_available"}}),_c('div',{staticClass:"vertical-separtor"}),_c('v-checkbox',{staticClass:"ml-3 notify-type",attrs:{"input-value":_vm.getCompanyProfile.notifi_container_available !== 'OFF',"label":"Push Notification"},on:{"click":function($event){return _vm.containerAvailableMenu()}}}),_c('v-select',{ref:"containerAvai",staticClass:"item-notif-settings",class:_vm.getCompanyProfile.notifi_container_available !== 'OFF'
              ? 'active'
              : '',attrs:{"items":_vm.stateData.containerAvailable,"menu-props":{ contentClass: 'notifi-menu-list' },"append-icon":"ic-chevron-down"},on:{"click":_vm.setContainerAvailableTime,"change":_vm.updateContainerAvailble},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"d-flex justify-start",staticStyle:{"border":"none","min-height":"45px","height":"45px"}},'v-list-item',attrs,false),on),[(item == 'Custom Time')?_c('div',{on:{"click":function($event){return _vm.openCustomTimeDialog('container_available')}}},[_c('p',{staticClass:"name font-regular",staticStyle:{"color":"#4a4a4a"}},[_vm._v(" "+_vm._s(item)+" ")])]):_c('div',[_c('p',{staticClass:"name font-regular",staticStyle:{"color":"#4a4a4a"}},[_vm._v(" "+_vm._s(item)+" ")])])])]}}]),model:{value:(_vm.containerAvailableNotifi),callback:function ($$v) {_vm.containerAvailableNotifi=$$v},expression:"containerAvailableNotifi"}})],1)]),(_vm.getCompanyProfile.email_container_available)?_c('div',{staticClass:"item-description"},[_c('p',{staticClass:"email-text"},[_vm._v("Send Email to:")]),_vm._l((JSON.parse(
          _vm.getCompanyProfile.recip_container_available
        )),function(data,index){return _c('div',{key:index,staticClass:"email-descp"},[_c('p',[_vm._v(_vm._s(data))])])}),_c('button',{on:{"click":function($event){return _vm.showDailog(
            _vm.getCompanyProfile.recip_container_available,
            'Container Available'
          )}}},[_c('p',[_vm._v("Edit")])])],2):_vm._e()]),_c('div',{staticClass:"item-main mt-4"},[_c('div',{staticClass:"item"},[_vm._m(1),_c('div',{staticClass:"item-right-section"},[_c('v-checkbox',{staticClass:"notify-type mr-3",attrs:{"label":"Email"},on:{"click":function($event){return _vm.showEmaiList(
              'container_discharged',
              _vm.getCompanyProfile.email_container_discharged
            )}},model:{value:(_vm.getCompanyProfile.email_container_discharged),callback:function ($$v) {_vm.$set(_vm.getCompanyProfile, "email_container_discharged", $$v)},expression:"getCompanyProfile.email_container_discharged"}}),_c('div',{staticClass:"vertical-separtor"}),_c('v-checkbox',{staticClass:"ml-3 notify-type",attrs:{"input-value":_vm.getCompanyProfile.notifi_container_discharged !== 'OFF',"label":"Push Notification"},on:{"click":function($event){return _vm.containerDischargeMenu()}}}),_c('v-select',{ref:"contDischargeSelect",staticClass:"item-notif-settings",class:_vm.getCompanyProfile.notifi_container_discharged !== 'OFF'
              ? 'active'
              : '',attrs:{"items":_vm.stateData.containerDischarged,"menu-props":{ contentClass: 'notifi-menu-list' },"append-icon":"ic-chevron-down"},on:{"click":function($event){return _vm.setdischargeContainerTime()},"change":_vm.updateDischargeContainer},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"d-flex justify-start",staticStyle:{"border":"none","min-height":"45px","height":"45px"}},'v-list-item',attrs,false),on),[(item == 'Custom Time')?_c('div',{on:{"click":function($event){return _vm.openCustomTimeDialog('container_discharged')}}},[_c('p',{staticClass:"name font-regular",staticStyle:{"color":"#4a4a4a"}},[_vm._v(" "+_vm._s(item)+" ")])]):_c('div',[_c('p',{staticClass:"name font-regular",staticStyle:{"color":"#4a4a4a"}},[_vm._v(" "+_vm._s(item)+" ")])])])]}}]),model:{value:(_vm.containerDischargedNotifi),callback:function ($$v) {_vm.containerDischargedNotifi=$$v},expression:"containerDischargedNotifi"}})],1)]),(_vm.getCompanyProfile.email_container_discharged)?_c('div',{staticClass:"item-description"},[_c('p',{staticClass:"email-text"},[_vm._v("Send Email to:")]),_vm._l((JSON.parse(
          _vm.getCompanyProfile.recip_container_discharged
        )),function(data,index){return _c('div',{key:index,staticClass:"email-descp"},[_c('p',[_vm._v(_vm._s(data))])])}),_c('button',{on:{"click":function($event){return _vm.showDailog(
            _vm.getCompanyProfile.recip_container_discharged,
            'Container Discharged'
          )}}},[_c('p',[_vm._v("Edit")])])],2):_vm._e()]),_c('div',{staticClass:"item-main mt-4"},[_c('div',{staticClass:"item"},[_vm._m(2),_c('div',{staticClass:"item-right-section"},[_c('v-checkbox',{staticClass:"notify-type mr-3",attrs:{"label":"Email"},on:{"click":function($event){return _vm.showEmaiList('LFD_issued', _vm.getCompanyProfile.email_lfd_issued)}},model:{value:(_vm.getCompanyProfile.email_lfd_issued),callback:function ($$v) {_vm.$set(_vm.getCompanyProfile, "email_lfd_issued", $$v)},expression:"getCompanyProfile.email_lfd_issued"}}),_c('div',{staticClass:"vertical-separtor"}),_c('v-checkbox',{staticClass:"ml-3 notify-type",attrs:{"input-value":_vm.getCompanyProfile.notifi_lfd_issued !== 'OFF',"label":"Push Notification"},on:{"click":function($event){return _vm.LFDIssuedMenu()}}}),_c('v-select',{ref:"LFDIssuedSelect",staticClass:"item-notif-settings",class:_vm.getCompanyProfile.notifi_lfd_issued !== 'OFF' ? 'active' : '',attrs:{"items":_vm.stateData.LFDIssued,"menu-props":{ contentClass: 'notifi-menu-list' },"append-icon":"ic-chevron-down"},on:{"click":function($event){return _vm.setLFDIssuedTime()},"change":_vm.updateLFDIssued},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"d-flex justify-start",staticStyle:{"border":"none","min-height":"45px","height":"45px"}},'v-list-item',attrs,false),on),[(item == 'Custom Time')?_c('div',{on:{"click":function($event){return _vm.openCustomTimeDialog('lfd_issued')}}},[_c('p',{staticClass:"name font-regular",staticStyle:{"color":"#4a4a4a"}},[_vm._v(" "+_vm._s(item)+" ")])]):_c('div',[_c('p',{staticClass:"name font-regular",staticStyle:{"color":"#4a4a4a"}},[_vm._v(" "+_vm._s(item)+" ")])])])]}}]),model:{value:(_vm.LFDIssuedNotifi),callback:function ($$v) {_vm.LFDIssuedNotifi=$$v},expression:"LFDIssuedNotifi"}})],1)]),(_vm.getCompanyProfile.email_lfd_issued)?_c('div',{staticClass:"item-description"},[_c('p',{staticClass:"email-text"},[_vm._v("Send Email to:")]),_vm._l((JSON.parse(
          _vm.getCompanyProfile.recip_lfd_issued
        )),function(data,index){return _c('div',{key:index,staticClass:"email-descp"},[_c('p',[_vm._v(_vm._s(data))])])}),_c('button',{on:{"click":function($event){return _vm.showDailog(_vm.getCompanyProfile.recip_lfd_issued, 'LFD Issued')}}},[_c('p',[_vm._v("Edit")])])],2):_vm._e()]),_c('div',{staticClass:"item-main mt-4"},[_c('div',{staticClass:"item"},[_vm._m(3),_c('div',{staticClass:"item-right-section"},[_c('v-checkbox',{staticClass:"notify-type mr-3",attrs:{"label":"Email"},on:{"click":function($event){return _vm.showEmaiList(
              'eta_changed_alert',
              _vm.getCompanyProfile.email_eta_changed_alert
            )}},model:{value:(_vm.getCompanyProfile.email_eta_changed_alert),callback:function ($$v) {_vm.$set(_vm.getCompanyProfile, "email_eta_changed_alert", $$v)},expression:"getCompanyProfile.email_eta_changed_alert"}}),_c('div',{staticClass:"vertical-separtor"}),_c('v-checkbox',{staticClass:"ml-3 notify-type",attrs:{"input-value":_vm.getCompanyProfile.notifi_eta_changed_alert !== 'OFF',"label":"Push Notification"},on:{"click":function($event){return _vm.ETAChangeMenu()}}}),_c('v-select',{ref:"etaSelect",staticClass:"item-notif-settings",class:_vm.getCompanyProfile.notifi_eta_changed_alert !== 'OFF'
              ? 'active'
              : '',attrs:{"items":_vm.stateData.ETAChangeAlert,"menu-props":{ contentClass: 'notifi-menu-list' },"append-icon":"ic-chevron-down"},on:{"click":function($event){return _vm.setETAChangeAlertTime()},"change":_vm.updateETAChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"d-flex justify-start",staticStyle:{"border":"none","min-height":"45px","height":"45px"}},'v-list-item',attrs,false),on),[(item == 'Custom Time')?_c('div',{on:{"click":function($event){return _vm.openCustomTimeDialog('eta_changed_alert')}}},[_c('p',{staticClass:"name font-regular",staticStyle:{"color":"#4a4a4a"}},[_vm._v(" "+_vm._s(item)+" ")])]):_c('div',[_c('p',{staticClass:"name font-regular",staticStyle:{"color":"#4a4a4a"}},[_vm._v(" "+_vm._s(item)+" ")])])])]}}]),model:{value:(_vm.ETAChangeAlertNotifi),callback:function ($$v) {_vm.ETAChangeAlertNotifi=$$v},expression:"ETAChangeAlertNotifi"}})],1)]),(_vm.getCompanyProfile.email_eta_changed_alert)?_c('div',{staticClass:"item-description"},[_c('p',{staticClass:"email-text"},[_vm._v("Send Email to:")]),_vm._l((JSON.parse(
          _vm.getCompanyProfile.recip_eta_changed_alert
        )),function(data,index){return _c('div',{key:index,staticClass:"email-descp"},[_c('p',[_vm._v(_vm._s(data))])])}),_c('button',{on:{"click":function($event){return _vm.showDailog(
            _vm.getCompanyProfile.recip_eta_changed_alert,
            'ETA Changed Alert'
          )}}},[_c('p',[_vm._v("Edit")])])],2):_vm._e()]),_c('div',{staticClass:"item-main mt-4"},[_c('div',{staticClass:"item"},[_vm._m(4),_c('div',{staticClass:"item-right-section"},[_c('v-checkbox',{staticClass:"notify-type mr-3",attrs:{"label":"Email"},on:{"click":function($event){return _vm.showEmaiList('LFD_reminder', _vm.getCompanyProfile.email_lfd_reminder)}},model:{value:(_vm.getCompanyProfile.email_lfd_reminder),callback:function ($$v) {_vm.$set(_vm.getCompanyProfile, "email_lfd_reminder", $$v)},expression:"getCompanyProfile.email_lfd_reminder"}}),_c('div',{staticClass:"vertical-separtor"}),_c('v-checkbox',{staticClass:"ml-3 notify-type",attrs:{"input-value":_vm.getCompanyProfile.notifi_lfd_reminder !== 'OFF',"label":"Push Notification"},on:{"click":function($event){return _vm.LFDReminderMenu()}}}),_c('v-select',{ref:"LFDReminderSelect",staticClass:"item-notif-settings",class:_vm.getCompanyProfile.notifi_lfd_reminder !== 'OFF' ? 'active' : '',attrs:{"items":_vm.stateData.LFDReminder,"menu-props":{ contentClass: 'notifi-menu-list' },"append-icon":"ic-chevron-down"},on:{"click":_vm.setLFDReminderTime,"change":_vm.updateLFDReminder},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"d-flex justify-start",staticStyle:{"border":"none","min-height":"45px","height":"45px"}},'v-list-item',attrs,false),on),[(item == 'Custom Time')?_c('div',{on:{"click":function($event){return _vm.openCustomTimeDialog('lfd_reminder')}}},[_c('p',{staticClass:"name font-regular",staticStyle:{"color":"#4a4a4a"}},[_vm._v(" "+_vm._s(item)+" ")])]):_c('div',[_c('p',{staticClass:"name font-regular",staticStyle:{"color":"#4a4a4a"}},[_vm._v(" "+_vm._s(item)+" ")])])])]}}]),model:{value:(_vm.LFDReminderNotifi),callback:function ($$v) {_vm.LFDReminderNotifi=$$v},expression:"LFDReminderNotifi"}})],1)]),(_vm.getCompanyProfile.email_lfd_reminder)?_c('div',{staticClass:"item-description"},[_c('p',{staticClass:"email-text"},[_vm._v("Send Email to:")]),_vm._l((JSON.parse(
          _vm.getCompanyProfile.recip_lfd_reminder
        )),function(data,index){return _c('div',{key:index,staticClass:"email-descp"},[_c('p',[_vm._v(_vm._s(data))])])}),_c('button',{on:{"click":function($event){return _vm.showDailog(_vm.getCompanyProfile.recip_lfd_reminder, 'LFD Reminder')}}},[_c('p',[_vm._v("Edit")])])],2):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"item-heading"},[_vm._v("Container Available")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"item-heading"},[_vm._v("Container Discharged")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"item-heading"},[_vm._v("LFD Issued")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"item-heading"},[_vm._v("ETA Changed Alert")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"item-heading"},[_vm._v("LFD Reminder")])])}]

export { render, staticRenderFns }