<template>
    <div class="drivers-page-wrapper pa-6" v-resize="onResize">
        <NewDriverTable
            @addDriver="addDriver"
            @editDriver="editDriver"
            @editPassdata="editPassdata"
            @deleteSupplier="deleteSupplier"
            @updatelisting="updatelisting"
            :itemData.sync="theItem"
            :isdeleted.sync="isdeleted"
            :isMobile="isMobile"
            :reloadcomponent="reloadcomponent"
            v-if="renderComponent"
            :defaultPage="pagerender" />

        <DriverDialog
            :notifysavedparent.sync="tellparent"
            :editedIndexData.sync="editedIndex"
            :dialogData.sync="dialog"
            :editedItemData.sync="editedItem"
            :defaultItemData.sync="defaultItem"
            @setToDefault="setToDefault"
            @close="close"
            v-if="renderadddialog" />

        <DriverEditDialog
            :notifyupdate.sync="notifyupdate"
            :editedIndexData.sync="editedIndex"
            :dialogData.sync="dialogEdit"
            :editedItemData.sync="editedItem"
            :edititem="updatelisting"
            :idrow="currentEditrow" />

        <DriverDeleteDialog
            :notifyDeleteitem.sync="isdeleted"
            :editedIndexData.sync="editedIndex"
            :dialogData.sync="dialogDelete"
            :editedItemData.sync="editedItem"
            :defaultItemData.sync="defaultItem"
            @setToDefault="setToDefault"
            @close="close"
            :idrow="currentEditrow" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DriverDialog from '../components/DriverComponents/DriverDialog.vue';
import DriverEditDialog from '../components/DriverComponents/DriverEditDialog.vue';
import DriverDeleteDialog from '../components/DriverComponents/DriverDeleteDialog.vue';
import NewDriverTable from '../components/Tables/Drivers/NewDriverTable.vue';

export default {
    name: "NewDrivers",
    components: {
        DriverDialog,
        DriverEditDialog,
        DriverDeleteDialog,
        NewDriverTable
    },
    data() {
        return {
            pagerender: 1,
            isMobile: false,
            renderadddialog: true,
            renderComponent: true,
            tellparent: 0,
            isdeleted: 0,
            notifyupdate: 0,
            theItem: 0,
            currentEditrow: '',
            updatelisting: [],
            isaddeddriver: false,
            dialogEdit: false,
            isRender: 0,
            reloadcomponent: 0,
            page: 1,
            pageCount: 0,
            itemsPerPage: 35,
            dialog: false,
            dialogDelete: false,
            headers: [
                {
                    text: "Name",
                    align: "start",
                    sortable: false,
                    value: "company_name",
                    width: "25%",
                    fixed: true
                },
                {
                    text: "Phone",
                    align: "start",
                    value: "phone",
                    sortable: false,
                    width: "20%",
                    fixed: true
                },
                {
                    text: "Address",
                    align: "start",
                    value: "address",
                    sortable: false,
                    width: "25%",
                    fixed: true
                },
                {
                    text: "Email",
                    align: "start",
                    value: "emails",
                    sortable: false,
                    width: "20%",
                    fixed: true
                },
                {
                    text: "",
                    align: "center",
                    value: "actions",
                    sortable: false,
                    width: "10%",
                    fixed: true
                },
            ],
            editedIndex: -1,
            editedItem: {
                company_name: "",
                phone_number: "",
                address: "",
                emails: null
            },
            defaultItem: {
                company_name: "",
                phone_number: "",
                address: "",
                emails: null
            },
            search: '',
        }
    },
    computed: {
        ...mapGetters({
			getDrivers: 'drivers/getDrivers',
		}),
        driverLists() {
			let array = [];
			var response = this.getDrivers;
			// object reading server response changed
			if (Object.keys(response).length !== 0) {
				response.forEach((v) => {
					let m = {
						driver_id: v.id,
						name: v.name,
						last_name: v.last_name || '',
						phone_number: v.phone_number == null ? '' : v.phone_number,
						emails: v.email,
						trucker_id: response.trucker_id
					}
					array.push(m)
				});
			}
			return array
		}
    },
    watch: {
		dialog(val) {
			val || this.close();
			this.renderadddialog = false;
			this.$nextTick(() => {
				this.renderadddialog = true;
			});
		},
		dialogEdit(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.close();
		},
		isdeleted(v) {
			if(v) {
				this.reloadcomponent = 1;
				this.isdeleted = 0;
				this.renderCall(v)
			}
		},
		tellparent(v) {
			if(v) {
				this.reloadcomponent = 1;
				this.msgcontent = 'New driver has been added.'
				this.isaddeddriver = true;
				setTimeout(async () => {
					this.isaddeddriver = false;
				}, 5000)
				this.tellparent = 0;
				this.renderCall(v)
			}
		},
		notifyupdate(v) {
			this.isRender = 1;
			this.msgcontent = 'Driver has beed edited!';
			this.isaddeddriver = true;
			setTimeout(async () => {
				this.isaddeddriver = false;
			}, 5000)
			this.notifyupdate = 0;
			this.renderCall(v)
		},
		isRender(v) {
			if(v) this.reloadcomponent = v;
		}
	},
    methods: {
        ...mapActions({
			fetchdrivers: 'drivers/fetchDrivers'
		}),
        onResize() {
			if (window.innerWidth < 769) {
				this.isMobile = true
			} else {
				this.isMobile = false
			}
		},
        addDriver() {
			this.dialog = true;
		},
		editDriver(data) {
			this.currentEditrow = data.id
			this.editedIndex = this.driverLists.indexOf(data);
			this.editedItem = Object.assign({}, data);
			this.dialogEdit = true;
		},
		editPassdata(value) {
			this.currentEditrow = value
		},
		deleteSupplier(item) {
			this.editedIndex = this.driverLists.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},
		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},
		updatelistingf(v) {
			this.updatelisting = v
		},
        renderCall(c) {
			this.renderComponent = false;
			this.$nextTick(() => {
				this.renderComponent = true;
				this.theItem = c
			});
		},
		setToDefault() {
			this.editedItem = this.defaultItem
			this.close()
			this.dialog = true
		}
    },
    async mounted() {
        this.$store.dispatch("page/setPage", "drivers");
        // await this.fetchdrivers()

        let pageId = this.$route.params.id;

        if (typeof pageId !== "undefined") {
            this.pagerender = pageId
        }
    },
    updated() {}
}
</script>

<style lang="scss">
@import '../assets/scss/pages_scss/drivers/driver.scss';
@import '../assets/scss/pages_scss/drivers/driver_css.scss';
@import '../assets/scss/buttons.scss';
@import '../assets/css/dialog_styles/dialogHeader.css';
@import '../assets/css/dialog_styles/dialogBody.css';
@import '../assets/css/dialog_styles/dialogFooter.css';
</style>