<template>
  <div class="supplier-content-wrapper driver-content-wrapper">
    <div
      class="driverHeader"
      style="
        background: none !important;
        display: block !important;
        padding-bottom: 0px;
        min-height: 128px;
      "
    >
      <div class="classHeadTop">
        <!-- <router-link 
                class="gobacknow" 
                :to="goBackPage">
                <img 
                    width="6.5px" 
                    src="@/assets/icons/arrowLeft.svg">
                Go Back
            </router-link> -->

        <button
          class="gobacknow"
          @click="goBackPage"
          style="color: #0171a1 !important"
        >
          <img width="6.5px" src="@/assets/icons/arrowLeft.svg" />
          Go Back
        </button>
      </div>

      <v-toolbar class="containerdetailheader">
        <v-toolbar-title v-if="completed" class="titleheader">
          <span
            class="nameheader"
            style="
              font-size: 24px;
              width: auto;
              margin-right: 12px;
              display: inline-block;
              color: #4a4a4a;
            "
          >
            <span @click="showLegs"> </span> {{ driver_name }}
          </span>
          <template>
            <span v-if="getLocFrom !== 'notactive'">
              <span
                class="connectedDriver commonDriverStatus"
                v-if="connectedStatus == 1"
              >
                Connected
              </span>
              <span class="notconnectedDriver commonDriverStatus" v-else>
                Not Connected
              </span>
            </span>
            <span v-else>
              <span
                class="notactiveDriver commonDriverStatus"
                v-if="activatedStatus"
              >
                Activated
              </span>
              <span class="notactiveDriver commonDriverStatus" v-else>
                Deactivated
              </span>
            </span>
            <span style="background: none !important" class="doprodownreport">
              <span :class="checktheclass()">
                <span class="scheduletext"> Notifications </span>
                <span class="ON_text">
                  {{ onAndOffText() }}
                </span>
                <span>
                  <v-menu
                    min-width="150px"
                    bottom
                    offset-y
                    nudge-top="-5"
                    nudge-left="121"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        style="position: relative; top: 4.1px"
                      >
                        <img src="@/assets/icons/Chevron Down2.svg" />
                      </span>
                    </template>
                    <v-list>
                      <div class="dropdownreport">
                        <ul class="listmenu">
                          <li @click="ONandOFF" class="text-center">
                            <span class="headtextlist sendreport">
                              {{ onAndOffDropdown() }}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </v-list>
                  </v-menu>
                </span>
              </span>
            </span>
          </template>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="btn-white"
          style="margin-right: 6px"
          @click="sendEmail"
        >
          <img style="margin-right: 4px" src="@/assets/icons/editpencil.svg" />
          Send Email
        </v-btn>
        <v-menu
          offset-y
          bottom
          left
          min-width="165px"
          content-class="driver-table-menu-details trucking-menu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="btn-white button-more-details-driver"
            >
              <img src="@/assets/icons/more-blue.svg" alt="" />
            </v-btn>
          </template>
          <v-list>
            <!-- <v-list-item
							class="trucking_DropDown"
						>
							<v-list-item-title
								v-if="getLocFrom !== 'notactive'"
								@click="
									runAction(
										connectedStatus
											? 'Disconnect'
											: 'Connect'
									)
								"
								class=""
							>
								{{ connectedStatus ? "Disconnect" : "Connect" }}
							</v-list-item-title>
							<v-list-item-title
								class=""
								@click="
									runAction(
										activatedStatus
											? 'Deactivate'
											: 'Activate'
									)
								"
							>
								{{ dropdownStatus }}
							</v-list-item-title>
						</v-list-item> -->

            <v-list-item
              class="driver-item-wrapper"
              @click="deactivateDriver(true)"
              v-if="getLocFrom !== 'notactive'"
            >
              <v-list-item-title class="driver-list-item">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.8498 0.800781C14.5954 0.800781 15.1998 1.4052 15.1998 2.15078V3.95078C15.1998 4.53834 14.8244 5.03824 14.3004 5.22374L14.2998 12.0508C14.2998 13.7905 12.8895 15.2008 11.1498 15.2008H4.84981C3.11011 15.2008 1.69981 13.7905 1.69981 12.0508L1.70007 5.22406C1.17559 5.03881 0.799805 4.53868 0.799805 3.95078V2.15078C0.799805 1.4052 1.40422 0.800781 2.14981 0.800781H13.8498ZM13.3998 5.30078H2.59981V12.0508C2.59981 13.2934 3.60717 14.3008 4.84981 14.3008H11.1498C12.3924 14.3008 13.3998 13.2934 13.3998 12.0508V5.30078ZM6.64981 7.10078H9.34981C9.59834 7.10078 9.79981 7.30225 9.79981 7.55078C9.79981 7.7717 9.64062 7.95543 9.4307 7.99353L9.34981 8.00078H6.64981C6.40128 8.00078 6.19981 7.79931 6.19981 7.55078C6.19981 7.32987 6.35899 7.14613 6.56892 7.10803L6.64981 7.10078H9.34981H6.64981ZM13.8498 1.70078H2.14981C1.90128 1.70078 1.69981 1.90225 1.69981 2.15078V3.95078C1.69981 4.19931 1.90128 4.40078 2.14981 4.40078H13.8498C14.0983 4.40078 14.2998 4.19931 14.2998 3.95078V2.15078C14.2998 1.90225 14.0983 1.70078 13.8498 1.70078Z"
                    fill="#6D858F"
                  />
                </svg>
                <span style="margin-left: 9px">Archived</span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              class="driver-item-wrapper"
              @click="runAction('Activate')"
              v-else
            >
              <v-list-item-title class="driver-list-item">
                <span style="margin-left: 9px">Activate</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>

      <div
        v-if="completed"
        class="Headerbottomtext"
        style="
          background: none !important;
          position: relative;
          top: -15px;
          font-size: 12px;
        "
      >
        <span class="reportscheduletag">
          <span
            style="
              color: #6d858f;
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
            "
            >DRIVER ID</span
          >
          <span class="shiflkey">
            {{ driver_id !== "" || driver_id != null ? "#" + driver_id : "" }}
          </span>
        </span>
        <span class="telephoneholder">
          <img src="@/assets/icons/phone.svg" />
          <span class="shiflkey">{{ phone_number }}</span>
        </span>
        <span class="emailholder">
          <img src="@/assets/icons/email.svg" />
          <span class="shiflkey">{{ getemailsdata }}</span>
        </span>
      </div>
    </div>

    <v-data-table
      :headers="headers"
      mobile-breakpoint="400"
      :items="drivers"
      class="suppliers-table driver-details-table elevation-1"
      :search="search"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      hide-default-footer
      fixed-header
    >
      <template v-slot:[`header.name`]="{ header }">
        {{ header.text }}<i class="classsort"></i>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-tabs>
            <v-tab>Schedule History</v-tab>
          </v-tabs>
          <!-- <Tab 
                :pageCurrent="pageCurrent"
                @currentPage="currenttab"                  
                />
                <v-spacer></v-spacer>
                <Search
                    placeholder="Search Drivers"
                    className="search custom-search"
                    :inputData.sync="search"
                /> -->
        </v-toolbar>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <p class="mb-0">
          {{ item.name !== "" ? item.name + " " + item.last_name : "--" }}
        </p>
      </template>

      
      <template v-slot:[`item.unique_id`]="{ item,index }">
        <p v-html="legRefID(item,index)" class="mb-0"></p>
      </template>

      <template v-slot:[`item.shiflcontainer`]="{ item }">
        <p v-html="containers(item)" class="mb-0"></p>
      </template>

      <template v-slot:[`item.pickup_scheduled_date_time`]="{ item }">
        <p v-html="pickupon(item)" class="mb-0"></p>
      </template>

      <template v-slot:[`item.delivery_scheduled_date_time`]="{ item }">
        <p class="mb-0" v-html="deliveryon(item)"></p>
      </template>
      <template v-slot:[`item.driver_status`]="{ item }">
        <div
          class="status-div"
          :class="
            item.driver_status == 'Accepted'
              ? 'accept-div'
              : item.driver_status == 'Declined'
              ? 'cancel-div'
              : 'pending-div'
          "
        >
          <p
            style="font-size: 12px !important"
            class="mb-0"
            :class="
              item.driver_status == 'Accepted'
                ? 'accept-txt'
                : item.driver_status == 'Declined'
                ? 'cancel-txt'
                : 'pending-txt'
            "
          >
            {{
              item.driver_status == "Declined"
                ? "Cancelled"
                : item.driver_status
            }}
          </p>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <!-- <template v-slot:[`item.actions`]="{ item }"> -->
        <div class="action-button-wrapper-driver">
          <v-menu
            offset-y
            bottom
            left
            min-width="140px"
            content-class="driver-table-menu trucking-menu"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="btn-white">
                <img src="@/assets/icons/more-blue.svg" alt="" />
              </v-btn>
            </template>
            <v-list class="py-1">
              <v-list-item @click="editLeg(item)" class="driver-item-wrapper">
                <v-list-item-title class="driver-list-item">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.4165 0.58353C12.1945 1.36157 12.1945 2.62302 11.4165 3.40106L4.05846 10.7591C3.73929 11.0782 3.33939 11.3047 2.9015 11.4141L0.619328 11.9847C0.254548 12.0759 -0.0758704 11.7455 0.0153245 11.3807L0.585868 9.0985C0.69534 8.66061 0.921762 8.2607 1.24092 7.94154L8.59893 0.58353C9.37697 -0.19451 10.6384 -0.19451 11.4165 0.58353ZM7.89447 2.69657L1.94531 8.64592C1.75381 8.83742 1.61796 9.07736 1.55227 9.3401L1.18306 10.8169L2.6599 10.4477C2.92263 10.382 3.16258 10.2462 3.35407 10.0547L9.30303 4.10513L7.89447 2.69657ZM9.30332 1.28791L8.59875 1.9923L10.0073 3.40085L10.7121 2.69668C11.1011 2.30766 11.1011 1.67693 10.7121 1.28791C10.3231 0.898893 9.69234 0.898893 9.30332 1.28791Z"
                      fill="#6D858F"
                    />
                  </svg>
                  <span style="margin-left: 10px">Edit</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="deleteLegFunction(item)"
                class="driver-item-wrapper"
              >
                <v-list-item-title class="driver-list-item">
                  <span style="padding-right: 3px">
                    <img src="@/assets/icons/Delete.svg" alt="" />
                  </span>
                  <span class="ml-1">Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>

      <template v-slot:no-data>
        <div class="no-data-preloader mt-4 mb-4" v-if="getDriverLoading">
          <v-progress-circular
            :size="35"
            color="#0171a1"
            indeterminate
            v-if="getDriverLoading"
          >
          </v-progress-circular>
        </div>
      </template>
    </v-data-table>

    <ArchivedDriverDialog :dialogData.sync="isShowArchivedWarning">
      <template v-slot:dialog_icon>
        <div class="header-wrapper-close">
          <img src="@/assets/icons/icon-delete.svg" alt="alert" />
        </div>
      </template>

      <template v-slot:dialog_title>
        <h2>Archived Driver</h2>
      </template>

      <template v-slot:dialog_content>
        <p>
          Do you want to archive driver
          <span class="name">
            "{{
              currentDriverSelected !== null &&
              currentDriverSelected.name !== null &&
              currentDriverSelected.name !== ""
                ? currentDriverSelected.name
                : ""
            }}" </span
          >? Once archived, driver will not be able to be assigned to any loads.
        </p>
      </template>

      <template v-slot:dialog_actions>
        <v-btn
          class="btn-blue"
          @click="deactivateDriver(false)"
          text
          :disabled="archivedLoading"
        >
          <span v-if="!archivedLoading">Understood</span>
          <span v-if="archivedLoading">Archiving...</span>
        </v-btn>

        <v-btn
          class="btn-white ml-0"
          text
          @click="closeArchivedDialog"
          :disabled="archivedLoading"
        >
          Cancel
        </v-btn>
      </template>
    </ArchivedDriverDialog>

    <Pagination
      v-if="typeof drivers != 'undefined' && drivers.length > 0"
      :pageData.sync="page"
      :lengthData.sync="pageCount"
      :isMobile="isMobile"
    />

    <Dialog
      :dialogData.sync="dialogHolder"
      :driverID="driverID"
      :bus="bus"
      :fetchCompleted.sync="fetchCompleted"
    />
    <LegDelete
      :dialogDelete="deleteLeg"
      :legSelectedForDelete="legSelectedForDelete"
      @close-leg-dialog="closeLegDialog"
    />
    <EditLeg
      :legEditDialog="legEditDialog"
      :legSelectedForEdit="legSelectedForEdit"
      @close-leg-edit-dialog="closeLegEditDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Pagination from "../../Pagination.vue";
import axios from "axios";
const poBaseUrl = process.env.VUE_APP_PO_URL;
import Dialog from "./dialog/driverHolder.vue";
import moment from "moment";
import ArchivedDriverDialog from "./dialog/ArchivedDriverDialog.vue";
import globalMethods from "../../../utils/globalMethods";
import LegDelete from "./dialog/LegDelete.vue";
import EditLeg from "./dialog/EditLeg.vue";
//import _ from "lodash";
export default {
  name: "DriversDesktopTable",
  props: ["items", "isMobile", "itemData", "reloadcomponent", "bus"],
  components: {
    Pagination,
    Dialog,
    LegDelete,
    EditLeg,
    ArchivedDriverDialog,
  },
  data: () => ({
    completed: 0,
    labelnotification: "",
    fetchCompleted: 0,
    driverID: 0,
    deleteLeg: false,
    legSelectedForDelete: "",
    legEditDialog: false,
    legSelectedForEdit: "",
    dialogHolder: false,
    activatedStatus: 0,
    connectedStatus: 0,
    phone_number: "",
    driver_name: "",
    getemailsdata: "",
    driver_id: "",
    details: {
      id: "",
      name: "",
      trucker_id: "",
      email: "",
      lat: "",
      long: "",
      user_id: "",
      phone_number: "",
      last_name: "",
      status: 0,
      driver: {},
    },
    datapass: [],
    getphone: "09109916799",

    page: 1,
    pageCount: 0,
    itemsPerPage: 8,
    thecurrent: { page: 0 },
    startfetching: false,
    DriverData: [],
    search: "",
    //the_checkclass:'off_value',
    the_checkclass: "reportscheduletag",
    ON_TEXT: "ON",
    headers: [
      {
        text: "Schedule ID",
        align: "start",
        value: "unique_id",
        sortable: true,
        width: "10%",
        fixed: true,
      },
      {
        text: "Container/Ref#",
        align: "start",
        value: "shiflcontainer",
        sortable: false,
        width: "10%",
        fixed: true,
      },
      {
        text: "Pickup On",
        align: "start",
        value: "pickup_scheduled_date_time",
        sortable: false,
        width: "19%",
        fixed: true,
      },
      {
        text: "Delivery On",
        align: "start",
        value: "delivery_scheduled_date_time",
        sortable: false,
        width: "19%",
        fixed: true,
      },
      {
        text: "Status",
        align: "center",
        value: "driver_status",
        sortable: false,
        width: "6%",
        fixed: true,
      },
      {
        text: "",
        align: "start",
        value: "actions",
        sortable: false,
        width: "8%",
        fixed: true,
      },
    ],
    isShowArchivedWarning: false,
    archivedLoading: false,
    currentDriverSelected: null,
  }),
  computed: {
    ...mapGetters({
      getDrivers: "drivers/getDrivers",
      getDriverLoading: "drivers/getDriverLoading",
      getBackState: "drivers/getBackState",
      getNotificationStatus: "drivers/getNotificationStatus",
      getpassData: "drivers/getpassData",
      getLocFrom: "drivers/getLocFrom",
      getNoficationResult: "drivers/getNoficationResult",
      getDetailsNotification: "drivers/getDetailsNotification",
      getDriverLegShipment: "drivers/getDriverLegShipment",
    }),
    dropdownStatus: {
      get() {
        return this.activatedStatus == 1 ? "Deactivate" : "Reactivate Account";
      },
    },
    drivers: {
      get() {
        //console.log(this.getDriverLegShipment.drivers);
        return this.getDriverLoading ? [] : this.getDriverLegShipment.drivers;
      },
      set(value) {
        if (value) return [];
      },
    },
  },
  watch: {
    fetchCompleted(c) {
      if (c) {
        this.connectedStatus = c;
      }
    },
  },
  created() {},
  methods: {
    ...mapActions({
      fetchDrivers: "drivers/fetchDrivers",
      saveNotification: "drivers/putNotificationStatus",
      detailNotification: "drivers/detailNotification",
      fetchLegShipment: "drivers/FetchDriverWithLegAndShipment",
      passLocFrom: "drivers/passLocFrom",
    }),
    ...globalMethods,
    closeLegDialog() {
      this.deleteLeg = false;
    },
    closeLegEditDialog() {
      this.legEditDialog = false;
    },
    deleteLegFunction(item) {
      this.legSelectedForDelete = item;
      this.deleteLeg = true;
    },
    editLeg(item) {
      this.legSelectedForEdit = item;
      this.legEditDialog = true;
    },

    deactivateDriver(isShowModal) {
      if (isShowModal) {
        this.isShowArchivedWarning = true;
        this.currentDriverSelected = {
          name: this.driver_name,
          id: this.driverID,
        };
      } else {
        this.archivedLoading = true;
        let url = "deactivateDriver";

        axios
          .put(`${poBaseUrl}/` + url + "/" + this.driverID)
          .then((response) => {
            if (response.status === 200) {
              this.notificationCustom(`Driver has been successfully archived.`);
              this.archivedLoading = false;
              this.closeArchivedDialog();
              this.$router
                .push("/drivers-details/" + this.driverID + `/03`)
                .catch(() => {});
              this.passLocFrom("notactive");
              this.activatedStatus = 0;
            }
          })
          .catch(() => {
            this.archivedLoading = false;
          });
      }
    },
    closeArchivedDialog() {
      this.isShowArchivedWarning = false;
      this.currentDriverSelected = null;
    },
    goBackPage() {
      let isFromArchived = localStorage.getItem("fromArchived");
      if (isFromArchived === true || isFromArchived === "true") {
        this.$router.push("/driver/archived");
      } else {
        this.$router.push("/drivers/01");
      }

      localStorage.removeItem("fromArchived");
    },
    async sendEmail() {
      await axios
        .post(`${poBaseUrl}/sendEmailDriver`, {
          driverID: this.driverID,
        })
        .then(function (response) {
          if (response) {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    legRefID(item,index) {
      if(item.legID == null){
        let theindex ='';
        if(index + 1 < 10){
          theindex = '0'
        }
        return item.shipment.shifl_ref + '-' + theindex + ( index + 1 )
      }
      return (      
        "<div>" +
        item.legID +
        "</div>"
      );
    },
    containers(item) {
      return (
        "<div>" +
        item.shipment.trucker_container +
        "</div>" +
        "<div>" +
        item.shipment.shifl_ref +
        "</div>"
      );
    },
    pickupon(item) {
      var svg =
        '<svg style="position: relative;top:3px;margin-right:5px" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        '<path d="M8.00005 0.799988C11.9766 0.799988 15.2 4.02415 15.2 7.99999C15.2 11.9758 11.9766 15.2 8.00005 15.2C4.02349 15.2 0.800049 11.9758 0.800049 7.99999C0.800049 4.02415 4.02349 0.799988 8.00005 0.799988ZM8.00005 2.00023C4.69165 2.00023 2.00029 4.69159 2.00029 7.99999C2.00029 11.3084 4.69165 13.9997 8.00005 13.9997C11.3084 13.9997 13.9998 11.3084 13.9998 7.99999C13.9998 4.69159 11.3084 2.00023 8.00005 2.00023ZM7.46005 3.67999C7.73329 3.67999 7.95934 3.88327 7.99512 4.14675L8.00005 4.21999V7.99999H10.34C10.6381 7.99999 10.88 8.24191 10.88 8.53999C10.88 8.81323 10.6768 9.03928 10.4133 9.07506L10.34 9.07999H7.46005C7.18681 9.07999 6.96076 8.87671 6.92498 8.61323L6.92005 8.53999V4.21999C6.92005 3.92191 7.16197 3.67999 7.46005 3.67999Z" fill="#819FB2"/></svg>';
      if (item) {
        if (
          item.arrival_at_pickup_location_date_time == null &&
          item.arrival_at_delivery_location_date_time != null
        ) {
          return (
            '<div style="color:#4A4A4A">' +
            item.address +
            "</div>" +
            '<div style="color:#6D858F">' +
            moment(item.arrival_at_delivery_location_date_time)
              .utc(false)
              .format("hh:mm A, MMM D, YYYY") +
            "</div>"
          );
        } else if (
          item.arrival_at_delivery_location_date_time == null &&
          item.arrival_at_pickup_location_date_time == null
        ) {
          return (
            "<div>" +
            item.address +
            "</div>" +
            '<div style="color:#6D858F"><div class="tooltip">' +
            svg +
            '<span class="tooltiptextdriver tooltip-top">Scheduled</span></div>' +
            moment(item.pickup_scheduled_date_time)
              .utc(false)
              .format("hh:mm A, MMM D, YYYY") +
            "</div>"
          );
        } else if (
          item.arrival_at_pickup_location_date_time !== null &&
          item.arrival_at_delivery_location_date_time == null
        ) {
          return (
            '<div style="color:#4A4A4A">' +
            item.address +
            "</div>" +
            '<div style="color:#6D858F">' +
            moment(item.arrival_at_pickup_location_date_time)
              .utc(false)
              .format("hh:mm A, MMM D, YYYY") +
            "</div>"
          );
        }
      }
    },
    deliveryon(item) {
      var svg =
        '<svg style="position: relative;top:3px;margin-right:5px" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        '<path d="M8.00005 0.799988C11.9766 0.799988 15.2 4.02415 15.2 7.99999C15.2 11.9758 11.9766 15.2 8.00005 15.2C4.02349 15.2 0.800049 11.9758 0.800049 7.99999C0.800049 4.02415 4.02349 0.799988 8.00005 0.799988ZM8.00005 2.00023C4.69165 2.00023 2.00029 4.69159 2.00029 7.99999C2.00029 11.3084 4.69165 13.9997 8.00005 13.9997C11.3084 13.9997 13.9998 11.3084 13.9998 7.99999C13.9998 4.69159 11.3084 2.00023 8.00005 2.00023ZM7.46005 3.67999C7.73329 3.67999 7.95934 3.88327 7.99512 4.14675L8.00005 4.21999V7.99999H10.34C10.6381 7.99999 10.88 8.24191 10.88 8.53999C10.88 8.81323 10.6768 9.03928 10.4133 9.07506L10.34 9.07999H7.46005C7.18681 9.07999 6.96076 8.87671 6.92498 8.61323L6.92005 8.53999V4.21999C6.92005 3.92191 7.16197 3.67999 7.46005 3.67999Z" fill="#819FB2"/></svg>';
      if (item) {
        if (
          item.arrival_at_pickup_location_date_time == null &&
          item.arrival_at_delivery_location_date_time != null
        ) {
          return (
            '<div style="color:#4A4A4A">' +
            item.address +
            "</div>" +
            '<div style="color:#6D858F">' +
            moment(item.arrival_at_delivery_location_date_time)
              .utc(false)
              .format("hh:mm A, MMM D, YYYY") +
            "</div>"
          );
        } else if (
          item.arrival_at_delivery_location_date_time == null &&
          item.arrival_at_pickup_location_date_time == null
        ) {
          return (
            '<div style="color:#4A4A4A">' +
            item.address +
            "</div>" +
            '<div style="color:#6D858F"><div class="tooltip">' +
            svg +
            '<span class="tooltiptextdriver tooltip-top">Scheduled</span></div>' +
            moment(item.delivery_scheduled_date_time)
              .utc(false)
              .format("hh:mm A, MMM D, YYYY") +
            "</div>"
          );
        } else if (
          item.arrival_at_pickup_location_date_time !== null &&
          item.arrival_at_delivery_location_date_time == null
        ) {
          return (
            '<div style="color:#4A4A4A">' +
            item.address +
            "</div>" +
            '<div style="color:#6D858F"><div class="tooltip">' +
            svg +
            '<span class="tooltiptextdriver tooltip-top">Scheduled</span></div>' +
            moment(item.delivery_scheduled_date_time)
              .utc(false)
              .format("hh:mm A, MMM D, YYYY") +
            "</div>"
          );
        }
      }
    },
    async ONandOFF() {
      await this.saveNotification(this.$route.params.id);
      if (this.getNoficationResult == "completed") {
        await this.detailNotification({ id: this.$route.params.id });
      }
    },
    async runAction(action) {
      if (action == "Connect") {
        this.connectDriver(this.driverID);
      } else {
        let dis = this;
        let url =
          action == "Disconnect"
            ? "disconnectDriver"
            : action === "Deactivate"
            ? "deactivateDriver"
            : "activateDriver";
        await axios
          .put(`${poBaseUrl}/` + url + "/" + this.driverID)
          .then(function (response) {
            if (response.status === 200) {
              dis.connectedStatus = action === "Disconnect" ? 0 : 1;
              dis.activatedStatus = action === "Deactivate" ? 0 : 1;

              if (action === "Activate") {
                dis.$router
                  .push("/drivers-details/" + dis.driverID + `/02`)
                  .catch(() => {});
                dis.passLocFrom("notconnected");
                dis.connectedStatus = 0;
                dis.notificationCustom(`Driver has been activated.`);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    async showLegs() {
      await axios
        .get(`${poBaseUrl}/getDriverDispatch`)
        .then(function (response) {
          if (response) {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getcurrentpage(current) {
      this.thecurrent = { page: -1 };
      this.$nextTick(() => {
        this.thecurrent = { page: current };
      });
    },
    checktheclass() {
      return this.getDetailsNotification.state == 1
        ? "reportscheduletag"
        : "off_value";
    },
    onAndOffText() {
      return this.getDetailsNotification.state == 1 ? "ON" : "OFF";
    },
    onAndOffDropdown() {
      return this.getDetailsNotification.state == 1 ? "Turn Off" : "Turn On";
    },
    connectDriver(id) {
      this.dialogHolder = true;
      this.driverID = id;
    },
  },
  async mounted() {
    await this.fetchLegShipment({ id: this.$route.params.id });
    let datapass = this.getpassData;
    //let getLocFrom = this.getLocFrom;

    // notactive == getLocFrom;
    let dis = this;

    //getpassData.name
    if (datapass.length < 1) {
      let url = "getsingleDriverByID";
      await this.detailNotification({ id: this.$route.params.id });
      await axios
        .get(`${poBaseUrl}/` + url + "/" + this.$route.params.id)
        .then(function (response) {
          if (response) {
            if (typeof response.data.drivers != "undefined") {
              let lastname = "";
              if (
                response.data.drivers[0].last_name !== null &&
                response.data.drivers[0].last_name !== ""
              ) {
                lastname = " " + response.data.drivers[0].last_name;
              } else {
                lastname = "";
              }
              dis.driver_name = response.data.drivers[0].name + lastname;
              dis.driver_id =
                response.data.drivers[0].driver !== null
                  ? response.data.drivers[0].driver.dah_id
                  : null;
              dis.phone_number = response.data.drivers[0].phone_number;
              dis.getemailsdata = response.data.drivers[0].email;
              dis.driverID = response.data.drivers[0].id;

              if (response.data.drivers[0].driver != null) {
                dis.connectedStatus = 1; // connected
              } else if (response.data.drivers[0].driver == null) {
                dis.connectedStatus = 0; // disconnected
              }

              if (response.data.drivers[0].status) {
                dis.activatedStatus = 1; // reverse action
              } else {
                dis.activatedStatus = 0; // reverse
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let lastname = "";
      if (datapass.last_name !== null && datapass.last_name !== "") {
        lastname = " " + datapass.last_name;
      } else {
        lastname = "";
      }
      dis.driverID = datapass.id;
      dis.driver_name = datapass.name + lastname;
      // dis.driver_id    = datapass.driver.dah_id
      dis.phone_number = datapass.phone_number;
      dis.getemailsdata = datapass.email;

      if (typeof datapass.driver !== "undefined" && datapass.driver !== null) {
        dis.driver_id = datapass.driver.dah_id;
      }

      if (datapass.driver != null) {
        dis.connectedStatus = 1; // connected
      } else {
        dis.connectedStatus = 0; // disconnected
      }

      // check if deactivated or activated
      if (datapass.status) {
        dis.activatedStatus = 1; // activated
      } else {
        dis.activatedStatus = 0; // deactivated
      }
    }

    this.ON_TEXT = this.getDetailsNotification.state == 1 ? "ON" : "OFF";
    this.labelnotification =
      this.getDetailsNotification.state == 1 ? "Turn Off" : "Turn On";
    this.completed = 1;

    let currentRouteTab = this.$router.currentRoute.params.sid;

    if (currentRouteTab === "03") {
      await this.passLocFrom("notactive");
      this.activatedStatus = 0;
    } else {
      if (currentRouteTab === "01") {
        await this.passLocFrom("connected");
      } else {
        await this.passLocFrom("notconnected");
        this.connectedStatus = 0;
      }
    }

    //set current page
    this.$store.dispatch("page/setPage", "drivers");
  },
  updated() {},
};
</script>
<style>
.status-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  border-radius: 4px;
  /* width: 40px; */
}
.accept-div {
  background-color: #ebfaef;
}
.cancel-div {
  background-color: #fff2f2;
}
.pending-div {
  background-color: #fff9f0;
}
.accept-txt {
  color: #16b442 !important;
}
.cancel-txt {
  color: #f93131 !important;
}
.pending-txt {
  color: #d68a1a !important;
}

.classsort {
  background: url("../../../assets/icons/Icon_sort.svg") no-repeat !important;
  margin-left: 4px;
  width: 21px;
  height: 17px;
  display: block;
  position: relative;
  top: 3px;
  left: 1px;
  float: right;
}

.assignUnassignFilter {
  display: flex;
  border: 1px solid #b4cfe0;
  border-radius: 5px;
  margin: 0;
  margin-left: 0px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0px;
  caret-color: transparent;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.assignUnassignFilter div.active {
  background-color: #ebf2f5 !important;
  color: #0171a1;
}

.assignUnassignFilter div:first-child {
  padding: 8px 10px 8px 9px !important;
  border-radius: 5px 0 0 5px !important;
}

.assignUnassignFilter div:hover {
  cursor: pointer;
  background-color: #ebf2f5 !important;
}

.assignUnassignFilter div {
  padding: 8px 10px 8px 12px !important;
  border-right: 1px solid #b4cfe0;
  font-size: 12px;
  color: #4a4a4a;
  -moz-user-select: none;
  -ms-user-select: none;
}

.trucking_DropDown .v-list-item__title {
  width: 100% !important;
  color: #4a4a4a !important;
}

.gobacknow {
  font-size: 14px;
  text-decoration: none !important;
}
.classHeadTop {
  padding: 12px;
  padding-bottom: 12px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
}

.connectedDriver {
  background: #f0fbff;
  /* Blue/Dark Blue */
  border: 1px solid #0171a1;
}

.notconnectedDriver {
  background: #fff2f2;
  /* Accent/Red */
  border: 1px solid #f93131;
}

.notactiveDriver {
  background: #f7f7f7;
  /* BW/Border Grey */
  border: 1px solid #d8e7f0;
}

.commonDriverStatus {
  font-size: 10px;
  border-radius: 17px;
  text-transform: uppercase;
  padding-left: 11px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  position: relative;
  top: -6px;

  border-radius: 99px;
  margin-right: 12px;
  margin-left: 8px;
}
</style>
<style lang="scss">
.containerdetailheader {
  background: none !important;
  padding-left: 0px;
  .v-toolbar__content {
    padding-left: 0px !important;
    padding-right: 0px !important;
    .titleheader {
      width: 100%;
      .reportscheduletag {
        font-size: 10px;
        border: 1px solid #16b442;
        border-radius: 17px;
        text-transform: uppercase;
        padding-left: 11px;
        padding-right: 12px;
        padding-top: 8px;
        padding-bottom: 8px;
        background: #ebfaef;
        position: relative;
        top: -6px;
        .scheduletext {
          color: #4a4a4a;
          font-weight: 600;
        }
        .ON_text {
          color: #16b442;
          margin-left: 5px;
          margin-right: 4px;
          font-weight: 600;
        }
      }

      .off_value {
        font-size: 10px;
        border: 1px solid #d8e7f0;
        border-radius: 17px;
        text-transform: uppercase;
        padding-left: 11px;
        padding-right: 12px;
        padding-top: 8px;
        padding-bottom: 8px;
        background: #fff;
        position: relative;
        top: -6px;
        .scheduletext {
          color: #4a4a4a;
          font-weight: 600;
        }
        .ON_text {
          color: #6d858f;
          margin-left: 5px;
          margin-right: 4px;
          font-weight: 600;
        }
      }
    }
  }

  .btn-white.button-more-details-driver {
    min-width: 40px !important;
    padding: 4px !important;
  }
}

.driver-content-wrapper {
  .driver-details-table {
    .v-toolbar {
      height: 54px !important;

      .v-toolbar__content {
        height: 54px !important;
        border-bottom: 2px solid #ebf2f5 !important;

        .v-tabs {
          .v-tab {
            padding: 0 20px !important;
            letter-spacing: 0 !important;
            font-family: "Inter-Medium", sans-serif !important;
            font-weight: unset !important;
            color: #4a4a4a !important;
            text-transform: capitalize !important;

            &.v-tab--active {
              color: #0171a1 !important;
            }
          }
        }
      }
    }

    .v-data-table__wrapper {
      height: calc(100vh - 350px);
      overflow-y: auto;

      table {
        thead {
          tr {
            th {
              box-shadow: none !important;
              border-bottom: 2px solid #ebf2f5 !important;
              height: 40px;
              padding: 8px 10px !important;
              text-transform: capitalize;
              font-size: 12px !important;
              color: #6d858f !important;

              span {
                font-size: 12px !important;
                color: #6d858f !important;
              }

              &:first-child {
                padding-left: 16px !important;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              border-bottom: 1px solid #ebf2f5 !important;
              padding: 10px !important;
              font-size: 14px;

              &:first-child {
                padding-left: 16px !important;
              }

              .action-button-wrapper-driver {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-right: 8px;

                .btn-white {
                  height: 34px !important;
                  min-width: 34px !important;
                  padding: 8px !important;
                  margin-left: 8px !important;

                  &.reinvite-invite {
                    padding: 8px 12px !important;
                    font-size: 12px !important;
                  }
                }
              }
            }

            &.v-data-table__empty-wrapper {
              cursor: unset;

              &:hover {
                background-color: #fff !important;
              }

              td {
                border-bottom: none !important;
              }
            }
          }
        }
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #d3d3d3;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #d3d3d3;
      }
    }
  }

  .pagination-wrapper {
    border: 1px solid #ebf2f5 !important;
  }
}

.v-menu__content {
  &.driver-table-menu-details {
    min-width: 165px !important;

    .driver-item-wrapper {
      min-height: 38px;
      cursor: pointer;

      .v-list-item__title {
        font-size: 14px !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}

.Headerbottomtext {
  font-size: 12px !important;
  margin-top: 7px;
  padding-left: 0px;
}

.telephoneholder {
  padding-left: 20px;
  padding-right: 19px;
  img {
    position: relative;
    top: 3px;
  }
}

.emailholder {
  padding-left: 5px;
  img {
    position: relative;
    top: 3px;
  }
}

.titleheader {
  padding-left: 0px;
}

.gobacknow {
  text-decoration: none !important;
  font-size: 14px;
}
.mdi-arrow-up {
  display: none !important;
}
.font-medium {
  color: #4a4a4a;
}
.containerID {
  color: #6d858f;
}
.classsort {
  background: url("../../../assets/icons/Icon_sort.svg") no-repeat !important;
  margin-left: 4px;
  width: 21px;
  height: 17px;
  display: block;
  position: relative;
  top: 3px;
  left: 1px;
  float: right;
}

.tabcontainers {
  border-bottom: 2px solid #ebf2f5;
}
.title-tab {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  text-transform: none !important;
}

.table_customer {
  margin-top: 0px;
}

.table_customer table thead th {
  text-transform: none !important;
  font-size: 14px !important;
}
.shiflkey {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-left: 6px;
  color: #4a4a4a;
}

.headtextlist {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0171a1;
  cursor: pointer;
}
.listmenuborderline {
  border-bottom: 1px solid #eee;
  margin-bottom: 0px;
  padding-bottom: 0px;
  .list_item {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #6d858f;
    margin-bottom: 4px !important;
    padding-bottom: 6px;
  }
}
.listmenu {
  padding-left: 0px !important;
  .cmanage {
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.cmon_item {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.numbericon {
  border-radius: 40px;
  background: rgb(1, 113, 161);
  color: rgb(255, 255, 255);
  width: 21px;
  margin-left: 2px;
  font-size: 12px;
}

.doprodownshow {
  display: inline-block;
}
.scheduled_tag {
  color: #6d858f;
  font-weight: 700;
  font-size: 14px;
}

.v-tooltip__content {
  background: #4a4a4a !important;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #4a4a4a transparent transparent transparent;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptextdriver {
  visibility: hidden;
  width: 120px;
  background-color: #4a4a4a;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px 0;
  position: absolute;
  z-index: 999;
  bottom: 114%;
  left: 35%;
  margin-left: -60px;

  opacity: 0;
  transition: opacity 1s;
}
.tooltip:hover .tooltiptextdriver {
  visibility: visible;
  opacity: 1;
}
.tooltip .tooltiptextdriver::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #4a4a4a transparent transparent transparent;
}

.tooltip:hover .tooltiptextdriver {
  visibility: visible;
}
</style>
