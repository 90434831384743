<template>
  <div>
    <v-dialog persistent v-model="showEditLegModal" max-width="1200px" content-class="leg-pop-up leg-modal-wrapper edit"
      :retain-focus="false" scrollable>
      <v-card>
        <div class="">
          <v-card-title>
            <!-- <div class="flex">
              <div>
                <span
                  class="text-h5 pl-5 pr-3"
                  style="padding-top: 5px !important; display: inline-block"
                  >Edit Leg - #{{ currentEditingLegNum + 1 > 9 ? "" : "0"
                  }}{{ currentEditingLegNum + 1 }}</span
                >
              </div>
              <div v-if="isCompleted">
                <span class="marked-as-arrived-badge"> Arrived </span>
              </div>
            </div> -->

            <div class="d-flex align-center">
              <!-- <span class="headline" style="font-size: 22px !important;">
                Edit Leg - #{{ currentEditingLegNum + 1 > 9 ? "" : "0" }}{{ currentEditingLegNum + 1 }}
              </span> -->
              <span>
                {{ refNumber + '-' + ((currentEditingLegNum + 1 > 9) ? "" : '0') }}{{ currentEditingLegNum + 1 }}
              </span>
              <div v-if="isCompleted" class="ml-2">
                <span class="marked-as-arrived-badge">
                  Arrived
                </span>
              </div>
            </div>
          </v-card-title>
        </div>
        <v-card-text class="anl-input-group" style="caret-color: transparent; padding: 0">
          <v-form ref="form" @submit.prevent>
            <div style="display: flex; justify-content: center">
              <div style="flex-basis: 32%; font-size: 10px" class="bg-gray px-5 py-4 anl-input-group-left">
                <div style="display: flex" class="mb-2">
                  <div style="flex-basis: 58%; margin-right: 8px;">
                    <div class="dropdown">
                      <div style="color: #819fb2" class="label">
                        Driver
                      </div>
                      <v-autocomplete class="newDriverSelect" v-model="selectedDriver" :items="driverList"
                        item-text="name" item-value="id" placeholder="Select Driver" @input="detectchange" solo
                        append-icon="ic-chevron-down" :rules="requiredRules">
                        <template slot="item" slot-scope="{ parent, item }">
                          <span class="">
                            <!-- {{ parent.genFilteredText(item.name+" "+(item.last_name !== null ? item.last_name : "")) }} -->
                            {{ item.name }}
                            <span v-if="getLinkActive(item)" class="ml-3 warning--text driverStatus">{{
                              parent.genFilteredText(getStatus(item))
                            }}</span>
                            <span v-if="getLinkActive(item)" class="ml-3 mr-5 label activeRef">
                              {{ parent.genFilteredText(getRef(item)) }}
                              <span class="pl-1 linkIcon" @click="handleShipmentLink(getShipment(item))">
                                <svg width="14" height="14" viewBox="0 0 12 12" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M9.5 9.5H2.5V2.5H6V1.5H2.5C1.945 1.5 1.5 1.95 1.5 2.5V9.5C1.5 10.05 1.945 10.5 2.5 10.5H9.5C10.05 10.5 10.5 10.05 10.5 9.5V6H9.5V9.5ZM7 1.5V2.5H8.795L3.88 7.415L4.585 8.12L9.5 3.205V5H10.5V1.5H7Z"
                                    fill="#0171A1" />
                                </svg>
                              </span>
                            </span>
                          </span>
                        </template>
                      </v-autocomplete>
                    </div>
                  </div>
                  <div style="flex-basis: 42%;">
                    <div class="dropdown">
                      <div style="color: #819fb2" class="label">
                        Status
                      </div>
                      <v-autocomplete class="" v-model="driverstatus" :items="driverListstatus" item-text="name"
                        item-value="name" placeholder="Driver Staus" solo append-icon="ic-chevron-down"
                        :rules="[statusValidateaccepted]"></v-autocomplete>
                    </div>
                  </div>
                </div>
                <div style="display: flex" class="mt-2">
                  <div style="flex-basis: 58%; margin-right: 8px">
                    <div class="dropdown">
                      <div style="color: #819fb2" class="pb-1 label">
                        Type of delivery
                      </div>
                      <v-autocomplete class="" :rules="[
                        ...requiredRules,
                        generalRules(selectedType).typeRule,
                      ]" :items="typeDropdown" item-text="label" item-value="value" placeholder="Select Type" solo
                        append-icon="ic-chevron-down" v-model="selectedType"></v-autocomplete>
                    </div>
                  </div>
                  <div style="flex-basis: 42%;">
                    <div class="dropdown">
                      <div style="color: #819fb2" class="pb-1 label">Mode</div>
                      <v-autocomplete class="" :items="modedropdown" item-text="name" item-value="id"
                        placeholder="Select Mode" solo append-icon="ic-chevron-down" v-model="selectedMode"
                        :rules="requiredRules"></v-autocomplete>
                    </div>
                  </div>
                </div>

                <div class="dropdown">
                  <div style="color: #819fb2" class="pb-1 label">Chassis</div>
                  <v-autocomplete ref="chassisDrop" class="" v-model="selectedChassis" :items="chassisList"
                    item-text="name" item-value="id" placeholder="Select Chassis" solo append-icon="ic-chevron-down"
                    :search-input.sync="getSearchedChassis">
                    <div slot="no-data"></div>
                    <div v-if="hasNewChassis" class="v-list-item v-list-item__title add-option-btn py-2"
                      slot="append-item" @click="handleNewChassis" style="font-size: 14px;">
                      <span class="pr-1" style="color: #0171A1;">+ Add New</span>&nbsp;<strong style="color: #4a4a4a;">{{
                        newChassis }}</strong>
                    </div>
                  </v-autocomplete>
                </div>
                <div class="readonly-textarea pr-0 pb-5">
                  <div style="color: #819fb2" class="pb-1 label">
                    Pickup Location
                  </div>
                  <v-textarea
                    class="pickup-location-field"
                    style="font-size: 12px !important"
                    solo
                    label="Pickup location"
                    rows="2"
                    :value="newDataPickuplocation"
                    readonly
                    hide-details="auto"
                    no-resize
                  ></v-textarea>
                </div>
                <div class="textarea">
                  <div style="color: #819fb2" class="pb-1 label">
                    Delivery location
                  </div>
                  <!-- <v-textarea
                  style="font-size: 12px !important"
                  solo
                  label="Delivery location"
                  rows="3"
                  :rules="requiredRules"
                ></v-textarea> -->
                </div>
                <div class="dropdown">
                  <v-autocomplete :loading="loadingLocationPlaces" v-model="address" :items="placesEditLeg"
                    :search-input.sync="searchPlace" item-text="place_name" :label="address" hide-no-data hide-details
                    no-filter clearable @click:clear="clearSearchPlaces" @change="changeDeliveryLocationState" full-width
                    solo :rules="requiredRules" append-icon="ic-chevron-down" class="delivery-location-field">

                    <template slot="selection" slot-scope="{ item }">
                      <div class="pt-1" style="color: #4a4a4a; font-size: 14px; line-height: 20px; width: 100%;">
                        {{ item.place_name }}
                      </div>
                    </template>
                  </v-autocomplete>
                </div>

                <div style="margin-top: 16px" class="driver-notes dropdown pb-2" v-if="leg">
                  <div style="color: #819fb2" class="label">
                    Driver Instructions (optional)
                  </div>
                  <v-textarea v-model="leg.driver_instructions" placeholder="Driver Instructions" rows="2" outlined
                    class="driver-instructions-field" hide-details="auto">
                  </v-textarea>
                </div>
                <div class="checkbox-div" v-if="leg && false">
                  <div class="delivery-order-check">
                    <div class="label custom-label">
                      Driver can see Delivery Order
                    </div>
                    <div>
                      <v-checkbox v-model="leg.driverCanSeeDeliveryOrder" />
                    </div>
                  </div>
                  <div class="delivery-order-check">
                    <div class="label custom-label">POD Required</div>
                    <div>
                      <v-checkbox v-model="leg.pod_required" />
                    </div>
                  </div>
                </div>
              </div>
              <div style="flex-basis: 68%;" class="flex flex-column">
                <div style="display: flex; flex-basis: 65%;" class="anl-input-group-right">
                  <div style="flex-basis: 50%; border-right: 1px solid #ebf2f5;" class="box">
                    <h3 style="font-size: 20px !important; font-family: 'Inter-Medium', sans-serif !important;">Pickup
                    </h3>
                    <div class="datetime-field-groups mt-3">
                      <div style="color: #819fb2" class="label">
                        Pickup Scheduled
                      </div>
                      <div style="display: flex">
                        <div class="vc-date-picker__wrapper" style="width: 50%;">
                          <vc-date-picker title-position="left" is-expanded v-model="pickup_scheduled_date" mode="date"
                            :popover="{ visibility: 'click' }" :firstDayOfWeek="2" :masks="masks"
                            :select-attribute="attribute" @input="(e) => handlePickedDate(e, 'pickup_scheduled_date')"
                            @emptied="(e) => handlePickedDate(e, 'pickup_scheduled_date')" ref="pickup_scheduled_date">

                            <template #default="{ inputValue, inputEvents }">
                              <input :value="inputValue" v-on="inputEvents" class="leg-date-field"
                                placeholder="Select Date" @keyup="(e) => checkKeyUpVal(e, 'pickup_scheduled_date')" />
                              <i class="append-icon ic-calendar"></i>
                            </template>
                          </vc-date-picker>
                        </div>
                        <DxDateBox v-model="pdt" type="time" class="date-field-dxtime" @value-changed="handlePickupTime"
                          :use-mask-behavior="true" display-format="hh:mm a" placeholder="Select Time"
                          :openOnFieldClick="true" :show-clear-button="true" width="50%" />
                      </div>
                      <!-- custom error text wrapper without the error should always show as we don't want a chappy form -->
                      <div class="custom-error-text"> <!-- set height here in style to have space for showing error  -->
                        <div v-show="showError">
                          <span v-show="hasError('pickup_scheduled_date')">
                            {{ typeof dateErrors["pickup_scheduled_date"] !== 'undefined' ?
                              dateErrors["pickup_scheduled_date"].message : '' }}
                          </span>
                          <span v-show="hasError('pickup_scheduled_time')">
                            {{ typeof dateErrors["pickup_scheduled_time"] !== 'undefined' ?
                              dateErrors["pickup_scheduled_time"].message : '' }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="datetime-field-groups mt-2" v-if="showArrivalAtPickup">
                      <div style="color: #819fb2" class="label d-flex justify-space-between align-center">
                        Arrival at Pickup

                        <button type="button" :disabled="isArrivalAtPickupDisabled()" icon dark class="btn-close ml-2"
                          @click="removeField('showArrivalAtPickup')">
                          <v-icon size="18px" :class="checkColorRemoveButton('showArrivalAtPickup')">
                            <!-- :disabled="(showOutgateFromPickup || !justAddShowArrivalPickup)" -->
                            mdi-close
                          </v-icon>
                        </button>
                      </div>
                      <div style="display: flex">
                        <div class="vc-date-picker__wrapper">
                          <vc-date-picker title-position="left" is-expanded v-model="arrival_at_pickup_location_date"
                            mode="date" :popover="{ visibility: 'click' }" :firstDayOfWeek="2" :masks="masks"
                            :select-attribute="attribute"
                            @input="(e) => handlePickedDate(e, 'arrival_at_pickup_location_date')"
                            @emptied="(e) => handlePickedDate(e, 'arrival_at_pickup_location_date')"
                            ref="arrival_at_pickup_location_date">
                            <!-- :min-date='pickup_scheduled_date' -->

                            <template #default="{ inputValue, inputEvents }">
                              <input :value="inputValue" v-on="inputEvents" class="leg-date-field"
                                placeholder="Select Date"
                                @keyup="(e) => checkKeyUpVal(e, 'arrival_at_pickup_location_date')" />
                              <i class="append-icon ic-calendar"></i>
                            </template>
                          </vc-date-picker>
                        </div>
                        <DxDateBox v-model="apdt" type="time" class="date-field-dxtime"
                          @value-changed="handleArrivalPickupTime" :use-mask-behavior="true" display-format="hh:mm a"
                          placeholder="Select Time" :openOnFieldClick="true" />
                      </div>
                      <div class="custom-error-text">
                        <div v-show="showError">
                          <span v-show="hasError('arrival_at_pickup_location_date')">
                            {{ typeof dateErrors["arrival_at_pickup_location_date"] !== 'undefined' ?
                              dateErrors["arrival_at_pickup_location_date"].message : '' }}
                          </span>
                          <span v-show="hasError('arrival_at_pickup_location_time')">
                            {{ typeof dateErrors["arrival_at_pickup_location_time"] !== 'undefined' ?
                              dateErrors["arrival_at_pickup_location_time"].message : '' }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="datetime-field-groups mt-2" v-if="showOutgateFromPickup">
                      <div style="color: #819fb2" class="label d-flex justify-space-between align-center">
                        Outgate From Pickup

                        <button type="button" :disabled="isOutgateFromPickupDisabled()" icon dark class="btn-close ml-2"
                          @click="removeField('showOutgateFromPickup')">
                          <v-icon size="18px" :class="checkColorRemoveButton('showOutgateFromPickup')">
                            mdi-close
                          </v-icon>
                          <!-- :disabled="!justAddShowOutgatePickup" -->
                        </button>
                      </div>
                      <div style="display: flex">
                        <div class="vc-date-picker__wrapper">
                          <vc-date-picker title-position="left" is-expanded v-model="departure_from_pickup_location_date"
                            mode="date" :popover="{ visibility: 'click' }" :firstDayOfWeek="2" :masks="masks"
                            :select-attribute="attribute" :min-date="arrival_at_pickup_location_date"
                            @input="(e) => handlePickedDate(e, 'departure_from_pickup_location_date')"
                            @emptied="(e) => handlePickedDate(e, 'departure_from_pickup_location_date')"
                            ref="departure_from_pickup_location_date">

                            <template #default="{ inputValue, inputEvents }">
                              <input :value="inputValue" v-on="inputEvents" class="leg-date-field"
                                placeholder="Select Date"
                                @keyup="(e) => checkKeyUpVal(e, 'departure_from_pickup_location_date')" />
                              <i class="append-icon ic-calendar"></i>
                            </template>
                          </vc-date-picker>
                        </div>
                        <DxDateBox v-model="ofpdt" type="time" class="date-field-dxtime"
                          @value-changed="handleOutgatePickupTime" :use-mask-behavior="true" display-format="hh:mm a"
                          placeholder="Select Time" :openOnFieldClick="true" />
                      </div>
                      <div class="custom-error-text">
                        <div v-show="showError">
                          <span v-show="hasError('departure_from_pickup_location_date')">
                            {{ typeof dateErrors["departure_from_pickup_location_date"] !== 'undefined' ?
                              dateErrors["departure_from_pickup_location_date"].message : '' }}
                          </span>
                          <span v-show="hasError('departure_from_pickup_location_time')">
                            {{ typeof dateErrors["departure_from_pickup_location_time"] !== 'undefined' ?
                              dateErrors["departure_from_pickup_location_time"].message : '' }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      class="datetime-field-groups mt-2 mb-1"
                      v-if="showOutgateFromPickup"
                    >
                      <div style="color: #819fb2" class="label">
                        Time at pickup
                      </div>
                      <input
                        class="readonly-text-field"
                        :value="getTimeAtPickup"
                        disabled
                        style="border-radius: 4px; min-height: 38px;"
                      /> 
                    </div>-->
                    <v-btn v-if="!showOutgateFromPickup" text color="#0171A1" class="button-outline btn-white"
                      style="width: 100%; margin-top: 20px" @click="addPickup">
                      <span style="font-size: 12px" class="text-uppercase">
                        + {{ addPickupButtonText }}
                      </span>
                    </v-btn>
                  </div>
                  <div style="flex-basis: 50%; border-left: 1px solid #ebf2f5" class="box">
                    <h3 style="font-size: 20px !important; font-family: 'Inter-Medium', sans-serif !important;">Delivery
                    </h3>
                    <div class="datetime-field-groups mt-3">
                      <div style="color: #819fb2" class="label">
                        Delivery Scheduled
                      </div>
                      <div style="display: flex">
                        <div class="vc-date-picker__wrapper" style="width: 50%;">
                          <vc-date-picker title-position="left" is-expanded v-model="delivery_scheduled_date" mode="date"
                            :popover="{ visibility: 'click' }" :firstDayOfWeek="2" :masks="masks"
                            :select-attribute="attribute" @input="(e) => handlePickedDate(e, 'delivery_scheduled_date')"
                            @emptied="(e) => handlePickedDate(e, 'delivery_scheduled_date')"
                            ref="delivery_scheduled_date">

                            <template #default="{ inputValue, inputEvents }">
                              <input :value="inputValue" v-on="inputEvents" class="leg-date-field"
                                placeholder="Select Date" @keyup="(e) => checkKeyUpVal(e, 'delivery_scheduled_date')" />
                              <i class="append-icon ic-calendar"></i>
                            </template>
                          </vc-date-picker>
                        </div>
                        <DxDateBox v-model="ddt" type="time" class="date-field-dxtime" @value-changed="handleDeliveryTime"
                          :use-mask-behavior="true" display-format="hh:mm a" placeholder="Select Time"
                          :openOnFieldClick="true" :show-clear-button="true" width="50%" />
                      </div>
                      <div class="custom-error-text">
                        <div v-show="showError">
                          <span v-show="hasError('delivery_scheduled_date')">
                            {{ typeof dateErrors["delivery_scheduled_date"] !== 'undefined' ?
                              dateErrors["delivery_scheduled_date"].message : '' }}
                          </span>
                          <span v-show="hasError('delivery_scheduled_time')">
                            {{ typeof dateErrors["delivery_scheduled_time"] !== 'undefined' ?
                              dateErrors["delivery_scheduled_time"].message : '' }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="datetime-field-groups mt-2" v-if="showArrivalAtDelivery">
                      <div style="color: #819fb2" class="label d-flex justify-space-between align-center">
                        Arrival at Delivery

                        <button type="button" :disabled="isArrivalAtDeliveryDisabled()" icon dark class="btn-close ml-2"
                          @click="removeField('showArrivalAtDelivery')">
                          <!-- <v-icon size="18px" color="#F93131">mdi-close</v-icon> -->
                          <v-icon size="18px" :class="checkColorRemoveButton('showArrivalAtDelivery')">
                            mdi-close
                          </v-icon>
                        </button>
                        <!-- :disabled="(showOutgateFromDelivery || !justAddShowArrivalAtDelivery)" -->
                      </div>
                      <div style="display: flex">
                        <div class="vc-date-picker__wrapper">
                          <vc-date-picker title-position="left" is-expanded v-model="arrival_at_delivery_location_date"
                            mode="date" :popover="{ visibility: 'click' }" :firstDayOfWeek="2" :masks="masks"
                            :select-attribute="attribute" :min-date="arrival_at_pickup_location_date"
                            @input="(e) => handlePickedDate(e, 'arrival_at_delivery_location_date')"
                            @emptied="(e) => handlePickedDate(e, 'arrival_at_delivery_location_date')"
                            ref="arrival_at_delivery_location_date">

                            <template #default="{ inputValue, inputEvents }">
                              <input :value="inputValue" v-on="inputEvents" class="leg-date-field"
                                placeholder="Select Date"
                                @keyup="(e) => checkKeyUpVal(e, 'arrival_at_delivery_location_date')" />
                              <i class="append-icon ic-calendar"></i>
                            </template>
                          </vc-date-picker>
                        </div>
                        <DxDateBox v-model="addt" type="time" class="date-field-dxtime"
                          @value-changed="handleArrivalDeliveryTime" :use-mask-behavior="true" display-format="hh:mm a"
                          placeholder="Select Time" :openOnFieldClick="true" />
                      </div>
                      <div class="custom-error-text">
                        <div v-show="showError">
                          <span v-show="hasError('arrival_at_delivery_location_date')">
                            {{ typeof dateErrors["arrival_at_delivery_location_date"] !== 'undefined' ?
                              dateErrors["arrival_at_delivery_location_date"].message : '' }}
                          </span>
                          <span v-show="hasError('arrival_at_delivery_location_time')">
                            {{ typeof dateErrors["arrival_at_delivery_location_time"] !== 'undefined' ?
                              dateErrors["arrival_at_delivery_location_time"].message : '' }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="datetime-field-groups mt-2" v-if="showOutgateFromDelivery">
                      <div style="color: #819fb2" class="label d-flex justify-space-between align-center">
                        Outgate From Delivery

                        <button type="button" :disabled="isOutgateFromDeliveryDisabled()" icon dark class="btn-close ml-2"
                          @click="removeField('showOutgateFromDelivery')">
                          <!-- <v-icon size="18px" color="#F93131">mdi-close</v-icon> -->
                          <v-icon size="18px" :class="checkColorRemoveButton('showOutgateFromDelivery', 'class')">
                            mdi-close
                          </v-icon>
                          <!-- :disabled="!justAddShowOutgateDelivery" -->
                        </button>
                      </div>
                      <div style="display: flex">
                        <div class="vc-date-picker__wrapper">
                          <vc-date-picker title-position="left" is-expanded
                            v-model="departure_from_delivery_location_date" mode="date" :popover="{ visibility: 'click' }"
                            :firstDayOfWeek="2" :masks="masks" :select-attribute="attribute"
                            :min-date="departure_from_pickup_location_date"
                            @input="(e) => handlePickedDate(e, 'departure_from_delivery_location_date')"
                            @emptied="(e) => handlePickedDate(e, 'departure_from_delivery_location_date')"
                            ref="departure_from_delivery_location_date">

                            <template #default="{ inputValue, inputEvents }">
                              <input :value="inputValue" v-on="inputEvents" class="leg-date-field"
                                placeholder="Select Date"
                                @keyup="(e) => checkKeyUpVal(e, 'departure_from_delivery_location_date')" />
                              <i class="append-icon ic-calendar"></i>
                            </template>
                          </vc-date-picker>
                        </div>
                        <DxDateBox v-model="dddt" type="time" class="date-field-dxtime"
                          @value-changed="handleDepartureDeliveryTime" :use-mask-behavior="true" display-format="hh:mm a"
                          placeholder="Select Time" :openOnFieldClick="true" />
                      </div>
                      <div class="custom-error-text">
                        <div v-show="showError">
                          <span v-show="hasError('departure_from_delivery_location_date')">
                            {{ typeof dateErrors["departure_from_delivery_location_date"] !== 'undefined' ?
                              dateErrors["departure_from_delivery_location_date"].message : '' }}
                          </span>
                          <span v-show="hasError('departure_from_delivery_location_time')">
                            {{ typeof dateErrors["departure_from_delivery_location_time"] !== 'undefined' ?
                              dateErrors["departure_from_delivery_location_time"].message : '' }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      class="datetime-field-groups mt-2 mb-1"
                      v-if="showOutgateFromDelivery"
                    >
                      <div style="color: #819fb2" class="label">
                        Time at Delivery
                      </div>
                      <input
                        class="readonly-text-field"
                        :value="getTimeAtDelivery"
                        disabled
                        style="border-radius: 4px; min-height: 38px;"
                      />
                    </div> -->
                    <v-btn v-if="!showOutgateFromDelivery" text color="#0171A1" class="button-outline btn-white"
                      style="width: 100%; margin-top: 20px" @click="addDelivery">
                      <span style="font-size: 12px" class="text-uppercase">
                        + {{ addDeliveryButtonText }}
                      </span>
                    </v-btn>
                  </div>
                </div>
                <div style="flex-basis: 35%; line-height: 15px; width: 100%;" class="align-end bg-gray pt-4 pb-2">

                  <div class="details-wrapper d-flex">
                    <div style="width: 35%;" class="details-left-column px-3">
                      <div class="details-content">
                        <div class="details-title">Container #</div>
                        <div class="details-data">{{ getContainerNo() }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">Cont. Size</div>
                        <div class="details-data">{{ getContSizeandWeight('size') }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">Cont. Weight</div>
                        <div class="details-data">{{ getContSizeandWeight('weight') }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">MBL</div>
                        <div class="details-data">{{ getMBLandCarrier('mbl') }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">Carrier</div>
                        <div class="details-data">{{ getMBLandCarrier('carrier') }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">ETA</div>
                        <div class="details-data">{{ getETA() }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">LFD - Pickup</div>
                        <div class="details-data">{{ getLFD() }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">LFD - Return</div>
                        <div class="details-data">{{ getPDD() }}</div>
                      </div>
                    </div>

                    <div style="width: 65%;" class="details-right-column px-3">
                      <div class="details-content">
                        <div class="details-title">Time at pickup</div>
                        <div class="details-data">{{ getTimeAtPickup }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">Time at Delivery</div>
                        <div class="details-data">{{ getTimeAtDelivery }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">Customer</div>
                        <div class="details-data">{{ getCustomer() }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">Delivery To</div>
                        <div class="details-data">{{ getDeliveryTo() }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">Terminal at</div>
                        <div class="details-data">{{ getTerminal() }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">Notes</div>
                        <div class="details-data">{{ getNotes() }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-form>
        </v-card-text>
        <!-- // actions  -->
        <v-card-actions>
          <v-btn @click="edit" class="btn-blue">
            <span>
              <span> Ok </span>
            </span>
          </v-btn>
          <v-btn style="margin-left: 0px !important" class="btn-white" text @click="close">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CreatePicker :dialogDatepicker="dialogDatepicker" :defaultData="pickerDefaultdata" @passbackdata="getdata"
      @close="closeDialogpicker" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import _ from "lodash";
import CreatePicker from "../../../Createpicker.vue";
import moment from "moment";
import DxDateBox from 'devextreme-vue/date-box';

import axios from "axios";
const poBaseUrl = process.env.VUE_APP_PO_URL;
import globalMethods, { convertDateTimeToUTC } from "@/utils/globalMethods";

// for cancelling fetch requests
let controller, signal

export default {
  name: "EditLeg",
  props: [
    "showEditLegModal",
    "driverList",
    "chassisList",
    "modedropdown",
    "typeDropdown",
    "pickupLocation", //old 
    "leg",
    "currentEditingLegNum",
    "dialogData",
    "responseChassis",
    "responseObjectMeta",
    "eta",
    "dispatchData",
    "markasrrive",
    "editIndex",
    "fromDispatch",
    "shouldClearJustAddedFields",
    'getpickupLocation', // new,
    'refNumber',
    'shipmentData',
    'editingLocation'
  ],
  components: {
    CreatePicker,
    DxDateBox
  },
  data() {
    return {
      pickupLocationinline:'',
      newDataPickuplocation:'',
      newdatetimepickup: '',
      driver_instructions: '',
      dateFrom: '',
      typingTimeout: 0,
      Show_Error_IfPending_Arrived: 0,
      callApiForDeliveryLoc: true,
      etaifEmpty: "",
      driverstatus: "Pending",
      driverListstatus: ["Pending", "Accepted", "Declined"],
      drivernewvalue: "",
      pickerDefaultdata: "",
      dialogDatepicker: false,
      currentstatepicker: "",
      searchDeliveryPlace: "",
      searchPlace: "",
      placesEditLeg: [],
      loadingLocationPlaces: false,
      selectedDriver: "",
      modal_ps_date: false,
      pickup_scheduled_date: "",
      modal_ps_time: false,
      pickup_scheduled_time: null,
      modal_ds_date: false,
      delivery_scheduled_date: "",
      modal_ds_time: false,
      delivery_scheduled_time: null,
      modal_aapld: false,
      arrival_at_pickup_location_date: "",
      modal_aaplt: false,
      arrival_at_pickup_location_time: null,
      modal_dfpld: false,
      departure_from_pickup_location_date: "",
      modal_dfplt: false,
      departure_from_pickup_location_time: null,
      modal_aadld: false,
      arrival_at_delivery_location_date: "",
      modal_aadlt: false,
      arrival_at_delivery_location_time: null,
      modal_dfdld: false,
      departure_from_delivery_location_date: "",
      modal_dfdlt: false,
      departure_from_delivery_location_time: null,
      address: "",
      selectedMode: "",
      selectedType: "",
      requiredRules: [(v) => !!v || v === 0 || "This field is required"],
      showArrivalAtPickup: false,
      showOutgateFromPickup: false,
      showArrivalAtDelivery: false,
      showOutgateFromDelivery: false,
      errors: {
        pickup_scheduled: "",
        delivery_scheduled: "",
        pickup_outgate: "",
        delivery_outgate: "",
        delivery_arrival: "",
      },
      custom_error_show: false,
      selectedChassis: "",
      newChassis: "",
      hasNewChassis: 0,
      oldAddress: "",
      addressLongitude: null,
      addressLatitude: null,
      address_model_type: null,
      address_model_id: null,
      attribute: {
        highlight: {
          style: {
            backgroundColor: '#0171A1', // blue
            borderRadius: '4px'
          },
          contentStyle: {
            color: '#ffffff', // color of the text
            borderRadius: '4px'
          }
        },
      },
      masks: {
        input: 'MM/DD/YY',
        weekdays: "WWW"
      },
      dateErrors: {},
      closeButtonColor: {},
      showError: false,
      submitting: "",
      justAddShowArrivalPickup: false,
      justAddShowOutgatePickup: false,
      justAddShowArrivalAtDelivery: false,
      justAddShowOutgateDelivery: false,
      //pickupLocation: "",
      legCopyData: null
    };
  },
  watch: {
    // leg(newVal) {
    //   // FIX ALEX WRONG DATE INDEX AND MODAL
    //   if (_.isEmpty(newVal)) return;
    //   //alert(newVal.address)
    //   this.selectedDriver = newVal.driver ? newVal.driver : 0;
    //   this.address = newVal.address || "";
    //   this.selectedMode = newVal.mode ? (newVal.mode === "Live" ? 2 : 1) : "";
    //   this.selectedType = newVal.type;
    //   this.driverstatus = newVal.driver_status;
    //   this.pickup_scheduled_date = moment.utc(newVal.from_date).format('MM/DD/YYYY');
    //   // this.pickup_scheduled_time = newVal.to_time;
    //   this.pickup_scheduled_time = this.formatTime(newVal.to_time, "12");
    //   this.newdatetimepickup = this.pickup_scheduled_time
    //   this.delivery_scheduled_date = newVal.date !== "" && newVal.date !== "Invalid date" ? moment.utc(newVal.date.substr(0, 10)).format('MM/DD/YYYY') : "";
    //   // this.delivery_scheduled_time = newVal.time;
    //   this.delivery_scheduled_time = this.formatTime(newVal.time, "12");
    //   this.arrival_at_pickup_location_date = moment.utc(newVal.arrival_at_pickup_location_date).format('MM/DD/YYYY');

    //   // this.arrival_at_pickup_location_time = newVal.arrival_at_pickup_location_time;
    //   this.arrival_at_pickup_location_time = this.formatTime(
    //     newVal.arrival_at_pickup_location_time,
    //     "12"
    //   );
    //   this.departure_from_pickup_location_date = moment.utc(newVal.departure_from_pickup_location_date).format('MM/DD/YYYY');

    //   // this.departure_from_pickup_location_time = newVal.departure_from_pickup_location_time;
    //   this.departure_from_pickup_location_time = this.formatTime(
    //     newVal.departure_from_pickup_location_time,
    //     "12"
    //   );
    //   this.arrival_at_delivery_location_date = moment.utc(newVal.arrival_at_delivery_location_date).format('MM/DD/YYYY');

    //   // this.arrival_at_delivery_location_time = newVal.arrival_at_delivery_location_time;
    //   this.arrival_at_delivery_location_time = this.formatTime(
    //     newVal.arrival_at_delivery_location_time,
    //     "12"
    //   );
    //   this.departure_from_delivery_location_date = moment.utc(newVal.departure_from_delivery_location_date).format('MM/DD/YYYY')

    //   // this.departure_from_delivery_location_time = newVal.departure_from_delivery_location_time;
    //   this.departure_from_delivery_location_time = this.formatTime(
    //     newVal.departure_from_delivery_location_time,
    //     "12"
    //   );

    //   /**
    //    * Will comment this out and change the conditions
    //    */
    //   // this.showArrivalAtPickup =
    //   //   newVal.arrival_at_pickup_location_date ||
    //   //   newVal.arrival_at_pickup_location_time
    //   //     ? true
    //   //     : false;
    //   // this.showOutgateFromPickup =
    //   //   newVal.departure_from_pickup_location_date ||
    //   //   newVal.departure_from_pickup_location_time
    //   //     ? true
    //   //     : false;
    //   // this.showArrivalAtDelivery =
    //   //   newVal.arrival_at_delivery_location_date ||
    //   //   newVal.arrival_at_delivery_location_time
    //   //     ? true
    //   //     : false;
    //   // this.showOutgateFromDelivery =
    //   //   newVal.departure_from_delivery_location_date ||
    //   //   newVal.departure_from_delivery_location_time
    //   //     ? true
    //   //     : false;

    //   this.showArrivalAtPickup =  this.leg.arrival_at_pickup_location_date === "" || 
    //                               this.leg.arrival_at_pickup_location_date === null ? false : true
    //   this.showArrivalAtDelivery =  this.leg.arrival_at_delivery_location_date === "" || 
    //                               this.leg.arrival_at_delivery_location_date === null ? false : true
    //   this.showOutgateFromPickup = this.leg.departure_from_pickup_location_date === "" || 
    //                               this.leg.departure_from_pickup_location_date === null ? false : true
    //   this.showOutgateFromDelivery = this.leg.departure_from_delivery_location_date === "" || 
    //                               this.leg.departure_from_delivery_location_date === null ? false : true

    //   this.selectedChassis = newVal.chassis ? newVal.chassis : "";
    //   this.oldAddress = this.address;
    //   this.addressLongitude = newVal.addressLongitude || null;
    //   this.addressLatitude = newVal.addressLatitude || null;
    //   this.address_model_type = newVal.address_model_type || null;
    //   this.address_model_id = newVal.address_model_id || null;
    // },
    leg(newVal) {
      if (_.isEmpty(newVal)) return;
      this.newDataPickuplocation = ''
      console.log("EditLeg newVal");
      
      if(typeof  this.shipmentData !='undefined'){
      let parseDispatch = JSON.parse( this.shipmentData.dispatch_schedule );
           
      if( parseDispatch.legs.length > 0 ){
        var index = parseDispatch.legs.findIndex(img => img.id === newVal.id);
        if( index === 0 ){
            // then get the shipment address
            // get the pickup location wit custom 
            if(this.shipmentData.is_custom_starting_location){             
              // this.shipmentData.starting_location_address  
              if(typeof this.editingLocation == 'undefined' ){   
                if(this.newDataPickuplocation == '')  {                  
                  this.newDataPickuplocation = this.shipmentData.starting_location_address;
                }
              }
            }
        }
      }}
     
      this.legCopyData = newVal;

      this.selectedDriver = this.legCopyData.driver ? this.legCopyData.driver : 0;
      this.address = this.legCopyData.address || "";
      this.selectedMode = this.legCopyData.mode ? (this.legCopyData.mode === "Live" ? 2 : 1) : "";
      this.selectedType = this.legCopyData.type;
      this.driverstatus = this.legCopyData.driver_status;

      let pickupDate = this.handleConvertDateTimeToLocal(this.legCopyData.from_date, this.legCopyData.to_time);
      let deliveryDate = this.handleConvertDateTimeToLocal(this.legCopyData.date, this.legCopyData.time);
      let arrivalPickupDate = this.handleConvertDateTimeToLocal(this.legCopyData.arrival_at_pickup_location_date, this.legCopyData.arrival_at_pickup_location_time);
      let departurePickupDate = this.handleConvertDateTimeToLocal(this.legCopyData.departure_from_pickup_location_date, this.legCopyData.departure_from_pickup_location_time);
      let arrivalDeliveryDate = this.handleConvertDateTimeToLocal(this.legCopyData.arrival_at_delivery_location_date, this.legCopyData.arrival_at_delivery_location_time);
      let departureDeliveryDate = this.handleConvertDateTimeToLocal(this.legCopyData.departure_from_delivery_location_date, this.legCopyData.departure_from_delivery_location_time);

      this.pickup_scheduled_date = pickupDate.newDate;
      this.pickup_scheduled_time = pickupDate.newTime;
      this.newdatetimepickup = this.pickup_scheduled_time;
      this.delivery_scheduled_date = deliveryDate.newDate;
      this.delivery_scheduled_time = deliveryDate.newTime;

      this.arrival_at_pickup_location_date = arrivalPickupDate.newDate;
      this.arrival_at_pickup_location_time = arrivalPickupDate.newTime;

      this.departure_from_pickup_location_date = departurePickupDate.newDate;
      this.departure_from_pickup_location_time = departurePickupDate.newTime;

      this.arrival_at_delivery_location_date = arrivalDeliveryDate.newDate;
      this.arrival_at_delivery_location_time = arrivalDeliveryDate.newTime;

      this.departure_from_delivery_location_date = departureDeliveryDate.newDate;
      this.departure_from_delivery_location_time = departureDeliveryDate.newTime;

      this.showArrivalAtPickup =
        this.leg.arrival_at_pickup_location_date === "" ||
          this.leg.arrival_at_pickup_location_date === null ? false : true;

      this.showArrivalAtDelivery =
        this.leg.arrival_at_delivery_location_date === "" ||
          this.leg.arrival_at_delivery_location_date === null ? false : true;

      this.showOutgateFromPickup =
        this.leg.departure_from_pickup_location_date === "" ||
          this.leg.departure_from_pickup_location_date === null ? false : true;

      this.showOutgateFromDelivery =
        this.leg.departure_from_delivery_location_date === "" ||
          this.leg.departure_from_delivery_location_date === null ? false : true;

      this.selectedChassis = this.legCopyData.chassis ? this.legCopyData.chassis : "";
      this.oldAddress = this.address;
      this.addressLongitude = this.legCopyData.addressLongitude || null;
      this.addressLatitude = this.legCopyData.addressLatitude || null;
      this.address_model_type = this.legCopyData.address_model_type || null;
      this.address_model_id = this.legCopyData.address_model_id || null;
    },
    pickup_scheduled_date(c) {
      if (c && !this.custom_error_show) this.custom_error_show = true;
      if (c && _.isEmpty(this.delivery_scheduled_date) && this.validateDate(c))
        this.delivery_scheduled_date = c;

      // let eta = moment(this.eta)
      // let adt = this.arrival_at_pickup_location_date !== "" ? moment(this.arrival_at_pickup_location_date) : ""
      // let pdt = moment(c)
      // if(this.showArrivalAtPickup) {
      //   if(adt !== "") {
      //     if(adt.isBefore(pdt) && pdt.isAfter(eta)) {
      //       this.arrival_at_pickup_location_date = c
      //     }
      //   }
      // }
      this.handlePickedDate(c, 'pickup_scheduled_date')
    },
    delivery_scheduled_date(c) {
      if (c && this.pickup_scheduled_date === "") {
        this.pickup_scheduled_date = c;
      }
      this.handlePickedDate(c, 'delivery_scheduled_date')
    },
    pickup_scheduled_time(c) {
      if (c && !this.custom_error_show) this.custom_error_show = true;
    },
    delivery_scheduled_time(c) {
      if (c && !this.custom_error_show) this.custom_error_show = true;
    },
    departure_from_pickup_location_date(c) {
      if (c && !this.custom_error_show) {
        this.custom_error_show = true;
      }
      this.handlePickedDate(c, 'departure_from_pickup_location_date')
    },
    departure_from_pickup_location_time(c) {
      if (c && !this.custom_error_show) {
        this.custom_error_show = true;
      }
    },
    departure_from_delivery_location_date(c) {
      if (c && !this.custom_error_show) {
        this.custom_error_show = true;
      }
      this.handlePickedDate(c, 'departure_from_delivery_location_date')
    },
    departure_from_delivery_location_time(c) {
      if (c && !this.custom_error_show) {
        this.custom_error_show = true;
      }
    },
    arrival_at_delivery_location_date(c) {
      if (c && !this.custom_error_show) {
        this.custom_error_show = true;
      }
      this.handlePickedDate(c, 'arrival_at_delivery_location_date')
    },
    arrival_at_delivery_location_time(c) {
      if (c && !this.custom_error_show) {
        this.custom_error_show = true;
      }
    },
    arrival_at_pickup_location_date(c) {
      let eta = moment(this.eta)
      let adt = this.arrival_at_delivery_location_date !== "" ? moment(this.arrival_at_delivery_location_date) : ""
      let ddt = this.departure_from_pickup_location_date !== "" ? moment(this.departure_from_pickup_location_date) : ""
      let apt = moment(c)
      if (this.showArrivalAtPickup) {
        if (adt !== "") {
          if (adt.isBefore(apt) && apt.isAfter(eta)) {
            this.arrival_at_delivery_location_date = c
          }
        }
      }
      if (this.showOutgateFromPickup) {
        if (adt !== "") {
          if (ddt.isBefore(apt) && apt.isAfter(eta)) {
            this.departure_from_pickup_location_date = c
          }
        }
      }
      this.handlePickedDate(c, 'arrival_at_pickup_location_date')
    },
    responseChassis: function (val) {
      this.selectedChassis = val.id;
      this.hasNewChassis = 0;
      this.$refs.chassisDrop.blur();
    },
    searchPlace(val) {
      // if (val && this.callApiForDeliveryLoc) {
      //   this.getDeliveryLocation(val);
      // }

      clearTimeout(this.typingTimeout)
      this.typingTimeout = setTimeout(() => {
        this.getDeliveryLocation(val);
      }, 300)
    },
    showEditLegModal(c) {
      if (c) {
        if(this.newDataPickuplocation == ''){        
          this.newDataPickuplocation = typeof this.editingLocation !='undefined' ? this.pickupLocation : this.getpickupLocation;
        }
        this.Show_Error_IfPending_Arrived = 0;
        if (this.markasrrive) {
          this.arrival_at_delivery_location_time = moment().format("HH:mm A");
          this.addDelivery();
        }
        this.etaifEmpty = this.getETA();
        if (typeof this.driverstatus == "undefined") {
          this.driverstatus = "Pending";
        } else {
          this.driverstatus = this.leg.driver_status;
        }
      } else {
        this.showArrivalAtDelivery = false;
      }

      if (typeof this.fromDispatch === "undefined") {
        this.justAddShowArrivalPickup = false
        this.justAddShowOutgatePickup = false
        this.justAddShowArrivalAtDelivery = false
        this.justAddShowOutgateDelivery = false
      }
    },
    /*
      If changed then reset to Pending
     */
    drivernewvalue(newval) {
      if (newval) {
        this.driverstatus = "Pending";
      }
    },

    selectedType(newval) {
      if (_.isEmpty(this.address)) {
        this.setInitAddressDropDown(newval);
      }
      if (newval == "Empty Return") {
        this.pod_required = false;
      } else {
        this.pod_required = true;
      }
    },
    dateErrors: {
      handler(nv) {
        console.log('errors >>>', nv)
      },
      deep: true
    },
    submitting: {
      handler() {
        this.showError = true
      },
      deep: true
    },
    shouldClearJustAddedFields(c) {
      if (c) { // clear all fields
        this.justAddShowArrivalPickup = false
        this.justAddShowOutgatePickup = false
        this.justAddShowArrivalAtDelivery = false
        this.justAddShowOutgateDelivery = false
      }
    },
    // pickupLocation(v) {
    //   console.log('pickup location >>>', v)
    //   if(typeof this.responseObjectMeta[0]?.deliver_to !== "undefined") {
    //     // comment by alex: caused conflict with the deliver address
    //     //this.address = this.responseObjectMeta[0]?.deliver_to 
    //     //alert(this.address)
    //     this.searchPlace = this.address
    //   }
    // },
    driverstatus(v) {
      if (v !== "Pending") {
        this.Show_Error_IfPending_Arrived = 0
      }
    },
    isCompleted(v) {
      console.log('v >>>', v)

      /**
      * Commented due to unclarified validation for the arrived leg -Kenny
      */
      // if(v) {
      //   this.addPickup()
      //   this.addDelivery()
      // }
    }
  },
  computed: {
    ...mapGetters({
      getYards: "common/getYards",
    }),
    getTimeAtPickup() {
      if (
        this.arrival_at_pickup_location_date &&
        this.departure_from_pickup_location_date &&
        this.validateDate(this.arrival_at_pickup_location_date) &&
        this.validateDate(this.departure_from_pickup_location_date)
      ) {
        let pickup_arrival_ms = 0;
        let pickup_departure_ms = 0;

        let arrivalConvertTime = moment(
          this.arrival_at_pickup_location_time,
          "hh:mm A"
        ).format("HH:mm");
        let departureConvertTime = moment(
          this.departure_from_pickup_location_time,
          "hh:mm A"
        ).format("HH:mm");

        if (
          arrivalConvertTime &&
          moment(arrivalConvertTime, "HH:mm", true).isValid()
        ) {
          pickup_arrival_ms =
            Number(arrivalConvertTime.split(":")[0]) * 60 * 60 * 1000 +
            Number(arrivalConvertTime.split(":")[1]) * 60 * 1000;
        }
        if (
          departureConvertTime &&
          moment(departureConvertTime, "HH:mm", true).isValid()
        ) {
          pickup_departure_ms =
            Number(departureConvertTime.split(":")[0]) * 60 * 60 * 1000 +
            Number(departureConvertTime.split(":")[1]) * 60 * 1000;
        }
        let date_pickup_arrival = new Date(
          this.arrival_at_pickup_location_date
        );
        date_pickup_arrival.setHours(0, 0, 0);
        let date_pickup_departure = new Date(
          this.departure_from_pickup_location_date
        );
        date_pickup_departure.setHours(0, 0, 0);
        pickup_arrival_ms += date_pickup_arrival.getTime();
        pickup_departure_ms += date_pickup_departure.getTime();
        let diff =
          Math.abs(pickup_arrival_ms - pickup_departure_ms) / (1000 * 60);
        return parseInt(diff / 60) + "hrs " + (diff % 60) + "mins ";
      } else {
        return "--";
      }
    },
    getTimeAtDelivery() {
      if (
        this.arrival_at_delivery_location_date &&
        this.departure_from_delivery_location_date &&
        this.validateDate(this.arrival_at_delivery_location_date) &&
        this.validateDate(this.departure_from_delivery_location_date)
      ) {
        let delivery_arrival_ms = 0;
        let delivery_departure_ms = 0;

        let arrivalConvertTime = moment(
          this.arrival_at_delivery_location_time,
          "hh:mm A"
        ).format("HH:mm");
        let departureConvertTime = moment(
          this.departure_from_delivery_location_time,
          "hh:mm A"
        ).format("HH:mm");

        if (
          arrivalConvertTime &&
          moment(arrivalConvertTime, "HH:mm", true).isValid()
        ) {
          delivery_arrival_ms =
            Number(arrivalConvertTime.split(":")[0]) * 60 * 60 * 1000 +
            Number(arrivalConvertTime.split(":")[1]) * 60 * 1000;
        }
        if (
          departureConvertTime &&
          moment(departureConvertTime, "HH:mm", true).isValid()
        ) {
          delivery_departure_ms =
            Number(departureConvertTime.split(":")[0]) * 60 * 60 * 1000 +
            Number(departureConvertTime.split(":")[1]) * 60 * 1000;
        }
        let date_delivery_arrival = new Date(
          this.arrival_at_delivery_location_date
        );
        date_delivery_arrival.setHours(0, 0, 0);
        let date_delivery_departure = new Date(
          this.departure_from_delivery_location_date
        );
        date_delivery_departure.setHours(0, 0, 0);
        delivery_arrival_ms += date_delivery_arrival.getTime();
        delivery_departure_ms += date_delivery_departure.getTime();
        let diff =
          Math.abs(delivery_arrival_ms - delivery_departure_ms) / (1000 * 60);
        return parseInt(diff / 60) + "hrs " + (diff % 60) + "mins ";
      } else {
        return "--";
      }
    },
    isCompleted() {
      // return this.arrival_at_delivery_location_date ? true : false
      if (this.showArrivalAtDelivery) {
        return true;
      } else {
        return false;
      }
    },
    getSearchedChassis: {
      get() {
        return this.newChassis !== null
          ? this.newChassis.toString()
          : this.newChassis;
      },
      set(input) {
        let temp = [];
        this.chassisList.map((list) => {
          temp.push(list.name);
        });
        if (temp.includes(input)) {
          this.hasNewChassis = 0;
        } else if (this.newChassis === "" || this.newChassis === null) {
          this.hasNewChassis = 0;
        } else {
          this.hasNewChassis = 1;
        }
        this.newChassis = input;
      },
    },
    addDeliveryButtonText() {
      if (this.showArrivalAtDelivery) {
        return "Add Outgate From Delivery";
      } else {
        return "Add Arrival at Delivery (Arrived)";
      }
    },
    addPickupButtonText() {
      if (this.showArrivalAtPickup) return "Add Outgate From Pickup";
      else {
        return "Add Arrival at Pickup";
      }
    },
    pdt: {
      get() {
        if (this.newdatetimepickup !== "" && this.newdatetimepickup !== null && this.newdatetimepickup !== "Invalid date") {
          let time = moment(moment().format('YYYY-MM-DD') + " " + this.newdatetimepickup).utc(false)

          // return moment(moment().format('YYYY-MM-DD')+" "+this.newdatetimepickup)
          return time._d
        } else {
          // return moment()
          return null
        }

        // if (this.newdatetimepickup !== "" && this.newdatetimepickup !== null && this.newdatetimepickup !== "Invalid date") {
        //   let combineDateAndTime = this.pickup_scheduled_date + " " + this.newdatetimepickup;

        //   // let time = 
        // }
      },
      set(value) {
        if (value) {
          return moment(moment().format('YYYY-MM-DD') + " " + value)
        }
        return null
      }
    },
    apdt: {
      get() {
        if (this.arrival_at_pickup_location_time !== "" && this.arrival_at_pickup_location_time !== null && this.arrival_at_pickup_location_time !== "Invalid date") {
          let time = moment(moment().format('YYYY-MM-DD') + " " + this.arrival_at_pickup_location_time).utc(false)

          return time._d
        } else {
          // return moment()
          return null
        }
      },
      set(value) {
        if (value) {
          return moment(moment().format('YYYY-MM-DD') + " " + value)
        }
        return null
      }
    },
    ofpdt: {
      get() {
        if (this.departure_from_pickup_location_time !== "" && this.departure_from_pickup_location_time !== null && this.departure_from_pickup_location_time !== "Invalid date") {
          let time = moment(moment().format('YYYY-MM-DD') + " " + this.departure_from_pickup_location_time).utc(false)

          return time._d
        } else {
          // return moment()
          return null
        }
      },
      set(value) {
        if (value) {
          return moment(moment().format('YYYY-MM-DD') + " " + value)
        }
        return null
      }
    },
    ddt: {
      get() {
        if (this.delivery_scheduled_time !== "" && this.delivery_scheduled_time !== null && this.delivery_scheduled_time !== "Invalid date") {
          let time = moment(moment().format('YYYY-MM-DD') + " " + this.delivery_scheduled_time).utc(false)

          return time._d
        } else {
          // return moment()
          return null
        }
      },
      set(value) {
        if (value) {
          return moment(moment().format('YYYY-MM-DD') + " " + value)
        }
        return null
      }
    },
    addt: {
      get() {
        if (this.arrival_at_delivery_location_time !== "" && this.arrival_at_delivery_location_time !== null && this.arrival_at_delivery_location_time !== "Invalid date") {
          let time = moment(moment().format('YYYY-MM-DD') + " " + this.arrival_at_delivery_location_time).utc(false)

          return time._d
        } else {
          // return moment()
          return null
        }
      },
      set(value) {
        if (value) {
          return moment(moment().format('YYYY-MM-DD') + " " + value)
        }
        return null
      }
    },
    dddt: {
      get() {
        if (this.departure_from_delivery_location_time !== "" && this.departure_from_delivery_location_time !== null && this.departure_from_delivery_location_time !== "Invalid date") {
          let time = moment(moment().format('YYYY-MM-DD') + " " + this.departure_from_delivery_location_time).utc(false)

          return time._d
        } else {
          // return moment()
          return null
        }
      },
      set(value) {
        if (value) {
          return moment(moment().format('YYYY-MM-DD') + " " + value)
        }
        return null
      }
    },
  },
  mounted() {

    if (this.getYards.length == 0) {
      this.fetchYards();
    }
  },
  updated() {
    //this.pickupLocation = this.getpickupLocation;//this.getTerminal()
  },
  methods: {
    ...mapActions({
      fetchYards: "common/fetchYards",
    }),
    ...globalMethods,
    handleConvertDateTimeToLocal(date, time) {
      let dateTogether = "";
      let convertDateTimeToLocal = "";
      let convertedDateAndTime = { newDate: "", newTime: "" }

      if (date !== null && date !== "") {
        let convertDate = moment(date).format("YYYY-MM-DD")

        if (time !== null && time !== "") {
          dateTogether = convertDate + " " + time; // combine date and time
          convertDateTimeToLocal = moment.utc(dateTogether)
          var finalTimeConverted = convertDateTimeToLocal.local().format('YYYY-MM-DD HH:mm')

          let splitDateAndTime = finalTimeConverted.split(" ");
          if (splitDateAndTime.length === 2) {
            convertedDateAndTime.newDate = splitDateAndTime[0];
            convertedDateAndTime.newTime = this.formatTime(splitDateAndTime[1], "12");
          }
        } else {
          convertedDateAndTime.newDate = convertDate;
          convertedDateAndTime.newTime = null;
        }
      }

      return convertedDateAndTime;
    },
    statusValidateaccepted(value) {
      if (value != 'Accepted') {
        if (this.Show_Error_IfPending_Arrived) {
          return 'Should be accepted';
        }
      }
      return true;
    },
    isArrivalAtPickupDisabled() {
      // if (typeof this.fromDispatch !== "undefined") {
      //   if (this.fromDispatch) {
      //     if (this.leg !== null) {
      //       console.log(this.leg);
      //       if (typeof this.leg.is_newly_added_leg !== "undefined" && this.leg.is_newly_added_leg) {
      //         return false
      //       } else {
      //         if (this.showOutgateFromPickup || !this.justAddShowArrivalPickup) {
      //           return true
      //         } else {
      //           return false
      //         }
      //       }
      //     }

      //     return false
      //   }
      // } else {
      //   if (this.showOutgateFromPickup || !this.justAddShowArrivalPickup) {
      //     return true
      //   } else {
      //     return false
      //   }
      // }

      if (this.showOutgateFromPickup) {
        return true
      } else {
        return false
      }
    },
    isOutgateFromPickupDisabled() {
      // if (typeof this.fromDispatch !== "undefined") {
      //   if (this.fromDispatch) {
      //     if (this.leg !== null) {
      //       if (typeof this.leg.is_newly_added_leg !== "undefined" && this.leg.is_newly_added_leg) {
      //         return false
      //       } else {
      //         if (!this.justAddShowOutgatePickup) {
      //           return true
      //         } else {
      //           return false
      //         }
      //       }
      //     }

      //     return false
      //   }
      // } else {
      //   if (!this.justAddShowOutgatePickup) {
      //     return true
      //   } else {
      //     return false
      //   }
      // }

      return false
    },
    isArrivalAtDeliveryDisabled() {
      // if (typeof this.fromDispatch !== "undefined") {
      //   if (this.fromDispatch) {
      //     if (this.leg !== null) {
      //       if (typeof this.leg.is_newly_added_leg !== "undefined" && this.leg.is_newly_added_leg) {
      //         return false
      //       } else {
      //         if (this.showOutgateFromDelivery || !this.justAddShowArrivalAtDelivery) {
      //           return true
      //         } else {
      //           return false
      //         }
      //       }
      //     }

      //     return false
      //   }
      // } else {
      //   if (this.showOutgateFromDelivery || !this.justAddShowArrivalAtDelivery) {
      //     return true
      //   } else {
      //     return false
      //   }
      // }
      if (this.showOutgateFromDelivery) {
        return true
      } else {
        return false
      }
    },
    isOutgateFromDeliveryDisabled() {
      // if (typeof this.fromDispatch !== "undefined") {
      //   if (this.fromDispatch) {
      //     if (this.leg !== null) {
      //       if (typeof this.leg.is_newly_added_leg !== "undefined" && this.leg.is_newly_added_leg) {
      //         return false
      //       } else {
      //         if (!this.justAddShowOutgateDelivery) {
      //           return true
      //         } else {
      //           return false
      //         }
      //       }
      //     }

      //     return false
      //   }
      // } else {
      //   if (!this.justAddShowOutgateDelivery) {
      //     return true
      //   } else {
      //     return false
      //   }
      // }

      return false
    },
    checkKeyUpVal(e, model) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        this.$refs[model].hidePopover(); // close popover on click enter
      }
    },
    checkColorRemoveButton() {
      return 'red-color'

      // if (field === "showArrivalAtPickup") {
      //   if (this.justAddShowArrivalPickup) {
      //     return 'red-color'
      //   } else {
      //     return 'not-red'
      //   }

      // } else if (field === "showOutgateFromPickup") {
      //   if (this.justAddShowOutgatePickup) {
      //     return 'red-color'
      //   } else {
      //     return 'not-red'
      //   }

      // } else if (field === "showArrivalAtDelivery") {
      //   if (this.justAddShowArrivalAtDelivery) {
      //     return 'red-color'
      //   } else {
      //     return 'not-red'
      //   }

      // } else if (field === "showOutgateFromDelivery") {
      //   if (this.justAddShowOutgateDelivery) {
      //     return 'red-color'
      //   } else {
      //     return 'not-red'
      //   }
      // }
    },
    removeField(field) {
      if (field === "showArrivalAtPickup") {
        // if (this.justAddShowArrivalPickup) {
        this[field] = false
        this.arrival_at_pickup_location_date = ""
        this.arrival_at_pickup_location_time = null
        // Added Alex
        // Delete object para mag save gihapon bisag wala ni padayon ug butang ug arrival
        // at pickup location date ug time          
        delete this.dateErrors["arrival_at_pickup_location_date"]
        delete this.dateErrors["arrival_at_pickup_location_time"]
        this.Show_Error_IfPending_Arrived = 0;
        // this.justAddShowArrivalPickup = false - comment this to avoid issue on editing leg (Praisey)
        // }

      } else if (field === "showOutgateFromPickup") {
        // if (this.justAddShowOutgatePickup) {
        this[field] = false
        this.departure_from_pickup_location_date = ""
        this.departure_from_pickup_location_time = null
        delete this.dateErrors.departure_from_pickup_location_date
        delete this.dateErrors.departure_from_pickup_location_time
        // this.justAddShowOutgatePickup = false - comment this to avoid issue on editing leg (Praisey)
        // }

      } else if (field === "showArrivalAtDelivery") {
        // if (this.justAddShowArrivalAtDelivery) {
        this[field] = false
        this.arrival_at_delivery_location_date = ""
        this.arrival_at_delivery_location_time = null
        delete this.dateErrors["arrival_at_delivery_location_date"]
        delete this.dateErrors["arrival_at_delivery_location_time"]
        this.Show_Error_IfPending_Arrived = 0;
        // this.justAddShowArrivalAtDelivery = false - comment this to avoid issue on editing leg (Praisey)
        // }

      } else if (field === "showOutgateFromDelivery") {
        // if (this.justAddShowOutgateDelivery) {
        this[field] = false
        this.departure_from_delivery_location_date = ""
        this.departure_from_delivery_location_time = null
        delete this.dateErrors.departure_from_delivery_location_date
        delete this.dateErrors.departure_from_delivery_location_time
        // this.justAddShowOutgateDelivery = false - comment this to avoid issue on editing leg (Praisey)
        // }
      }
    },
    setInitAddressDropDown(val) {
      if (_.isEmpty(val)) {
        val = this.selectedType;
      }
      if (val == "Empty Return" && this.responseObjectMeta !== undefined) {
        let terminal = this.responseObjectMeta[0]?.terminal;
        if (terminal && terminal.latitude && terminal.longitude) {
          let address =
            (terminal.nickname ? terminal.nickname + " - " : "") +
            terminal.name +
            ` [${terminal.firms_code}], ` +
            terminal.address;
          this.placesEditLeg = [
            {
              terminal: terminal,
              place_name: address,
              geometry: {
                coordinates: [terminal.longitude, terminal.latitude],
              },
            },
          ];
        }
      } else if (val == "Yard") {
        this.placesEditLeg = this.getYards.map((yard) => {
          return {
            yard: yard,
            place_name: yard.name + " - " + yard.address,
            geometry: {
              coordinates: [yard.longitude, yard.latitude],
            },
          };
        });
      } else if (val == "Customer") {
        let shipment = this.responseObjectMeta[0];
        // console.log(shipment)
        if (
          shipment &&
          shipment.deliver_to &&
          shipment.delivery_latitude &&
          shipment.delivery_longitude
        ) {
          this.placesEditLeg = [
            {
              place_name: shipment.deliver_to,
              geometry: {
                coordinates: [
                  shipment.delivery_longitude,
                  shipment.delivery_latitude,
                ],
              },
            },
          ];
        }
      }
    },
    handleDateChange(value, key) {
      if (value) {
        let momentOb = moment(value);
        if (momentOb.isValid()) {
          if (momentOb.year() == "2001") momentOb.set("year", moment().year());
          this[key] = momentOb.format("Y-MM-DD");
        }
      }
    },
    handleTimeChange(value, key) {
      if (value) {
        // let momentOb = moment(value, "HH:mm");
        // if(momentOb.isValid()) {
        // 	this[key] = momentOb.format('HH:mm');
        // }

        let momentOb = moment(value, "hh:mm A");
        if (momentOb.isValid()) {
          this[key] = momentOb.format("hh:mm A");
        } else if (value[0] && value[0] > 2) {
          value = "0" + value;
          momentOb = moment(value, "hh:mm A");
          if (momentOb.isValid()) {
            this[key] = momentOb.format("hh:mm A");
          }
        }
      }
    },
    detectchange() {
      this.driverstatus = "Pending";
    },
    validateDaterules(v, c, isTime = false) {
      if (isTime) {
        v = moment(v, "hh:mm A").format("HH:mm");
        if (!_.isEmpty(v) && !moment(v, "HH:mm", true).isValid())
          return "Invalid Time ex. HH:mm";
        return true;
      }
      if (v === "") return "This field is required";
      if (!this.validateDate(v)) {
        return "Invalid Date ex: YYYY-mm-dd";
      } else if (moment(this.eta).isAfter(v)) {
        return "The " + c + " should be later than ETA";
      } else {
        return true;
      }
    },
    validateDate(dateStr) {
      console.log(dateStr)
      // if(typeof dateStr.split() === 'function') {
      //   if (typeof dateStr == "undefined" || dateStr == null) return false;
      //   let str = dateStr.split("T");
      //   dateStr = str[0];
      //   const regex = /^\d{4}-\d{2}-\d{2}$/;
      //   if (dateStr.match(regex) === null) {
      //     return false;
      //   }
      //   const date = new Date(dateStr);
      //   const timestamp = date.getTime();
      //   if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
      //     return false;
      //   }
      //   return date.toISOString().startsWith(dateStr);
      // }
    },
    getdata(item) {
      if (this.currentstatepicker == "pickup_scheduled_date") {
        this.pickup_scheduled_date = item;
      }
      if (this.currentstatepicker == "arrival_at_pickup_location_date") {
        this.arrival_at_pickup_location_date = item;
      }
      if (this.currentstatepicker == "arrival_at_delivery_location_date") {
        this.arrival_at_delivery_location_date = item;
      }
      if (this.currentstatepicker == "departure_from_delivery_location_date") {
        this.departure_from_delivery_location_date = item;
      }
      if (this.currentstatepicker == "departure_from_pickup_location_date") {
        this.departure_from_pickup_location_date = item;
      }
    },
    closeDialogpicker() {
      this.dialogDatepicker = false;
    },
    toMonthName(monthNumber) {
      const date = new Date();
      date.setMonth(monthNumber - 1);
      return date.toLocaleString("en-US", {
        month: "long",
      });
    },
    confirm() {
      this.dialogDatepicker = false;
    },
    showcustom(state, m) {
      this.pickerDefaultdata = state;
      this.currentstatepicker = m;
      this.dialogDatepicker = true;
    },
    generalRules(value) {
      return {
        typeRule: () => {
          if (
            ["Empty Return", "Yard"].includes(value) &&
            this.selectedMode == 2
          )
            return (
              this.typeDropdown.find((e) => e.value == value).label +
              " is not allowed when mode is Live"
            );
          return true;
        },
      };
    },
    dateRules(value, time = "", isTimeField = false) {
      let datecompare = value;
      time = time !== "" ? moment(time, "hh:mm A").format("HH:mm") : ""; // format to 24 hr
      value = _.isEmpty(value) ? value : time ? value + "T" + time : value;
      return {
        pickupScheduled: () => {
          let msg = "";
          if (!_.isEmpty(time)) {
            time = moment(time, "HH:mm", true);
            if (time.isValid()) {
              //
            } else {
              msg = this.errors["pickup_scheduled"] = "Invalid Time ex. HH:mm";
              return msg;
            }
          }
          if (_.isEmpty(value)) {
            msg = "This field is required";
            this.errors["pickup_scheduled"] = msg;
          } else if (
            _.isEmpty(time) &&
            _.isEmpty(this.delivery_scheduled_time)
          ) {
            this.errors["pickup_scheduled"] =
              "At least one scheduled time is required";
            if (isTimeField) return "err";
            else return true;
          } else if (moment(this.etaifEmpty).isAfter(datecompare)) {
            msg = "Pickup date should be later than ETA";
            this.errors["pickup_scheduled"] = msg;
          } else {
            // purpose to detect if month is less than the current
            if (!this.validateDate(value)) {
              msg = "Invalid Date ex: YYYY-mm-dd";
              this.errors["pickup_scheduled"] = msg;
              return false;
            } else {
              this.errors["pickup_scheduled"] = "";
              return true;
            }
          }
          if (isTimeField) {
            return true;
          }
          return msg;
        },
        deliveryScheduled: () => {
          let msg = "";
          let pickupDateTime = null;
          if (_.isEmpty(time) && value && !value.includes("T")) {
            value += "T23:59";
          }
          if (this.pickup_scheduled_date) {
            pickupDateTime = this.pickup_scheduled_date;
            if (this.pickup_scheduled_time) {
              pickupDateTime +=
                "T" +
                moment(this.pickup_scheduled_time, "hh:mm A").format("HH:mm");
            }
          }
          if (!_.isEmpty(time)) {
            time = moment(time, "HH:mm", true);
            if (time.isValid()) {
              //
            } else {
              msg = this.errors["delivery_scheduled"] =
                "Invalid Time ex. HH:mm";
              return msg;
            }
          }
          if (_.isEmpty(value)) {
            msg = "This field is required";
            this.errors["delivery_scheduled"] = msg;
          } else if (!this.validateDate(value)) {
            msg = "Invalid Date ex: YYYY-mm-dd";
            this.errors["delivery_scheduled"] = msg;
          } else if (
            !_.isEmpty(pickupDateTime) &&
            !moment.utc(value).isAfter(moment.utc(pickupDateTime))
          ) {
            msg = "The Delivery cannot be before the Pickup";
            this.errors["delivery_scheduled"] = msg;
          } else if (_.isEmpty(time) && _.isEmpty(this.pickup_scheduled_time)) {
            this.errors["delivery_scheduled"] =
              "At least one scheduled time is required";
            if (isTimeField) return "err";
            else return true;
          } else if (moment(this.etaifEmpty).isAfter(datecompare)) {
            msg = "The Delivery should be later than ETA";
            this.errors["delivery_scheduled"] = msg;
          } else {
            this.errors["delivery_scheduled"] = "";
            return true;
          }
          if (isTimeField) {
            return true;
          }
          return msg;
        },
        pickupOutgate: () => {
          let msg = "";
          if (_.isEmpty(time) && value && !value.includes("T")) {
            value += "T23:59";
          }
          let pickupArrival = "";
          if (this.arrival_at_pickup_location_date) {
            pickupArrival = this.arrival_at_pickup_location_date;
            if (this.arrival_at_pickup_location_time) {
              pickupArrival +=
                "T" +
                moment(this.arrival_at_pickup_location_time, "hh:mm A").format(
                  "HH:mm"
                );
            }
          }
          if (!_.isEmpty(time)) {
            time = moment(time, "HH:mm", true);
            if (time.isValid()) {
              //
            } else {
              msg = this.errors["pickup_outgate"] = "Invalid Time ex. HH:mm";
              return msg;
            }
          }
          if (_.isEmpty(value)) {
            msg = "This field is required";
            this.errors["pickup_outgate"] = msg;
          } else if (_.isEmpty(pickupArrival)) {
            this.errors["pickup_outgate"] = "";
            return true;
          } else if (!this.validateDate(value)) {
            msg = "Invalid Date ex: YYYY-mm-dd";
            this.errors["pickup_outgate"] = msg;
          } else if (moment.utc(value).isBefore(moment.utc(pickupArrival))) {
            msg =
              "The Outgate from Pickup can't be earlier than the Arrival at Pickup";
            this.errors["pickup_outgate"] = msg;
          } else if (moment(this.etaifEmpty).isAfter(datecompare)) {
            msg = "The Pickup Outgate should be later than ETA";
            this.errors["pickup_outgate"] = msg;
          } else {
            this.errors["pickup_outgate"] = "";
            return true;
          }
          if (isTimeField) {
            return true;
          }
          return msg;
        },
        deliveryOutgate: () => {
          let msg = "";
          if (_.isEmpty(time) && value && !value.includes("T")) {
            value += "T23:59";
          }
          let deliveryArrival = "";
          if (this.arrival_at_delivery_location_date) {
            deliveryArrival = this.arrival_at_delivery_location_date;
            if (this.arrival_at_delivery_location_time) {
              deliveryArrival +=
                "T" +
                moment(
                  this.arrival_at_delivery_location_time,
                  "hh:mm A"
                ).format("HH:mm");
            }
          }
          if (!_.isEmpty(time)) {
            time = moment(time, "HH:mm", true);
            if (time.isValid()) {
              //
            } else {
              msg = this.errors["delivery_outgate"] = "Invalid Time ex. HH:mm";
              return msg;
            }
          }
          if (_.isEmpty(value)) {
            msg = "This field is required";
            this.errors["delivery_outgate"] = msg;
          } else if (_.isEmpty(deliveryArrival)) {
            this.errors["delivery_outgate"] = "";
            return true;
          } else if (!this.validateDate(value)) {
            msg = "Invalid Date ex: YYYY-mm-dd";
            this.errors["delivery_outgate"] = msg;
          } else if (moment.utc(value).isBefore(moment.utc(deliveryArrival))) {
            msg =
              "The Outgate from Delivery can't be earlier than the Arrival at Delivery";
            this.errors["delivery_outgate"] = msg;
          } else if (moment(this.etaifEmpty).isAfter(datecompare)) {
            msg = "The Outgate from Delivery should be later than ETA";
            this.errors["delivery_outgate"] = msg;
          } else {
            this.errors["delivery_outgate"] = "";
            return true;
          }
          if (isTimeField) {
            return true;
          }
          return msg;
        },
        arrivalDelivery: () => {
          let msg = "";
          let pickupArrival = "";
          let pickupOutgate = "";
          if (this.arrival_at_pickup_location_date) {
            pickupArrival = this.arrival_at_pickup_location_date;
            if (this.arrival_at_pickup_location_time) {
              pickupArrival +=
                "T" +
                moment(this.arrival_at_pickup_location_time, "hh:mm A").format(
                  "HH:mm"
                );
            }
          }
          if (this.departure_from_pickup_location_date) {
            pickupOutgate = this.departure_from_pickup_location_date;
            if (this.departure_from_pickup_location_time) {
              pickupOutgate +=
                "T" +
                moment(
                  this.departure_from_pickup_location_time,
                  "hh:mm A"
                ).format("HH:mm");
            }
          }
          if (!_.isEmpty(time)) {
            time = moment(time, "HH:mm", true);
            if (time.isValid()) {
              //
            } else {
              msg = this.errors["delivery_arrival"] = "Invalid Time ex. HH:mm";
              return msg;
            }
          }
          if (_.isEmpty(value)) {
            msg = "This field is required";
            this.errors["delivery_arrival"] = msg;
          } else if (_.isEmpty(pickupOutgate) && _.isEmpty(pickupArrival)) {
            this.errors["delivery_arrival"] = "";
            return true;
          } else if (!this.validateDate(value)) {
            msg = "Invalid Date ex: YYYY-mm-dd";
            this.errors["delivery_arrival"] = msg;
          } else if (
            !_.isEmpty(pickupOutgate) &&
            (moment.utc(pickupOutgate).isAfter(moment.utc(value)) ||
              moment.utc(pickupOutgate).isSame(moment.utc(value)))
          ) {
            msg =
              "The Arrival at Delivery should be later than the Outgate from Pickup";
            this.errors["delivery_arrival"] = msg;
          } else if (
            !_.isEmpty(pickupArrival) &&
            (moment.utc(pickupArrival).isAfter(moment.utc(value)) ||
              moment.utc(pickupArrival).isSame(moment.utc(value)))
          ) {
            msg =
              "The Arrival at Delivery should be later than the Arrival at Pickup";
            this.errors["delivery_arrival"] = msg;
          } else if (moment(this.etaifEmpty).isAfter(datecompare)) {
            msg = "The Arrival at Delivery should be later than ETA";
            this.errors["delivery_arrival"] = msg;
          } else {
            this.errors["delivery_arrival"] = "";
            return true;
          }
          if (isTimeField) {
            return true;
          }
          return msg;
        },
        arrivalPickup: () => {
          let msg = "";
          if (!_.isEmpty(time)) {
            time = moment(time, "HH:mm", true);
            if (time.isValid()) {
              //
            } else {
              msg = this.errors["pickup_arrival"] = "Invalid Time ex. HH:mm";
              return msg;
            }
          }
          if (_.isEmpty(value)) {
            msg = "This field is required";
            this.errors["pickup_arrival"] = msg;
          } else if (!this.validateDate(value)) {
            msg = "Invalid Date ex: YYYY-mm-dd";
            this.errors["pickup_arrival"] = msg;
          } else if (moment(this.etaifEmpty).isAfter(datecompare)) {
            msg = "The Arrival at Pickup should be later than ETA";
            this.errors["pickup_arrival"] = msg;
          } else {
            this.errors["pickup_arrival"] = "";
            return true;
          }
          if (isTimeField) {
            return true;
          }
          return msg;
        },
      };
    },
    close() {
      this.$emit("close");
      this.legCopyData = null;

      if (typeof this.fromDispatch === "undefined") {
        this.justAddShowArrivalPickup = false
        this.justAddShowOutgatePickup = false
        this.justAddShowArrivalAtDelivery = false
        this.justAddShowOutgateDelivery = false
      }
    },
    edit() {
      let hasErrors = Object.keys(this.dateErrors).length

      if (this.showArrivalAtDelivery && this.driverstatus !== "Accepted") {
        // if( (this.arrival_at_pickup_location_date != null && this.arrival_at_pickup_location_date != '') || (this.arrival_at_delivery_location_date != null && this.arrival_at_delivery_location_date != '')){
        //   if( this.driverstatus != 'Accepted' ){
        // show the error in driver status
        this.Show_Error_IfPending_Arrived = 1;
        // }
      } else {
        this.Show_Error_IfPending_Arrived = 0;
      }

      if (hasErrors > 0) {
        this.submitting = Math.random()
      }

      // convert to utc before sending to payload
      let pickupDate = this.handleDateTimeFormatToUTC(this.pickup_scheduled_date, this.pickup_scheduled_time);
      let deliveryDate = this.handleDateTimeFormatToUTC(this.delivery_scheduled_date, this.delivery_scheduled_time);
      let arrivalPickupDate = this.handleDateTimeFormatToUTC(this.arrival_at_pickup_location_date, this.arrival_at_pickup_location_time);
      let departurePickupDate = this.handleDateTimeFormatToUTC(this.departure_from_pickup_location_date, this.departure_from_pickup_location_time);
      let arrivalDeliveryDate = this.handleDateTimeFormatToUTC(this.arrival_at_delivery_location_date, this.arrival_at_delivery_location_time);
      let departureDeliveryDate = this.handleDateTimeFormatToUTC(this.departure_from_delivery_location_date, this.departure_from_delivery_location_time);

      let ob = {
        id: this.leg.id,
        legNum: this.currentEditingLegNum,
        selectedDriver: this.selectedDriver,
        driverCanSeeDeliveryOrder: this.leg.driverCanSeeDeliveryOrder,
        driver_instructions: this.leg.driver_instructions,
        pickup_scheduled_date: pickupDate.newDate,
        pickup_scheduled_time: pickupDate.newTime,

        delivery_scheduled_date: deliveryDate.newDate,
        delivery_scheduled_time: deliveryDate.newTime,
        arrival_at_pickup_location_date: arrivalPickupDate.newDate,
        arrival_at_pickup_location_time: arrivalPickupDate.newTime,
        departure_from_pickup_location_date: departurePickupDate.newDate,
        departure_from_pickup_location_time: departurePickupDate.newTime,
        arrival_at_delivery_location_date: arrivalDeliveryDate.newDate,
        arrival_at_delivery_location_time: arrivalDeliveryDate.newTime,
        departure_from_delivery_location_date: departureDeliveryDate.newDate,
        departure_from_delivery_location_time: departureDeliveryDate.newTime,

        schedule_assigned_date_time:
          this.leg.schedule_assigned_date_time == null &&
            this.selectedDriver > 0
            ? new Date().toJSON()
            : this.leg.schedule_assigned_date_time,

        schedule_accepted_date_time:
          this.leg.schedule_accepted_date_time == null &&
            this.driverstatus == "Accepted"
            ? new Date().toJSON()
            : null,
        schedule_completed_date_time: this.leg.schedule_completed_date_time,
        address: this.address,
        selectedMode: this.selectedMode,
        selectedType: this.selectedType,
        chassis: this.selectedChassis,
        driverstatus: this.driverstatus
      };

      // let ob = {
      //   id: this.leg.id,
      //   legNum: this.currentEditingLegNum,
      //   selectedDriver: this.selectedDriver,
      //   driverCanSeeDeliveryOrder: this.leg.driverCanSeeDeliveryOrder,
      //   driver_instructions: this.leg.driver_instructions,
      //   pickup_scheduled_date: this.pickup_scheduled_date,
      //   // pickup_scheduled_time: this.pickup_scheduled_time,
      //   pickup_scheduled_time: this.formatTime(
      //     this.pickup_scheduled_time,
      //     "24"
      //   ),
      //   delivery_scheduled_date: this.delivery_scheduled_date,
      //   // delivery_scheduled_time: this.delivery_scheduled_time,
      //   delivery_scheduled_time: this.formatTime(
      //     this.delivery_scheduled_time,
      //     "24"
      //   ),
      //   arrival_at_pickup_location_date: this.arrival_at_pickup_location_date,
      //   // arrival_at_pickup_location_time: this.arrival_at_pickup_location_time,
      //   arrival_at_pickup_location_time: this.formatTime(
      //     this.arrival_at_pickup_location_time,
      //     "24"
      //   ),
      //   departure_from_pickup_location_date:
      //     this.departure_from_pickup_location_date,
      //   // departure_from_pickup_location_time: this.departure_from_pickup_location_time,
      //   departure_from_pickup_location_time: this.formatTime(
      //     this.departure_from_pickup_location_time,
      //     "24"
      //   ),
      //   arrival_at_delivery_location_date:
      //     this.arrival_at_delivery_location_date,
      //   // arrival_at_delivery_location_time: this.arrival_at_delivery_location_time,
      //   arrival_at_delivery_location_time: this.formatTime(
      //     this.arrival_at_delivery_location_time,
      //     "24"
      //   ),
      //   departure_from_delivery_location_date:
      //     this.departure_from_delivery_location_date,
      //   // departure_from_delivery_location_time: this.departure_from_delivery_location_time,
      //   departure_from_delivery_location_time: this.formatTime(
      //     this.departure_from_delivery_location_time,
      //     "24"
      //   ),
      //   schedule_assigned_date_time:
      //     this.leg.schedule_assigned_date_time == null &&
      //     this.selectedDriver > 0
      //       ? new Date().toJSON()
      //       : this.leg.schedule_assigned_date_time,

      //   schedule_accepted_date_time:
      //     this.leg.schedule_accepted_date_time == null &&
      //     this.driverstatus == "Accepted"
      //       ? new Date().toJSON()
      //       : null,
      //   schedule_completed_date_time: this.leg.schedule_completed_date_time,
      //   address: this.address,
      //   selectedMode: this.selectedMode,
      //   selectedType: this.selectedType,
      //   chassis: this.selectedChassis,
      //   driverstatus: this.driverstatus,
      // };
      const selectedPlace = this.placesEditLeg.find(
        (element) => element.place_name === this.address
      );

      console.log('errors >>>', this.dateErrors)

      if (this.$refs.form.validate() && hasErrors === 0 &&
        this.Show_Error_IfPending_Arrived === 0) {
        if (this.oldAddress != this.address) {
          ob["addressLongitude"] = typeof selectedPlace !== "undefined"
            ? selectedPlace.geometry.coordinates[0]
            : null;
          ob["addressLatitude"] = typeof selectedPlace !== "undefined"
            ? selectedPlace.geometry.coordinates[1]
            : null;



          if (selectedPlace) {
            let address_model_type = null,
              address_model_id = null;
            if (
              selectedPlace.yard !== undefined &&
              selectedPlace.yard &&
              selectedPlace.yard.id
            ) {
              address_model_type = "App\\Yard";
              address_model_id = selectedPlace.yard.id;
            } else if (
              selectedPlace.terminal !== undefined &&
              selectedPlace.terminal &&
              selectedPlace.terminal.id
            ) {
              address_model_type = "App\\Terminal";
              address_model_id = selectedPlace.terminal.id;
            }

            ob["address_model_type"] = address_model_type;
            ob["address_model_id"] = address_model_id;
          }
        } else {
          ob["addressLongitude"] = this.addressLongitude;
          ob["addressLatitude"] = this.addressLatitude;


          ob["address_model_type"] = this.address_model_type;
          ob["address_model_id"] = this.address_model_id;
        }

     

        this.$emit("saveEditedLeg", ob);
        this.$emit("update:dialogData", ob);
        this.legCopyData = null;

        if (typeof this.editIndex == "undefined") {
          this.$refs.form.reset();
        }

        this.custom_error_show = false;

        if (typeof this.shouldClearJustAddedFields !== "undefined" && this.shouldClearJustAddedFields) {
          this.justAddShowArrivalPickup = false
          this.justAddShowOutgatePickup = false
          this.justAddShowArrivalAtDelivery = false
          this.justAddShowOutgateDelivery = false
        }
      } else {
        this.custom_error_show = true;
      }
    },
    addPickup() {
      console.log('pickup');
      if (this.showArrivalAtPickup) {
        if (moment(this.arrival_at_pickup_location_date).isValid()) {
          this.departure_from_pickup_location_date = this.arrival_at_pickup_location_date;
        }
        this.showOutgateFromPickup = true;
        this.justAddShowOutgatePickup = true;
        this.departure_from_pickup_location_date = null
      } else {
        if (moment(this.pickup_scheduled_date).isValid()) {
          this.arrival_at_pickup_location_date = this.pickup_scheduled_date;
        }
        this.showArrivalAtPickup = true;
        this.justAddShowArrivalPickup = true;
        this.arrival_at_pickup_location_date = null
      }
    },
    addDelivery() {
      console.log('delivery');
      if (this.showArrivalAtDelivery) {
        if (this.arrival_at_delivery_location_date && moment(this.arrival_at_delivery_location_date).isValid()) {
          this.departure_from_delivery_location_date = this.arrival_at_delivery_location_date;
        }
        this.showOutgateFromDelivery = true;
        this.justAddShowOutgateDelivery = true;
        this.departure_from_delivery_location_date = null
      } else {
        if (moment(this.delivery_scheduled_date).isValid()) {
          this.arrival_at_delivery_location_date = this.delivery_scheduled_date;
        }
        this.showArrivalAtDelivery = true;
        this.justAddShowArrivalAtDelivery = true;
        this.arrival_at_delivery_location_date = null
      }
    },
    handleNewChassis() {
      let data = {
        chassis_number: this.newChassis,
      };
      if (this.newChassis === null || this.newChassis === "") {
        this.dispatchNotificationError("New Chassis should not be blank/null");
      } else {
        this.$emit("saveNewChassis", data);
      }
    },
    getETA() {
      if (this.responseObjectMeta !== undefined) {
        let temp = this.responseObjectMeta[0]?.eta;
        if (temp !== null && temp !== "") {
          // return moment(temp).utc(true).format("YYYY-MM-DD");
          return moment(temp).utc(true).format("MM/DD/YY");
        } else {
          return "--";
        }
      }
    },
    getLFD() {
      if (this.responseObjectMeta !== undefined) {
        let temp = this.responseObjectMeta[0]?.last_free_date;
        if (temp !== null && temp !== "") {
          // return moment(temp).utc(true).format("YYYY-MM-DD");
          return moment(temp).utc(true).format("MM/DD/YY");
        } else {
          return "--";
        }
      }
    },
    getPDD() {
      if (this.responseObjectMeta !== undefined) {
        let temp = this.responseObjectMeta[0]?.per_diem_date;
        if (temp !== null && temp !== "") {
          // return moment(temp).utc(true).format("YYYY-MM-DD");
          return moment(temp).utc(true).format("MM/DD/YY");
        } else {
          return "--";
        }
      }
    },
    getCustomer() {
      if (this.responseObjectMeta !== undefined) {
        let temp = this.responseObjectMeta[0]?.customer;
        if (temp !== null && temp !== "") {
          return temp?.company_name;
        } else {
          return "--";
        }
      }
    },
    getTerminal() {
      if (this.responseObjectMeta !== undefined) {
        let temp = this.responseObjectMeta[0]?.terminal;
        if (temp !== null && temp !== "") {
          return `${temp?.firms_code} | ${temp?.name} | ${temp?.map_location}`
        } else {
          return "--";
        }
      }
    },
    getDeliveryTo() {
      if (this.responseObjectMeta !== undefined) {
        let temp = this.responseObjectMeta[0]?.deliver_to;
        if (temp !== null && temp !== "") {
          return temp;
        } else {
          return "--";
        }
      }
    },

    getContainerNo() {
      if (this.responseObjectMeta !== undefined) {
        let cont = this.responseObjectMeta[0]?.trucker_container;
        let isCSNull = cont === null || cont === "";
        return `${isCSNull ? "--" : cont}`;
      }
    },

    getNotes() {
      if (this.responseObjectMeta !== undefined) {
        let notes = this.responseObjectMeta[0]?.custom_notes;
        let isNNull = notes === null;
        return `${isNNull ? "--" : notes}`;
      }
    },
    async getDeliveryLocation(val) {
      if (val && val.length >= 3) {
        this.loadingLocationPlaces = true;
        // await fetch(
        //   `${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/` +
        //     val +
        //     `.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}&country=US`
        // )

        // cancel previous fetch requests if there are
        if (controller) {
          controller.abort()
        }
        controller = new AbortController()
        signal = controller.signal

        let url = `${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/` + val + `.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}&country=US`
        await fetch(url, {
          method: 'get',
          signal: signal
        })
          .then((response) => response.json())
          .then(async (json) => {
            this.loadingLocationPlaces = false;
            this.placesEditLeg = [...json.features];
            // this.places = [...json.features, ...customInput];
            if (this.selectedType == "Empty Return") {
              await axios
                .get(poBaseUrl + "/terminals/search?search_text=" + val)
                .then((res) => res.data)
                .then((terminals) => {
                  terminals = terminals.filter(
                    (f) => f.latitude && f.longitude
                  );
                  terminals = terminals.map((terminal) => {
                    return {
                      terminal: terminal,
                      place_name:
                        (terminal.nickname ? terminal.nickname + " - " : "") +
                        terminal.name +
                        ` [${terminal.firms_code}], ` +
                        terminal.address,
                      geometry: {
                        coordinates: [terminal.longitude, terminal.latitude],
                      },
                    };
                  });
                  this.placesEditLeg = [...terminals, ...this.placesEditLeg];
                })
                .catch((e) => {
                  this.loadingLocationPlaces = false;
                  console.log(e);
                });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.setInitAddressDropDown(this.selectedType);
      }
    },
    changeDeliveryLocationState() {
      this.callApiForDeliveryLoc = !this.callApiForDeliveryLoc;
    },
    clearSearchPlaces() {
      this.placesEditLeg = [];
      this.setInitAddressDropDown(this.selectedType);
    },
    formatTime(time, to) {
      return time !== ""
        ? to === "12"
          ? moment(time, ["HH:mm"]).format("hh:mm A")
          : moment(time, ["hh:mm A"]).format("HH:mm")
        : "";
    },
    getMBLandCarrier(isFor) {
      console.log(this.responseObjectMeta);
      if (this.responseObjectMeta !== undefined) {
        let mbl = this.responseObjectMeta[0]?.mbl_num;
        let carrier = this.responseObjectMeta[0]?.carrier;
        let isCSNull = mbl === null || mbl === "";
        let isCNull = carrier === null || carrier === "";

        if (isFor === 'mbl') {
          return isCSNull ? "--" : mbl
        } else {
          return isCNull ? "--" : carrier?.name
        }
        // return `${isCSNull ? "--" : mbl}, ${isCNull ? "--" : carrier?.name}`;
      }
    },

    getContSizeandWeight(isFor) {
      if (this.responseObjectMeta !== undefined) {
        let cont_size = this.responseObjectMeta[0]?.container_size;
        let cont_weight = this.responseObjectMeta[0]?.container_weight;
        let isCSNull = cont_size === null || cont_size === "";
        let isCWNull = cont_weight === null || cont_weight === "";

        if (isFor === 'size') {
          return isCSNull ? "--" : cont_size?.name
        } else {
          return isCWNull ? "--" : cont_weight
        }
        // return `${isCSNull ? "--" : cont_size?.name}, ${
        //   isCWNull ? "--" : cont_weight
        // }`;
      }
    },
    getStatus(driver) {
      /**
      * Get Driver's assigned schedules
      */
      if (typeof driver.dispatch_legs !== "undefined" || driver.dispatch_legs !== null) {
        let dispatchLegs = driver.dispatch_legs

        /**
        * Loop thru the Dispatches and get the related Shipments
        * Get the Legs and check if the assigned Driver is the same as the Looped Driver
        * Check the dates if it overlapped the current selected Pickup and Delivery Date & Time
        */

        let overlaps = []
        if (typeof dispatchLegs !== 'undefined') {
          dispatchLegs.forEach((dispatch) => {
            let shipment = dispatch.shipment

            let ds = []
            if (typeof shipment.dispatch_schedule !== "undefined") {
              ds = JSON.parse(shipment.dispatch_schedule)
            }

            ds.legs.map((leg) => {
              /**
                * current pickup and delivery is in the middle of (Pickup DateTime & Delivery DateTime) 
                * (Pickup DateTime & Delivery DateTime) is in between of select Pickup and Delivery DateTime of the current edited leg 
                * Pickup DateTime is before the current selected pickup but Delivery DateTime is After selected pickup
                * current selected pickup is before Pickup DateTime but selected delivery is After Delivery DateTime
                */
              // if(!leg.completed && leg.driver === driver.id) {
              if (!leg.completed) {
                let isNull = (this.pickup_scheduled_time === "" && this.pickup_scheduled_time === null && this.pickup_scheduled_time === "Invalid date")
                let disNull = (this.delivery_scheduled_time === "" && this.delivery_scheduled_time === null && this.delivery_scheduled_time === "Invalid date")
                let pickupDate = moment(this.pickup_scheduled_date)
                let deliveryDate = this.delivery_scheduled_date !== "" ? moment(this.delivery_scheduled_date) : ""

                let currentPickupDateTime = isNull
                  ? moment(pickupDate.format('YYYY-MM-DD') + " 00:00:00")
                  : moment(pickupDate.format('YYYY-MM-DD') + " " + this.pickup_scheduled_time.substr(0, 5))
                let currentDeliveryDateTime = deliveryDate !== ""
                  ? disNull
                    ? moment(deliveryDate.format('YYYY-MM-DD') + " 12:00:00")
                    : moment(deliveryDate.format('YYYY-MM-DD') + " " + this.delivery_scheduled_time.substr(0, 5))
                  : ""

                let pickup_date = (leg.from_date !== "" && leg.from_date !== "Invalid date")
                  ? moment(leg.from_date.substr(0, 10)) : null
                let pickup_time = (leg.to_time !== "" && leg.to_time !== "Invalid date")
                  ? leg.to_time : "12:00"

                let delivery_date = (leg.date !== "" && leg.date !== "Invalid date")
                  ? moment(leg.date.substr(0, 10)) : null
                let delivery_time = (leg.time !== "" && leg.time !== "Invalid date")
                  ? leg.time : "12:00"

                let pickup_datetime = pickup_date !== null
                  ? pickup_time !== null
                    ? moment(pickup_date.format('YYYY-MM-DD') + " " + pickup_time + ":00")
                    : moment(pickup_date)
                  : ""
                let delivery_datetime = delivery_date !== null
                  ? delivery_time !== null
                    ? moment(delivery_date.format('YYYY-MM-DD') + " " + delivery_time + ":00")
                    : moment(delivery_date)
                  : ""

                console.log("")
                console.log('current PD >>>', currentPickupDateTime.format('YYYY-MM-DD HH:mm'))
                console.log('current DD >>>', currentDeliveryDateTime.format('YYYY-MM-DD HH:mm'))
                console.log('PD >>>', pickup_datetime.format('YYYY-MM-DD HH:mm'))
                console.log('DD >>>', delivery_datetime.format('YYYY-MM-DD HH:mm'))

                if (
                  (pickup_datetime.isBetween(currentPickupDateTime, currentDeliveryDateTime)) ||
                  (currentPickupDateTime.isBetween(pickup_datetime, delivery_datetime)) ||
                  (delivery_datetime.isBetween(pickup_datetime, delivery_datetime)) ||
                  (currentPickupDateTime.format('YYYY-MM-DD HH:mm') === pickup_datetime.format('YYYY-MM-DD HH:mm'))
                ) {
                  overlaps.push(shipment)
                }
              }
            })
          })

          if (overlaps.length > 0) {
            return "Busy";
          }
        }
      }
    },
    getRef(driver) {
      /**
      * Get Driver's assigned schedules
      */
      if (typeof driver.dispatch_legs !== "undefined" || driver.dispatch_legs !== null) {
        let dispatchLegs = driver.dispatch_legs

        /**
        * Loop thru the Dispatches and get the related Shipments
        * Get the Legs and check if the assigned Driver is the same as the Looped Driver
        * Check the dates if it overlapped the current selected Pickup and Delivery Date & Time
        */

        let overlaps = []
        if (typeof dispatchLegs !== 'undefined') {
          dispatchLegs.forEach((dispatch) => {
            let shipment = dispatch.shipment

            let ds = []
            if (typeof shipment.dispatch_schedule !== "undefined") {
              ds = JSON.parse(shipment.dispatch_schedule)
            }

            ds.legs.map((leg) => {
              /**
                * current pickup and delivery is in the middle of (Pickup DateTime & Delivery DateTime) 
                * (Pickup DateTime & Delivery DateTime) is in between of select Pickup and Delivery DateTime of the current edited leg 
                * Pickup DateTime is before the current selected pickup but Delivery DateTime is After selected pickup
                * current selected pickup is before Pickup DateTime but selected delivery is After Delivery DateTime
                */
              // if(!leg.completed && leg.driver === driver.id) {
              if (!leg.completed) {
                let isNull = (this.pickup_scheduled_time === "" && this.pickup_scheduled_time === null && this.pickup_scheduled_time === "Invalid date")
                let disNull = (this.delivery_scheduled_time === "" && this.delivery_scheduled_time === null && this.delivery_scheduled_time === "Invalid date")
                let pickupDate = moment(this.pickup_scheduled_date)
                let deliveryDate = this.delivery_scheduled_date !== "" ? moment(this.delivery_scheduled_date) : ""

                let currentPickupDateTime = isNull
                  ? moment(pickupDate.format('YYYY-MM-DD') + " 00:00:00")
                  : moment(pickupDate.format('YYYY-MM-DD') + " " + this.pickup_scheduled_time.substr(0, 5))
                let currentDeliveryDateTime = deliveryDate !== ""
                  ? disNull
                    ? moment(deliveryDate.format('YYYY-MM-DD') + " 12:00:00")
                    : moment(deliveryDate.format('YYYY-MM-DD') + " " + this.delivery_scheduled_time.substr(0, 5))
                  : ""

                let pickup_date = (leg.from_date !== "" && leg.from_date !== "Invalid date")
                  ? moment(leg.from_date.substr(0, 10)) : null
                let pickup_time = (leg.to_time !== "" && leg.to_time !== "Invalid date")
                  ? leg.to_time : "12:00"

                let delivery_date = (leg.date !== "" && leg.date !== "Invalid date")
                  ? moment(leg.date.substr(0, 10)) : null
                let delivery_time = (leg.time !== "" && leg.time !== "Invalid date")
                  ? leg.time : "12:00"

                let pickup_datetime = pickup_date !== null
                  ? pickup_time !== null
                    ? moment(pickup_date.format('YYYY-MM-DD') + " " + pickup_time + ":00")
                    : moment(pickup_date)
                  : ""
                let delivery_datetime = delivery_date !== null
                  ? delivery_time !== null
                    ? moment(delivery_date.format('YYYY-MM-DD') + " " + delivery_time + ":00")
                    : moment(delivery_date)
                  : ""

                console.log("")
                console.log('current PD >>>', currentPickupDateTime.format('YYYY-MM-DD HH:mm'))
                console.log('current DD >>>', currentDeliveryDateTime.format('YYYY-MM-DD HH:mm'))
                console.log('PD >>>', pickup_datetime.format('YYYY-MM-DD HH:mm'))
                console.log('DD >>>', delivery_datetime.format('YYYY-MM-DD HH:mm'))

                if (
                  (pickup_datetime.isBetween(currentPickupDateTime, currentDeliveryDateTime)) ||
                  (currentPickupDateTime.isBetween(pickup_datetime, delivery_datetime)) ||
                  (delivery_datetime.isBetween(pickup_datetime, delivery_datetime)) ||
                  (currentPickupDateTime.format('YYYY-MM-DD HH:mm') === pickup_datetime.format('YYYY-MM-DD HH:mm'))
                ) {
                  overlaps.push(shipment)
                }
              }
            })
          })

          if (overlaps.length > 0) {
            return "Ref " + overlaps[overlaps.length - 1].shifl_ref;
          }
        }
      }

    },
    getLinkActive(driver) {
      /**
      * Get Driver's assigned schedules
      */
      if (typeof driver.dispatch_legs !== "undefined" || driver.dispatch_legs !== null) {
        let dispatchLegs = driver.dispatch_legs

        /**
        * Loop thru the Dispatches and get the related Shipments
        * Get the Legs and check if the assigned Driver is the same as the Looped Driver
        * Check the dates if it overlapped the current selected Pickup and Delivery Date & Time
        */

        let overlaps = []
        if (typeof dispatchLegs !== 'undefined') {
          dispatchLegs.forEach((dispatch) => {
            let shipment = dispatch.shipment

            let ds = []
            if (typeof shipment.dispatch_schedule !== "undefined") {
              ds = JSON.parse(shipment.dispatch_schedule)
            }

            ds.legs.map((leg) => {
              /**
                * current pickup and delivery is in the middle of (Pickup DateTime & Delivery DateTime) 
                * (Pickup DateTime & Delivery DateTime) is in between of select Pickup and Delivery DateTime of the current edited leg 
                * Pickup DateTime is before the current selected pickup but Delivery DateTime is After selected pickup
                * current selected pickup is before Pickup DateTime but selected delivery is After Delivery DateTime
                */
              // if(!leg.completed && leg.driver === driver.id) {
              if (!leg.completed) {
                let isNull = (this.pickup_scheduled_time === "" && this.pickup_scheduled_time === null && this.pickup_scheduled_time === "Invalid date")
                let disNull = (this.delivery_scheduled_time === "" && this.delivery_scheduled_time === null && this.delivery_scheduled_time === "Invalid date")
                let pickupDate = moment(this.pickup_scheduled_date)
                let deliveryDate = this.delivery_scheduled_date !== "" ? moment(this.delivery_scheduled_date) : ""

                let currentPickupDateTime = isNull
                  ? moment(pickupDate.format('YYYY-MM-DD') + " 00:00:00")
                  : moment(pickupDate.format('YYYY-MM-DD') + " " + this.pickup_scheduled_time.substr(0, 5))
                let currentDeliveryDateTime = deliveryDate !== ""
                  ? disNull
                    ? moment(deliveryDate.format('YYYY-MM-DD') + " 12:00:00")
                    : moment(deliveryDate.format('YYYY-MM-DD') + " " + this.delivery_scheduled_time.substr(0, 5))
                  : ""

                let pickup_date = (leg.from_date !== "" && leg.from_date !== "Invalid date")
                  ? moment(leg.from_date.substr(0, 10)) : null
                let pickup_time = (leg.to_time !== "" && leg.to_time !== "Invalid date")
                  ? leg.to_time : "12:00"

                let delivery_date = (leg.date !== "" && leg.date !== "Invalid date")
                  ? moment(leg.date.substr(0, 10)) : null
                let delivery_time = (leg.time !== "" && leg.time !== "Invalid date")
                  ? leg.time : "12:00"

                let pickup_datetime = pickup_date !== null
                  ? pickup_time !== null
                    ? moment(pickup_date.format('YYYY-MM-DD') + " " + pickup_time + ":00")
                    : moment(pickup_date)
                  : ""
                let delivery_datetime = delivery_date !== null
                  ? delivery_time !== null
                    ? moment(delivery_date.format('YYYY-MM-DD') + " " + delivery_time + ":00")
                    : moment(delivery_date)
                  : ""

                console.log("")
                console.log('current PD >>>', currentPickupDateTime.format('YYYY-MM-DD HH:mm'))
                console.log('current DD >>>', currentDeliveryDateTime.format('YYYY-MM-DD HH:mm'))
                console.log('PD >>>', pickup_datetime.format('YYYY-MM-DD HH:mm'))
                console.log('DD >>>', delivery_datetime.format('YYYY-MM-DD HH:mm'))

                if (
                  (pickup_datetime.isBetween(currentPickupDateTime, currentDeliveryDateTime)) ||
                  (currentPickupDateTime.isBetween(pickup_datetime, delivery_datetime)) ||
                  (delivery_datetime.isBetween(pickup_datetime, delivery_datetime)) ||
                  (currentPickupDateTime.format('YYYY-MM-DD HH:mm') === pickup_datetime.format('YYYY-MM-DD HH:mm'))
                ) {
                  overlaps.push(shipment)
                }
              }
            })
          })

          // console.log('overlaps >>>', overlaps)
          if (overlaps.length > 0) {
            return 1;
          }
        }
      }
    },
    getShipment(driver) {
      /**
      * Get Driver's assigned schedules
      */
      if (typeof driver.dispatch_legs !== "undefined" || driver.dispatch_legs !== null) {
        let dispatchLegs = driver.dispatch_legs

        /**
        * Loop thru the Dispatches and get the related Shipments
        * Get the Legs and check if the assigned Driver is the same as the Looped Driver
        * Check the dates if it overlapped the current selected Pickup and Delivery Date & Time
        */

        let overlaps = []
        if (typeof dispatchLegs !== 'undefined') {
          dispatchLegs.forEach((dispatch) => {
            let shipment = dispatch.shipment

            let ds = []
            if (typeof shipment.dispatch_schedule !== "undefined") {
              ds = JSON.parse(shipment.dispatch_schedule)
            }

            ds.legs.map((leg) => {
              /**
                * current pickup and delivery is in the middle of (Pickup DateTime & Delivery DateTime) 
                * (Pickup DateTime & Delivery DateTime) is in between of select Pickup and Delivery DateTime of the current edited leg 
                * Pickup DateTime is before the current selected pickup but Delivery DateTime is After selected pickup
                * current selected pickup is before Pickup DateTime but selected delivery is After Delivery DateTime
                */
              // if(!leg.completed && leg.driver === driver.id) {
              if (!leg.completed) {
                let isNull = (this.pickup_scheduled_time === "" && this.pickup_scheduled_time === null && this.pickup_scheduled_time === "Invalid date")
                let disNull = (this.delivery_scheduled_time === "" && this.delivery_scheduled_time === null && this.delivery_scheduled_time === "Invalid date")
                let pickupDate = moment(this.pickup_scheduled_date)
                let deliveryDate = this.delivery_scheduled_date !== "" ? moment(this.delivery_scheduled_date) : ""

                let currentPickupDateTime = isNull
                  ? moment(pickupDate.format('YYYY-MM-DD') + " 00:00:00")
                  : moment(pickupDate.format('YYYY-MM-DD') + " " + this.pickup_scheduled_time.substr(0, 5))
                let currentDeliveryDateTime = deliveryDate !== ""
                  ? disNull
                    ? moment(deliveryDate.format('YYYY-MM-DD') + " 12:00:00")
                    : moment(deliveryDate.format('YYYY-MM-DD') + " " + this.delivery_scheduled_time.substr(0, 5))
                  : ""

                let pickup_date = (leg.from_date !== "" && leg.from_date !== "Invalid date")
                  ? moment(leg.from_date.substr(0, 10)) : null
                let pickup_time = (leg.to_time !== "" && leg.to_time !== "Invalid date")
                  ? leg.to_time : "12:00"

                let delivery_date = (leg.date !== "" && leg.date !== "Invalid date")
                  ? moment(leg.date.substr(0, 10)) : null
                let delivery_time = (leg.time !== "" && leg.time !== "Invalid date")
                  ? leg.time : "12:00"

                let pickup_datetime = pickup_date !== null
                  ? pickup_time !== null
                    ? moment(pickup_date.format('YYYY-MM-DD') + " " + pickup_time + ":00")
                    : moment(pickup_date)
                  : ""
                let delivery_datetime = delivery_date !== null
                  ? delivery_time !== null
                    ? moment(delivery_date.format('YYYY-MM-DD') + " " + delivery_time + ":00")
                    : moment(delivery_date)
                  : ""

                console.log("")
                console.log('current PD >>>', currentPickupDateTime.format('YYYY-MM-DD HH:mm'))
                console.log('current DD >>>', currentDeliveryDateTime.format('YYYY-MM-DD HH:mm'))
                console.log('PD >>>', pickup_datetime.format('YYYY-MM-DD HH:mm'))
                console.log('DD >>>', delivery_datetime.format('YYYY-MM-DD HH:mm'))

                if (
                  (pickup_datetime.isBetween(currentPickupDateTime, currentDeliveryDateTime)) ||
                  (currentPickupDateTime.isBetween(pickup_datetime, delivery_datetime)) ||
                  (delivery_datetime.isBetween(pickup_datetime, delivery_datetime)) ||
                  (currentPickupDateTime.format('YYYY-MM-DD HH:mm') === pickup_datetime.format('YYYY-MM-DD HH:mm'))
                ) {
                  overlaps.push(shipment)
                }
              }
            })
          })

          if (overlaps.length > 0) {
            return overlaps[overlaps.length - 1];
          }
        }
      }
    },
    handleShipmentLink(leg) {
      console.log('test >>>', leg)
      window.location.replace(`/container/edit/${leg.id}`);
    },
    handlePickedDate(e, model) {
      this.showError = false
      if (this[model] !== null && this[model] !== "") {
        let date = moment(this[model])
        let eta = moment(this.eta)

        // if(!date.isValid()) {
        //   console.log('model >>>', this[model])
        //   if(this.justAddShowArrivalPickup || this.justAddShowArrivalAtDelivery || this.justAddShowOutgatePickup || this.justAddShowOutgateDelivery) {
        //     this.dateErrors[model] = {
        //       message: "Please input a valid date"
        //     }
        //   }
        // } 
        if (date.isValid()) {
          if (model === 'pickup_scheduled_date') {
            let pd = moment(this[model])
            let dd = this.delivery_scheduled_date !== "" ? moment(this.delivery_scheduled_date) : ""
            if (typeof this.$refs[model] !== 'undefined') {
              if (typeof this.$refs[model].value_ !== "undefined" && this.$refs[model].value_ !== null) {
                if (this.$refs[model].value_.getFullYear() === 2001) {
                  this.$refs[model].value_.setFullYear(moment().year())
                }
              }
            }

            if (dd !== "") {
              if (pd.isAfter(dd)) {
                this.dateErrors['delivery_scheduled_date'] = {
                  message: "Delivery should be later than Pickup"
                }
              }
              else {
                delete this.dateErrors.pickup_scheduled_date
              }
            }

            if (!_.isEmpty(this.delivery_scheduled_date)) {
              if (_.isEmpty(this.pickup_scheduled_time) && _.isEmpty(this.delivery_scheduled_time)) {
                if (dd !== "") {
                  this.dateErrors[model] = {
                    message: "At least one scheduled time is required"
                  }
                  this.dateErrors['pickup_scheduled_time'] = true
                }
                else {
                  delete this.dateErrors.pickup_scheduled_date
                  delete this.dateErrors.pickup_scheduled_time
                  delete this.dateErrors.delivery_scheduled_time
                }
              }
            }

            if (eta.isAfter(pd)) {
              this.dateErrors[model] = {
                message: "Pickup date should be later than ETA"
              }
            }
            else {
              delete this.dateErrors.pickup_scheduled_date
            }
          }
          else if (model === 'arrival_at_pickup_location_date') {
            let pd = moment(this.pickup_scheduled_date)
            let apd = moment(this[model])
            let add = this.arrival_at_delivery_location_date !== "" ? moment(this.arrival_at_delivery_location_date) : ""
            if (typeof this.$refs[model] !== 'undefined') {
              if (typeof this.$refs[model].value_ !== "undefined" && this.$refs[model].value_ !== null) {
                if (this.$refs[model].value_.getFullYear() === 2001) {
                  this.$refs[model].value_.setFullYear(moment().year())
                }
              }
            }

            if (add !== "") {
              if (apd.isAfter(add)) {
                this.dateErrors['arrival_at_delivery_location_date'] = {
                  message: "Arrival at delivery date should be later than Arrival at pickup date"
                }
              } else {
                delete this.dateErrors.arrival_at_pickup_location_date
              }
            }

            if (this.arrival_at_delivery_location_date !== "" && this.arrival_at_delivery_location_date !== null) {
              if (_.isEmpty(this.arrival_at_pickup_location_time) && _.isEmpty(this.arrival_at_delivery_location_time)) {
                if (add !== "") {
                  this.dateErrors[model] = {
                    message: "At least one scheduled time is required"
                  }
                  this.dateErrors['arrival_at_pickup_location_time'] = true
                } else {
                  delete this.dateErrors.arrival_at_pickup_location_date
                  delete this.dateErrors.arrival_at_pickup_location_time
                  delete this.dateErrors.arrival_at_delivery_location_date
                  delete this.dateErrors.arrival_at_delivery_location_time
                }
              }
            }

            if (eta.isAfter(apd)) {
              this.dateErrors[model] = {
                message: "Arrival at pickup date should be later than ETA"
              }
            }
            else if (pd.isAfter(apd)) {
              this.dateErrors[model] = {
                message: "Arrival at pickup should be later than Pickup"
              }
            } else {
              delete this.dateErrors.arrival_at_pickup_location_date
            }
          }
          else if (model === 'departure_from_pickup_location_date') {
            let pd = moment(this.pickup_scheduled_date)
            let apd = moment(this.arrival_at_pickup_location_date)
            let ofpd = moment(this[model])
            let ddd = this.departure_from_delivery_location_date !== "" ? moment(this.departure_from_delivery_location_date) : ""
            if (typeof this.$refs[model] !== 'undefined') {
              if (typeof this.$refs[model].value_ !== "undefined" && this.$refs[model].value_ !== null) {
                if (this.$refs[model].value_.getFullYear() === 2001) {
                  this.$refs[model].value_.setFullYear(moment().year())
                }
              }
            }

            if (ddd !== "") {
              if (ofpd.isAfter(ddd)) {
                this.dateErrors['departure_from_delivery_location_date'] = {
                  message: "Departure from delivery should be later than Departure from pickup date"
                }
              } else {
                delete this.dateErrors.departure_from_pickup_location_date
              }
            }

            if (pd.isAfter(ofpd)) {
              this.dateErrors[model] = {
                message: "Outgate from pickup date should be later than scheduled Pickup date"
              }
            } else {
              delete this.dateErrors.departure_from_pickup_location_date
            }

            if (this.departure_from_delivery_location_date !== "" && this.departure_from_delivery_location_date !== null) {
              if (_.isEmpty(this.departure_from_pickup_location_time) && _.isEmpty(this.departure_from_delivery_location_time)) {
                if (ddd !== "") {
                  this.dateErrors[model] = {
                    message: "At least one scheduled time is required"
                  }
                  this.dateErrors['departure_from_pickup_location_time'] = true
                }
              }
            } else {
              delete this.dateErrors.departure_from_pickup_location_date
              delete this.dateErrors.departure_from_pickup_location_time
              delete this.dateErrors.departure_from_delivery_location_time
            }

            if (eta.isAfter(ofpd)) {
              this.dateErrors[model] = {
                message: "Outgate from pickup should be later than ETA"
              }
            }
            else if (apd.isAfter(ofpd)) {
              this.dateErrors[model] = {
                message: "Outgate from pickup date should be later than Arrival at pickup date"
              }
            } else {
              delete this.dateErrors.departure_from_pickup_location_date
            }
          }
          else if (model === 'delivery_scheduled_date') {
            let dd = moment(this.delivery_scheduled_date)
            let pd = moment(this.pickup_scheduled_date)
            if (typeof this.$refs[model] !== 'undefined') {
              if (typeof this.$refs[model].value_ !== "undefined" && this.$refs[model].value_ !== null) {
                if (this.$refs[model].value_.getFullYear() === 2001) {
                  this.$refs[model].value_.setFullYear(moment().year())
                }
              }
            }

            if (eta.isAfter(dd)) {
              this.dateErrors[model] = {
                message: "Delivery date should be later than ETA"
              }
            } else {
              delete this.dateErrors.delivery_scheduled_date
            }

            if (pd.isAfter(dd)) {
              this.dateErrors[model] = {
                message: "Delivery date should be later than scheduled Pickup date"
              }
            } else {
              delete this.dateErrors.delivery_scheduled_date
            }

            if (this.pickup_scheduled_date !== "" && this.pickup_scheduled_date !== null) {
              if (_.isEmpty(this.pickup_scheduled_time) && _.isEmpty(this.delivery_scheduled_time)) {
                if (pd !== "") {
                  this.dateErrors[model] = {
                    message: "At least one scheduled time is required"
                  }
                  this.dateErrors['delivery_scheduled_time'] = true
                }
              }
            }
            else {
              delete this.dateErrors.delivery_scheduled_date
              delete this.dateErrors.delivery_scheduled_time
              delete this.dateErrors.pickup_scheduled_time
            }
          }
          else if (model === 'arrival_at_delivery_location_date') {
            let add = moment(this.arrival_at_delivery_location_date)
            let apd = moment(this.arrival_at_pickup_location_date)
            if (typeof this.$refs[model] !== 'undefined') {
              if (typeof this.$refs[model].value_ !== "undefined" && this.$refs[model].value_ !== null) {
                if (this.$refs[model].value_.getFullYear() === 2001) {
                  this.$refs[model].value_.setFullYear(moment().year())
                }
              }
            }

            if (eta.isAfter(add)) {
              this.dateErrors[model] = {
                message: "Delivery date should be later than ETA"
              }
            } else {
              delete this.dateErrors.arrival_at_delivery_location_date
            }

            if (apd.isAfter(add)) {
              this.dateErrors[model] = {
                message: "Arrival at Delivery date should be later than Arrival at Pickup date"
              }
            } else {
              delete this.dateErrors.arrival_at_delivery_location_date
            }

            if (this.arrival_at_pickup_location_date !== "" && this.arrival_at_pickup_location_date !== null) {
              if (_.isEmpty(this.arrival_at_pickup_location_time) && _.isEmpty(this.arrival_at_delivery_location_time)) {
                if (apd !== "") {
                  this.dateErrors[model] = {
                    message: "At least one scheduled time is required"
                  }
                  this.dateErrors['arrival_at_delivery_location_time'] = true
                }
              }
            }
            else {
              delete this.dateErrors.arrival_at_delivery_location_date
              delete this.dateErrors.arrival_at_delivery_location_time
              delete this.dateErrors.arrival_at_pickup_location_time
            }
          }
          else if (model === 'departure_from_delivery_location_date') {
            let dpd = moment(this.departure_from_pickup_location_date)
            let ddd = moment(this.departure_from_delivery_location_date)
            if (typeof this.$refs[model] !== 'undefined') {
              if (typeof this.$refs[model].value_ !== "undefined" && this.$refs[model].value_ !== null) {
                if (this.$refs[model].value_.getFullYear() === 2001) {
                  this.$refs[model].value_.setFullYear(moment().year())
                }
              }
            }

            if (eta.isAfter(ddd)) {
              this.dateErrors[model] = {
                message: "Delivery date should be later than ETA"
              }
            } else {
              delete this.dateErrors.departure_from_delivery_location_date
            }

            if (dpd.isAfter(ddd)) {
              this.dateErrors[model] = {
                message: "Departure from Delivery date should be later than Departure from Pickup date"
              }
            } else {
              delete this.dateErrors.departure_from_delivery_location_date
            }

            if (this.departure_from_pickup_location_date !== "" && this.departure_from_pickup_location_date !== null) {
              if (_.isEmpty(this.departure_from_pickup_location_time) && _.isEmpty(this.departure_from_delivery_location_time)) {
                if (dpd !== "") {
                  this.dateErrors[model] = {
                    message: "At least one scheduled time is required"
                  }
                  this.dateErrors['departure_from_delivery_location_time'] = true
                }
              }
            }
            else {
              delete this.dateErrors.departure_from_delivery_location_date
              delete this.dateErrors.departure_from_delivery_location_time
              delete this.dateErrors.departure_from_pickup_location_time
            }
          }
        }
      } else {
        // this.dateErrors[model] = {
        //   message: "This field is required"
        // }

        if (model === 'pickup_scheduled_date') {
          this.dateErrors[model] = {
            message: "This field is required"
          }
        } else if (model === 'delivery_scheduled_date') {
          this.dateErrors[model] = {
            message: "This field is required"
          }
        } else if (model === 'arrival_at_pickup_location_date') {
          if (this.justAddShowArrivalPickup) {
            this.dateErrors[model] = {
              message: "This field is required"
            }
          } else {
            this.arrival_at_pickup_location_date = ""
            this.arrival_at_pickup_location_time = null
            delete this.dateErrors.arrival_at_pickup_location_date
            delete this.dateErrors.arrival_at_pickup_location_time
          }
        } else if (model === 'departure_from_pickup_location_date') {
          if (this.justAddShowOutgatePickup) {
            this.dateErrors[model] = {
              message: "This field is required"
            }
          } else {
            this.departure_from_pickup_location_date = ""
            this.departure_from_pickup_location_time = null
            delete this.dateErrors.departure_from_pickup_location_date
            delete this.dateErrors.departure_from_pickup_location_time
          }
        } else if (model === 'arrival_at_delivery_location_date') {
          if (this.justAddShowArrivalAtDelivery || this.showArrivalAtDelivery) {
            this.dateErrors[model] = {
              message: "This field is required"
            }
          } else {
            this.arrival_at_delivery_location_date = ""
            this.arrival_at_delivery_location_time = null
            delete this.dateErrors.arrival_at_delivery_location_date
            delete this.dateErrors.arrival_at_delivery_location_time
          }
        } else if (model === 'departure_from_delivery_location_date') {
          if (this.justAddShowOutgateDelivery) {
            this.dateErrors[model] = {
              message: "This field is required"
            }
          } else {
            this.departure_from_delivery_location_date = ""
            this.departure_from_delivery_location_time = null
            delete this.dateErrors.departure_from_delivery_location_date
            delete this.dateErrors.departure_from_delivery_location_time
          }
        }
      }
    },
    handlePickupTime(e) {
      this.showError = false
      if (e.value !== null) {
        let time = moment(e.value)
        if (!time.isValid()) {
          if (!time.isValid()) {
            if (this.delivery_scheduled_time === "") {
              this.dateErrors['pickup_scheduled_time'] = {
                message: "Invalid Time ex. HH:mm"
              }
            }
          }
        } else {
          // let hasTimeError = this.dateErrors['pickup_scheduled_time']
          // if(hasTimeError) {
          delete this.dateErrors.pickup_scheduled_date
          delete this.dateErrors.pickup_scheduled_time
          delete this.dateErrors.delivery_scheduled_date
          delete this.dateErrors.delivery_scheduled_time
          // }
          this.pickup_scheduled_time = time.format('HH:mm')
        }
        this.handlePickedDate(this.pickup_scheduled_date, 'pickup_scheduled_date')
        this.handlePickedDate(this.delivery_scheduled_date, 'delivery_scheduled_date')
      } else {
        this.pickup_scheduled_time = null;
      }
    },
    handleArrivalPickupTime(e) {
      this.showError = false
      if (e.value !== null) {
        let time = moment(e.value)
        if (!time.isValid()) {
          if (this.arrival_at_delivery_location_time === "") {
            this.dateErrors['arrival_at_pickup_location_time'] = {
              message: "Invalid Time ex. HH:mm"
            }
          }
        } else {
          // let hasTimeError = this.dateErrors['arrival_at_pickup_location_time']
          // if(hasTimeError) {
          delete this.dateErrors.arrival_at_pickup_location_date
          delete this.dateErrors.arrival_at_pickup_location_time
          delete this.dateErrors.arrival_at_delivery_location_date
          delete this.dateErrors.arrival_at_delivery_location_time
          // }
          this.arrival_at_pickup_location_time = time.format('HH:mm')
        }
        this.handlePickedDate(this.arrival_at_pickup_location_date, 'arrival_at_pickup_location_date')
        this.handlePickedDate(this.arrival_at_delivery_location_date, 'arrival_at_delivery_location_date')
      } else {
        this.arrival_at_pickup_location_time = null;
      }
    },
    handleOutgatePickupTime(e) {
      this.showError = false
      if (e.value !== null) {
        let time = moment(e.value)
        if (!time.isValid()) {
          if (this.departure_from_delivery_location_time === "") {
            this.dateErrors['departure_from_pickup_location_time'] = {
              message: "Invalid Time ex. HH:mm"
            }
          }
        } else {
          // let hasTimeError = this.dateErrors['departure_from_pickup_location_time']
          // if(hasTimeError) {
          delete this.dateErrors.departure_from_pickup_location_date
          delete this.dateErrors.departure_from_pickup_location_time
          delete this.dateErrors.departure_from_delivery_location_date
          delete this.dateErrors.departure_from_delivery_location_time
          // }
          this.departure_from_pickup_location_time = time.format('HH:mm')
        }
        this.handlePickedDate(this.departure_from_pickup_location_date, 'arrival_at_pickup_location_date')
        this.handlePickedDate(this.departure_from_delivery_location_date, 'arrival_at_delivery_location_date')
      } else {
        this.departure_from_pickup_location_time = null;
      }
    },
    handleDeliveryTime(e) {
      this.showError = false
      if (e.value !== null) {
        let time = moment(e.value)
        if (!time.isValid()) {
          if (this.pickup_scheduled_time === "") {
            this.dateErrors['delivery_scheduled_time'] = {
              message: "Invalid Time ex. HH:mm"
            }
          }
        } else {
          // let hasTimeError = this.dateErrors['delivery_scheduled_time']
          // if(hasTimeError) {
          delete this.dateErrors.pickup_scheduled_date
          delete this.dateErrors.delivery_scheduled_date
          delete this.dateErrors.pickup_scheduled_time
          delete this.dateErrors.delivery_scheduled_time
          // }
          this.delivery_scheduled_time = time.format('HH:mm')
        }
        this.handlePickedDate(this.delivery_scheduled_date, 'delivery_scheduled_date')
        this.handlePickedDate(this.pickup_scheduled_date, 'pickup_scheduled_date')
      } else {
        this.delivery_scheduled_time = null;
      }
    },
    handleArrivalDeliveryTime(e) {
      this.showError = false
      if (e.value !== null) {
        let time = moment(e.value)
        if (!time.isValid()) {
          if (this.arrival_at_pickup_location_time === "" && this.arriva) {
            this.dateErrors['arrival_at_delivery_location_time'] = {
              message: "Invalid Time ex. HH:mm"
            }
          }
        } else {
          // let hasTimeError = this.dateErrors['arrival_at_delivery_location_time']
          // if(hasTimeError) {
          delete this.dateErrors.arrival_at_pickup_location_date
          delete this.dateErrors.arrival_at_delivery_location_date
          delete this.dateErrors.arrival_at_pickup_location_time
          delete this.dateErrors.arrival_at_delivery_location_time
          // }
          this.arrival_at_delivery_location_time = time.format('HH:mm')
        }
        this.handlePickedDate(this.arrival_at_pickup_location_date, 'arrival_at_pickup_location_date')
        this.handlePickedDate(this.arrival_at_delivery_location_date, 'arrival_at_delivery_location_date')
      } else {
        this.arrival_at_delivery_location_time = null;
      }
    },
    handleDepartureDeliveryTime(e) {
      this.showError = false
      if (e.value !== null) {
        let time = moment(e.value)
        if (!time.isValid()) {
          if (this.departure_from_pickup_location_time === "") {
            this.dateErrors['departure_at_delivery_location_time'] = {
              message: "Invalid Time ex. HH:mm"
            }
          }
        } else {
          // let hasTimeError = this.dateErrors['departure_from_delivery_location_time']
          // if(hasTimeError) {
          delete this.dateErrors.departure_from_pickup_location_date
          delete this.dateErrors.departure_from_delivery_location_date
          delete this.dateErrors.departure_from_pickup_location_time
          delete this.dateErrors.departure_from_delivery_location_time
          // }
          this.departure_from_delivery_location_time = time.format('HH:mm')
        }
        this.handlePickedDate(this.departure_from_pickup_location_date, 'departure_from_pickup_location_date')
        this.handlePickedDate(this.departure_from_delivery_location_date, 'departure_from_delivery_location_date')
      } else {
        this.departure_from_delivery_location_time = null;
      }
    },
    hasError(model) {
      let check = typeof this.dateErrors[model] !== 'undefined'

      return check ? true : false
    },
    // date method
    handleDateTimeFormatToUTC(date, time) {
      return convertDateTimeToUTC(date, time);
    }
  },
};
</script>

<style lang="scss">
.v-dialog.leg-modal-wrapper.edit {
  .v-card {
    .v-card__title {
      .marked-as-arrived-badge {
        /* padding-left: 15px;
        padding-right: 15px;
        padding-top: 7.5px;
        padding-bottom: 7.5px; */
        padding: 8px 12px !important;
        background: #f0fbff;
        border: 1px solid #ebf2f5;
        box-sizing: border-box;
        border-radius: 99px;
        font-size: 11px;
        color: #0171a1;
        font-style: normal;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Inter-Medium', sans-serif !important;
      }
    }

    .details-wrapper {
      .details-left-column {
        .details-content {
          .details-title {
            width: 45% !important;
          }

          .details-data {
            width: 55%;
          }
        }
      }

      .details-right-column {
        .details-content {
          .details-title {
            width: 22% !important;
          }

          .details-data {
            width: 78%;
          }
        }
      }

      .details-content {
        display: flex;
        align-items: flex-start;
        margin-bottom: 6px;

        .details-title {
          font-size: 10px;
          color: #819fb2;
          font-family: "Inter-Medium", sans-serif !important;
          text-transform: uppercase;
        }

        .details-data {
          font-size: 11px;
          color: #4a4a4a;
          font-family: "Inter-Regular", sans-serif !important;
        }
      }
    }
  }

  .v-input {
    .v-input__control {
      .v-input__slot {
        min-height: 42px !important;
        margin-bottom: 4px;
        border: 1px solid #B4CFE0 !important;

        fieldset {
          border: 1px solid #B4CFE0 !important;
          color: #4a4a4a !important;
          background-color: #fff;
        }

        input,
        textarea {
          font-family: 'Inter-Regular', sans-serif !important;
          font-size: 14px !important;
          color: #4a4a4a;

          &::placeholder {
            color: #B4CFE0 !important;
          }
        }

        textarea {
          border-radius: 4px;
          min-height: 76px !important;
          line-height: 20px !important;
        }

        .v-input__append-inner {
          .v-input__icon {
            i {
              font-size: 20px !important;
              color: #4a4a4a !important;
            }
          }
        }
      }
    }

    &.driver-instructions-field {
      .v-input__control {
        .v-input__slot {
          fieldset {
            border: none !important;
          }

          textarea {
            margin-top: 0 !important;
            padding-top: 4px !important;
          }
        }
      }
    }

    &.pickup-location-field {
      .v-label {
        top: 8px !important;
        font-family: "Inter-Regular", sans-serif;
        font-size: 14px;
        color: #4a4a4a !important;
      }

      .v-input__control {
        background-color: transparent !important;

        .v-input__slot {
          .v-text-field__slot {
            margin-right: 0 !important;

            textarea {
              padding-top: 4px !important;
            }
          }
        }
      }
    }

    &.delivery-location-field {
      .v-label {
        color: #4a4a4a !important;
        font-family: "Inter-Regular", sans-serif;
        font-size: 14px;
        position: unset !important;
        overflow: unset !important;
        white-space: normal !important;
        height: 100% !important;
        padding: 4px 0 !important;
      }

      .v-input__control {
        .v-input__slot {
          .v-select__slot {
            min-height: 76px;
            padding-top: 4px;

            .v-select__selections {
              // height: 100% !important;
              align-self: flex-start;
              flex-wrap: wrap;
              flex-direction: column;
            }

            input {
              align-self: flex-start;
            }
          }
        }

        .v-input__append-inner {
          display: none !important;
        }
      }
    }
  }

  .anl-input-group {
    .anl-input-group-right {
      .date-field {
        .v-input__slot {
          padding-top: 0 !important;
          border: 1px solid #B4CFE0;

          input {
            padding: unset !important;
          }

          .v-input__append-inner {
            padding-top: 7px;

            i {
              color: #4a4a4a !important;
            }
          }
        }
      }
    }
  }


  .date-field-dxtime {
    margin-top: 0;
    height: 41px;
    border: 1px solid #B4CFE0 !important;
    border-radius: 0px 4px 4px 0px !important;
    font-size: 12px !important;

    &.dx-state-hover {
      .dx-dropdowneditor-icon {
        background-color: transparent !important;
      }
    }

    .dx-dropdowneditor-input-wrapper {
      .dx-texteditor-container {
        .dx-texteditor-input-container {
          .dx-texteditor-input {
            padding-right: 0px !important;
            font-size: 14px;
            font-family: "Inter-Regular", sans-serif !important;
            caret-color: #4a4a4a;
          }

          .dx-placeholder {
            color: #B4CFE0 !important;
            font-size: 14px;
            font-family: "Inter-Regular", sans-serif !important;
          }
        }
      }

      .dx-texteditor-buttons-container {
        .dx-dropdowneditor-button {
          width: 35px;

          .dx-dropdowneditor-icon {
            width: 40px;
            color: #4a4a4a;

            &::before {
              content: "";
              background-image: url('/icons/clock.svg') !important;
              background-position: center !important;
              background-repeat: no-repeat !important;
              background-size: cover !important;
              width: 16px !important;
              height: 16px !important;
              color: #4a4a4a !important;
              padding: 0px;
            }
          }
        }

        .dx-clear-button-area {
          width: 30px;
          min-width: 30px;

          .dx-icon.dx-icon-clear {
            &::before {
              content: "\e932";
              background-image: url('/icons/close/close-red.svg') !important;
              background-position: center !important;
              background-repeat: no-repeat !important;
              background-size: cover !important;
              width: 14px !important;
              height: 14px !important;
              color: #4a4a4a !important;
              padding: 0px;
            }
          }
        }
      }
    }
  }

  .leg-date-field {
    border-width: 1px !important;
    font-size: 14px !important;
    font-family: "Inter-Regular", sans-serif !important;
    margin-top: 0;
    caret-color: #4a4a4a;
    outline: 0;
  }

  i.append-icon {
    position: relative;
    float: right;
    margin: -30px 10px;
    color: #4a4a4a;
  }

  .label {
    font-size: 10px !important;
    font-weight: unset !important;
    font-family: "Inter-SemiBold", sans-serif !important;
    padding-bottom: 2px !important;
  }

  .custom-error-text,
  .v-text-field__details {
    height: 12px;
    line-height: 12px;
    margin-top: 6px;
    font-family: 'Inter-Medium', sans-serif;
  }

  .button-outline {
    .v-btn__content {
      letter-spacing: 0;
    }
  }

  .datetime-field-groups {
    i.red-color {
      color: #F93131 !important;
    }

    i.not-red {
      color: #819fb2 !important
    }
  }
}
</style>

<style>
.add-option-btn:hover {
  background: rgba(180, 207, 224, 0.3);
  cursor: pointer;
}

.v-dialog .v-input__control .v-input__slot .v-input__append-inner .v-input__icon--append .ic-calendar::before {
  font-size: 16px !important;
}
</style>

<style lang="scss">
@import '../../../../assets/scss/vc_calendar.scss';

.v-menu__content {
  // min-width: 275px !important;

  div[role="listbox"] {
    padding: 0px;

    div[role="option"] {
      padding: 5px 12px !important;
      border-bottom: 1px solid #ddd;

      span {
        font-size: 14px;

        .driverStatus {
          background: #fff9f0;
          border-radius: 4px;
          font-size: 10px;
          font-weight: 600;
          text-transform: uppercase !important;
        }

        .activeRef {
          font-size: 10px;
          color: #6d858f;
          font-weight: 600;
          text-transform: uppercase;

          .linkIcon {
            position: absolute;
            margin-top: 3px;
          }
        }
      }
    }
  }
}
</style>
