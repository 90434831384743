<template>
	<div class="containers-inner-wrapper compact-view-wrapper">
		<div class="container-compact-tabs-wrapper">
			<v-tabs v-if="retabs" class="container-compact-tabs" v-model="activeTab">
				<v-tab v-for="(n, i) in tabItems" :key="i" @click="getCurrentTab(n.name, i, n)">
					<span>{{ n.name == "Assigned" ? "Scheduled" : n.name }}</span>

					<span class="tab-count-wrapper" v-if="n.name !== 'Completed' && n.name !== 'Cancelled'">
						<span class="tab-count-value" v-if="n.count">
							{{ n.count }}
						</span>
					</span>
				</v-tab>
			</v-tabs>
		</div>

		<div class="table-content-wrapper">
			<div class="loading-overlay" v-if="isShowLoadingUI && !isUpdatingCells">
				<v-progress-circular :size="30" color="#0171a1" indeterminate>
				</v-progress-circular>
			</div>

			<div class="search-component-container">
				<v-tabs class="container-sub-tabs" v-model="subTabIndex">
					<v-tab v-for="(n, i) in tabItems[activeTab].sub_tab" :key="i" @click="getCurrentSubTab(n, i)">
						<span>{{ n.name }}</span>

						<span class="tab-count-value"
							v-if="tabItems[activeTab].sub_tab[i] && tabItems[activeTab].sub_tab[i].count !== 0 && currentSelectTab !== 'Scheduled'">
							<span v-if="tabItems[activeTab].sub_tab[i]" style="color: #fff">
								{{ tabItems[activeTab].sub_tab[i].count }}
							</span>
						</span>
					</v-tab>
				</v-tabs>

				<div class="d-flex justify-end align-center">
					<button type="button" class="btn-white mr-2" @click="activateFilter"
						:class="isFilterEnabled ? 'activated' : ''">
						<img src="@/assets/icons/filter.svg" class="mr-2" height="14px" width="14px" />
						Filters
					</button>

					<SearchComponent placeholder="Search Containers" :searchValue.sync="search"
						:handleSearchComponent="true" />
				</div>
			</div>

			<div id="data-grid-demo" class="px-4">
				<DxDataGrid v-if="reload" ref="dataGridReference" id="gridContainer" :data-source="dataSource"
					:show-borders="true" :errorRowEnabled="false" :on-focused-cell-changing="onFocusedCellChanging"
					key-expr="ID" @cell-prepared="onCellPrepared" width="100%" noDataText="" @editing-start="onEditingStart"
					@content-ready="onContentReady" @editor-preparing="onEditorPreparing" @row-updating="onRowUpdating"
					@cellHoverChanged="onCellHoverChanged($event)" :hover-state-enabled="true"
					:onContextMenuPreparing="onContextMenuPreparing" :remote-operations="true" :scrolling="scrollingOptions"
					:syncLookupFilterValues="false" :editing="editingOptions" :loadPanel="loadPanelOptions">

					<DxFilterRow :visible="isFilterEnabled" />

					<!-- Start of Default Columns -->
					<DxColumn data-field="Container" :width="150" caption="Container" cell-template="container-template"
						:allow-sorting="false" :calculate-filter-expression="calculateFilterExpressionContainer"
						:filter-operations="allowedOperations" :selected-filter-operation="selectedOperation">
						<DxRequiredRule message="Container is required" />
						<DxStringLengthRule :min="11" :max="11"
							message="The first 4 characters are alpha and followed by 7 digits." />
						<DxCustomRule type="custom" message="" :validation-callback="validationCallBackMethodContainer" />
					</DxColumn>

					<DxColumn data-field="MBL" :width="170" caption="MBL" cell-template="mbl-template"
						:allow-sorting="false" :calculate-filter-expression="calculateFilterExpressionMBL"
						:filter-operations="allowedOperations" :selected-filter-operation="selectedOperation">
						<DxRequiredRule message="MBL is required" />
					</DxColumn>

					<DxColumn data-field="SizeID" :width="110" caption="Size" :allow-sorting="false"
						:calculate-filter-expression="calculateFilterExpressionSize" :filter-operations="allowedOperations"
						:selected-filter-operation="selectedOperation">
						<DxLookup :data-source="sizeLists" value-expr="id" display-expr="name" />
					</DxColumn>

					<DxColumn data-field="Type" :width="100" :allowEditing="false" caption="Type" :allow-sorting="false"
						:calculate-filter-expression="calculateFilterExpressionType" :filter-operations="allowedOperations"
						:selected-filter-operation="selectedOperation" />

					<DxColumn data-field="DO" :width="95" :allowEditing="false" :allow-sorting="false" caption="DO ID"
						cell-template="do-template" :calculate-filter-expression="calculateFilterExpressionDO"
						:filter-operations="allowedOperations" :selected-filter-operation="selectedOperation" />

					<DxColumn data-field="Ref" :width="105" :allowEditing="false" :allow-sorting="false" caption="Ref#"
						cell-template="ref-template" :calculate-filter-expression="calculateFilterExpressionRef"
						:filter-operations="allowedOperations" :selected-filter-operation="selectedOperation" />

					<DxColumn data-field="CustomerID" :width="220" :allow-sorting="false" caption="Customer"
						cell-template="customer-template" :calculate-filter-expression="calculateFilterExpressionCustomer"
						:filter-operations="allowedOperations" :selected-filter-operation="selectedOperation">
						<DxRequiredRule message="Customer is required" />
						<DxLookup :data-source="customerLists" value-expr="id" display-expr="company_name"
							value="company_name">
						</DxLookup>
					</DxColumn>

					<DxColumn data-field="ConsigneeName" :width="200" :allow-sorting="false" caption="Consignee"
						:calculate-display-value="calculateDisplayValueConsignee" cell-template="consignee-template"
						:calculate-filter-expression="calculateFilterExpressionConsignee"
						:filter-operations="allowedOperations" :selected-filter-operation="selectedOperation">
						<DxLookup :data-source="getCurrentConsignees" display-expr="name" value-expr="id" value="name">
						</DxLookup>
					</DxColumn>

					<DxColumn data-field="CarrierID" :width="140" :allow-sorting="false" caption="Carrier"
						:calculate-filter-expression="calculateFilterExpressionCarrier"
						:filter-operations="allowedOperations" :selected-filter-operation="selectedOperation">
						<DxLookup :data-source="carrierLists" value-expr="id" display-expr="name" />
					</DxColumn>
					<!-- End of Default Columns -->

					<!-- Start of hiding/showing columns (conditional) -->
					<DxColumn data-field="Lfdp" :width="120" data-type="date" caption="LFD"
						cell-template="date-cell-template" :visible="currentSelectTab === 'Scheduled'" name="LfdpField2"
						:calculate-filter-expression="calculateFilterExpressionLfd" :filter-operations="allowedOperations"
						:selected-filter-operation="selectedOperation">
						<DxCustomRule type="custom" message="" :validation-callback="validationCallBackMethodLfd"
							:reevaluate="true" />
					</DxColumn>

					<DxColumn data-field="Lfdr" :width="120" data-type="date" caption="PDD"
						cell-template="date-cell-template" :visible="currentSelectTab === 'Scheduled'" name="LfdrField2"
						:calculate-filter-expression="calculateFilterExpressionPdd" :filter-operations="allowedOperations"
						:selected-filter-operation="selectedOperation">
						<DxCustomRule type="custom" message="" :validation-callback="validationCallBackMethodPdd"
							:reevaluate="true" />
					</DxColumn>

					<DxColumn data-field="Driver" :width="180" :allow-sorting="false" caption="Driver"
						:visible="currentSelectTab === 'Scheduled'" :calculate-display-value="calculateDisplayValueDriver"
						cell-template="driver-template"
						:calculate-filter-expression="calculateFilterExpressionDispatchDriver"
						:filter-operations="allowedOperations" :selected-filter-operation="selectedOperation">
						<DxLookup :data-source="driverLists" value-expr="id" display-expr="name" value="name" />
					</DxColumn>

					<DxColumn data-field="Status" :width="160" :allow-sorting="false" :allow-editing="false"
						caption="Status" :visible="currentSelectTab === 'Scheduled'" cell-template="current-status-template"
						:filter-operations="allowedOperations" :selected-filter-operation="selectedOperation"
						:calculate-filter-expression="calculateFilterExpressionDispatchStatus">
						<!-- <DxLookup
							:data-source="terminals"
							value-expr="id"
							display-expr="name" /> -->
					</DxColumn>

					<DxColumn data-field="Mode" :width="100" :allow-editing="false" caption="Mode"
						:visible="currentSelectTab === 'Scheduled'" name="ModeField1" :filter-operations="allowedOperations"
						:selected-filter-operation="selectedOperation"
						:calculate-filter-expression="calculateFilterExpressionDispatchMode">
					</DxColumn>

					<DxColumn data-field="TypeDelivery" :width="140" :allow-editing="false" caption="Type Of Delivery"
						:visible="currentSelectTab === 'Scheduled'" :filter-operations="allowedOperations"
						:selected-filter-operation="selectedOperation"
						:calculate-filter-expression="calculateFilterExpressionDispatchDeliveryType">
					</DxColumn>

					<!-- Current Location -->
					<DxColumn data-field="Location" :width="250" :allow-sorting="false" :allow-editing="true"
						caption="Current Location"
						:visible="!isTabLFDCaption && currentSelectTab !== 'At Yard' && currentSelectTab !== 'Scheduled'"
						cell-template="current-location-template" :filter-operations="allowedOperations"
						:selected-filter-operation="selectedOperation"
						:calculate-filter-expression="calculateFilterExpressionLocation" name="DefaultLocation">
						<DxLookup :data-source="terminals" value-expr="id" display-expr="name" />
					</DxColumn>

					<!-- Pickup Location Field -->
					<DxColumn data-field="Location" :width="250" :allow-sorting="false" :allow-editing="false"
						caption="Pickup Location" :visible="currentSelectTab === 'Scheduled'"
						cell-template="current-location-template" :filter-operations="allowedOperations"
						:selected-filter-operation="selectedOperation"
						:calculate-filter-expression="calculateFilterExpressionLocation" name="PickupLocation">
					</DxColumn>

					<!-- For At Yard Location -->
					<DxColumn data-field="Location" :width="250" :allow-sorting="false" :allow-editing="false"
						caption="Current Location" :visible="currentSelectTab === 'At Yard'"
						cell-template="atyard-location-template" :filter-operations="allowedOperations"
						:selected-filter-operation="selectedOperation"
						:calculate-filter-expression="calculateFilterExpressionLocation">
					</DxColumn>

					<DxColumn data-field="LocationDateTime" :width="160" :allow-editing="false" data-type="date"
						caption="Pickup Time" :visible="currentSelectTab === 'Scheduled'"
						:calculate-display-value="calculateDisplayValuePickupTime"
						:calculate-filter-expression="calculateFilterExpressionPickupTime"
						:filter-operations="allowedOperations" :selected-filter-operation="selectedOperation"
						name="DefaultLocationDateTime">
						<!-- cell-template="pickuptime-cell-template" -->
					</DxColumn>

					<DxColumn data-field="DeliveryLocation" :width="250" :allow-sorting="false" :allow-editing="false"
						caption="Delivery On" :visible="currentSelectTab === 'Scheduled'"
						cell-template="current-delivery-template" :filter-operations="allowedOperations"
						:selected-filter-operation="selectedOperation"
						:calculate-filter-expression="calculateFilterExpressionDeliveryOn">
					</DxColumn>

					<DxColumn data-field="DeliveryDateTime" :width="160" :allow-editing="false" data-type="date"
						caption="Delivery Time" :visible="currentSelectTab === 'Scheduled'"
						:calculate-display-value="calculateDisplayValueDeliveryTime"
						:calculate-filter-expression="calculateFilterExpressionDeliveryTime"
						:filter-operations="allowedOperations" :selected-filter-operation="selectedOperation"
						name="DefaultDeliveryDateTime">
					</DxColumn> <!-- cell-template="delivery-cell-template" -->

					<!-- ETA Field -->
					<DxColumn data-field="Eta" :width="120" data-type="date" caption="ETA"
						cell-template="date-cell-template" :visible="isShowETA"
						:calculate-filter-expression="calculateFilterExpressionEta" :filter-operations="allowedOperations"
						:selected-filter-operation="selectedOperation">
						<DxRequiredRule message="ETA is required" />
						<!-- sort-order="asc"  -->
						<DxCustomRule type="custom" message="" :validation-callback="validationCallBackMethodEta"
							:reevaluate="true" />
					</DxColumn>

					<!-- LFD field -->
					<DxColumn data-field="Lfdp" :width="110" data-type="date" :caption="isTabLFDCaption ? 'LFD P' : 'LFD'"
						cell-template="date-cell-template" :visible="isShowLfdp"
						:calculate-filter-expression="calculateFilterExpressionLfd" :filter-operations="allowedOperations"
						:selected-filter-operation="selectedOperation">
						<DxCustomRule type="custom" message="" :validation-callback="validationCallBackMethodLfd" />
					</DxColumn>

					<DxColumn data-field="discharged" :width="35" :allow-sorting="false" dataType="boolean"
						editorType="dxCheckBox" header-cell-template="dischargedHeaderTemplate"
						:visible="currentSelectTab === 'At Port' && selected_sub_tab.name === 'Pending'"
						:allow-filtering="false" />
					<!-- v-if="currentSelectTab === 'At Port' && selected_sub_tab.name === 'Pending'" -->

					<DxColumn data-field="released" :width="35" :allow-sorting="false" dataType="boolean"
						header-cell-template="releasedHeaderTemplate"
						:visible="currentSelectTab === 'At Port' && selected_sub_tab.name === 'Pending'"
						:allow-filtering="false" />
					<!-- v-if="currentSelectTab === 'At Port' && selected_sub_tab.name === 'Pending'" -->

					<DxColumn data-field="gate_fees" :width="35" :allow-sorting="false" dataType="boolean"
						header-cell-template="feesHeaderTemplate"
						:visible="currentSelectTab === 'At Port' && selected_sub_tab.name === 'Pending'"
						:allow-filtering="false" />
					<!-- v-if="currentSelectTab === 'At Port' && selected_sub_tab.name === 'Pending'"  -->

					<!-- For Completed Tab -->
					<DxColumn data-field="LocationDateTime" :width="160" :allow-editing="false" data-type="date"
						caption="Picked Up" :visible="currentSelectTab === 'Completed'"
						:calculate-display-value="calculateDisplayValuePickupTime" :filter-operations="allowedOperations"
						:selected-filter-operation="selectedOperation"
						:calculate-filter-expression="calculateFilterExpressionPickedUp">
					</DxColumn>

					<DxColumn data-field="DeliveryDateTime" :width="160" :allow-editing="false" data-type="date"
						caption="Delivered On" :visible="currentSelectTab === 'Completed'"
						:calculate-display-value="calculateDisplayValueDeliveryTime" :filter-operations="allowedOperations"
						:selected-filter-operation="selectedOperation"
						:calculate-filter-expression="calculateFilterExpressionDelivered">
					</DxColumn>

					<!-- For Cancelled Tab -->
					<DxColumn data-field="Opened" :width="120" :allow-editing="false" data-type="date" caption="Opened"
						cell-template="date-cell-template" :visible="currentSelectTab === 'Cancelled'"
						:filter-operations="allowedOperations" :selected-filter-operation="selectedOperation"
						:calculate-filter-expression="calculateFilterExpressionOpened">
					</DxColumn>

					<DxColumn data-field="Closed" :width="120" :allow-editing="false" data-type="date" caption="Closed"
						cell-template="date-cell-template" :visible="currentSelectTab === 'Cancelled'"
						:filter-operations="allowedOperations" :selected-filter-operation="selectedOperation"
						:calculate-filter-expression="calculateFilterExpressionClosed">
					</DxColumn>

					<!-- At Yard Tab -->
					<DxColumn data-field="ArrivedAt" :width="160" caption="Arrived At" :allowEditing="false"
						data-type="date" :visible="currentSelectTab === 'At Yard' || currentSelectTab === 'At the Customer'"
						:calculate-cell-value="calculateDisplayArrivedAtData" :filter-operations="allowedOperations"
						:selected-filter-operation="selectedOperation"
						:calculate-filter-expression="calculateFilterExpressionArrived">
						<!-- v-if="currentSelectTab === 'At Yard' || currentSelectTab === 'At the Customer'" -->
						<!-- :calculate-cell-value="calculateArrivedAtData" -->
					</DxColumn>

					<DxColumn data-field="Mode" :width="100" :allow-editing="false" caption="Mode"
						:visible="currentSelectTab === 'At the Customer'" name="ModeField2"
						:filter-operations="allowedOperations" :selected-filter-operation="selectedOperation"
						:calculate-filter-expression="calculateFilterExpressionDispatchMode">
					</DxColumn>

					<!-- Empty Notified -->
					<DxColumn data-field="EmptyNotified" :width="160" caption="Empty Notified" data-type="datetime"
						:visible="currentSelectTab === 'At the Customer' && selected_sub_tab.name === 'empty'"
						:calculate-display-value="calculateDisplayValueEmptyNotified" :filter-operations="allowedOperations"
						:selected-filter-operation="selectedOperation">
						<!-- v-if="currentSelectTab === 'At the Customer' && selected_sub_tab.name === 'empty'" -->
					</DxColumn>

					<DxColumn data-field="Lfdr" :width="110" data-type="date"
						:caption="isTabLFDCaption ? 'LFD R' : 'Return LFD'" :visible="isShowLfdr"
						:calculate-display-value="calculateDisplayValueLFDR"
						:calculate-filter-expression="calculateFilterExpressionPdd" :filter-operations="allowedOperations"
						:selected-filter-operation="selectedOperation">
						<!-- cell-template="date-cell-template" -->
						<DxCustomRule type="custom" message="" :validation-callback="validationCallBackMethodPdd" />
					</DxColumn>

					<DxColumn data-field="AllTabStatus" :width="160" :allow-sorting="false" :allow-editing="false"
						caption="Status" :visible="currentSelectTab === 'All'" cell-template="all-tab-status-template"
						:calculate-filter-expression="calculateFilterExpressionTabStatus"
						:filter-operations="allowedOperations" :selected-filter-operation="selectedOperation">
					</DxColumn>

					<!-- Empty Returned for Completed -->
					<DxColumn data-field="EmptyNotified" :width="160" caption="Empty Returned" :allow-editing="false"
						data-type="date" :visible="currentSelectTab === 'Completed'"
						:calculate-display-value="calculateDisplayValueEmptyNotified" name="EmptyNotifiedField2"
						:filter-operations="allowedOperations" :selected-filter-operation="selectedOperation"
						:calculate-filter-expression="calculateFilterExpressionEmptyReturned">
					</DxColumn>

					<!-- Direct / Prepull for Completed -->
					<DxColumn data-field="DirectPrepull" :width="160" caption="Direct / Prepull" :allow-editing="false"
						:visible="currentSelectTab === 'Completed'" :filter-operations="allowedOperations"
						:selected-filter-operation="selectedOperation"
						:calculate-filter-expression="calculateFilterExpressionPrepull">
					</DxColumn>

					<!-- Scheduled -->
					<DxColumn data-field="Scheduled" :width="160" :allow-sorting="false" :allow-editing="true"
						caption="Scheduled" data-type="datetime" :visible="isShowScheduledColumn"
						:calculate-display-value="calculateDisplayValueScheduled" :filter-operations="allowedOperations"
						:selected-filter-operation="selectedOperation">
						<DxCustomRule type="custom" message="" :validation-callback="validationCallBackScheduled" />
					</DxColumn>

					<DxColumn cell-template="action-cell" :width="70" type="buttons" caption="Action"
						:allow-filtering="false" />

					<template #container-template="{ data }">
						<CustomTemplate :data="data" field="Container" :isCustom="false" @copyFunction="copyToClipboard" />
					</template>

					<template #mbl-template="{ data }">
						<CustomTemplate :data="data" field="MBL" :isCustom="false" @copyFunction="copyToClipboard" />
					</template>

					<template #do-template="{ data }">
						<CustomTemplate :data="data" field="DO" :isCustom="false" @copyFunction="copyToClipboard" />
					</template>

					<template #ref-template="{ data }">
						<CustomTemplate :data="data" field="Reference Number" :isCustom="false"
							@copyFunction="copyToClipboard" />
					</template>

					<template #customer-template="{ data }">
						<CustomTemplate :data="data" field="Customer" :isCustom="true" @copyFunction="copyToClipboard" />
					</template>

					<template #consignee-template="{ data }">
						<CustomTemplate :data="data" field="Consignee" :isCustom="true" @copyFunction="copyToClipboard" />
					</template>

					<template #driver-template="{ data }">
						<CustomTemplate :data="data" field="Driver" :isCustom="true" @copyFunction="copyToClipboard" />
					</template>

					<template #date-cell-template="{ data }">
						<span> {{ getDateFormat(data.value) === "" ? "N/A" : getDateFormat(data.value) }} </span>
					</template>

					<template #all-tab-status-template="{ data }">
						<span class="text-capitalize"> {{ data.value }} </span>
					</template>

					<template #lfdr-template="{ data }">
						<span>{{ data.value !== "" ? data.value : "N/A" }}</span>
					</template>

					<template #pickuptime-cell-template="{ data }">
						<div class="pickup_on_data tooltip-location" style="font-size: 14px">
							{{ pickupOn(data.row.data, data.row.data, "time") }}
						</div>
					</template>

					<template #delivery-cell-template="{ data }">
						<div class="pickup_on_data tooltip-location" style="font-size: 14px">
							{{ deliveryon(data.row.data, "time") }}
						</div>
					</template>

					<template #current-location-template="{ data }">
						<div>
							<v-tooltip bottom offset-y right content-class="terminal-at-tooltip"
								v-if="currentSelectTab !== 'Scheduled'">
								<template v-slot:activator="{ on, attrs }">
									<p class="overflow-hidden-ellipsis mb-0" style="font-size: 14px" v-bind="attrs"
										v-on="on">
										{{ data.data.terminal_id != null
											? getTerminalData(data.data.terminal_id, "nickname") : "--"
										}}
									</p>
								</template>
								<p class="mb-0" style="font-size: 14px">
									{{ data.data.terminal_id != null
										? getTerminalData(data.data.terminal_id, "name") : "--"
									}}
									<span>
										{{ data.data.terminal_id != null
											? getTerminalData(data.data.terminal_id, "firms") : ""
										}}
									</span>
								</p>
								<p class="mb-0" style="font-size: 12px">
									{{ data.data.terminal_id != null
										? getTerminalData(data.data.terminal_id, "address") : ""
									}}
								</p>
							</v-tooltip>

							<div v-else>
								<!-- if location is terminal (show nickname in the cell and show details in tooltip on hover) -->
								<div class="tooltip-for-pickup" v-if="checklength_dispatch(data.data.dispatch_schedule)">
									<v-tooltip bottom offset-y right content-class="terminal-at-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<span v-bind="attrs" v-on="on">
												<div class="pickup_on_data tooltip-location" style="font-size: 14px">
													{{ pickupOn(data.data, data.data, "location") }}
												</div>
											</span>
										</template>

										<span>
											<!-- <p class="mb-0" style="font-size: 14px">
												{{ data.data.terminal != null
													? data.data.terminal.name
													: "--"
												}}
												<span style="font-size: 14px">
												{{ data.data.terminal != null
													? data.data.terminal.firms_code
													: ""
												}}
												</span>
											</p>
											<p class="mb-0" style="font-size: 12px">
												{{ data.data.terminal != null
													? data.data.terminal.address
													: ""
												}}
											</p> -->

											{{ pickupOn(data.data, data.data, "location") }}
										</span>
									</v-tooltip>
								</div>

								<div v-else>
									<!-- if location is not terminal (show whole location details in tooltip on hover) -->
									<v-tooltip bottom offset-y right content-class="terminal-at-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<span v-bind="attrs" v-on="on">
												<div class="pickup_on_data non-tooltip" style="font-size: 14px">
													{{ pickupOn(data.data, data.data, "location") }}
												</div>
											</span>
										</template>

										<div class="pickup_on_data non-tooltip">
											{{ pickupOn(data.data, data.data, "location") }}
										</div>
									</v-tooltip>
								</div>
							</div>
						</div>
					</template>

					<template #atyard-location-template="{ data }">
						<div class="current-location-template-wrapper">
							<v-tooltip bottom offset-y right content-class="terminal-at-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<span v-bind="attrs" v-on="on">
										<div class="pickup_on_data non-tooltip" style="font-size: 14px">
											{{ getLastArrivedLeg(data.row.data) !== null
												? getLastArrivedLeg(data.row.data).address : ""
											}}
										</div>
									</span>
								</template>

								<div class="pickup_on_data non-tooltip">
									{{ getLastArrivedLeg(data.row.data) !== null
										? getLastArrivedLeg(data.row.data).address : ""
									}}
								</div>
							</v-tooltip>
						</div>
					</template>

					<template #current-delivery-template="{ data }">
						<v-tooltip bottom offset-y right content-class="terminal-at-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<div class="pickup_on_data non-tooltip">
										{{ deliveryon(data.row.data, "location") }}
									</div>
								</span>
							</template>

							<div class="pickup_on_data non-tooltip">
								{{ deliveryon(data.row.data, "location") }}
							</div>
						</v-tooltip>
					</template>

					<template #current-status-template="{ data }">
						<span :id="`status${data.row.data.id}`" :class="getStatusClassUpdated(data.row.data)">
							{{ getAssignedToDriver(data.row.data).status }}
						</span>
					</template>

					<template #action-cell="{ data }">
						<div class="action-cell-wrapper">
							<div class="d-flex justify-center align-center" v-if="isShowMenuTab">
								<button @click="goToDetailsPage(data)" title="View" style="height: 16px">
									<i class="dx-icon dx-icon-customicon view"></i>
								</button>
							</div>

							<div class="d-flex justify-center align-center" v-else>
								<!-- <button class="mr-1">
									<img src="../../../../../assets/icons/email_icon_send.svg" alt=""
										style="width: 15px !important; height: 15px !important;">
								</button> -->

								<v-menu bottom left offset-y content-class="datagrid-lists-menu">
									<template v-slot:activator="{ on, attrs }">
										<v-btn :ripple="false" icon v-bind="attrs" v-on="on" width="25px" height="unset">
											<i class="dx-icon dx-icon-customicon dots"></i>
										</v-btn>
									</template>

									<v-list class="datagrid-lists">
										<v-list-item v-if="!isAnyLegAvailable(data)" @click="addFirstLeg(data)">
											<div class="d-flex" style="margin-bottom: 2px">
												<img src="@/assets/icons/add-icon-black.svg" alt="Add" class="mr-3"
													width="11px" height="11px" />
											</div>
											<v-list-item-title> Add Leg </v-list-item-title>
										</v-list-item>

										<v-list-item v-else @click="editFirstLeg(data)">
											<img src="@/assets/icons/edit-black.svg" alt="Edit" class="mr-2" width="15px"
												height="15px" />
											<v-list-item-title style="padding-top: 2px">
												Edit Leg
											</v-list-item-title>
										</v-list-item>

										<v-list-item @click="goToDetailsPage(data)">
											<img src="@/assets/icons/visibility-black.svg" alt="View" class="mr-2"
												width="15px" height="15px" />
											<v-list-item-title> View Details </v-list-item-title>
										</v-list-item>

										<v-list-item @click="openDialog(data)"
											v-if="currentSelectTab === 'At the Customer'">
											<img src="@/assets/icons/mark-empty-black.svg" alt="Mark Empty" class="mr-2"
												width="15px" height="15px" />
											<v-list-item-title>
												{{ selected_sub_tab.name === "full"
													? "Mark Empty"
													: "Unmark as Empty"
												}}
											</v-list-item-title>
										</v-list-item>

										<v-list-item v-if="currentSelectTab === 'Scheduled'"
											@click="markAsArrivedFirstLeg(data)">
											<img src="@/assets/icons/mark-empty-black.svg" alt="Mark Empty" class="mr-2"
												width="15px" height="15px" />
											<v-list-item-title> Mark as Arrived </v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
							</div>
						</div>
					</template>

					<!-- header templates -->
					<template #dischargedHeaderTemplate="{}">
						<HeaderTemplate field="discharged" />
					</template>

					<template #releasedHeaderTemplate="{}">
						<HeaderTemplate field="released" />
					</template>

					<template #feesHeaderTemplate="{}">
						<HeaderTemplate field="gate-fees" />
					</template>

					<DxPaging :page-size="20" />
				</DxDataGrid>

				<DxTooltip :ref="tooltipRefGlobal" position="top" :hide-on-outside-click="true">
					<template>
						<span>{{ tooltipMessage }}</span>
					</template>
				</DxTooltip>
			</div>
		</div>
		<!-- :pickupLocation="editingLegPickupLocation" -->
		<EditLeg
			:showEditLegModal="showEditLegModal"
			:driverList="getDrivers"
			:chassisList="getChassis"
			:responseChassis="responseChassis"
			:modedropdown="modedropdown"
			:typeDropdown="typeDropdown"			
			:pickupLocation="editingLegPickupLocation"
			:leg="editingLeg"
			:currentEditingLegNum="currentEditingLegIndex"
			:markasrrive="markasrrived"
			:editIndex="editIndex"
			:refNumber="refNumber"
			@close="() => {
				showEditLegModal = false;
				editingLeg = null;
				markasrrived = 0;
			}"
			@saveEditedLeg="saveEditedLeg"
			@saveNewChassis="saveNewChassis"
			:responseObjectMeta="responseObjectMeta"
			:editingLocation="editinglocation"
			:theTerminals="terminals"
			:eta="getEditingShipmentEta" />

		<AddNewLeg
			:showAddLegModal="showAddLegModal"
			:driverList="getDrivers"
			:chassisList="getChassis"
			:responseChassis="responseChassis"
			:modedropdown="modedropdown"
			:getObject="null"
			:typeDropdown="typeDropdown"
			:pickupLocation="getPickupLocation"
			:lastLeg="lastLeg"
			@close="showAddLegModal = false"
			@addNewLeg="addNewLeg"
			@saveNewChassis="saveNewChassis"
			:bus="null"
			:responseObjectMeta="responseObjectMeta"
			:eta="getEditingShipmentEta"
			:theTerminals="terminals"
			:editingLocation="editinglocation"
			:dialogData="editingShipment ? editingShipment.dispatch_schedule : editingShipment" />

		<EmptyDialog :dialog.sync="dialog" :itemData="dialogData" :isLoading="isSubmitting" @closeDialog="closeDialog"
			@updateContainerEmpty="updateContainer" />
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SearchComponent from "../../../../SearchComponent/SearchComponent.vue";
import globalMethods, { } from "../../../../../utils/globalMethods";
import AddNewLeg from "../../Dialog/AddNewLeg.vue";
import EditLeg from "../../Dialog/EditLeg.vue";
import EmptyDialog from "../../Dialog/EmptyDialog.vue";
import CustomTemplate from "./Templates/CustomTemplate.vue";
import HeaderTemplate from "./Templates/HeaderTemplate.vue";

import moment from 'moment'
import _ from 'lodash'
import iziToast from "izitoast"
import axios from "axios";
var cancel;
const poBaseUrl = process.env.VUE_APP_PO_URL;

// devextreme imports start
import DxDataGrid, { DxColumn, DxLookup, DxPaging, DxFilterRow, } from 'devextreme-vue/data-grid';
import { DxRequiredRule, DxCustomRule, DxStringLengthRule } from 'devextreme-vue/validator';
import DxTooltip from "devextreme-vue/tooltip"
import CustomStore from 'devextreme/data/custom_store';

import compactViewMethods, {
	GetStore, onCellPreparedFunction,
	onContextMenuPreparingFunction, onFocusedCellChangingFunction,
	onCellHoverChangedFunction, onContentReadyFunction,
	onEditorPreparingFunction, onRowUpdatingFunction, editorPreparedFunction,
	getDateFormatFunction, timeConvertFunction, validationErrorsFunction
} from '../methods/compactViewMethods';

import {
	calculateFilterExpressionContainer, calculateFilterExpressionMBL, calculateFilterExpressionSize,
	calculateFilterExpressionCustomer, calculateFilterExpressionCarrier, calculateFilterExpressionDO,
	calculateFilterExpressionRef, calculateFilterExpressionConsignee, calculateFilterExpressionEta,
	calculateFilterExpressionLfd, calculateFilterExpressionPdd, calculateFilterExpressionType,
	calculateFilterExpressionLocation, calculateFilterExpressionDispatchDriver, calculateFilterExpressionDispatchMode,
	calculateFilterExpressionDispatchDeliveryType, calculateFilterExpressionPickupTime, calculateFilterExpressionDeliveryTime,
	calculateFilterExpressionDeliveryOn, calculateFilterExpressionDispatchStatus, calculateFilterExpressionTabStatus,
	calculateFilterExpressionOpened, calculateFilterExpressionClosed, calculateFilterExpressionArrived,
	calculateFilterExpressionPickedUp, calculateFilterExpressionDelivered, calculateFilterExpressionEmptyReturned,
	calculateFilterExpressionPrepull
} from '../methods/compactFilterExpressions';

let currentConsignees = []

export default {
	name: "ContainersDesktopCompact",
	props: [
		"items",
		"isMobile",
		"tabitemdata",
		"newtabitem",
		"contentData",
		"allcomopents",
		"customers",
		"terminals",
		"magdriver",
		"shipmentsize",
		"mgaFiles",
		"responseObjectMeta",
		"typeDropdown",
		"modedropdown"
	],
	components: {
		EmptyDialog,
		EditLeg,
		AddNewLeg,
		DxDataGrid,
		DxColumn,
		DxRequiredRule,
		DxLookup,
		DxTooltip,
		SearchComponent,
		DxCustomRule,
		DxStringLengthRule,
		DxPaging,
		DxFilterRow,
		CustomTemplate,
		HeaderTemplate,
	},
	data: () => ({
		editinglocation:'inline',
		refNumber:'',
		dataSource: [],
		isFilterEnabled: false,
		reload: 1,
		currentIndex: 0,
		currentDataleg: { legs: '' },
		editIndex: true,
		oldPage: 1,
		markasrrived: 0,
		magdriverFilter: [],
		magdropterminal: [],
		foundsearch: false,
		metaobject: [],
		typeFilter: 0,
		sub_tab: [],
		selected_sub_tab: {},
		updatedData: 0,
		retabs: 1,
		currentStateClick: "",
		loopUnassigned: 0,
		loopAssign: 0,
		lenthDispatch: 0,
		alert: 1,
		filterAssign: ["All", "Assign", "Unassigned"],
		isDisabled: true,
		dialog: false,
		dialogData: [],
		isSubmitting: false,
		page: 1,
		pageCount: 0,
		search: "",
		activeTab: 0,
		currentSelectTab: "All",
		tabdata: [],
		tabClicked: false,
		responseChassis: null,
		showEditLegModal: false,
		editingLeg: null,
		editingShipment: null,
		showAddLegModal: false,
		lastLeg: null,
		newContainersData: [],
		oldContainersData: [],
		shouldLoad: true,
		loadingData: false,
		updatedPageCount: false,
		lastPage: 1,
		oldselectedTab: "",
		selectedActiveInactiveFilter: { label: 'All', value: 'all' },
		activeInactiveFilter: [{ label: 'All', value: 'all' }, { label: 'Only Actives', value: 'active' }, { label: 'Only Inactives', value: 'inactive' }],
		selectedCustomerFilter: { id: 0, company_name: 'Select' },
		isDatePickerOpen: false,
		selectedEtaRange: [],
		pickerLabel: '',
		nudgePos: "120%",
		// for data grid table
		editOnKeyPress: false,
		enterKeyActions: ['startEdit', 'moveFocus'],
		enterKeyDirections: ['none', 'column', 'row'],
		enterKeyDirection: 'none',
		enterKeyAction: 'moveFocus',
		tooltipRefName: 'tooltip',
		tooltipRefNameNoEdit: 'tooltipA',
		tooltipRefNameDisabled: 'tooltipDisabled',
		tooltipHeaders: 'tooltipHeaders',
		tooltipRefCopy: 'tooltipCopy',
		tooltipDateInfo: 'tooltipDateInfo',
		pendingClick: 0,
		isCellEditing: false,
		buttonItems: [
			{ icon: "check", name: "confirmButton" },
			{ icon: "close", name: "rejectButton" },
		],
		currentSelectedCell: null,
		subTabIndex: 0,
		loadPanelOptions: {
			text: 'Loading data...',
			showPane: true,
			shading: false,
			shadingColor: '#ffffff61',
			position: {
				my: 'top',
				at: 'top',
				of: '#data-grid-demo .dx-datagrid-content'
			},
			animation: 'disabled',
		},
		scrollingOptions: {
			mode: "virtual",
			useNative: true,
			scrollByContent: true,
			scrollByThumb: true,
			direction: "both",
			showScrollbar: "always",
			timeout: 500,
			minTimeout: 500,
		},
		editingOptions: {
			allowUpdating: true,
			startEditAction: 'dblClick',
			useIcons: true,
			mode: "cell",
			refreshMode: 'repaint'
		},
		typingTimeout: 0,
		isShowPagination: false,
		isUpdatingCells: false,
		isShowLoadingUI: false,
		loadedContainersData: {},
		currentTab: "",
		compactViewTabs: [
			{
				name: "All",
				count: 0,
				sub_tab: []
			},
			{
				name: "Scheduled",
				count: 0,
				sub_tab: [
					{
						label: "All",
						name: "All",
						count: 0,
					},
					{
						label: "Unassigned",
						name: "Unassigned",
						count: 0,
					},
					{
						label: "Assigned",
						name: "Assigned",
						count: 0,
					},
					{
						label: "In-Progress",
						name: "In-Progress",
						count: 0,
					},
				]
			},
			{
				name: "At Port",
				count: 0,
				sub_tab: [
					{
						label: "Pending",
						name: "Pending",
						count: 0,
					},
					{
						label: "Available",
						name: "available",
						count: 0,
					},
				]
			},
			{
				name: "At Yard",
				count: 0,
				sub_tab: [
					{
						label: "Full",
						name: "full",
						count: 0,
					},
					{
						label: "Empty",
						name: "empty",
						count: 0,
					},
				]
			},
			{
				name: "At the Customer",
				count: 0,
				sub_tab: [
					{
						label: "Full",
						name: "full",
						count: 0,
					},
					{
						label: "Empty",
						name: "empty",
						count: 0,
					},
				]
			},
			{
				name: "Pending billing",
				count: 0,
				sub_tab: []
			},
			{
				name: "Completed",
				count: 0,
				sub_tab: []
			},
			{
				name: "Cancelled",
				count: 0,
				sub_tab: []
			},
		],
		scheduledLastLegID: "",
		isLegUpdated: 0,
		containerEditedData: null,
		allowedOperations: [],
		selectedOperation: '',
		generalConsigneeLists: [],
		tooltipMessage: "",
		tooltipRefGlobal: 'tooltipRefGlobal',
		previousTab: "All"
	}),
	computed: {
		...mapGetters({
			getPoLoading: "containers/getPoLoading",
			gettabcounter: "containers/gettabcounter",
			getAllcontainer: "containers/getAllContainers",
			getAllcontainerStatus: "containers/getAllcontainersStatus",
			getTabLoading: "containers/getTabLoading",
			getCurrentSelectTab: "containers/getCurrentSelectedTab",
			getScheduledFilterState: "containers/getScheduledFilterState",
			getDrivers: "common/getDrivers",
			getChassis: "common/getChassis",
			getTerminals: "common/getTerminals",
			getCarriers: "containers/getAllCarriers",
			getAllConsignees: 'containers/getAllConsignees',
			getAllConsigneesLoading: 'containers/getAllConsigneesLoading',
			getUpdateIndexesLoading: 'containers/getUpdateIndexesLoading',
			getCustomers: 'customers/getCustomers',
		}),
		newData: {
			get() {
				return this.newContainersData;
			},
		},
		customerLists() {
			let data = []

			if (this.customers.length > 0)
				data = this.customers

			return data
		},
		driverLists() {
			let data = []

			if (this.magdriver.length > 0)
				data = this.magdriver

			return data
		},
		sizeLists() {
			let data = []

			if (this.shipmentsize !== null && this.shipmentsize.length > 0)
				data = this.shipmentsize

			return data
		},
		carrierLists() {
			let data = []

			if (typeof this.getCarriers !== 'undefined' && this.getCarriers !== null &&
				typeof this.getCarriers.data !== 'undefined' && this.getCarriers.data !== null) {
				data = this.getCarriers.data
			}

			return data
		},
		tabItems() {
			var s = this.compactViewTabs;

			if (typeof this.getAllcontainerStatus !== "undefined" &&
				this.getAllcontainerStatus !== null) {
				if (typeof this.getAllcontainerStatus.data !== "undefined" &&
					this.getAllcontainerStatus.data.length !== "undefined") {
					var dataTabs = Object.values(this.getAllcontainerStatus.data);

					if (dataTabs) {
						this.compactViewTabs.map((element) => {
							if (element.name !== "") {
								let thename = "";

								if (element.name == "All") {
									thename = "all_tab";
								} else if (element.name == "At Port") {
									thename = "Pending,available";
								} else if (element.name == "Yard full") {
									thename = "in_yard_full";
								} else if (element.name == "At Yard") {
									thename = "at_yard";
								} else if (element.name == "Available") {
									thename = "available";
								} else if (element.name == "Assigned") {
									thename = "assigned";
								} else if (element.name == "Scheduled") {
									thename = "assigned";
								} else if (element.name == "At the Customer") {
									thename = "at_the_customer";
								} else if (element.name == "Completed") {
									thename = "completed";
								} else if (element.name == "Cancelled") {
									thename = "cancelled";
								} else {
									var c = element.name.toLowerCase();
									thename = c.charAt(0).toUpperCase() + c.slice(1);
								}
								const result = dataTabs.filter((find) => thename.split(",").includes(find.name));
								element.count = 0;

								Object.values(result).forEach(function (f) {
									if (["at_the_customer", "at_yard"].includes(f.name)) {
										element.sub_tab.map(v => {
											if (v.name === "full") {
												v.count = f.full.count
											} else {
												v.count = f.empty.count
											}
										})
									} else
										element.sub_tab.forEach(function (s_tab) {
											if (s_tab.name.toLowerCase() == f.name.toLowerCase()) {
												s_tab.count = parseInt(f.count);
											}
										});
									element.count += parseInt(f.count);
								});

								if (element.name == "Assigned") {
									element.name = "Scheduled";
								}
							}
						});
						s = this.compactViewTabs;
					}
				}
			}
			return s;
		},
		isShowMenuTab() {
			let show = true

			let fieldsShow = ['All', 'Pending billing', 'Completed', 'Cancelled']
			let shouldFieldShow = _.find(fieldsShow, c => c === this.currentSelectTab)

			if (shouldFieldShow !== undefined) {
				show = true
			} else {
				show = false
			}

			return show
		},
		isShowETA() {
			let show = true
			let showTabs = ['At Yard', 'At the Customer', "Scheduled"]
			let findShowTab = _.find(showTabs, c => c === this.currentSelectTab)

			if (findShowTab !== undefined) {
				show = false
			} else {
				show = true
			}

			return show
		},
		isShowScheduledColumn() {
			let show = true
			let showTabs = ['At Port', 'At Yard', 'At the Customer']
			let findShowTab = _.find(showTabs, c => c === this.currentSelectTab)

			if (findShowTab !== undefined) {
				show = true

				if (findShowTab === 'At Port' && this.selected_sub_tab.name !== 'available') {
					show = false
				}
			} else {
				show = false
			}

			return show
		},
		isShowLfdp() {
			let show = true
			let showTabs = ['All', 'At Port', "Pending billing", "Completed"]
			let findShowTab = _.find(showTabs, c => c === this.currentSelectTab)

			if (findShowTab !== undefined) {
				show = true
			} else {
				show = false
			}

			return show
		},
		isShowLfdr() {
			let show = true
			let showTabs = ['All', 'At Yard', "At the Customer", "Pending billing", "Completed"]
			let findShowTab = _.find(showTabs, c => c === this.currentSelectTab)

			if (findShowTab !== undefined) {
				show = true
			} else {
				show = false
			}

			return show
		},
		isTabLFDCaption() { // if these are the current tabs, show LFD R as caption
			let show = true
			let showTabs = ['All', "Pending billing", "Completed", "Cancelled"]
			let findShowTab = _.find(showTabs, c => c === this.currentSelectTab)

			if (findShowTab !== undefined) {
				show = true
			} else {
				show = false
			}

			return show
		},
		// edit leg
		getEditingShipmentEta: {
			get() {
				if (this.editingShipment && this.editingShipment.eta) {
					return moment(this.editingShipment.eta).utc(false).format("YYYY-MM-DD");
				}
				return null;
			},
		},
		editingLegPickupLocation() {
			if (this.editingLeg != null && this.editingShipment != null) {
				let legIndex = this.currentEditingLegIndex;

				try {
					if (legIndex == 0) {
						// CHECK IF SHIPMENT STARTING LOCATION IS CUSTOM ADDRESS 
						// THEN SHOW THE starting_location_address
						if( this.editingShipment.is_custom_starting_location) {
              // starting_location_address
              return this.editingShipment.starting_location_address;
            } else {
							return this.getTerminals.find((t) => t.id == this.editingShipment.terminal_id).name;
						}
					}
				} catch (err) {
					return "";
				}

				if (this.editingShipment.dispatch_schedule != "") {
					let dispatch_schedule = null;

					try {
						dispatch_schedule = JSON.parse(this.editingShipment.dispatch_schedule);
					} catch (err) {
						console.log(err);
					}

					if (dispatch_schedule) {
						let legs = dispatch_schedule.legs || {};

						if (legs.length > 0) {
							let address = legs[0].address;
							for (let i = 1; i < legs.length; i++) {
								if (legs[i].id == this.editingLeg.id) {
									return address;
								}
								address = legs[i].address;
							}
						}
					}
				}
			}
			return "";
		},
		currentEditingLegIndex() {
			if (this.editingLeg != null && this.editingShipment != null) {
				if (this.editingShipment.dispatch_schedule != "") {
					let dispatch_schedule = null;

					try {
						dispatch_schedule = JSON.parse(this.editingShipment.dispatch_schedule);
					} catch (err) {
						console.log(err);
					}
					if (dispatch_schedule) {
						let legs = dispatch_schedule.legs || {};
						if (legs.length > 0) {
							for (let i = 0; i < legs.length; i++) {
								if (legs[i].id == this.editingLeg.id) {
									return i;
								}
							}
						}
					}
				}
			}
			return 0;
		},
		getPickupLocation() {
			if (this.editingShipment != null) {
				try {
					if (this.editingShipment.dispatch_schedule) {
						let dispatch_schedule = JSON.parse(this.editingShipment.dispatch_schedule);

						if (dispatch_schedule && typeof dispatch_schedule.legs != "undefined" &&
							dispatch_schedule.legs.length > 0)
							return dispatch_schedule.legs[dispatch_schedule.legs.length - 1].address;
					}

					if (this.editingShipment.terminal_id)
						return this.getTerminals.find((t) => t.id == this.editingShipment.terminal_id).name;
				} catch (err) {
					console.log(err);
				}
			}
			return "";
		},
	},
	methods: {
		...mapActions({
			fetchavailable: "containers/fetchAvailable",
			fetchyardfull: "containers/yardfull",
			fetchassigned: "containers/assigned",
			fetchdroppedfull: "containers/droppedfull",
			fetchdroppedempty: "containers/droppedEmpty",
			fetchpendingbilling: "containers/pendingBilling",
			fetchcompleted: "containers/completed",
			storeEditData: "containers/passData",
			storeCurrentTabSelected: "containers/currentTabContainer",
			setFilter: "containers/setScheduleFilterState",
			setContainerEmptyStatus: "containers/setContainerEmptyStatus",
			fetchConsignees: 'containers/fetchConsignees',
			updateTableIndexes: 'containers/updateTableIndexes',
			createConsignee: 'containers/createConsignee',
			fetchCustomers: 'customers/fetchCustomers',
			fetchAllConsignees: 'containers/fetchAllConsignees'
		}),
		...globalMethods,
		...compactViewMethods,
		calculateFilterExpressionContainer,
		calculateFilterExpressionMBL,
		calculateFilterExpressionSize,
		calculateFilterExpressionCustomer,
		calculateFilterExpressionCarrier,
		calculateFilterExpressionDO,
		calculateFilterExpressionRef,
		calculateFilterExpressionConsignee,
		calculateFilterExpressionEta,
		calculateFilterExpressionLfd,
		calculateFilterExpressionPdd,
		calculateFilterExpressionType,
		calculateFilterExpressionLocation,
		calculateFilterExpressionDispatchDriver,
		calculateFilterExpressionDispatchMode,
		calculateFilterExpressionDispatchDeliveryType,
		calculateFilterExpressionPickupTime,
		calculateFilterExpressionDeliveryTime,
		calculateFilterExpressionDeliveryOn,
		calculateFilterExpressionDispatchStatus,
		calculateFilterExpressionTabStatus,
		calculateFilterExpressionOpened,
		calculateFilterExpressionClosed,
		calculateFilterExpressionArrived,
		calculateFilterExpressionPickedUp,
		calculateFilterExpressionDelivered,
		calculateFilterExpressionEmptyReturned,
		calculateFilterExpressionPrepull,
		activateFilter() {
			this.isFilterEnabled = !this.isFilterEnabled
			let dataGridTable = this.$refs.dataGridReference.instance
			dataGridTable.clearFilter();
		},
		async getCurrentTab(id, i, tab) {
			this.page = 1
			this.search = ""
			this.isUpdatingCells = false
			this.previousTab = this.currentSelectTab;

			if (this.currentSelectTab !== id) {
				if (cancel !== undefined) cancel("cancel_previous_request")

				this.sub_tab = tab.sub_tab !== undefined ? tab.sub_tab : [];
				if (tab.sub_tab.length > 0) this.selected_sub_tab = tab.sub_tab[0];
				if (!this.getTabLoading) {
					if (["Cancelled", "Completed"].includes(id)) {
						this.selected_sub_tab = {};
					}
					this.currentSelectTab = id;
					this.tabClicked = true;
					this.storeCurrentTabSelected({
						id: id,
						activeTab: i,
						sub_tab: this.selected_sub_tab
					});
				}
				this.subTabIndex = 0
				setTimeout(() => {
					this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, this.page);
				}, 1)
			}
			let dataGridTable = this.$refs.dataGridReference.instance
			dataGridTable.clearFilter();
		},
		checkIfinvalid(string) {
			if (string.search("Invalid") == -1) { // not found
				return 0;
			}
			return 1;
		},
		checkifValid_then_return(string) {
			if (string.search("Invalid") == -1) { // not found
				return string;
			}
			return '0000-00-00';
		},
		async getCurrentSubTab(subtab, i) {
			this.search = ""
			this.isUpdatingCells = false
			this.page = 1

			if (this.selected_sub_tab !== subtab) {
				if (cancel !== undefined) cancel("cancel_previous_request")

				let current_tab_item = this.getCurrentSelectTab
				this.subTabIndex = i
				this.selected_sub_tab = subtab

				this.storeCurrentTabSelected({
					id: current_tab_item.id,
					activeTab: current_tab_item.activeTab,
					sub_tab: subtab,
					subTabIndex: i
				});
				setTimeout(() => {
					this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, this.page);
				}, 1)
			}
			let dataGridTable = this.$refs.dataGridReference.instance
			dataGridTable.clearFilter();
		},
		/** Alex added */
		detectchangesReloadchanges() {
			delete this.loadedContainersData[this.currentTab];
			delete this.loadedContainersData['assigned-schedule'];
			delete this.loadedContainersData['unassigned-schedule'];
			delete this.loadedContainersData['all-scheduled'];
			delete this.loadedContainersData['all'];
		},
		getDateFormat(date) {
			return getDateFormatFunction(date)
		},
		// check to time
		checkTotime(data) {
			if (typeof data != "undefined") {
				return timeConvertFunction(data)
			} else {
				return "";
			}
		},
		// time convert
		timeConvert(dateString) {
			return timeConvertFunction(dateString)
		},
		convertDefaultTime(time) {
			if (time !== '' && time !== null) {
				return moment(time, ["HH:mm"]).format("hh:mm A")
			} else {
				return ''
			}
		},
		selectedTabData(tab, subTab = this.selected_sub_tab, page) {
			// without loading 
			// set loading if search is not empty & not updating details in compact view
			if (this.search !== "" && !this.isUpdatingCells) {
				this.isShowLoadingUI = true
			}

			if (tab == "All") {
				if (typeof this.loadedContainersData["all"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
					this.getLoadedData("all", page)
				} else {
					this.getSelectedTabData("all", page, true); // added true param to load the page, if false, don't show loading
				}
			} else if (tab == "At Port") {
				if (subTab.label == "Pending") {
					if (typeof this.loadedContainersData["at-port-pending"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
						this.getLoadedData("at-port-pending", page)
					} else {
						this.getSelectedTabData("at-port-pending", page, true);
					}
				} else {
					if (typeof this.loadedContainersData["at-port-available"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
						this.getLoadedData("at-port-available", page)
					} else {
						this.getSelectedTabData("at-port-available", page, true);
					}
				}
			} else if (tab == "At Yard") {
				if (subTab.name == "full") {
					if (typeof this.loadedContainersData["at-yard-full"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
						this.getLoadedData("at-yard-full", page)
					} else {
						this.getSelectedTabData("at-yard-full", page, true);
					}
				} else {
					if (typeof this.loadedContainersData["at-yard-empty"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
						this.getLoadedData("at-yard-empty", page)
					} else {
						if (this.search !== "") {
							this.isShowLoadingUI = true
						}
						this.getSelectedTabData("at-yard-empty", page, true);
					}
				}
			} else if (tab == "At the Customer") {
				if (subTab.name == "full") {
					if (typeof this.loadedContainersData["at-customer-full"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
						this.getLoadedData("at-customer-full", page)
					} else {
						this.getSelectedTabData("at-customer-full", page, true);
					}
				} else {
					if (typeof this.loadedContainersData["at-customer-empty"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
						this.getLoadedData("at-customer-empty", page)
					} else {
						this.getSelectedTabData("at-customer-empty", page, true);
					}
				}
			} else if (tab == "Pending billing") {
				if (typeof this.loadedContainersData["pending-billing"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
					this.getLoadedData("pending-billing", page)
				} else {
					this.getSelectedTabData("pending-billing", page, true);
				}
			} else if (tab == "Completed") {
				if (typeof this.loadedContainersData["completed"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
					this.getLoadedData("completed", page)
				} else {
					this.getSelectedTabData("completed", page, true);
				}
			} else if (tab == "Cancelled") {
				if (typeof this.loadedContainersData["cancelled"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
					this.getLoadedData("cancelled", page)
				} else {
					this.getSelectedTabData("cancelled", page, true);
				}
			} else if (tab == "Scheduled") {
				if (subTab == "Assigned" || subTab.name == "Assigned") {
					if (typeof this.loadedContainersData["assigned-schedule"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
						this.getLoadedData("assigned-schedule", page)
					} else {
						this.getSelectedTabData("assigned-schedule", page, true);
					}
				} else if (subTab == "Unassigned" || subTab.name == "Unassigned") {
					if (typeof this.loadedContainersData["unassigned-schedule"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
						this.getLoadedData("unassigned-schedule", page)
					} else {
						this.getSelectedTabData("unassigned-schedule", page, true);
					}
				} else if (subTab == "In-Progress" || subTab.name == "In-Progress") {
					if (typeof this.loadedContainersData["in-transit-schedule"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
						this.getLoadedData("in-transit-schedule", page)
					} else {
						this.getSelectedTabData("in-transit-schedule", page, true);
					}
				} else {
					if (typeof this.loadedContainersData["all-scheduled"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
						this.getLoadedData("all-scheduled", page)
					} else {
						this.getSelectedTabData("all-scheduled", page, true);
					}
				}
			}
		},
		async getSelectedTabData(tab, page, loading) { // call API for each tabs
			this.oldContainersData = this.newData;
			this.shouldLoad = loading
			this.oldPage = this.page
			this.currentTab = tab

			let c_id = this.selectedCustomerFilter.id;
			let status = this.selectedActiveInactiveFilter.value;
			let etaStart = this.selectedEtaRange.length > 0 ? this.selectedEtaRange[0] : '';
			let etaEnd = this.selectedEtaRange.length > 0 ? this.selectedEtaRange[1] : '';
			let search = this.search;

			let url = `${poBaseUrl}/shipment-tabs/${tab}?
				customer_id=${c_id}&status=${status}&
				etaStart=${etaStart}&etaEnd=${etaEnd}&
				search=${search}`

			this.dataSource = GetStore(url, this, tab)
			this.shouldLoad = false;
			this.isDisabled = false;
			this.isUpdatingCells = false;
			this.isLegUpdated = 0
		},
		getLoadedData(tab, page) {
			if (!this.loadedContainersData[tab].page.includes(page)) {
				this.getSelectedTabData(tab, page, true)
			} else {
				this.currentTab = tab
				this.page = page
				this.newContainersData = this.loadedContainersData[tab].data[this.getLoadedDataPage()]
				this.dataSource = this.loadedContainersData[tab].data[this.getLoadedDataPage()]
				this.lastPage = this.loadedContainersData[tab].lastPage
				this.pageCount = this.loadedContainersData[tab].pageCount
				this.getSelectedTabData(tab, page, false)

				setTimeout(() => {
					this.isDisabled = false
				}, 100)
			}
		},
		getLoadedDataPage() {
			let index = this.loadedContainersData[this.currentTab].page.findIndex((p) => {
				return p === this.page
			})

			return index
		},
		goToDetailsPage(e) {
			localStorage.setItem("queryData", this.currentSelectTab);
			this.storeEditData({
				items: e.data,
				objectMeta: this.metaobject,
				files: this.mgaFiles,
				drivers: this.magdriver,
				terminals: this.terminals,
				customers: this.customers,
				shipmentsize: this.shipmentsize,
				currentState: this.currentSelectTab,
			});
			this.$router.push("/container/edit/" + e.data.id).catch(() => { });
		},
		// data grid methods
		onContextMenuPreparing(e) {
			return onContextMenuPreparingFunction(e, this)
		},
		onFocusedCellChanging(e) {
			return onFocusedCellChangingFunction(e, this)
		},
		onCellPrepared(e) {
			return onCellPreparedFunction(e, this)
		},
		onCellHoverChanged(e) { // when hovering cell method
			return onCellHoverChangedFunction(e, this)
		},
		onEditingStart(e) {
			e.component.isEditing = true
			this.isCellEditing = true
		},
		onContentReady: function (e) {
			return onContentReadyFunction(e, this)
		},
		onEditorPreparing: function (e) {
			return onEditorPreparingFunction(e, this)
		},
		async onRowUpdating(e) { // do the update here - call update addPickup
			return onRowUpdatingFunction(e, this)
		},
		editorPrepared: (e) => {
			return editorPreparedFunction(e, this)
		},
		validationCallBackMethodContainer(e) {
			return validationErrorsFunction(e, "Container")
		},
		validationCallBackMethodEta(e) {
			return validationErrorsFunction(e, "ETA")
		},
		validationCallBackMethodLfd(e) {
			return validationErrorsFunction(e, "LFD")
		},
		validationCallBackMethodPdd(e) {
			return validationErrorsFunction(e, "PDD")
		},
		validationCallBackScheduled(e) {
			return validationErrorsFunction(e, "Scheduled")
		},
		updateMilestone(item) {
			let fieldindex = '';
			switch (item.field) {
				case "discharged":
					fieldindex = 'discharged'
					break
				case "pier_pass_paid":
					fieldindex = 'pier_pass_paid'
					break
				case "fully_released":
					fieldindex = 'fully_released';
					break;
			}

			axios.post(`${poBaseUrl}/milestones-update/${item.id}`, {
				id: item.id,
				value: item.value,
				field: item.field,
			})
				.then(async (res) => {
					if (res.status == 200) {
						this.notificationMessageCustomSuccess(`${item.displayName} has been updated successfully.`)
						let data = res.data.data

						let temp = this.newContainersData;
						const currentIndex = _.findIndex(this.newContainersData, (c) => c.id === item.id);

						// discharged
						// pier_pass_paid
						if (fieldindex != '') {
							temp[currentIndex][fieldindex] = item.value
							this.newContainersData.splice(currentIndex, 1, temp[currentIndex])
							this.loadedContainersData[this.currentTab].data[this.getLoadedDataPage()] = temp
						}

						if ((data.discharge || data.discharge === 1) &&
							(data.fully_released || data.fully_released === 1) &&
							(data.peir_pass_paid || data.peir_pass_paid === 1)) {
							const previousSecondElementOfTheArray = this.newContainersData.splice(currentIndex, 1);
							this.loadedContainersData[this.currentTab].data[this.getLoadedDataPage()] = previousSecondElementOfTheArray

							this.detectchangesReloadchanges();
							//await this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1)
							this.$emit("reinitializeTable");
						}
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		getDataValue(e) {
			return this.displayDataValueMethod(e)
		},
		copyToClipboard(data, isFor) {
			this.copyToClipboardMethod(data, isFor)
		},
		async addNewConsigneeItem(data) { // creating consignee in column consignee cell dropdown
			let lookupText = document.getElementsByClassName("dx-texteditor-input")[0].value

			if (data !== null) {
				if (lookupText === '') {
					this.notificationCustom('Please input consignee name')
				} else {
					if (lookupText !== 'N/A') {
						let findConsigneeValue = _.find(currentConsignees, e => e.name === lookupText)

						if (findConsigneeValue !== undefined) {
							let message = `"${lookupText}" already exists. Enter a different Consignee Name`
							this.notificationCustom(message)
						} else {
							let payload = {
								name: lookupText,
								customer_id: data.row.data.CustomerID
							}
							let toastData = {
								message: 'Creating Consignee...',
								timeout: 2000,
								class: 'creating-consignee'
							}
							this.notificationCustomAlert(toastData)

							await this.createConsignee(payload).then(async (res) => {
								if (res) {
									// hide creating consignee toast once creating is successful
									var toast = document.querySelector('.iziToast.creating-consignee');
									iziToast.hide({}, toast);

									let response = res.data
									this.notificationMessageCustomSuccess(res.message)

									// assign new consignee id
									data.row.data.NewConsigneeID = response.id
									// set cell value
									data.component.cellValue(data.row.rowIndex, "ConsigneeName", response.name);
									this.shouldLoad = false
									this.isShowLoadingUI = false
								}
							})
						}
					} else {
						this.notificationCustom('Please input consignee name')
					}
				}
			}
		},
		async addNewCustomer(data) {
			let lookupText = document.getElementsByClassName("dx-texteditor-input")[0].value

			if (data !== null) {
				if (lookupText === '') {
					this.notificationCustom('Please input customer name')
				} else {
					// console.log(lookupText, this.customerLists);

					if (lookupText !== 'N/A') {
						let findCustomer = _.find(this.customerLists, e => e.company_name === lookupText)

						if (findCustomer !== undefined) {
							let message = `"${lookupText}" already exists. Enter a different Customer Name`
							this.notificationCustom(message)
						} else {
							let formData = new FormData()
							formData.append('name', lookupText)
							formData.append('central_customer_key', "")
							formData.append('email', "");
							formData.append('pnumber', "")

							let toastData = {
								message: 'Creating Customer...',
								timeout: 2000,
								class: 'creating-consignee'
							}
							this.notificationCustomAlert(toastData)

							await axios.post(`${poBaseUrl}/createCustomer`, formData)
								.then((response) => {
									return response.data
								}).catch(error => {
									if (typeof error.type != undefined) this.typeError = error.type
									if (typeof error.error != undefined) {
										this.errorContent = error.msg
									}
								}).then(response => {
									if (response) {
										var toast = document.querySelector('.iziToast.creating-consignee');
										iziToast.hide({}, toast);

										this.notificationMessageCustomSuccess("Customer has been added.")
										data.row.data.NewCustomerId = response.customer_id
										data.component.cellValue(data.row.rowIndex, "CustomerID", lookupText);
										this.$emit("reinitializeTable");
										this.shouldLoad = false
										this.isShowLoadingUI = false
										// this.fetchCustomers()
									}
								})
						}
					} else {
						this.notificationCustom('Please input customer name')
					}
				}
			}
		},
		// checking values
		isAnyLegAvailable(item) {
			let { row } = item
			let { data } = row

			if (data.dispatch_schedule != "" && data.dispatch_schedule != null) {
				let dispatch_schedule = null;
				try {
					dispatch_schedule = JSON.parse(data.dispatch_schedule);
				} catch (err) {
					console.log(err);
				}

				if (dispatch_schedule) {
					let legs = dispatch_schedule.legs || [];
					let len = 0;

					try {
						len = legs.length;
					} catch (err) {
						console.log(err);
					}
					if (len > 0) {
						return legs.find(leg => !leg.completed)
					}
				}
			}
			return false;
		},
		getTerminalData(id, type) {
			if (id) {
				let terminal = this.getTerminals.find(t => t.id == id)
				if (terminal) {
					if (type === 'name') {
						return terminal.name
					} else if (type === 'nickname') {
						return terminal.nickname !== null ? terminal.nickname : '--'
					} else if (type === 'address') {
						return terminal.address
					} else {
						return terminal.firms_code
					}
				}
			}
			return ""
		},
		getLastArrivedLeg(item) {
			let dispatch_schedule = null;
			try {
				dispatch_schedule = JSON.parse(item.dispatch_schedule);
			} catch (err) {
				dispatch_schedule = null;
			}

			let legs = dispatch_schedule ? dispatch_schedule.legs || [] : [];

			if (legs.length > 0) {
				for (let i = legs.length - 1; i >= 0; i--) {
					if (legs[i].completed) {
						return legs[i];
					}
				}
			}

			return null;
		},
		getFirstUnArrivedLeg(item) {
			let dispatch_schedule = null;
			try {
				dispatch_schedule = JSON.parse(item.dispatch_schedule);
			} catch (err) {
				dispatch_schedule = null;
			}

			let legs = dispatch_schedule ? dispatch_schedule.legs || [] : [];

			if (legs.length > 0) {
				for (let i = 0; i < legs.length; i++) {
					if (!legs[i].completed) {
						return legs[i];
					}
				}
			}
			return null;
		},
		getATCTimeArrived(item) {
			let scheds = null;
			try {
				scheds = JSON.parse(item.dispatch_schedule);
			} catch (err) {
				scheds = null;
			}

			var time = scheds !== null ? scheds.legs[scheds.legs.length - 1]?.arrival_at_delivery_location_time : null;
			var date = scheds !== null ? scheds.legs[scheds.legs.length - 1]?.arrival_at_delivery_location_date : null;

			let newTime = ''
			let newDate = ''
			let finalDisplay = ''

			// check time
			if (time !== undefined && time !== "") {
				var bd = moment().format("YYYY-MM-DD");
				var checkdate = moment(bd + " " + time).utc(true).isValid();
				newTime = checkdate ? moment(bd + " " + time).format("hh:mm A") : "";
			} else {
				newTime = ""
			}

			// check date
			if (date !== undefined && date !== "") {
				if (this.checkifValid(date, "MM/DD/YY")) {
					newDate = moment(date).utc(true).format("MM/DD/YY");
				} else {
					newDate = ""
				}
			} else {
				newDate = ""
			}

			// check for final display
			if (newDate !== '') {
				if (newTime === '')
					finalDisplay = newDate
				else
					finalDisplay = newTime + ' ' + newDate
			} else {
				finalDisplay = ''
			}

			return finalDisplay
		},
		getTimeArrived(item) {
			let last_arrived_leg = this.getLastArrivedLeg(item);
			let time = "";
			let date = "";
			let finalDisplay = ""

			if (last_arrived_leg) {
				time = last_arrived_leg.arrival_at_delivery_location_time
				date = last_arrived_leg.arrival_at_delivery_location_date

				let newDate = this.getDateFormat(date)
				let newTime = this.convertDefaultTime(time)

				if (newDate !== '') {
					if (newTime === '')
						finalDisplay = newDate
					else
						finalDisplay = newTime + ' ' + newDate
				} else {
					finalDisplay = ''
				}
			}

			return finalDisplay
		},
		getEmptyNotified(data) {
			let { container_empty, container_empty_date, container_empty_time } = data
			container_empty = container_empty === 1 ? true : false

			let date = ''
			let time = ''
			let finalDisplay = null

			if (container_empty) {
				date = container_empty_date !== null ? this.getDateFormat(container_empty_date) : ''
				time = container_empty_time !== null ? moment(container_empty_time, ["HH:mm"]).format("hh:mm A") : ''
				// time = time !== null || time !== '' ? this.checkTotime(container_empty_time) : '' - utc ?

				if (date !== '') {
					if (time !== '' || time !== null) {
						finalDisplay = date + ' ' + time
					} else {
						finalDisplay = date
					}
				} else {
					finalDisplay = null
				}
			}

			return finalDisplay
		},
		getDefaultScheduledDate(item) { // getScheduledDate
			let dispatch_schedule = null;

			try {
				dispatch_schedule = JSON.parse(item.dispatch_schedule);
			} catch (err) {
				dispatch_schedule = null;
			}

			let legs = dispatch_schedule ? dispatch_schedule.legs || [] : [];
			let not_scheduled = null;
			let finalDisplay = null

			if (["Available"].includes(this.currentSelectTab) ||
				["Available"].includes(this.selected_sub_tab.label)) {
				if (legs.length > 0 && legs[0] !== undefined) {
					let date = legs.length > 0 && (legs[0].from_date !== null || legs[0].from_date !== "") ? this.getDateFormat(legs[0].from_date) : ''
					let time = legs.length > 0 && (legs[0].to_time !== null || legs[0].to_time !== "") ? moment(legs[0].to_time, ["HH:mm"]).format("hh:mm A") : ''

					if (date !== '') {
						if (time !== '' || time !== null) {
							finalDisplay = date + ' ' + time
						} else {
							finalDisplay = date
						}
					} else {
						finalDisplay = null
					}
				}

				return finalDisplay
			} else if (
				["Dropped empty", "Yard full", "At Yard"].includes(this.currentSelectTab) ||
				["Dropped empty", "Yard full", "At Yard"].includes(this.selected_sub_tab.label)) {

				let first_unarrived_leg = this.getFirstUnArrivedLeg(item);

				if (!(first_unarrived_leg && first_unarrived_leg.from_date)) {
					return not_scheduled;
				}
				if (["Yard full", "At Yard"].includes(this.currentSelectTab) ||
					["Yard full", "At Yard"].includes(this.selected_sub_tab.label)) {

					let date = this.getDateFormat(first_unarrived_leg.from_date);
					let time = moment(first_unarrived_leg.to_time, ["HH:mm"]).format("hh:mm A");
					// let time = this.checkTotime(first_unarrived_leg.to_time);

					if (date !== '') {
						if (time !== '' || time !== null) {
							finalDisplay = date + ' ' + time
						} else {
							finalDisplay = date
						}
					} else {
						finalDisplay = null
					}

					return finalDisplay
				}
				return this.getDateFormat(first_unarrived_leg.from_date);
			}
		},
		getAtTheCustomerScheduledDate(item) { // getScheduleDate
			let scheds = null;

			try {
				scheds = JSON.parse(item.dispatch_schedule);
			} catch (err) {
				scheds = null;
			}

			var date = scheds !== null ? scheds.legs[scheds.legs.length - 1]?.from_date : null;
			var time = scheds !== null ? scheds.legs[scheds.legs.length - 1]?.to_time : null;
			var is_arrived = scheds !== null ? scheds.legs[scheds.legs.length - 1]?.completed : null;
			let finalDisplay = null

			// Return blank if the last leg is arrived, there should be another schedule leg that has not yet arrived
			if (this.checkifValid(date, "MM/DD/YY") && !is_arrived) {
				let newDate = date !== null ? this.getDateFormat(date) : ''
				let newTime = time !== null ? moment(time, ["HH:mm"]).format("hh:mm A") : ''

				if (newDate !== '') {
					if (newTime !== '' || newTime !== null) {
						finalDisplay = newDate + ' ' + newTime
					} else {
						finalDisplay = newDate
					}
				} else {
					finalDisplay = null
				}
			} else
				return finalDisplay;
		},
		checkifValid(string, format) {
			return moment(moment(string).format(format), format, true).isValid();
		},
		// for legs
		addFirstLeg(item) {
			let { row } = item; let { data } = row;

			this.editingShipment = data;
			this.showAddLegModal = true;

			try {
				let ds = JSON.parse(this.editingShipment.dispatch_schedule);
				if (typeof ds.legs != "undefined" && ds.legs && ds.legs.length > 0)
					this.lastLeg = ds.legs[ds.legs.length - 1];
				else this.lastLeg = null;
			} catch (err) {
				console.log(err);
				this.lastLeg = null;
			}
			this.$emit("retrieveContainerDetails", data);
		},
		markAsArrivedFirstLeg(item) {
			this.markasrrived = 1
			this.editFirstLeg(item)
		},
		editFirstLeg(item) {
			let { row } = item; let { data } = row;
			this.refNumber = data.shifl_ref;
			if (data.dispatch_schedule != "" && data.dispatch_schedule != null) {
				let dispatch_schedule = null;
				try {
					dispatch_schedule = JSON.parse(data.dispatch_schedule);
				} catch (err) {
					console.log(err);
				}

				if (dispatch_schedule) {
					let legs = dispatch_schedule.legs || [];
					let len = 0, leg = null; // let leg = null;
					try {
						len = legs.length;
						if (len > 0) {
							leg = legs.find(leg => !leg.completed)
						}
					} catch (err) {
						console.log(err);
					}
					if (len > 0 && leg) {
						this.editLeg(data, leg);
					}
				}
			}
		},
		editLeg(item, leg) {
			const indexResult = _.findIndex(this.newContainersData, (c) => c.id === item.id);
			let dispatch_schedule = JSON.parse(item.dispatch_schedule);
			const result = _.findIndex(dispatch_schedule.legs, (c) => c.id === leg.id);

			if (result != -1) {
				this.currentIndex = indexResult;
				this.currentLegID = leg.id;
			}
			this.currentDataleg.legs = item.dispatch_schedule;
			this.$emit("retrieveContainerDetails", item);
			this.editingLeg = leg;
			this.editingShipment = item;
			this.showEditLegModal = true;
		},
		makegenerate() {
			return new Date().valueOf();
		},
		saveEditedLeg(leg) {
			// Alex fix mismatch date
			let dateFromISOString = null;
			let dateToISOString = null;
			let arrivalDateTime_dateToISOString = null;
			let departureDateTime_dateToISOString = null;
			let arrivalDLD_dateToISOString = null;
			let departure_ffrom_d_l_d_ToISOString = null;
			let dateFromField = null;

			// schedule pickup
			if (leg.pickup_scheduled_date != null) {
				var dateFrom = moment(leg.pickup_scheduled_date).format("YYYY-MM-DD");
				if (dateFrom != null && dateFrom != "Invalid date") {
					dateFromISOString = dateFrom + " " + this.checkifValid_then_return(leg.pickup_scheduled_time);
				}
			}

			// scheduled delivery
			if (leg.delivery_scheduled_date != null) {
				var dateTo = moment(leg.delivery_scheduled_date).format("YYYY-MM-DD");
				if (dateTo != null && dateTo != "Invalid date") {
					dateToISOString = dateTo + " " + this.checkifValid_then_return(leg.delivery_scheduled_time);
				}
			}

			// schedule arrival_at_pickup_location_date
			if (leg.arrival_at_pickup_location_date != null &&
				leg.arrival_at_pickup_location_date != "") {
				var arrivalDatePickup = moment(leg.arrival_at_pickup_location_date).format("YYYY-MM-DD");
				if (arrivalDatePickup != null && arrivalDatePickup != "Invalid date") {
					arrivalDateTime_dateToISOString =
						arrivalDatePickup + " " + this.checkifValid_then_return(leg.arrival_at_pickup_location_time);
				}
			}

			// departure_from_pickup_location_date
			if (leg.departure_from_pickup_location_date != null) {
				dateFromField = moment(leg.departure_from_pickup_location_date).format("YYYY-MM-DD");
				if (dateFromField != null && dateFromField != "Invalid date") {
					departureDateTime_dateToISOString =
						dateFromField + " " + this.checkifValid_then_return(leg.departure_from_pickup_location_time);
				}
			}

			// arrival_at_delivery_location_date
			if (leg.arrival_at_delivery_location_date != null) {
				dateFromField = moment(leg.arrival_at_delivery_location_date).format("YYYY-MM-DD");
				if (dateFromField != null && dateFromField != "Invalid date") {
					arrivalDLD_dateToISOString =
						dateFromField + " " + this.checkifValid_then_return(leg.arrival_at_delivery_location_time);
				}
			}

			// departure_from_delivery_location_date
			if (leg.departure_from_delivery_location_date != null) {
				dateFromField = moment(leg.departure_from_delivery_location_date).format("YYYY-MM-DD");
				if (dateFromField != null && dateFromField != "Invalid date") {
					departure_ffrom_d_l_d_ToISOString =
						dateFromField + " " + this.checkifValid_then_return(leg.departure_from_delivery_location_time);
				}
			}

			let ob = {
				id: leg.id,
				schedule_id: this.editingLeg.schedule_id,
				driver_status: leg.driverstatus,
				type: leg.selectedType,
				address: leg.address,
				// date: leg.delivery_scheduled_date,
				date: dateToISOString,
				time: leg.delivery_scheduled_time,
				driver: leg.selectedDriver ? leg.selectedDriver : "",
				chassis: leg.chassis ? leg.chassis : "",
				mode: leg.selectedMode == 1 ? "Dropped" : "Live",
				completed:
					leg.arrival_at_delivery_location_date !== "Invalid date" &&
						leg.arrival_at_delivery_location_date !== null &&
						leg.arrival_at_delivery_location_date !== ""
						? true
						: false,
				// comment by alex wrong date from_date: leg.pickup_scheduled_date,
				from_date: dateFromISOString,
				to_time: leg.pickup_scheduled_time,
				driver_instructions: leg.driver_instructions,
				//arrival_at_pickup_location_date:leg.arrival_at_pickup_location_date || "",
				arrival_at_pickup_location_date: arrivalDateTime_dateToISOString || "",
				arrival_at_pickup_location_time:
					leg.arrival_at_pickup_location_time != "Invalid date"
						? leg.arrival_at_pickup_location_time
						: "",

				// departure_from_pickup_location_date: departure_from_pickup_location_date || "",
				departure_from_pickup_location_date:
					departureDateTime_dateToISOString || "",
				departure_from_pickup_location_time:
					leg.departure_from_pickup_location_time != "Invalid date"
						? leg.departure_from_pickup_location_time
						: "",

				//arrival_at_delivery_location_date:leg.arrival_at_delivery_location_date || "",
				arrival_at_delivery_location_date: arrivalDLD_dateToISOString || "",
				arrival_at_delivery_location_time:
					leg.arrival_at_delivery_location_time != "Invalid date"
						? leg.arrival_at_delivery_location_time
						: "",

				//departure_from_delivery_location_date:leg.departure_from_delivery_location_date || "",
				departure_from_delivery_location_date:
					departure_ffrom_d_l_d_ToISOString || "",
				departure_from_delivery_location_time:
					leg.departure_from_delivery_location_time != "Invalid date"
						? leg.departure_from_delivery_location_time
						: "",

				address_model_type: leg.address_model_type || null,
				address_model_id: leg.address_model_id || null,
				addressLongitude: leg.addressLongitude || null,
				addressLatitude: leg.addressLatitude || null
			};

			// let temp = this.newContainersData;
			// let ds = JSON.parse(this.currentDataleg.legs);
			// const result = _.findIndex(ds.legs, (c) => c.id === this.currentLegID);

			axios.put(`${poBaseUrl}/legs/${leg.id}`, {
				leg_data: ob,
				shipment_id: this.editingShipment.id,
			}).then((res) => {
				if (res.status == 200) {
					// if (result != -1) {
					// 	ds.legs.splice(result, 1, ob);
					// 	temp[this.currentIndex]["dispatch_schedule"] = JSON.stringify(ds);
					// 	this.newContainersData.splice(
					// 		this.currentIndex, 1, temp[this.currentIndex]
					// 	);
					// 	this.loadedContainersData[this.currentTab].data[
					// 		this.getLoadedDataPage()
					// 	] = temp;
					// }

					this.isLegUpdated = 1;
					this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, this.page);
					this.notificationCustom("Container has been successfully updated!");
				}
			})
				.catch((err) => {
					console.log(err);
				})
				.then(async () => {
					this.editingLeg = null;
					this.editingShipment = null;
					this.showEditLegModal = false;
					if (this.currentSelectTab == "Scheduled") {
						console.log("Scheduled tab");
						// this.setSetFilterActive(this.currentStateClick)
					} else {
						// Commented By Alex
						// await this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1)
						this.$emit("reinitializeTable");
					}
				}
				);
		},
		saveNewChassis(data) {
			axios.post(`${poBaseUrl}/chassis-save`, data)
				.then((response) => {
					this.notificationCustom(response.data.message);
					this.responseChassis = response.data.chassis;
					this.fetchChassis();
				}).catch((error) => {
					console.log(error);
				});
		},
		addNewLeg(leg) {
			let dispatch_schedule = null, id = null, legs = [], ds = null;

			if (this.editingShipment != null) {
				try {
					ds = JSON.parse(this.editingShipment.dispatch_schedule);
					if (typeof ds.id != "undefined" && ds.id) id = ds.id;
					if (typeof ds.legs != "undefined" && ds.legs && ds.legs.length > 0)
						legs = ds.legs;
				} catch (err) {
					console.log(err);
					id = this.makegenerate();
				}
				dispatch_schedule = {
					id: id,
					location_from: this.editingShipment.terminal_id,
					last_free_date: this.editingShipment.last_free_date,
					per_diem_date: this.editingShipment.per_diem_date,
					legs: legs,
				};
			}

			// Alex fix mismatch date
			let dateFromISOString = null;
			let dateToISOString = null;
			let arrivalDateTime_dateToISOString = null;
			let departureDateTime_dateToISOString = null;
			let arrivalDLD_dateToISOString = null;
			let departure_ffrom_d_l_d_ToISOString = null;
			let dateFromField = null;

			// schedule pickup
			if (leg.pickup_scheduled_date != null) {
				var dateFrom = moment.utc(leg.pickup_scheduled_date).format("YYYY-MM-DD");
				if (dateFrom != null && dateFrom != "Invalid date") {
					dateFromISOString = dateFrom + " " + this.checkifValid_then_return(leg.pickup_scheduled_time);
				}
			}

			// scheduled delivery
			if (leg.delivery_scheduled_date != null) {
				var dateTo = moment.utc(leg.delivery_scheduled_date).format("YYYY-MM-DD");
				if (dateTo != null && dateTo != "Invalid date") {
					dateToISOString = dateTo + " " + this.checkifValid_then_return(leg.delivery_scheduled_time);
				}
			}

			// schedule arrival_at_pickup_location_date
			if (leg.arrival_at_pickup_location_date != null &&
				leg.arrival_at_pickup_location_date != "") {
				var arrivalDatePickup = moment.utc(leg.arrival_at_pickup_location_date).format("YYYY-MM-DD");
				if (arrivalDatePickup != null && arrivalDatePickup != "Invalid date") {
					arrivalDateTime_dateToISOString =
						arrivalDatePickup + " " + this.checkifValid_then_return(leg.arrival_at_pickup_location_time);
					//alert(arrivalDatePickup + 'naaa')
				}
			}

			// departure_from_pickup_location_date
			if (leg.departure_from_pickup_location_date != null) {
				dateFromField = moment.utc(leg.departure_from_pickup_location_date).format("YYYY-MM-DD");
				if (dateFromField != null && dateFromField != "Invalid date") {
					departureDateTime_dateToISOString =
						dateFromField + " " + this.checkifValid_then_return(leg.departure_from_pickup_location_time);
				}
			}

			// arrival_at_delivery_location_date
			if (leg.arrival_at_delivery_location_date != null) {
				dateFromField = moment.utc(leg.arrival_at_delivery_location_date).format("YYYY-MM-DD");
				if (dateFromField != null && dateFromField != "Invalid date") {
					arrivalDLD_dateToISOString =
						dateFromField + " " + this.checkifValid_then_return(leg.arrival_at_delivery_location_time);
				}
			}

			// departure_from_delivery_location_date
			if (leg.departure_from_delivery_location_date != null) {
				dateFromField = moment.utc(leg.departure_from_delivery_location_date).format("YYYY-MM-DD");
				if (dateFromField != null && dateFromField != "Invalid date") {
					departure_ffrom_d_l_d_ToISOString =
						dateFromField + " " + this.checkifValid_then_return(leg.departure_from_delivery_location_time);
				}
			}

			let ob = {
				id: this.makegenerate(),
				schedule_id: dispatch_schedule.id,
				type: leg.selectedType,
				address: leg.address,
				date: dateToISOString,
				time: leg.delivery_scheduled_time,
				driver: leg.selectedDriver ? leg.selectedDriver : "",
				chassis: leg.chassis,
				mode: leg.selectedMode == 1 ? "Dropped" : "Live",
				completed: false,
				from_date: dateFromISOString,
				to_time: leg.pickup_scheduled_time,
				arrival_at_pickup_location_date: arrivalDateTime_dateToISOString,
				arrival_at_pickup_location_time: leg.arrival_at_pickup_location_time,
				departure_from_pickup_location_date: departureDateTime_dateToISOString,
				departure_from_pickup_location_time:
					leg.departure_from_pickup_location_time,
				arrival_at_delivery_location_date: arrivalDLD_dateToISOString,
				arrival_at_delivery_location_time:
					leg.arrival_at_delivery_location_time,
				departure_from_delivery_location_date:
					departure_ffrom_d_l_d_ToISOString,
				departure_from_delivery_location_time:
					leg.departure_from_delivery_location_time,
				address_model_type: leg.address_model_type || null,
				address_model_id: leg.address_model_id || null,
			};

			// Comment Don't Delete
			// let ob = {
			// 	id: this.makegenerate(),
			// 	schedule_id: dispatch_schedule.id,
			// 	type: leg.selectedType,
			// 	address: leg.address,
			// 	date: leg.delivery_scheduled_date,
			// 	time: leg.delivery_scheduled_time,
			// 	driver: leg.selectedDriver ? leg.selectedDriver : "",
			// 	chassis: leg.chassis,
			// 	mode: leg.selectedMode == 1 ? "Dropped" : "Live",
			// 	completed: false,
			// 	from_date: leg.pickup_scheduled_date,
			// 	to_time: leg.pickup_scheduled_time,
			// 	arrival_at_pickup_location_date: leg.arrival_at_pickup_location_date,
			// 	arrival_at_pickup_location_time: leg.arrival_at_pickup_location_time,
			// 	departure_from_pickup_location_date: leg.departure_from_pickup_location_date,
			// 	departure_from_pickup_location_time: leg.departure_from_pickup_location_time,
			// 	arrival_at_delivery_location_date: leg.arrival_at_delivery_location_date,
			// 	arrival_at_delivery_location_time: leg.arrival_at_delivery_location_time,
			// 	departure_from_delivery_location_date: leg.departure_from_delivery_location_date,
			// 	departure_from_delivery_location_time: leg.departure_from_delivery_location_time,
			// 	address_model_type: leg.address_model_type || null,
			// 	address_model_id: leg.address_model_id || null,
			// };
			dispatch_schedule.legs.push(ob);

			// let temp = this.newContainersData;
			//let ds = JSON.parse(this.currentDataleg.legs)
			// const indexdata = _.findIndex(this.newContainersData, (c) => c.id ===  this.editingShipment.id); 

			axios.post(`${poBaseUrl}/dispatch-save/${this.editingShipment.id}`, {
				data: JSON.stringify(dispatch_schedule),
			})
				.then((res) => {
					if (res.status == 200) {
						// temp[indexdata]['dispatch_schedule'] = JSON.stringify(dispatch_schedule) 					
						// this.newContainersData.splice( indexdata , 1 , temp[indexdata]) 					
						// this.loadedContainersData[this.currentTab].data[this.getLoadedDataPage()] = temp 

						this.isLegUpdated = 1
						//this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1)
						this.notificationCustom("Container has been successfully updated!");
					}
				})
				.catch((err) => {
					console.log(err);
				})
				.then(async () => {
					this.editingLeg = null;
					this.editingShipment = null;
					this.showAddLegModal = false;

					if (this.currentSelectTab == 'Scheduled') {
						console.log('Scheduled tab');
						// this.setSetFilterActive(this.currentStateClick)
					} else {
						await this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, this.page)
						// this.$emit("reinitializeTable");
					}
				});
		},
		// mark as empty
		openDialog(item) {
			let { row } = item
			let { data } = row
			this.dialog = true;
			this.dialogData = data;
		},
		closeDialog() {
			this.dialog = false;
			this.dialogData = []
		},
		async updateContainer(item, dateTime) {
			let payload = {
				item: item,
				dateTime: dateTime,
			};

			this.isSubmitting = true;
			axios.post(`${poBaseUrl}/container-empty/` + payload.item.id, payload.dateTime)
				.then(async (res) => {
					if (res.status == 200) {
						this.notificationCustom("Container has been successfully updated!");
						delete this.loadedContainersData["at-customer-full"];
						delete this.loadedContainersData["at-customer-empty"];
						await this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, this.page)
						await this.$emit("reinitializeTable");
					}
				})
				.catch((err) => {
					console.log(err);
				});
			// await this.setContainerEmptyStatus(payload)
			// .then(async () => {
			// 	this.notificationCustom("Container has been successfully updated!");
			// 	await this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, this.page)
			// 	await this.$emit("reinitializeTable");
			// });
			this.isSubmitting = false;
			this.dialog = false;
		},
		// fetching
		getCurrentConsignees: (options) => ({
			store: new CustomStore({
				key: 'id',
				loadMode: 'raw',
				load: () => {
					let customer_id = options.data ? options.data.CustomerID : (typeof options === "number" ? options : null)
					currentConsignees = []

					if (customer_id !== null) {
						return axios.get(`${poBaseUrl}/get-consignees/${customer_id}`)
							.then(res => {
								if (res.data) {
									currentConsignees = res.data.results
									return res.data.results
								}
							})
							.catch(() => {
								currentConsignees = []
								throw 'Network error'
							});
					}
				},
			}),
			// filter: options.data ? ['CustomerID', '=', options.data.CustomerID] : null,
		}),
		calculateDisplayValueConsignee(v) {
			return v.ConsigneeName !== '' ? v.ConsigneeName : 'N/A'
		},
		calculateDisplayValueEmptyNotified(v) {
			return v.EmptyNotified !== null && v.EmptyNotified !== '' ? v.EmptyNotified : 'N/A'
		},
		calculateDisplayValueScheduled(v) {
			return v.Scheduled !== null && v.Scheduled !== '' && v.Scheduled !== undefined ? v.Scheduled : 'Not Scheduled'
		},
		calculateDisplayValuePickupTime(v) {
			return v.LocationDateTime !== null && v.LocationDateTime !== '' && v.LocationDateTime !== undefined ? v.LocationDateTime : 'N/A'
		},
		calculateDisplayValueDeliveryTime(v) {
			return v.DeliveryDateTime !== null && v.DeliveryDateTime !== '' && v.DeliveryDateTime !== undefined && v.DeliveryDateTime !== "Invalid date" ? v.DeliveryDateTime : 'N/A'
		},
		calculateDisplayValueLFDR(v) {
			let val = ""
			if (v.Lfdr !== "" && v.Lfdr !== null) {
				val = v.Lfdr
			} else {
				if (this.currentSelectTab === "At Yard") {
					val = this.selected_sub_tab.name === "full" ? "Pending" : "Not Available"
				} else {
					val = "N/A"
				}
			}
			return val
		},
		calculateDisplayArrivedAtData(v) {
			return v.ArrivedAt !== null && v.ArrivedAt !== '' && v.ArrivedAt !== undefined ? v.ArrivedAt : 'N/A'
		},
		calculateDisplayValueDriver(v) {
			return v.Driver !== "" ? v.Driver : 'Unassigned'
		},
		// Scheduled methods
		getStatusClassUpdated(item) { // updated
			let status = "yellow-bg-status"

			if (item !== null && item.dispatch !== undefined && typeof item.dispatch.first_unarrived_leg !== "undefined") {
				// let currentLeg = _.first(item.dispatch.legs);
				let currentLeg = item.dispatch.first_unarrived_leg;

				if (item.dispatch.legs.length > 0 && currentLeg !== undefined) {
					if (currentLeg.driver_status !== "Declined") {
						if (currentLeg.schedule_completed_date_time !== null) {
							status = "green-bg-status"
						} else if (currentLeg.departure_from_delivery_location_date_time !== null) {
							status = "blue-bg-status"
						} else if (currentLeg.arrival_at_delivery_location_date_time !== null) {
							status = "blue-bg-status"
						} else if (currentLeg.departure_from_pickup_location_date_time !== null) {
							status = "blue-bg-status"
						} else if (currentLeg.arrival_at_pickup_location_date_time !== null) {
							status = "blue-bg-status"
						} else if (currentLeg.start_date_time !== null) {
							status = "blue-bg-status"
						} else if (currentLeg.schedule_accepted_date_time !== null || currentLeg.driver_status === "Accepted") {
							status = "green-bg-status"
						} else if (currentLeg.schedule_assigned_date_time !== null) {
							status = "blue-bg-status"
						} else if (currentLeg.driver_status === "Pending") {
							status = "yellow-bg-status"
						}
					} else
						status = "red-bg-status"
				}
			}
			return `status ${status}`
		},
		deliveryon(item, isFor) {
			if (item !== null && item.dispatch !== undefined) {
				if (item.dispatch.legs.length > 0 && typeof item.dispatch.first_unarrived_leg !== "undefined") {
					// var akoy = this,
					var parsejson = item.dispatch.first_unarrived_leg;
					// parsejson = _.first(item.dispatch.legs);
					var isDateTimeSeparated = false, s = "";
					let address = parsejson.address;
					let date = "", time = "";

					if (parsejson.arrival_at_delivery_location_date_time !== null &&
						parsejson.arrival_at_delivery_location_date_time !== "") {
						date = parsejson.arrival_at_delivery_location_date_time;
						isDateTimeSeparated = false;
					} else {
						if (parsejson.delivery_scheduled_date_time !== null &&
							parsejson.delivery_scheduled_date_time !== "") {
							date = parsejson.delivery_scheduled_date_time
							isDateTimeSeparated = false;
						}
					}

					var theDate = null;
					let getDateFormatVal = "";

					if (isDateTimeSeparated) {
						// theDate = convertDateTimeToUTC(date, time);
						let dateTogether = date + " " + time;
						getDateFormatVal = moment.utc(dateTogether);
					} else {
						// theDate = moment(date).format("MM/DD/YY hh:mm A");
						getDateFormatVal = moment.utc(date)
					}

					theDate = getDateFormatVal !== "" ? getDateFormatVal.local().format('MM/DD/YY hh:mm A') : "";

					var xlenthtaddress = address // f.address;
					if (isFor === 'location')
						s += xlenthtaddress
					else
						s += theDate

					return s;
				}
			}
		},
		pickupOn(item, mainItem, isFor) {
			if (item !== null && item.dispatch !== undefined && typeof item.dispatch.first_unarrived_leg !== "undefined") {
				var akoy = this, getfirst = item.dispatch.first_unarrived_leg;
				// getfirst = _.first(item.dispatch.legs);
				akoy.lenthDispatch = 0;

				if (item.dispatch.legs.length > 0) {
					var ii = "", address = '', isDateTimeSeparated = false, terminalName = '';

					if (getfirst !== null) {
						if (getfirst.is_first_leg) { // get the terminal name
							if (mainItem.terminal !== null) {
								Object.keys(mainItem).forEach(function (key) {
									if (key == 'terminal') {
										terminalName = (mainItem['terminal'].nickname);
									}
								});
							}
						} else
							address = getfirst.pickup_location;
					}

					/** Get the legs */
					var datePick = {};

					if (getfirst.arrival_at_pickup_location_date_time !== null) {
						datePick.date = getfirst.arrival_at_pickup_location_date_time;
						isDateTimeSeparated = false;
					} else {
						if (getfirst.pickup_scheduled_date_time !== null) {
							datePick.date = getfirst.pickup_scheduled_date_time
							isDateTimeSeparated = false;
						} else {
							let parsedispatch = JSON.parse(item) // get the dispatch_schedule
							isDateTimeSeparated = true;
							if (parsedispatch.legs.length > 0) {
								const result = parsedispatch.legs.find((e) => e.id = getfirst.unique_id);
								datePick.date = result.from_date;
								datePick.time = result.to_time;
							} else {
								datePick.date = '--';
								datePick.time = '--';
							}
						}
					}

					if (typeof terminalName === "undefined" || terminalName === null || terminalName === "") {
						terminalName = "--";
					}

					address = address.length > 0 ? address : terminalName;

					// is_first_leg
					var datedate = null;
					let getDateFormatVal = "";
					if (isDateTimeSeparated) {
						// datedate = convertDateTimeToUTC(datePick.date, datePick.time);
						let dateTogether = datePick.date + " " + datePick.time;
						getDateFormatVal = moment.utc(dateTogether)
					} else {
						// datedate = moment(datePick.date).format("MM/DD/YY hh:mm A")
						getDateFormatVal = moment.utc(datePick.date)
					}
					datedate = getDateFormatVal !== "" ? getDateFormatVal.local().format('MM/DD/YY hh:mm A') : "";

					var lenthtaddress = address;
					if (isFor === 'location')
						ii += lenthtaddress
					else
						ii += datedate

					return ii;
				}
			}
		},
		checklength_dispatch(item) {
			var parse = this.getparse(item);
			var ret = 0;
			if (parse !== null) {
				if (typeof parse.legs != 'undefined') {
					if (parse.legs.length) {
						Object.values(parse.legs).forEach(function (f) {
							if (typeof f.x_element != 'undefined') {
								ret = 0;
							} else {
								ret = 1
							}
						})
					}
					return ret
				}
			}
		},
		/* PARSE THE ITEM */
		getparse(item) {
			if (item != "") {
				try {
					return JSON.parse(item);
				} catch (err) {
					// Uncaught SyntaxError: JSON.parse: unexpected character at
					// line 1 column 2 of the JSON data
					return {};
				}
			}
		},
		convertDateformat(data, isDate) {
			if (typeof data != "undefined") {
				var thedate = "--";
				if (isDate == "isDate") {
					thedate = timeConvertFunction(data);
				}
				return thedate;
			} else {
				return "--";
			}
		},
		getpickup(date) {
			if (typeof date != "undefined") {
				if (this.checkifValid(date, "MMM DD, YYYY")) {
					return this.getDateFormat(date)
				} else {
					return "--";
				}
			} else {
				return "--";
			}
		},
		fromdate(date) {
			if (typeof date != "undefined") {
				return this.getDateFormat(date)
			} else {
				return "--";
			}
		},
		getAssignedToDriver(item) {
			var des = this.getDrivers,
				kani = this, driver_id = "", driver_name = "", status = "Pending";

			if (item.dispatch !== undefined && typeof item.dispatch.first_unarrived_leg !== "undefined") {
				// let currentLeg = _.first(item.dispatch.legs);
				let currentLeg = item.dispatch.first_unarrived_leg;

				if (item.dispatch.legs.length > 0 && currentLeg !== undefined) {
					if (currentLeg.driver_status !== "Declined") {
						// if (currentLeg.driver_status === "Pending") {
						// 	if (currentLeg.schedule_assigned_date_time !== null) {
						// 		status = "Pending" // named as Assigned before but use Pending now

						// 		if (currentLeg.schedule_accepted_date_time !== null) {
						// 			status = "Accepted"
						// 		}
						// 	}

						// 	if (currentLeg.schedule_accepted_date_time !== null) {
						// 		status = "Accepted"
						// 	}
						// } else if (currentLeg.driver_status === "Accepted") {
						// 	status = "Accepted"

						// 	if (currentLeg.start_date_time !== null) {
						// 		status = "Started"

						// 		if (currentLeg.arrival_at_pickup_location_date_time !== null) {
						// 			status = "Arrived At Pickup"

						// 			if (currentLeg.departure_from_pickup_location_date_time !== null) {
						// 				status = "Outgated From Pickup"

						// 				if (currentLeg.arrival_at_delivery_location_date_time !== null) {
						// 					status = "Arrived At Delivery"

						// 					if (currentLeg.departure_from_delivery_location_date_time !== null) {
						// 						status = "Outgated From Delivery"
						// 					}
						// 				}
						// 			}
						// 		}
						// 	} else {
						// 		if (currentLeg.arrival_at_pickup_location_date_time !== null) {
						// 			status = "Arrived At Pickup"

						// 			if (currentLeg.departure_from_pickup_location_date_time !== null) {
						// 				status = "Outgated From Pickup"

						// 				if (currentLeg.arrival_at_delivery_location_date_time !== null) {
						// 					status = "Arrived At Delivery"

						// 					if (currentLeg.departure_from_delivery_location_date_time !== null) {
						// 						status = "Outgated From Delivery"
						// 					}
						// 				}
						// 			}
						// 		}
						// 	}							
						// }

						if (currentLeg.schedule_completed_date_time !== null) {
							status = "Completed"
						} else if (currentLeg.departure_from_delivery_location_date_time !== null) {
							status = "Outgated From Delivery"
						} else if (currentLeg.arrival_at_delivery_location_date_time !== null) {
							status = "Arrived At Delivery"
						} else if (currentLeg.departure_from_pickup_location_date_time !== null) {
							status = "Outgated From Pickup"
						} else if (currentLeg.arrival_at_pickup_location_date_time !== null) {
							status = "Arrived At Pickup"
						} else if (currentLeg.start_date_time !== null) {
							status = "Started"
						} else if (currentLeg.schedule_accepted_date_time !== null || currentLeg.driver_status === "Accepted") {
							status = "Accepted"
						} else if (currentLeg.schedule_assigned_date_time !== null) {
							status = "Assigned"
						} else if (currentLeg.driver_status === "Pending") {
							status = "Pending"
						}
					} else {
						status = currentLeg.driver_status
					}

					let findDriver = des.filter((find) => find.id == currentLeg.driver_id);
					if (findDriver.length > 0) {
						Object.values(findDriver).forEach(function (ff) {
							driver_name = kani.nameTransform(ff.name)
						});
					} else {
						driver_name = "Unassigned"
					}
				}
			}

			return { driver_id, driver_name, status };
		},
		nameTransform(text) {
			return text.toLowerCase().split(" ").map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(" ");
		},
		getScheduledLeg(item) {
			this.scheduledLastLegID = item.id;
		},
		async callAllConsingeees() {
			await axios.get(`${poBaseUrl}/get-consignees-for-trucker`)
				.then(res => {
					if (res.data) {
						this.generalConsigneeLists = res.data.results
					}
				})
				.catch(() => {
					this.generalConsigneeLists = []
					throw 'Network error'
				});
		}
	},
	watch: {
		search() {
			if (cancel !== undefined) {
				cancel("cancel_previous_request")
			}

			clearTimeout(this.typingTimeout)
			this.typingTimeout = setTimeout(() => {
				this.pageCount = 1;
				this.shouldLoad = true
				this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1);
			}, 300)
		},
		pageCount: {
			handler(newValue, oldValue) {
				if (this.search !== "") {
					if (newValue !== 0) {
						this.updatedPageCount = 1;
					} else if (oldValue > this.lastPage) {
						this.updatedPageCount = 1;
					} else if (this.lastPage > oldValue) {
						this.updatedPageCount = this.lastPage;
					} else {
						this.updatedPageCount = 1;
					}
				} else {
					if (newValue > 1) {
						this.updatedPageCount = newValue;
					} else this.updatedPageCount = this.lastPage;
				}
			},
			immediate: true,
		},
		activeTab() {
			if (this.oldselectedTab !== this.currentSelectTab) {
				this.isDisabled = true;
			}
		},
		getPoLoading: function (value) {
			if (value) {
				this.isDisabled = true;
			} else {
				this.isDisabled = false;
			}
		},
		shouldLoad: {
			handler(newValue) {
				this.loadingData = newValue;
			},
			immediate: true,
		},
		updatedData(c) {
			if (c) {
				this.retabs = 0
				this.$nextTick(() => {
					var text = this.getCurrentSelectTab;
					this.isUpdatingCells = false

					if (Object.keys(text).length !== 0) {
						this.activeTab = parseInt(text.activeTab);
						this.currentSelectTab = text.id;

						if (text.sub_tab === undefined) {
							let current_tab_item = this.tabItems.find((f) => f.name == this.currentSelectTab);
							this.selected_sub_tab = current_tab_item.sub_tab[0];
							this.subTabIndex = 0
						} else {
							if (text.sub_tab !== undefined) {
								if (typeof text.sub_tab === 'string') {
									this.selected_sub_tab = {
										label: text.sub_tab,
										name: text.sub_tab,
										count: 0,
									}
									this.subTabIndex = text.subTabIndex
								} else {
									this.selected_sub_tab = this.getCurrentSelectTab.sub_tab
									this.subTabIndex = this.getCurrentSelectTab.subTabIndex
								}
							}
						}

						this.retabs = 1;
						this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1);
					} else {
						// set the default tab to All tabs
						this.storeCurrentTabSelected({
							id: "All",
							activeTab: 0,
							sub_tab: [],
							subTabIndex: 0
						});

						this.activeTab = 0;
						this.currentSelectTab = "All";
						this.selected_sub_tab = []
						this.subTabIndex = 0
						this.retabs = 1;
						this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1);
					}
				});
			}
		},
	},
	async mounted() {
		this.$store.dispatch("page/setPage", "containers");
		await this.callAllConsingeees();
	},
	updated() {
		if (this.sub_tab.length == 0) {
			let current_tab_item = this.tabItems.find((f) => f.name == this.currentSelectTab);
			// let current_sub_tab = this.getCurrentSelectTab.sub_tab

			if (current_tab_item && current_tab_item.sub_tab && current_tab_item.sub_tab.length > 0) {
				this.sub_tab = current_tab_item.sub_tab;

				if (this.selected_sub_tab === undefined) {
					this.selected_sub_tab = current_tab_item.sub_tab[0];
					this.subTabIndex = 0
				}
			}
		}

		this.updatedData = 1
	}
};
</script>
<style lang="scss">
@import "../../../../../assets/scss/buttons.scss";
@import "../scss/containerCompact.scss";
</style>