<template>
	<div class="supplier-content-wrapper">
		<div class="" style="background:none !important">
			<router-link class="gobacknow mb-2" to="/customers" style="color: #0171a1 !important;">
				<img width="8px" height="10px" src="@/assets/icons/arrowLeft.svg"> <span class="ml-2">Go Back</span>
			</router-link>
			<v-toolbar class="containerdetailheader customer">
				<v-toolbar-title class="titleheader customer">
					<div class="d-flex align-center">
						<span class="nameheader"
							style=" font-size:24px;
									width: auto;
									margin-right:12px;
									color:#4A4A4A;
									font-family: 'Inter-Medium', sans-serif !important;">
							<span>
								<!-- <img src="@/assets/icons/IconArrowleft.svg"> -->
							</span> {{ company_name_details }}
						</span>
						<v-tooltip v-if="hidebutton" top>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on" class="doprodownreport">
									<span :class="the_checkclass">
										<span class="scheduletext"> Report Scheduling </span>
										<span class="ON_text">
											<!-- {{getManageReporttext()}} -->
											{{ ON_TEXT }}
										</span>
										<span>
											<v-menu offset-y nudge-width="240" nudge-left="230" nudge-top="-7" 
												content-class="report-scheduling-tooltip">
												<template v-slot:activator="{ on, attrs }">
													<span v-bind="attrs" v-on="on" class="d-flex justify-center align-center">
														<img src="@/assets/icons/Chevron Down2.svg">
													</span>
												</template>

												<v-list flat>										
													<div class="dropdownreport">
														<ul class="listmenu">
															<li>
																<span @click="sendReport" class="headtextlist sendreport"> 
																	Send Report
																</span>
															</li>
															<li v-if="allDataresponse!=''" class="listmenuborderline">
																<!-- <p class="list_item" style="font-size:10px;"> 
																	Last sent on : {{allDataresponse}}
																</p> -->

																<p style="font-size:10px; color: #6D858F;" class="pt-1 mb-3"> 
																	Sent last time: {{ allDataresponse }}
																</p>
															</li>
															<li @click="manageschedule" class="cmanage">
																<span class="cmon_item">Manage Schedule </span>
															</li>

															<li class="cmanage">
																<span @click="reportdownload" class="cmon_item">
																	Download Report 
																</span>
															</li>
														</ul>
													</div>
												</v-list>
											</v-menu>
										</span>
									</span>
								</span>
							</template>
							<span class="tooltip">
								<span
									class="tooltiptext"
									style="font-size:10px;"
								> Sent last time: {{allDataresponse}}
								</span>
							</span>
						</v-tooltip>
						<span class="emailholder pl-4" v-if="getHashId">
							<a class="shiflkey pl-0" @click="copyVisibilityLink"> Copy Visibility Link </a>
						</span>
					</div>
				</v-toolbar-title>
				<v-spacer></v-spacer>				
				
				<!-- <span style="cursor: not-allowed;">
					<v-btn 
						color="primary" 
						class="btn-white" 
						style="margin-right:6px; pointer-events: none;"
					>
						<span class="d-flex align-center">
							<img style="margin-top:0;" src="@/assets/icons/edit-inventory.svg" class="mr-1" width="16px" height="12px">
							Edit Customer
						</span>
					</v-btn> 
				</span> -->
				<span style="cursor: not-allowed;">
					<v-btn 
						color="primary" 
						class="btn-white" 
						style="margin-right:6px; pointer-events: none;"
						@click="sendEmail"
						disabled>
						<span class="d-flex align-center">
							<img style="margin-top:0;" src="@/assets/icons/email_icon_send.svg" class="mr-1" width="16px" height="12px">
							Send Email
						</span>
					</v-btn> 
				</span>
				

				<v-menu offset-y nudge-width="130" nudge-left="130">
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on" class="btn-white" style="min-width: 40px; width: 40px !important;">
							<img src="@/assets/icons/more-blue.svg" alt="" />
						</v-btn>
					</template>

					<v-list>
						<v-list-item style="min-height: 35px;" @click="editCustomer">              
							<v-list-item-title style="color: #4a4a4a; font-size: 14px !important;" class="d-flex align-center">
								<img src="@/assets/icons/editpencil.svg" style="top:0;" class="mr-2" width="12px" height="12px"> 
								Edit Customer
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu> 
			
			</v-toolbar>
			<div class="Headerbottomtext customer mb-4">
				<span class="reportscheduletag" v-if="getcompanykey !== null && getcompanykey !== '' && getcompanykey !== '--'">
					<span style="color:#6D858F;
								font-style: normal;
								font-weight: 600;
								font-size: 12px;">SHIFL KEY</span>
					<span class="shiflkey pr-1">{{getcompanykey}}</span>
				</span>
				<span class="telephoneholder-customer px-2" v-if="getphone !== ''">
					<img src="@/assets/icons/phone.svg">
					<span class="shiflkey">{{getphone}}</span>
				</span>
				<span class="emailholder-customer" v-if="getemailsdata !== ''">
					<img src="@/assets/icons/email.svg">
					<span class="shiflkey pr-1">{{getemailsdata}}</span>
				</span>
				<!-- <span class="emailholder pl-1" v-if="getHashId">
					<a class="shiflkey pl-0" @click="copyVisibilityLink"> Copy Visibility Link </a>
				</span> -->

				<!-- <v-tooltip v-if="hidebutton" top>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on" class="doprodownreport">
							<span :class="the_checkclass">
								<span class="scheduletext"> Report Scheduling </span>
								<span class="ON_text"> -->
									<!-- {{getManageReporttext()}} -->
									<!-- {{ ON_TEXT }}
								</span>
								<span>
									<v-menu offset-y nudge-width="240" nudge-left="230" nudge-top="-7" 
										content-class="report-scheduling-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<span v-bind="attrs" v-on="on" class="d-flex justify-center align-center">
												<img src="@/assets/icons/Chevron Down2.svg">
											</span>
										</template>

										<v-list flat>										
											<div class="dropdownreport">
												<ul class="listmenu">
													<li>
														<span @click="sendReport" class="headtextlist sendreport"> 
															Send Report
														</span>
													</li>
													<li v-if="allDataresponse!=''" class="listmenuborderline"> -->
														<!-- <p class="list_item" style="font-size:10px;"> 
															Last sent on : {{allDataresponse}}
														</p> -->

														<!-- <p style="font-size:10px; color: #6D858F;" class="pt-1 mb-3"> 
															Sent last time: {{ allDataresponse }}
														</p>
													</li>
													<li @click="manageschedule" class="cmanage">
														<span class="cmon_item">Manage Schedule </span>
													</li>

													<li class="cmanage">
														<span @click="reportdownload" class="cmon_item">
															Download Report 
														</span>
													</li>
												</ul>
											</div>
										</v-list>
									</v-menu>
								</span>
							</span>
						</span>
					</template>
					<span class="tooltip">
						<span
							class="tooltiptext"
							style="font-size:10px;"
						> Sent last time: {{allDataresponse}}
						</span>
					</span>
				</v-tooltip> -->
			</div>
		</div>
		<!-- :headers="tabswitch == 0 ? headers : tabswitch == 1 ? headersConsignee : []" -->
		<v-data-table
			:headers="tabswitch == 0 ? headers : []"
			mobile-breakpoint="769"
			:items="tabswitch == 0 ? databaheader : databaheaderConsignee"
			class="table_customer containers-table elevation-1"
			:page.sync="page"
			:items-per-page="itemsPerPage"
			@page-count="pageCount = $event"
			hide-default-footer
			fixed-header
			:loading="false"
			:class="{mobile: isMobile}"
			:custom-sort="customSort"
		>
			<template v-slot:[`header.eta`]="{ header }">
				<div class="d-flex justify-space-between align-center">{{ header.text }}<i class="classsort-icon"></i></div>
			</template>
			<template v-slot:[`header.terminal.address`]="{ header }">
				<div class="d-flex justify-space-between align-center">{{ header.text }}<i class="classsort-icon"></i></div>
			</template>
			<template v-slot:[`header.dispatch.legs`]="{ header }">
				<div class="d-flex justify-space-between align-center">{{ header.text }}<i class="classsort-icon"></i></div>
			</template>
			<template v-slot:top>
				<v-tabs
						v-model="tabsModel"
						class="tabcontainers">
							<v-tab 
									@click="gettabs('containers')"
									value="1" 
									class="title-tab">
									Containers <span
									class="numbericon"
									style="border-radius:40px;background:#0171A1;color:#fff;"
								>
								{{databaheader.length}}
								</span>
							</v-tab>
							<v-tab
								@click="gettabs('details')" 
								value="2" 
								class="title-tab"
							>
								Detail Info
							</v-tab>
							<v-tab
								@click="gettabs('consignee')" value="2" class="title-tab">Consignee<span
								class="numbericon"
								style="border-radius:40px;background:#0171A1;color:#fff;"
								>
									{{consignee.length}}
								</span>
							</v-tab>
				</v-tabs>
			</template>
			<!-- <template v-slot:[`item.address`]="{ item }">
					<span class="tets"
						v-html="check_ref_containers(item)">
					</span><br />
				</template>  -->
			<!-- <template v-slot:[`item.thedriver`]="{ item }">
					<span
						v-html="getdriverhtml(item.thedriver)">
					</span>
				</template>  -->
			<!-- <template v-slot:[`item.terminal.address`]="{ item }">
					<span v-html="callpickupon(item,1)"></span>
				</template>  -->
			<!-- <template v-slot:[`item.dispatch.legs`]="{ item }">
						<span
						v-html="callpickupon(item,0)">
					</span>
				</template>  -->
			<template v-slot:body="{ items }">
				<tbody v-if="items.length > 0 && (tabswitch == 0 )">
					<tr
						v-for="item in items"
						:key="item.id"
					>
						<td>
							<span
								class="tets"
								v-html="check_ref_containers(item)"
							>
							</span><br />
						</td>
						<td>
							<span>
								{{ getDateFormated(item.eta) }}
							</span>
						</td>
						<td>
							<span v-html="getdriverhtml(item.thedriver)">
							</span>
						</td>
						<td>
							<span v-html="callpickupon(item,1)"></span>
						</td>
						<td>
							<span v-html="callpickupon(item,0)">
							</span>
						</td>
					</tr>
				</tbody>
				<tbody v-if="tabswitch == 1">
					<div v-if="consignee.length <= 0" class="flex justify-center mt-3 text--secondary">
						<div class="flex flex-column text-center mt-10">
							<div>
								<span class="head-text"> Add Consignee </span>
							</div>
							<div class="head-sub-text mt-3">
								<span>You have not added any consignee yet. Let's add a consignee to</span><br>
								<span>Shifl platform</span>
							</div>
							<div class="flex justify-center mt-6">
								<v-btn @click="handleAddConsignee" class="btn-white">Add Consignee</v-btn>
							</div>
						</div>
					</div>
					<div v-else>
						<v-data-table
							class="consignee-table"
							:headers="consigneeHeaders"
							:items="consignee"
							hide-default-footer
							dense
						>
							<template v-slot:top>
								<v-toolbar flat>
									<div class="customer-search-component">
										<SearchComponent
											placeholder="Search Consignee"
											:searchValue.sync="searchConsignee"
											:handleSearchComponent="true"
										/>
									</div>
									<v-spacer></v-spacer>
									<v-btn  
										class="add-consignee-btn"
										@click="handleAddConsignee"
									>
										Add Consignee
									</v-btn>
								</v-toolbar>
							</template>
							<template v-slot:item="{ item }">
								<tr @dblclick="handleConsigneeDetails(item)">
									<td class="text-start">
										<span style="font-size: 14px; color: #4A4A4A;">{{ item.name }}</span>
									</td>
									<td class="text-start">
										<span style="font-size: 14px; color: #4A4A4A;">{{ getEmail(item.emails) }}</span>
									</td>
									<td class="text-start">
										<span style="font-size: 14px; color: #4A4A4A;">{{ getAddress(item.address) }}</span>
									</td>
									<td class="text-start align-center">
										<span style="font-size: 14px; color: #4A4A4A;" v-html="getPrimaryContact(item.contacts)"></span>
									</td>
									<td class="text-center">
										<div class="flex justify-center">
											<v-btn  
												@click="handleEditConsignee(item)"
												class="consignee-update-btn"
											>
												<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" clip-rule="evenodd" d="M8.15275 1.17785C8.88989 0.440717 10.085 0.440717 10.8221 1.17785C11.5593 1.91498 11.5593 3.11011 10.8221 3.84725L6.26407 8.40533C6.12341 8.54598 5.93265 8.625 5.73374 8.625H4.125C3.71079 8.625 3.375 8.28922 3.375 7.875V6.26627C3.375 6.06735 3.45402 5.87659 3.59467 5.73594L8.15275 1.17785ZM4.875 7.125H5.42307L8.61871 3.92937L8.07063 3.38129L4.875 6.57693V7.125ZM9.13129 2.32063L9.67937 2.86871L9.76149 2.78659C9.91284 2.63524 9.91284 2.38986 9.76149 2.23851C9.61014 2.08716 9.36476 2.08716 9.21341 2.23851L9.13129 2.32063ZM0.875 4.45192C0.875 3.44294 1.69294 2.625 2.70192 2.625H4.31731C4.73152 2.625 5.06731 2.96079 5.06731 3.375C5.06731 3.78921 4.73152 4.125 4.31731 4.125H2.70192C2.52137 4.125 2.375 4.27137 2.375 4.45192V9.29808C2.375 9.47863 2.52137 9.625 2.70192 9.625H7.54808C7.72863 9.625 7.875 9.47863 7.875 9.29808V7.68269C7.875 7.26848 8.21079 6.93269 8.625 6.93269C9.03921 6.93269 9.375 7.26848 9.375 7.68269V9.29808C9.375 10.3071 8.55706 11.125 7.54808 11.125H2.70192C1.69294 11.125 0.875 10.3071 0.875 9.29808V4.45192Z" fill="#0171A1"/>
												</svg>
											</v-btn>
											<v-btn @click="handleDeleteConsignee(item)" class="consignee-del-btn ml-2">
												<svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 2.75V2.25H5.75V2.75H4.25ZM3.5006 4.25C3.5004 4.25 3.5002 4.25 3.5 4.25C3.4998 4.25 3.4996 4.25 3.4994 4.25H2.3151L2.74741 9.43772C2.74914 9.45843 2.75 9.47921 2.75 9.5C2.75 9.63807 2.86193 9.75 3 9.75H7C7.13807 9.75 7.25 9.63807 7.25 9.5C7.25 9.47921 7.25086 9.45843 7.25259 9.43772L7.6849 4.25H6.5006C6.5004 4.25 6.5002 4.25 6.5 4.25C6.4998 4.25 6.4996 4.25 6.4994 4.25H3.5006ZM7.25 2.75V2C7.25 1.30964 6.69036 0.75 6 0.75H4C3.30964 0.75 2.75 1.30964 2.75 2V2.75H1.50876H1.49291H1C0.585786 2.75 0.25 3.08579 0.25 3.5C0.25 3.84779 0.486722 4.14028 0.80783 4.22515L1.25035 9.53541C1.26921 10.4856 2.04533 11.25 3 11.25H7C7.95467 11.25 8.73079 10.4856 8.74965 9.53541L9.19217 4.22515C9.51328 4.14028 9.75 3.84779 9.75 3.5C9.75 3.08579 9.41421 2.75 9 2.75H8.50708H8.49124H7.25ZM4 4.75C4.41421 4.75 4.75 5.08579 4.75 5.5V8.5C4.75 8.91422 4.41421 9.25 4 9.25C3.58579 9.25 3.25 8.91422 3.25 8.5V5.5C3.25 5.08579 3.58579 4.75 4 4.75ZM6.75 5.5C6.75 5.08579 6.41421 4.75 6 4.75C5.58579 4.75 5.25 5.08579 5.25 5.5V8.5C5.25 8.91422 5.58579 9.25 6 9.25C6.41421 9.25 6.75 8.91422 6.75 8.5V5.5Z" fill="#F93131"/>
												</svg>
											</v-btn>
										</div>
									</td>
								</tr>
							</template>
						</v-data-table>
					</div>
				</tbody>
				<tbody v-if="tabswitch === 2">
					<v-row class="px-4 pt-3">
						<v-col cols="6">
							<span class="detail-item-label">Billing Address</span>
							<div class="customer-detail-info mt-2">
								<span>{{ getBillingAddress() }}</span>
							</div>
						</v-col>
						<v-col cols="6">
							<span class="detail-item-label">Terms</span>
							<div class="customer-detail-info mt-2">
								<span>{{ getBillingNotes() }}</span>
							</div>
						</v-col>
						<v-col cols="6">
							<span class="detail-item-label">Billing Note</span>
							<div class="customer-detail-info mt-2">
								<span>{{ getBillingTerms() }}</span>
							</div>
						</v-col>
					</v-row>

					<div class="px-4 pt-8 pb-3">
						<label class="section-text-black">Contacts</label>
					</div>
					<v-divider style="border: 1px solid #EBF2F5"></v-divider>
					<v-data-table
						class="details-contacts-table"
						:headers="contactHeaders"
						:items="getCustomerContacts"
						hide-default-footer
						dense
					>
						<template v-slot:item="{ item, index }">
							<tr>
								<td
									class="text-start"
									style="width: 200px; padding: 0px 16px"
								>
									<v-text-field
										v-if="indexToEdit === index"
										type="text"
										dense
										hide-details="auto"
										placeholder="Enter contact name"
										class="text-fields qbContactsDetails container-nbr-ph"
										flat
										solo
										validate-on-blur
										v-model="item.contact_name"
										@change="handleValueChange($event, 'contact_name')"
									/>
									<span v-else style="float: left; margin-left: 15px;">{{ item.contact_name }}</span>
								</td>
								<td
									class="text-end"
									style="border-left: none"
								>
									<vue-tel-input
										v-if="indexToEdit === index"
										mode="international"
										defaultCountry="us"
										validCharactersOnly
										:autoDefaultCountry="true"
										:inputOptions="vueTelInputOptions"
										v-model="item.phone_number"
										@input="(s, p) => handlePhoneNumber(s, p, index)"
									>
										<template v-slot:arrow-icon>
											<v-icon class="ml-1">mdi-chevron-down</v-icon>
										</template>
									</vue-tel-input>
									<span v-else style="float: left; margin-left: 15px;">{{ item.phone_number }}</span>
								</td>
								<td
									class="text-end"
									style="border-left: none"
								>
									<v-text-field
										v-if="indexToEdit === index"
										flat
										solo
										type="email"
										placeholder="Enter email address"
										class="text-fields qbContactsDetails inlinefield text-end"
										dense
										hide-details="auto"
										full-width
										v-model="item.email"
										@change="handleValueChange($event, 'email')"
									>
									</v-text-field>
									<span v-else style="float: left; margin-left: 15px;">{{ item.email }}</span>
								</td>
								<td
									class="text-end align-center"
									style="border-left: none; font-size: 14px"
								>
									<div class="flex" v-if="indexToEdit === index">
										<v-text-field
											flat
											solo
											type="text"
											placeholder="Role"
											class="text-fields qbContactsDetails inlinefield text-end"
											hide-details
											v-model="item.role"
										>
										</v-text-field>
										<div class="flex align-center">
											<input
												class="empty-checkbox my-checkbox"
												type="checkbox"
												:checked="item.primary"
												@change="handleValueChange($event, 'primary')"
											/>
											<span class="checkbox-text ml-2 mr-2">Primary</span>
										</div>
									</div>
									<span v-else style="float: left; margin-left: 15px; font-size: 14px">
										{{ item.role }} <span v-show="item.primary" class="primary-badge">Primary Contact</span>
									</span>
								</td>
								<td
									class="text-center"
									style="border-left: none"
								>
									<div class="flex justify-center">
										<v-btn 
											v-if="indexToEdit !== index" 
											class="contacts-update-btn"
											@click="handleUpdateContact(index)"
										>
											<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path fill-rule="evenodd" clip-rule="evenodd" d="M8.15275 1.17785C8.88989 0.440717 10.085 0.440717 10.8221 1.17785C11.5593 1.91498 11.5593 3.11011 10.8221 3.84725L6.26407 8.40533C6.12341 8.54598 5.93265 8.625 5.73374 8.625H4.125C3.71079 8.625 3.375 8.28922 3.375 7.875V6.26627C3.375 6.06735 3.45402 5.87659 3.59467 5.73594L8.15275 1.17785ZM4.875 7.125H5.42307L8.61871 3.92937L8.07063 3.38129L4.875 6.57693V7.125ZM9.13129 2.32063L9.67937 2.86871L9.76149 2.78659C9.91284 2.63524 9.91284 2.38986 9.76149 2.23851C9.61014 2.08716 9.36476 2.08716 9.21341 2.23851L9.13129 2.32063ZM0.875 4.45192C0.875 3.44294 1.69294 2.625 2.70192 2.625H4.31731C4.73152 2.625 5.06731 2.96079 5.06731 3.375C5.06731 3.78921 4.73152 4.125 4.31731 4.125H2.70192C2.52137 4.125 2.375 4.27137 2.375 4.45192V9.29808C2.375 9.47863 2.52137 9.625 2.70192 9.625H7.54808C7.72863 9.625 7.875 9.47863 7.875 9.29808V7.68269C7.875 7.26848 8.21079 6.93269 8.625 6.93269C9.03921 6.93269 9.375 7.26848 9.375 7.68269V9.29808C9.375 10.3071 8.55706 11.125 7.54808 11.125H2.70192C1.69294 11.125 0.875 10.3071 0.875 9.29808V4.45192Z" fill="#0171A1"/>
											</svg>
											Update
										</v-btn>
										<v-btn @click="handleSaveContact" v-if="indexToEdit === index" class="contacts-save-btn">
											<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M4.6129 8.11542L2.56568 5.96584C2.25326 5.6378 1.74673 5.6378 1.43431 5.96584C1.1219 6.29388 1.1219 6.82574 1.43431 7.15378L4.10098 9.95377C4.43362 10.303 4.98008 10.277 5.28124 9.89755L10.6146 3.17758C10.8974 2.82118 10.8515 2.29151 10.5121 1.99452C10.1727 1.69753 9.66826 1.74568 9.38541 2.10207L4.6129 8.11542Z" fill="#16B442"/>
											</svg>
											Save
										</v-btn>
										<v-btn @click="handleDeleteContact(index)" class="contacts-del-btn ml-2">
											<svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 2.75V2.25H5.75V2.75H4.25ZM3.5006 4.25C3.5004 4.25 3.5002 4.25 3.5 4.25C3.4998 4.25 3.4996 4.25 3.4994 4.25H2.3151L2.74741 9.43772C2.74914 9.45843 2.75 9.47921 2.75 9.5C2.75 9.63807 2.86193 9.75 3 9.75H7C7.13807 9.75 7.25 9.63807 7.25 9.5C7.25 9.47921 7.25086 9.45843 7.25259 9.43772L7.6849 4.25H6.5006C6.5004 4.25 6.5002 4.25 6.5 4.25C6.4998 4.25 6.4996 4.25 6.4994 4.25H3.5006ZM7.25 2.75V2C7.25 1.30964 6.69036 0.75 6 0.75H4C3.30964 0.75 2.75 1.30964 2.75 2V2.75H1.50876H1.49291H1C0.585786 2.75 0.25 3.08579 0.25 3.5C0.25 3.84779 0.486722 4.14028 0.80783 4.22515L1.25035 9.53541C1.26921 10.4856 2.04533 11.25 3 11.25H7C7.95467 11.25 8.73079 10.4856 8.74965 9.53541L9.19217 4.22515C9.51328 4.14028 9.75 3.84779 9.75 3.5C9.75 3.08579 9.41421 2.75 9 2.75H8.50708H8.49124H7.25ZM4 4.75C4.41421 4.75 4.75 5.08579 4.75 5.5V8.5C4.75 8.91422 4.41421 9.25 4 9.25C3.58579 9.25 3.25 8.91422 3.25 8.5V5.5C3.25 5.08579 3.58579 4.75 4 4.75ZM6.75 5.5C6.75 5.08579 6.41421 4.75 6 4.75C5.58579 4.75 5.25 5.08579 5.25 5.5V8.5C5.25 8.91422 5.58579 9.25 6 9.25C6.41421 9.25 6.75 8.91422 6.75 8.5V5.5Z" fill="#F93131"/>
											</svg>
											Delete
										</v-btn>
									</div>
								</td>
							</tr>
						</template>
					</v-data-table>
					<!-- <div class="flex justify-center mt-3 text--secondary" v-show="getCustomerContacts === null">
						<span> No Data </span>
					</div> -->
				</tbody>
				<!-- <tbody v-else>
					<tr>
						<td style="text:center" colspan="12">
							<div style="width:100%;text-align:center;">
								<v-progress-circular
									:size="40"
									color="#0171a1"
									indeterminate
									v-if="hidebutton==0"
								>
								</v-progress-circular>
								<div v-if="hidebutton" style="color: #4a4a4a;"> No data available </div>
							</div>
						</td>
					</tr>
				</tbody> -->
			</template>
			<template v-slot:no-data>
				<div class="no-data-preloader mt-4">
					<v-progress-circular
						:size="40"
						color="#0171a1"
						indeterminate
					>
					</v-progress-circular>
				</div>
			</template>
		</v-data-table>
		<Pagination
			v-if="tabswitch == 0 && databaheader.length !==0"
			:pageData.sync="page"
			:lengthData.sync="pageCount"
		/>
		<Pagination
			v-else-if="tabswitch == 1 && databaheaderConsignee.length !==0"
			:pageData.sync="page"
			:lengthData.sync="pageCount"
		/>
		<sendReport
			@updatetooltip="updatetooltip"
			:dialogData="dialogData"
			@close="closeSendreport"
			:data="dataDialog"
		/>
		<ManageReport
			:dialogData="dialogManage"
			:tooggle.sync="toggleonandoff"
			:toggleswitch.sync="metavalue"
			@close="closeManageDialog"
		/>
		<CustomerEditDialog
			:notifyupdate.sync="notifyupdate"
			:editedIndexData.sync="editedIndex"
			:dialogData.sync="dialogEdit"
			:editedItemData.sync="editedItem"
			@callUpdatedDetails="callUpdatedDetails"
		/>
		<AddConsigneeDialog
			:dialog.sync="consigneeDialog"
		/>
		<ConsigneeDeleteDialog 
			:dialog.sync="deleteDialog"
			:itemData="deletingConsignee"
		/>
		<EditConsigneeDialog 
			:dialog.sync="editDialog"
			:itemData="editingConsignee"
		/>

	</div>
</template>
<script>
import axios from "axios";
import {
	mapActions,
	mapGetters
}
from 'vuex'
import Pagination from '../../Pagination.vue'
const APIBaseUrl = process.env.VUE_APP_PO_URL
import sendReport from './dialog/sendReport.vue'
import ManageReport from './dialog/ManageReport.vue'
import CustomerEditDialog from '../../CustomerComponents/CustomerEditDialog.vue'
import _ from "lodash";
import moment from "moment";
import globalMethods from '../../../utils/globalMethods'
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import AddConsigneeDialog from './dialog/AddConsigneeDialog.vue'
import SearchComponent from "../../SearchComponent/SearchComponent.vue";
import ConsigneeDeleteDialog from './dialog/ConsigneeDeleteDialog.vue';
import EditConsigneeDialog from './dialog/EditConsigneeDialog.vue';

export default {
	name: "CustomerDesktopTable",
	props: ['reloadcomponent',
            'items',
            'drivers',
            'companykey',
            'phone',
            'emails',
            'companyname'],
	components: {
		Pagination,
		sendReport,
		ManageReport,
		CustomerEditDialog,
		VueTelInput,
		AddConsigneeDialog,
		SearchComponent,
		ConsigneeDeleteDialog,
		EditConsigneeDialog
	},
	data() {
		return {
			allconsigneeobject:[],
			tabswitch:0,
			consignee:[],
			tabsModel:'',
			detailsReport: {},
			driverdetails: this.drivers,
			hidebutton: 1,
			the_checkclass: 'off_value',
			ON_TEXT: 'OFF',
			getcompanykey: '',
			getphone: '',
			databaheader: [],
			databaheaderConsignee:[],
			getemailsdata: '',
			getHashId: null,
			tago: 0,
			reload: 0,
			company_name_details: '',
			hideme: 0,
			hasshipment: true,
			allDataresponse: '',
			hasData: false,
			checkdisable: true,
			refreshclass: 0,
			refreshmanage: 0,
			metavalue: [{
				"toggle": 0,
				"copy": 0
			}],
			toggleonandoff: 0,
			editedIndex: -1,
			notifyupdate: 0,
			dialogEdit: false,
			editedItem: {
				company_name: "",
				phone: "",
				address: "",
				emails: null,
				central_customer_key: ""
			},
			dataDialog: {
				name: this.companyname,
				email: this.emails
			},
			dialogData: false,
			dialogManage: false,
			typeGet: 'Customer',
			thedriver: '',
			isMobile: true,
			page: 1,
			startfetching: 1,
			pageCount: 0,
			itemsPerPage: 9,
			headersConsignee: [
				{
					text: "Name",
					align: "start",
					value: "contact_name",
					sortable: false,
					width: "5%",
					fixed: true
        },
				{
					text: "Visibility Link",
					align: "start",
					value: "name",
					sortable: false,
					width: "50%",
					fixed: true
        },
      ],
			headers: [
				{
					text: "Container/Ref#",
					align: "start",
					value: "address",
					sortable: false,
					width: "10%",
					fixed: true
        },
				{
					text: "ETA",
					align: "start",
					value: "eta",
					sortable: true,
					width: "8%",
					fixed: true
				},
				{
					text: "Schedule ID/Driver",
					align: "start",
					sortable: false,
					value: "thedriver",
					width: "10%",
					fixed: true
        },
				{
					text: "Pickup On",
					align: "start",
					value: "terminal.address",
					sortable: true,
					width: "16%",
					fixed: true
        },
				{
					text: "Delivery On",
					align: "start",
					value: "dispatch.legs",
					sortable: true,
					width: "16%",
					fixed: true
        },

      ],
			search: '',
			Customers: [],
			contactHeaders: [
            {
                text: "Name",
                align: "start",
                sortable: false,
                value: "name",
                fixed: true,
                width: "12%",
            },
            {
                text: "Phone Number",
                align: "start",
                sortable: false,
                value: "phone_number",
                fixed: true,
                width: "12%",
            },
            {
                text: "Email Address",
                align: "start",
                sortable: false,
                value: "email",
                fixed: true,
                width: "12%",
            },
            {
                text: "Role",
                align: "start",
                sortable: false,
                value: "primary",
                fixed: true,
                width: "12%",
            },
            {
                text: "Action",
				align: "center",
                sortable: false,
                value: "",
                fixed: true,
                width: "6%",
            },
        ],
		consigneeHeaders: [
			{
                text: "Name",
                align: "start",
                sortable: false,
                value: "consginee_name",
                fixed: true,
                width: "15%",
            },
			{
                text: "Email",
                align: "start",
                sortable: false,
                value: "emails",
                fixed: true,
                width: "15%",
            },
			{
                text: "Address",
                align: "start",
                sortable: false,
                value: "address",
                fixed: true,
                width: "20%",
            },
			{
                text: "Primary Contact",
                align: "start",
                sortable: false,
                value: "contacts",
                fixed: true,
                width: "12%",
            },
			{
                text: "",
                align: "start",
                sortable: false,
                value: "",
                fixed: true,
                width: "6%",
            },
		],
        contacts: [
            // {
            //     contact_name: "Test",
            //     phone_number: "0349234234",
            //     email: "test@gmail.com",
            //     primary: "1",
            //     role: "Admin"
            // }
        ],
        vueTelInputOptions: {
			autocomplete: false,
			placeholder: "Type Phone Number",
			styleClasses: "tel-input-class",
			required: false,
		},
		indexToEdit: "",
		isEditing: false,
		isValidEmail: false,
		numberHasError: false,
		consigneeDialog: false,
		searchConsignee: "",
		deleteDialog: false,
		deletingConsignee: "",
		editDialog: false,
		editingConsignee: ""
		}
	},
	computed: {
		...mapGetters({
			getCustomers: 'customers/getCustomers',
			getCustomersLoading: 'customers/getCustomerLoading',
			getEditviewData: "customers/getViewDataCustomers",
			getmanagereportschedule: "customers/getmanagereportschedule",
			getLastSentReport: 'customers/getLastSentReport',
			getlastsentreportcount: 'customers/getlastsentreportcount',
			getdetailsdata: 'customers/getdetailsdata',
			getCustomerdata: 'customers/getcustomerdetails',
			getFetchedQBCustomer: 'customers/getFetchedQBCustomer',
            getConsignees: 'customers/getConsignees'
		}),
		on_add_off: {
			get() {
				return this.getlastsentreportcount ? true : false;
			}
		},
		getdefault: {
			get() {
				var metavalue = this.metavalue;
				if(this.refreshmanage) {
					return this.metavalue;
				}
				else {
					if(typeof this.getmanagereportschedule != 'undefined') {
						if(Object.keys(this.getmanagereportschedule).length > 0) {
							Object.values(this.getmanagereportschedule).map((value) => {
								try {
									metavalue = JSON.parse(value[0].meta_value);
								}
								catch (error) {
									metavalue = {
										"state": false,
										"copy": false
									};
								}
							});
						}
					}
				}
				return metavalue;
			}
		},
		getManageReport: {
			get() {
				// var metavalue = this.metavalue;
				// // IF NOT ZERO
				// if(this.refreshmanage){
				//     return this.metavalue.state ? 'ON' : 'OFF'
				// }else{
				//     if(typeof this.getmanagereportschedule !='undefined'){
				//         if(Object.keys(this.getmanagereportschedule).length > 0){
				//             Object.values(this.getmanagereportschedule).map((value) =>{
				//                 //console.log(value[0].status)
				//                 try{
				//                    // metavalue = JSON.parse(value[0].meta_value);
				//                     metavalue = value[0].status; //metavalue['state'];
				//                 }catch(error){
				//                     metavalue = 0;
				//                 }
				//             });
				//         }
				//     }
				//     return  (metavalue) ? 'ON' : 'OFF';
				// }
				return 'OFF';
			}
		},
		header2() {
			var theresult;
			if(this.items.length) {
				var dis = this;
				//console.log(this.items)


				Object.values(this.items).map(function(f) {
					if(f.dispatch.legs.length) {
						
						_.map(Object.values(f.dispatch.legs), function(o) {
							if(o.type == dis.typeGet) {
								theresult = dis.getCustomerdata.drivers.filter(e => o.driver_id == e.id);
								if(theresult.length) {
									f.thedriver = o.unique_id == null ? '--' + '==' + theresult[0].name : o.unique_id + '==' + theresult[0].name
									
									//f.thedriver = o.legID == null ? '--' + '==' + theresult[0].name : o.legID + '==' + theresult[0].name
								}
							}
						});
					}
				});
			}
			return this.items;
		},
		databaheader3: {
			get() {
				var theresult;
				if(this.items.length) {
					var dis = this;
					Object.values(this.items).map(function(f) {
						if(f.dispatch.legs.length) {
							_.map(Object.values(f.dispatch.legs), function(o) {
								if(o.type == dis.typeGet) {
									theresult = dis.driverdetails.filter(e => o.driver_id == e.id);
									if(theresult.length) {
										f.thedriver = o.unique_id == null ? '--' + '==' + theresult[0].name : o.unique_id + '==' + theresult[0].name
									}
								}
							});
						}
					});
				}
				return this.items;
			},
			set(value) {
				return value;
			}
		},
		getCustomerContacts: {
			get() {
				if(this.getFetchedQBCustomer !== null) {
					if(this.getFetchedQBCustomer.contacts !== null && this.getFetchedQBCustomer.contacts !== "") {
						return JSON.parse(this.getFetchedQBCustomer.contacts)
					} else {
						return []
					}
				} else {
					return [] 
				}
			}
		}
	},
	watch: {
		toggleonandoff(c) {
			this.metavalue = c;
			this.refreshmanage = (c.state != true) ? 3 : 4
			this.refreshclass = 1;
			//console.log(c)
			this.ON_TEXT = c.state == true ? 'ON' : 'OFF';
			this.the_checkclass = c.state == true ? 'reportscheduletag' : 'off_value';
			this.hideme = 1
			this.$nextTick(() => {
				this.hideme = 0;
			});
			//console.log(c)
		},
		companyname() {
			this.contacts = this.getCustomerContacts
		},
		contacts(nv) {
			console.log('contacts >>>', nv)
		},
		getConsignees(nv) {
			this.consignee = nv
		}
	},
	created() {},
	methods: {
		...mapActions({
			fetchCustomers: 'customers/fetchCustomers',
			getManageReportSetting: 'customers/getManageReportSetting',
			//fetchLastSentReport: 'customers/fetchLastSentReport'
			updateCustomerContacts: 'customers/updateCustomerContacts'
		}),
		...globalMethods,
		sendEmail() {},
		capitalizewords(c){
			const mySentence = c;
			const words = mySentence.split(" ");

			for (let i = 0; i < words.length; i++) {
					words[i] = words[i][0].toUpperCase() + words[i].substr(1);
			}
			return words.join(" ");
		},
		gettabs(tabs){
			// this.tabswitch = (tabs !=='containers') ? 1 : 0;

			switch(tabs) {
				case 'consignee':
					this.tabswitch = 1
					break;
				case 'details':
					this.tabswitch = 2
					break;
				default:
					this.tabswitch = 0
			}
		},
		copyConsigneeVisibilityLink(consigneeID){
			let dis = this
			navigator.clipboard.writeText(`${window.location.origin}/consignee-visibility/${this.getHashId}/${consigneeID}/containers`).then(function() {
				dis.dispatchNotifcation('<i class="ic-check-mark"></i>&nbsp;<span class="text-message">Consignee Visibility Link Copied!</span>')
				console.log('Async: Copying to clipboard was successful!');
			}, function(err) {
			console.error('Async: Could not copy text: ', err);
			});
		},
		copyVisibilityLink() {
			let dis = this
			navigator.clipboard.writeText(`${window.location.origin}/customer-visibility/${this.getHashId}/containers`).then(function() {
				dis.dispatchNotifcation('<i class="ic-check-mark"></i>&nbsp;<span class="text-message">Customer Visibility Link Copied!</span>')
				console.log('Async: Copying to clipboard was successful!');
			}, function(err) {
			console.error('Async: Could not copy text: ', err);
			});
		},
		getDateFormated(date){
			if(date)
				return moment(date).utc(true).format('MMM DD YYYY')
			return "--"
		},
		getDateTimeFormated(date){
			if(date)
				return moment(date).utc(true).format('h:mmA, MMM DD YYYY')
			return "--"
		},
		// header
		drivercolumn(items) {
			// console.log(this.drivers)
			var theresult;
			if(items.length) {
				var dis = this;
				Object.values(items).map(function(f) {
					if(f.dispatch.legs.length) {
						//console.log(f)
						_.map(Object.values(f.dispatch.legs), function(o,index) {
							if(o.type == dis.typeGet) {
								theresult = dis.driverdetails.filter(e => o.driver_id == e.id);
								if(theresult.length) {
									let indenew = '';
									if(index < 10){
										indenew = '0';
									}
								//f.thedriver = o.unique_id == null ? '--' + '==' + theresult[0].name : o.unique_id + '==' + theresult[0].name
								f.thedriver = o.legID == null ? f.shifl_ref + '-' + indenew + (index + 1 ) + '==' + theresult[0].name : o.legID +  '==' + theresult[0].name
								}
							}
						});
					}
				});
				return items;
			}
			return items;
		},
		updatetooltip(response) {
			if(Object.keys(response.sent_last_time).length) {
				this.allDataresponse = (response.sent_last_time !== '') ? new Date(response.sent_last_time).toLocaleString() : '';
			}
		},
		getManageReporttext() {
			var metavalue = this.metavalue;
			// IF NOT ZERO
			if(this.refreshmanage) {
				return this.metavalue.state ? 'ON' : 'OFF'
			}
			else {
				if(typeof this.getmanagereportschedule != 'undefined') {
					if(Object.keys(this.getmanagereportschedule).length > 0) {
						Object.values(this.getmanagereportschedule).map((value) => {
							//console.log(value[0].status)
							try {
								// metavalue = JSON.parse(value[0].meta_value);
								metavalue = value[0].status; //metavalue['state'];
							}
							catch (error) {
								metavalue = 0;
							}
						});
					}
				}
				return (metavalue) ? 'ON' : 'OFF';
			}
		},
		doprodownreport() {
			return 'showhide'
		},
		checkclass() {
			this.signalnow = 0;
			var metavalue = this.metavalue;
			if(this.refreshclass == 1) {
				this.signalnow = 1;
				return (metavalue.state) == 1 ? 'reportscheduletag' : 'off_value';
			}
			else {
				if(typeof this.getmanagereportschedule != 'undefined') {
					if(Object.keys(this.getmanagereportschedule).length > 0) {
						Object.values(this.getmanagereportschedule).map((value) => {
							try {
								//metavalue = JSON.parse(value[0].meta_value);
								metavalue = value[0].status; //metavalue['state'];
							}
							catch (error) {
								metavalue = 0;
							}
						});
					}
				}
				this.signalnow = 1;
				return (metavalue) ? 'reportscheduletag' : 'off_value';
			}
		},
		manageschedule() {
			this.dialogManage = true;
		},
		closeManageDialog() {
			this.dialogManage = false;
		},
		closeSendreport() {
			this.dialogData = false;
		},
		addCustomer() {
			this.$emit('addCustomer')
		},
		async editCustomer() {
			let array = [];
			if(typeof this.getEditviewData.items == 'undefined') {
				await axios.get(`${APIBaseUrl}/getCustomerById/` + this.$route.params.id).then((response) => {
					return response;
				}).catch(error => {
					this.response = error;
				}).then(response =>  {
					if(typeof response != 'undefined') {
						response.data.forEach((value) => {
							var text = value.emails;
							const myArray = text.split(",");
							let m = {
								customer_id: value.id,
								name: value.compnay_name,
								phone_number: value.phone == null ? '--' : value.phone,
								central_customer_key: value.central_customer_key ? value.central_customer_key : '--',
								hashId: value.hashId,
								emails: Object.assign({}, myArray),
								emailsaddress: value.emails == '' ? '--' : value.emails,
							}
							array.push(m)
						});
						this.editedItem = Object.assign({}, array[0]);
						this.dialogEdit = true;
					}
				})
			} else {
				this.editedItem = Object.assign({}, this.getEditviewData.items);
				this.dialogEdit = true;
			}
		},
		deleteCustomer(item) {
			this.$emit('deleteCustomer', item)
		},
		editItem(item) {
			this.editedIndex = this.suppliers.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},
		sendReport() {
			this.dialogData = true;
		},
		async customerListing() {
			let array = [];
			var letme = this;
			await axios.get(`${APIBaseUrl}/customersList`).then((response) => {
				return response;
			}).catch(error => {
				this.response = error;
			}).then(response => {
				if(typeof response != 'undefined') {
					response['data'].forEach((value) => {
						var text = value.emails;
						const myArray = text.split(",");
						let m = {
							customer_id: value.id,
							central_customer_key: value.central_customer_key ? value.central_customer_key : '--',
							hashId: value.hashId,
							name: value.compnay_name,
							phone_number: value.phone == null ? '--' : value.phone,
							emails: Object.assign({}, myArray),
							emailsaddress: value.emails == '' ? '--' : value.emails,
						}
						array.push(m)
					});
					letme.Customers = array;
				}
				this.startfetching = false
			})
		},
		showdropdown() {
			this.menu = true;
		},
		getemails(emails) {
			let lenghkeys = Object.keys(emails).length;
			let newkeys = [];
			if(Object.keys(emails).length) {
				let i = 0;
				for(i = 0; i < lenghkeys; i++) {
					newkeys.push(emails[Object.keys(emails)[i]].email)
				}
				let unshuffled = newkeys;
				let shuffled = unshuffled.map(value => ({
					value,
					sort: Math.random()
				})).sort((a, b) => a.sort - b.sort).map(({
					value
				}) => value)
				return shuffled[0];
			}
			return '';
		},
		getdriverhtml(item) {
			var var3 = item;
			if(typeof var3 != 'undefined') {
				const str = var3.split("==");
				if(typeof str[0] != "undefined" && typeof str[1] != "undefined") var mvar = (str[0] == null) ? '-' : str[0];
				return ('<span class="ref font-medium">' + mvar + '</span><br><span class="containerID">' + str[1] + "</span>");
			}
			return '-'
		},
		check_ref_containers(item) {
			var var3 = item.trucker_container + '-' + item.shifl_ref;
			const str = var3.split("-");
			if(typeof str[0] != "undefined" && typeof str[1] != "undefined") return ('<span class="ref font-medium">' + str[0] + '</span><br><span class="containerID">' + str[1] + "</span>");
		},
		goback() {
			this.$router.push("/customers");
		},
		deleteItem(item) {
			this.editedIndex = this.suppliers.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},
		deleteItemConfirm() {
			this.suppliers.splice(this.editedIndex, 1);
			this.closeDelete();
		},
		toUpperCase(str) {
			return str.charAt(0).toUpperCase() + str.slice(1)
		},
		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},
		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},
		onResize() {
			if(window.innerWidth < 769) {
				this.isMobile = true
			}
			else {
				this.isMobile = false
			}
		},
		setToDefault() {
			this.editedItem = this.defaultItem
			this.close()
			this.dialog = true
		},
		callpickupon(item, type_loc) {
			let theaddress = '-';
			let _date_time = '-';
			let dispatch_legs = item.dispatch.legs
			let scheduled_or_actual = '';
			let dis = this;
			if(typeof item.dispatch.legs != 'undefined') {
				if(!_.isEmpty(item.dispatch.legs)) {
					let parr = (dispatch_legs)
					let getar = (parr)
					if(getar.length > 0) {
						_.map(Object.values(getar), function(o) {
							if(o.type == dis.typeGet) {
								if(type_loc) {
									// pickup location
									if(item.terminal === null) {
										theaddress = '-';
									}
									else {
										// theaddress = item.terminal.address;
										theaddress = 
											`${item.terminal.firms_code} | ${item.terminal.name} | ${item.terminal.map_location}`
									}
									if(o.arrival_at_pickup_location_date_time !== null) {
										let toggle = o.arrival_at_pickup_location_date_time;
										let newtime = moment(toggle).utc(true).format('h:mmA, MMM DD YYYY');
										_date_time = newtime;
									}
									else {
										// check if arrival_at_delivery_location_date_time is not empty
										if(o.arrival_at_delivery_location_date_time == null) {
											scheduled_or_actual = '<span class="scheduled_tag">[Scheduled] </span>';
										}
										let newtime = moment(o.pickup_scheduled_date_time).utc(true).format('h:mmA, MMM DD YYYY');
										_date_time = newtime;
									}
								}
								else {
									// delivery
									theaddress = o.address;
									if(o.arrival_at_delivery_location_date_time !== null) {
										// ang pickup location actual na, if scheduled ra sya walay arrival
										_date_time = moment(o.arrival_at_delivery_location_date_time).utc(true).format('h:mmA, MMM DD YYYY');
									}
									else {
										scheduled_or_actual = '<span class="scheduled_tag">[Scheduled] </span>';
										_date_time = moment(o.delivery_scheduled_date_time).utc(true).format('h:mmA, MMM DD YYYY');
									}
								}
							}
						});
					}
				}
			}
			return '<div>' + theaddress + '</div>' + '<span>' + scheduled_or_actual + _date_time + '</span>'
		},
		getdriver(item) {
			var dis = this;
			if(!_.isEmpty(item)) {
				if(item.length > 0) {
					var thedriver = '';
					_.map(Object.values(item), function(o) {
						if(o.type == dis.typeGet) {
							thedriver = o.driver;
						}
					});
					var getdriver = this.driverdetails.filter((find) => find.id == thedriver);
					return getdriver;
				}
				else {
					return '--';
				}
			}
			return '--';
		},
		async reportdownload() {
			axios({
				url: `${APIBaseUrl}/customer/directDownload/` + this.$route.params.id,
				method: "GET",
				responseType: "blob",
			}).then((response) => {
				window.URL = window.URL || window.webkitURL;
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(new Blob([response.data], {
					type: "application/octet-stream",
				}));
				let theDate = new Date().valueOf();
				link.setAttribute("download", "customer_report_" + theDate + "_.xlsx");
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				this.snackbar = true;
			});
		},
		getPickupDeliveryDateTime(item, type_loc) {
			let _date_time = null;
			let dispatch_legs = item.dispatch.legs
			let dis = this;
			if(typeof item.dispatch.legs != 'undefined') {
				if(!_.isEmpty(item.dispatch.legs)) {
					let parr = (dispatch_legs)
					let getar = (parr)
					if(getar.length > 0) {
						_.map(Object.values(getar), function(o) {
							if(o.type == dis.typeGet) {
								if(type_loc) {
									if(o.arrival_at_pickup_location_date_time !== null) {
										_date_time = o.arrival_at_pickup_location_date_time
									}
									else {
										// check if arrival_at_delivery_location_date_time is not empty
										_date_time = o.pickup_scheduled_date_time
									}
								}
								else {
									// delivery
									if(o.arrival_at_delivery_location_date_time !== null) {
										// ang pickup location actual na, if scheduled ra sya walay arrival
										_date_time = o.arrival_at_delivery_location_date_time
									}
									else {
										_date_time = o.delivery_scheduled_date_time
									}
								}
							}
						});
					}
				}
			}
			return _date_time
		},
		customSort(items, index, isDesc) {
			if(index[0] != 'undefined'){
				const distantFuture = new Date(8640000000000000)
				items.sort((a, b) => {
					if(["dispatch.legs",  "terminal.address", "eta"].includes(index[0])){
							let aDate = null, bDate = null
							if(index[0] === "terminal.address") {
								aDate = this.getPickupDeliveryDateTime(a, 1)
								bDate = this.getPickupDeliveryDateTime(b, 1)
							}else if(index[0] === "dispatch.legs"){
								aDate = this.getPickupDeliveryDateTime(a, 0)
								bDate = this.getPickupDeliveryDateTime(b, 0)
							}else if(index[0] == "eta"){
								aDate = a.eta
								bDate = b.eta
							}
							aDate = aDate ? new Date(aDate) : distantFuture
							bDate = bDate ? new Date(bDate) : distantFuture
							if(isDesc[0] === true) return aDate.getTime() - bDate.getTime()
							else if(isDesc[0] === false) return bDate.getTime() - aDate.getTime()
					}

				});
			}

			return items
		},
		async callUpdatedDetails() {
			await axios.get(`${APIBaseUrl}/customer/details/` + this.$route.params.id).then((response) => {
				return response;
			}).catch(error => {
				this.response = error;
			}).then(response => {
				if(typeof response != 'undefined') {
					if(Object.keys(response.data).length) {
						this.driverdetails = (response.data.driver);
						let itemreturn = this.drivercolumn(response.data.data);
						this.databaheader = itemreturn
						this.getcompanykey = response.data.company_key
						this.company_name_details = response.data.company_name;
						this.getHashId = response.data.hashId;
						this.getphone = response.data.company_phone;
						if(response.data.getemails.length) {
							this.getemailsdata = response.data.getemails[0].email
						}
						if(response.data.last_report_count) {
							//this.checkdisable = false
						}
						if(response.data.count) {
							//this.hasData = response.data.count
						}
					}
				}
			})
		},
		getBillingAddress() {
			if(this.getFetchedQBCustomer !== null && this.getFetchedQBCustomer.billing_address !== null && this.getFetchedQBCustomer.billing_address !== "") {
				return this.getFetchedQBCustomer.billing_address
			} else {
				return "--"
			}
		},
		getBillingNotes() {
			if(this.getFetchedQBCustomer !== null && this.getFetchedQBCustomer.billing_notes !== null && this.getFetchedQBCustomer.billing_notes !== "") {
				return this.getFetchedQBCustomer.billing_notes
			} else {
				return "--"
			}
		},
		getBillingTerms() {
			if(this.getFetchedQBCustomer !== null && this.getFetchedQBCustomer.billing_terms !== null && this.getFetchedQBCustomer.billing_terms !== "") {
				return this.getFetchedQBCustomer.billing_terms
			} else {
				return "--"
			}
		},
		handlePhoneNumber(s) {
			let cpContacts = [...this.contacts]

			console.log('cpContacts >>>', cpContacts)
            let validate = /^(?=.*[0-9])[- +()0-9]+$/.test(s)
            if(!validate && s !== "") {
                this.numberHasError = true
                this.notificationRedError('Letters are not allowed')
            } else {
				if(typeof cpContacts[this.indexToEdit] !== undefined) {
					this.numberHasError = false
					cpContacts[this.indexToEdit].phone_number = s
				}
            }

			this.contacts = cpContacts
        },
		handleUpdateContact(index) {
			this.indexToEdit = index
			// this.isEditing true
		},
		handleValueChange(el, model) {
			let cpContacts = [...this.contacts]

			if(model !== "primary" && model !== "email") {
                cpContacts[this.indexToEdit][model] = el
            }
            else if(model === "email") {
                let validate = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(el)
                if(!validate) {
                    this.isValidEmail = true
                    this.notificationRedError('Email must be valid')
                } else {
                    this.isValidEmail = false
					cpContacts[this.indexToEdit][model] = el
                }
            } 
            else {
                cpContacts[this.indexToEdit][model] = el.target.checked
            }

			console.log('cp edited <<<', cpContacts)
			this.contacts = cpContacts
		},
		handleSaveContact() {
			console.log('edited >>>', this.contacts)

			let payload = {
				id: this.getFetchedQBCustomer.id,
				form: {
					contacts: JSON.stringify(this.contacts)
				}
			}
			if(!this.isValidEmail && !this.numberHasError) {
				this.indexToEdit = ""
				this.isEditing = false
				this.updateCustomerContacts(payload)
			}
		},
		handleDeleteContact(index) {
			let cpContacts = [...this.contacts]
			cpContacts.splice(index, 1)

			console.log('cp contacts >>>', cpContacts)
			this.contacts = cpContacts

			// let payload = {
			// 	id: this.getFetchedQBCustomer.id,
			// 	form: {
			// 		contacts: JSON.stringify(this.contacts)
			// 	}
			// }
			// if(!this.isValidEmail && !this.numberHasError) {
			// 	this.indexToEdit = ""
			// 	this.isEditing = false
			// 	this.updateCustomerContacts(payload)
			// }
		},
		handleAddConsignee() {
			this.consigneeDialog = true
		},
		getEmail(emails) {
			if(emails !== null) {
				let emailarr = emails.split(',')

				return emailarr[0]
			} else {
				return 'N/A'
			}
		},
		getAddress(address) {
			if(address !== null && address !== "") {
				return address
			} else {
				return 'N/A'
			}
		},
		getPrimaryContact(contacts) {
			if(contacts !== null && contacts.length > 0) {
				let parsedContacts = JSON.parse(contacts)

				let primary = parsedContacts.find(contact => {
					console.log('contact >>>', contact)
					if(contact.primary) {
						return contact
					}
				})

				console.log('p >>>', primary)
				if(primary !== undefined) {
					return `<span>${primary.contact_name}</span><br><span style="color: #6D858F">${primary.phone_number}</span>`
				} else {
					return 'N/A'
				}
			} else {
				return 'N/A'
			}
		},
		handleDeleteConsignee(item) {
			this.deleteDialog = true
			this.deletingConsignee = item
		},
		handleEditConsignee(item) {
			this.editDialog = true
			this.editingConsignee = item
		},
		handleConsigneeDetails(item) {
			// this.$router.push(`/consignee-details/${item.id}?customer=${this.$route.params.id}`)
			this.$router.push(`/consignee-details/${item.id}`)
		}
	},
	async mounted() {
		this.$store.dispatch("page/setPage", "customers");
		let data = this.getdetailsdata;
		if(typeof data.items !== 'undefined') {
			this.consignee = data.items.consignee.data;
			//this.databaheader = data.items.details.data;
			let itemreturn = this.drivercolumn(data.items.details.data);
			this.databaheader = itemreturn
			this.databaheaderConsignee = this.consignee
			
			//Object.values(this.databaheaderConsignee).map((value) => {
			//	this.allconsigneeobject.push(value.consignee)
			//});
			//this.databaheaderConsignee =  _.uniqBy(this.allconsigneeobject,'id');
			this.consignee = this.databaheaderConsignee;
			
			this.company_name_details = data.items.name;
			this.getphone = data.items.phone_number;
			this.getcompanykey = data.items.central_customer_key;
			this.getemailsdata = data.items.details.getemails;
			this.getHashId = data.items.hashId;
			this.allDataresponse = (data.items.lastreport.data !== '') ? new Date(data.items.lastreport.data).toLocaleString() : 'None';
			if(typeof data.items.getManageReportSetting.data[0] != 'undefined') {
				this.ON_TEXT = data.items.getManageReportSetting.data[0].status == 1 ? 'ON' : 'OFF';
				this.the_checkclass = data.items.getManageReportSetting.data[0].status == 1 ? 'reportscheduletag' : 'off_value';
			}
		}
		else {
			this.hidebutton = 0;
			await axios.get(`${APIBaseUrl}/customer/details/` + this.$route.params.id).then((response) => {
				return response;
			}).catch(error => {
				this.response = error;
			}).then(response => {
				if(typeof response != 'undefined') {
					//console.log(response);
					if(Object.keys(response.data).length) {
						this.driverdetails = (response.data.driver);
						let itemreturn = this.drivercolumn(response.data.data);
						this.databaheader = itemreturn
						this.databaheaderConsignee = response.data.consignee.data;
						
						//Object.values(this.databaheaderConsignee).map((value) => {				
						//this.allconsigneeobject.push(value.consignee)
						//});
						//this.databaheaderConsignee =  _.uniqBy(this.allconsigneeobject,'id');
						
						this.consignee = this.databaheaderConsignee;
						this.getcompanykey = response.data.company_key
						this.company_name_details = response.data.company_name;
						this.getHashId = response.data.hashId;
						this.getphone = response.data.company_phone;
						if(response.data.getemails.length) {
							this.getemailsdata = response.data.getemails[0].email
						}
						if(response.data.last_report_count) {
							//this.checkdisable = false
						}
						if(response.data.count) {
							//this.hasData = response.data.count
						}
						//alldata = response.data.data
					}
					// this.hidefirst = 1
				}
			})
			//await this.fetchLastSentReport(this.$route.params.id);
			var alldata = '';
			await axios.get(`${APIBaseUrl}/getlastreport/` + this.$route.params.id).then((response) => {
				return response;
			}).catch(error => {
				this.response = error;
			}).then(response => {
				if(typeof response != 'undefined') {
					if(Object.keys(response.data).length) {
						if(response.data.last_report_count) {
							this.checkdisable = false
						}
						if(response.data.count) {
							this.hasData = response.data.count
						}
						alldata = response.data.data
					}
					// this.hidefirst = 1
				}
			})
			this.allDataresponse = (alldata !== '') ? new Date(alldata).toLocaleString() : '';
		}

		//console.log(this.databaheaderConsignee);
		this.dataDialog.name = this.company_name_details
		this.dataDialog.email = this.getemailsdata
		// GET THE REPORT DATA SETTINGS
		await this.getManageReportSetting(this.$route.params.id);
		let dis = this;
		if(typeof this.getmanagereportschedule != 'undefined') {
			if(Object.keys(this.getmanagereportschedule).length > 0) {
				Object.values(this.getmanagereportschedule).map((value) => {
					try {
						if(value.length === 0) {
							dis.metavalue = {
								state: false,
								copy: false,
								frequency: 1,
								day: '',
								time: ''
							}
						}
						else {
							dis.metavalue = {
								state: (value[0].status) ? true : false,
								copy: value[0].copy ? true : false,
								frequency: value[0].frequency,
								day: value[0].day,
								time: value[0].time
							}
						}
						dis.ON_TEXT = dis.metavalue.state == true ? 'ON' : 'OFF';
						dis.the_checkclass = dis.metavalue.state == true ? 'reportscheduletag' : 'off_value';
						this.hidebutton = 1;
					}
					catch (error) {
						console.log(error)
					}
				});
			}
		}
	},
};
</script>
<style lang="scss">
.containerdetailheader.customer {
    background: none !important;
	height: auto !important;

    .v-toolbar__content {
        padding-left: 0 !important;
		height: auto !important;
		padding-bottom: 0 !important;

        .titleheader.customer {
            width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
        }
    }
}

.customer-wrapper {
	.v-data-table {
		&.table_customer.containers-table {
			box-shadow: none !important;
			border: 1px solid #EBF2F5 !important;
			border-bottom: none !important;

			.v-data-table__wrapper {
				overflow-y: inherit;
				overflow-x: hidden;
				height: calc(100vh - 338px);

				table {
					thead {
						tr {
							th {
								color: #819FB2 !important;
								font-size: 14px !important;
								padding: 8px 16px !important;
								height: 40px !important;
								box-shadow: none !important;
								border-bottom: 2px solid #EBF2F5 !important;

								span {
									color: #819FB2 !important;
									font-size: 14px !important;
								}
							}
						}
					}

					tbody {
						tr {
							td {
								border-bottom: 1px solid #EBF2F5 !important;
								padding: 10px 16px;
							}
						}
					}
				}
			}
		}
	}

	.pagination-wrapper {
		border: 1px solid #EBF2F5 !important;
	}
}

.details-contacts-table {
	div.v-data-table__wrapper {
		table {
			tbody {
				tr {
					td {
						// border-top: 1px solid rgb(235, 242, 245) !important;
						// border-right: 1px solid rgb(235, 242, 245) !important;
						// border-bottom: 1px solid rgb(235, 242, 245) !important;
						// border-left: none !important;
						// border-image: initial !important;
						padding: 0px 0px !important;
						height: 40px !important;
					}
				}
			}
		}
	}
}
.details-contacts-table {
	div.v-data-table__wrapper {
		table {
			tbody {
				tr:hover {
					background: #FFF !important;

					td {
						// border-top: 1px solid rgb(235, 242, 245) !important;
						// border-right: 1px solid rgb(235, 242, 245) !important;
						// border-bottom: 1px solid rgb(235, 242, 245) !important;
						// border-left: none !important;
						// border-image: initial !important;
						padding: 0px 0px !important;
						height: 40px !important;
					}
				}
			}
		}
	}
}

.v-data-table {
	&.consignee-table {
		.v-data-table__wrapper {
				overflow-y: auto !important;
				overflow-x: hidden !important;
				// height: 560px !important;
				height: calc(100vh - 402px) !important;
		}
	}
}

.titleheader.customer {
    font-size: 12px !important;
    margin-top: 4px;
    padding-left: 0;
	display: flex;
    justify-content: flex-start;
    align-items: center;
	height: 28px;

	.doprodownreport {
		padding-left: 8px;

		.reportscheduletag {
			display: flex;
			justify-content: center;
			align-items: center;
			background: #EBFAEF;
			padding: 4px 10px 4px 12px;
			border-radius: 50px;
			font-size: 10px;
			border: 1px solid #16B442;
			text-transform: uppercase;
			height: 28px;
			top: 0px !important;

			.scheduletext {
				color: #4A4A4A;
				font-family: 'Inter-SemiBold', sans-serif !important;
			}

			.ON_text {
				color: #16B442;
				margin-left: 5px;
				margin-right: 8px;
				font-family: 'Inter-SemiBold', sans-serif !important;
			}
		}

		.off_value {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #fff;
			padding: 4px 10px 4px 12px;
			border-radius: 50px;
			font-size: 10px;
			border: 1px solid #D8E7F0;
			text-transform: uppercase;
			height: 28px;
			top: 0px !important;

			.scheduletext {
				color: #4A4A4A;
				font-family: 'Inter-SemiBold', sans-serif !important;
			}

			.ON_text {
				color: #6D858F;
				margin-left: 5px;
				margin-right: 8px;
				font-family: 'Inter-SemiBold', sans-serif !important;
			}
		}
	}
}

.Headerbottomtext.customer {
    font-size: 12px !important;
    margin-top: 4px;
    padding-left: 0;
	display: flex;
    justify-content: flex-start;
    align-items: center;
	height: 28px;

	.doprodownreport {
		padding-left: 8px;

		.reportscheduletag {
			display: flex;
			justify-content: center;
			align-items: center;
			background: #EBFAEF;
			padding: 4px 10px 4px 12px;
			border-radius: 50px;
			font-size: 10px;
			border: 1px solid #16B442;
			text-transform: uppercase;
			height: 28px;

			.scheduletext {
				color: #4A4A4A;
				font-family: 'Inter-SemiBold', sans-serif !important;
			}

			.ON_text {
				color: #16B442;
				margin-left: 5px;
				margin-right: 8px;
				font-family: 'Inter-SemiBold', sans-serif !important;
			}
		}

		.off_value {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #fff;
			padding: 4px 10px 4px 12px;
			border-radius: 50px;
			font-size: 10px;
			border: 1px solid #D8E7F0;
			text-transform: uppercase;
			height: 28px;

			.scheduletext {
				color: #4A4A4A;
				font-family: 'Inter-SemiBold', sans-serif !important;
			}

			.ON_text {
				color: #6D858F;
				margin-left: 5px;
				margin-right: 8px;
				font-family: 'Inter-SemiBold', sans-serif !important;
			}
		}
	}
}

.telephoneholder-customer {
	padding-left: 5px;
	display: flex;
    justify-content: flex-start;
    align-items: center;

	img {
		width: 16px;
		height: 16px;
    }
}

.emailholder-customer {
	padding-left: 5px;
	display: flex;
    justify-content: flex-start;
    align-items: center;

	img {
		width: 17px;
		height: 17px;
    }
}

.titleheader {
    padding-left: 0;
}

.gobacknow {
    text-decoration: none !important;
    font-size: 14px;
}
.mdi-arrow-up {
    display: none !important;
}
.font-medium {
    color: #4A4A4A;
}
.containerID {
    color: #6D858F;
}
.classsort-icon {
    background: url("../../../assets/icons/Icon_sort.svg") no-repeat !important;
    width: 15px;
    height: 10px;
    display: block;
    // margin-left: 4px;
    // position: relative;
    // top: 3px;
    // left: 1px;
    // float: right;
}

.tabcontainers {
    border-bottom: 2px solid #EBF2F5;
}

.tabcontainers .title-tab {
	font-weight: unset !important;
}


.tabcontainers .title-tab.v-tab--active {
	color: #0171a1 !important;
	border-bottom: 2px solid #0171a1 !important;
}

.tabcontainers .v-tabs-slider-wrapper {
	display: none !important;
}

.title-tab {
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: none !important;
	letter-spacing: 0 !important;
	font-family: 'Inter-SemiBold', sans-serif !important;
}

.table_customer {
    margin-top: 0;
}

.table_customer table thead th {
    text-transform: none !important;
    font-size: 14px !important;
}
.shiflkey {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding-left: 6px;
    color: #4A4A4A;
}

.dropdownreport {
    padding: 2px 16px 0;
}
.headtextlist {
    font-size: 14px;
    color: #0171A1;
    cursor: pointer;
}
.listmenuborderline {
    border-bottom: 1px solid #eee;
    margin-bottom: 0;
    padding-bottom: 0;
    .list_item {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #6D858F;
        margin-bottom: 4px !important;
        padding-bottom: 6px;
    }
}
.listmenu {
    padding-left: 0 !important;
    .cmanage {
        cursor: pointer;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}
.cmon_item {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.numbericon {
    border-radius: 40px;
    background: rgb(1, 113, 161);
    color: rgb(255, 255, 255);
    width: 20px;
    margin-left: 6px;
    font-size: 12px;
	display: flex;
    justify-content: center;
    align-items: center;
}

.doprodownshow {
    display: inline-block;
}
.scheduled_tag {
    color: #6D858F;
    font-weight: 700;
    font-size: 14px;
}

.v-tooltip__content {
    background: #4A4A4A !important;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;

    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #4A4A4A transparent transparent transparent;

    }
}
.customer-detail-info {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #4A4A4A;
}
.head-text {
	font-size: 20px;
	font-weight: 600;
	line-height: 30px;
	letter-spacing: 0em;
	text-align: center;
}

.head-sub-text {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: center;
}

.details-contacts-table {
	.v-data-table__wrapper {
		overflow-y: hidden !important;
		height: max-content !important;
	}
}
</style>
<style>
.showhideme {
	display: inline-block !important;
}

.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #4A4A4A transparent transparent transparent;
}
.detail-item-label {
    color: #819FB2;
    font-size: 12px;
    text-transform: uppercase;
    font-family: 'Inter-SemiBold', sans-serif;
    margin-bottom: 6px;
}
.section-text-black {
	font-size: 20px;
	font-weight: 600;
	line-height: 30px;
	letter-spacing: 0em;
	text-align: left;
    color: #4A4A4A;
}
.checkbox-text {
	font-size: 14px;
	color: #4A4A4A;
	max-width: 60%;
}
.contacts-update-btn {
	width: 60px;
    height: 30px !important;
    padding: 6px 8px 6px 8px;
    border-radius: 4px;
    border: 1px;
    background-color: #ffffff !important;
    color: #0171A1 !important;
    border: 1px solid #B4CFE0 !important;
    font-size: 12px !important;
    text-transform: capitalize;
    letter-spacing: 0;
    box-shadow: none !important;
    font-family: "Inter-Regular", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
}
.contacts-save-btn {
	width: 60px;
    height: 30px !important;
    padding: 6px 8px 6px 8px;
    border-radius: 4px;
    border: 1px;
    background-color: #ffffff !important;
    color: #16B442 !important;
    border: 1px solid #B4CFE0 !important;
    font-size: 12px !important;
    text-transform: capitalize;
    letter-spacing: 0;
    box-shadow: none !important;
    font-family: "Inter-Regular", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
}
.contacts-del-btn {
	width: 60px;
    height: 30px !important;
    border-radius: 4px;
    border: 1px;
    background-color: #ffffff !important;
    color: #F93131 !important;
    border: 1px solid #B4CFE0 !important;
    font-size: 12px !important;
    text-transform: capitalize;
    letter-spacing: 0;
    box-shadow: none !important;
    font-family: "Inter-Regular", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
}
.consignee-update-btn {
	min-width: 34px !important;
    height: 34px !important;
    border-radius: 4px;
    background-color: #ffffff !important;
    color: #0171A1 !important;
    border: 1px solid #B4CFE0 !important;
    font-size: 12px !important;
    text-transform: capitalize;
    letter-spacing: 0;
    box-shadow: none !important;
    font-family: "Inter-Regular", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
}
.consignee-del-btn {
	min-width: 34px !important;
    height: 34px !important;
    padding: 6px 8px 6px 8px;
    border-radius: 4px;
    background-color: #ffffff !important;
    color: #F93131 !important;
    border: 1px solid #B4CFE0 !important;
    font-size: 12px !important;
    text-transform: capitalize;
    letter-spacing: 0;
    box-shadow: none !important;
    font-family: "Inter-Regular", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
}
.add-consignee-btn {
    height: 40px !important;
    padding: 6px 8px 6px 8px;
    border-radius: 4px;
    background-color: #ffffff !important;
    color: #0171A1 !important;
    border: 1px solid #B4CFE0 !important;
    font-size: 12px !important;
    text-transform: capitalize;
    letter-spacing: 0;
    box-shadow: none !important;
    font-family: "Inter-Regular", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
	left: -10px;
}
.qbContactsDetails.v-text-field.v-text-field--solo .v-input__control {
    min-height: 40px;
    padding: 0;
	font-size: 14px;
}
.v-text-fields.select .vs__dropdown-toggle, .text-end .vue-tel-input {
    min-height: 40px;
    border: none !important;
    font-size: 14px;
    background-color: #fff !important;
}
.primary-badge {
	font-size: 10px;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0em;
	text-align: left;
	padding: 3px 4px 3px 4px;
	border-radius: 99px;
	color: #16B442;
	background: #EBFAEF;
}
</style>
