<template>
  <div class="create-do-form-wrapper">
    <v-dialog
      v-model="openDialogComCreate"
      fullscreen
      persistent
      scrollable
      transition="dialog-bottom-transition"
      :content-class="`dialogClassDos create-do popupdialogDos ${pageClass}`"
      no-click-animation
    >
      <v-card>
        <v-card-title>
          <span class="headline">Create Delivery Order</span>
          <button icon dark class="btn-close" @click="closedialog">
            <v-icon>mdi-close</v-icon>
          </button>
        </v-card-title>

        <v-card-text class="pa-0">
          <div class="container-create pb-0">
            <v-progress-circular
              :size="15"
              color="#fff"
              :width="2"
              indeterminate
              v-if="startloading"
              style="margin-right: 3px"
            >
            </v-progress-circular>

            <v-form
              class="sFormClass"
              style="padding: 0px !important; width: 100%"
              id="sform"
              ref="form"
              v-model="valid"
              @submit.prevent="submit"
            >
              <!-- style="padding: 0px !important; width: 99%;" -->

              <div class="d-flex">
                <div
                  class="leftcol"
                  style="
                    width: 790px !important;
                    min-width: 650px !important;
                    max-width: 790px !important;
                    padding: 16px 20px !important;
                    border-right: 1px solid #d8e7f0 !important;
                  "
                >
                  <v-row>
                    <v-col
                      class="etacol pb-0 mt-0"
                      cols="6"
                      md="6"
                      id="eta-col-data"
                    >
                      <label class="text-item-label" for=""> ETA </label>
                      <div class="vc-date-picker__wrapper">
                        <!-- <DxDateBox
                      placeholder="Select ETA"
                      class="create-do-eta-dxdate mt-1"
                      v-model="date"
                      type="date"
                      :openOnFieldClick="true"
                      @value-changed="handleETAValueChange"
                      :use-mask-behavior="true" 
                      display-format="yyyy-MM-dd" /> -->

                        <div class="vc-datepicker-with-clear">
                          <vc-date-picker
                            title-position="left"
                            is-expanded
                            v-model="date"
                            mode="date"
                            :popover="{ visibility: 'focus' }"
                            :firstDayOfWeek="2"
                            :masks="masks"
                            :select-attribute="attribute"
                            @input="(e) => checkErrorShowing(e, 'eta')"
                            ref="eta_ref_do"
                            :is-required="isDateRequired"
                          >
                            <template #default="{ inputValue, inputEvents }">
                              <input
                                :value="inputValue"
                                v-on="inputEvents"
                                class="create-do-eta-dxdate-vc"
                                placeholder="Select ETA"
                                @keyup="(e) => checkKeyUpVal(e)"
                              />
                            </template>
                          </vc-date-picker>

                          <button
                            type="button"
                            class="vc-date-clear-button"
                            @click="clearDate"
                            v-if="date !== null"
                          >
                            <v-icon size="18px" color="#F93131"
                              >mdi-close</v-icon
                            >
                          </button>
                        </div>

                        <p class="p-form-errors" v-if="isShowETAError">
                          ETA is required
                        </p>

                        <!-- <v-text-field
											v-model="date"
											placeholder="05/21/2021"
											append-icon="ic-calendar"
											:rules="validateDaterules"
											name="ETA"
											@click="showcustom(date, 'date')"
											@click:append="showcustom(date, 'date')"
											hide-details="auto"
											class="create-do-eta"
										>
										</v-text-field> -->
                      </div>
                    </v-col>
                    <v-col cols="6" md="6" class="pb-0 mt-0">
                      <label class="text-item-label" for=""> MBL # </label>
                      <v-text-field
                        placeholder="Enter MBL Number"
                        v-model="mbl"
                        name="mbl_num"
                        hide-details="auto"
                        @keyup="blurevent"
                        @input="(e) => checkErrorShowing(e, 'mbl')"
                        autocomplete="off"
                      >
                      </v-text-field>
                      <p class="p-form-errors" v-if="isShowMBLError">
                        MBL is required
                      </p>
                      <p class="p-form-errors" v-else-if="checkfieldExist">
                        There is an existing Delivery Order with the MBL#
                      </p>
                      <!-- :rules="mblRules" -->
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" md="6" class="selectBox">
                      <label class="text-item-label" for=""> Carrier </label>
                      <v-autocomplete
                        v-model="CarrierModel"
                        :items="carrierDropdown"
                        item-text="name"
                        item-value="id"
                        placeholder="Select Carrier"
                        name="carrier_id"
                        @mousedown="dataDown('CARRIER')"
                        @keyup="blurevent"
                        append-icon="ic-chevron-down"
                        return-object
                        hide-details="auto"
                        class="icon-append-custom mt-1"
                        attach
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="6" md="6">
                      <label class="text-item-label" for=""> Vessel </label>
                      <v-text-field
                        placeholder="Vessel"
                        v-model="VesselModel"
                        name="vessel_name"
                        hide-details="auto"
                        @keyup="blurevent"
                        autocomplete="off"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="selectBox pt-0" cols="12" sm="12">
                      <label class="text-item-label" for="">
                        CUSTOMER NAME
                      </label>
                      <v-autocomplete
                        v-if="iscustomer"
                        v-model="customerdropdown"
                        :items="itemsDropdownComputed"
                        item-text="company_name"
                        item-value="id"
                        append-icon="ic-chevron-down"
                        class="customerDropdown mt-1 icon-append-custom"
                        :search-input.sync="getSearchCompany"
                        return-object
                        hide-details="auto"
                        placeholder="Enter Customer Name"
                        attach
                        @input="(e) => checkErrorShowing(e, 'customer_name')"
                        :menu-props="{
                          contentClass: 'consignee-menu-lists-do',
                        }"
                        @change="onSelectCustomer(customerdropdown)"
                      >
                        <!-- :rules="customerRules" -->
                        <!--
														<template slot="item" slot-scope="{ item }">
															<template v-if="typeof item == 'object'">
																<div @click="createcustomer(item.company_name)"
																	class="listDropdown"
																	style="width:100%"
																	v-if="item.id===999999">
																	<span style="font-size:15px;">
																		<span style="color:#b3c7d3">{{ item.company_name }} </span>
																		<span class="createClass">Create Customer</span>
																	</span>
																</div>
																<div class="listDropdown" v-else>
																{{ item.company_name }}
																</div>
															</template>
														</template>
													-->
                        <div
                          slot="no-data"
                          style="font-size: 14px"
                          class="px-4 py-3"
                        >
                          No data available
                        </div>
                        <div
                          style="font-size: 14px"
                          v-if="hasNewChassis"
                          class="v-list-item v-list-item__title add-option-btn"
                          slot="append-item"
                          @click="createcustomer()"
                        >
                          <span style="color: #0171a1 !important">Add New</span>
                        </div>
                      </v-autocomplete>
                      <p class="p-form-errors" v-if="isShowCustomerError">
                        Customer is required
                      </p>
                    </v-col>
                  </v-row>

                  <v-row class="mt-4">
                    <v-col class="selectBox pt-0" cols="12" sm="12">
                      <label class="text-item-label" for=""> CONSIGNEE </label>
                      <v-autocomplete
                        ref="consigneeRef"
                        class="select-consignee icon-append-custom"
                        v-model="selectedConsignee"
                        :items="consigneeLists"
                        item-text="name"
                        item-value="id"
                        :placeholder="
                          getAllConsigneesLoading
                            ? 'Fetching consignees...'
                            : 'Select Consignee'
                        "
                        append-icon="ic-chevron-down"
                        :search-input.sync="searchedConsignee"
                        :menu-props="{
                          contentClass: 'consignee-menu-lists-do',
                        }"
                        :disabled="
                          getCreateConsigneeLoading || getAllConsigneesLoading
                        "
                        attach
                        hide-details="auto"
                      >
                        <div slot="no-data">
                          <p
                            class="px-4 py-3 mb-0"
                            style="font-size: 14px; color: #4a4a4a"
                            v-if="
                              !hasNewConsignee || consigneeLists.length === 0
                            "
                          >
                            No available data
                          </p>
                        </div>

                        <template slot="item" slot-scope="{ item }">
                          <v-list-item-content>
                            <p
                              class="consignee-name mb-0"
                              style="font-size: 14px; color: #4a4a4a"
                            >
                              {{ item.name }}
                            </p>
                          </v-list-item-content>
                        </template>

                        <div
                          class="v-list-item v-list-item__title add-option-btn px-3 py-1"
                          style="
                            min-height: 35px;
                            border-top: 1px solid #ebf2f5 !important;
                          "
                          slot="append-item"
                          @click="handleNewConsignee"
                          v-if="
                            hasNewConsignee &&
                            searchedConsignee !== '' &&
                            customerdropdown !== null &&
                            customerdropdown !== ''
                          "
                        >
                          <span
                            style="font-size: 14px; color: #0171A1; !important;"
                          >
                            {{
                              getCreateConsigneeLoading
                                ? "Adding..."
                                : "+ Add New"
                            }}
                            <span
                              v-if="searchedConsignee"
                              style="color: #4a4a4a"
                              >- {{ searchedConsignee }}</span
                            >
                          </span>
                        </div>
                      </v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="selectBox pt-1 pb-1" cols="12" md="12">
                      <label class="text-item-label">
                        Customer Reference #
                      </label>

                      <div class="tags-email-wrapper">
                        <vue-tags-input
                          hide-details="auto"
                          :tags="optionsReferrence"
                          :add-on-blur="true"
                          :class="myclass2"
                          class="DialogInputfield mt-1"
                          :add-on-key="[13, ',']"
                          :validation="
                            myclass.length > 0
                              ? tagsValidation2
                              : tagsValidation2
                          "
                          v-model="customerReferrenceNumber"
                          placeholder="Enter Reference Number"
                          @tags-changed="
                            (newTags) => {
                              this.myclass2 = 'noclass';
                              this.optionsReferrence = newTags;
                              this.tagsInput2.touched = true;
                              this.tagsInput2.hasError =
                                this.optionsReferrence.length > 0
                                  ? false
                                  : true;
                              let el =
                                this.documentProto.getElementsByClassName(
                                  'ti-input'
                                )[0];
                            }
                          "
                        />
                        <!-- :rules="arrayNotEmptyRulesRefference" -->
                      </div>
                      <span style="color: #819fb2; font-size: 12px">
                        <i style="font-size: 12px; color: #819fb2">
                          Separate multiple referrence # with comma
                        </i>
                      </span>
                      <div style="line-heigh: 12px; height: 12px">
                        <div
                          v-if="tagsInput2.touched && tagsInput2.hasError"
                          class="v-text-field__details"
                        >
                          <div
                            class="v-messages theme--light error--text"
                            role="alert"
                          >
                            <div class="v-messages__wrapper">
                              <div
                                v-if="
                                  optionsReferrence.length > 0 &&
                                  customerReferrenceNumber !== ''
                                "
                                class="v-messages__message"
                              >
                                {{ tagsInput2.errorMessage }}
                              </div>

                              <div
                                v-if="
                                  optionsReferrence.length == 0 &&
                                  customerReferrenceNumber !== ''
                                "
                                class="v-messages__message"
                              >
                                {{ tagsInput2.errorMessage }}
                              </div>
                              <!-- <div
                          v-if="
                          optionsReferrence.length == 0 &&
                          warehouseEmailAddress == ''
                          "
                          class="v-messages__message"
                          >
                          Please provide at least 1 valid refrence number.
                          </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="selectBox pt-1" cols="12" md="12">
                      <label class="text-item-label"> Customer Contacts </label>
                      <div class="tags-email-wrapper">
                        <vue-tags-input
                          hide-details="auto"
                          :tags="options"
                          :add-on-blur="true"
                          :class="myclass"
                          class="DialogInputfield mt-1"
                          :add-on-key="[13, ',']"
                          :validation="
                            myclass.length > 0 ? tagsValidation : tagsValidation
                          "
                          v-model="warehouseEmailAddress"
                          placeholder="Enter Email Address"
                          @tags-changed="
                            (newTags) => {
                              this.myclass = 'noclass';
                              this.options = newTags;
                              this.tagsInput.touched = true;
                              this.tagsInput.hasError =
                                this.options.length > 0 ? false : true;
                              let el =
                                this.documentProto.getElementsByClassName(
                                  'ti-input'
                                )[0];
                              if (typeof el !== 'udnefined') {
                                /*if (this.tagsInput.hasError)
															el.classList.add('ti-new-tag-input-error')
														else
															el.classList.remove('ti-new-tag-input-error')*/
                              }
                            }
                          "
                        />
                      </div>
                      <!-- :rules="arrayNotEmptyRules" -->
                      <span style="color: #819fb2; font-size: 12px">
                        <i style="font-size: 12px; color: #819fb2">
                          Separate multiple email addresses with comma
                        </i>
                      </span>

                      <div style="line-heigh: 12px; height: 12px">
                        <div
                          v-if="tagsInput.touched && tagsInput.hasError"
                          class="v-text-field__details"
                        >
                          <div
                            class="v-messages theme--light error--text"
                            role="alert"
                          >
                            <div class="v-messages__wrapper">
                              <div
                                v-if="
                                  options.length > 0 &&
                                  warehouseEmailAddress !== ''
                                "
                                class="v-messages__message"
                              >
                                {{ tagsInput.errorMessage }}
                              </div>

                              <div
                                v-if="
                                  options.length == 0 &&
                                  warehouseEmailAddress !== ''
                                "
                                class="v-messages__message"
                              >
                                {{ tagsInput.errorMessage }}
                              </div>
                              <!-- <div
                          v-if="options.length == 0 && warehouseEmailAddress == ''"
                          class="v-messages__message"
                          >
                          Please provide at least 1 valid email address.
                          </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="row-locations rowRemovemargin mt-0 mb-4">
                    <div class="col-xs-6 col-md-6 pb-0 box">
                      <label class="text-item-label" for="">
                        Container Starting Location
                        <span
                          @click="custom_seach"
                          style="
                            font-weight: bold;
                            float: right;
                            position: relative;
                            height: 12px;
                          "
                        >
                          <span
                            style="border-bottom: 0px !important"
                            class="qtip tip-top"
                            :data-tip="
                              switchtostartinglocation
                                ? 'Switch to terminal'
                                : 'Switch to custom address search'
                            "
                          >
                            <svg
                              width="20px"
                              height="24px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0" />
                              <g
                                id="SVGRepo_tracerCarrier"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  d="M21 9L9 9"
                                  stroke="#819fb2"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M15 15L3 15"
                                  stroke="#819fb2"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M18 12L20.913 9.08704V9.08704C20.961 9.03897 20.961 8.96103 20.913 8.91296V8.91296L18 6"
                                  stroke="#819fb2"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M6 18L3.08704 15.087V15.087C3.03897 15.039 3.03897 14.961 3.08704 14.913V14.913L6 12"
                                  stroke="#819fb2"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                            </svg>
                          </span>
                        </span>
                      </label>

                      <v-autocomplete
                        v-if="this.switchtostartinglocation == 0"
                        v-model="terminalAt"
                        :items="terminalsDropdown"
                        :loading="loadingStartingLocationPlaces"
                        placeholder="Search/Enter Terminal"
                        item-text="name"
                        item-value="id"
                        clearable
                        hide-no-data
                        class="Goodsdeliverylocation container-starting-location mt-0"
                        name="custom_notes"
                        return-object
                        hide-details="auto"
                        :filter="onFilter"
                        attach
                        @focus="
                          (e) => handleStartingFocused('startingLocation')
                        "
                        @blur="
                          (e) =>
                            handleStartingBlur(
                              'startingLocation',
                              'Search/Enter Terminal'
                            )
                        "
                      >
                        <!-- :rules="TerminalAtRules"	 -->
                        <!-- <template slot="selection" slot-scope="{ item }">
                          <div style="color: #4a4a4a; font-size: 14px; line-height: 20px;">
                            {{ item.firms_code }} | {{ item.name }} | {{ item.map_location }}
                          </div>
                        </template> -->

                        <template slot="item" slot-scope="{ item }">
                          <template v-if="typeof item == 'object'">
                            <div class="listDropdown" style="font-size: 12px">
                              {{ item.firms_code }}
                              <span class="strtinglocationh"></span>
                              {{ item.name }}
                              <span class="strtinglocationh"></span>
                              {{ item.map_location }}
                            </div>
                          </template>
                        </template>
                      </v-autocomplete>
                      <v-autocomplete
                        v-else
                        v-model="starting_location_address"
                        :items="terminalsDropdown"
                        :loading="loadingStartingLocationPlaces"
                        placeholder="Search Address"
                        :search-input.sync="searchPlaceStaringLocation"
                        @click:clear="clearSearchPlacesStartingLocation"
                        item-text="place_name"
                        clearable
                        hide-no-data
                        class="Goodsdeliverylocation container-starting-location mt-0"
                        name="custom_notes"
                        return-object
                        hide-details="auto"
                        attach
                      >
                      </v-autocomplete>
                    </div>
                    <div class="col-xs-6 col-md-6 pb-0 box">
                      <label class="text-item-label"
                        >Goods Delivery Location</label
                      >
                      <v-autocomplete
                        :loading="loadingLocationPlaces"
                        v-model="delivery_location"
                        :items="places"
                        :search-input.sync="searchPlace"
                        placeholder="Search/Enter Address"
                        item-text="place_name"
                        clearable
                        hide-no-data
                        @click:clear="clearSearchPlaces"
                        @change="changeDeliveryLocationState"
                        filled
                        full-width
                        class="Goodsdeliverylocation mt-0"
                        name="custom_notes"
                        hide-details="auto"
                        attach
                        @input="
                          (e) => checkErrorShowing(e, 'delivery_location')
                        "
                        @focus="(e) => handleFocused('deliveryLocation')"
                        @blur="
                          (e) =>
                            handleBlur(
                              'deliveryLocation',
                              'Search/Enter Address'
                            )
                        "
                      >
                        <!-- <template slot="selection" slot-scope="{ item }">
                          <div style="color: #4a4a4a; font-size: 14px; line-height: 20px;">
                            {{ item.place_name }}
                          </div>
                        </template> -->
                      </v-autocomplete>
                      <p class="p-form-errors" v-if="isShowDeliveryError">
                        Input is required
                      </p>
                    </div>
                  </v-row>
                  <v-row class="rowRemovemargin">
                    <v-col class="selectBox pt-0" cols="12" md="12">
                      <label class="text-item-label" for="">
                        Description of Articles, Special Marks & Exceptions
                      </label>
                      <v-textarea
                        placeholder="Enter Description Of Articles, Special Marks & Exceptions"
                        v-model="container_commodity"
                        rows="3"
                        name="container_commodity"
                        hide-details="auto"
                        class="textarea-commodity"
                        @keyup="blurevent"
                      >
                      </v-textarea>

                      <!-- <label class="text-item-label" for="">
											Commodity Description
										</label>
										<v-text-field
											placeholder="Enter Container Commodity"
											required
											v-model="container_commodity"
											name="mbl_num"
											hide-details="auto"
											@keyup="blurevent"
										>
										</v-text-field> -->
                    </v-col>
                  </v-row>
                  <v-row class="notesrow mt-0">
                    <v-col class="textareanotes" cols="12" md="12">
                      <label class="text-item-label" for="">
                        Notes <span class="text-capitalize">(Optional)</span>
                      </label>
                      <v-textarea
                        v-model="notembl"
                        :rules="noterules"
                        placeholder="Type Note..."
                        rows="3"
                        name="custom_notes"
                        class="textarea-commodity"
                        hide-details="auto"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <v-row class="notesrow mt-0"> </v-row>
                  <v-row class="pt-2 pb-2">
                    <v-col cols="12" md="12">
                      <v-form ref="containerForm">
                        <!-- <h4 style="padding-left:6px; font-size: 16px; font-family: 'Inter-Medium', sans-serif;">Containers</h4> -->
                        <div class="milestone-create-container333">
                          <div style="color: red; height: 20px">
                            <ul class="errorList">
                              <li>{{ errorMessagetext["container"] }}</li>
                              <!-- <li>{{errorMessagetext['Volume']}}</li>
                              <li>{{errorMessagetext['Weight']}}</li>
                              <li>{{errorMessagetext['Quantity']}}</li>
                              <li>{{errorMessagetext['Sizes']}}</li> -->
                            </ul>
                          </div>
                          <div class="mainWrapperListContainers">
                            <div class="col-md-12 px-0">
                              <v-data-table
                                v-model="selected"
                                class="add-containers-table containerListingTable create-do"
                                :headers="headers"
                                :items="containerList"
                                :page.sync="page"
                                :items-per-page="5"
                                hide-default-footer
                                fixed-header
                                dense
                                v-if="reload"
                                disable-pagination
                              >
                                <!-- <template v-slot:header.data-table-select="">
																		<v-checkbox
																			value="0"
																			style="position: relative;top: 4px;"
																			@change="getcheckall"
																			class="text-fields"
																			v-model="checkboxall"
																		>
																		</v-checkbox>
																</template> -->

                                <template v-slot:body="{ items }">
                                  <tbody>
                                    <tr
                                      class="listing_table"
                                      v-for="(item, index) in items"
                                      :key="index"
                                    >
                                      <!-- <td v-if="showselect" width="4%">
																				<v-checkbox
																				:value="item.checkbox"
																				@change="checkselected()"
																				class="text-fields checkboxrow"
																				v-model="item.checkbox"
																				></v-checkbox>
																			</td> -->
                                      <td
                                        class="text-start"
                                        style="
                                          border-left: 1px solid #ebf2f5;
                                          border-bottom: 1px solid #ebf2f5;
                                        "
                                      >
                                        <div style="position: relative">
                                          <!-- {{item.container_number}} -->
                                          <v-text-field
                                            type="text"
                                            placeholder="Input Container #"
                                            class="text-fields"
                                            @keyup="
                                              validateContainerNumber(
                                                index,
                                                item.container_number
                                              )
                                            "
                                            full-width
                                            v-model="item.container_number"
                                          />
                                          <div
                                            style="
                                              position: absolute;
                                              top: 9px;
                                              left: 128px;
                                              display: none;
                                            "
                                            :class="`ContainerTooltip_${index}`"
                                          >
                                            <div class="tooltipContainer">
                                              <div class="right">
                                                <div class="text-content">
                                                  Invalid container # Ex:
                                                  QWER1234567
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td class="text-end cl">
                                        <!-- {{ item.container_size }}  -->
                                        <v-select
                                          v-model="item.container_size"
                                          :items="itemsDropdownSize"
                                          item-text="name"
                                          item-value="id"
                                          placeholder="Select Size"
                                          class="text-fields fieldClass sizeClass"
                                          append-icon="ic-chevron-down"
                                        ></v-select>
                                        <!-- @input="validatesize(item.container_size)" -->
                                      </td>
                                      <td class="text-end cl">
                                        <!-- {{ item.container_weight }} -->
                                        <v-text-field
                                          type="number"
                                          placeholder="0"
                                          class="text-fields text-input-end"
                                          full-width
                                          v-model="item.container_weight"
                                          suffix="KG"
                                        >
                                          <!-- @keyup="checkvalue(item.container_weight,'Weight')" -->

                                          <!-- <template slot="append">
																						<span style="font-size: 14px;color: #819FB2;">KG</span>
																					</template> -->
                                        </v-text-field>
                                        <!-- <input class="cinput_containers" type="text" @blur="checkvalue(item.container_weight)" v-model="item.container_weight"><SPAN>kg</SPAN>
																				-->
                                      </td>
                                      <td class="text-end cl">
                                        <!-- {{ item.container_volume }} -->
                                        <v-text-field
                                          type="number"
                                          placeholder="0"
                                          class="text-fields text-input-end"
                                          full-width
                                          v-model="item.container_volume"
                                          suffix="CBM"
                                        >
                                          <!-- @keyup="checkvalue(item.container_volume,'Volume')" -->

                                          <!-- <template slot="append">
																						<span style="font-size: 14px;color: #819FB2;">CBM</span>
																					</template> -->
                                        </v-text-field>
                                        <!-- <input class="cinput_containers" type="text" @blur="checkvalue(item.container_volume)" v-model="item.container_volume"><span>cbm</span> -->
                                      </td>
                                      <td class="text-end cl">
                                        <!-- {{ item.container_qty }} -->
                                        <v-text-field
                                          type="number"
                                          placeholder="0"
                                          class="text-fields text-input-end"
                                          full-width
                                          v-model="item.container_qty"
                                          suffix="UNIT"
                                        >
                                          <!-- @keyup="checkvalue(item.container_qty,'Quantity')" -->

                                          <!-- <template slot="append">
																						<span style="font-size: 14px;color: #819FB2;">UNIT</span>
																					</template> -->
                                        </v-text-field>
                                        <!-- <input class="cinput_containers" type="text" @blur="checkvalue(item.container_qty)" v-model="item.container_qty"> -->
                                      </td>
                                      <td
                                        class="text-center"
                                        style="
                                          border-right: 0px !important;
                                          border-bottom: none !important;
                                        "
                                      >
                                        <span
                                          @click="removeContainer(index)"
                                          style="color: red; cursor: pointer"
                                        >
                                          <svg
                                            width="12"
                                            height="12"
                                            viewBox="0 0 12 12"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M11.1527 0.846318C11.3932 1.08682 11.3932 1.47674 11.1527 1.71724L6.87069 5.99928L11.1527 10.2813C11.3932 10.5218 11.3932 10.9117 11.1527 11.1522C10.9122 11.3927 10.5223 11.3927 10.2818 11.1522L5.99977 6.8702L1.71773 11.1522C1.47723 11.3927 1.0873 11.3927 0.846806 11.1522C0.606308 10.9117 0.606308 10.5218 0.846807 10.2813L5.12884 5.99928L0.846807 1.71724C0.606308 1.47674 0.606308 1.08682 0.846807 0.846318C1.08731 0.605819 1.47723 0.605819 1.71773 0.846318L5.99977 5.12835L10.2818 0.846318C10.5223 0.605819 10.9122 0.605819 11.1527 0.846318Z"
                                              fill="#F93131"
                                            />
                                          </svg>
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-if="isAdding">
                                    <tr class="addingnewrow">
                                      <!-- <td v-if="showselect" width="4%">
																				<v-checkbox

																				:value="checkboxitem"
																				class="text-fields checkboxrow"
																				v-model="checkboxcontainers"
																				></v-checkbox>
																			</td> -->
                                      <td
                                        class="text-end"
                                        style="
                                          border-right: 1px solid #ebf2f5 !important;
                                        "
                                      >
                                        <div style="position: relative">
                                          <v-text-field
                                            type="text"
                                            placeholder="Input Container #"
                                            class="text-fields"
                                            v-model="
                                              containers.container_number
                                            "
                                            @keyup="
                                              validateContainerNumber(
                                                99,
                                                containers.container_number
                                              )
                                            "
                                            full-width
                                          />
                                          <div
                                            style="
                                              position: absolute;
                                              top: 9px;
                                              left: 128px;
                                              display: none;
                                            "
                                            :class="`ContainerTooltip_99`"
                                          >
                                            <div class="tooltipContainer">
                                              <div class="right">
                                                <div class="text-content">
                                                  Invalid container # Ex:
                                                  QWER1234567
                                                </div>
                                              </div>
                                              <i></i>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        class="text-end"
                                        style="
                                          border-right: 1px solid #ebf2f5 !important;
                                          border-left: none !important;
                                        "
                                      >
                                        <v-select
                                          v-model="containers.container_size"
                                          :items="itemsDropdownSize"
                                          item-text="name"
                                          placeholder="Select Size"
                                          item-value="id"
                                          class="text-fields fieldClass sizeClass"
                                          append-icon="ic-chevron-down"
                                        ></v-select>
                                        <!-- @change="validatesize(containers.container_size)" -->
                                      </td>
                                      <td
                                        class="text-end"
                                        style="
                                          border-right: 1px solid #ebf2f5 !important;
                                          border-left: none !important;
                                        "
                                      >
                                        <v-text-field
                                          type="number"
                                          placeholder="0"
                                          class="text-fields text-input-end"
                                          full-width
                                          v-model="containers.container_weight"
                                          suffix="KG"
                                        >
                                          <!-- @keyup="checkvalue(containers.container_weight,'Weight')" -->
                                          <!-- <template slot="append">
																							<span style="font-size: 14px;color: #819FB2;">KG</span>
																						</template> -->
                                        </v-text-field>
                                      </td>
                                      <td
                                        class="text-end"
                                        style="
                                          border-right: 1px solid #ebf2f5 !important;
                                          border-left: none !important;
                                        "
                                      >
                                        <v-text-field
                                          type="number"
                                          placeholder="0"
                                          class="text-fields text-input-end"
                                          full-width
                                          v-model="containers.container_volume"
                                          suffix="CBM"
                                        >
                                          <!-- @keyup="checkvalue(containers.container_volume,'Volume')" -->
                                          <!-- <template slot="append">
																							<span style="font-size: 14px;color: #819FB2;">CBM</span>
																						</template> -->
                                        </v-text-field>
                                      </td>
                                      <td
                                        class="text-end"
                                        style="
                                          border-right: 1px solid #ebf2f5 !important;
                                          border-left: none !important;
                                        "
                                      >
                                        <v-text-field
                                          type="number"
                                          placeholder="0"
                                          class="text-fields quantifyFields text-input-end"
                                          full-width
                                          v-model="containers.container_qty"
                                          suffix="UNIT"
                                        >
                                          <!-- @keyup="checkvalue(containers.container_qty,'Quantity')" -->
                                          <!-- <template slot="append">
																							<span style="font-size: 14px;color: #819FB2;">UNIT</span>
																						</template> -->
                                        </v-text-field>
                                      </td>
                                      <td
                                        style="
                                          border-bottom: 0px !important;
                                          border-left: none !important;
                                        "
                                        class="text-end"
                                      >
                                        <span
                                          @click="cancelAdding"
                                          style="
                                            color: grey;
                                            cursor: pointer;
                                            position: relative;
                                            top: 0px;
                                          "
                                        >
                                          <svg
                                            width="12"
                                            height="12"
                                            viewBox="0 0 12 12"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M11.1528 0.846389C11.3933 1.08689 11.3933 1.47681 11.1528 1.71731L6.87076 5.99935L11.1528 10.2814C11.3933 10.5219 11.3933 10.9118 11.1528 11.1523C10.9123 11.3928 10.5224 11.3928 10.2819 11.1523L5.99984 6.87027L1.7178 11.1523C1.4773 11.3928 1.08738 11.3928 0.846878 11.1523C0.606379 10.9118 0.606379 10.5219 0.846878 10.2814L5.12891 5.99935L0.846879 1.71731C0.60638 1.47681 0.60638 1.08689 0.846878 0.846389C1.08738 0.605891 1.4773 0.605891 1.7178 0.846389L5.99984 5.12843L10.2819 0.846389C10.5224 0.605891 10.9123 0.605891 11.1528 0.846389Z"
                                              fill="#B4CFE0"
                                            />
                                          </svg>
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-data-table>
                              <div class="d-flex" style="width: 100%">
                                <div
                                  style="cursor: pointer; width: 27% !important"
                                  @click="addCOntainer"
                                  class="addcontainer"
                                >
                                  + Add Container
                                </div>
                                <div style="width: 20%"></div>
                                <div style="width: 15%"></div>
                                <div style="width: 15%"></div>
                                <div style="width: 15%"></div>
                                <div style="width: 8%"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-form>
                    </v-col>
                  </v-row>
                </div>

                <div
                  class="uploadDocuwrapper colright mt-0"
                  style="
                    width: calc(100% - 790px) !important;
                    max-width: calc(100% - 790px) !important;
                    padding: 16px 0 0 !important;
                  "
                >
                  <div class="deliveryOrderColumn px-4">
                    <!-- <div class="mt-3"
										style="
											padding-bottom: 7px;
											width:48.7% !important;
											color: #4a4a4a;
											font-size: 16px;
											font-weight: 600;
										"
										>Document</div> -->
                    <small
                      style="color: red; font-size: 12px; margin-left: 5px"
                      v-if="errorFile"
                    >
                      Invalid File Type
                    </small>
                    <span style="display: none" class="fileuploaderror"> </span>
                    <div
                      style="display: block"
                      class="uploadElement flex w-full h-screen items-left justify-left text-left mt-0"
                      id="app"
                    >
                      <div
                        style="width: 100%; background: #fff !important"
                        :class="hasContent"
                        xclass="cDownloadfiles"
                        @dragover="dragover"
                        @dragleave="dragleave"
                        @drop="drop"
                      >
                        <input
                          style="display: none"
                          type="file"
                          name="files[]"
                          id="assetsFieldHandlecreate"
                          class="w-px h-px opacity-0 overflow-hidden absolute"
                          @change="onChangeCreate"
                          ref="fileCreate"
                          accept="application/pdf"
                        />

                        <label
                          for="assetsFieldHandlecreate"
                          class="block cursor-pointer triggerelementCreate"
                        >
                          <div class="browserInput2 dragfileElement">
                            <div class="browseFileDelivery">
                              Browse Or Drop File
                            </div>
                            <div class="btn-white2 browseButton2">
                              <div class="wrappIconbutton2 mt-3">
                                <span class="iconUploadfile"
                                  ><i class="ic-upload"></i
                                ></span>
                                <span class="classUpload">Upload</span>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>

                      <div
                        class="flex w-full h-screen items-left justify-left text-left"
                        style="width: 100%"
                      ></div>
                    </div>
                  </div>
                  <div
                    :style="
                      filelistCreate.length > 0
                        ? 'height: calc(100% - 40px);'
                        : ''
                    "
                  >
                    <div v-if="filelistCreate.length > 0" style="height: 100%">
                      <div
                        class="forthis"
                        v-for="file in filelistCreate"
                        :key="file.name"
                        style="height: 100%"
                      >
                        <div class="flex align-center justify-end mb-3 ml-0">
                          <!-- <div>
														<label for="" class="reviewSubHeader-text document-align d-flex align-center">
															<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M12.6532 6.56315H16.9276C17.2438 6.56315 17.5 6.80836 17.5 7.10971V17.4486C17.5 18.387 16.7038 19.1673 15.721 19.1673H4.3C3.3178 19.1673 2.5 18.387 2.5 17.4486V2.53268C2.5 1.59424 3.3178 0.833984 4.3 0.833984H10.9276C11.2438 0.833984 11.5 1.07862 11.5 1.38055V5.462C11.5 6.07044 12.016 6.56315 12.6532 6.56315ZM6.7 15.1569H13.3C13.6312 15.1569 13.9 14.9008 13.9 14.584C13.9 14.2672 13.6312 14.0111 13.3 14.0111H6.7C6.3688 14.0111 6.1 14.2672 6.1 14.584C6.1 14.9008 6.3688 15.1569 6.7 15.1569ZM6.7 12.8652H13.3C13.6312 12.8652 13.9 12.6091 13.9 12.2923C13.9 11.9755 13.6312 11.7194 13.3 11.7194H6.7C6.3688 11.7194 6.1 11.9755 6.1 12.2923C6.1 12.6091 6.3688 12.8652 6.7 12.8652ZM6.7 10.5736H13.3C13.6312 10.5736 13.9 10.3169 13.9 10.0007C13.9 9.6844 13.6312 9.42773 13.3 9.42773H6.7C6.3688 9.42773 6.1 9.6844 6.1 10.0007C6.1 10.3169 6.3688 10.5736 6.7 10.5736ZM16.117 5.41732H13.2724C12.9562 5.41732 12.7 5.17211 12.7 4.87076V2.15456C12.7 1.93284 12.8392 1.73289 13.054 1.64925C13.267 1.56446 13.5142 1.61143 13.6774 1.76727L16.522 4.48347C16.6858 4.63987 16.735 4.87591 16.6462 5.0793C16.558 5.2844 16.3492 5.41732 16.117 5.41732Z" fill="#6D858F"/>
															</svg>
															<span class="ml-2" style="color: #4a4a4a;">
																{{ file.name }}
															</span>
														</label>
													</div> -->
                          <div class="d-flex align-center pr-4">
                            <button
                              type="button"
                              class="btn-white replace-btn"
                              @click="handleReplace"
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.29882 3.63602C5.5221 3.91096 5.46577 4.30383 5.173 4.51352C4.0217 5.33812 3.33333 6.61527 3.33333 8.00078C3.33333 10.1904 5.04317 12.005 7.27733 12.3312L6.80474 11.887C6.54439 11.6425 6.54439 11.2461 6.80474 11.0015C7.06509 10.757 7.4872 10.757 7.74755 11.0015L9.41421 12.5668C9.67456 12.8113 9.67456 13.2077 9.41421 13.4522L7.74755 15.0174C7.4872 15.2619 7.06509 15.2619 6.80474 15.0174C6.54439 14.7729 6.54439 14.3765 6.80474 14.132L7.36685 13.6046C4.35077 13.3075 2 10.912 2 8.00078C2 6.22047 2.88588 4.57685 4.36444 3.51786C4.65721 3.30817 5.07554 3.36107 5.29882 3.63602ZM6.58579 2.54938L8.25245 0.984158C8.5128 0.739656 8.93491 0.739656 9.19526 0.984158C9.43559 1.20985 9.45407 1.56499 9.25072 1.8106L9.19526 1.86958L8.63315 2.397C11.6492 2.69406 14 5.08961 14 8.00078C14 9.70363 13.1899 11.2835 11.8189 12.347C11.5351 12.5672 11.1149 12.5296 10.8804 12.263C10.6459 11.9964 10.6859 11.6018 10.9698 11.3816C12.0373 10.5535 12.6667 9.3261 12.6667 8.00078C12.6667 5.81116 10.9568 3.99659 8.72267 3.6704L9.19526 4.11459C9.45561 4.35909 9.45561 4.75551 9.19526 5.00001C8.95494 5.22571 8.57679 5.24307 8.31526 5.0521L8.25245 5.00001L6.58579 3.4348C6.34546 3.2091 6.32698 2.85397 6.53033 2.60836L6.58579 2.54938L8.25245 0.984158L6.58579 2.54938Z"
                                  fill="#0171A1"
                                />
                              </svg>
                              <span class="ml-1">Replace</span>
                            </button>

                            <button
                              type="button"
                              class="btn-white replace-btn ml-2"
                              @click="removeFileUpload"
                            >
                              <svg
                                style="position: relative"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M13.1528 2.84639C13.3933 3.08689 13.3933 3.47681 13.1528 3.71731L8.87076 7.99935L13.1528 12.2814C13.3933 12.5219 13.3933 12.9118 13.1528 13.1523C12.9123 13.3928 12.5224 13.3928 12.2819 13.1523L7.99984 8.87027L3.7178 13.1523C3.4773 13.3928 3.08738 13.3928 2.84688 13.1523C2.60638 12.9118 2.60638 12.5219 2.84688 12.2814L7.12891 7.99935L2.84688 3.71731C2.60638 3.47681 2.60638 3.08689 2.84688 2.84639C3.08738 2.60589 3.4773 2.60589 3.7178 2.84639L7.99984 7.12843L12.2819 2.84639C12.5224 2.60589 12.9123 2.60589 13.1528 2.84639Z"
                                  fill="#F93131"
                                />
                              </svg>
                              <span class="ml-1" style="color: #f93131"
                                >Remove</span
                              >
                            </button>
                          </div>
                        </div>

                        <div class="flex" style="height: 100%">
                          <!-- style="height: calc(100% - 60px);" -->
                          <object
                            style="overflow: hidden !important"
                            :data="getFileURL(file)"
                            frameborder="0"
                            width="100%"
                            height="100%"
                          ></object>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-form>
          </div>
        </v-card-text>

        <v-card-actions
          class="mt-0"
          style="border-top: 1px solid #ebf2f5; padding-top: 16px"
        >
          <div class="flex flex-row">
            <button
              type="button"
              style="height: 41px; font-size: 14px"
              class="btn-blue"
              @click="saveEdit"
              :disabled="isSubmittingForm"
            >
              {{ isSubmittingForm ? "Creating..." : "Create" }}
            </button>
            <button
              type="button"
              class="btn-white cancel-btn"
              style="color: #f93131; height: 41px; font-size: 14px"
              @click="cancelEdit"
              :disabled="isSubmittingForm"
            >
              <span style="color: #4a4a4a">Cancel</span>
            </button>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ContainerDialog
      @saveContainer="saveContainer"
      :dialogData.sync="dialog"
      :ContainerSize="ContainerSize"
      :lastIndexData="lastIndexData"
    />

    <ContainerDialogEdit
      @updateContainer="updateContainer"
      :dialogData.sync="showedit"
      :ContainerSize="ContainerSize"
      :editdata="keysvalue"
    />

    <CreatePicker
      :dialogDatepicker="dialogDatepicker"
      :defaultData="pickerDefaultdata"
      @passbackdata="getdata"
      @close="closeDialogpicker"
    />

    <Confirm
      @confirmDialog="continueDialog"
      :dialogData.sync="dialogConfirm"
      :isSaving.sync="isSavingConfirm"
      :deliveryID="DO_ID"
      :containersnumber="containers_number"
      :countshipment="countshipment"
      :shipmentIDS="shipmentIDS"
      :confirmmessage="confirm_message"
      :location="location"
    />

    <CustomerDialogform
      :aftersavecustomer.sync="aftersavecustomer"
      :aftersave.sync="aftersave"
      :dialogData.sync="dialogCustomerdialog"
      :item="item"
    />

    <Containerrequired
      :dialogData.sync="dialogrequired"
      @close="
        () => {
          dialogrequired = false;
        }
      "
    />
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
const poBaseUrl = process.env.VUE_APP_PO_URL;
import globalMethods from "../../../../utils/globalMethods";
import VueTagsInput from "@johmun/vue-tags-input";
import ContainerDialog from "./DialogContainer.vue";
import ContainerDialogEdit from "./EditDialogContainer.vue";
//import ListRendering from "../newlistRender.vue";
import Containerrequired from "./Onecontainerrequired.vue";
import CustomerDialogform from "./CustomerDialogform.vue";
import CreatePicker from "../../../Createpicker.vue";
import Confirm from "../confirm.vue";
import jQuery from "jquery";
import Vue from "vue";

// import DxDateBox from 'devextreme-vue/date-box';
import moment from "moment";

//import _ from "lodash";
export default {
  name: "create_containers",
  props: ["openDialogCreate", "pageClass"],
  components: {
    VueTagsInput,
    ContainerDialog,
    //ListRendering,
    CreatePicker,
    Confirm,
    ContainerDialogEdit,
    CustomerDialogform,
    Containerrequired,
    // DxDateBox
  },
  data() {
    return {
      checkfieldExist: false,
      reloadmbl: 1,
      starting_location_address: "",
      switchtostartinglocation: 0,
      place_name: "place_name",
      isDateRequired: false,
      attribute: {
        highlight: {
          style: {
            backgroundColor: "#0171A1", // blue
            borderRadius: "4px",
          },
          contentStyle: {
            color: "#ffffff", // color of the text
            borderRadius: "4px",
          },
        },
      },
      masks: {
        weekdays: "WWW",
        navMonths: "MMMM",
      },
      isSubmittingForm: false,
      checkboxitem: 1,
      checkboxcontainers: "",
      checkboxall: [],
      reload: 1,
      calceladding: false,
      hascheck: 0,
      showselect: true,
      hasfileni: "",
      dialogrequired: false,
      item: [],
      dialogCustomerdialog: false,
      loadingLocationPlaces: false,
      loadingStartingLocationPlaces: false,
      pickup_location: "",
      delivery_location: "",
      containers: {
        container_number: "",
        container_size: "",
        container_weight: "",
        container_volume: "",
        container_qty: "",
        lfd: "",
      },
      page: 1,
      hasNewChassis: 0,
      selected: [],
      places: [],
      startinglocationplaces: [],
      places_pickup: [],
      searchPlace: "",
      searchPlaceStaringLocation: "",
      searchPlacePickup: "",
      isAdding: false,
      hassblickvalidate: 0,
      errorMessagetext: {
        container: "",
        Weight: "",
        Volume: "",
        Quantity: "",
        Sizes: "",
      },
      gooodsdeliverylocation: "",
      terminals: [],
      carriers: [],
      customers: [],
      ContainerSize: [],
      location: "create",
      istriggerededit: 3,
      iscreate: 1,
      confirm_message: [],
      movedo: "createdo",
      isMovedo: 0,
      eventBus: new Vue(),
      keytovalue: 0,
      keysvalue: [],
      editdata: [],
      showedit: 0,
      isErrorInlinEdit: 0,
      countshipment: 0,
      shipmentIDS: [],
      containers_number: [],
      DO_ID: "",
      isSavingConfirm: false,
      dialogConfirm: false,
      disableEditContainers: false,
      refresh: 0,
      aftersave: 0,
      newChassis: "",
      iscustomer: 1,
      aftersavecustomer: [],
      callna: "",
      solidestyle: 0,
      errorstate: 0,
      notpermited: false,
      calleditableELe: false,
      pickerDefaultdata: "",
      currentstatepicker: "",
      dialogDatepicker: false,
      modaletd: false,
      container_commodity: "",
      customerdropdown: "",
      allDeliveryData: [],
      currentindexshow: 99999,
      containerEmptytext:
        "Container is empty. Add container before you save changes",
      roottablecontainer: "blueteam",
      containerList2: [],
      containerList: [],
      HBL_NUMBER: "",
      uploadCompleted: true,
      uploadTrigger: false,
      noerrorForm: true,
      contactsModel: "",
      CarrierModel: "",
      VesselModel: "",
      responseDatatempt: "",
      serverObject: [],
      objectServerid: "",
      hasContent: "hasContentFiles2",
      filelistCreate: [],
      errorFile: false,
      comodity: "",
      resetform: false,
      shipmentmsg: "The shipment was created!",
      Truckers: false,
      noerror: false,
      testsubmit: "",
      formFieldlength: 0,
      startloading: 0,
      clickOrigin: "",
      clicksubmit: false,
      submitisclick: false,
      selectedmilestone: [],
      customer: [],
      mbl: "",
      containerNumber: "",
      terminalAt: [],
      CarrierRules: [(v) => !!v || "Customer is required"],
      VesselRules: [(v) => !!v || "Customer is required"],
      customerRules: [(v) => !!v || "Customer is required"],
      mblRules: [(v) => !!v || "MBL# is required"],
      fieldrequired: [(v) => !!v || "This field is required"],
      containerNumberRules: [(v) => !!v || "Container # is required"],
      notembl: "",
      noterules: [
        (v) =>
          (v || "").length < 200 ||
          "Should not exceeds to 200 allowed characters.",
        // (v) => v.length < 200 || "Should not exceeds to 200 allowed characters.",
      ],
      TerminalAtRules: [(v) => !!v || "Terminal At is required"],
      containerSizeModel: [],
      containersize: "",
      containersizeRules: [(v) => !!v || "Container size is required"],
      deliverTo: "",
      delivertoRules: [(v) => !!v || "Deliver to is required"],
      weight: "",
      weightRules: [(v) => !!v || "Weight is required"],
      customerpoNumber: "",
      customerpoNumberRules: [(v) => !!v || "Customer PO# is required"],
      containerTypeField: "",
      containerTypeRules: [(v) => !!v || "Container Type is required"],
      truckersField: "",
      truckersRules: [(v) => !!v || "Truckers is required"],
      hiddenmilestone: "",
      hiddenmilestoneRules: [(v) => !!v || "Please select milestone"],
      select: "",
      customerSelected: "",
      containertypeSelected: "",
      terminalatSelected: "",
      containersizeSelected: "",
      currentDropdownState: "",
      trucker_idSelected: "",
      valid: true,
      currentComboState: "",
      tabscurrent: 0,
      tab: null,
      tabsNavigation: ["Header Info"],
      date_etd: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      // date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),
      date: null,
      menu: false,
      modal: false,
      menu2: false,
      checkboxColor: "#0171A1",
      container_type: "",
      select_terminal_at: "",
      itemsDropdown: [],
      dialog: false,
      dialog2: false,
      search: "",
      searchData: "",
      selectedTasks: [],
      containerType: [],
      containerSize: [],
      TERMINAL_AT: [],
      ex4: [],
      truckers: [],
      newdata: [],
      myclass: "",
      myclass2: "",
      options: [],
      rules: [(v) => !!v || "Input is required."],
      optionsReferrence: [],
      documentProto: document,
      warehouseEmailAddress: "",
      customerReferrenceNumber: "",
      moreFilesError: false,
      submit: false,
      tagsInput: {
        touched: false,
        hasError: false,
        errorMessage:
          'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.',
      },
      tagsInput2: {
        touched: false,
        hasError: false,
        errorMessage:
          'Please confirm the entered Customer Referrence by pressing the "Enter" or "," key in your keyboard.',
      },
      tagsValidation: [
        {
          classes: "t-new-tag-input-text-error",
          rule: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
          disableAdd: true,
        },
      ],
      tagsValidation2: [
        {
          classes: "t-new-tag-input-text-error",
          rule: /^([a-zA-Z0-9_.-])+$/,
          disableAdd: true,
        },
      ],
      arrayNotEmptyRules: [
        //(v) => !!v || "Email is required",
        () =>
          this.optionsFiltered.length > 0 ||
          "Make sure to supply at least 1 email.",
      ],
      arrayNotEmptyRulesRefference: [
        //(v) => !!v || "Email is required",
        () =>
          this.optionsFiltered.length > 0 ||
          "Make sure to supply at least 1 email.",
      ],
      tagsValidation3: [
        {
          classes: "ttt-new-tag-input-text-error",
          rule: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
          disableAdd: true,
        },
      ],
      validateDaterules: [
        (v) => {
          if (v === "") return "This field is required";
          if (!this.validateDate(v)) {
            return "Invalid Date ex: YYYY-mm-dd";
          }
          return true;
        },
      ],
      lastIndexData: null,
      whereitcome: "",
      //aftersavecustomer:[],
      isShowETAError: false,
      isShowMBLError: false,
      isShowCustomerError: false,
      isShowDeliveryError: false,
      consigneeLists: [],
      selectedConsignee: null,
      newConsignee: "",
      hasNewConsignee: 0,
    };
  },
  watch: {
    startinglocationplaces(c) {
      console.log(c);
    },
    aftersave(item) {
      if (item) {
        this.customerdropdown = item;
        this.dialogCustomerdialog = false;
        this.whereitcome = "aftersave";
      }
    },
    dialogCustomerdialog(c) {
      if (c || !c) {
        this.iscustomer = 0;
        this.$nextTick(() => {
          this.customers =
            this.whereitcome == "aftersave"
              ? this.aftersavecustomer
              : this.aftersavecustomer;
          this.iscustomer = 1;
        });
      }
    },
    searchPlacePickup(val) {
      if (val) {
        this.getPickupLocation(val);
      }
    },
    searchPlace(val) {
      if (val) {
        this.getDeliveryLocation(val);
      }
    },
    searchPlaceStaringLocation(val) {
      if (val) {
        this.getStartingLocation(val);
      }
    },
    openDialogCreate(c) {
      if (c) {
        // this.$refs.form.reset();
        this.optionsReferrence = [];
        this.options = [];
        this.containerList = [];
        this.warehouseEmailAddress = "";
        this.containers = {
          container_number: "",
          container_size: "",
          container_weight: "",
          container_volume: "",
          container_qty: "",
          lfd: "",
        };
        this.date = null;
        // this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        // .toISOString()Customer Kim
        // .substr(0, 10)

        this.isAdding = false;
        this.showselect = true;
        this.calltheresource();
        this.isShowETAError = false;
        this.isShowMBLError = false;
        this.isShowCustomerError = false;
        this.isShowDeliveryError = false;
        this.hasfileni = "";
        this.haserrorContainers = false;
        this.errorMessagetext = {
          container: "",
          Weight: "",
          Volume: "",
          Quantity: "",
          Sizes: "",
        };
        this.selectedConsignee = null;
        this.consigneeLists = [];
        this.isDateRequired = false;
      } else {
        this.filelistCreate = [];
      }
    },
    keysvalue(c) {
      console.log(c);
    },
    customers(c) {
      if (c == "") {
        console.log("customer is null");
      }
    },
    items: function (newVal) {
      this.getPrelims(newVal);
    },
    filelistCreate(c) {
      this.hasfileni = "";
      if (c.length == 0) this.hasContent = "hasContentFiles2";
      else this.hasContent = "cDownloadfiles hidethisdiv";
    },
  },
  computed: {
    getSearchCompany: {
      get() {
        return this.newChassis !== null
          ? this.newChassis.toString()
          : this.newChassis;
      },
      set(input) {
        let temp = [];

        this.itemsDropdownComputed.map((list) => {
          temp.push(list.company_name);
        });
        if (temp.includes(input)) {
          this.hasNewChassis = 0;
        } else if (this.newChassis === "" || this.newChassis === null) {
          this.hasNewChassis = 0;
        } else {
          this.hasNewChassis = 1;
        }
        this.newChassis = input;
      },
    },
    itemsDropdownSize: {
      get() {
        return this.ContainerSize;
      },
    },
    headers() {
      return [
        {
          text: "Container #",
          align: "start",
          value: "container_number",
          sortable: false,
          width: "27%",
          fixed: true,
        },
        {
          text: "Size",
          align: "end",
          value: "container_size",
          sortable: false,
          width: "20%",
          fixed: true,
        },
        {
          text: "Weight",
          align: "end",
          value: "container_weight",
          sortable: false,
          width: "15%",
          fixed: true,
        },
        {
          text: "Volume",
          align: "end",
          value: "container_volume",
          sortable: false,
          width: "15%",
          fixed: true,
        },
        {
          text: "Quantity",
          align: "end",
          value: "container_qty",
          sortable: false,
          width: "15%",
          fixed: true,
        },
        {
          text: "",
          align: "end",
          value: "actions",
          sortable: false,
          width: "8%",
          fixed: true,
          class: "alex",
        },
      ];
    },
    openDialogComCreate: {
      get() {
        this.handleFocused("deliveryLocation");
        this.handleStartingFocused("startingLocation");
        return this.openDialogCreate;
      },
      set(val) {
        this.$emit("update:openDialogCreate", val);
      },
    },
    ...mapGetters({
      getCreateloading: "containers/getPoLoading",
      formdetails: "containers/getcontainerFormDetails",
      getPoLoading: "containers/getPoLoading",
      getAllConsignees: "containers/getAllConsignees",
      getAllConsigneesLoading: "containers/getAllConsigneesLoading",
      getCreateConsigneeLoading: "containers/getCreateConsigneeLoading",
      getTruckerId: "getTruckerId",
    }),
    itemsDropdownComputed: {
      get() {
        return this.customers;
      },
    },
    terminalsDropdown: {
      get() {
        if (this.switchtostartinglocation) {
          return this.startinglocationplaces;
        }
        return this.terminals;
      },
    },
    carrierDropdown: {
      get() {
        return this.carriers;
      },
    },
    sizeDropdown: {
      get() {
        return this.ContainerSize;
      },
    },
    searchedConsignee: {
      get() {
        return this.newConsignee !== null && this.newConsignee !== ""
          ? this.newConsignee.toString()
          : this.newConsignee;
      },
      set(input) {
        let temp = [];
        this.consigneeLists.map((list) => {
          temp.push(list.name);
        });
        if (temp.includes(input)) {
          this.hasNewConsignee = 0;
        } else if (this.newConsignee === "" || this.newConsignee === null) {
          this.hasNewConsignee = 0;
        } else {
          this.hasNewConsignee = 1;
        }
        this.newConsignee = input;
      },
    },
  },

  methods: {
    async checkexisting() {
      this.checkfieldExist = false;
      await axios
        .post(`${poBaseUrl}/checkfield`, {
          fieldname: "mbl_num",
          value: this.mbl,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .then((response) => {
          if (response) {
            if (response.data) {
              this.checkfieldExist = true;
            }
          }
        });
    },
    custom_seach() {
      this.switchtostartinglocation = this.switchtostartinglocation ? 0 : 1;
      if (this.switchtostartinglocation) {
        this.handleStartingFocused("startingLocation");
      }
    },
    clearDate() {
      this.date = null;
      this.isDateRequired = false;
    },
    checkKeyUpVal(e) {
      if (e.key === "Enter" || e.keyCode === 13) {
        this.$refs.eta_ref_do.hidePopover(); // close popover on click enter
      }
    },
    async onSelectCustomer(selected) {
      if (selected !== null && selected !== "") {
        this.consigneeLists = [];
        await this.fetchConsigneeAction(selected.id);
      }
    },
    async handleNewConsignee() {
      if (this.newConsignee === null || this.newConsignee === "") {
        this.notificationCustom("Please enter consignee name");
      } else {
        let data = {
          name: this.newConsignee,
          customer_id: this.customerdropdown.id,
        };
        await this.createConsignee(data).then(async (res) => {
          if (res) {
            let data = res.data;
            this.notificationMessageCustomSuccess(res.message);
            this.selectedConsignee = data.id;
            this.consigneeLists = [];
            await this.fetchConsigneeAction(data.customer_id);
          }
        });
      }
    },
    async fetchConsigneeAction(id) {
      await this.fetchConsignees(id);
      if (
        typeof this.getAllConsignees !== "undefined" &&
        this.getAllConsignees !== null
      ) {
        this.consigneeLists = this.getAllConsignees.results;
      }
      this.$refs.consigneeRef.blur();
    },
    onFilter(item, queryText) {
      if (item.nickname) {
        return (
          item.name
            .toLocaleLowerCase()
            .includes(queryText.toLocaleLowerCase()) ||
          item.nickname
            .toLocaleLowerCase()
            .includes(queryText.toLocaleLowerCase()) ||
          item.firms_code
            .toLocaleLowerCase()
            .includes(queryText.toLocaleLowerCase())
        );
      }
      return (
        item.name.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) ||
        item.firms_code
          .toLocaleLowerCase()
          .includes(queryText.toLocaleLowerCase())
      );
    },
    clearselection() {
      this.containerList.map((e) => {
        if (e.checkbox == 1) {
          e.checkbox = 0;
        }
      });
      this.checkboxall = [];
      this.hascheck = 0;
      this.reload = 0;
      this.$nextTick(() => {
        this.reload = 1;
      });
      this.checkboxcontainers = 0;
    },
    checkvalue(item, name) {
      console.log(item, name);
      let haserror = 0;
      // if (item =='' || isNaN(item)) {
      //     this.haserrorContainers = true;
      //     haserror = 1;
      //     if(name=='Weight'){
      //         this.errorMessagetext['Weight'] = 'Weight must a numeric value';
      //     }else if(name=='Volume'){
      //         this.errorMessagetext['Volume'] = 'Volume must a numeric value';
      //     }else if(name=='Quantity'){
      //         this.errorMessagetext['Quantity'] = 'Quantity must a numeric value';
      //     }
      // }else{
      //     this.haserrorContainers = false;
      //     if(name=='Weight'){
      //         this.errorMessagetext['Weight'] = '';
      //     }else if(name=='Volume'){
      //         this.errorMessagetext['Volume'] = '';
      //     }else if(name=='Quantity'){
      //         this.errorMessagetext['Quantity'] = '';
      //     }
      // }

      return haserror;
    },
    validatesize(text) {
      let value = 0;
      if (typeof text == "undefined") {
        value = 1;
      } else if (text != "") {
        if (text == "NaN") {
          value = 1;
        } else if (isNaN(text)) {
          value = 1;
        }
      } else if (text == "") {
        value = 1;
      }

      if (value == 1) {
        this.haserrorContainers = true;
        this.errorMessagetext["Sizes"] = "Size is required.";
      } else {
        this.haserrorContainers = false;
        this.errorMessagetext["Sizes"] = "";
      }
      return value;
    },
    validateContainerNumber(index, text) {
      let haserror = 0; // no error
      // let msg = "Container #,First 4 characters are alpha and followed by 7 digits.";
      //let msg = "Container Number's first 4 characters are alpha and followed by 7 digits.";
      let msg = "";
      if (text != "" && typeof text != "undefined") {
        if (text.length == 11) {
          if (
            /^[a-zA-Z]*$/.test(text.substring(0, 4)) &&
            /^[0-9]*$/.test(text.substring(4, text.length))
          ) {
            msg = "";
            this.haserrorContainers = false;
            this.errorMessagetext["container"] = "";
            jQuery(document)
              .find(".ContainerTooltip_" + index)
              .hide();
          } else {
            this.haserrorContainers = true;
            haserror = 1;
            this.errorMessagetext["container"] = msg;

            if (index != null) {
              jQuery(document)
                .find(".ContainerTooltip_" + index)
                .show();
            }
          }
        } else {
          if (index != null) {
            jQuery(document)
              .find(".ContainerTooltip_" + index)
              .show();
          }
          this.haserrorContainers = true;
          haserror = 1;
          this.errorMessagetext["container"] = msg;
        }
      } else if (text === "") {
        haserror = 1;
        jQuery(document)
          .find(".ContainerTooltip_" + index)
          .hide();
        this.errorMessagetext["container"] = msg;
      }
      return haserror;
    },
    getcheckall() {
      let check = 1;
      if (typeof this.checkboxall[0] == "undefined") {
        check = 0;
      }
      this.containerList.map((e) => {
        e.checkbox = check;
      });

      this.checkboxcontainers = check == 1 ? 1 : 0;
      this.reload = 0;
      this.$nextTick(() => {
        this.reload = 1;
        this.hascheck = check;
      });
    },
    checkselected() {
      let hascheckdetec = 0;
      this.containerList.map((e) => {
        if (e.checkbox == 1) {
          hascheckdetec = 1;
        }
      });
      this.checkboxitem = 0;
      //this.hascheck = hascheckdetec == 1 ? 1 : 0
      this.reload = 0;
      this.$nextTick(() => {
        this.reload = 1;
        this.hascheck = hascheckdetec == 1 ? 1 : 0;
      });
    },
    createcustomer() {
      //this.customerdropdown = [];
      this.dialogCustomerdialog = true;
      this.iscustomer = 0;
      this.$nextTick(() => {
        // this.customers == this.tempcustomer;
        this.iscustomer = 0;
      });
    },
    cancelAdding() {
      this.calceladding = true;
      this.isAdding = false;
      this.errorMessagetext = {
        container: "",
        Weight: "",
        Volume: "",
        Quantity: "",
        Sizes: "",
      };

      this.containers = {
        container_number: "",
        container_size: "",
        container_weight: "",
        container_volume: "",
        container_qty: "",
        checkbox: 0,
      };
      this.hassblickvalidate = 0;
    },
    changeDeliveryLocationState() {
      this.callApiForDeliveryLoc = !this.callApiForDeliveryLoc;
    },
    clearSearchPlacesStartingLocation() {
      this.startinglocationplaces = [];
    },
    clearSearchPlaces() {
      this.places = [];
    },
    clearSearchPlacesPickup() {
      this.places_pickup = [];
    },
    cancelEdit() {
      this.$emit("closeDialog");
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
      (this.tagsInput = {
        touched: false,
        hasError: false,
        errorMessage:
          'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.',
      }),
        (this.tagsInput2 = {
          touched: false,
          hasError: false,
          errorMessage:
            'Please confirm the entered Customer Referrence by pressing the "Enter" or "," key in your keyboard.',
        });
    },
    checklastcontainer() {
      let hassfounderror = false;
      let c = 0;
      if (this.isAdding) {
        this.containerList.push(this.containers);
        this.isAdding = false;
      }
      // let valem = '';
      Object.values(this.containerList).forEach((val) => {
        // if(typeof val.container_size =='undefined'){
        //     valem = '';
        // }else{
        //     valem = val.container_size
        // }
        c += this.validateContainerNumber(null, val.container_number);
        // this.validatesize(valem) +
        // this.checkvalue(val.container_weight,'Weight') +
        // this.checkvalue(val.container_volume,'Volume') +
        // this.checkvalue(val.container_qty,'Quantity')
      });

      if (c > 0) {
        hassfounderror = true;
      }
      return hassfounderror;
    },
    saveEdit() {
      // if(this.checklastcontainer() || this.haserrorContainers){
      //   this.dialogcontainervalidate = true;
      // }else if(this.containerList.length === 0){
      //   this.dialogrequired = true;
      // } else if (this.date === null || this.date === "") {
      //   this.isShowETAError = true
      // }else{
      //   this.clicsubmit();
      // }

      if (this.checklastcontainer() || this.haserrorContainers) {
        this.dialogcontainervalidate = true;
      } else if (this.containerList.length === 0) {
        this.dialogrequired = true;
      } else if (this.checkFormErrors()) {
        this.notificationCustom("Please fill the required fields.");
      } else {
        this.clicsubmit();
      }
    },
    checkFormErrors() {
      // check required fields and show error after submitting
      let hasErrors = false;

      // ETA
      if (this.date === null || this.date === "") {
        this.isShowETAError = true;
      } else {
        this.isShowETAError = false;
      }
      // MBL
      if (this.mbl === null || this.mbl === "") {
        this.isShowMBLError = true;
      } else {
        this.isShowMBLError = false;
      }
      // CUSTOMER NAME
      if (this.customerdropdown === null || this.customerdropdown === "") {
        this.isShowCustomerError = true;
      } else {
        this.isShowCustomerError = false;
      }
      // GOODS DELIVERY LOCATION
      if (this.delivery_location === null || this.delivery_location === "") {
        this.isShowDeliveryError = true;
      } else {
        this.isShowDeliveryError = false;
      }

      if (
        !this.isShowDeliveryError &&
        !this.isShowMBLError &&
        !this.isShowCustomerError &&
        !this.isShowETAError
      ) {
        hasErrors = false;
      } else {
        hasErrors = true;
      }

      return hasErrors;
    },
    checkErrorShowing(e, field) {
      if (e !== "") {
        if (field === "mbl") {
          this.checkexisting();
          this.isShowMBLError = false;
        } else if (field === "customer_name") {
          this.isShowCustomerError = false;
        } else if (field === "delivery_location") {
          this.isShowDeliveryError = false;
        } else if (field === "eta") {
          this.isShowETAError = false;
          this.isDateRequired = true;

          if (this.date !== null && this.date !== "") {
            // this.$refs.eta_ref_do.value_.setFullYear(moment().year())
            if (this.$refs.eta_ref_do.value_.getFullYear() === 2001) {
              this.$refs.eta_ref_do.value_.setFullYear(moment().year());
            }
          }
        }
      }
    },
    async getStartingLocation(val) {
      if (val.length > 4) {
        this.loadingStartingLocationPlaces = true;
        await fetch(
          `${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/` +
            val +
            `.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}&country=US`
        )
          .then((response) => response.json())
          .then((json) => {
            this.loadingStartingLocationPlaces = false;
            this.startinglocationplaces = [...json.features];
          })
          .catch((e) => {
            this.loadingStartingLocationPlaces = false;
            console.log(e);
          });
      }
    },
    async getDeliveryLocation(val) {
      if (val.length > 4) {
        this.loadingLocationPlaces = true;

        await fetch(
          `${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/` +
            val +
            `.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}&country=US`
        )
          .then((response) => response.json())
          .then((json) => {
            this.loadingLocationPlaces = false;
            this.places = [...json.features];
          })
          .catch((e) => {
            this.loadingLocationPlaces = false;
            console.log(e);
          });
      }
    },
    async getPickupLocation(val) {
      if (val.length > 4) {
        this.loadingLocationPlaces = true;

        await fetch(
          `${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/` +
            val +
            `.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}`
        )
          .then((response) => response.json())
          .then((json) => {
            this.loadingLocationPlaces = false;
            this.places_pickup = [...json.features];
          })
          .catch((e) => {
            this.loadingLocationPlaces = false;
            console.log(e);
          });
      }
    },
    select_company() {
      return this.initialcompany;
    },
    sizecheck(thechecker) {
      let value = thechecker === "" ? false : true;
      if (this.hassblickvalidate) {
        if (value == false) {
          this.errorMessagetext["Sizes"] = "Size is required.";
        } else {
          value = true;
          this.errorMessagetext["Sizes"] = "";
        }
      }
      if (value) {
        this.errorMessagetext["Sizes"] = "";
      }
      return value;
    },
    rulesCheck(thechecker, name) {
      let msg = "";
      let value = false;
      if (isNaN(thechecker)) {
        msg = name + " must be numeric";
      } else {
        msg = "";
        value = true;
      }

      if (thechecker == "") {
        msg = name + " must be numeric";
        value = false;
      }

      if (this.hassblickvalidate) {
        if (name == "Weight") {
          this.errorMessagetext["Weight"] = msg;
        } else if (name == "Volume") {
          this.errorMessagetext["Volume"] = msg;
        } else if (name == "Quantity") {
          this.errorMessagetext["Quantity"] = msg;
        }
      }
      return value;
    },
    age(text) {
      let value = false;

      let msg = "First 4 characters are alpha and followed by 7 digits.";
      if (text.length == 11) {
        if (
          /^[a-zA-Z]*$/.test(text.substring(0, 4)) &&
          /^[0-9]*$/.test(text.substring(4, text.length))
        ) {
          msg = "";
          value = true;
        }
      }
      if (this.hassblickvalidate) {
        this.errorMessagetext["container"] = msg;
      }
      return value;
    },
    handleAddContainer() {
      this.hassblickvalidate = 1;
      //this.errorMessagetext  = [];
      if (this.$refs.containerForm.validate()) {
        this.hassblickvalidate = 0;
        this.containerList.push(this.containers);
        this.isAdding = false;
        this.containers = {
          container_number: "",
          container_size: "",
          container_weight: "",
          container_volume: "",
          container_qty: "",
          lfd: "",
        };
      }
    },
    addCOntainer() {
      if (this.isAdding) {
        this.containerList.push(this.containers);
        this.isAdding = false;
        this.containers = {
          container_number: "",
          container_size: "",
          container_weight: "",
          container_volume: "",
          container_qty: "",
          checkbox: 0,
        };
      }
      this.isAdding = true;
    },
    closedialog() {
      this.$emit("closeDialog");
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
      this.removeFileUpload();
    },
    async calltheresource() {
      let that = this;
      await axios
        .get(`${poBaseUrl}/get-other-resources`)
        .then(function (response) {
          if (response.data) {
            that.ContainerSize = response.data.sizes;
            that.customers = response.data.customer;
            that.carriers = response.data.carrier;
            that.terminals = response.data.terminal;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeContainer(i) {
      if (this.containerList.length > 1) {
        this.containerList.splice(i, 1);
        this.haserrorContainers = false;
        this.errorMessagetext = {
          container: "",
        };
      } else {
        this.dialogrequired = true;
      }
    },
    continueDialog(item) {
      if (item.isConfirmed) {
        // this.dialogConfirm = false;
        this.isSavingConfirm = true;
        if (item.isConfirmed) {
          //this.saveForm();

          if (this.$router.currentRoute.path !== "/containers") {
            this.$router.push({ path: "/containers" }).then(() => {
              this.$router.go();
            });
          } else {
            location.reload();
          }
        }
      }
    },
    classCheck(cate) {
      if (cate.error == 1) {
        return "triggerclasserror";
      }
      return "";
    },

    isNumber(n) {
      return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    },
    calleditable() {
      return this.calleditableELe;
    },
    edit() {
      return (this.calleditableELe = true);
    },
    validateDate(dateStr) {
      let str = dateStr.split("T");
      dateStr = str[0];
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (dateStr.match(regex) === null) {
        return false;
      }
      const date = new Date(dateStr);
      const timestamp = date.getTime();
      if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
        return false;
      }
      return date.toISOString().startsWith(dateStr);
    },
    showcustom(state, m) {
      this.pickerDefaultdata = state;
      this.currentstatepicker = m;
      this.dialogDatepicker = true;
    },
    closeDialogpicker() {
      this.dialogDatepicker = false;
    },
    getdata(item) {
      if (this.currentstatepicker == "date") {
        this.date = item;
      }
    },
    clickDownload() {
      this.gotoDownload();
    },
    uploadAgain() {
      jQuery(document).find(".triggerelementCreate").trigger("click");
    },
    timetopass() {
      let finalEmailAddress =
        this.options.length > 0
          ? this.options.map((o) => {
              return o.text;
            })
          : [];
      return finalEmailAddress;
    },
    timetopassreference() {
      // let finalEmailAddress = (this.optionsReferrence.length > 0) ? this.optionsReferrence.map(o => {
      //         return o.text
      //     }) : []
      //     return finalEmailAddress;
      let finalrefrenceNumber =
        this.optionsReferrence.length > 0
          ? this.optionsReferrence.map(({ text }) => ({
              customer_ref_number: text,
            }))
          : [];
      return JSON.stringify(finalrefrenceNumber);
    },
    ...mapActions({
      fetchFormdetails: "containers/fetchallformdetails",
      fetchConsignees: "containers/fetchConsignees",
      createConsignee: "containers/createConsignee",
    }),
    ...globalMethods,
    blurevent() {
      this.checkobjects();
    },
    currentshow(index) {
      if (index == this.currentindexshow) return 1;
    },
    // magamit if click sa save sa taas
    checkobjects() {
      if (
        //this.mbl.length !== 0 &&
        Object.keys(this.customerdropdown).length !== 0 &&
        Object.keys(this.CarrierModel).length !== 0 &&
        this.VesselModel.length !== 0 &&
        Object.keys(this.terminalAt).length !== 0 &&
        Object.keys(this.containerList).length !== 0 &&
        this.isErrorInlinEdit != 1
      ) {
        this.noerror = 1;
        this.noerrorForm = false;
      } else {
        this.noerror = 0;
        this.noerrorForm = true;
      }
    },
    deleteClear(i) {
      this.containerList.splice(i, 1);
      this.checkobjects();
    },
    clicsubmit() {
      if (this.$refs.form.validate()) {
        this.startloading = 1;
        this.isSubmittingForm = true;
        let formData = new FormData(document.getElementById("sform"));
        let carrier_id = this.CarrierModel !== null ? this.CarrierModel.id : "";
        let carrier_name =
          this.CarrierModel !== null ? this.CarrierModel.name : "";
        let eta =
          this.date !== null ? moment(this.date).format("YYYY-MM-DD") : "";
        let cid =
          typeof this.customerdropdown === "object"
            ? this.customerdropdown.id
            : this.customerdropdown;

        formData.append("ETA", eta);
        formData.append("flagCreatedFE", 1);
        formData.append("xmbl", this.mbl);
        formData.append("xcustomer", cid);
        formData.append("xcontacts[]", this.timetopass());
        formData.append("xcarrier", carrier_id);
        formData.append("xcarrier_name", carrier_name);
        formData.append("xvessel", this.VesselModel);
        formData.append("xreferrence[]", this.timetopassreference());
        formData.append("xlocation_at", this.terminalAt.id);
        //formData.append("xlocation_at_name", this.terminalAt.name);
        formData.append("xnote_mbl", this.notembl);
        formData.append("xcontainerList", JSON.stringify(this.containerList));
        formData.append("container_commodity", this.container_commodity);
        formData.append("xlocation_at_name", this.pickup_location);
        formData.append("trucker", this.getTruckerId);

        // FOR THE STARTING LOCATION ADDRESS
        formData.append(
          "starting_location_address",
          this.switchtostartinglocation > 0
            ? this.starting_location_address.place_name
            : ""
        );

        if (this.switchtostartinglocation) {
          let placesthis = this.starting_location_address.geometry; // .coordinates[0]
          formData.append(
            "starting_location_latitude",
            placesthis.coordinates[1]
          );
          formData.append(
            "starting_location_longitude",
            placesthis.coordinates[0]
          );
        }

        // check if the starting location is use
        // if is_starting_location_address is 1 then use the custom search address
        // else use the custom termnal
        formData.append(
          "is_starting_location_address",
          this.switchtostartinglocation
        );

        formData.append("xdelivery_location", this.delivery_location);
        formData.append("consignee_id", this.selectedConsignee);
        //this.filelistCreate.map((file, key) => {
        //formData.append(`files[${key}]`, file);
        //});
        axios
          .post(`${poBaseUrl}/orderdelivery-save`, formData)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            this.isSubmittingForm = false;
          })
          .then((response) => {
            this.startloading = 0;
            this.isSubmittingForm = false;
            if (response) {
              this.DO_ID = response.DO_ID;
              this.shipmentIDS = response.shipment_id;
              this.containers_number = response.containers_number;
              this.countshipment = response.count_shipment;
              this.confirm_message = response.confirm_message;

              // var c = ( this.containerList.length > 1) ?
              //          ' Containers':
              //          ' Container';

              // var finaltext = 'DO ' + response.DO_ID +' Created and' + c;
              //this.dispatchNotifcation('<i class="ic-check-mark"></i>&nbsp;<span class="text-message">'+ finaltext +' Added!</span>')

              if (origin == "save") {
                this.formReset();
              } else {
                this.$refs["form"].resetValidation();

                this.dialogConfirm = true;
                //this.$router.push('/delivery-order/'+response.DO_ID)
                // show modal confirmation
                // then if hide redirect to index
                // if close redirect to index
                /*
                  Delivery Order ID - 6789009

                  4 Shipments created with the following details:

                  Containers
                  345345454,34534543,34534543,34543543

                  IDs
                  324324324,343243,2343243,32432
              */
                //this.$router.push('/containers')
              }
            }
          });
      }
    },
    saveUpdated(item) {
      this.isErrorInlinEdit = 0;
      if (item.updated) {
        this.containerList = item.data;
      }

      this.containerList.map((v) => {
        const propertyValues = Object.values(v);
        const result = propertyValues.filter((word, index) => {
          if (index > 7) {
            return word;
          }
        });

        this.isErrorInlinEdit = result.reduce(
          (partialSum, a) => partialSum + a,
          0
        );
      });

      this.checkobjects();
    },
    checklengthdata(item) {
      if (item.value === 0) {
        this.containerList = [];
      }
      this.checkobjects();
    },
    emitedit(data) {
      this.showedit = data.show;
      this.keysvalue = data.data[data.index];
      this.keytovalue = data.index;
    },
    updateContainer(item) {
      this.containerList[this.keytovalue] = item;
      console.log(this.containerList);
      var sizesize = item.container_size;
      var getsize = this.ContainerSize.find((e) => {
        if (e.id == sizesize) {
          return e;
        }
      });

      item.container_size = getsize.id;

      //this.containerList.push(item);
      this.refresh = 0;
      this.$nextTick(() => {
        this.refresh = 1;
      });

      this.checkobjects();
    },
    saveContainer(item) {
      var sizesize = item.container_size;
      var dt = item.delivery_to;
      var getsize = this.ContainerSize.find((e) => {
        if (e.id == sizesize) {
          return e;
        }
      });

      item.container_size = getsize.id;
      this.lastIndexData = dt;

      this.containerList.push(item);
      this.refresh = 0;
      this.$nextTick(() => {
        this.refresh = 1;
      });
      //console.log(this.containerList)
      this.checkobjects();
    },
    cancel() {
      if (this.isMovedo) {
        this.$router.push(
          "/delivery-order/" +
            this.$route.params.id +
            "/" +
            this.$route.params.sid
        );
      } else {
        this.$router.push("/containers");
      }
    },
    formReset() {
      this.$router.push("/containers");
    },
    addContainer() {
      this.dialog = true;
    },
    dataDown(value) {
      this.formFieldlength++;
      this.currentDropdownState = value;
      this.currentSelectTab = value;
    },
    checkindex(index) {
      this.currentindexshow = index;
      jQuery(".tablecontainer").css({ background: "none" });
      jQuery(".blueteam_" + index).css({ background: "#F0FBFF" });
    },
    getPrelims: function (data) {
      let response = data;

      let customers = response.data.customers;

      let DataCustomer = [];
      customers.forEach((value) => {
        DataCustomer.push({ id: value.id, name: value.company_name });
      });

      this.itemsDropdown = DataCustomer;

      let terminal_at = response.data.terminal;
      terminal_at.forEach((value) => {
        this.TERMINAL_AT.push({ id: value.id, name: value.name });
      });

      let size = response.data.size;
      size.forEach((value) => {
        this.containerSize.push({ id: value.id, name: value.name });
      });

      let containertype = response.data.type;
      containertype.forEach((value) => {
        this.containerType.push({ id: value.id, name: value.name });
      });

      let truckersID = response.data.trucker;
      truckersID.forEach((value) => {
        this.truckers.push({ id: value.id, name: value.name });
      });
      this.startloading = 0;
    },

    onChangeCreate() {
      // this.filelistCreate = [...this.$refs.fileCreate.files];
      // let table = this.filelistCreate;
      // let errorCount = 0;
      // let keys = [];

      // Object.keys(table).forEach(function eachKey(key) {
      //   if (table[key].type != "application/pdf") {
      //     keys.push(key);
      //     errorCount++;
      //   }
      // });

      // if (errorCount > 0) {
      //   this.errorFile = 1;
      //   this.allFieldsValid = false;
      // } else {
      //   this.errorFile = 0;
      //   this.allFieldsValid = true;
      // }

      // keys.forEach((e) => {
      //   this.filelistCreate.splice(e, keys.length + 1);
      // });

      // if (this.filelistCreate.length > 1) {
      //   this.filelistCreate.splice(1, this.filelistCreate.length);
      //   this.moreFilesError = true;
      // } else {
      //   this.moreFilesError = false;
      // }

      // console.log(keys, this.filelistCreate);

      let files = this.$refs.fileCreate.files;

      if (!files.length) {
        return false;
      }

      if (files[0].type !== "application/pdf") {
        this.notificationCustom("Invalid File Type");
      } else {
        this.filelistCreate = files;
      }
    },
    remove(i, e) {
      this.uploadTrigger = false;
      try {
        axios
          .delete(`${poBaseUrl}/upload/remove/` + e.id)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
          .then((response) => {
            this.responseDatatempt = response;
          });

        this.filelistCreate.splice(i, 1);
        if (this.filelistCreate.length == 0) {
          this.errorFile = 0;
          this.allFieldsValid = true;
        }
      } catch (err) {
        document.querySelector(".fileuploaderror").innerHTML = err.message;
        setTimeout(() => {
          document.querySelector(".fileuploaderror").style.display = "none";
        }, 5000);
      }
    },
    gotoDownload() {
      let passdata = this.objectServerid.id;
      this.isFetching = 1;
      if (passdata !== "") {
        axios({
          url: `${poBaseUrl}/newshipment/download/${passdata}`,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          this.isFetching = 0;
          window.URL = window.URL || window.webkitURL;
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/octet-stream" })
          );
          link.setAttribute("download", "Delivery_order.pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.fileCreate.files = event.dataTransfer.files;
      this.onChangeCreate();
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    async callUPdocument(data) {
      if (data) jQuery(".cDownloadfiles").hide();
      this.uploadCompleted = false;
      this.uploadTrigger = true;
      this.serverObject = [];
      var formData = new FormData();

      try {
        var pdfiles = document.getElementById("assetsFieldHandle");
        formData.append("fields[]", pdfiles.files[0]);
        formData.append("data[]", this.filelistCreate);
        formData.append("isCreateShipment", "Yes");

        await axios
          .post(`${poBaseUrl}/upload_file`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
          .then((response) => {
            this.uploadCompleted = true;
            this.serverObject = response.data;
            this.objectServerid = response.data.find((d) => d.id != "");
          });
      } catch (err) {
        document.querySelector(".fileuploaderror").innerHTML = err.message;
        setTimeout(() => {
          document.querySelector(".fileuploaderror").style.display = "none";
        }, 5000);
      }
    },
    handleReplace() {
      // jQuery(document).find(".triggerelementCreate").trigger("click");
      this.$refs.fileCreate.click();
    },
    // getFileURL(file) {
    //   //return URL.createObjectURL(file);
    //     if(this.hasfileni !=''){
    //           return (this.hasfileni);
    //       }else{
    //           this.hasfileni = URL.createObjectURL(file);
    //           return URL.createObjectURL(file);
    //       }
    // },

    getFileURL(file) {
      if (this.hasfileni !== "") {
        return this.hasfileni;
      } else {
        // remove toolbar by adding parameter #toolbar=0
        this.hasfileni = URL.createObjectURL(file) + "#toolbar=0";
        return URL.createObjectURL(file) + "#toolbar=0";
      }
    },
    handleETAValueChange() {
      // if (this.date === null) {
      //   this.isShowETAError = true
      // } else {
      //   this.isShowETAError = false
      //   this.date = this.date !== '' ? moment(this.date).format("YYYY-MM-DD") : ""
      // }
      if (this.date !== null) {
        this.isShowETAError = false;
        this.date =
          this.date !== "" && this.date !== null
            ? moment(this.date).format("YYYY-MM-DD")
            : "";
      }
    },
    removeFileUpload() {
      this.$refs.fileCreate.value = null;
      this.filelistCreate = [];
      this.hasfileni = "";
    },
    handleFocused(dynamicClass) {
      console.log("true");
      jQuery(`.${dynamicClass}`).remove();
    },
    // handleBlur(dynamicClass, placeHolder) {
    //   if(this.delivery_location !== null) {
    //     jQuery(`<span class="${dynamicClass}" style="color: #4a4a4a; font-size: 14px; line-height: 20px;">${this.delivery_location}</span>`).insertAfter(`input[placeholder="${placeHolder}"]`)
    //   }
    // },
    handleBlur() {},
    handleStartingFocused(dynamicClass) {
      jQuery(`.${dynamicClass}`).remove();
    },
    handleStartingBlur(dynamicClass, placeHolder) {
      //console.log(dynamicClass);
      //console.log(placeHolder);
      if (this.terminalAt !== null) {
        if (
          typeof this.terminalAt.firms_code != "undefined" &&
          typeof this.terminalAt.name != "undefined" &&
          typeof this.terminalAt.map_location != "undefined"
        ) {
          if (typeof this.terminalAt !== "number") {
            jQuery(
              `<span class="${dynamicClass}" style="color: #4a4a4a; font-size: 14px; line-height: 20px;">${
                this.terminalAt.firms_code +
                " | " +
                this.terminalAt.name +
                " | " +
                (this.terminalAt.map_location || "")
              }</span>`
            ).insertAfter(`input[placeholder="${placeHolder}"]`);
          } else {
            let terminal = this.terminalsDropdown.find(
              (terminal) => terminal.id === this.terminalAt
            );
            jQuery(
              `<span class="${dynamicClass}" style="color: #4a4a4a; font-size: 14px; line-height: 20px;">${
                terminal.firms_code +
                " | " +
                terminal.name +
                " | " +
                (terminal.map_location || "")
              }</span>`
            ).insertAfter(`input[placeholder="${placeHolder}"]`);
          }
        }
      }
    },
  },
  async mounted() {
    if (typeof this.$route.params.moveid != "undefined") {
      //let id = this.$route.params.id
      //
      // query to server
      this.isMovedo = 1;
      //this.fetchdo(this.$route.params.id);
    }
    // this.$store.dispatch("page/setPage", "delivery-order");
  },
  created() {
    this.eventBus.$on("changeIt", (data) => {
      this.showedit = data.show;
      this.keysvalue = data.data[data.index];
      this.keytovalue = data.index;
    });
  },
  updated() {
    // console.log(this.places);
  },
};
</script>
<style lang="scss">
@import "../../../../assets/scss/pages_scss/containers/containersTable.scss";
@import "../../../../assets/scss/pages_scss/dialog/globalDialog.scss";
//  @import '../../../assets/scss/pages_scss/deliveryorder/listcontainer.scss';
@import "../../../../assets/scss/buttons.scss";
@import "../../../../assets/scss/vc_calendar.scss";
@import "../../../../assets/scss/pages_scss/deliveryorder/createDo.scss";

.dialogClassDos.create-do {
  .v-card {
    .v-card__text {
      .deliveryOrderColumn {
        width: 100% !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 0 !important;
      }

      .text-item-label {
        font-family: "Inter-SemiBold", sans-serif !important;
        font-size: 10px !important;
        text-transform: uppercase !important;
      }

      .v-input {
        .v-input__control {
          .v-input__slot {
            padding: 4px 12px !important;

            &::after {
              background-color: #4a4a4a !important;
              border-color: #4a4a4a !important;
            }

            .v-input__append-inner {
              i {
                color: #4a4a4a !important;
              }
            }
          }
        }

        &.icon-append-custom {
          .v-input__control {
            .v-input__slot {
              .v-select__slot {
                .v-input__append-inner {
                  margin-top: 4px !important;

                  i {
                    color: #4a4a4a !important;
                  }
                }
              }
            }
          }
        }

        &.textarea-commodity {
          .v-input__control {
            .v-input__slot {
              padding-top: 8px !important;
              padding-right: 4px !important; // to set padding outside the textarea

              textarea {
                font-size: 14px !important;
                line-height: 20px;
                padding-right: 12px;
                font-family: "Inter-Regular", sans-serif;
              }
            }
          }
        }

        &.create-do-eta {
          .v-input__control {
            .v-input__slot {
              .v-input__append-inner {
                padding-left: 0;

                .v-input__icon {
                  .ic-calendar {
                    padding-top: 6px;
                  }
                }
              }
            }
          }
        }

        &.select-consignee.v-input--is-disabled {
          .v-input__control {
            .v-input__slot {
              background-color: #ebf2f5;
              border-color: #ebf2f5 !important;
              border-radius: 4px;
            }
          }
        }

        input {
          font-family: "Inter-Regular", sans-serif !important;
          font-size: 14px !important;
        }
      }

      .row-locations {
        .box {
          display: flex;
          flex-direction: column;

          .text-item-label {
            margin-bottom: 4px;
            font-family: "Inter-SemiBold", sans-serif !important;
          }

          .v-input {
            &.Goodsdeliverylocation {
              .v-input__control {
                height: 100%;

                .v-input__slot {
                  height: 100%;
                  padding: 8px 12px !important;

                  .v-select__slot {
                    min-height: 60px !important;

                    .v-select__selections {
                      align-self: flex-start;
                      flex-wrap: wrap;
                    }

                    input {
                      align-self: flex-start;
                      padding: 0;
                    }

                    .v-label {
                      color: #4a4a4a !important;
                      font-family: "Inter-Regular", sans-serif;
                      font-size: 14px;
                      position: unset !important;
                      overflow: unset !important;
                      white-space: normal !important;
                      height: 100% !important;
                      padding: 0;
                    }

                    .v-input__append-inner {
                      display: block !important;

                      .v-input__icon {
                        height: 20px;

                        i {
                          transition: none !important;
                          transform: none !important;

                          &.ic-search-address {
                            width: 26px !important;

                            &::before {
                              content: "" !important;
                              background-image: url("/icons/search-address.svg") !important;
                              background-position: center !important;
                              background-repeat: no-repeat !important;
                              background-size: cover !important;
                              width: 16px !important;
                              height: 18px !important;
                              opacity: 0.9;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              &.container-starting-location {
                .v-menu__content {
                  .v-list {
                    padding: 0 !important;

                    .v-list-item {
                      border-bottom: 1px solid #ebf2f5;
                      padding: 6px 16px;

                      .listDropdown {
                        color: #4a4a4a !important;
                        line-height: 20px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      // dxdatebox ETA
      .create-do-eta-dxdate {
        height: 42px;
        border: 1px solid #b4cfe0;
        border-radius: 4px;

        input {
          font-family: "Inter-Regular", sans-serif;
          caret-color: #4a4a4a;
          color: #4a4a4a;
          padding-left: 12px;
        }

        .dx-placeholder {
          color: #b4cfe0 !important;

          &::before {
            padding-left: 12px;
          }
        }

        .dx-texteditor-buttons-container {
          .dx-dropdowneditor-button {
            width: 45px;

            .dx-dropdowneditor-icon {
              width: 45px;
              color: #4a4a4a;

              &::before {
                content: "";
                background-image: url("/icons/calendar-black.svg") !important;
                background-position: center !important;
                background-repeat: no-repeat !important;
                background-size: cover !important;
                width: 18px !important;
                height: 18px !important;
              }
            }
          }
        }
      }

      .add-containers-table.containerListingTable.create-do {
        table {
          thead {
            tr {
              th {
                padding: 0 8px;
                box-shadow: none !important;
                font-size: 12px !important;

                &:first-child {
                  padding-left: 16px;
                }

                span {
                  font-size: 12px !important;
                }
              }
            }
          }

          tbody {
            .v-input {
              .v-input__control {
                .v-input__slot {
                  padding: 0 !important;

                  input {
                    line-height: 20px !important;
                  }

                  .v-select__slot {
                    padding: 2px 0 2px 8px !important;

                    .v-select__selection--comma {
                      font-size: 14px !important;
                    }
                  }

                  .v-input__append-inner {
                    padding-top: 14px;
                  }

                  .v-text-field__suffix {
                    color: #819fb2;
                    line-height: 20px;
                    padding-right: 8px;
                  }
                }
              }

              &.text-input-end {
                input {
                  text-align: right !important;
                }
              }
            }
          }
        }
      }

      /* Scrollbar */
      &::-webkit-scrollbar {
        width: 12px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background-color: #e2e2e2;
        border-radius: 6px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background-color: #e2e2e2;
      }
    }
  }
}

.v-menu__content.consignee-menu-lists-do {
  .v-list {
    padding: 0 !important;

    .v-list-item {
      min-height: 36px;
      border-bottom: 1px solid #ebf2f5 !important;
      padding: 5px 12px !important;

      .v-list-item__content {
        padding: 8px 0 !important;

        .v-list-item__title {
          font-size: 14px !important;
        }
      }
    }
  }
}
</style>

<style>
.create-do-form-wrapper {
  font-family: "Inter-Regular", sans-serif !important;
}

.p-form-errors {
  color: #ff5252;
  font-size: 12px;
  font-family: "Inter-Regular", sans-serif;
  margin-top: 4px;
  margin-bottom: 0 !important;
}

.reviewHeader-text.create-do {
  font-family: "Inter-SemiBold", sans-serif !important;
  color: #4a4a4a;
}
/* New style */
.createPickuplocation .v-input__slot,
.Goodsdeliverylocation .v-input__slot {
  background: none !important;
}
.topheader {
  padding-left: 19px;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 4px;
}
.topheader li {
  color: #6d858f !important;
  display: inline-block;
  width: 19%;
  word-break: break-all;
  cursor: pointer;
  font-size: 14px;
}
.addContainer {
  border-bottom: 1px solid #eee;
  border-top: 0px solid #eee;
  font-size: 14px;
  margin-top: 9px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
}
.leftcol {
  border-right: 1px solid #eee;
}
.data-element .item {
  border-bottom: 0px solid #eee;
  padding: 6px;
}
.headerContainer {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  align-items: center;

  color: #4a4a4a;
}
.tablecontainer {
  padding: 0 5px;
  border-bottom: 1px solid #ebf2f5;
  border-top: 1px solid #ebf2f5;
  font-size: 14px;
}
.tablecontainer:hover {
  background: rgb(240, 251, 255) none repeat scroll 0% 0% !important;
}
.tablecontainer li {
  display: inline-block;
  width: 19%;
  word-break: break-all;
  cursor: pointer;
  text-transform: uppercase;
  padding-left: 3px;
  height: 37px;
  color: #4a4a4a !important;
  padding-top: 6px;
  padding-bottom: 16px;
  margin-top: 3px;
}

.uploadDocuwrapper {
  margin-top: 7px !important;
  max-width: 48.7% !important;
}

.containers-wrappers {
  padding-top: 8px !important;
}

.container-create {
  padding-top: 0px !important;
}

.container-create .toolbHeader .v-toolbar__content {
  padding-left: 0px;
  padding-right: 0px;
}

.toolbHeader {
  margin-bottom: 4px;
}

.container-create .toolbHeader {
  background: none !important;
}

.NewContainerShipmentTitle {
  font-size: 24px;
  font-weight: 600;
}

.deliveryOrderColumn {
  background-color: #fff;
  padding: 0px;
  width: 99% !important;
  margin-left: 8px;
  margin-right: 0;
  margin-bottom: 0;
}

.vue-tags-input {
  max-width: 100% !important;
}

.vue-tags-input::before {
  bottom: -1px;
  content: "";
  left: 0;
  position: absolute;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}

.vue-tags-input::after {
  bottom: -1px;
  content: "";
  left: 0;
  position: absolute;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
  transform: scaleX(0);
}

.vue-tags-input.ti-focus::after {
  background-color: #4a4a4a !important;
  border: 1px solid #4a4a4a !important;
  transform: scaleX(1);
}

.ti-new-tag-input {
  caret-color: #4a4a4a !important;
}

.ti-tags .ti-tag {
  background: #f7f7f7;
  color: #4a4a4a;
  border: 1px solid #ebf2f5;
  border-radius: 4px;
  padding: 3px 8px;
}

div.ti-input {
  border-radius: 4px;
  border: 1px solid #b4cfe0 !important;
  min-height: 42px;
  width: 100% !important;
}

div.ti-input input {
  font-family: "Inter-Regular", sans-serif !important;
}

.uploadElement .hasContentFiles2 {
  background: #fff !important;
  padding: 6px !important;
  display: block !important;
  height: 106px !important;
  border: 2px dashed #b4cfe0 !important;
  border-radius: 5px;
}

.browserInput2 {
  width: 100%;
}

.browseFileDelivery {
  width: 100% !important;
  text-align: center;
  color: #b4cfe0 !important;
  padding-top: 12px;
  font-size: 14px !important;
}

.browseButton2 {
  width: 79px;
  font-size: 12px;
  margin: 0 auto;
  margin-top: 6px;
}

.wrappIconbutton2 {
  border: 1px solid #b4cfe0;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  padding: 5px;
  margin-top: 21px;
}
.classUpload {
  font-size: 12px;
  color: #0171a1;
  position: relative;
  left: 6px;
}

/* .tablecontainer li:hover {
    background: #fff;
} */

.ic-chevron-down {
  font-size: 16px !important;
}

.iconUploadfile {
  color: #0171a1;
}

.rowItemUploaded {
  background: #f7f7f7 !important;
  border-radius: 6px;
  padding: 10px;
  height: 64px;
}

.mainwrapitem {
  border: 0px !important;
}

.uploadCreateNew {
  position: relative;
  top: 3px;
  left: -1px;
  height: 15px;
}

.hidethisdiv {
  display: none !important;
}

.reupload {
  float: right;
  font-size: 14px !important;
  color: rgb(1, 113, 161);
  cursor: pointer;
  position: relative;
  top: 5px;
  left: 0px;
  margin-top: 3px;
  margin-left: 0px;
  margin-right: 1px;
}
.ic-calendar::before {
  font-size: 16px !important;
}

.lastchild:hover {
  background: none !important;
}

.document-btn {
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #b4cfe0;
  border-radius: 4px;
}

.replace-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0171a1;
}
.strtinglocationh {
  display: inline-block;
  height: 8.8px;
  position: relative;
  border-right: 1px solid #4a4a4a;
  margin-right: 3px;
  margin-left: 3px;
}

.tooltipContainer {
  display: inline-block;
  position: relative;
  border-bottom: 1px dotted #666;
  text-align: left;
}

.tooltipContainer h3 {
  margin: 12px 0;
}

.tooltipContainer .right {
  min-width: 300px;
  max-width: 450px;
  top: 50%;
  left: 100%;
  margin-left: 20px;
  transform: translate(0, -50%);
  padding: 0;
  color: #eeeeee;
  background-color: #444444;
  font-weight: normal;
  font-size: 13px;
  border-radius: 4px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  visibility: visible;
  opacity: 1;
  transition: opacity 0.8s;
}

.tooltipContainer .right {
  visibility: visible;
  opacity: 1;
}

.tooltipContainer .right img {
  width: 400px;
  border-radius: 8px 8px 0 0;
}
.tooltipContainer .text-content {
  padding: 10px 20px;
}

.tooltipContainer .right i {
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -12px;
  width: 12px;
  height: 24px;
  overflow: hidden;
}
.tooltipContainer .right::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.custom_notes {
  color: #4a4a4a;
  font-size: 14px;
  line-height: 20px;
}

/*tipped element. should be inline-block or block*/
.qtip {
  display: inline-block;
  position: relative;
  cursor: pointer;
  color: #3bb4e5;
  border-bottom: 0.05em dotted #3bb4e5;
  box-sizing: border-box;
  font-style: normal;
  transition: all 0.25s ease-in-out;
  text-transform: none !important;
}
.qtip:hover {
  color: #069;
  border-bottom: 0.05em dotted #069;
}
/*the tip*/
.qtip:before {
  content: attr(data-tip);
  font-size: 9px;
  position: absolute;
  background: #819fb2;
  color: #fff;
  line-height: 1.2em;
  padding: 0.5em;
  font-style: normal;
  box-shadow: 0 1px 3px #819fb2;
  min-width: 140px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  text-shadow: 1px 1px 1px #819fb2;
  font-family: sans-serif;
  letter-spacing: 0;
}
.qtip:after {
  width: 0;
  height: 0;
  border-style: solid;
  content: "";
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.qtip:hover:before,
.qtip:hover:after {
  visibility: visible;
  opacity: 1;
}
/*top*/
.qtip.tip-top:before {
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 8px));
  box-sizing: border-box;
  border-radius: 3px;
}
.qtip.tip-top:after {
  border-width: 8px 8px 0 8px;
  border-color: #819fb2 transparent transparent transparent;
  top: -8px;
  left: 50%;
  transform: translate(-50%, 0);
}
/*bottom*/
.qtip.tip-bottom:before {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 8px));
  box-sizing: border-box;
  border-radius: 3px;
}
.qtip.tip-bottom:after {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #819fb2 transparent;
  bottom: -8px;
  left: 50%;
  transform: translate(-50%, 0);
}
/*left*/
.qtip.tip-left:before {
  left: 0;
  top: 50%;
  transform: translate(calc(-100% - 8px), -50%);
  box-sizing: border-box;
  border-radius: 3px;
}
.qtip.tip-left:after {
  border-width: 8px 0 8px 8px;
  border-color: transparent transparent transparent #819fb2;
  left: -8px;
  top: 50%;
  transform: translate(0, -50%);
}
/*right*/
.qtip.tip-right:before {
  right: 0;
  top: 50%;
  transform: translate(calc(100% + 8px), -50%);
  box-sizing: border-box;
  border-radius: 3px;
}
.qtip.tip-right:after {
  border-width: 8px 8px 8px 0;
  border-color: transparent #819fb2 transparent transparent;
  right: -8px;
  top: 50%;
  transform: translate(0, -50%);
}

.containerListingTable .v-text-field__details {
  display: none !important
}
</style>
