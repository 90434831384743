<template>
  <div class="dos-table-wrapper-content">
    <div class="driverHeader">
      <h2
        style="
          font-family: 'Inter-Medium', sans-serif !important;
          font-weight: unset;
        "
      >
        Equipments
      </h2>

      <div>
        <v-btn
          color="primary"
          class="btn-blue add-supplier addCustomer"
          @click="addNewEquipment"
        >
          + Add Equipments
        </v-btn>
      </div>
    </div>

    <v-data-table
      :headers="headers"
      mobile-breakpoint="400"
      :items="getChassis"
      class="driver-table elevation-1 chassis-table-wrapper"
      hide-default-footer
      :page.sync="page"
      @page-count="pageCount = $event"
      :items-per-page="itemsPerPage"
      fixed-header
    >
      <template v-slot:top>
        <v-toolbar class="headerClass DosHeadertabs" style="" flat>
          <v-tabs class="btabsclass" v-model="tabsmodel">
            <v-tab value="1">Chasis</v-tab>
            <!-- <v-tab value="2">Trucks</v-tab> -->
            <!-- <v-tab value="2">Trailers</v-tab> -->
          </v-tabs>
        </v-toolbar>
      </template>

      <template v-slot:[`item.trucker_owned`]="{ item }">
        <p class="mb-0">{{ item.trucker_owned == "1" ? "Yes" : "No" }}</p>
      </template>
      <template v-slot:[`item.last_inspection`]="{ item }">
        <p class="mb-0">
          {{
            item.last_inspection
              ? new Date(item.last_inspection).toDateString()
              : "--"
          }}
        </p>
      </template>
      <template v-slot:[`item.next_inspection`]="{ item }">
        <p class="mb-0">
          {{
            item.next_inspection
              ? new Date(item.next_inspection).toDateString()
              : "--"
          }}
        </p>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div
          :class="
            item.status == 'In Use'
              ? 'status-div-shadow status-div-in'
              : 'status-div'
          "
          style="position: relative; margin-left: 18px"
        >
          <p class="status-txt">
            {{ item.status }}
          </p>
          <i
            @mouseover="statusHover(item, true)"
            @mouseleave="statusHover(item, false)"
            style="font-size: 14px; color: #0084bd"
            class="ic-info ml-2 mb-1 mt-1"
          ></i>
          <div
            style="position: absolute; left: 3px; top: 10px"
            v-if="
              hoverInfo.status &&
              hoverInfo.id == item.id &&
              item.status == 'In Use'
            "
          >
            <div class="cone-div" />
            <div class="cone-div-info">
              <p style="margin-top: 15px" class="white--text">
                Last Track 5m ago
              </p>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flexs justify-center mr-1">
          <v-btn
            small
            width="55"
            style="margin-right: 8px; min-height: 30px !important"
            class="truckerMoreBtn"
            @click="editChassisItem(item)"
          >
            <img class="" src="@/assets/icons/EditEquip.svg" alt="" />
            <span class="EditBtn">Edit</span>
          </v-btn>
          <v-menu
            offset-y
            bottom
            left
            min-width="120px !important"
            content-class="dos-table-menu trucking-menu"
          >
            <!-- <v-menu offset-y nudge-left="12" nudge-top="-5"> -->
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs" v-on="on" class="EditMoreBtn">
                <img src="@/assets/icons/more-blue.svg" alt="" />
              </v-btn>
            </template>
            <v-list class="trucking_DropDown" style="overflow: hidden">
              <v-list-item class="trucking-archived-wrapper" style="padding: 5px 14px; min-height: 34px;">
                <v-list-item-title
                  class="trucking-archived-item"
                  @click="displayChassisView(item)"
                >
                  <span style="padding-right: 4px">
                    <svg
                      style="position: relative; top: 2px"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_9327_10539)">
                        <path
                          d="M1.20537 9.66212L0.800803 10.0621L1.20537 9.66212ZM1.20537 6.33788L0.800803 5.93787L1.20537 6.33788ZM14.7946 9.66212L15.1992 10.0621L14.7946 9.66212ZM14.7946 6.33788L15.1992 5.93787L14.7946 6.33788ZM14.3901 9.26209C12.9464 10.8423 10.5898 12.8387 8 12.8387V14C11.0891 14 13.7319 11.6681 15.1992 10.0621L14.3901 9.26209ZM8 12.8387C5.41023 12.8387 3.0536 10.8423 1.60993 9.26209L0.800803 10.0621C2.26806 11.6681 4.91087 14 8 14V12.8387ZM1.60993 6.73791C3.0536 5.1577 5.41023 3.16129 8 3.16129V2C4.91087 2 2.26806 4.33186 0.800803 5.93787L1.60993 6.73791ZM8 3.16129C10.5898 3.16129 12.9464 5.1577 14.3901 6.73791L15.1992 5.93787C13.7319 4.33186 11.0891 2 8 2V3.16129ZM1.60993 9.26209C0.951732 8.54163 0.951732 7.45837 1.60993 6.73791L0.800803 5.93787C-0.266934 7.10658 -0.266934 8.89342 0.800803 10.0621L1.60993 9.26209ZM15.1992 10.0621C16.2669 8.89342 16.2669 7.10658 15.1992 5.93787L14.3901 6.73791C15.0483 7.45837 15.0483 8.54163 14.3901 9.26209L15.1992 10.0621ZM9.67442 8C9.67442 8.96201 8.92473 9.74194 8 9.74194V10.9032C9.54129 10.9032 10.7907 9.60343 10.7907 8H9.67442ZM8 9.74194C7.07528 9.74194 6.32558 8.96201 6.32558 8H5.2093C5.2093 9.60343 6.45874 10.9032 8 10.9032V9.74194ZM6.32558 8C6.32558 7.03799 7.07528 6.25806 8 6.25806V5.09677C6.45874 5.09677 5.2093 6.39659 5.2093 8H6.32558ZM8 6.25806C8.92473 6.25806 9.67442 7.03799 9.67442 8H10.7907C10.7907 6.39659 9.54129 5.09677 8 5.09677V6.25806Z"
                          fill="#6D858F"
                        />
                      </g>
                    </svg>
                  </span>
                  <span class="ml-1">View</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template v-slot:no-data>
        <div class="no-data-preloader mt-4" v-if="getChassisLoading">
          <v-progress-circular
            :size="40"
            color="#0171a1"
            indeterminate
            v-if="getChassisLoading"
          >
          </v-progress-circular>
        </div>
      </template>
    </v-data-table>

    <Pagination
      :pageData.sync="page"
      :lengthData.sync="pageCount"
      :isMobile="isMobile"
    />

    <ChassisNewDialog
      :AddEquipment="AddEquipment"
      @closeEquipment="closeEquipment"
    />
    <ChassisEditDialog
      :dialogEdit="dialogEdit"
      :editChassisItemData="editChassisItemData"
      @closeEditChassis="closeEditChassis"
    />
  </div>
</template>

<script>
import ChassisNewDialog from "./dialog/Chassis/ChassisNewDialog.vue";
import ChassisEditDialog from "./dialog/Chassis/ChassisNewEditDialog.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import globalMethods from "../../../utils/globalMethods";
import Pagination from "../../Pagination.vue";

export default {
  components: {
    Pagination,
    ChassisNewDialog,
    ChassisEditDialog,
  },
  props: ["isMobile"],
  data() {
    return {
      tabsmodel: [],
      AddEquipment: false,
      editChassisItemData: "",
      dialogEdit: false,
      itemsPerPage: 20,
      page: 1,
      pageCount: 2,
      hoverInfo: {
        id: null,
        status: false,
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Number",
          align: "start",
          value: "number",
          sortable: false,
          width: "15%",
          fixed: true,
        },
        {
          text: "Type",
          align: "start",
          value: "type",
          sortable: false,
          width: "15%",
          fixed: true,
        },
        {
          text: "Company Owned",
          align: "center",
          value: "trucker_owned",
          sortable: false,
          width: "15%",
          fixed: true,
        },
        {
          text: "Last Inspection",
          align: "start",
          value: "last_inspection",
          sortable: false,
          width: "15%",
          fixed: true,
        },
        {
          text: "Next Inspection",
          align: "start",
          value: "next_inspection",
          sortable: false,
          width: "15%",
          fixed: true,
        },
        {
          text: "Status",
          align: "center",
          value: "status",
          sortable: false,
          width: "10%",
          fixed: true,
        },
        {
          text: "",
          align: "end",
          value: "actions",
          sortable: false,
          width: "12%",
          fixed: true,
        },
      ];
    },
    ...mapGetters({
      getChassis: "chassis/getList",
      getChassisLoading: "chassis/getChassisLoading",
      getalert_box: "chassis/getalert_box",
      get_alert_box_text: "chassis/get_alert_box_text",
      get_alert_type: "chassis/get_alert_type",
      getTruckerId: "chassis/getTruckerId",
    }),
  },
  watch: {
    getalert_box() {
      setTimeout(() => {
        this.changeAlertBoxStatus(false);
      }, 2000);
    },
  },
  methods: {
    ...globalMethods,
    ...mapActions("chassis", ["getChassisFromApi", "changeAlertBoxStatus"]),
    ...mapMutations("chassis", ["SET_CHASSIS_ITEM"]),
    closeEquipment() {
      this.AddEquipment = false;
    },
    closeEditChassis() {
      this.dialogEdit = false;
      this.editChassisItemData = "";
    },
    addNewEquipment() {
      this.AddEquipment = true;
    },
    editChassisItem(item) {
      this.editChassisItemData = item;
      this.dialogEdit = true;
    },
    statusHover(item, current) {
      this.hoverInfo.id = item.id;
      this.hoverInfo.status = current;
    },
    displayChassisView(item) {
      this.SET_CHASSIS_ITEM(item);
      this.$router.push(`/chassis/view/${item.id}`);
    },
  },
  mounted() {
    this.getChassisFromApi();
  },
};
</script>

<style lang="scss">
.viewEquip {
  font-size: 14px;
  color: #4a4a4a;
  font-weight: 400;
  font-family: "Inter-Regular" !important;
  margin-top: 15px;
  margin-left: 2px;
  text-transform: none;
  text-decoration: none !important;
}
.EditBtn {
  color: #0171a1;
  font-weight: 500;
  font-size: 12px;
  text-transform: capitalize;
  font-family: "Inter-Medium", sans-serif !important;
  line-height: 18px;
  letter-spacing: 0;
  margin-left: 3px;
}
.cone-div {
  position: absolute;
  top: -17px;
  left: 53px;
  width: 0;
  height: 0;
  border-top: 6px solid #4a4a4a;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}
.cone-div-info {
  position: absolute;
  top: -52px;
  left: -17px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  background-color: #4a4a4a;
  border-radius: 4px;
  padding: 0px 15px;
  height: 35px;
}
.status-div-in {
  background-color: #f5f9fc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  width: 80px;
  height: 31px;
  .status-txt {
    margin-top: 15px;
    color: #4a4a4a;
    font-size: 12px;
    font-weight: 500;
  }
}
.status-div {
  background-color: #f5f9fc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  width: 90px;
  height: 31px;
  .status-txt {
    margin-top: 15px;
    color: #4a4a4a;
    font-size: 12px;
    font-weight: 500;
  }
}

.EditMoreBtn {
  height: 30px !important;
  width: 30px !important;
  background-color: #fff !important;
  border: 1px solid #b4cfe0;
  box-shadow: none;
  min-width: 30px !important;
}

//

//
.status-div-shadow {
  box-shadow: 0px 3px 5px #e3e5e6;
}

.dos-table-wrapper-content {
  position: relative;

  .loading-overlay {
    position: absolute;
    z-index: 1000;
    left: 0;
    right: 0;
    bottom: 0;
    top: 112px;
    background-color: #fff;
    padding: 24px;
    display: flex;
    justify-content: center;
    border: 1px solid #ebf2f5;
    height: calc(100% - 108px);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .v-data-table.chassis-table-wrapper {
    box-shadow: none !important;
    border: 1px solid #ebf2f5;

    .DosHeadertabs {
      height: 54px !important;

      .v-toolbar__content {
        height: 54px !important;

        .v-tabs {
          .v-tab {
            padding: 0 20px !important;
            letter-spacing: 0 !important;
            font-family: "Inter-SemiBold", sans-serif !important;
            font-weight: unset !important;
            color: #4a4a4a !important;
            text-transform: capitalize !important;

            &.v-tab--active {
              color: #0171a1 !important;
            }
          }
        }
      }
    }

    .v-data-table__wrapper {
      height: calc(100vh - 278px);
      overflow-y: auto;

      table {
        thead {
          tr {
            th {
              box-shadow: none !important;
              border-bottom: 2px solid #ebf2f5 !important;
              border-top: 2px solid #ebf2f5 !important;
              height: 40px;
              padding: 8px !important;
              text-transform: capitalize;
              font-size: 14px !important;

              span {
                font-size: 14px !important;
                font-family: "Inter-Regular" !important;
              }

              &:first-child {
                padding-left: 20px !important;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              border-bottom: 1px solid #ebf2f5 !important;
              padding: 10px 8px !important;
              font-size: 14px;

              &:first-child {
                padding-left: 20px !important;
              }

              &:nth-child(5),
              &:nth-child(6) {
                vertical-align: top;
                padding: 14px 8px !important;
              }

              .action-button-wrapper-do {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .truckerMoreBtn2 {
                  height: 30px !important;
                  width: 30px;
                  min-width: 30px !important;
                  min-width: 30px !important;
                  background-color: #fff !important;
                  border: 1px solid #b4cfe0;
                  box-shadow: none;
                }

                .btn-white.reviewbutton {
                  height: 30px !important;
                  font-size: 12px !important;
                  margin-right: 6px;
                  padding: 4px 10px !important;
                }
              }
            }

            &.v-data-table__empty-wrapper {
              cursor: unset;

              &:hover {
                background-color: #fff !important;
              }

              td {
                border-bottom: none !important;
              }
            }
          }
        }
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #d3d3d3;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #d3d3d3;
      }
    }

    .btn-black.archived {
      min-width: 75px !important;
      padding: 0 !important;
    }
  }

  .pagination-wrapper {
    border: 1px solid #ebf2f5 !important;
    border-top: none !important;
  }
}

.v-menu__content {
  &.dos-table-menu {
    min-width: 150px !important;

    .trucking-archived-wrapper {
      min-height: 38px;
      cursor: pointer;

      .v-list-item__title {
        font-size: 14px !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .trucking_DropDown > div {
      width: 100% !important;
    }
  }
}
@media screen and (min-width: 1500px) {
  .status-div {
    margin-left: 50px !important;
  }
  .status-div-in {
    margin-left: 50px !important;
  }
}
</style>
