<template>
  <div class="text-center">
    <v-dialog
      persistent
      v-model="dialogEdit"
      max-width="590px"
      content-class="add-supplier-dialog add-driver-dialog  dialogYard"
      :retain-focus="false"
    >
      <v-card class="add-supplier-card">
        <v-form
          id="createdriver"
          ref="form"
          v-model="valid"
          action="#"
          @submit.prevent=""
        >
          <v-card-title style="caret-color: transparent">
            <span class="headline">Update Chassis </span>

            <button icon dark class="btn-close" @click="close">
              <v-icon>mdi-close</v-icon>
            </button>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-row>
              <v-col
                style="color: red"
                v-if="errorFound"
                cols="12"
                sm="12"
                md="12"
                class="pb-3 pt-1"
              >
                {{ errorMessage }}
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-3 pt-1">
                <label class="text-item-label">Number</label>
                <v-text-field
                  outlined
                  dense
                  v-on:keypress="ISALPHANUMERIC($event)"
                  :rules="inputRules"
                  v-model="editChassisItemData.number"
                  name="name"
                  hide-details="auto"
                  style="font-size: 14px !important"
                >
                </v-text-field>
                <!-- <v-text-field
                  outlined
                  dense
                  :rules="inputRules"
                  v-model="editChassisItemData.number"
                  v-on:keypress="ISALPHANUMERIC($event)"
                  class="DialogInputfield"
                  name="name"
                  hide-details="auto"
                >
                </v-text-field> -->
              </v-col>

              <v-col cols="12" sm="12" class="pt-0 pb-0" md="12">
                <label class="text-item-label">Chassis Type</label>
                <v-autocomplete
                  v-model="editChassisItemData.chassis_type_id"
                  item-text="name"
                  item-value="id"
                  :items="getChassisType"
                  background-color="white"
                  :rules="selectRules"
                  outlined
                  hide-details="auto"
                  append-icon="ic-chevron-down"
                  dense
                ></v-autocomplete>
                <!-- <v-autocomplete
                  v-model="editChassisItemData.chassis_type_id"
                  :items="getChassisType"
                  class="inputselect"
                  item-text="name"
                  item-value="id"
                  dense
                  outlined
                  append-icon="ic-chevron-down"
                >
                </v-autocomplete> -->
              </v-col>
              <v-col cols="12" sm="12" class="pt-0 pb-2 mt-0" md="12">
                <label class="text-item-label">Company Owned </label>

                <v-radio-group
                  v-model="editChassisItemData.trucker_owned"
                  class="mt-0"
                >
                  <v-radio label="Yes" class="custom-radio" value="1"></v-radio>
                  <v-radio label="No" class="custom-radio" value="0"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions
            style="
              padding-left: 4px;
              padding-left: 10px;
              margin-top: 5px;
              border-top: 2px solid #ebf2f5;
            "
          >
            <div class="text-right buttonActions" style="padding: 0px">
              <div class="row">
                <div class="col-md-12 px-5">
                  <div class="row">
                    <div class="col-md-6 ml-1 mr-1">
                      <v-btn class="btn-blue" text @click="save">
                        <span>
                          <span color="#fff">
                            <v-progress-circular
                              :size="15"
                              color="#fff"
                              :width="2"
                              indeterminate
                              v-if="getUpdateChassisLoading"
                              style="margin-right: 3px"
                            >
                            </v-progress-circular>
                            Save Changes
                          </span>
                        </span>
                      </v-btn>
                    </div>
                    <div class="col-md-4">
                      <v-btn class="btn-white" text @click="close">
                        Cancel
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["dialogEdit", "editChassisItemData"],
  data: () => {
    return {
      trucker_owned: 1,
      errorFound: 0,
      errorMessage: "",
      errorContent: "",
      errorSaving: 0,
      imran: true,
      valid: true,
      chassisType: 0,
      inputRules: [
        (v) => !!v || "This field is required",
        // (v) => v || v.length > 3 || "Minimum of 4 characters",
        // (v) => v.length < 20 || "Maximum of 20 alphanumeric characters",
        (v) => /^[A-Za-z0-9]+$/.test(v) || "Aphanumeric only",
      ],
      selectRules: [(v) => !!v || "This field is required"],
    };
  },
  computed: {
    ...mapGetters({
      getUpdateChassisLoading: "chassis/getUpdateChassisLoading",
      getChassisType: "chassis/getChassisType",
      get_alert_box_text: "chassis/get_alert_box_text",
      getdetails: "chassis/getdetails",
    }),
  },
  watch: {
    dialogEdit(c) {
      if (c) {
        this.trucker_owned = parseInt(this.editChassisItemData.trucker_owned);
      }
      if (!c || c) {
        this.errorFound = 0;
      }
    },
    getdetails(newValue) {
      if (newValue.status === 1) {
        this.$emit("closeEditChassis");
      }
    },
  },
  methods: {
    ...mapActions("chassis", ["editChassisItem"]),
    close() {
      this.$emit("closeEditChassis");
    },
    ISALPHANUMERIC(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z0-9]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    async save() {
      var findme = this.getChassisType.find(
        (d) => d.id == this.editChassisItemData.chassis_type_id
      );
      this.editChassisItemData.type = findme.name;
      // this.editChassisItemData.trucker_owned =
      //   this.trucker_owned == true ? 1 : 0;

      if (this.$refs.form.validate()) {
        await this.editChassisItem(this.editChassisItemData);
        if (this.getdetails.status) {
          this.close();
          this.$emit("syncAction", {
            created: 0,
            msg: this.get_alert_box_text,
          });
        } else {
          this.errorFound = 1;
          this.errorMessage = this.getdetails.msg;
        }
      }
    },
  },
};
</script>
<style>
.v-text-field--outlined >>> fieldset {
  border-color: #b4cfe0;
  border-width: 1px;
}
.v-select--is-menu-active {
  background: none !important;
  border: 0px !important;
}
</style>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #b4cfe0;
  border-width: 1px;
}
.v-select--is-menu-active {
  background: none !important;
  border: 0px !important;
}
.v-text-field__slot input {
  font-size: 14px !important;
}
.v-input__slot {
  border-radius: 6px;
  height: 28px !important;
  min-height: 39px !important;
}
.custom-radio .v-label {
  font-size: 12px !important;
  color: #4a4a4a;
  font-weight: 500;
}
.custom-radio .v-icon.v-icon {
  font-size: 20px;
}
</style>
