<template>
  <div class="supplier-container" v-resize="onResize">
    <ChassisView mode="view" :isMobile="isMobile" v-if="renderComponent" />
  </div>
</template>
<script>
import ChassisView from "../components/Tables/Equipment/View/ChassisView.vue";
export default {
  name: "createContainers",
  components: {
    ChassisView,
  },
  data: () => ({
    renderComponent: true,
    dataFormfield: [],
    isMobile: false,
    dialog: false,
    dialogView: false,
    editedIndex: -1,
    editedItem: {
      invoice_no: "",
      invoice_date: "",
      shipment_reference: "",
      due_date: "",
      amuont: "",
    },
    defaultItem: {
      invoice_no: "",
      invoice_date: "",
      shipment_reference: "",
      due_date: "",
      amuont: "",
    },
  }),
  methods: {
    onResize() {
      if (window.innerWidth < 1024) {
        this.isMobile = true;
        this.activeColor = "#0171A1";
      } else {
        this.isMobile = false;
        this.activeColor = "white";
      }

      if (window.innerWidth > 1023 && window.innerWidth < 1201) {
        this.isTablet = true;
      } else {
        this.isTablet = false;
      }
    },
  },

  mounted() {
    this.$store.dispatch("page/setPage", "chassis");
  },
};
</script>

<style lang="scss">
@import "../assets/scss/pages_scss/drivers/driver.scss";
@import "../assets/scss/pages_scss/drivers/driver_css.scss";
@import "../assets/scss/buttons.scss";
</style>
