<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            width="1100"
            
        >
            <v-card class="bkgd-faded-blue">
                <v-card-title>
                    <span class="text-h5">Edit Consignee</span>
                </v-card-title>

                <div class="px-6 pt-6">
                    <label class="section-text">General Information</label>
                </div>
                <v-form ref="form" v-model="customerForm" id="editConsignee" action="#" @submit.prevent="">
                    <v-container class="px-8 py-8">
                        <v-row class="bkgd-white px-2 py-2">
                            <v-col
                                cols="12"
                                sm="6"
                                md="6"
                                class="py-0"
                            >
                                <label class="text-item-label">
                                    Consignee Name
                                </label>
                                <div>
                                    <v-text-field
                                        type="text"
                                        placeholder="Enter Name"
                                        outlined
                                        class="text-fields"
                                        v-model="consignee_name"
                                        :rules="requiredFields"
                                    />
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                md="6"
                                class="py-0"
                            >
                                <!-- <label class="text-item-label">Customer Email</label>
                                <div>
                                    <v-text-field
                                        type="text"
                                        placeholder="Enter Company Name"
                                        outlined
                                        class="text-fields"
                                    />
                                </div> -->
                                <label class="text-item-label">Consignee Email</label>
                                <div class="tags-email-wrapper mb-1">
                                    <vue-tags-input
                                        hide-details="auto"
                                        :rules="arrayNotEmptyRules"
                                        :tags="options"
                                        :add-on-blur=true
                                        :class="myclass"
                                        class="DialogInputfield"
                                        :add-on-key="[13, ',']"
                                        :validation="myclass.length > 0 ? tagsValidation : tagsValidation"
                                        v-model="email_address"
                                        placeholder="Enter Email Address"
                                        @input="tagsInput.hasError = false"

                                        @tags-changed="newTags => {
                                            this.myclass = 'noclass';
                                            this.options = newTags
                                            this.tagsInput.touched = true
                                            this.tagsInput.hasError = (this.options.length > 0) ? false : true
                                            let el = this.documentProto.getElementsByClassName('ti-input')[0]
                                            if (typeof el!=='udnefined') {
                                                /*if (this.tagsInput.hasError)
                                                    el.classList.add('ti-new-tag-input-error')
                                                else
                                                    el.classList.remove('ti-new-tag-input-error')*/
                                            }
                                        }"
                                    />
                                </div>

                                <span v-show="tagsInput.hasError" class="error--text" style="font-size: 12px;">
                                    Please add atleast (1) email address
                                </span>

                                <span v-show="!tagsInput.touched" style="color: #819FB2; font-size: 12px;">
                                    Press the "Enter" or "," key in your keyboard to confirm the email address
                                </span>

                                <div style="height: 14px;">
                                    <div v-if="tagsInput.touched" class="v-text-field__details">
                                        <div class="v-messages theme--light error--text" role="alert">
                                            <div class="v-messages__wrapper">
                                                <div v-if="(options.length > 0) && email_address !==''" class="v-messages__message">
                                                    {{
                                                        tagsInput.errorMessage
                                                    }}
                                                </div>

                                                <div v-if="options.length == 0 && email_address !==''" class="v-messages__message">
                                                    {{
                                                        tagsInput.errorMessage
                                                    }}
                                                </div>
                                                <!-- <div v-if="options.length == 0 && email_address ==''" class="v-messages__message">
                                                    Please provide at least 1 valid email address.
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col 
                                cols="12"
                                sm="6"
                                md="6"
                                class="py-0"
                            >
                                <label class="text-item-label">Address</label>
                                <textarea
                                    rows="5"
                                    cols="56"
                                    style="resize: none"
                                    class="textarea-field"
                                    placeholder="Enter Address"
                                    v-model="address"
                                >
                                </textarea>
                            </v-col>
                            <v-col 
                                cols="12"
                                sm="6"
                                md="6"
                                class="py-0"
                            >
                                <label class="text-item-label">Billing/Quotation Note</label>
                                <textarea
                                    rows="5"
                                    cols="56"
                                    style="resize: none"
                                    class="textarea-field"
                                    placeholder="Enter Billing/Quotation Note"
                                    v-model="note"
                                >
                                </textarea>
                            </v-col>
                        </v-row>
                    </v-container>

                    <div class="px-6 pt-6">
                        <label class="section-text">Contacts</label>
                    </div>
                    <v-container class="px-6 py-6">
                        <div class="px-4 py-4 bkgd-white">
                            <v-data-table
                                class="add-contacts-table"
                                :headers="headers"
                                :items="contacts"
                                item-key="email"
                                hide-default-footer
                                dense
                            >
                                <template v-slot:body="{ items }">
                                    <tr v-for="(item, index) in items" :key="index">
                                        <td
                                            class="text-start"
                                            style="border: 1px solid #ebf2f5; width: 200px"
                                        >
                                            <v-text-field
                                                type="text"
                                                dense
                                                hide-details="auto"
                                                placeholder="Enter Contact Name"
                                                class="text-fields qbContacts container-nbr-ph"
                                                flat
                                                solo
                                                validate-on-blur
                                                v-model="item.contact_name"
                                                @change="handleChange('contact_name', $event, index)"
                                            />
                                        </td>
                                        <td
                                            class="text-end"
                                            style="border: 1px solid #ebf2f5; border-left: none"
                                        >
                                            <vue-tel-input
                                                mode="international"
                                                defaultCountry="us"
                                                validCharactersOnly
                                                :autoDefaultCountry="true"
                                                :inputOptions="vueTelInputOptions"
                                                v-model="item.phone_number"
                                                @input="(s, p) => handlePhoneNumber(s, p, index)"
                                            >
                                                <template v-slot:arrow-icon>
                                                    <v-icon class="ml-1">mdi-chevron-down</v-icon>
                                                </template>
                                            </vue-tel-input>
                                        </td>
                                        <td
                                            class="text-end"
                                            style="border: 1px solid #ebf2f5; border-left: none"
                                        >
                                            <v-text-field
                                                flat
                                                solo
                                                type="email"
                                                placeholder="Enter Email Address"
                                                class="text-fields qbContacts inlinefield text-end"
                                                dense
                                                hide-details="message"
                                                full-width
                                                :rules="emailRules"
                                                v-model="item.email"
                                                @change="handleChange('email', $event, index)"
                                            >
                                            </v-text-field>
                                        </td>
                                        <td
                                            class="text-end"
                                            style="border: 1px solid #ebf2f5; border-left: none"
                                        >
                                            <div class="flex justify-center">
                                                <input
                                                    class="empty-checkbox my-checkbox"
                                                    type="checkbox"
                                                    :checked="item.primary"
                                                    @change="handleChange('primary', $event, index)"
                                                />
                                            </div>
                                        </td>
                                        <td
                                            class="text-end"
                                            style="border: 1px solid #ebf2f5; border-left: none"
                                        >
                                            <v-text-field
                                                flat
                                                solo
                                                type="text"
                                                placeholder="Enter Role"
                                                class="text-fields qbContacts text-end"
                                                dense
                                                hide-details="auto"
                                                full-width
                                                v-model="item.role"
                                                @change="handleChange('role', $event, index)"
                                            />
                                        </td>
                                        <td
                                            class="text-center"
                                            style="border: 1px solid #ebf2f5; border-left: none"
                                            @click="handleRemoveContact(index)"
                                        >
                                            <v-icon
                                                size="19"
                                                :style="'color:red'"
                                                style="cursor: pointer"
                                            >mdi-close</v-icon>
                                        </td>
                                    </tr>
                                    <!-- <tr v-show="index === (contacts.length-1)"> -->
                                    <tr
                                        class="px-8 "
                                    >
                                        <td 
                                            colspan="6" 
                                            class="px-3 py-2"
                                            style="border-top: 1px solid #B4CFE0 !important;"
                                        >
                                            <span class="add-more-btn" @click="handleAddContact">+ Add More Contact</span>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </div>
                    </v-container>
                </v-form>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="buttonActions d-flex justify-start align-center pr-0" style="width: 100%;">
                    <v-btn :disabled="isFetching" class="btn-blue" text @click="save">
                        <span>
                            <span>
                                <v-progress-circular
                                    :size="15"
                                    color="#fff"
                                    :width="2"
                                    indeterminate
                                    v-if="getIsEditing"
                                    style="margin-right:3px" >
                                </v-progress-circular>
                                Save Changes
                            </span>
                        </span>
                    </v-btn>
                    <v-btn class="btn-white" text @click="handleClose">
                        Cancel
                    </v-btn>
                </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>

// import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import VueTagsInput from '@johmun/vue-tags-input';
import { VueTelInput } from "vue-tel-input";
// import "vue-tel-input/dist/vue-tel-input.css";
import globalMethods from '../../../../utils/globalMethods';
import jQuery from 'jquery';
// const APIBaseUrl = process.env.VUE_APP_PO_URL;

export default {
    name: 'EditConsigneeDialog',
    props: ['dialog', 'itemData'],
    components: {
        VueTagsInput,
        VueTelInput
    },
    data: () => ({
        arrayNotEmptyRules: [
            () => this.optionsFiltered.length > 0 || "Make sure to supply at least 1 email."
        ],
        requiredFields: [(v) => !!v || v === 0 || "This field is required"],
        tagsValidation: [
            {
            classes: 't-new-tag-input-text-error',
            rule: (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
            disableAdd: true
            }
        ],
        numberRules: [
            //(v) => !!v || "Input is required.",
            (v) => !v || /^(?=.*[0-9])[- +()0-9]+$/.test(v) || "Letters are not allowed."
        ],
        emailRules:[
             //(v) => !!v || "Email is required.",
             v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
        ],
        tagsInput: {
            touched: false,
            hasError: false,
            errorMessage: 'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.'
        },
        documentProto: document,
        vueTelInputOptions: {
			autocomplete: false,
			placeholder: "Type Phone Number",
			styleClasses: "tel-input-class",
			required: false,
		},
        headers: [
            {
                text: "Name",
                align: "start",
                sortable: false,
                value: "name",
                fixed: true,
                width: "20%",
            },
            {
                text: "Phone Number",
                align: "start",
                sortable: false,
                value: "phone_number",
                fixed: true,
                width: "20%",
            },
            {
                text: "Email",
                align: "start",
                sortable: false,
                value: "email",
                fixed: true,
                width: "20%",
            },
            {
                text: "Primary",
                align: "center",
                sortable: false,
                value: "primary",
                fixed: true,
                width: "10%",
            },
            {
                text: "Role",
                align: "start",
                sortable: false,
                value: "role",
                fixed: true,
                width: "15%",
            },
            {
                text: "",
                sortable: false,
                value: "",
                fixed: true,
                width: "4%",
            },
        ],
        options: [],
        contacts: [
            {
                contact_name: "",
                phone_number: "",
                email: "",
                primary: false, 
                role: ""
            }
        ],
        myclass: '',
        rawContactFields: {
            contact_name: "",
            phone_number: "",
            email: "",
            primary: false,
            role: ""
        },
        email_address : "",
        customer_id: "",
        consignee_name: "",
        address: "",
        note: "",
        isAdding: false,
        customerForm: true,
        isFetching: false,
        numberHasError: false,
        hasEmailerror: false,
        isValidEmail: false,
    }),
    computed: {
        ...mapGetters({
            getIsEditing: 'customers/getIsEditing'
        }),
        getContacts: {
            get() {
                return this.contacts
            }
        },
        getFilterCustomers: {
            get() {
                if(typeof this.getQBCustomers !== "string") {
                    let filtered = []
                    filtered = this.getQBCustomers.filter(item => {
                        if(!this.getFilterQBCustomers.includes(item.Id)) {
                            return item;
                        }
                    })

                    return filtered
                } else {
                    return this.getQBCustomers
                }
            }
        },
        getConnected: {
            get() {
                if(typeof this.getQBCustomers !== "string") {
                    return "Connected"
                } else {
                    return "Disconnected"
                }
            }
        },
        getClass: {
            get() {
                if(typeof this.getQBCustomers !== "string") {
                    return "sub-text-green"
                } else {
                    return "sub-text-gray"
                }
            }
        },
    },
    watch: {
        getIsEditing(nv) {
            if(!nv) {
                this.options = [],
                this.contacts = []
                this.contacts.push(this.rawContactFields)
                this.email_address  = "",
                this.customer_id = "",
                this.consignee_name = "",
                this.address = "",
                this.note = "",
                this.customerForm = true
                this.isFetching = false
                this.tagsInput.hasError = false
                this.$refs['form'].resetValidation()

                this.dispatchNotifcation('Consignee has been updated successfully!')
                this.handleClose()
            }
        },
        itemData(nv) {
            console.log('item data >>>', nv)
            this.consignee_name = nv.name
            this.address = nv.address
            this.note = nv.note
            this.contacts = nv.contacts !== null && nv.contacts.length > 0 ? JSON.parse(nv.contacts) : []

            let emailArr = nv.emails.split(',')

            let opt = []
            emailArr.map(email => {
                opt.push({
                    text: email,
                    tiClasses: ['ti-valid']
                })
            })
            this.options = opt
        },
        options: {
            handler(nv) {
                console.log('options >>>', nv)
            },
            deep: true
        }
    },
    methods: {
        ...mapActions({
            updateConsignee: 'customers/updateConsignee'
        }),
        generateErrorMessage() {
            this.tagsInput.hasError = (this.options.length > 0) ? false : true
            if (this.tagsInput.hasError)
                jQuery('.ti-input').addClass('ti-new-tag-input-error')
            else
                jQuery('.ti-input').removeClass('ti-new-tag-input-error')
        },
        ...globalMethods,
        onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        handleClose() {
            // this.options = [],
            // this.contacts = []
            // this.contacts.push(this.rawContactFields)
            // this.email_address  = "",
            // this.customer_id = "",
            // this.consignee_name = "",
            // this.address = "",
            // this.note = "",
            // this.customerForm = true
            // this.isFetching = false
            // this.tagsInput.hasError = false

            // this.$refs['form'].resetValidation()
            this.isFetching = false
            this.$emit('update:dialog', false)
        },
        async save() {
            if (!this.tagsInput.touched) {
                this.tagsInput.touched = true
            }

            this.$refs.form.validate()
            this.tagsInput.hasError = (this.options.length > 0) ? false : true

            if(this.options.length === 0) {
                this.hasEmailerror = true
            } else {
                this.hasEmailerror = false
            }

            //this.generateErrorMessage()
            setTimeout(async () => {
                if (this.$refs.form.validate() && !this.hasEmailerror && !this.numberHasError) {
                    if (!this.tagsInput.hasError) {
                        try {
                            jQuery('.ti-new-tag-input').trigger(
                                jQuery.Event( 'keyup', { keyCode: 13, which: 13 } )
                            )

                            let finalEmailAddress = (this.options.length > 0) ? this.options.map(o => {
                                return o.text
                            }) : []
                            this.isFetching = true
                            await this.saveConsignee(finalEmailAddress);

                        } catch (e) {
                            console.log(e)
                        }
                    } else{
                        //alert('going else')
                    }
                }
            }, 200)
        },
        async saveConsignee(emails) {
            // let joined = email
            // // if(typeof emails.map === "function") {
            //     emails.map((email, index) => {
            //         joined += email+(emails.length !== index+1 ? "," : "") 
            //     })
            // // }

            // console.log('joined >>>', joined)

            let filteredContacts = this.contacts.filter(item => {
                if(item.contact_name !== "") {
                    return item
                }
            })

            let formData = new FormData(document.getElementById('editConsignee'))
            formData.append('name', this.consignee_name,)
            formData.append('email', emails.join())
            formData.append('customer_id', this.$route.params.id,)
            formData.append('address', this.address,)
            formData.append('note', this.note,)
            formData.append('contacts', JSON.stringify(filteredContacts))

            this.updateConsignee({
                id: this.itemData.id,
                form: formData
            })
        },
        handleAddContact() {
            // this.contacts.splice(this.contacts.length+1, 0, this.rawContactFields)
            this.contacts.push(this.rawContactFields)
        },  
        handleRemoveContact(index) {
            if(this.contacts.length > 1) {
                this.contacts.splice(index, 1)
            }
        },
        handlePrimaryChange(index) {
            this.contacts[index].primary = 1
        },
        handleChange(model, el, index) {
            if(model !== "primary" && model !== "email") {
                this.contacts[index][model] = el
            }
            else if(model === "email") {
                let validate = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(el)
                if(!validate) {
                    this.isValidEmail = true
                    this.notificationRedError('Email must be valid')
                } else {
                    this.isValidEmail = false
                    this.contacts[index][model] = el
                }
            } 
            else {
                this.contacts[index][model] = el.target.checked
            }
        },
        handlePhoneNumber(s, p, index) {
            let validate = /^(?=.*[0-9])[- +()0-9]+$/.test(s)
            if(!validate && s !== "") {
                this.numberHasError = true
                this.notificationRedError('Letters are not allowed')
            } else {
                this.numberHasError = false
            }

            this.contacts[index].phone_number = s
        },
    }
}
</script>
<style lang="scss" scoped>
#addsinglecustomerheader {
    border-bottom: 2px solid #EBF2F5;

    .v-toolbar__content {
        .v-toolbar__title {
            font-size: 24px;
            font-weight: 600;
            line-height: 34px;
            letter-spacing: 0em;
            text-align: left;
        }
    }
}

.v-data-table.add-contacts-table {
    border: 1px solid #B4CFE0;
}

.qbCustomerField {
    .v-text-field {
        .v-input__control {
            max-height: 40px;
            padding: 0;
        }
    }
}

.bkgd-faded-blue {
    .container {
        .bkgd-white {
            div {
                .textarea-field {
                    flex-direction: column;
                    align-items: flex-start;
                    height: 76px;
                    background: #FFFFFF;
                    border: 1px solid #B4CFE0;
                    border-radius: 4px;
                    font-size: 14px;
                    padding: 10px 11px;
                    color: #4A4A4A;
                    caret-color: #0171A1 !important;
                    width: 100% !important;
                }
            }
        }
    }
}

.vti__input {
    caret-color: black !important;
    border: none;
    border-radius: 0 2px 2px 0;
    width: 100%;
    outline: none;
    padding-left: 68px;
}
.vti__dropdown {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: absolute;
    padding: 7px;
    cursor: pointer;
}
.vti__dropdown-list {
    z-index: 9999 !important;
    padding: 0;
    margin: 0;
    text-align: left;
    list-style: none;
    max-height: 200px;
    overflow-y: scroll;
    position: absolute;
    left: -1px;
    background-color: #fff;
    border: 1px solid #ccc;
}

.mdi-close {
    font-size: 24px;
}

.section-text {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.section-text-black {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #4A4A4A;
}

.bkgd-faded-blue {
    background: #F5F9FC;
}
.bkgd-white {
    background: #FFF;
}
.sub-text-green {
    color: #16B442;
    text-transform: capitalize;
}
.sub-text-gray {
    color: #6D858F;
    text-transform: capitalize;
}
</style>

<style>
.qbCustomerField > .v-text-field.v-text-field--solo .v-input__control {
    min-height: 40px !important;
    padding: 0;
}

/* .qbContacts.v-text-field.v-text-field--solo .v-input__control {
    max-height: 40px !important;
    padding: 0;
    border-radius: 0px !important;
    font-size: 14px !important;
} */

div.qbContacts.text-fields>div.v-input__control>div.v-input__slot {
    min-height: 40px!important;
    max-height: 40px!important;
    width: 100%;
    font-size: 14px !important;
}

.qbContacts.v-text-field.v-text-field--solo .v-input__control .v-text-field__details {
    margin-left: 12px;
}

.add-contacts-table div table tbody tr td.text-end {
    border-top: 1px solid #B4CFE0;
    border-right: 1px solid #B4CFE0;
    border-bottom: 1px solid #B4CFE0;
    border-left: none;
    border-image: initial;
    padding: 0 !important;
    height: 40px !important;
}
.add-contacts-table div table tr td.text-start {
    border: 1px solid #B4CFE0;
    width: 100%;
    padding: 0 !important;
}
.add-contacts-table div table tbody tr td.text-end .vue-tel-input {
    /* border: 1px solid rgb(235, 242, 245) !important; */
    border: 1px solid #B4CFE0 !important;
    width: 100%;
    padding: 0 !important;
}
.add-contacts-table div table tbody tr td.text-end .vue-tel-input:focus {
    /* outline: 1px solid rgb(235, 242, 245) !important; */
    outline: 1px solid #B4CFE0 !important;
    box-shadow: #FFF;
    width: 100%;
    padding: 0 !important;
}
.add-contacts-table div table tbody tr:hover {
    background: #FFF !important;
}
.add-contacts-table.v-data-table .v-data-table__wrapper table thead tr th {
    text-transform: uppercase !important;
    color: #6D858F !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    /* border-bottom: none !important; */
    border-bottom: 1px solid #B4CFE0 !important;
    background: #fff !important;
}
.add-contacts-table .v-data-table__wrapper table tbody tr td {
    color: #6D858F !important;
    font-size: 12px !important;
}
.add-contacts-table .v-data-table__wrapper table tr td.text-start {
    /* border-top: 1px solid #B4CFE0 !important; */
    border-right: 1px solid #B4CFE0 !important;
}
.add-contacts-table .v-data-table__wrapper table tr td.text-end {
    /* border-top: 1px solid #B4CFE0 !important; */
    border-right: 1px solid #B4CFE0 !important;
}
/* .add-contacts-table .v-data-table__wrapper table tr td.text-center {
    border-top: 1px solid #B4CFE0 !important;
} */
.v-text-field {
    padding-top: 12px;
    margin-top: 0px !important;
}
.add-more-btn {
    color: #0171A1;
    cursor: pointer;
    font-size: 14px;
}
.add-more-btn:hover {
    color: #035273 !important;
    cursor: pointer;
}
.v-text-fields.select .vs__dropdown-toggle, .text-end .vue-tel-input {
    min-height: 40px;
    border: none !important;
    font-size: 14px;
    background-color: #fff !important;
}
.vti__input {
    caret-color: black !important;
    border: none;
    border-radius: 0 2px 2px 0;
    width: 100%;
    outline: none;
    padding-left: 7px;
}
.vue-tel-input:focus-within {
    /* box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6); */
    box-shadow: none;
    /* border-color: #66afe9; */
}
.v-input.text-fields .v-text-field__details {
    display: inherit;
}
div.ti-input {
    border-radius: 4px;
    border: 1px solid #B4CFE0 !important;
    width: 100% !important;
    min-height: 40px;
}
.v-text-field--outlined > div > div[role="combobox"] > fieldset {
    border-color: #b4cfe0 !important;
    border-width: 1px;
    min-height: 45px !important;
    max-height: 45px !important
}
.vue-tel-input .vti__dropdown.open .vti__dropdown-list.below {
    z-index: 99999 !important;
    padding-left: 0;
}
.vti__dropdown {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: absolute !important;
    padding: 7px;
    cursor: pointer;
}
.vti__input {
    caret-color: black !important;
    border: none;
    border-radius: 0 2px 2px 0;
    width: 100%;
    outline: none;
    padding-left: 70px !important;
}
</style>