<template>
  <div class="dos-table-wrapper-content">
    <div class="">
      <div @click="$router.back()">
        <i class="ic-chevron-left ic-back-icon"></i>
        <button class="go-back-btn">Go Back</button>
      </div>

      <h2
        style="
          font-family: 'Inter-Medium', sans-serif !important;
          font-weight: unset;
        "
      >
        Chassis #{{ getChassisItem.number ? getChassisItem.number : "  ---" }}
      </h2>
      <div class="d-flex mt-2">
        <i class="ic-chassis icon-chassis"></i>
        <p class="chasiss-txt">{{ getChassisItem.type }}</p>
      </div>
    </div>

    <v-data-table
      :headers="headers"
      mobile-breakpoint="400"
      :items="items"
      class="driver-table elevation-1 DOSWRAPPER"
      hide-default-footer
      height="400"
      :page.sync="page"
      @page-count="pageCount = $event"
      :items-per-page="itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar class="headerClass DosHeadertabs" style="" flat>
          <v-tabs class="btabsclass">
            <v-tab value="1">Schedule History</v-tab>
          </v-tabs>
        </v-toolbar>
      </template>
      <template v-slot:[`header.address`]="{ header }">
        {{ header.text }}<i class="classsort"></i>
      </template>

      <template v-slot:[`header.pickup_location`]="{ header }">
        {{ header.text }}<i class="classsort"></i>
      </template>

      <template v-slot:[`item.id`]="{ item,index }">
        <p class="mb-0">
          {{ item.legID || callrefLegID( item.shifl_ref , index )}}
        </p>
        <p>{{ "To " + item.type }}</p>
      </template>
      <template v-slot:[`item.shifl_ref`]="{ item }">
        <p class="mb-0">
          {{ item.shifl_ref }}
        </p>
        <p>{{ item.dispatch_id }}</p>
      </template>
      <template v-slot:[`item.pickup_location`]="{ item }">
        <p class="mb-0">
          {{ item.pickup_location }}
        </p>
        <p>{{ item.pickup_time }}</p>
      </template>
      <template v-slot:[`item.address`]="{ item }">
        <p class="mb-0">
          {{ item.address }}
        </p>
        <p>{{ item.delivery_scheduled_date_time }}</p>
      </template>

      <!-- <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          small
          width="40"
          style="min-height: 30px !important"
          class="truckerMoreBtn ml-5"
          @click="editChassisItem(item)"
        >
          <img class="" src="@/assets/icons/more-blue.svg" alt="" />
        </v-btn>
       
      </template> -->
      <template v-slot:no-data>
        <div class="no-data-preloader mt-4" v-if="loading">
          <v-progress-circular
            :size="40"
            color="#0171a1"
            indeterminate
            v-if="loading"
          >
          </v-progress-circular>
        </div>
      </template>
    </v-data-table>

    <Pagination
      :pageData.sync="page"
      :lengthData.sync="pageCount"
      :isMobile="isMobile"
    />
  </div>
</template>

<script>
import axios from "axios";
const APIBaseUrl = process.env.VUE_APP_PO_URL;
import { mapGetters } from "vuex";
import moment from "moment";
import Pagination from "../../../Pagination.vue";
export default {
  components: {
    Pagination,
  },
  props: ["isMobile"],

  data() {
    return {
      headers: [
        {
          text: "Schedule ID",
          align: "left",
          sortable: false,
          value: "id",
          width: "10%",
          fixed: true,
        },
        {
          text: "Container REF#",
          align: "left",
          sortable: false,
          value: "shifl_ref",
          width: "10%",
          fixed: true,
        },
        {
          text: "Pickup On",
          align: "left",
          sortable: true,
          value: "pickup_location",
          width: "15%",
          fixed: true,
        },
        {
          text: "Delivery On",
          align: "left",
          sortable: true,
          value: "address",
          width: "15%",
          fixed: true,
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "actions",
          width: "8%",
          fixed: true,
        },
      ],
      page: 1,
      pageCount: 2,
      items: [],
      loading: false,
      itemsPerPage: 6,
      sortedDsc: false,
    };
  },
  computed: {
    ...mapGetters("chassis", ["getChassisItem"]),
  },
  methods: {
    callrefLegID(v,index){
      if(index < 10) {
        return v + '-' + '0' + (index + 1);
      }
      return v + '-' + index;
    },
    async getChassisHistory() {
      try {
        this.loading = true;
        const url = `${APIBaseUrl}/getChassis-history/${this.$route.params.id}`;
        const res = await axios.get(url);
        if (res.status == 200) {
          this.items = res.data.data;
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    customSort(items, sortBy) {
      if (!items && !sortBy == "pickup_location") return items;

      if (sortBy == "pickup_location") {
        if (!this.sortedDsc) {
          this.sortedDsc = true;
          return items.sort((a, b) => {
            const date1 = moment(a.pickup_location);
            const date2 = moment(b.pickup_location);
            return date1.isAfter(date2) ? -1 : 1;
          });
        } else {
          this.sortedDsc = false;
          return items.sort((c, d) => {
            const date1 = moment(c.pickup_location);
            const date2 = moment(d.pickup_location);
            return date1.isAfter(date2) ? 1 : -1;
          });
        }
      } else if (sortBy == "address") {
        if (!this.sortedDsc) {
          this.sortedDsc = true;
          return items.sort((a, b) => {
            const date1 = moment(a.pickup_location);
            const date2 = moment(b.pickup_location);
            return date1.isAfter(date2) ? -1 : 1;
          });
        } else {
          this.sortedDsc = false;
          return items.sort((c, d) => {
            const date1 = moment(c.pickup_location);
            const date2 = moment(d.pickup_location);
            return date1.isAfter(date2) ? 1 : -1;
          });
        }
      } else {
        return items;
      }
    },
    editChassisItem(item) {
      console.log(item.id);
    },
  },
  mounted() {
    this.getChassisHistory();
  },
};
</script>

<style>
.chasiss-txt {
  margin-left: 10px;
  color: #4a4a4a;
  font-size: 12px;
  font-weight: 400;
}
.icon-chassis {
  font-size: 12px;
}
.ic-back-icon {
  font-weight: 500;
  color: #0171a1;
  font-size: 14px;
}

.truckerMoreBtn {
  height: 30px !important;
  width: 30px;
  min-width: 30px !important;
  min-width: 30px !important;
  background-color: #fff !important;
  border: 1px solid #b4cfe0;
  box-shadow: none;
}
</style>
<style lang="scss">
.go-back-btn {
  color: #0171a1;
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
  font-family: "Inter-Regular" !important;
  line-height: 21px;
}
// table {
//   thead {
//     border: 2px solid red;
//   }
// }
.dos-table-wrapper-content {
  position: relative;

  .loading-overlay {
    position: absolute;
    z-index: 1000;
    left: 0;
    right: 0;
    bottom: 0;
    top: 112px;
    background-color: #fff;
    padding: 24px;
    display: flex;
    justify-content: center;
    border: 1px solid #ebf2f5;
    height: calc(100% - 108px);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .v-data-table.DOSWRAPPER {
    box-shadow: none !important;
    border: 1px solid #ebf2f5;

    .DosHeadertabs {
      height: 54px !important;

      .v-toolbar__content {
        height: 54px !important;

        .v-tabs {
          .v-tab {
            padding: 0 20px !important;
            letter-spacing: 0 !important;
            font-family: "Inter-SemiBold", sans-serif !important;
            font-weight: unset !important;
            color: #4a4a4a !important;
            text-transform: capitalize !important;

            &.v-tab--active {
              color: #0171a1 !important;
            }
          }
        }
      }
    }

    .v-data-table__wrapper {
      height: calc(100vh - 345px);
      overflow-y: auto;

      table {
        thead {
          tr {
            th {
              box-shadow: none !important;
              border-bottom: 2px solid #ebf2f5 !important;
              border-top: 2px solid #ebf2f5 !important;
              height: 40px;
              padding: 8px !important;
              text-transform: capitalize;
              font-size: 14px !important;

              span {
                font-size: 14px !important;
                font-family: "Inter-Regular" !important;
              }

              &:first-child {
                padding-left: 20px !important;
              }
            }
            th:hover {
              color: #6d858f;
            }
          }
        }

        tbody {
          tr {
            td {
              border-bottom: 1px solid #ebf2f5 !important;
              padding: 10px 8px !important;
              font-size: 14px;

              &:first-child {
                padding-left: 20px !important;
              }

              &:nth-child(5),
              &:nth-child(6) {
                vertical-align: top;
                padding: 14px 8px !important;
              }

              .action-button-wrapper-do {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .btn-white.reviewbutton {
                  height: 30px !important;
                  font-size: 12px !important;
                  margin-right: 6px;
                  padding: 4px 10px !important;
                }
              }
            }

            &.v-data-table__empty-wrapper {
              cursor: unset;

              &:hover {
                background-color: #fff !important;
              }

              td {
                border-bottom: none !important;
              }
            }
          }
        }
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #d3d3d3;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #d3d3d3;
      }
    }

    .btn-black.archived {
      min-width: 75px !important;
      padding: 0 !important;
    }
  }

  .pagination-wrapper {
    border: 1px solid #ebf2f5 !important;
    border-top: none !important;
  }
}
.DosHeadertabs {
  height: 54px !important;

  .v-toolbar__content {
    height: 54px !important;

    .v-tabs {
      .v-tab {
        padding: 0 20px !important;
        letter-spacing: 0 !important;
        font-family: "Inter-SemiBold", sans-serif !important;
        font-weight: unset !important;
        color: #4a4a4a !important;
        text-transform: capitalize !important;

        &.v-tab--active {
          color: #0171a1 !important;
        }
      }
    }
  }
}
</style>
