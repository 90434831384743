var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dos-table-wrapper-content"},[_c('div',{},[_c('div',{on:{"click":function($event){return _vm.$router.back()}}},[_c('i',{staticClass:"ic-chevron-left ic-back-icon"}),_c('button',{staticClass:"go-back-btn"},[_vm._v("Go Back")])]),_c('h2',{staticStyle:{"font-family":"'Inter-Medium', sans-serif !important","font-weight":"unset"}},[_vm._v(" Chassis #"+_vm._s(_vm.getChassisItem.number ? _vm.getChassisItem.number : " ---")+" ")]),_c('div',{staticClass:"d-flex mt-2"},[_c('i',{staticClass:"ic-chassis icon-chassis"}),_c('p',{staticClass:"chasiss-txt"},[_vm._v(_vm._s(_vm.getChassisItem.type))])])]),_c('v-data-table',{staticClass:"driver-table elevation-1 DOSWRAPPER",attrs:{"headers":_vm.headers,"mobile-breakpoint":"400","items":_vm.items,"hide-default-footer":"","height":"400","page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"headerClass DosHeadertabs",attrs:{"flat":""}},[_c('v-tabs',{staticClass:"btabsclass"},[_c('v-tab',{attrs:{"value":"1"}},[_vm._v("Schedule History")])],1)],1)]},proxy:true},{key:"header.address",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)),_c('i',{staticClass:"classsort"})]}},{key:"header.pickup_location",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)),_c('i',{staticClass:"classsort"})]}},{key:"item.id",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.legID || _vm.callrefLegID( item.shifl_ref , index ))+" ")]),_c('p',[_vm._v(_vm._s("To " + item.type))])]}},{key:"item.shifl_ref",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.shifl_ref)+" ")]),_c('p',[_vm._v(_vm._s(item.dispatch_id))])]}},{key:"item.pickup_location",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.pickup_location)+" ")]),_c('p',[_vm._v(_vm._s(item.pickup_time))])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.address)+" ")]),_c('p',[_vm._v(_vm._s(item.delivery_scheduled_date_time))])]}},{key:"no-data",fn:function(){return [(_vm.loading)?_c('div',{staticClass:"no-data-preloader mt-4"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"size":40,"color":"#0171a1","indeterminate":""}}):_vm._e()],1):_vm._e()]},proxy:true}],null,true)}),_c('Pagination',{attrs:{"pageData":_vm.page,"lengthData":_vm.pageCount,"isMobile":_vm.isMobile},on:{"update:pageData":function($event){_vm.page=$event},"update:page-data":function($event){_vm.page=$event},"update:lengthData":function($event){_vm.pageCount=$event},"update:length-data":function($event){_vm.pageCount=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }