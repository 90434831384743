<template>
  <div class="containers-inner-wrapper conTabledisplay" v-click-outside="detectClickOutsideTable">
    <EditLeg :showEditLegModal="showEditLegModal" :driverList="getNoFilterDrivers" :chassisList="getChassis"
      :responseChassis="responseChassis" :modedropdown="modedropdown" :typeDropdown="typeDropdown"
      :pickupLocation="editingLegPickupLocation" :leg="editingLeg" :currentEditingLegNum="currentEditingLegIndex"
      :markasrrive="markasrrived" :editIndex="editIndex" @close="() => {
          showEditLegModal = false;
          editingLeg = null;
          markasrrived = 0;
        }
      "
      @saveEditedLeg="saveEditedLeg"
      @saveNewChassis="saveNewChassis"
      :responseObjectMeta="responseObjectMeta"
      :eta="getEditingShipmentEta"
      :editingLocation="editinglocation"
      :refNumber="refNumber"
    />
    <AddNewLeg
      :showAddLegModal="showAddLegModal"
      :driverList="getNoFilterDrivers"
      :driver="getDrivers"
      :chassisList="getChassis"
      :responseChassis="responseChassis"
      :modedropdown="modedropdown"
      :getObject="null"
      :typeDropdown="typeDropdown"
      :pickupLocation="getPickupLocation"
      :lastLeg="lastLeg"
      @close="showAddLegModal = false"
      @addNewLeg="addNewLeg"
      @saveNewChassis="saveNewChassis"
      :bus="null"
      :responseObjectMeta="responseObjectMeta"
      :eta="getEditingShipmentEta"
			:dialogData="editingShipment ? editingShipment.dispatch_schedule : editingShipment"
      :theTerminals="terminals"
      :editingLocation="editinglocation"
    />
    <!-- ///////// -->
    <div class="spinner mt-4" v-if="getTabLoading">
      <v-progress-circular :size="40" color="#0171a1" indeterminate v-if="getPoLoading">
      </v-progress-circular>

    </div>
    <v-data-table :headers="headers" :items="loadingData ? [] : newData" :custom-sort="customSort"
      class="containers-table elevation-1 conTabledisplay" hide-default-footer :page.sync="page"
      :items-per-page="itemsPerPage" @page-count="pageCount = $event" @item-selected="itemSelected"
      v-model="selectedTasks" v-if="refresh" calculate-widths fixed-header>
      <template v-slot:top>
        <!-- <v-toolbar flat>
          <v-toolbar-title style="font-size: 20px;">Containers</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="createDo"
            color="primary"
            class="btn-blue manage-payment-methods-button"
          >
            <span class="btn-name-manage" style="font-size: 12px;">
              {{ titlePage }}
            </span>
          </v-btn>
        </v-toolbar> -->
        <div class="container-default-tabs-wrapper">
          <v-tabs v-if="retabs" class="container-default-tabs filterstabs" v-model="activeTab">
            <v-tab :disabled="isDisabled" v-for="(n, i) in newtabitem" :key="i" :value="assignValue(i)"
              @mouseup="getCurrentTab(n.name, i, n)">
              <!-- <span v-if="n.name=='Assigned'">
                Scheduled
              </span>
              <span v-else>{{ n.name }}</span>
              <span
              class="text-tab-counter"
              v-if="n.count"
              >
                {{ n.count }}
              </span> -->

              <span>{{ n.name == 'Assigned' ? 'Scheduled' : n.name }}</span>

              <span class="tab-count-wrapper"> <!-- v-if="n.name !== 'Completed' && n.name !== 'Cancelled'" -->
                <span class="tab-count-value" v-if="n.count">
                  {{ n.count }}
                </span>
              </span>
            </v-tab>
          </v-tabs>
        </div>
        <v-toolbar height="auto" class="searchAndFilter">


          <div v-if="currentSelectTab == 'All'">
            <div style="padding-left: 10px; display: flex">
              <div style="margin-right: 16px; display: flex">
                <div style="color: #6D858F; margin-right: 5px">Show</div>

                <v-menu bottom offset-y left min-width="120px">
                  <!-- nudge-top="50px" nudge-left="125px" -->
                  <template v-slot:activator="{ attrs, on }">

                    <div style="cursor: pointer" v-bind="attrs" v-on="on">
                      <span style="margin-right: 2px"> {{ selectedActiveInactiveFilter.label }} </span>
                      <svg width="10" height="8" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M5 4.47655L8.97867 0.188526C9.21703 -0.0683692 9.61175 -0.061258 9.84203 0.200905C10.0565 0.445037 10.0524 0.825416 9.83085 1.06423L5.42609 5.81148C5.19286 6.06284 4.80714 6.06284 4.57391 5.81148L0.169146 1.06423C-0.0524367 0.825415 -0.0564694 0.445037 0.15797 0.200905C0.388249 -0.0612581 0.782966 -0.0683693 1.02133 0.188526L5 4.47655Z"
                          fill="#0B6085" />
                      </svg>
                    </div>
                  </template>
                  <v-list>
                    <v-list-item @click="selectedActiveInactiveFilter = item" v-for="item in activeInactiveFilter"
                      :key="item.value" link>
                      <v-list-item-title v-text="item.label"></v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>

              <div style="margin-right: 16px; display: flex">
                <div style="color: #6D858F; margin-right: 5px">Customer</div>

                <v-menu bottom offset-y left min-width="120px">
                  <!-- nudge-top="50px" nudge-left="125px" -->
                  <template v-slot:activator="{ attrs, on }">

                    <div style="cursor: pointer" v-bind="attrs" v-on="on">
                      <span style="margin-right: 2px"> {{ selectedCustomerFilter.company_name }} </span>
                      <svg width="10" height="8" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M5 4.47655L8.97867 0.188526C9.21703 -0.0683692 9.61175 -0.061258 9.84203 0.200905C10.0565 0.445037 10.0524 0.825416 9.83085 1.06423L5.42609 5.81148C5.19286 6.06284 4.80714 6.06284 4.57391 5.81148L0.169146 1.06423C-0.0524367 0.825415 -0.0564694 0.445037 0.15797 0.200905C0.388249 -0.0612581 0.782966 -0.0683693 1.02133 0.188526L5 4.47655Z"
                          fill="#0B6085" />
                      </svg>
                    </div>
                  </template>
                  <v-list class="customer-list-filter-dropdown">
                    <v-list-item @click="selectedCustomerFilter = item"
                      v-for="item in [{ id: 0, company_name: 'Select' }, ...customers]" :key="item.id" link>
                      <!-- @click="selectedCustomerFilter = item" -->
                      <v-list-item-title v-html="item.company_name"></v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>

              <div style="margin-right: 16px; display: flex">
                <div style="color: #6D858F; margin-right: 5px; cursor: pointer" @click="handlePicker">
                  <span> ETA </span>
                  <span style=""> {{ selectedEtaRange.length > 0 ? (selectedEtaRange[0].replaceAll('-', '/') + '-' +
                    selectedEtaRange[1].replaceAll('-', '/')) : "" }} </span>
                  <svg width="10" height="8" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M5 4.47655L8.97867 0.188526C9.21703 -0.0683692 9.61175 -0.061258 9.84203 0.200905C10.0565 0.445037 10.0524 0.825416 9.83085 1.06423L5.42609 5.81148C5.19286 6.06284 4.80714 6.06284 4.57391 5.81148L0.169146 1.06423C-0.0524367 0.825415 -0.0564694 0.445037 0.15797 0.200905C0.388249 -0.0612581 0.782966 -0.0683693 1.02133 0.188526L5 4.47655Z"
                      fill="#0B6085" />
                  </svg>
                </div>
              </div>
              <!-- <div style="margin-right: 15px; display: flex">
									<div style="color: #6D858F; margin-right: 5px">ETA</div>
									<v-menu
										offset-y
									>
										<template v-slot:activator="{ attrs, on }">
											<div style="cursor: pointer" v-bind="attrs" v-on="on">
												<span> {{ selectedActiveInactiveFilter.label }} </span>
												<svg
													width="10"
													height="6"
													viewBox="0 0 10 6"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M5 4.47655L8.97867 0.188526C9.21703 -0.0683692 9.61175 -0.061258 9.84203 0.200905C10.0565 0.445037 10.0524 0.825416 9.83085 1.06423L5.42609 5.81148C5.19286 6.06284 4.80714 6.06284 4.57391 5.81148L0.169146 1.06423C-0.0524367 0.825415 -0.0564694 0.445037 0.15797 0.200905C0.388249 -0.0612581 0.782966 -0.0683693 1.02133 0.188526L5 4.47655Z"
														fill="#0B6085"
													/>
												</svg>
											</div>
										</template>
										<v-list>
											<v-list-item
												@click="selectedActiveInactiveFilter = item"
												v-for="item in activeInactiveFilter"
												:key="item.value"
												link
											>
												<v-list-item-title v-text="item.label" ></v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
							</div> -->
            </div>
          </div>


          <div v-if="currentSelectTab == 'Scheduled'" class="" style="display: flex">
            <!-- class="assignUnassignFilter" -->
            <!-- <div
              v-for="(item, index) in filterAssign"
              :key="index"
              :value="subTabIndex"
              :class="checkIfClassActive(item)"
              @click="setSetFilterActive(item); setSubTab(item, index);"
            >
              {{ item }}
            </div> -->

            <v-tabs class="container-sub-tabs pl-2" v-model="subTabIndex">
              <v-tab v-for="(item, i) in filterAssign" :key="i" @click="setSetFilterActive(item); setSubTab(item, i);"
                :style="i === 1 ? 'border-right: none !important;' : ''">
                <span>{{ item }}</span>
              </v-tab>
            </v-tabs>
          </div>
          <div class="assignUnassignFilter" style="display: flex">
            <div class="d-flex justify-center align-center" v-for="(item, index) in sub_tab" :key="index"
              :value="subTabIndex" :class="checkIfClassActive(item.name)" :style="'background:' +
                (item.name == selected_sub_tab.name ? '#EBF2F5' : '#ffffff')
                " @click="setSubTab(item, index)">
              {{ item.label }}

              <span class="text-tab-counter d-flex justify-center align-center ml-1" style="
                  padding: 3px 2px 2px;
                  gap: 10px;
                  width: 20px;
                  font-size: 11px;
                  height: 20px;
                  background: #0171a1;
                  border-radius: 12px;
                " :style="'background:' +
                  (item.name == selected_sub_tab.name ? '#0171A1' : '#EBF2F5') +
                  ';color:' +
                  (item.name == selected_sub_tab.name ? '#ffffff' : '#4A4A4A')
                  " v-if="newtabitem[activeTab].sub_tab[index] && newtabitem[activeTab].sub_tab[index].count !== 0">

                <span v-if="newtabitem[activeTab].sub_tab[index]">
                  {{ newtabitem[activeTab].sub_tab[index].count }}
                </span>
              </span>
            </div>
          </div>
          <v-spacer></v-spacer>
          <div style="position: relative" v-if="currentSelectTab == 'Scheduled' && cardDropdownFilter == true">
            <div class="text-center">
              <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="80" offset-y nudge-left="212"
                nudge-top="-6">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="btn-white" v-bind="attrs" v-on="on" ref="fi">
                    <img src="@/assets/icons/filter.svg" class="mr-1" width="15px" height="15px" />
                    <span style="font-size: 12px;">Filters</span>
                  </v-btn>
                </template>
                <v-card class="cardDropdown" position-x="233">
                  <v-list>
                    <v-list-item v-if="magdriver.length > 0">
                      <v-list-item-content>
                        <label class="driverLabel" for="">Drivers
                        </label>

                        <v-autocomplete :loading="isloading" v-model="driverSelectFilter" :items="driverdropdown"
                          color="blue" item-text="name" @change="changemenow" placeholder="Select Driver" item-value="id"
                          multiple @keyup="inputChanged" @keydown="inputChanged" size="14px">
                          <template v-slot:selection="data">
                            <span v-if="driverSelectFilter.length < 2">
                              {{ `${driverSelectFilter.length} Driver selected` }}
                            </span>
                            <div v-else-if="data.index === 0">
                              {{ `${driverSelectFilter.length} Drivers selected` }}
                            </div>
                          </template>
                          <template slot="item" slot-scope="{ item }">
                            <div class="listDropdown">
                              <v-checkbox v-model="item.selected" :size="12" @change="driverChange(item, $event)"
                                :label="item.name" class="my-checkbox2">
                              </v-checkbox>
                            </div>
                          </template>
                        </v-autocomplete>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <label class="driverLabel" for="">Pickup Location
                        </label>
                        <v-autocomplete v-if="pickuplocationload" :loading="isloading" v-model="locationfromfilter"
                          :items="getlocation" color="blue" @change="changelocation" @keyup="inputChangedLocation"
                          @keydown="inputChangedLocation" item-text="name" placeholder="Select Location" item-value="id"
                          multiple size="14px">
                          <template v-slot:selection="data">
                            <span v-if="locationfromfilter.length < 2">
                              {{ `${locationfromfilter.length} Location selected` }}
                            </span>
                            <span v-else-if="data.index === 0">
                              {{ `${locationfromfilter.length} Locations selected` }}
                            </span>
                          </template>
                          <template slot="item" slot-scope="{ item }">
                            <div class="listDropdown">
                              <div v-if="item.name != ''">
                                <v-checkbox v-model="item.selected" @change="changeinputme(item, $event)"
                                  :label="item.name" class="my-checkbox2" />
                              </div>
                              <!-- <input type="checkbox"  @change="changeinputme(item,$event)" v-model="item.selected" v-bind:id="item.id">{{item.name}} -->
                            </div>
                          </template>
                        </v-autocomplete>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-card-actions class="buttonCardActions">
                    <v-btn style="height: 30px !important" class="btn-blue filterButtonApply" color="primary" text
                      :disabled="isDisabledfilter" @click="applyFilter" ref="refapply">
                      <v-progress-circular :size="15" color="#fff" :width="2" indeterminate v-if="startloading"
                        style="margin-right: 3px">
                      </v-progress-circular>
                      Apply
                    </v-btn>
                    <v-btn v-if="locationfromfilter.length || driverSelectFilter.length" style="height: 30px !important"
                      class="btn-white filterButtonCancel" text @click="ClearFilter">
                      Clear
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </div>
          </div>
          <div class="initialDropdown" v-else-if="currentSelectTab == 'Scheduled' && cardDropdownFilter == false
            ">
            <!-- <v-autocomplete
              v-model="inititaldriverSelectFilter"
              :items="driverdropdown"
              color="blue"
              item-text="name"
              @change="changenowDropdown"
              placeholder="All Driver"
              item-value="id"
              size="14px"
              append-icon="ic-chevron-down"
            >
            </v-autocomplete> -->
          </div>
          <Search placeholder="Search Containers" className="search" :inputData.sync="search" />
        </v-toolbar>
        <v-toolbar style="display: none; border-top: 1px solid #eee; height: 48px" v-if="currentSelectTab == 'Scheduled'"
          class="filterShowtag">
          <v-toolbar-title> Active Filter by Driver </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <!-- headers  -->
      <template v-slot:[`header.discharged`]="{}">
        <v-tooltip top content-class="table-header-icons-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex justify-center align-center" v-bind="attrs" v-on="on">
              <!-- for discharged, show the circle check -->
              <img src="@/assets/icons/circle-outline-check.svg" alt="" width="20px">
            </div>
          </template>
          <span>Discharged</span>
        </v-tooltip>
      </template>

      <template v-slot:[`header.pier_pass_paid`]="{}">
        <v-tooltip top content-class="table-header-icons-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex justify-center align-center" v-bind="attrs" v-on="on">
              <!-- for pre gate fees, show the discharged icon -->
              <img src="@/assets/icons/discharge-icon-header.svg" alt="" width="20px">
            </div>
          </template>
          <span>Pre Gate Fees</span>
        </v-tooltip>
      </template>

      <template v-slot:[`header.fully_released`]="{}">
        <v-tooltip top content-class="table-header-icons-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex justify-center align-center" v-bind="attrs" v-on="on">
              <!-- for released, show the document -->
              <img src="@/assets/icons/submit-doc.svg" alt="" width="20px">
            </div>
          </template>
          <span>Released</span>
        </v-tooltip>
      </template>

      <template v-slot:[`header.Pickup_On`]="{ header }">
        {{ header.text }}<i class="classsort"></i>
      </template>

      <template v-slot:[`header.Delivery_On`]="{ header }">
        {{ header.text }}<i class="classsort"></i>
      </template>

      <template v-slot:[`header.lfd_pdd`]="{ header }">
        {{ header.text }}<i class="classsort"></i>
      </template>

      <template v-slot:body="{ items }">
        <tbody v-if="items.length > 0 && currentSelectTab == 'All'"
          @click="(dateActivator !== '' || pddActivator !== '' || lfdActivator !== '') ? handleCancel() : ''">
          <tr v-for="(item, index) in items" :key="item.id">
            <td class="referrence">
              <div class="classTop" v-html="Ref(item)"></div>
            </td>
            <td style="">
              <div class="text-uppercase">{{ item.mbl_num || "" }} </div>
              <div>{{ getCarrier(item) }}</div>
            </td>
            <td>
              <div @dblclick="quickSizeEdit(item, index)" :class="`size${item.id}`" class="containers_etas">
                {{ getContainerSize(item) }}
              </div>
              <span :id="`size${item.id}`"></span>
              <v-menu :activator="`#size${item.id}`" min-width="175px">
                <v-list class="pb-0">
                  <v-list-item class="hover-item" v-for="(size, i) in shipmentsize" :key="i" :value="size.id"
                    @click="quickSizeEditSave(size)">
                    <v-list-item-title>
                      <span>
                        {{ size.name }}
                      </span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td style="">
              <div>
                <p class="mb-0">{{ item.customer != null ? item.customer.company_name : "" }}</p>
                <p class="mb-0" style="color:#6d858f">{{ getconsineeitem(item)}}</p>
              </div>
            </td>
            <td @dblclick="handleDblClick(item, 'eta', index)">
              <!-- style="padding-top: 19px" -->
              <span @dblclick="handleDblClick(item, 'eta', index)" :class="`text${item.id}`">
                {{ getDateFormat(item.eta) }}
              </span>
              <div class="vc-date-picker__wrapper">
                <vc-date-picker title-position="left" is-expanded v-model="dateInput" mode="date"
                  :popover="{ visibility: 'click' }" :firstDayOfWeek="2" :masks="masks" :select-attribute="attribute"
                  @input="(e) => handleDateChange(e, item, dateInput, 'eta', index, 'dateRef1')" ref="dateRef1">

                  <template #default="{ inputValue, inputEvents }">
                    <input :value="inputValue" v-on="inputEvents" class="create-do-eta-dxdate-vc mt-0"
                      placeholder="Select Date" :class="`dp${item.id} ${item.id === dateActivator ? 'd-show' : 'd-none'}`"
                      @keyup="(e) => checkKeyUpVal(e, index, 'dateRef1')" />
                  </template>
                </vc-date-picker>
              </div>
            </td>
            <td style="" @dblclick="handleDblClick(item, 'last_free_date', index)">
              <!-- {{
                getDateFormat(
                  getLastArrivedLeg(item)
                    ? item.per_diem_date
                    : item.last_free_date
                )
              }} -->
              <span @dblclick="handleDblClick(item, 'last_free_date', index)" :class="`lfd${item.id}`">
                {{ getDateFormat(item.last_free_date) }}
              </span>
              <div class="vc-date-picker__wrapper">
                <vc-date-picker title-position="left" is-expanded v-model="dateInput" mode="date"
                  :popover="{ visibility: 'click' }" :firstDayOfWeek="2" :masks="masks" :select-attribute="attribute"
                  @input="(e) => handleDateChange(e, item, dateInput, 'last_free_date', index, 'dateRef2')"
                  ref="dateRef2">

                  <template #default="{ inputValue, inputEvents }">
                    <input :value="inputValue" v-on="inputEvents" class="create-do-eta-dxdate-vc mt-0"
                      placeholder="Select Date"
                      :class="`dp${item.shifl_ref} ${item.id === lfdActivator ? 'd-show' : 'd-none'}`"
                      @keyup="(e) => checkKeyUpVal(e, index, 'dateRef2')" />
                  </template>
                </vc-date-picker>
              </div>
              <!-- <v-text-field
                v-model="dateInput"
                outlined
                class="text-fields"
                :rules="dateValidation"
                @change="handleDateChange(item, dateInput, 'last_free_date', index)"
                :class="`dp${item.shifl_ref} ${item.id === lfdActivator ? 'd-show' : 'd-none'}`"
              ></v-text-field>
              <v-menu
                :activator="`.dp${item.shifl_ref}`"
                :close-on-content-click="true"
                :return-value.sync="trySelected"
                transition="scale-transition"
                nudge-left="290px"
                max-width="290px"
                min-width="290px"
              >
                <v-date-picker v-model="datePicked" no-title></v-date-picker>
              </v-menu> -->
            </td>
            <td class="text-end">
              <div class="d-flex justify-end pr-3">
                <v-btn @click="showAlert(item)" outlined class="more_btn_sm-custom">
                  <img src="../../../assets/icons/view-blue.svg" alt="" />
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="items.length === 0 && currentSelectTab === 'Scheduled'">
          <tr class="tr-schedule-no-data">
            <td style="align: center; border-bottom: none !important;" colspan="12">
              <div v-if="shouldLoad" style="text-align: center" class="pt-3">
                <v-progress-circular :size="35" color="#0171a1" indeterminate>
                </v-progress-circular>
              </div>
              <div v-if="!getPoLoading && !shouldLoad" style="text-align: center">
                <div v-if="currentSelectTab == 'Scheduled'">
                  <div v-if="filterstarted">Fetching data.....</div>
                  <div v-else class="wrapno-matching" style="padding-top: 70px; display: inline-block">
                    <img src="@/assets/icons/truckicon.svg" />
                    <p class="pt-5 nomatching mb-3">No matching result</p>
                    <p>We could not find any shipments under this filter</p>
                    <p class="d-flex justify-center align-center">
                      <button class="btn-white" @click="triggersearch">
                        Try Different Search
                      </button>
                    </p>
                  </div>
                </div>
                <div v-else>No available data</div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="items.length > 0 && currentSelectTab === 'Scheduled'"
          @click="(dateActivator !== '' || pddActivator !== '' || lfdActivator !== '') ? handleCancel() : ''">
          <tr v-for="(item, index) in items" :key="item.id">
            <td class="referrence">
              <div class="classTop" v-html="Ref(item)"></div>
            </td>
            <td style="">
              <div class="text-uppercase">{{ item.mbl_num || "" }} </div>
              <div>{{ getCarrier(item) }}</div>
            </td>
            <!-- <td
						class="container_customer"
						>
						<p
							style="padding-top:0px"
							class="classTop"
							v-html="TruckerConCustomer(item)">
						</p>
					</td> -->
            <td>
              <div @dblclick="quickSizeEdit(item, index)" :class="`size${item.id}`" class="containers_etas">
                {{ getContainerSize(item) }}
              </div>
              <span :id="`size${item.id}`"></span>
              <v-menu :activator="`#size${item.id}`" min-width="175px">
                <v-list class="pb-0">
                  <v-list-item class="hover-item" v-for="(size, i) in shipmentsize" :key="i" :value="size.id"
                    @click="quickSizeEditSave(size)">
                    <v-list-item-title>
                      <span>
                        {{ size.name }}
                      </span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td
              @dblclick="handleDblClick(item, `${getLastArrivedLeg(item) ? 'per_diem_date' : 'last_free_date'}`, index)">
              <span
                @dblclick="handleDblClick(item, `${getLastArrivedLeg(item) ? 'per_diem_date' : 'last_free_date'}`, index)"
                :class="`${getLastArrivedLeg(item) ? `pdd${item.id}` : `lfd${item.id}`}`">
                {{
                  getDateFormat(
                    getLastArrivedLeg(item)
                      ? item.per_diem_date
                      : item.last_free_date
                  )
                }}
                <p class="mb-0" style="color: #6d858f !important;">
                  {{ getCounter(getLastArrivedLeg(item) ? item.per_diem_date : item.last_free_date) }}
                </p>
              </span>
              <div class="vc-date-picker__wrapper">
                <vc-date-picker title-position="left" is-expanded v-model="dateInput" mode="date"
                  :popover="{ visibility: 'click' }" :firstDayOfWeek="2" :masks="masks" :select-attribute="attribute"
                  @input="(e) => handleDateChange(e, item, dateInput, `${getLastArrivedLeg(item) ? 'per_diem_date' : 'last_free_date'}`, index, 'dateRef3')"
                  ref="dateRef3">

                  <template #default="{ inputValue, inputEvents }">
                    <input :value="inputValue" v-on="inputEvents" class="create-do-eta-dxdate-vc mt-0"
                      placeholder="Select Date"
                      :class="`dp${item.id} ${item.id === (getLastArrivedLeg(item) ? pddActivator : lfdActivator) ? 'd-show' : 'd-none'}`"
                      @keyup="(e) => checkKeyUpVal(e, index, 'dateRef3')" />
                  </template>
                </vc-date-picker>
              </div>
            </td>
            <td class="schedule" style="padding-left: 0px !important; padding-right: 0px !important;">
              <div class="classTop f" v-html="get_schedule(
                typeFilter || !typeFilter
                  ? item
                  : item,
                typeFilter
              )
                "></div>
            </td>
            <td class="pickupon" style="padding-left: 0px !important; padding-right: 0px !important;">
              <div class="tooltip-for-pickup" v-if="checklength_dispatch(item)">
                <v-tooltip bottom offset-y right content-class="terminal-at-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <div class="pickup_on classTop" v-html="pickupOn(item, item)">
                      </div>
                    </span>
                  </template>
                  <span>
                   <p v-if="checkifstartinglocation(item)" class="mb-0">
                    {{item.starting_location_address}}
                  </p>
                  <p v-else>                  
                     {{ item.terminal != null ? item.terminal.name : "" }}
                    <span>{{ item.terminal != null ? item.terminal.firms_code : "" }}</span>
                  </p>
                    <!-- <p class="mb-0">{{ item.terminal != null ? item.terminal.firms_code : "" }}</p> -->
                    <p class="mb-0" style="font-size: 11px;">{{ item.terminal != null ? item.terminal.address : "" }}</p>
                  </span>
                </v-tooltip>
              </div>

              <div v-else>
                <div class="pickup_on classTop ccccccccc" v-html="pickupOn(item, item)">
                </div>
              </div>
            </td>
            <td class="deliveryon" style="padding-left: 0px !important; padding-right: 8px !important;">
              <div class="pickup_on classTop" v-html="deliveryon(
                typeFilter || !typeFilter
                  ? item
                  : item
              )
                "></div>
            </td>
            <td class="statuson" style="padding-left: 0px !important; padding-right: 0px !important;">
            
              <div class="AssignTo classTop">
                <p class="DRIVER_ASSIGN Assign commonP">
                  <span>
                    <span :class="`driver${item.id}`">
                      {{ getAssignedToDriver(
                        item,
                        typeFilter || !typeFilter
                          ? item
                          : item).driver
                      }}
                    </span>
                    <span :id="`menu${item.id}`" class="editDriver">
                      <svg width="17" height="17" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.7706 3.22941C17.7431 4.20196 17.7431 5.77878 16.7706 6.75133L7.57307 15.9488C7.17412 16.3478 6.67424 16.6308 6.12688 16.7677L3.27416 17.4808C2.81819 17.5948 2.40516 17.1818 2.51916 16.7258L3.23234 13.8731C3.36918 13.3258 3.6522 12.8259 4.05116 12.4269L13.2487 3.22941C14.2212 2.25686 15.798 2.25686 16.7706 3.22941ZM12.3681 5.87072L4.93163 13.3074C4.69226 13.5468 4.52245 13.8467 4.44034 14.1751L3.97883 16.0212L5.82488 15.5597C6.15329 15.4776 6.45322 15.3077 6.69259 15.0684L14.1288 7.63141L12.3681 5.87072ZM14.1291 4.10989L13.2484 4.99037L15.0091 6.75106L15.8901 5.87085C16.3764 5.38457 16.3764 4.59617 15.8901 4.10989C15.4038 3.62362 14.6154 3.62362 14.1291 4.10989Z"
                          fill="#0171A1" />
                      </svg>
                    </span><br>                   
                  </span>
                  <v-menu content-class="menuDriverdropdown" :activator="`#menu${item.id}`" transition="scale-transition"
                    max-height="300px" :nudge-width="200" offset-y nudge-left="200px" nudge-top="-1px">
                    <div v-if="displayMenu == 0">
                      <v-list class="pb-0">
                        <v-list-item style="height:50px !important" class="menu-border minHeightDriverDropdown"
                          v-for="(driver, i) in getNoFilterDrivers" :key="i" :value="driver.id">
                          <v-list-item-title class="px-1 pt-3 border" @click="handleDriverSelect(
                            driver,
                            item,
                            index,
                            typeFilter || !typeFilter
                              ? item.dispatch_schedule
                              : item.dispatch_schedule
                          )">
                            <button class="text-left mb-3">
                              <span>
                                {{ driver.name }}
                              </span>
                              <br>
                              <span class="greyColor" style="font-size: 10px">
                                {{ getDriverStatus(driver, item, item.dispatch_schedule) }}
                              </span>
                            </button>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </div>
                    <div v-else style="text-align:center; padding:20px;margin-bottom:10px;margin-top:10px;">
                      <div>
                        <v-progress-circular style="text-align:center" :size="20" :height="20" color="#0171a1"
                          indeterminate>
                        </v-progress-circular>
                      </div>
                    </div>
                  </v-menu>
                </p>
              </div>
            </td>
            <td class="statuson text-center" style="padding-left: 0px; padding-right: 0px">
              <div class="AssignTo classTop">
                <p class="DRIVER_ASSIGN Assign commonP">
                  <span :id="`status${item.id}`" :class="getAssignedToStatus(
                    typeFilter || !typeFilter
                      ? item
                      : item)">
                    {{ getAssignedToDriver(
                      typeFilter || !typeFilter
                        ? item
                        : item).status
                    }}
                  </span>
                </p>
              </div>
            </td>
            <!-- <td>
              <span :class="`status${item.id}`">{{ getDriverstatus(item) }}
               
              </span>
            </td> -->
            <td class="more_btn_outer statuson" style="padding-left: 0px; padding-right: 12px">
              <p class="mb-0 d-flex justify-end align-center" :class="isEmpty(leg.driver)
                  ? `${i ===
                    getNewDispatchScheduleLegs(
                      typeFilter || !typeFilter
                        ? item.dispatch_schedule
                        : item.dispatch_schedule
                    ).length -
                    1
                    ? ''
                    : 'commonP'
                  } DRIVER_unASSIGN unassignna hasNoDriver flex`
                  : `${i ===
                    getNewDispatchScheduleLegs(
                      typeFilter || !typeFilter
                        ? item.dispatch_schedule
                        : item.dispatch_schedule
                    ).length -
                    1
                    ? ''
                    : 'commonP'
                  } DRIVER_ASSIGN Assign hasDriver flex ${i}`
                " v-show="i === 0" v-for="(leg, i) in getNewDispatchScheduleLegs(
    typeFilter || !typeFilter
      ? item.dispatch_schedule
      : item.dispatch_schedule
  )" :key="leg.id">
                <v-btn @click="getPosition(`activator-${item.id}`)" :id="`activator-${item.id}`" class="more_btn_sm">
                  <img src="../../../assets/icons/more_dot.svg" alt="" />
                </v-btn>

                <v-menu bottom offset-y left :activator="`#activator-${item.id}`">
                  <!-- :nudge-top="nudgePos" -->
                  <v-list style="padding: 0">
                    <v-list-item class="hover-bg" @click="markAsArrived(item, leg, index)">
                      <v-list-item-title>Mark As Arrived</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="hover-bg" @click="editLeg(item, leg, index)">
                      <v-list-item-title>Edit Leg</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-btn @click="showAlert(item)" outlined class="more_btn_sm-custom ml-1">
                  <img src="../../../assets/icons/view-blue.svg" alt="" />
                </v-btn>
              </p>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="items.length > 0 &&
          (currentSelectTab == 'Yard full' || currentSelectTab == 'At Yard')
          " @click="(dateActivator !== '' || pddActivator !== '' || lfdActivator !== '') ? handleCancel() : ''">
          <tr v-for="(item, index) in items" :key="item.id" :class="getBg(item)">
            <td class="referrenceN">
              <div class="classTop">
                {{
                  item.trucker_container != null
                  ? capitalize(item.trucker_container)
                  : ""
                }}
              </div>
              <div>
                {{ item.shifl_ref != null ? item.shifl_ref : "" }}
              </div>
            </td>
            <td>
              <div class="text-uppercase">{{ item.mbl_num || "" }}</div>
            </td>
            <td>
              <div @dblclick="quickSizeEdit(item, index)" :class="`size${item.id}`" class="containers_etas">
                {{ getContainerSize(item) }}
              </div>
              <span :id="`size${item.id}`"></span>
              <v-menu :activator="`#size${item.id}`" min-width="175px">
                <v-list class="pb-0">
                  <v-list-item class="hover-item" v-for="(size, i) in shipmentsize" :key="i" :value="size.id"
                    @click="quickSizeEditSave(size)">
                    <v-list-item-title>
                      <span>
                        {{ size.name }}
                      </span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td>
              <p class="mb-0">{{ item.customer != null ? item.customer.company_name : "" }}</p>
              <p class="mb-0" style="color:#6d858f">{{ getconsineeitem(item) }}</p>
            </td>
            <td>
              <div class="containers_etas" :class="getTextColor(item)">
                <div>
                  <div> {{ getCarrier(item) }} </div>
                  <!-- <span class="text--disabled"> {{ getPerDiemDate(item) }} </span> -->
                  <span @dblclick="handleDblClick(item, 'per_diem_date', index)" class="text--disabled"
                    :class="`pdd${item.id}`">
                    {{ getDateFormat(item.per_diem_date) }}
                  </span>
                  <div class="vc-date-picker__wrapper">
                    <vc-date-picker title-position="left" is-expanded v-model="dateInput" mode="date"
                      :popover="{ visibility: 'click' }" :firstDayOfWeek="2" :masks="masks" :select-attribute="attribute"
                      @input="(e) => handleDateChange(e, item, dateInput, 'per_diem_date', index, 'dateRef4')"
                      ref="dateRef4">

                      <template #default="{ inputValue, inputEvents }">
                        <input :value="inputValue" v-on="inputEvents" class="create-do-eta-dxdate-vc mt-0"
                          placeholder="Select Date"
                          :class="`dp${item.id} ${item.id === pddActivator ? 'd-show' : 'd-none'}`"
                          @keyup="(e) => checkKeyUpVal(e, index, 'dateRef4')" />
                      </template>
                    </vc-date-picker>
                  </div>
                </div>

              </div>
            </td>
            <td v-html="getTimeArrived(item)"></td>
            <td v-html="getScheduledDate(item)"></td>
            <td v-if="['Yard full', 'At Yard'].includes(currentSelectTab) ||
              ['Yard full', 'At Yard'].includes(selected_sub_tab.label)
              " class="more_btn_outer statuson" style="padding-left: 0px; padding-right: 8px">
              <!-- <p class="more_btn_sm">
                <v-menu
                  class="more_btn"
                  bottom
                  origin="center center"
                  transition="scale-transition"
                  nudge-left="82"
                  nudge-top="-30"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on">
                      <img src="../../../assets/icons/more_dot.svg" alt="" />
                    </v-btn>
                  </template>
                  <v-list style="padding: 0">
                    <v-list-item
                      class="hover-bg"
                      @click="addFirstLeg(item)"
                      v-if="!isAnyUnarrivedLegAvailable(item)"
                    >
                      <v-list-item-title
                        >&nbsp;&nbsp;&nbsp;+ Add Leg
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      class="hover-bg"
                      @click="editFirstUnarrivedLeg(item)"
                      v-else
                    >
                      <v-list-item-title
                        >&nbsp;&nbsp;&nbsp; Edit Leg
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </p> -->
              <p class="flex justify-end pr-2 mb-0">
                <v-btn @click="getPosition(`activator-${item.id}`)" :id="`activator-${item.id}`" class="more_btn_sm">
                  <img src="../../../assets/icons/more_dot.svg" alt="" />
                </v-btn>

                <v-menu bottom offset-y left :activator="`#activator-${item.id}`">
                  <!-- :nudge-top="nudgePos" -->
                  <v-list style="padding: 0">
                    <v-list-item class="hover-bg" @click="addFirstLeg(item)" v-if="!isAnyLegAvailable(item)">
                      <v-list-item-title>&nbsp;&nbsp;&nbsp;+ Add Leg
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="hover-bg" @click="editFirstLeg(item)" v-else>
                      <v-list-item-title>&nbsp;&nbsp;&nbsp; Edit Leg
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn @click="showAlert(item)" outlined class="more_btn_sm-custom ml-1">
                  <img src="../../../assets/icons/view-blue.svg" alt="" />
                </v-btn>
              </p>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="items.length > 0 &&
          (currentSelectTab == 'At the Customer' ||
            currentSelectTab == 'Dropped empty')
          " @click="(dateActivator !== '' || pddActivator !== '' || lfdActivator !== '') ? handleCancel() : ''">
          <tr v-for="(item, index) in items" :key="item.id" :class="getBgAtheCustomer(item)">
            <td class="referrenceN">
              <div class="classTop">
                {{ item.trucker_container != null
                  ? capitalize(item.trucker_container)
                  : ""
                }}
              </div>
              <div>
                {{ item.shifl_ref != null ? item.shifl_ref : "" }}
              </div>
            </td>
            <td>
              <div class="text-uppercase">{{ item.mbl_num || "" }}</div>
            </td>
            <td>
              <div @dblclick="quickSizeEdit(item, index)" :class="`size${item.id}`" class="containers_etas">
                {{ getContainerSize(item) }}
              </div>
              <span :id="`size${item.id}`"></span>
              <v-menu :activator="`#size${item.id}`" min-width="175px">
                <v-list class="pb-0">
                  <v-list-item class="hover-item" v-for="(size, i) in shipmentsize" :key="i" :value="size.id"
                    @click="quickSizeEditSave(size)">
                    <v-list-item-title>
                      <span>
                        {{ size.name }}
                      </span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td>
              <p class="mb-0">{{ item.customer != null ? item.customer.company_name : "" }}</p>
              <p class="mb-0" style="color:#6d858f">{{ getconsineeitem(item) }}</p>
            </td>
            <td>
              <div class="containers_etas" :class="getTextColor(item)">
                <div>
                  {{ getCarrier(item) }}
                </div>
                <span :class="getPerDiemDateClass(item)">
                  <span @dblclick="handleDblClick(item, 'per_diem_date', index)" :class="`pdd${item.id}`">
                    {{ getDateFormat(item.per_diem_date) }}
                  </span>
                  <div class="vc-date-picker__wrapper">
                    <vc-date-picker title-position="left" is-expanded v-model="dateInput" mode="date"
                      :popover="{ visibility: 'click' }" :firstDayOfWeek="2" :masks="masks" :select-attribute="attribute"
                      @input="(e) => handleDateChange(e, item, dateInput, 'per_diem_date', index, 'dateRef5')"
                      ref="dateRef5">

                      <template #default="{ inputValue, inputEvents }">
                        <input :value="inputValue" v-on="inputEvents" class="create-do-eta-dxdate-vc mt-0"
                          placeholder="Select Date"
                          :class="`dp${item.id} ${item.id === pddActivator ? 'd-show' : 'd-none'}`"
                          @keyup="(e) => checkKeyUpVal(e, index, 'dateRef5')" />
                      </template>
                    </vc-date-picker>
                  </div>
                </span>
              </div>
            </td>
            <td>
              <div>
                <div>
                  {{ getAddress(item) }}
                </div>
                <div class="text--disabled">
                  <!-- {{ getATCTimeArrived(item) }}, {{ getDateArrived(item) }} -->
                  <span v-if="getATCTimeArrived(item) !== ''">{{ getATCTimeArrived(item) }}</span>
                  <span v-if="getDateArrived(item) !== ''">, {{ getDateArrived(item) }}</span>
                </div>
              </div>
            </td>
            <td v-if="currentSelectTab === 'At the Customer'">
              <span>{{ getMode(item) }}</span>
            </td>
            <td v-if="currentSelectTab === 'Dropped empty'">
              <span>{{ getType(item) }}</span>
            </td>
            <td class="text-center" v-if="currentSelectTab === 'At the Customer'">
              <input :id="item.id" class="empty-checkbox my-checkbox" type="checkbox" :checked="item.container_empty"
                @click="openDialog($event, item)" />
            </td>
            <td>
              <div>
                <div>
                  {{ getScheduleTime(item) }}
                </div>
                <div class="text--disabled" v-if="getScheduleDate(item) !== ''">
                  {{ getScheduleDate(item) }}
                </div>
              </div>
            </td>
            <td v-if="['At the Customer'].includes(currentSelectTab) ||
              ['At the Customer'].includes(selected_sub_tab.label)
              " class="more_btn_outer statuson" style="padding-left: 0px; padding-right: 12px">
              
              <p class="flex mb-0 d-flex align-center justify-end">
                <v-btn @click="getPosition(`activator-${item.id}`)" :id="`activator-${item.id}`" class="more_btn_sm">
                  <img src="../../../assets/icons/more_dot.svg" alt="" />
                </v-btn>

                <v-menu bottom offset-y left :activator="`#activator-${item.id}`">
                  <!-- :nudge-top="nudgePos" -->
                  <v-list style="padding: 0">
                    <v-list-item class="hover-bg" @click="addFirstLeg(item)" v-if="!isAnyLegAvailable(item)">
                      <v-list-item-title>&nbsp;&nbsp;&nbsp;+ Add Leg
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="hover-bg" @click="editFirstLeg(item)" v-else>
                      <v-list-item-title>&nbsp;&nbsp;&nbsp; Edit Leg
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn @click="showAlert(item)" outlined class="more_btn_sm-custom ml-1">
                  <img src="../../../assets/icons/view-blue.svg" alt="" />
                </v-btn>
              </p>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="items.length > 0 && currentSelectTab !== 'Scheduled'"
          @click="(dateActivator !== '' || pddActivator !== '' || lfdActivator !== '') ? handleCancel() : ''">
          <tr v-for="(item, index) in items" :key="item.id" :class="getBg(item)">
            <td class="referrenceN">
              <div class="classTop">
                {{
                  item.trucker_container != null
                  ? capitalize(item.trucker_container)
                  : ""
                }}
              </div>
              <div>
                {{ item.shifl_ref != null ? item.shifl_ref : "" }}
              </div>
            </td>
            <td>
              <div class="text-uppercase">{{ item.mbl_num || "" }}</div>
              <div>{{ getCarrier(item) }}</div>
            </td>
            <td>
              <div @dblclick="quickSizeEdit(item, index)" :class="`size${item.id}`" class="containers_etas">
                {{ getContainerSize(item) }}
              </div>
              <span :id="`size${item.id}`"></span>
              <v-menu :activator="`#size${item.id}`" min-width="175px">
                <v-list class="pb-0">
                  <v-list-item class="hover-item" v-for="(size, i) in shipmentsize" :key="i" :value="size.id"
                    @click="quickSizeEditSave(size)">
                    <v-list-item-title>
                      <span>
                        {{ size.name }}
                      </span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td>
              <p class="mb-0">{{ item.customer != null ? item.customer.company_name : "" }}</p>
              <p class="mb-0" style="color:#6d858f">{{getconsineeitem(item)}}</p>
            </td>
            <td v-if="[
                'Pending',
                'Available',
                'Yard full',
                'At Yard',
                'Dropped full',
                'Pending billing',
                'Completed',
                'Cancelled',
              ].includes(currentSelectTab) ||
              [
                'Pending',
                'Available',
                'Yard full',
                'At Yard',
                'Dropped full',
                'Pending billing',
                'Completed',
                'Cancelled',
              ].includes(selected_sub_tab.label)
              " @dblclick="handleDblClick(item, 'eta', index)">
              <!-- <div class="containers_etas" :class="getTextColor(item)">
                {{ getDateFormat(item.eta) }}
              </div> -->
              <div class="containers_etas" @dblclick="handleDblClick(item, 'eta', index)"
                :class="`text${item.id} ${getTextColor(item)}`">
                {{ getDateFormat(item.eta) }}
              </div>
              <div class="vc-date-picker__wrapper">
                <vc-date-picker title-position="left" is-expanded v-model="dateInput" mode="date"
                  :popover="{ visibility: 'click' }" :firstDayOfWeek="2" :masks="masks" :select-attribute="attribute"
                  @input="(e) => handleDateChange(e, item, dateInput, 'eta', index, 'dateRef6')" ref="dateRef6">

                  <template #default="{ inputValue, inputEvents }">
                    <input :value="inputValue" v-on="inputEvents" class="create-do-eta-dxdate-vc mt-0"
                      placeholder="Select Date" :class="`dp${item.id} ${item.id === dateActivator ? 'd-show' : 'd-none'}`"
                      @keyup="(e) => checkKeyUpVal(e, index, 'dateRef6')" />
                  </template>
                </vc-date-picker>
              </div>
            </td>
            <td v-if="['Available', 'Pending'].includes(currentSelectTab) ||
              ['Available', 'Pending'].includes(selected_sub_tab.label)
              " @dblclick="handleDblClick(item, 'last_free_date', index)">
              <div class="containers_etas" :class="getTextColor(item)">
                <!-- {{ getDateFormat(item.last_free_date) }} -->
                <span @dblclick="handleDblClick(item, 'last_free_date', index)" :class="`lfd${item.id}`">
                  {{ getDateFormat(item.last_free_date) }}
                </span>
                <div class="vc-date-picker__wrapper">
                  <vc-date-picker title-position="left" is-expanded v-model="dateInput" mode="date"
                    :popover="{ visibility: 'click' }" :firstDayOfWeek="2" :masks="masks" :select-attribute="attribute"
                    @input="(e) => handleDateChange(e, item, dateInput, 'last_free_date', index, 'dateRef7')"
                    ref="dateRef7">

                    <template #default="{ inputValue, inputEvents }">
                      <input :value="inputValue" v-on="inputEvents" class="create-do-eta-dxdate-vc mt-0"
                        placeholder="Select Date"
                        :class="`dp${item.shifl_ref} ${item.id === lfdActivator ? 'd-show' : 'd-none'}`"
                        @keyup="(e) => checkKeyUpVal(e, index, 'dateRef7')" />
                    </template>
                  </vc-date-picker>
                </div>
              </div>
            </td>
            <!-- // dropeed empty  -->
            <td v-if="['Dropped empty'].includes(currentSelectTab) ||
              ['Dropped empty'].includes(selected_sub_tab.label)
              ">
              <div>{{ item.carrier != null ? item.carrier.name : "" }}</div>
              <div class="text-dark-gray">
                <!-- {{ getDateFormat(item.per_diem_date) }} -->
                <span @dblclick="handleDblClick(item, 'per_diem_date', index)" :class="`pdd${item.id}`">
                  {{ getDateFormat(item.per_diem_date) }}
                </span>
                <div class="vc-date-picker__wrapper">
                  <vc-date-picker title-position="left" is-expanded v-model="dateInput" mode="date"
                    :popover="{ visibility: 'click' }" :firstDayOfWeek="2" :masks="masks" :select-attribute="attribute"
                    @input="(e) => handleDateChange(e, item, dateInput, 'per_diem_date', index, 'dateRef8')"
                    ref="dateRef8">

                    <template #default="{ inputValue, inputEvents }">
                      <input :value="inputValue" v-on="inputEvents" class="create-do-eta-dxdate-vc mt-0"
                        placeholder="Select Date"
                        :class="`dp${item.id} ${item.id === pddActivator ? 'd-show' : 'd-none'}`"
                        @keyup="(e) => checkKeyUpVal(e, index, 'dateRef8')" />
                    </template>
                  </vc-date-picker>
                </div>
              </div>
            </td>
            <td v-if="['Dropped empty'].includes(currentSelectTab) ||
              ['Dropped empty'].includes(selected_sub_tab.label)
              " v-html="getTimeArrived(item)"></td>
            <td v-if="['Dropped empty'].includes(currentSelectTab) ||
              ['Dropped empty'].includes(selected_sub_tab.label)
              ">
              {{ getType(item) }}
            </td>
            <td v-if="['Pending', 'Available'].includes(currentSelectTab) ||
              ['Pending', 'Available'].includes(selected_sub_tab.label)
              ">
              {{ getDirectPrepulStatus(item) || '' }}
            </td>
            <!--  // -->
            <td v-if="[
                'Pending',
                'Available',
                'Yard full',
                'At Yard',
                'Pending billing',
                'Completed',
                'Cancelled',
                'Dropped full',
              ].includes(currentSelectTab) ||
              [
                'Pending',
                'Available',
                'Yard full',
                'At Yard',
                'Pending billing',
                'Completed',
                'Cancelled',
                'Dropped full',
              ].includes(selected_sub_tab.label)
              ">
              <!-- {{ item.terminal_id != null ? getTerminalName(item.terminal_id) : "--" }} -->
              <v-tooltip bottom offset-y right content-class="terminal-at-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <p class="mb-0" v-bind="attrs" v-on="on">
                    {{ item.terminal_id != null ? getTerminalData(item.terminal_id, 'nickname') : "" }}
                  </p>
                </template>
                <p class="mb-0">
                  {{ item.terminal_id != null ? getTerminalData(item.terminal_id, 'name') : "" }}
                  <span>{{ item.terminal_id != null ? getTerminalData(item.terminal_id, 'firms') : "" }}</span>
                </p>
                <!-- <p class="mb-0">{{ item.terminal_id != null ? getTerminalData(item.terminal_id, 'firms') : "" }}</p> -->
                <p class="mb-0" style="font-size: 11px;">{{ item.terminal_id != null ? getTerminalData(item.terminal_id,
                  'address') : "" }}</p>
              </v-tooltip>
            </td>
            <td v-if="['Available', 'Dropped empty'].includes(currentSelectTab) ||
              ['Available', 'Dropped empty'].includes(selected_sub_tab.label)
              " v-html="getScheduledDate(item)"></td>
            <!-- milestones -->
            <td class="text-center" v-if="['Pending'].includes(currentSelectTab) ||
              ['Pending'].includes(selected_sub_tab.label)
              ">
              <input :id="item.id + '_discharged'" class="empty-checkbox my-checkbox" type="checkbox"
                :checked="item.discharged" @click="openMilestoneDialog($event, item, 'discharged')" />
            </td>
            <td class="text-center" v-if="['Pending'].includes(currentSelectTab) ||
              ['Pending'].includes(selected_sub_tab.label)
              ">
              <input :id="item.id + '_pier_pass_paid'" class="empty-checkbox my-checkbox" type="checkbox"
                :checked="item.pier_pass_paid" @click="openMilestoneDialog($event, item, 'pier_pass_paid')" />
            </td>
            <td class="text-center" v-if="['Pending'].includes(currentSelectTab) ||
              ['Pending'].includes(selected_sub_tab.label)
              ">
              <input :id="item.id + '_fully_released'" class="empty-checkbox my-checkbox" type="checkbox"
                :checked="item.fully_released" @click="openMilestoneDialog($event, item, 'fully_released')" />
            </td>
            <td v-if="['Pending', 'Available'].includes(currentSelectTab) ||
              ['Pending', 'Available'].includes(selected_sub_tab.label)
              " class="more_btn_outer statuson" style="padding-left: 0px; padding-right: 12px">
             
              <p class="flex mb-0 justify-end">
                <v-btn @click="getPosition(`activator-${item.id}`)" :id="`activator-${item.id}`" class="more_btn_sm">
                  <img src="../../../assets/icons/more_dot.svg" alt="" />
                </v-btn>

                <v-menu bottom offset-y left :activator="`#activator-${item.id}`">
                  <!-- :nudge-top="nudgePos" -->
                  <v-list style="padding: 0">
                    <v-list-item class="hover-bg" @click="addFirstLeg(item)" v-if="!isAnyLegAvailable(item)">
                      <v-list-item-title>&nbsp;&nbsp;&nbsp;+ Add Leg
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="hover-bg" @click="editFirstLeg(item)" v-else>
                      <v-list-item-title>&nbsp;&nbsp;&nbsp; Edit Leg
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn @click="showAlert(item)" outlined class="more_btn_sm-custom ml-1">
                  <img src="../../../assets/icons/view-blue.svg" alt="" />
                </v-btn>
              </p>
            </td>
            <td v-if="['Pending billing', 'Completed', 'Cancelled',].includes(currentSelectTab) ||
              ['Pending billing', 'Completed', 'Cancelled',].includes(selected_sub_tab.label)
              " class="more_btn_outer statuson" style="padding-left: 0px; padding-right: 16px !important;">
              <div class="d-flex align-center justify-end">
                <v-btn @click="showAlert(item)" outlined class="more_btn_sm-custom ml-1">
                  <img src="../../../assets/icons/view-blue.svg" alt="" />
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="tr-schedule-no-data">
            <td style="align: center" colspan="12">
              <!-- :colspan="
                ['Scheduled', 'At the Customer', 'At Port'].includes(
                  currentSelectTab
                )
                  ? 9
                  : 7
              " -->
              <div v-if="shouldLoad" style="text-align: center" class="pt-3">
                <v-progress-circular :size="35" color="#0171a1" indeterminate>
                </v-progress-circular>
              </div>
              <div v-if="!getPoLoading && !shouldLoad" style="text-align: center">
                <div v-if="currentSelectTab == 'Scheduled'">
                  <div v-if="filterstarted">Fetching data...</div>
                  <div v-else class="wrapno-matching" style="padding-top: 70px; display: inline-block">
                    <img src="@/assets/icons/truckicon.svg" />
                    <p class="nomatching">No matching result</p>
                    <p>We could not Find any Shipments under this filters</p>
                    <p>
                      <button class="btn-white" style="padding-top: 6px !important" @click="triggersearch">
                        Try Different Search
                      </button>
                    </p>
                  </div>
                </div>
                <div v-else class="pt-5">No available data</div>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:[`no-data`]>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>
    </v-data-table>
  
    <Pagination v-if="typeof newData !== 'undefined' && newData.length > 0" :pageData.sync="page"
      :lengthData.sync="updatedPageCount" :isMobile="isMobile" />
    <EmptyDialog :dialog.sync="dialog" :itemData="dialogData" :isLoading="isSubmitting" @closeDialog="closeDialog"
      @updateContainerEmpty="updateContainer" />
    <MilestoneConfirmationDialog :dialog.sync="milestoneDialog" :itemData="milestoneDialogData"
      :isLoading="milestoneLoading" :field="selectedMilestoneField" :isChecked="isSelectedMilestoneFieldChecked"
      @closeMilestoneDialog="closeMilestoneDialog" @updateMilestone="updateMilestone" />
   
    <DatePickerETARange :open="isDatePickerOpen" :multiple="true" :label="pickerLabel" @confirm="confirmDatePicker"
      @close="closeDatePicker" :defaultDate="selectedEtaRange" />
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions
}
  from "vuex";
import axios from "axios";
const poBaseUrl = process.env.VUE_APP_PO_URL;
import Search from "../../Search.vue";
import Pagination from "../../Pagination.vue";
import globalMethods, { } from "../../../utils/globalMethods";
import moment from "moment";
import jQuery from "jquery";
import tabs from "./Config/TabWiseColumn.js";
import EmptyDialog from "./Dialog/EmptyDialog.vue";
import MilestoneConfirmationDialog from "./Dialog/MilestoneConfirmationDialog.vue";
import EditLeg from "./Dialog/EditLeg";
import AddNewLeg from "./Dialog/AddNewLeg";
import DatePickerETARange from './Dialog/DatePickerETARange.vue';
const el = ".v-data-table__wrapper table";
import _ from "lodash";
var cancel;
var CancelToken = axios.CancelToken;

export default {
  name: "PO2DesktopTable",
  props: [
    "items",
    "isMobile",
    "tabitemdata",
    "newtabitem",
    "contentData",
    "allcomopents",
    "customers",
    "terminals",
    "magdriver",
    "shipmentsize",
    //"magdropterminal",
    "mgaFiles",
    "responseObjectMeta",
    "outsideClick"
  ],
  components: {
    Search,
    Pagination,
    EmptyDialog,
    MilestoneConfirmationDialog,
    EditLeg,
    AddNewLeg,
    DatePickerETARange
  },
  async mounted() {
    this.$store.dispatch("page/setPage", "containers");
    // this.fetchDrivers();
    // this.fetchChassis();
    // this.fetchTerminals();
    // if(this.getYards.length == 0){
    // 	this.fetchYards();
    // }
    this.getallDataschedule();
    this.refresh = 0;
    this.$nextTick(() => {
      let m = localStorage.getItem('key')
      this.activeTab = parseInt(m)
      this.currentSelectTab = localStorage.getItem('currentSelectTab');
      this.refresh = 1;

      this.handleCancel()
    });

  },
  data: () => ({
    editinglocation:'inline',
    refNumber:[],
    oldterminaldropdown:[],
    pickuplocationload:1,
    allcheckthebox:[],
    checkitem:true,
    allschedule:[],
    theReferenceIntersect:'',
    getDriverBusy:[],
    test:false,
    displayMenu:false,
    internalactivate:false,
    currentDataleg:{ legs: ''},
    currentIndex:0,
    editIndex: true,
    isloading: true,
    allnewDriver: [],
    markasrrived: 0,
    checkallLocation: [],
    checkall: [],
    allDriverList: [],
    allLocationList: [],
    checkbox1: true,
    uncheckbox1: false,
    magdriverFilter: [],
    magdropterminal: [],
    startloading: 0,
    detectchange: 0,
    foundsearch: false,
    whatclick: true,
    assigntrigger: 0,
    refresh: 1,
    metaobject: [],
    filterstarted: false,
    filterdataRes: [],
    triggerDisselected: 0,
    triggerapply: 1,
    hasAssignedDriver: false,
    driverfinalPush: [],
    newByDriverFilterAssginged: [],
    currentDriverFilterID: 0,
    cardDropdownFilter: true,
    typeFilter: 0,
    drivernameLabel: [],
    locationDropdowndetected: 0,
    locationfromfilter: [],
    driverSelectFilter: [],
    inititaldriverSelectFilter: [],
    sub_tab: [],
    selected_sub_tab: {},
    fav: true,
    menu: false,
    message: false,
    hints: true,
    showhideDropdown: false,
    hastwotypes: "hastwotypes",
    numberAssignType: "data-numberassigntype",
    filterTabitem: "Scheduled",
    callTrigger: "",
    titlePage: "+ Create Delivery Order",
    naayDriverwala: "hasDriver",
    walayDriver: "hasNoDriver",
    refcontainercl: "",
    updatedni: 0,
    retabs: 1,
    currentStateClick: "",
    borderzero: {
      border: "'border','0px !important'",
    },
    hasDriverLastElement: "hasDriverLastElement",
    loopUnassigned: 0,
    loopAssign: 0,
    lenthDispatch: 0,
    assignna: 0,
    alert: 1,
    // filterAssign: ["All", "Assign", "Unassigned"],
    filterAssign: ["All", "Unassigned", "Assigned", "In-Progress"],
    classSelected: "All",
    isDisabled: true,
    isDisabledfilter: true,
    currentView: [],
    itemsData: [],
    dialog: false,
    dialogData: [],
    isSubmitting: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 15,
    search: "",
    tabs: [
      "Pending",
      "Available",
      "In Yard full ",
      "Assigned",
      "Dropped full",
      "Dropped empty",
      "Pending billing",
      "Completed",
      "Cancelled",
    ],
    activeTab: 0,
    isInputExpanded: false,
    searchData: "",
    currentSelectTab: "All",
    selectedTasks: [],
    filters: [
      {
        title: "Pending",
      },
      {
        title: "Available",
      },
      {
        title: "Yard full",
      },
      {
        title: "Scheduled",
      },
      {
        title: "At the Customer",
      },
      //Renamed into "At the Customer" tab
      // {
      // 	title: 'Dropped full'
      // },
      {
        title: "Dropped empty",
      },
      {
        title: "Pending billing",
      },
      {
        title: "Completed",
      },
      {
        title: "Cancelled",
      },
    ],
    tabdata: [],
    tabClicked: false,
    aIsfirst: 0,
    bIsfirst: 0,
    milestoneDialog: false,
    milestoneDialogData: null,
    milestoneLoading: false,
    selectedMilestoneField: null,
    isSelectedMilestoneFieldChecked: false,
    typeDropdown: [
      {
        label: "Customer",
        value: "Customer",
      },
      {
        label: "Yard",
        value: "Yard",
      },
      {
        label: "To Port",
        value: "Empty Return",
      },
    ],
    modedropdown: [
      {
        id: 1,
        name: "Drop",
      },
      {
        id: 2,
        name: "Live",
      },
    ],
    responseChassis: null,
    showEditLegModal: false,
    editingLeg: null,
    editingShipment: null,
    showAddLegModal: false,
    lastLeg: null,
    newContainersData: [],
    oldContainersData: [],
    shouldLoad: false,
    loadingData: false,
    updatedPageCount: false,
    lastPage: 1,
    oldselectedTab: "",
    selectedActiveInactiveFilter: { label: 'All', value: 'all' },
    activeInactiveFilter: [{ label: 'All', value: 'all' }, { label: 'Only Actives', value: 'active' }, { label: 'Only Inactives', value: 'inactive' }],
    selectedCustomerFilter: { id: 0, company_name: 'Select' },
    isDatePickerOpen: false,
    selectedEtaRange: [],
    pickerLabel: '',
    nudgePos: "120%",
    dateActivator: "",
    lfdActivator: "",
    pddActivator: "",
    datePickerOpen: false,
    selectedCont: "",
    selectedETAEdit: "",
    selectedETADate: "",
    selectedLFDEdit: "",
    selectedLFDDate: "",
    selectedPDDEdit: "",
    selectedPDDDate: "",
    editedKey: "",
    dateInput: "",
    dateValidation: [
      (date) => {
        if (date === "") {
          return "This field is required"
        } else {
          if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
            return true
          }

          return "The date should be in YYYY-MM-DD format"
        }
      }
    ],
    selectedContSize: "",
    activeSize: "",
    selectedShipment: "",
    scheduledLastLeg: "",
    scheduledLastLegID: "",
    containerIndex: "",
    datePicked: "",
    subTabIndex: 0,
    trySelected: "",
    loadedContainersData: {},
    currentTab: "",
    attribute: {
      highlight: {
        style: {
          backgroundColor: '#0171A1', // blue
          borderRadius: '4px'
        },
        contentStyle: {
          color: '#ffffff', // color of the text
          borderRadius: '4px'
        }
      },
    },
    masks: {
      input: 'YYYY-MM-DD',
      weekdays: "WWW"
    },
    dateErrors: {},
    isLegUpdated: 0,
    isSelectedETARange: false,
    isSelectedCustomerFilter: false,
    isSelectedActiveInactiveFilter: false,
    typingTimeout: 0
  }),
  computed: {
    ...mapGetters({
      getPoLoading: "containers/getPoLoading",
      gettabcounter: "containers/gettabcounter",
      getAllcontainer: "containers/getAllContainers",
      getAllcontainerStatus: "containers/getAllcontainersStatus",
      getTabLoading: "containers/getTabLoading",
      getCurrentSelectTab: "containers/getCurrentSelectedTab",
      getScheduledFilterState: "containers/getScheduledFilterState",
      getDrivers: "common/getDrivers",
      getChassis: "common/getChassis",
      getTerminals: "common/getTerminals",
      getYards: "common/getYards",
      getNoFilterDrivers: "common/getNoFilterDrivers"
    }),
    checkmenu: {
      get() {
        return this.displayMenu
      }
    },
    getEditingShipmentEta: {
      get() {
        if (this.editingShipment && this.editingShipment.eta) {
          return moment(this.editingShipment.eta)
            .utc(false)
            .format("YYYY-MM-DD");
        }
        return null;
      },
    },
    getlocation: {
      get() {
        var f = this.magdropterminal;
        return f;
      },
    },
    driverdropdown: {
      get() {
        var f = this.allnewDriver;
        return f;
      },
    },
    object() {
      return Object.values(this.items);
    },
    headers() {
      if (this.currentSelectTab == "All") {
        return tabs.all_tab;
      } else if (this.currentSelectTab == "At Port") {
        if (this.selected_sub_tab.label == "Pending") {
          return tabs.pending_tab;
        } else {
          return tabs.available_tab;
        }
      }
      if (this.currentSelectTab == "Pending") {
        return tabs.pending_tab;
      } else if (this.currentSelectTab == "Available") {
        return tabs.available_tab;
      } else if (this.currentSelectTab == "Scheduled") {
        return tabs.scheduled_tab;
      } else if (this.currentSelectTab == "At Yard") {
        if (this.selected_sub_tab.name == "Pending") console.log(this.sub_tab);
        return tabs.in_yard_full;
      } else if (this.currentSelectTab == "At the Customer") {
        return tabs.at_the_customer_tab;
      } else if (this.currentSelectTab == "Dropped empty") {
        return tabs.dropped_empty_tab;
      }
      return tabs.default;
    },
    // edit leg
    editingLegPickupLocation() {
      if (this.editingLeg != null && this.editingShipment != null) {
        let legIndex = this.currentEditingLegIndex;
        try {
          if (legIndex == 0){
              // check if first leg is a custom address 
              if( this.editingShipment.is_custom_starting_location){
                // starting_location_address
                return this.editingShipment.starting_location_address;
              }else{
                return this.getTerminals.find(
                  (t) => t.id == this.editingShipment.terminal_id
                ).name;
              }
            }
        } catch (err) {
          return "";
        }
        if (this.editingShipment.dispatch_schedule != "") {
          let dispatch_schedule = null;
          try {
            dispatch_schedule = JSON.parse(
              this.editingShipment.dispatch_schedule
            );
          } catch (err) {
            console.log(err);
          }
          if (dispatch_schedule) {
            let legs = dispatch_schedule.legs || {};
            if (legs.length > 0) {
              let address = legs[0].address;
              for (let i = 1; i < legs.length; i++) {
                if (legs[i].id == this.editingLeg.id) {
                  return address;
                }
                address = legs[i].address;
              }
            }
          }
        }
      }
      return "";
    },
    currentEditingLegIndex() {
      if (this.editingLeg != null && this.editingShipment != null) {
        if (this.editingShipment.dispatch_schedule != "") {
          let dispatch_schedule = null;
          try {
            dispatch_schedule = JSON.parse(
              this.editingShipment.dispatch_schedule
            );
          } catch (err) {
            console.log(err);
          }
          if (dispatch_schedule) {
            let legs = dispatch_schedule.legs || {};
            if (legs.length > 0) {
              for (let i = 0; i < legs.length; i++) {
                if (legs[i].id == this.editingLeg.id) {
                  return i;
                }
              }
            }
          }
        }
      }
      return 0;
    },
    getPickupLocation() {
      if (this.editingShipment != null) {
        try {
          if (this.editingShipment.dispatch_schedule) {
            let dispatch_schedule = JSON.parse(
              this.editingShipment.dispatch_schedule
            );
            if (
              dispatch_schedule &&
              typeof dispatch_schedule.legs != "undefined" &&
              dispatch_schedule.legs.length > 0
            )
              return dispatch_schedule.legs[dispatch_schedule.legs.length - 1]
                .address;
          }
          if (this.editingShipment.terminal_id)
            return this.getTerminals.find(
              (t) => t.id == this.editingShipment.terminal_id
            ).name;
        } catch (err) {
          console.log(err);
        }
      }
      return "";
    },
    newData: {
      get() {
        return this.newContainersData;
      },
    },
    // defaultDate() {
    //   return this.datePicked
    // },
    lfdPdd() {
      let rv = ""
      if (this.selectedCont !== "") {
        if (this.getLastArrivedLeg(this.selectedCont)) {
          rv = this.selectedPDDDate
        }
        else {
          rv = this.selectedLFDDate
        }
      }

      return rv
    }
  },
  watch: {
    magdropterminal(c) {
      console.log(c)
    },
    menu(c) {
      if (c)
        if (this.magdropterminal.length == 0) {
          this.displayFilters()
        }
    },
    checkall(c) {
      this.allDriverList = (c);
    },
    locationfromfilter: {
      deep: true,
      handler(newValue) {

        // console.log(newValue)

        var newstring = [];
        newValue.forEach((v) => {
          newstring.push({ location: this.magdropterminal.find((e) => e.id == v).name });
        })
        this.allLocationList = JSON.stringify(newstring);
      }
    },
    classSelected(c) {
      if (c != "") this.whatclick = this.whatclick ? false : 0;
      this.refresh = 0;
      this.$nextTick(() => {
        this.refresh = 1;
      });
    },
    items: function (value) {
      this.itemsData = value;
    },
    itemsData: function (v) {
      this.itemsData = v;
    },
    getPoLoading: function (value) {
      if (value) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    updatedni(c) {
      if (c) {
        this.retabs = 0;
        this.$nextTick(() => {
          var text = this.getCurrentSelectTab;
          if (Object.keys(text).length !== 0) {
            this.activeTab = parseInt(text.activeTab);
            this.currentSelectTab = text.id;

            if (text.sub_tab === undefined) {
              let current_tab_item = this.tabItems.find((f) => f.name == this.currentSelectTab);
              this.selected_sub_tab = current_tab_item.sub_tab[0];
              this.subTabIndex = 0
            } else {
              if (text.sub_tab !== undefined) {
                if (typeof text.sub_tab === 'string') {
                  this.selected_sub_tab = {
                    label: text.sub_tab,
                    name: text.sub_tab,
                    count: 0,
                  }
                  this.subTabIndex = text.subTabIndex
                } else {
                  this.selected_sub_tab = this.getCurrentSelectTab.sub_tab
                  this.subTabIndex = this.getCurrentSelectTab.subTabIndex
                }
              }
            }

            this.retabs = 1;
            this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1);
          } else {
            this.retabs = 1;
            this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1);
          }
        });
      }
    },
    activeTab(nv) {
      //console.log(nv);
      if (nv && !nv) {
        //console.log(nv);
      }
      if (this.oldselectedTab !== this.currentSelectTab) {
        this.isDisabled = true;
      }
    },
    currentTab(nv) {
      if (typeof this.loadedContainersData[nv] !== "undefined") {
        this.pageCount = 0
        this.lastPage = this.loadedContainersData[nv].lastPage
      }
    },
    pageCount: {
      handler(newValue, oldValue) {
        if (this.search !== "") {
          //console.log(newValue, oldValue);
          if (newValue !== 0) {
            this.updatedPageCount = 1;
          } else if (oldValue > this.lastPage) {
            //console.log("2");
            this.updatedPageCount = 1;
          } else if (this.lastPage > oldValue) {
            //console.log("3");
            this.updatedPageCount = this.lastPage;
          } else {
            //console.log("4");
            this.updatedPageCount = 1;
          }
        } else {
          if (newValue > 1) {
            this.updatedPageCount = newValue;
          } else this.updatedPageCount = this.lastPage;
        }
      },
      immediate: true,
    },
    shouldLoad: {
      handler(newValue) {
        this.loadingData = newValue;
      },
      immediate: true,
    },
    // selected_sub_tab(nv) {
    //   this.selectedTabData(this.currentSelectTab, nv, 1);
    // },
    currentSelectTab(nv, oldValue) {
      this.oldselectedTab = oldValue;
      this.pageCount = 1;
      // if (this.newContainersData.length < 1) {
      //   if (this.oldContainersData.length < 1) {
      //     this.selectedTabData(nv, this.selected_sub_tab, 1);
      //   } else {
      //     this.selectedTabData(nv, this.selected_sub_tab);
      //   }
      // } else this.selectedTabData(nv, this.selected_sub_tab, 1);

      this.dateActivator = ""
      this.lfdActivator = ""
      this.pddActivator = ""
    },
    page(nv) {
      if (this.driverSelectFilter.length > 0 || this.locationfromfilter.length > 0) {
        this.methodtest(this.driverSelectFilter, this.locationfromfilter, nv);
      } else {
        this.clearSelection();
        this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, nv);
      }
    },
    search() {
      if (cancel !== undefined) {
        cancel("cancel_previous_request")
      }

      clearTimeout(this.typingTimeout)
      this.typingTimeout = setTimeout(() => {
        this.pageCount = 1;
        this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1);
      }, 250)

      // this.pageCount = 1;
      // this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1);
    },
    contentData() {
      // if(this.currentSelectTab=='All')
      // this.selectedTabData("All", "Pending");
    },
    selectedCustomerFilter() {
      this.isSelectedCustomerFilter = true
      if (this.currentSelectTab == "All") this.selectedTabData("All", this.selected_sub_tab, 1);
    },
    selectedActiveInactiveFilter() {
      this.isSelectedActiveInactiveFilter = true
      if (this.currentSelectTab == "All") this.selectedTabData("All", this.selected_sub_tab, 1);
    },
    selectedShipment(nv, ov) {
      nv, ov
      jQuery(`.size${ov.id}`).removeClass('d-none')
      jQuery(`.pdd${ov.id}`).removeClass('d-none')
      jQuery(`.lfd${ov.id}`).removeClass('d-none')
      jQuery(`.text${ov.id}`).removeClass('d-none')
    },
    selectedContSize(nv, ov) {
      nv, ov
      console.log(this.shipmentsize)
      this.shipmentsize.map((s) => {
        if (s.id === nv) {
          this.activeSize = s.name
        }
      })
    },
    datePicked(nv, ov) {
      console.log('nv:ov >>>', nv + ":" + ov)
      if (nv !== "" && ov !== "") {
        if (this.selectedCont[this.editedKey] === "" && nv !== "") {
          this.trySelected = nv
        } else {
          this.trySelected = nv
        }
      }
    },
    trySelected(nv) {
      if (nv !== "") {
        let fd = moment(nv)
        let containerETADate = moment(this.selectedCont.eta)
        let containerDate = moment(this.selectedCont[this.editedKey])

        // console.log('nv >>>', nv)
        // console.log('fd >>>', fd)
        // console.log('container ETA >>>', containerETADate)
        // console.log('container date >>>', containerDate)

        if (this.editedKey === 'per_diem_date' || this.editedKey === 'last_free_date') {
          if (fd.isBefore(containerETADate)) {
            this.notificationErrorCustom('The date should be later than the ETA')
          } else {
            if (nv !== containerDate.format('YYYY-MM-DD')) {
              this.handleSave()
            }
          }
        }
        else {
          if (nv !== containerDate.format('YYYY-MM-DD')) {
            this.handleSave()
          }
        }
      }
    },
    outsideClick(nv) {
      console.log(nv)
      this.handleCancel()
    }
  },
  methods: {
    getconsineeitem(c){
      if(typeof c.deliveryorder === 'object' ){
        /**
         *  &&
            !Array.isArray(yourVariable) &&
            yourVariable !== null

         */
        if(c.deliveryorder.consignee === null ){
            return '--'          
        }else{
          return c.deliveryorder.consignee.name;
        }
      }
      
      return '--';
      

    },
    callselection(data) {
      this.magdropterminal.map(function (num) {
        let array = data;
        let found = array.find(function (element) {
          return element == num.id;
        });
        if (typeof found !== 'undefined') {
          if (typeof num.selected != 'undefined') {
            num.selected = true
          }
        } else {
          num.selected = false
        }
      })
      //console.log(this.magdropterminal)
    },
    ifhaselement(item) {
      if (typeof item.selected != 'undefined') {
        return item.selected;
      }
      return false;
    },
    checkIfinvalid(string) {
      if (string.search("Invalid") == -1) { // not found
        return 0;
      }
      return 1;
    },
    checkifValid_then_return(string) {
      if (string.search("Invalid") == -1) { // not found
        return string;
      }
      return '00:00:00';
    },
    getCounter(item) {
      if (item !== null && item !== "-000001-11-30T00:00:00.000000Z") {
        let now = moment(moment().format('YYYY-MM-DD'))
        let date = moment(item).format('YYYY-MM-DD')
        let countDay = moment.duration(moment(item).diff(moment()))
        let isPast = moment(item).isBefore(moment())
        let humanize = moment.duration(moment(item).diff(moment())).humanize()

        if (now.format('YYYY-MM-DD') === date) {
          return 'Today'
        }
        else {
          // check if current month is same as the date month
          if (countDay.days() === 1 && countDay._data.months === 0) {
            if (now.isBefore(date)) {
              return '1 day to'
            }
            else {
              return '1 day ago'
            }
          }
          else if (countDay.days() === 0 && countDay._data.months === 0) {
            return '1 day ago'
          }
          return humanize + (isPast ? ' ago' : ' to')
        }
      } else {
        return ''
      }
    },
    async getPlacePickuplocation(val) {
      let theplace = [];
      await fetch(`${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/` + val + `.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}&country=US`)
        .then((response) => response.json())
        .then((json) => {
          theplace = [...json.features]
        })
        .catch((e) => {
          console.log(e);
        });

      if (typeof theplace[0].geometry.coordinates[0] != 'undefined') {
        this.theplacesValue.push(theplace[0].geometry.coordinates[0] + ',' + theplace[0].geometry.coordinates[1]);
      }
    },
    /**
     *  type is delivery = if delivery means null ang pickup date and time 
     *  type is pickup = if pickup means null ang delivery date and time 
     */
    async getPlaces(currentLeg, currentLoop, type) {
      // check overlapp pickup location and driver pickup location
      // check overlap pickup location 

      let durations = [];
      let targetDate = '';
      //var travelTime = '';
      let latitudes = this.theplacesValue.join(';');

      let url = "https://api.mapbox.com/directions-matrix/v1/mapbox/driving/";
      let attribute = url + latitudes + `?annotations=distance,duration&approaches=curb;curb&access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}`;

      await fetch(`${attribute}`)
        .then((response) => response.json())
        .then((json) => {
          durations = json
        })
        .catch((e) => {
          console.log(e);
        });

      if (durations.durations.length > 0) {
        this.estimatedTimeArrival = durations.durations[0][1];

        // Add it to the
        if (type == 'Delivery') {
          // currentLeg.date = delivery date
          targetDate = currentLeg.date + ' ' + currentLeg.time; //add it to delivery

          /**
           *  date = delivery date
           *  time = delivery time
           * 
           *  from_date  = pickup date
           *  to_time = pickup time
           * 
           **/

          let travelDate = moment(targetDate).add(this.estimatedTimeArrival, 'seconds').format('YYYY-MM-DD');
          // travelTime = moment(targetDate).add(this.estimatedTimeArrival, 'seconds').format('hh:mm A'); // estimated

          let monthCompare = moment(travelDate).utc(true).format("YYYY-MM-DD")
          let monthloopCompare = moment(currentLoop.from_date).utc(true).format("YYYY-MM-DD")
          let monthloopCompareDelivery = moment(currentLoop.date).utc(true).format("YYYY-MM-DD")
          //let timeLoopCompareCurrentLoop =  moment(currentLoop.to_time).format('hh:mm A'); //AM or PM // estimated

          // compare it with loop container's leg and the current leg Delivery Order
          if (monthloopCompare == monthCompare) {
            if (monthloopCompareDelivery == monthCompare) {
              /* 
                0: 2
                1: '' 
                2: "AM"
              */
              //return this.timeOverlapped(travelTime,currentLoop,'delivery');
            }
          }
        } else {
          // if pickup
          // currentLeg.date = delivery date
          targetDate = currentLeg.from_date + ' ' + currentLeg.to_time; //add it to pickup

          /**
           *  date = delivery date
           *  time = delivery time
            
           *  from_date  = pickup date
           *  to_time = pickup time
           * 
           **/

          let travelDate = moment(targetDate).add(this.estimatedTimeArrival, 'seconds').format('YYYY-MM-DD');
          //travelTime     = moment(targetDate).add(this.estimatedTimeArrival, 'seconds').format('hh:mm A'); // estimated

          let monthCompare = moment(travelDate).utc(true).format("YYYY-MM-DD")
          let monthloopCompare = moment(currentLoop.date).utc(true).format("YYYY-MM-DD")
          // let timeLoopCompareCurrentLoop =  moment(currentLoop.time).format('hh:mm A'); // AM or PM // estimated

          // compare it with loop container's leg and the current leg Delivery Order
          if (monthloopCompare == monthCompare) {
            /* 
              0: 2
              1: '' 
              2: "AM"
            */
            //return this.timeOverlapped(travelTime,currentLoop,'pickup');    

          }
        }

        // moment('2016-03-12 13:00:00').add(24, 'hours').format('LLL'
        // it will add 642 seconds in the current time and will give time in 03:35 PM format

      }
    },
    detectClickOutsideTable() {
      this.handleCancel()
    },
    checkKeyUpVal(e, index, model) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        this.$refs[model][index].hidePopover(); // close popover on click enter
      }
    },

    /** 
        timeFrom = Loop shchedule time , 
        estimatedTime = estimated time + delivery date
        currentLoop = current edit leg 
        type = type delivery or pickup 
        
        [arrival_at_pickup_location_date] => 
        [arrival_at_pickup_location_time] => 
        
        [departure_from_pickup_location_date] => 
        [departure_from_pickup_location_time] => 
        
        [arrival_at_delivery_location_date] => 2023-01-14
        [arrival_at_delivery_location_time] => 
        
        [departure_from_delivery_location_date] => 
        [departure_from_delivery_location_time] => 
     */
    timeOverlapped(estimatedTime, currentLoop, type) {

      //let timefrom   = this.timetoArray(timeFrom); // MEANS SPLIT THE TIME EX: 04:12 AM
      //alert(estimatedTime)

      let timeTarget = this.timetoArray(estimatedTime);
      let time = '';
      let time_interval = '';
      let time_end = '';

      try {

        if (type == 'arrival') {
          // based on the arrival
          if (currentLoop.arrival_at_pickup_location_time != null && currentLoop.arrival_at_delivery_location_time != null) {
            //time = this.timetoArray(currentLoop.arrival_at_pickup_location_time);  
            time = this.tConvert(currentLoop.arrival_at_pickup_location_time);     // the object loop time From pickup
            time_interval = timeTarget;  // the time current leg time , or the estimated time
            //time_end = this.timetoArray(currentLoop.arrival_at_delivery_location_time); // the object loop time To Delivery time  
            time_end = this.tConvert(currentLoop.arrival_at_delivery_location_time);
          } else {
            return '';
          }
        } else {
          // based on the Schedule
          if (currentLoop.to_time != null && currentLoop.time != null) {
            //legFirst_CurrentLegScheduled.to_time

            let startingTime = ''; // to time check if valid time 
            let endingTime = ''; // to time check if valid time 

            //let momentOb = moment(value, "hh:mm A");
            //if (momentOb.isValid()) {

            // var s = this.formatTime(currentLoop.to_time, "12");

            //if(moment(currentLoop.to_time).format('hh:mm A').isValid()){
            // 00:59
            //console.log()
            if (currentLoop.to_time != '') {
              //(moment(currentLoop.to_time).format('hh:mm A'))
              startingTime = this.tConvert(currentLoop.to_time);//moment(currentLoop.to_time).format('hh:mm A');  
            } else {
              startingTime = moment(currentLoop.from_date).format('hh:mm A');
            }

            //moment(currentLoop.time).format('hh:mm A');
            //if(moment(currentLoop.time).format('hh:mm A').isValid()){


            if (moment(currentLoop.time, "hh:mm A").isValid()) {
              //endingTime = moment(currentLoop.time).format('hh:mm A');
              endingTime = this.tConvert(currentLoop.time);
            } else {
              endingTime = moment(currentLoop.date).format('hh:mm A');
            }

            let currentLoopFromTime = startingTime
            //console.log(currentLoopFromTime)
            let currentLoopToTime = endingTime
            time = this.timetoArray(currentLoopFromTime);   // the object loop time From pickup
            time_interval = timeTarget;                     // the time current leg time , or the estimated time
            time_end = this.timetoArray(currentLoopToTime); // the object loop time To Delivery time 
          } else {
            return '';
          }
        }
        //console.log(time)
        if ((time[2] == 'AM' && time_interval[2] == 'AM' && time_end[2] == 'AM') ||
          (time[2] == 'PM' && time_interval[2] == 'PM' && time_end[2] == 'PM')) {

          let timeStart = parseInt(this.extractTime(time[0]));     // element 0 -> example its time format is 04:12
          let timeEnd = parseInt(this.extractTime(time_end[0])); // element 0 -> example its time format is 04:12
          let timeIntervalBetween = parseInt(this.extractTime(time_interval[0]));

          // logic 
          /**
             *  date = delivery date
             *  time = delivery time
             * 
             *  from_date  = pickup date
             *  to_time = pickup time
             * 
             **/
          if (timeIntervalBetween >= timeStart || timeIntervalBetween <= timeEnd) {
            return 'Busy'
          }
          return '';
        }
        return ''; // wala ni agi
      }
      catch (err) {
        console.log(err)
      }
    },
    formatTime(time, to) {
      return time !== ""
        ? to === "12"
          ? moment(time, ["HH:mm"]).format("hh:mm A")
          : moment(time, ["hh:mm A"]).format("HH:mm")
        : "";
    },
    tConvert(time) {
      // Check correct time format and split into components
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(''); // return adjusted time or original string
    }
    ,
    // MEANS SPLIT THE TIME EX: 04:12 AM
    timetoArray(time) {
      /* return value  
          0:"01:00"
          1:" "
          2:"AM"
      */
      return time.split(/(\s+)/);
    },
    extractTime(time) {
      var stringArray = time.split(':');
      return stringArray[0];
    },
    // NO PAGINATON
    async getallDataschedule() {
      await axios
        .get(`${poBaseUrl}/getallschedule`)
        .then((response) => {
          if (response) {
            let responData = response.data.results
            this.allschedule = (responData)
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        })
    },
    async displayFilters() {
      let that = this;
      let all_fetch_driver = []
      let all_location_driver = []
      //shipment-tabs/all-scheduled
      await axios
        .get(`${poBaseUrl}/shipment-tabs/getfiltersdata`)
        .then((response) => {
          if (response) {
            let responData = response.data.results
            Object.values(responData).forEach((e) => {
              let status = _.first(e.dispatch.legs)
              var r = that.getDrivers.filter((find) => find.id == status.driver_id);
              all_fetch_driver.push({ id: r[0].id, name: r[0].name })
              //all_location_driver.push({id:status.id,name:status.address}) 
              if (status.pickup_location != null && status.pickup_location != '') {
                all_location_driver.push({ id: status.id, name: status.pickup_location })
              }
            })
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        })
      this.allnewDriver = _.uniqBy(all_fetch_driver, 'name'); //removed if had duplicate id
      this.magdropterminal = _.uniqBy(all_location_driver, 'name');
      this.isloading = false;
      //this.oldterminaldropdown = _.uniqBy(all_location_driver,'name'); 
      //console.log() 
    },
    assignValue(i) {
      return i;
    },
    confirmDatePicker(date) {
      this.isSelectedETARange = true
      this.selectedEtaRange = date || []
      if (this.currentSelectTab == "All") this.selectedTabData("All", this.selected_sub_tab, 1);
      this.isDatePickerOpen = false
    },
    closeDatePicker() {
      this.isSelectedETARange = false
      this.isDatePickerOpen = false
    },
    getTerminalData(id, type) {
      if (id) {
        let terminal = this.getTerminals.find(t => t.id == id)
        if (terminal) {
          if (type === 'name') {
            return terminal.name
          } else if (type === 'nickname') {
            return terminal.nickname !== null ? terminal.nickname : ''
          } else if (type === 'address') {
            return terminal.address
          } else {
            return terminal.firms_code
          }
        }
      }
      return ""
    },
    async filtertabs(subType) {
      this.newContainersData = [];
      this.filterstarted = 1;
      this.shouldLoad = true;
      this.currentSelectTab = "Scheduled";
      this.selectedTabData(this.currentSelectTab, subType, 1);
      this.filterstarted = 0;
    },
    ...mapActions({
      fetchavailable: "containers/fetchAvailable",
      fetchyardfull: "containers/yardfull",
      fetchassigned: "containers/assigned",
      fetchdroppedfull: "containers/droppedfull",
      fetchdroppedempty: "containers/droppedEmpty",
      fetchpendingbilling: "containers/pendingBilling",
      fetchcompleted: "containers/completed",
      storeEditData: "containers/passData",
      storeCurrentTabSelected: "containers/currentTabContainer",
      setFilter: "containers/setScheduleFilterState",
      setContainerEmptyStatus: "containers/setContainerEmptyStatus",
      fetchDrivers: "common/fetchDrivers",
      fetchChassis: "common/fetchChassis",
      fetchTerminals: "common/fetchTerminals",
      fetchYards: "common/fetchYards",
    }),
    setSubTab(tab, i) {
      // this.selected_sub_tab = tab;
      // let current_tab_item = this.getCurrentSelectTab

      // this.storeCurrentTabSelected({
      //   id: current_tab_item.id,
      //   activeTab: current_tab_item.activeTab,
      //   sub_tab: tab,
      //   subTabIndex: i
      // });

      // this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1);

      this.search = ''
      this.isUpdatingCells = false

      if (this.selected_sub_tab !== tab) {
        let current_tab_item = this.getCurrentSelectTab
        this.subTabIndex = i
        this.selected_sub_tab = tab;

        this.storeCurrentTabSelected({
          id: current_tab_item.id,
          activeTab: current_tab_item.activeTab,
          sub_tab: tab,
          subTabIndex: i
        });

        this.newContainersData = []
        this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1);
      }
    },
    getDriverstatus(item) {

      let status = _.first(item.dispatch.legs);

      if (item.dispatch.legs.length > 0) {
        return status.driver_status;
      }
      return "Pending"

      // if (typeof items != "undefined") {
      //   let ds = JSON.parse(items);
      //   if (typeof ds.legs[0].driver_status != "undefined")
      //     return ds.legs[0].driver_status;
      // }
      // return "Pending";

    },
    addFirstLeg(item) {
      this.editingShipment = item;
      this.showAddLegModal = true;
      try {
        let ds = JSON.parse(this.editingShipment.dispatch_schedule);
        if (typeof ds.legs != "undefined" && ds.legs && ds.legs.length > 0)
          this.lastLeg = ds.legs[ds.legs.length - 1];
        else this.lastLeg = null;
      } catch (err) {
        console.log(err);
        this.lastLeg = null;
      }
      this.$emit("retrieveContainerDetails", item);
    },
    makegenerate() {
      return new Date().valueOf();
    },
    addNewLeg(leg) {
      let dispatch_schedule = null,
        id = null,
        legs = [],
        ds = null;
      if (this.editingShipment != null) {
        try {
          ds = JSON.parse(this.editingShipment.dispatch_schedule);
          if (typeof ds.id != "undefined" && ds.id) id = ds.id;
          if (typeof ds.legs != "undefined" && ds.legs && ds.legs.length > 0)
            legs = ds.legs;
        } catch (err) {
          console.log(err);
          id = this.makegenerate();
        }
        dispatch_schedule = {
          id: id,
          location_from: this.editingShipment.terminal_id,
          last_free_date: this.editingShipment.last_free_date,
          per_diem_date: this.editingShipment.per_diem_date,
          legs: legs,
        };
      }

      // Alex fix mismatch date
      let dateFromISOString = null;
      let dateToISOString = null;
      let arrivalDateTime_dateToISOString = null;
      let departureDateTime_dateToISOString = null;
      let arrivalDLD_dateToISOString = null;
      let departure_ffrom_d_l_d_ToISOString = null;
      let dateFromField = null;

      // schedule pickup
      if (leg.pickup_scheduled_date != null) {
        var dateFrom = moment.utc(leg.pickup_scheduled_date).format('YYYY-MM-DD')
        if (dateFrom != null && dateFrom != 'Invalid date') {
          dateFromISOString = dateFrom + ' ' + this.checkifValid_then_return(leg.pickup_scheduled_time);
        }
      }

      // scheduled delivery
      if (leg.delivery_scheduled_date != null) {
        var dateTo = moment.utc(leg.delivery_scheduled_date).format('YYYY-MM-DD')
        if (dateTo != null && dateTo != 'Invalid date') {
          dateToISOString = dateTo + ' ' + this.checkifValid_then_return(leg.delivery_scheduled_time);
        }
      }

      // schedule arrival_at_pickup_location_date
      if (leg.arrival_at_pickup_location_date != null && leg.arrival_at_pickup_location_date != '') {
        var arrivalDatePickup = moment.utc(leg.arrival_at_pickup_location_date).format('YYYY-MM-DD')
        if (arrivalDatePickup != null && arrivalDatePickup != 'Invalid date') {
          arrivalDateTime_dateToISOString = (arrivalDatePickup + ' ' + this.checkifValid_then_return(leg.arrival_at_pickup_location_time));

        }
      }

      // departure_from_pickup_location_date
      if (leg.departure_from_pickup_location_date != null) {
        dateFromField = moment.utc(leg.departure_from_pickup_location_date).format('YYYY-MM-DD')
        if (dateFromField != null && dateFromField != 'Invalid date') {
          departureDateTime_dateToISOString = (dateFromField + ' ' + this.checkifValid_then_return(leg.departure_from_pickup_location_time));
        }
      }

      // arrival_at_delivery_location_date 
      if (leg.arrival_at_delivery_location_date != null) {
        dateFromField = moment.utc(leg.arrival_at_delivery_location_date).format('YYYY-MM-DD')
        if (dateFromField != null && dateFromField != 'Invalid date') {
          arrivalDLD_dateToISOString = (dateFromField + ' ' + this.checkifValid_then_return(leg.arrival_at_delivery_location_time));
        }
      }

      // departure_from_delivery_location_date 
      if (leg.departure_from_delivery_location_date != null) {
        dateFromField = moment.utc(leg.departure_from_delivery_location_date).format('YYYY-MM-DD')
        if (dateFromField != null && dateFromField != 'Invalid date') {
          departure_ffrom_d_l_d_ToISOString = dateFromField + ' ' + this.checkifValid_then_return(leg.departure_from_delivery_location_time);
        }
      }

      // Comment by alex dont delete
      // let ob = {
      //   id: this.makegenerate(),
      //   schedule_id: dispatch_schedule.id,
      //   type: leg.selectedType,
      //   address: leg.address,
      //   date: leg.delivery_scheduled_date,
      //   time: leg.delivery_scheduled_time,
      //   driver: leg.selectedDriver ? leg.selectedDriver : "",
      //   chassis: leg.chassis,
      //   mode: leg.selectedMode == 1 ? "Dropped" : "Live",
      //   completed: false,
      //   from_date: leg.pickup_scheduled_date,
      //   to_time: leg.pickup_scheduled_time,
      // 	arrival_at_pickup_location_date: leg.arrival_at_pickup_location_date,
      // 	arrival_at_pickup_location_time: leg.arrival_at_pickup_location_time,
      // 	departure_from_pickup_location_date: leg.departure_from_pickup_location_date,
      // 	departure_from_pickup_location_time: leg.departure_from_pickup_location_time,
      // 	arrival_at_delivery_location_date: leg.arrival_at_delivery_location_date,
      // 	arrival_at_delivery_location_time: leg.arrival_at_delivery_location_time,
      // 	departure_from_delivery_location_date: leg.departure_from_delivery_location_date,
      // 	departure_from_delivery_location_time: leg.departure_from_delivery_location_time,
      // 	address_model_type: leg.address_model_type || null,
      // 	address_model_id: leg.address_model_id || null,
      // };

      let ob = {
        id: this.makegenerate(),
        schedule_id: dispatch_schedule.id,
        type: leg.selectedType,
        address: leg.address,
        date: dateToISOString,
        time: leg.delivery_scheduled_time,
        driver: leg.selectedDriver ? leg.selectedDriver : "",
        chassis: leg.chassis,
        mode: leg.selectedMode == 1 ? "Dropped" : "Live",
        completed: false,
        from_date: dateFromISOString,
        to_time: leg.pickup_scheduled_time,
        arrival_at_pickup_location_date: arrivalDateTime_dateToISOString,
        arrival_at_pickup_location_time: leg.arrival_at_pickup_location_time,
        departure_from_pickup_location_date: departureDateTime_dateToISOString,
        departure_from_pickup_location_time: leg.departure_from_pickup_location_time,
        arrival_at_delivery_location_date: arrivalDLD_dateToISOString,
        arrival_at_delivery_location_time: leg.arrival_at_delivery_location_time,
        departure_from_delivery_location_date: departure_ffrom_d_l_d_ToISOString,
        departure_from_delivery_location_time: leg.departure_from_delivery_location_time,
        address_model_type: leg.address_model_type || null,
        address_model_id: leg.address_model_id || null,
      };
      dispatch_schedule.legs.push(ob);
      axios
        .post(`${poBaseUrl}/dispatch-save/${this.editingShipment.id}`, {
          data: JSON.stringify(dispatch_schedule),
        })
        .then((res) => {
          if (res.status == 200) {
            this.isLegUpdated = 1
            this.dispatchNotifcation(
              "Container has been successfully updated!"
            );
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .then(async () => {
          this.editingLeg = null;
          this.editingShipment = null;
          this.showAddLegModal = false;

          if (this.currentSelectTab == 'Scheduled') {
            this.setSetFilterActive(this.currentStateClick)
          } else {
            await this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, this.page)
            // this.$emit("reinitializeTable");
          }
        });
    },
    isAnyLegAvailable(item) {
      if (item.dispatch_schedule != "" && item.dispatch_schedule != null) {
        let dispatch_schedule = null;
        try {
          dispatch_schedule = JSON.parse(item.dispatch_schedule);
        }
        catch (err) {
          console.log(err);
        }
        if (dispatch_schedule) {
          let legs = dispatch_schedule.legs || [];
          let len = 0;
          try {
            len = legs.length;
          }
          catch (err) {
            console.log(err);
          }
          if (len > 0) {
            return legs.find(leg => !leg.completed)
          }
        }
      }
      return false;
    },
    isAnyUnarrivedLegAvailable(item) {
      if (item.dispatch_schedule != "" && item.dispatch_schedule != null) {
        let dispatch_schedule = null;
        try {
          dispatch_schedule = JSON.parse(item.dispatch_schedule);
        } catch (err) {
          console.log(err);
        }
        if (dispatch_schedule) {
          let legs = dispatch_schedule.legs || [];
          let len = 0;
          try {
            len = legs.length;
          } catch (err) {
            console.log(err);
          }
          if (len > 0) {
            return legs.find((leg) => !leg.completed);
          }
        }
      }
      return false;
    },
    editFirstLeg(item) {
      if (item.dispatch_schedule != "" && item.dispatch_schedule != null) {
        let dispatch_schedule = null;
        try {
          dispatch_schedule = JSON.parse(item.dispatch_schedule);
        }
        catch (err) {
          console.log(err);
        }
        if (dispatch_schedule) {
          let legs = dispatch_schedule.legs || [];
          let len = 0;
          let leg = null;
          item['terminal'] = this.terminals.find(terminal => terminal.id === item.terminal_id)

          try {
            len = legs.length;
            if (len > 0) {
              leg = legs.find(leg => !leg.completed)
            }
          }
          catch (err) {
            console.log(err);
          }
          if (len > 0 && leg) {
            this.editLeg(item, leg);
          }
        }
      }
    },
    editFirstUnarrivedLeg(item) {
      if (item.dispatch_schedule != "" && item.dispatch_schedule != null) {
        let dispatch_schedule = null;
        try {
          dispatch_schedule = JSON.parse(item.dispatch_schedule);
        } catch (err) {
          console.log(err);
        }
        if (dispatch_schedule) {
          let legs = dispatch_schedule.legs || [];
          let len = 0;
          let leg = null;
          try {
            len = legs.length;
            if (len > 0) {
              leg = legs.find((leg) => !leg.completed);
            }
          } catch (err) {
            console.log(err);
          }
          if (len > 0 && leg) {
            this.editLeg(item, leg);
          }
        }
      }
    },
    async saveEditedLeg(leg) {

      // Alex fix mismatch date
      let dateFromISOString = null;
      let dateToISOString = null;
      let arrivalDateTime_dateToISOString = null;
      let departureDateTime_dateToISOString = null;
      let arrivalDLD_dateToISOString = null;
      let departure_ffrom_d_l_d_ToISOString = null;
      let dateFromField = null;

      // schedule pickup
      if (leg.pickup_scheduled_date != null) {
        var dateFrom = moment(leg.pickup_scheduled_date).format('YYYY-MM-DD')
        if (dateFrom != null && dateFrom != 'Invalid date') {
          dateFromISOString = dateFrom + ' ' + this.checkifValid_then_return(leg.pickup_scheduled_time);
        }
      }

      // scheduled delivery
      if (leg.delivery_scheduled_date != null) {
        var dateTo = moment(leg.delivery_scheduled_date).format('YYYY-MM-DD')
        if (dateTo != null && dateTo != 'Invalid date') {
          dateToISOString = dateTo + ' ' + this.checkifValid_then_return(leg.delivery_scheduled_time);
        }
      }

      // schedule arrival_at_pickup_location_date
      if (leg.arrival_at_pickup_location_date != null && leg.arrival_at_pickup_location_date != '') {
        var arrivalDatePickup = moment(leg.arrival_at_pickup_location_date).format('YYYY-MM-DD')
        if (arrivalDatePickup != null && arrivalDatePickup != 'Invalid date') {
          arrivalDateTime_dateToISOString = (arrivalDatePickup + ' ' + this.checkifValid_then_return(leg.arrival_at_pickup_location_time));
          // alert(arrivalDatePickup + 'naaa')
        }
      }

      // departure_from_pickup_location_date
      if (leg.departure_from_pickup_location_date != null) {
        dateFromField = moment(leg.departure_from_pickup_location_date).format('YYYY-MM-DD')
        if (dateFromField != null && dateFromField != 'Invalid date') {
          departureDateTime_dateToISOString = (dateFromField + ' ' + this.checkifValid_then_return(leg.departure_from_pickup_location_time));
        }
      }

      // arrival_at_delivery_location_date 
      if (leg.arrival_at_delivery_location_date != null) {
        dateFromField = moment(leg.arrival_at_delivery_location_date).format('YYYY-MM-DD')
        if (dateFromField != null && dateFromField != 'Invalid date') {
          arrivalDLD_dateToISOString = (dateFromField + ' ' + this.checkifValid_then_return(leg.arrival_at_delivery_location_time));
        }
      }

      // departure_from_delivery_location_date 
      if (leg.departure_from_delivery_location_date != null) {
        dateFromField = moment(leg.departure_from_delivery_location_date).format('YYYY-MM-DD')
        if (dateFromField != null && dateFromField != 'Invalid date') {
          departure_ffrom_d_l_d_ToISOString = dateFromField + ' ' + this.checkifValid_then_return(leg.departure_from_delivery_location_time);
        }
      }

      // comment by alex dont delete
      // let ob = {
      //   id: leg.id,
      //   schedule_id: this.editingLeg.schedule_id,
      //   driver_status: leg.driverstatus,
      //   type: leg.selectedType,
      //   address: leg.address,
      //   date: leg.delivery_scheduled_date,
      //   time: leg.delivery_scheduled_time,
      //   driver: leg.selectedDriver ? leg.selectedDriver : "",
      //   chassis: leg.chassis ? leg.chassis : "",
      //   mode: leg.selectedMode == 1 ? "Dropped" : "Live",
      //   completed: leg.arrival_at_delivery_location_date ? true : false,
      //   from_date: leg.pickup_scheduled_date,
      //   to_time: leg.pickup_scheduled_time,
      //   arrival_at_pickup_location_date:
      //     leg.arrival_at_pickup_location_date || "",
      //   arrival_at_pickup_location_time:
      //     leg.arrival_at_pickup_location_time || "",
      //   departure_from_pickup_location_date:
      //     leg.departure_from_pickup_location_date || "",
      //   departure_from_pickup_location_time:
      //     leg.departure_from_pickup_location_time || "",
      //   arrival_at_delivery_location_date:
      //     leg.arrival_at_delivery_location_date || "",
      //   arrival_at_delivery_location_time:
      //     leg.arrival_at_delivery_location_time || "",
      //   departure_from_delivery_location_date:
      //     leg.departure_from_delivery_location_date || "",
      //   departure_from_delivery_location_time:
      //     leg.departure_from_delivery_location_time || "",
      // 	address_model_type: leg.address_model_type || null,
      // 	address_model_id: leg.address_model_id || null,
      // };

      let ob = {
        id: leg.id,
        schedule_id: this.editingLeg.schedule_id,
        driver_status: leg.driverstatus,
        type: leg.selectedType,
        address: leg.address,
        // date: leg.delivery_scheduled_date,
        date: dateToISOString,
        time: leg.delivery_scheduled_time,
        driver: leg.selectedDriver ? leg.selectedDriver : "",
        chassis: leg.chassis ? leg.chassis : "",
        mode: leg.selectedMode == 1 ? "Dropped" : "Live",
        completed: leg.arrival_at_delivery_location_date !== "Invalid date"
          && leg.arrival_at_delivery_location_date !== null
          && leg.arrival_at_delivery_location_date !== "" ? true : false,
        // comment by alex wrong date from_date: leg.pickup_scheduled_date,
        from_date: dateFromISOString,
        to_time: leg.pickup_scheduled_time,
        driver_instructions: leg.driver_instructions,
        //arrival_at_pickup_location_date:leg.arrival_at_pickup_location_date || "",
        arrival_at_pickup_location_date: arrivalDateTime_dateToISOString || "",
        arrival_at_pickup_location_time: (leg.arrival_at_pickup_location_time != 'Invalid date') ? leg.arrival_at_pickup_location_time : "",

        // departure_from_pickup_location_date: departure_from_pickup_location_date || "",
        departure_from_pickup_location_date: departureDateTime_dateToISOString || "",
        departure_from_pickup_location_time: (leg.departure_from_pickup_location_time != 'Invalid date') ? leg.departure_from_pickup_location_time : "",

        //arrival_at_delivery_location_date:leg.arrival_at_delivery_location_date || "",
        arrival_at_delivery_location_date: arrivalDLD_dateToISOString || "",
        arrival_at_delivery_location_time: (leg.arrival_at_delivery_location_time != 'Invalid date') ? leg.arrival_at_delivery_location_time : "",

        //departure_from_delivery_location_date:leg.departure_from_delivery_location_date || "",
        departure_from_delivery_location_date: departure_ffrom_d_l_d_ToISOString || "",
        departure_from_delivery_location_time: (leg.departure_from_delivery_location_time != 'Invalid date') ? leg.departure_from_delivery_location_time : "",

        address_model_type: leg.address_model_type || null,
        address_model_id: leg.address_model_id || null,
        addressLongitude: leg.addressLongitude || null,
        addressLatitude: leg.addressLatitude || null
      };

      console.log('obj >>>', ob)

      let temp = this.newContainersData
      //let currentEdit_ID = 0;
      let isLegnotEmpty = 0;
      let result = -1;
      let ds;

      if (this.currentDataleg.legs != '') {
        isLegnotEmpty = 1;
        ds = JSON.parse(this.currentDataleg.legs)
        result = _.findIndex(ds.legs, (c) => c.id === this.currentLegID);
      }
      await axios
        .put(`${poBaseUrl}/legs/${leg.id}`, {
          leg_data: ob,
          shipment_id: this.editingShipment.id,
        })
        .then(async (res) => {
          if (res.status == 200) {

            if (isLegnotEmpty) {
              if (result != -1) {
                ds.legs.splice(result, 1, ob)
                temp[this.currentIndex]['dispatch_schedule'] = JSON.stringify(ds)
                this.newContainersData.splice(this.currentIndex, 1, temp[this.currentIndex])
                this.loadedContainersData[this.currentTab].data[this.getLoadedDataPage()] = temp
              }
            }

            this.isLegUpdated = 1
            await this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, this.page)
            this.dispatchNotifcation(
              "Container has been successfully updated!"
            );
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .then(async () => {
          this.editingLeg = null;
          this.editingShipment = null;
          this.showEditLegModal = false;
          if (this.currentSelectTab == 'Scheduled') {
            this.setSetFilterActive(this.currentStateClick)
          } else {
            await this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1)
            this.$emit("reinitializeTable");
          }
        });
    },
    /**
     *  Get completed and return first element 
     */
    getClearnDispatch(item) {

      var decodeleg = JSON.parse(item);

      let filtered_array = _.filter(
        decodeleg.legs, function (o) {
          return !o.completed;
        }
      );

      let c = _.first(filtered_array)
      return c;
    },
    saveNewChassis(data) {
      axios
        .post(`${poBaseUrl}/chassis-save`, data)
        .then((response) => {
          this.dispatchNotifcation(
            '<i class="ic-check-mark"></i>&nbsp;<span class="text-message">' +
            response.data.message +
            "</span>"
          );
          this.responseChassis = response.data.chassis;
          this.fetchChassis();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    markAsArrived(item, leg, index) {
      this.markasrrived = 1
      this.editLeg(item, leg, index);
    },
    editLeg(item, leg, index = -1) {
      if (index != -1) {
        this.currentIndex = index;
        this.currentLegID = leg.id;
        this.currentDataleg.legs = item.dispatch_schedule;
      }
      //console.log(leg)
      // this.$emit("retrieveContainerDetails", item);
      // this.editingLeg = leg;
      // this.editingShipment = item;

      // To get the updated container's data
      let shipment = (index == -1) ? item : this.newContainersData[index]
      if (!shipment) shipment = item

      this.$emit("retrieveContainerDetails", shipment);

      let ds = JSON.parse(shipment.dispatch_schedule)

      if (ds.legs.length > 0) {
        ds.legs.map((dsLeg) => {
          if (dsLeg.id === leg.id) {
            this.editingLeg = dsLeg;
          }
        })
      }
      this.editingShipment = shipment;
      this.refNumber = shipment.shifl_ref
      this.showEditLegModal = true;
    },
    isEmpty(val) {
      return val == null || val == 0 || val == "undefined";
    },
    getNewDispatchScheduleLegs(item) {
      if (item != null) {
        item = this.getparse(item);

        let filtered_array = _.filter(
          item.legs, function (o) {
            return !o.completed;
          }
        );

        let c = _.first(filtered_array)

        let m = [];
        m.push(c);

        if (item) {
          if (item.legs != "undefined") {
            return m;
          }
        }
      }
      return [];
      // if (item != null) {
      //   item = this.getparse(item);
      //   if (item) {
      //     if (item.legs != "undefined") {
      //       return item.legs;
      //     }
      //   }
      // }
      // return [];
    },
    closeMilestoneDialog(item) {
      jQuery("#" + item.id + "_" + this.selectedMilestoneField).prop(
        "checked",
        !jQuery("#" + item.id + "_" + this.selectedMilestoneField).is(
          ":checked"
        )
      );
      this.milestoneDialogData = null;
      this.selectedMilestoneField = null;
      this.milestoneDialog = false;
    },
    updateMilestone(item) {
      axios
        .post(`${poBaseUrl}/milestones-update/${item.id}`, {
          id: item.id,
          value: this.isSelectedMilestoneFieldChecked,
          field: this.selectedMilestoneField,
        })
        .then(async (res) => {
          if (res.status == 200) {
            this.milestoneDialogData = null;
            this.selectedMilestoneField = null;
            this.milestoneDialog = false;

            let data = res.data.data
            if ((data.discharge || data.discharge === 1) &&
              (data.fully_released || data.fully_released === 1) &&
              (data.peir_pass_paid || data.peir_pass_paid === 1)) {
              await this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, this.page)
              this.$emit("reinitializeTable");
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openMilestoneDialog(e, item, field) {
      let isChecked = e.target.checked;
      this.milestoneDialogData = item;
      this.selectedMilestoneField = field;
      this.isSelectedMilestoneFieldChecked = isChecked;
      // this.milestoneDialog = true;
      this.updateMilestone(item)
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
    async methodtest(driver, location, page) {
      var dis = this;
      var formData = new FormData();

      //formData.append("xid",this.allDriverList);
      //formData.append("xname",this.allLocationList);
      var newstring = [];
      location.forEach((v) => {
        newstring.push({ name: this.magdropterminal.find((e) => e.id == v).name });
      })

      var driverID = [];
      driver.forEach((v) => {
        driverID.push({ id: v });
      })

      let the_location = JSON.stringify(newstring);
      let the_driver = JSON.stringify(driverID);

      formData.append("xid", the_driver);
      formData.append("xname", the_location);

      await axios
        .post(`${poBaseUrl}/legsfilter`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            page: page
          }
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          dis.errorMessage = error.message;
          //console.error("There was an error!", error);
          alert("Server Error" + error);
        })
        .then((response) => {
          dis.startloading = 0;
          //dis.filterdataRes = response;

          //dis.newContainersData = response.data;
          dis.page = page;
          dis.pageCount = 0;
          dis.lastPage = response.results.last_page;
          if (response.results.last_page > 1) {
            dis.pageCount = response.results.last_page;
          }
          //console.log(response.data.results.data);
          dis.newContainersData = response.results.data;
        });
    },
    triggersearch() {
      this.$refs.fi.$el.click();
    },
    disabledni() {
      this.isDisabledfilter = false;
    },
    changenowDropdown(ee) {
      this.triggerapply = 1;
      this.driverSelectFilter = ee;
      this.currentDriverFilterID = ee;
      this.typeFilter = 3;
      this.cardDropdownFilter = true;
      var drivername = this.magdriver.find((e) => {
        return e.id == ee;
      });
      var Checkdrivername = this.drivernameLabel.find((e) => {
        return e.id == drivername.id;
      });
      if (typeof Checkdrivername != "undefined") {
        if (Checkdrivername == "") {
          this.drivernameLabel.push(drivername);
        }
      } else {
        this.drivernameLabel.push(drivername);
      }
      this.driverfinalPush = this.drivernameLabel;
      this.typeFilter = 3;
    },
    async applyFilter() {
      console.log(this.allcheckthebox);
      this.startloading = 1;
      this.startFilter();
      // location alone
      //if(this.locationDropdowndetected == 1 && this.drivernameLabel.length == 0){

      /**
       *   To be removed by Alex
       */

      // if (this.detectchange == 1 && this.drivernameLabel.length == 0) {
      //   this.typeFilter = 4;
      //   this.$nextTick(() => {
      //     this.locationDropdowndetected = 4;
      //   });
      //   await this.methodtest(allid);
      //   this.isDisabledfilter = true;
      //   this.triggerapply = 1;
      //   this.triggerDisselected = 1;
      //   this.detectchange = 0;
      // } else {
      //   // locaton and driver if
      //   var drivername = this.magdriver.find((e) => {
      //     return e.id == this.driverSelectFilter;
      //   });
      //   if (typeof drivername != "undefined") {
      //     var Checkdrivername = this.drivernameLabel.find((e) => {
      //       return e.id == drivername.id;
      //     });
      //     if (typeof Checkdrivername != "undefined") {
      //       if (Checkdrivername == "") {
      //         this.drivernameLabel.push(drivername);
      //       }
      //     } else {
      //       this.drivernameLabel.push(drivername);
      //     }
      //     this.typeFilter++;
      //     if (this.typeFilter < 3) {
      //       this.typeFilter = 3;
      //     }
      //     if (this.typeFilter == 2) {
      //       this.typeFilter++;
      //     }
      //     this.driverfinalPush = this.drivernameLabel;
      //     this.menu = true;
      //     this.driverSelectFilter = [];
      //   }
      //   var allid = [];
      //   Object.values(this.driverfinalPush).forEach(function (ff) {
      //     allid.push(ff.id);
      //   });
      //   await this.methodtest(allid);
      //   this.isDisabledfilter = true;
      //   this.triggerapply = 1;
      //   this.triggerDisselected = 1;
      // }

      // let driver   = this.driverSelectFilter; // use exact match
      // let location = this.locationfromfilter; // use like for search name        
      await this.methodtest(this.driverSelectFilter,
        this.locationfromfilter, 1);
      this.startFilter();
      //this.startloading = 0;
    },
    ClearFilter() {
      this.typeFilter = 0; // changed from 1
      this.menu = true;
      this.drivernameLabel = [];
      this.newByDriverFilterAssginged = [];
      //this.driverSelectFilter = [];
      this.locationfromfilter = [];
      // added clear data
      this.locationDropdowndetected = 0;
      this.detectchange = 0;
      //this.foundsearch = false;
      this.isDisabledfilter = true;

      // new clear
      this.checkallLocation = []; // checkboxes of the location
      this.checkall = []; // checkboxes of the drivers

      //this.magdropterminal = this.oldterminaldropdown
      this.driverSelectFilter = [];

      this.magdropterminal.map(e => {
        e.selected = false;
      })

      this.allnewDriver.map(e => {
        e.selected = false
      })

      this.displayFilters();
      //http://reschedule2.trucking/api/shipment-tabs/all-scheduled?page=1&customer_id=0&status=all&etaStart=&etaEnd=

      this.getSelectedTabData("all-scheduled", 1);
    },
    //button filter
    changemenow() {
      this.clearDriverSelected();
      //this.driverSelectFilter = this.checkall;
      this.isDisabledfilter = false;
      //this.triggerapply = 0;
      //this.triggerDisselected = 0;
    },
    inputChanged() {
      this.checkall = [];
    },
    checkthisbox(item, queryText) {
      this.checkall = [];
      return item.name.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
    },
    driverChange(item, event) {
      // let array = this.checkall;   
      // if(event == false){
      //   var index = array.indexOf(item.id);
      //   if (index !== -1) {
      //     array.splice(index, 1);
      //     this.checkall = array;
      //   }
      // }else{
      //   this.checkall.push(item.id);
      // }
      this.allnewDriver.map(function (num) {
        if (num.id === item.id) {
          if (event) {
            if (typeof num.selected != 'undefined') {
              num.selected = event
            } else {
              num.selected = event
            }
          } else {
            num.selected = event
          }
        }
        return num;
      })
    },
    clearSelection() {
      this.allnewDriver.map(function (num) {
        num.selected = false
      })

      this.magdropterminal.map(function (num) {
        num.selected = false
      })
    },
    clearDriverSelected() {
      let dat = this;
      let array = dat.driverSelectFilter;
      this.allnewDriver.map(function (num) {
        let found = array.find(function (element) {
          return element == num.id;
        });

        if (typeof found !== 'undefined') {
          if (typeof num.selected != 'undefined') {
            num.selected = true
          }
        } else {
          num.selected = false
        }
        return num;
      })

      console.log(this.driverSelectFilter)
    },
    changeinputme(item, event) {

      //var returnvalue = event.target.checked;       
      this.magdropterminal.map(function (num) {
        if (num.id === item.id) {
          if (event) {
            if (typeof num.selected != 'undefined') {
              num.selected = event
            } else {
              num.selected = event
            }
          } else {
            num.selected = event
          }
        }
        return num;
      })
    },
    inputChangedLocation() {
      this.checkallLocation = [];
    },
    changelocation() {
      this.callselection(this.locationfromfilter)
      //this.locationfromfilter = this.allcheckthebox;
      this.isDisabledfilter = false;
      //this.triggerapply = 0;
      //this.locationDropdowndetected = 0;
      this.detectchange = 1;
    },
    showhideClick() {
      this.showhideDropdown = this.showhideDropdown == true ? false : true;
    },
    getType(item) {
      let last_arrived_leg = this.getLastArrivedLeg(item);
      return last_arrived_leg.type ? last_arrived_leg.type : "__";
    },
    startFilter() {
      this.filterstarted = this.filterstarted ? false : true;
    },
    getLastArrivedLeg(item) {
      let dispatch_schedule = null;
      try {
        dispatch_schedule = JSON.parse(item.dispatch_schedule);
      } catch (err) {
        dispatch_schedule = null;
      }
      let legs = dispatch_schedule ? dispatch_schedule.legs || [] : [];
      if (legs.length > 0) {
        for (let i = legs.length - 1; i >= 0; i--) {
          if (legs[i].completed) {
            return legs[i];
          }
        }
      }
      return null;
    },
    getFirstUnArrivedLeg(item) {
      let dispatch_schedule = null;
      try {
        dispatch_schedule = JSON.parse(item.dispatch_schedule);
      } catch (err) {
        dispatch_schedule = null;
      }
      let legs = dispatch_schedule ? dispatch_schedule.legs || [] : [];
      if (legs.length > 0) {
        for (let i = 0; i < legs.length; i++) {
          if (!legs[i].completed) {
            return legs[i];
          }
        }
      }
      return null;
    },
    getDirectPrepulStatus(item) {
      let dispatch_schedule = null;
      try {
        dispatch_schedule = JSON.parse(item.dispatch_schedule);
      } catch (err) {
        dispatch_schedule = null;
      }
      let legs = dispatch_schedule ? dispatch_schedule.legs || [] : [];
      if (legs.length > 0) {
        if (legs[0].type) {
          if (legs[0].type == 'Customer') return 'Direct'
          else return 'Prepull'
        }
      }
      return null;
    },
    getTimeArrived(item) {
      let last_arrived_leg = this.getLastArrivedLeg(item);
      let time_html = "";
      let date_html = "";
      if (last_arrived_leg) {
        time_html =
          "<div> " +
          this.checkTotime(last_arrived_leg.arrival_at_delivery_location_time) +
          "  </div> ";
        date_html =
          "<div class='text-dark-gray'>  " +
          this.getDateFormat(
            last_arrived_leg.arrival_at_delivery_location_date
          ) +
          " </div>";
      }
      return time_html + date_html;
    },
    getScheduledDate(item) {
      let dispatch_schedule = null;
      try {
        dispatch_schedule = JSON.parse(item.dispatch_schedule);
      } catch (err) {
        dispatch_schedule = null;
      }
      let legs = dispatch_schedule ? dispatch_schedule.legs || [] : [];
      let not_scheduled = "<p class='text-dark-gray'> Not Scheduled </p>";
      if (
        ["Available"].includes(this.currentSelectTab) ||
        ["Available"].includes(this.selected_sub_tab.label)
      )
        return legs.length > 0 && legs[0].from_date
          ? this.getDateFormat(legs[0].from_date)
          : not_scheduled;
      else if (
        ["Dropped empty", "Yard full", "At Yard"].includes(
          this.currentSelectTab
        ) ||
        ["Dropped empty", "Yard full", "At Yard"].includes(
          this.selected_sub_tab.label
        )
      ) {
        let first_unarrived_leg = this.getFirstUnArrivedLeg(item);
        if (!(first_unarrived_leg && first_unarrived_leg.from_date)) {
          return not_scheduled;
        }
        if (
          ["Yard full", "At Yard"].includes(this.currentSelectTab) ||
          ["Yard full", "At Yard"].includes(this.selected_sub_tab.label)
        ) {
          let date_div =
            "<span class='text-dark-gray'>" +
            this.getDateFormat(first_unarrived_leg.from_date) +
            "</span>";
          let time_div =
            " <span>" + this.checkTotime(first_unarrived_leg.to_time) + "</span> <br>";
          return time_div + date_div;
        }
        return this.getDateFormat(first_unarrived_leg.from_date);
      }
    },
    getTextColor(item) {
      return ["Available", "Pending"].includes(this.currentSelectTab) ||
        ["Available", "Pending"].includes(this.selected_sub_tab.label)
        ? this.compareWithToday(item.last_free_date, ">")
          ? "text-red"
          : this.compareWithToday(item.last_free_date, "==")
            ? "text-yellow"
            : ""
        : "";
    },
    getBg(item) {
      return ["Available", "Pending"].includes(this.currentSelectTab) ||
        ["Available", "Pending"].includes(this.selected_sub_tab.label)
        ? this.compareWithToday(item.last_free_date, ">")
          ? "bg-redish"
          : this.compareWithToday(item.last_free_date, "==")
            ? "bg-yellowish"
            : ""
        : "";
    },
    getBgAtheCustomer(item) {
      return ["At the Customer", "Dropped empty"].includes(
        this.currentSelectTab
      ) ||
        ["At the Customer", "Dropped empty"].includes(
          this.selected_sub_tab.label
        )
        ? this.compareWithToday(item.per_diem_date, ">")
          ? "bg-redish"
          : this.compareWithToday(item.per_diem_date, "==")
            ? "bg-yellowish"
            : ""
        : "";
    },
    disSelected(s) {
      this.triggerapply = 1;
      this.triggerDisselected = 1;
      this.driverfinalPush.splice(s, 1);
      this.disabledni();
      this.applyFilter();
      this.isDisabledfilter = true;
    },
    compareWithToday(date, operator) {
      if (date) {
        date = new Date(date);
        let now = new Date();
        date.setHours(0, 0, 0, 0);
        now.setHours(0, 0, 0, 0);
        if (operator == ">") return now > date;
        else if (operator == "==") return date.getTime() == now.getTime();
      }
      return false;
    },
    callitemcustomer(name) {
      if (name.customer == null) {
        return "";
      } else {
        return name.customer.company_name;
      }
    },
    ...globalMethods,
    itemSelected() {
      //console.log(this.selectedTasks);
    },
    getDateUTCTRUE(date) {
      if (date) {
        if (this.checkifValid(date, "MMM DD, YYYY")) {
          return moment(date).utc(true).format("MMM DD, YYYY");
        } else {
          // return "--";
          return "";
        }
      }
      // return "--";
      return "";
    },
    getDateFormat(date) {
      if (date) {
        //return moment(date).utc(false).format('MMM DD, YYYY');
        if (this.checkifValid(date, "MMM DD, YYYY")) {
          return moment(date).utc(false).format("MMM DD, YYYY");
        } else {
          // return "--";
          return "";
        }
      }
      // return "--";
      return "";
    },
    getCarrier(item) {
      let carrier = item.carrier;
      return carrier !== null
        ? typeof carrier !== "undefined"
          ? carrier.name
          : ""
        : "";
    },
    getPerDiemDate(item) {
      if (item.per_diem_date !== undefined && item.per_diem_date !== "") {
        if (this.checkifValid(item.per_diem_date, "DD MMM YYYY")) {
          return moment(item.per_diem_date).utc(true).format("MMM DD, YYYY");
        } else {
          // return "--";
          return "";
        }
      }
      //return old code item.per_diem_date ? moment(item.per_diem_date).utc(false).format('MMM DD, YYYY') : '__'
    },
    getPerDiemDateClass(item) {
      if (item.per_diem_date !== undefined && item.per_diem_date !== "") {
        return this.compareWithToday(item.per_diem_date, ">")
          ? "text-red"
          : this.compareWithToday(item.per_diem_date, "==")
            ? "text-yellow"
            : "text--disabled";
      } else {
        return "text--disabled";
      }
    },
    getMode(item) {
      let scheds = null;
      let mode = "N/A";
      try {
        scheds = JSON.parse(item.dispatch_schedule);
      } catch (err) {
        scheds = null;
      }
      if (scheds !== null) {
        scheds.legs.map((value) => {
          if (value.completed) {
            mode = value.mode === "Live" ? "Live" : "Drop";
          }
        });
      }
      return mode;
    },
    getATCTimeArrived(item) {
      let scheds = null;
      try {
        scheds = JSON.parse(item.dispatch_schedule);
      } catch (err) {
        scheds = null;
      }
      var adlt =
        scheds.legs[scheds.legs.length - 1]?.arrival_at_delivery_location_time;
      if (adlt !== undefined && adlt !== "") {
        var bd = moment().format("YYYY-MM-DD");
        var checkdate = moment(bd + " " + adlt)
          .utc(true)
          .isValid();
        return checkdate ? moment(bd + " " + adlt).format("hh:mm A") : "";
      } else {
        // return "--";
        return "";
      }
    },
    getAddress(item) {
      if (item.dispatch_schedule !== undefined) {
        let ds = JSON.parse(item.dispatch_schedule);
        let legs = ds.legs;
        if (legs.length > 0) {
          if (
            legs[legs.length - 1].address !== null &&
            legs[legs.length - 1].completed
          ) {
            return legs[legs.length - 1].address ?? "";
          } else {
            // return "--";
            return "";
          }
        } else return "";
      } else return "";
    },
    getDateArrived(item) {
      let scheds = null;
      try {
        scheds = JSON.parse(item.dispatch_schedule);
      } catch (err) {
        scheds = null;
      }
      var adld =
        scheds.legs[scheds.legs.length - 1]?.arrival_at_delivery_location_date;
      //return  adld !== undefined && adld !== "" ? moment(adld).utc(false).format('DD MMM YYYY') : '__'
      if (adld !== undefined && adld !== "") {
        if (this.checkifValid(adld, "DD MMM YYYY")) {
          return moment(adld).utc(true).format("DD MMM YYYY");
        } else {
          // return "--";
          return "";
        }
      } else {
        // return "--";
        return "";
      }
    },
    getScheduleTime(item) {
      let scheds = null;
      try {
        scheds = JSON.parse(item.dispatch_schedule);
      } catch (err) {
        scheds = null;
      }
      var t = scheds.legs[scheds.legs.length - 1]?.to_time;
      var is_arrived = scheds.legs[scheds.legs.length - 1]?.completed;
      var bd = moment().format("YYYY-MM-DD");
      // Return blank if the last leg is arrived, there should be another schedule leg that has not yet arrived
      //
      if (this.checkifValid(bd + " " + t, "hh:mm A") && !is_arrived) {
        return moment(bd + " " + t).format("hh:mm A");
      } else {
        // return "--";
        return "";
      }
    },
    getScheduleDate(item) {
      let scheds = null;
      try {
        scheds = JSON.parse(item.dispatch_schedule);
      } catch (err) {
        scheds = null;
      }
      var d = scheds.legs[scheds.legs.length - 1]?.from_date;
      var is_arrived = scheds.legs[scheds.legs.length - 1]?.completed;
      // Return blank if the last leg is arrived, there should be another schedule leg that has not yet arrived
      if (this.checkifValid(d, "DD MMM YYYY") && !is_arrived) {
        return moment(d).utc(true).format("DD MMM YYYY");
      } else {
        // return "--";
        return "";
      }
    },
    getContainerSize(item) {
      if (item.container_size !== null) {
        if (item.container_size.id !== item.container_size_id) {
          let size = this.shipmentsize.find((size) => {
            if (size.id === item.container_size_id) {
              return size;
            }
          })

          return size?.name ?? "";
        } else {
          return item.container_size?.name ?? "";
        }
      } else {
        // return "--"
        return "";
      }
    },
    async updateContainer(item, dateTime) {
      let payload = {
        item: item,
        dateTime: dateTime,
      };
      this.isSubmitting = true;
      await this.setContainerEmptyStatus(payload)
        .then(async () => {
          this.dispatchNotifcation("Container has been successfully updated!");
          await this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, this.page)
          await this.$emit("reinitializeTable");
        });
      this.isSubmitting = false;
      this.dialog = false;
    },
    getpickup(date) {
      if (typeof date != "undefined") {
        //return '<span class="greyColor">, '+ moment(date).utc(false).format('MMM DD, YYYY')+'</span>';
        if (this.checkifValid(date, "MMM DD, YYYY")) {
          return moment(date).utc(true).format("MMM DD, YYYY");
        } else {
          // return "--";
          return "";
        }
      } else {
        // return "--";
        return "";
      }
    },
    Ref(item) {
      if (item.shifl_ref != null) {
        var dispatchitem = this.typeFilter
          ? item.new_dispatch_schedule
          : item.dispatch_schedule;
        var getContainer =
          item.trucker_container != null
            ? this.capitalize(item.trucker_container)
            : "";
        return (
          '<span class="referennceColumn ' +
          this.checkIFOneRowdispatch(dispatchitem) +
          '">' +
          '<span id="container">' +
          getContainer +
          '</span> <br><span style="color:#6D858F;display: inline-block;">' +
          item.shifl_ref +
          "</span></span>"
        );
      } else {
        return "";
      }
    },
    /**
     *  Trucker Container Name
     *  Mixed Container name and Customer name
     */
    // TruckerConCustomer(item) {
    //   var l;
    //   var dispatchitem = this.typeFilter
    //     ? item.new_dispatch_schedule
    //     : item.dispatch_schedule;
    //   if (dispatchitem != "") {
    //     var parsejson = this.getparse(dispatchitem);
    //     l = parsejson.legs.length;
    //   } else {
    //     l = 0;
    //   }
    //   let whichAssignTo = this.checkIFOneRowdispatch(dispatchitem);
    //   let pilaynaassign = this.HomanyTtypes(dispatchitem);
    //   const toCustomerCase = (ct) => {
    //     return ct
    //       .toLowerCase()
    //       .split(" ")
    //       .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    //       .join(" ");
    //   };
    //   //let contai 			= item.trucker_container ;
    //   //let concatContainer = contai != null ? '<span>' + contai.toUpperCase() + '</span><br>' : '';
    //   let concatCustomer =
    //     item.customer != null
    //       ? '<span style="color:#6D858F">' +
    //         toCustomerCase(item.customer.company_name) +
    //         "</span>"
    //       : "";
    //   this.refcontainercl = "";
    //   return (
    //     '<p data-numberAssignType="' +
    //     pilaynaassign +
    //     '" data-id="' +
    //     l +
    //     '" id="' +
    //     whichAssignTo +
    //     '" class="' +
    //     whichAssignTo +
    //     '">' +
    //     concatCustomer +
    //     "</p>"
    //   );
    // },
    fromdate(date) {
      if (typeof date != "undefined") {
        //return '<span class="greyColor">, '+ moment(date).utc(false).format('MMM DD, YYYY')+'</span>';
        return moment(date).utc(true).format("MMM DD, YYYY");
      } else {
        // return "--";
        return "";
      }
    },
    checkifValid(string, format) {
      return moment(moment(string).format(format), format, true).isValid();
    },
    getCurrentTab(id, i, tab) {
      localStorage.setItem('key', i);
      localStorage.setItem('currentSelectTab', id);
      // this.sub_tab = tab.sub_tab;
      // if (tab.sub_tab.length > 0) this.selected_sub_tab = tab.sub_tab[0];
      // if (!this.getTabLoading) {
      //   if (["Cancelled", "Completed"].includes(id)) {
      //     this.selected_sub_tab = {};
      //   }
      //   this.currentSelectTab = id;
      //   this.tabClicked = true;
      //   this.storeCurrentTabSelected({
      //     id: id,
      //     activeTab: i,
      //     sub_tab: this.selected_sub_tab
      //   });
      // }

      this.search = ''
      // if (cancel !== undefined) {
      //   cancel("cancel_previous_request")
      // }

      if (this.currentSelectTab !== id) {
        this.sub_tab = tab.sub_tab;
        if (tab.sub_tab.length > 0) this.selected_sub_tab = tab.sub_tab[0];
        if (!this.getTabLoading) {
          if (["Cancelled", "Completed"].includes(id)) {
            this.selected_sub_tab = {};
          }
          this.currentSelectTab = id;
          this.tabClicked = true;
          this.storeCurrentTabSelected({
            id: id,
            activeTab: i,
            sub_tab: this.selected_sub_tab
          });
        }
        this.subTabIndex = 0
        this.newContainersData = []
        this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1);
        this.isUpdatingCells = false
      }
    },
    customSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        const i = String(index[0]).toLowerCase();
        var al = "";
        var bl = "";
        if (i !== "undefined") {
          if (["Pickup_On"].includes(index[0])) {
            let ads = JSON.parse(a.dispatch_schedule);
            let bds = JSON.parse(b.dispatch_schedule);
            if (ads !== null) {
              if (ads.legs.length > 0) {
                ads.legs.map((leg, i) => {
                  if (!this.aIsfirst) {
                    if (!leg.completed) {
                      let a_aapld = leg.arrival_at_pickup_location_date;
                      // let a_aaplt = leg.arrival_at_pickup_location_time;
                      let date =
                        a_aapld !== undefined && a_aapld !== null && a_aapld !== ""
                          ? a_aapld
                          : ads.legs[i].from_date;
                      // let time =
                      //   a_aaplt !== undefined && a_aaplt !== null && a_aaplt !== "" && a_aaplt !== "Invalid date"
                      //     ? a_aaplt
                      //     : "00:00:00";
                      // al = date + " " + time;
                      al = moment(date).format("YYYY-MM-DD")
                      this.aIsfirst = 1;
                    }
                  }
                });
              }
            }
            if (bds !== null) {
              if (bds.legs.length > 0) {
                bds.legs.map((leg, i) => {
                  if (!this.bIsfirst) {
                    if (!leg.completed) {
                      let b_aapld = leg.arrival_at_pickup_location_date;
                      // let b_aaplt = leg.arrival_at_pickup_location_time;
                      let date =
                        b_aapld !== undefined && b_aapld !== null && b_aapld !== ""
                          ? b_aapld
                          : bds.legs[i].from_date;
                      // let time =
                      //   b_aaplt !== undefined && b_aaplt !== null && b_aaplt !== "" && b_aaplt !== "Invalid date"
                      //     ? b_aaplt
                      //     : "00:00:00";
                      // bl = date + " " + time;
                      bl = moment(date).format("YYYY-MM-DD")
                      this.bIsfirst = 1;
                    }
                  }
                });
              }
            }
            this.aIsfirst = 0;
            this.bIsfirst = 0;
            if (isDesc[0]) {
              return new Date(bl) - new Date(al);
            } else {
              return new Date(al) - new Date(bl);
            }
          }
          if (["Delivery_On"].includes(index[0])) {
            let ads = _.first(a.dispatch.legs);
            let bds = _.first(b.dispatch.legs);
            if (ads !== null) {
              if (!this.aIsfirst) {
                let a_aapld = ads.delivery_scheduled_date_time;
                al = moment(a_aapld).format("YYYY-MM-DD hh:mm A")
                this.aIsfirst = 1;
              }
            }
            if (bds !== null) {
              if (!this.bIsfirst) {
                let b_aapld = bds.delivery_scheduled_date_time;
                bl = moment(b_aapld).format("YYYY-MM-DD hh:mm A")
                this.bIsfirst = 1;
              }
            }
            this.aIsfirst = 0;
            this.bIsfirst = 0;
            if (isDesc[0]) {
              return new Date(bl) - new Date(al);
            } else {
              return new Date(al) - new Date(bl);
            }
          }
          if (["ETA", "last_free_date", "lfd_pdd", "per_diem_date"].includes(index[0])) {
            let j, k;
            j = k = i;
            if (["lfd_pdd"].includes(index[0])) {
              j = this.getLastArrivedLeg(a)
                ? "per_diem_date"
                : "last_free_date";
              k = this.getLastArrivedLeg(b)
                ? "per_diem_date"
                : "last_free_date";
            }
            if (isDesc[0]) {
              return new Date(b[k]) - new Date(a[j]);
            } else {
              return new Date(a[j]) - new Date(b[k]);
            }
          }
        }
      });
      return items;
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
    showAlert(item) {
      localStorage.setItem("queryData", this.currentSelectTab);
      this.storeEditData({
        items: item,
        objectMeta: this.metaobject,
        files: this.mgaFiles,
        drivers: this.magdriver,
        noFilterDrivers: this.noFilterDrivers,
        terminals: this.terminals,
        customers: this.customers,
        shipmentsize: this.shipmentsize,
        currentState: this.currentSelectTab,
      });
      this.$router.push("/container/edit/" + item.id).catch(() => { });
    },
    openDialog($event, item) {
      var isChecked = $event.target.checked;
      if (!item.container_empty && isChecked) {
        this.dialogData = item;
        this.dialog = true;
      } else if (item.container_empty && !isChecked) {
        this.dialogData = item;
        this.dialog = true;
      }
    },
    closeDialog(item) {
      this.dialog = false;
      var div = jQuery(document);
      var isChecked = div.find("#" + item.id).is(":checked");
      if (!item.container_empty && isChecked) {
        jQuery("#" + item.id).prop("checked", false);
      } else if (item.container_empty && !isChecked) {
        jQuery("#" + item.id).prop("checked", true);
      }
    },
    handlePicker() {
      this.isDatePickerOpen = true
      this.pickerLabel = 'Pick ETA Range'
    },
    createDo() {
      this.$router.push("/delivery-order").catch(() => { });
    },
    gotoCreate() {
      this.$router.push("/container/add/new").catch(() => { });
    },
    clickFilter(nameele) {
      this.classSelected = nameele;
    },
    checkIfClassActive(name) {
      if (name === this.classSelected) {
        return "active";
      }
    },
    /* SET ACTIVE */
    setSetFilterActive(item) {
      if (item) {
        this.filtertabs(item);
        // if click assign,all,unassigned
        this.menu = false;
        this.driverSelectFilter = [];
        this.locationfromfilter = [];
        // if click assign,all,unassigned
        this.classSelected = item;
        this.currentStateClick = item;
        let __ = this;
        var div = jQuery(document);
        if (this.lenthDispatch > 1) {
          __.driverfinalPush = [];
          __.drivernameLabel = [];
          if (item == "Assign") {
            __.typeFilter = 1;
            let namePrefix = "hasDriver";
            //div.find(".v-data-table__wrapper .hasNoDriver").hide();
            //div.find(".v-data-table__wrapper .hasDriver").show();
            var leng = div.find(
              ".v-data-table__wrapper" +
              " table tr.hastwotypes td.schedule p.hasDriver"
            ).length;
            __.calJqueryEach("schedule", namePrefix, leng),
              __.calJqueryEach("pickupon", namePrefix, leng),
              __.calJqueryEach("deliveryon", namePrefix, leng),
              __.calJqueryEach("statuson", namePrefix, leng);
          } else if (item == "All") {
            this.typeFilter = 0;
            //div.find(".v-data-table__wrapper .hasNoDriver").show();
            //div.find(".v-data-table__wrapper .hasDriver").show();
            __.callremove(__.hasDriverLastElement, "schedule"),
              __.callremove(__.hasDriverLastElement, "pickupon"),
              __.callremove(__.hasDriverLastElement, "deliveryon"),
              __.callremove(__.hasDriverLastElement, "statuson");
          } else {
            this.typeFilter = 2;
            //div.find(".v-data-table__wrapper .hasNoDriver").show();
            //div.find(".v-data-table__wrapper .hasDriver").hide();
          }
          this.setFilter(item);
        }
      }
    },
    /**
     *  Add class to access the last element of the group
     *  @param1 = name class of ta target element (schedule,pickupon,deliveryon,statuson)
     *  @param2 = hassDriver or hasnoDriver
     *  @param3 = check the length
     */
    calJqueryEach(cl, pr, l) {
      if (l) {
        jQuery(document)
          .find(
            ".v-data-table__wrapper" +
            " table tr.hastwotypes td." +
            cl +
            " p." +
            pr
          )
          .each(function (e) {
            if (e + 2 == l + 1) {
              jQuery(this).addClass("hasDriverLastElement");
            }
          });
      }
    },
    /**
     *   Remove if all
     *   @param1 = element , the element to remove the class
     *   @param2 = el object element
     */
    callremove(element, el) {
      var c;
      var _dc = jQuery(document);
      switch (el) {
        case "schedule":
          c = _dc.find(".v-data-table__wrapper" + " table td.schedule p");
          break;
        case "deliveryon":
          c = _dc.find(".v-data-table__wrapper" + " table td.deliveryon p");
          break;
        case "pickupon":
          c = _dc.find(".v-data-table__wrapper" + " table td.pickupon p");
          break;
        case "statuson":
          c = _dc.find(".v-data-table__wrapper" + " table td.statuson p");
          break;
      }
      c.removeClass(element);
    },
    /* GET SCHEDULE */
    get_schedule(item) {

      if (item !== null) {
        let getfirst = _.first(item.dispatch.legs);
        var
          kani = this,
          c = "",
          s = "";
        let fobject = getfirst;
        c = kani.findDriverClass(fobject.driver_id);
        //let sched = typeof fobject.unique_id !='undefined' ? fobject.unique_id : '' ;
        let sched = (fobject.legID == null) ? item.shifl_ref + '-' + '01' : fobject.legID;
        // old code - commented
        // s +=
        //   '<p data-id="' +
        //   c +
        //   '" id="' +
        //   c +
        //   '" class="' +
        //   c +
        //   ' Assign commonP"><span>' +
        //   sched +
        //   '</span><br><span class="totype" >' +
        //   (fobject.type == "Empty Return" ? "" : "To ") +
        //   kani.nameTransform(
        //     fobject.type
        //       ? kani.typeDropdown.find((e) => e.value == fobject.type).label
        //       : fobject.type
        //   ) +
        //   "</span> <br> " +
        //   '<span class="totype" >' +
        //   kani.nameTransform(
        //     fobject.mode ? (fobject.mode == "Dropped" ? "Drop" : fobject.mode) : ""
        //   ) +
        //   "</span></p>";

        // new code - Praisey 
        s +=
          '<p data-id="' + c + '" id="' + c + '" class="' + c +
          ' Assign commonP"><span>' + sched +
          '</span><br><span class="totype" >' +
          kani.nameTransform(
            fobject.mode ? (fobject.mode == "Dropped" ? "Drop" : fobject.mode) : ""
          ) +
          "</span> | " +
          '<span class="totype" >' +
          (fobject.type == "Empty Return" ? "" : "To ") +
          kani.nameTransform(
            fobject.type
              ? kani.typeDropdown.find((e) => e.value == fobject.type).label
              : fobject.type
          ) +
          "</span></p>";
        return s;

      }

      /**
       
        var parsejson = this.getparse(item),
          kani = this,
          c = "",
          s = "";
          if(typeof parsejson.legs !=='undefined'){
            if (parsejson.legs.length > 0)
            {
              Object.values(parsejson.legs).forEach(function (f) {
                c = kani.findDriverClass(f.driver);
                let sched = typeof f.id !='undefined' ? f.id : '--' ;
                s +=
                  '<p data-id="' +
                  c +
                  '" id="' +
                  c +
                  '" class="' +
                  c +
                  ' Assign commonP"><span>' +
                  sched +
                  '</span><br><span class="totype" >' +
                  (f.type == "Empty Return" ? "" : "To ") +
                  kani.nameTransform(
                    f.type
                      ? kani.typeDropdown.find((e) => e.value == f.type).label
                      : f.type
                  ) +
                  "</span> <br> " +
                  '<span class="totype" >' +
                  kani.nameTransform(
                    f.mode ? (f.mode == "Dropped" ? "Drop" : f.mode) : ""
                  ) +
                  "</span></p>";
              });
              return s;
            }
          }
      }
       */
    },
    /* GET TYPE */
    get_type(item) {
      var parsejson = this.getparse(item);
      if (parsejson.legs.length > 0) {
        return parsejson.legs[0].type;
      }
    },
    /* ASSIGN TO */
    getAssignedTo(item) {
      var des = this.getDrivers,
        kani = this,
        hasDriver = "";
      if (item !== null) {
        var ti = this.getparse(item);
        if (typeof ti.legs != 'undefined') {
          if (ti.legs.length > 0) {
            var s = "";
            Object.values(ti.legs).forEach(function (f) {
              var r = des.filter((find) => find.id == f.driver);
              if (r.length > 0) {
                hasDriver = "hasDriver ";
                Object.values(r).forEach(function (ff) {
                  s +=
                    '<p  class="' +
                    hasDriver +
                    ' DRIVER_ASSIGN Assign commonP" ><span>' +
                    kani.nameTransform(ff.name) +
                    "<span></p>";
                });
              } else {
                hasDriver = "hasNoDriver ";
                s +=
                  '<p  class="' +
                  hasDriver +
                  ' DRIVER_unASSIGN unassignna commonP" ><span>Unassigned<span></p>';
              }
            });
            return s;
          }//end length
        }
      }
    },
    getAssignedToClass(item) {
      var des = this.getDrivers,
        // kani = this,
        hasDriver = "";
      if (item !== null) {
        var ti = this.getparse(item);
        if (typeof ti.legs != 'undefined') {
          if (ti.legs.length > 0) {
            // var s = "";
            Object.values(ti.legs).forEach(function (f) {
              var r = des.filter((find) => find.id == f.driver);
              if (r.length > 0) {
                hasDriver = "hasDriver ";
                Object.values(r).forEach(function () {
                });
              } else {
                hasDriver = "hasNoDriver ";
              }
            });
            return hasDriver;
          }//end length
        }
      }
    },
    getAssignedToDriver(item) {
      //   var des = this.getDrivers,
      //   kani = this,
      //   status = "";

      //   if(item.dispatch.legs.length> 0){       
      //   var decodeleg = JSON.parse(item.dispatch_schedule);       
      //   let filtered_array = _.filter(decodeleg.legs, function(o) {
      //       return !o.completed;
      //     }
      //   );

      //   let c = _.first(filtered_array)     
      //   let getfirst = c; 
      //   if(typeof getfirst !='undefined'){
      //         var s = "";            
      //           var r = des.filter((find) => find.id == getfirst.driver);
      //           if (r.length > 0) {
      //             if(getfirst.driver_status !== "Accepted") {
      //               status = getfirst.driver_status
      //             }
      //             Object.values(r).forEach(function (ff) {
      //               s = kani.nameTransform(ff.name)
      //             });
      //           } else {
      //             s = 'Unassigned'
      //           }            
      //         return {
      //           driver: s,
      //           status: status
      //         };

      //     }
      // }

      // return {
      //   driver: '',
      //   status: ''
      // };

      // if (item !== null) {
      //   var ti = this.getparse(item);
      //   if(typeof ti.legs !='undefined'){
      //     if (ti.legs.length > 0) {
      //       var s = "";
      //       Object.values(ti.legs).forEach(function (f) {
      //         var r = des.filter((find) => find.id == f.driver);
      //         if (r.length > 0) {
      //           if(f.driver_status !== "Accepted") {
      //             status = f.driver_status
      //           }
      //           Object.values(r).forEach(function (ff) {
      //             s = kani.nameTransform(ff.name)
      //           });
      //         } else {
      //           s = 'Unassigned'
      //         }
      //       });
      //       return {
      //         driver: s,
      //         status: status
      //       };
      //     }//end length
      //   }
      // }

      var des = this.getDrivers, kani = this;
      let status = "Pending"
      let driver = ""

      if (item !== null && item.dispatch !== undefined) {
        let currentLeg = _.first(item.dispatch.legs);

        if (item.dispatch.legs.length > 0) {
          if (currentLeg.driver_status !== "Declined") {
            if (currentLeg.driver_status === "Pending") {
              if (currentLeg.schedule_accepted_date_time !== null) {
                status = "Accepted"
              }
            } else if (currentLeg.driver_status === "Accepted") {
              status = "Accepted"

              if (currentLeg.start_date_time !== null) {
                status = "Started"

                if (currentLeg.arrival_at_pickup_location_date_time !== null) {
                  status = "Arrived At Pickup"

                  if (currentLeg.departure_from_pickup_location_date_time !== null) {
                    status = "Outgated From Pickup"

                    if (currentLeg.arrival_at_delivery_location_date_time !== null) {
                      status = "Arrived At Delivery"

                      if (currentLeg.departure_from_delivery_location_date_time !== null) {
                        status = "Outgated From Delivery"
                      }
                    }
                  }
                }
              } else {
                if (currentLeg.arrival_at_pickup_location_date_time !== null) {
                  status = "Arrived At Pickup"

                  if (currentLeg.departure_from_pickup_location_date_time !== null) {
                    status = "Outgated From Pickup"

                    if (currentLeg.arrival_at_delivery_location_date_time !== null) {
                      status = "Arrived At Delivery"

                      if (currentLeg.departure_from_delivery_location_date_time !== null) {
                        status = "Outgated From Delivery"
                      }
                    }
                  }
                }
              }
            }
          } else {
            status = currentLeg.driver_status
          }

          let findDriver = des.filter((find) => find.id == currentLeg.driver_id);
          if (findDriver.length > 0) {
            Object.values(findDriver).forEach(function (ff) {
              driver = kani.nameTransform(ff.name)
            });
          } else {
            driver = "Unassigned"
          }
        }
      }

      return {
        driver,
        status
      }
    },
    getAssignedToStatus(item) {
      // var des = this.getDrivers,
      // statusClass = "";

      // if (item !== null) {
      //   var ti = item.dispatch.legs; //this.getparse(item);
      //   if(typeof ti !='undefined'){
      //     if (ti.length > 0) {
      //       let getfirst = _.first(item.dispatch.legs);   


      //         var r = des.filter((find) => find.id == getfirst.driver_id);
      //         if (r.length > 0) {
      //           if(getfirst.driver_status === 'Pending') {
      //             statusClass = 'warning--text'
      //           }
      //           else if(getfirst.driver_status === 'Declined') {
      //             statusClass = 'red--text'
      //           }
      //         }
      //       return statusClass
      //     }//end length
      //   }
      // }

      // if (item !== null) {
      //   var ti = this.getparse(item);
      //   if(typeof ti.legs !='undefined'){
      //     if (ti.legs.length > 0) {
      //       Object.values(ti.legs).forEach(function (f) {
      //         var r = des.filter((find) => find.id == f.driver);
      //         if (r.length > 0) {
      //           if(f.driver_status === 'Pending') {
      //             statusClass = 'warning--text'
      //           }
      //           else if(f.driver_status === 'Declined') {
      //             statusClass = 'red--text'
      //           }
      //         }
      //       });
      //       return statusClass
      //     }//end length
      //   }
      // }

      let status = "yellow-bg-status"

      if (item !== null && item.dispatch !== undefined) {
        let currentLeg = _.first(item.dispatch.legs);

        if (item.dispatch.legs.length > 0) {
          if (currentLeg.driver_status !== "Declined") {
            if (currentLeg.driver_status === "Pending") {
              if (currentLeg.schedule_accepted_date_time !== null) {
                status = "green-bg-status"
              }
            } else if (currentLeg.driver_status === "Accepted") {
              status = "green-bg-status"

              if (currentLeg.start_date_time !== null) {
                status = "blue-bg-status"

                if (currentLeg.arrival_at_pickup_location_date_time !== null) {
                  status = "blue-bg-status"

                  if (currentLeg.departure_from_pickup_location_date_time !== null) {
                    status = "blue-bg-status"

                    if (currentLeg.arrival_at_delivery_location_date_time !== null) {
                      status = "blue-bg-status"

                      if (currentLeg.departure_from_delivery_location_date_time !== null) {
                        status = "blue-bg-status"
                      }
                    }
                  }
                }
              } else {
                if (currentLeg.arrival_at_pickup_location_date_time !== null) {
                  status = "blue-bg-status"

                  if (currentLeg.departure_from_pickup_location_date_time !== null) {
                    status = "blue-bg-status"

                    if (currentLeg.arrival_at_delivery_location_date_time !== null) {
                      status = "blue-bg-status"

                      if (currentLeg.departure_from_delivery_location_date_time !== null) {
                        status = "blue-bg-status"
                      }
                    }
                  }
                }
              }
            }
          } else {
            status = "red-bg-status"
          }
        }
      }

      return `assigned-status ${status}`
    },
    getDriverStatus(driver, item, dispatchSched) {
      let legindexdata = 0;
      let longLat = '';
      let originalData = this.allschedule; //this.newContainersData;
      let CurrentLegScheduled = JSON.parse(dispatchSched);
      let legFirst_CurrentLegScheduled = []
      let getFilterScheduled = [];
      let driverc = '';
      let locationfrom = '';
      var returnType = '';

      if (CurrentLegScheduled.legs.length > 0) {
        // get the !completed
        getFilterScheduled = _.filter(CurrentLegScheduled.legs,
          function (o) {
            return !o.completed;
          }
        );

        // The first uncompleted leg 
        legFirst_CurrentLegScheduled = _.first(getFilterScheduled)

        // Detect if first leg or not 
        legindexdata = _.findIndex(CurrentLegScheduled.legs, (c) => c.id === legFirst_CurrentLegScheduled.id);

      }

      // let theDriverListModified = this.getDrivers;
      // Steps use the newContainersData - done
      // Loop the newContainersData
      var cMap = Object.values(originalData).map((e) => {

        // use the dispatch_schedule 
        // json.pase the dispatch_schedule 
        let schedule = JSON.parse(e.dispatch_schedule);

        if (schedule.legs.length > 0) {
          // get the !completed
          let filtered_array = _.filter(schedule.legs, function (o) {
            return !o.completed;
          }
          );

          // then get the first !completed
          let currentLoopLeg = _.first(filtered_array)

          // then check the driver id if match sa current container nga gi click gamiton ang leg 
          // then excluding the current driver of the container  
          // modify the object of the all Driver Object
          // pickup date check if the same ba 
          // if the same then e check ang time.

          if (driver.id !== 0 && driver.id === currentLoopLeg.driver && driver.id !== legFirst_CurrentLegScheduled.driver) {

            // if ang driver dili the same sa current
            // match ang pickup date ug time 
            if (legFirst_CurrentLegScheduled.from_date != null && legFirst_CurrentLegScheduled.date != null) {
              let dateFrom = moment(legFirst_CurrentLegScheduled.from_date).utc(true).format("YYYY-MM-DD")
              let CurrentLoopdateFrom = moment(currentLoopLeg.from_date).utc(true).format("YYYY-MM-DD")

              let dateDelivery = moment(legFirst_CurrentLegScheduled.date).utc(true).format("YYYY-MM-DD")
              let CurrentLoopdateDateDelivery = moment(currentLoopLeg.date).utc(true).format("YYYY-MM-DD")

              // check the schedule date and time 
              if (dateFrom == CurrentLoopdateFrom || dateDelivery == CurrentLoopdateDateDelivery) {
                if (dateFrom == CurrentLoopdateFrom) {
                  if (dateFrom == CurrentLoopdateFrom && dateDelivery == CurrentLoopdateDateDelivery) {

                    // find the overlap of legFirst_CurrentLegScheduled.to_time | schedule pickup time
                    //let ctoToTime = moment( legFirst_CurrentLegScheduled.to_time ).format('hh:mm A');
                    //alert(legFirst_CurrentLegScheduled.to_time)
                    let ctoToTime = this.tConvert(legFirst_CurrentLegScheduled.to_time);
                    returnType = this.timeOverlapped(ctoToTime, currentLoopLeg, 'pickup');

                  }
                } else if (dateDelivery == CurrentLoopdateDateDelivery) { // delivery
                  if (dateFrom == CurrentLoopdateFrom && dateDelivery == CurrentLoopdateDateDelivery) {
                    // find the overlap of legFirst_CurrentLegScheduled.time schedule delivery time
                    //let ctoTime = moment( legFirst_CurrentLegScheduled.time ).format('hh:mm A');
                    let ctoTime = this.tConvert(legFirst_CurrentLegScheduled.time);
                    returnType = this.timeOverlapped(ctoTime, currentLoopLeg, 'pickup');

                  }
                }
              }

              // if empty then check time overlapped in arrival
              if (returnType == '') {
                // check arrival of delivery 
                if ((legFirst_CurrentLegScheduled.arrival_at_delivery_location_date == currentLoopLeg.arrival_at_delivery_location_date) &&
                  (legFirst_CurrentLegScheduled.arrival_at_delivery_location_date != null && currentLoopLeg.arrival_at_delivery_location_date != null)) {
                  // arrival_at_pickup_location_date equal currentLoopLeg.arrival_at_pickup_location_dat
                  if (legFirst_CurrentLegScheduled.arrival_at_pickup_location_date == currentLoopLeg.arrival_at_pickup_location_date) {
                    if (legFirst_CurrentLegScheduled.arrival_at_pickup_location_date != null && currentLoopLeg.arrival_at_pickup_location_date != null) {
                      //let cto = moment( legFirst_CurrentLegScheduled.arrival_at_pickup_location_time ).format('hh:mm A');
                      let cto = this.tConvert(legFirst_CurrentLegScheduled.arrival_at_pickup_location_time);
                      returnType = this.timeOverlapped(cto,
                        currentLoopLeg,
                        'arrival'); // arrival at pickup 

                      if (returnType == '') {
                        //let ct = moment( legFirst_CurrentLegScheduled.arrival_at_delivery_location_time ).format('hh:mm A');
                        let ct = this.tConvert(legFirst_CurrentLegScheduled.arrival_at_delivery_location_time);
                        returnType = this.timeOverlapped(ct,
                          currentLoopLeg,
                          'arrival'); // arrival at delivery
                      }
                    }
                  }

                  /* 
                  *  [arrival_at_pickup_location_date] =>
                  *  [arrival_at_pickup_location_time] =>
                  */
                }
              }

              if (returnType != '') {
                return returnType = { busy: e.shifl_ref };
              }
            } else {

              // if schedule pickup date is not null or schedule delivery date is not null
              if (legFirst_CurrentLegScheduled.from_date == null || legFirst_CurrentLegScheduled.date == null) {
                // get the delivery date and time plus estimated time of delivery the duration 
                // legFirst_CurrentLegScheduled.from_date
                // let terminal = item.terminal_id;
                // var dateOfDeliveryTime = legFirst_CurrentLegScheduled.date + " "  + legFirst_CurrentLegScheduled.time;

                if (legindexdata > 0) {
                  let previousLeg = CurrentLegScheduled[legindexdata - 1];
                  let previousLegAddress = previousLeg.address
                  this.theplacesValue = []; // empty first

                  this.getPlacePickuplocation(previousLegAddress);
                  // get the previous delivery address as location from

                  this.getPlacePickuplocation(legFirst_CurrentLegScheduled.address);
                  // get the current leg delivery location as location to

                  // call the coordinates matrix
                  // if null ang pickup date and time add it to delivery
                  if (legFirst_CurrentLegScheduled.from_date == null) {
                    if (legFirst_CurrentLegScheduled.date != null) {
                      return this.getPlaces(legFirst_CurrentLegScheduled, currentLoopLeg, 'delivery');
                    }
                  } else {
                    if (legFirst_CurrentLegScheduled.date == null) {
                      if (legFirst_CurrentLegScheduled.from_date != null) {
                        return this.getPlaces(legFirst_CurrentLegScheduled, currentLoopLeg, 'pickup');
                      }
                    }
                  }

                } else {
                  longLat = this.terminals.find((t) => t.id == item.terminal_id);

                  // Terminal Longitude and latitude as location from 
                  locationfrom = longLat.longitude + ',' + longLat.latitude;

                  if (longLat.longitude != null && longLat.latitude != null) {
                    this.theplacesValue = []; // empty first
                    this.theplacesValue.push(locationfrom);
                  }

                  this.getPlacePickuplocation(legFirst_CurrentLegScheduled.address);
                  // get the longitude and latitude of the current legs

                  if (legFirst_CurrentLegScheduled.from_date == null) {
                    if (legFirst_CurrentLegScheduled.date != null) {
                      if ((currentLoopLeg.from_date == legFirst_CurrentLegScheduled.from_date) && (currentLoopLeg.date == legFirst_CurrentLegScheduled.date)) {
                        return this.getPlaces(legFirst_CurrentLegScheduled, currentLoopLeg, 'delivery');
                      }
                    }
                  } else {
                    if (legFirst_CurrentLegScheduled.date == null) {
                      if (legFirst_CurrentLegScheduled.from_date != null) {
                        if ((currentLoopLeg.from_date == legFirst_CurrentLegScheduled.from_date) && (currentLoopLeg.date == legFirst_CurrentLegScheduled.date)) {
                          return this.getPlaces(legFirst_CurrentLegScheduled, currentLoopLeg, 'pickup');
                        }
                      }
                    }
                  }

                }
              }
              return '';
            }
          }
        } else {
          return ''
        }
      })

      if (cMap != '') {
        var result = cMap.find(x => {
          if (typeof x != 'undefined') {
            return x.busy
          }
        });

        if (typeof result != 'undefined') {
          return 'Busy' + ' Active Ref: #' + result.busy;
        }

      }
      if (driverc == '') {
        if (driver.driver === null || driver.id == 0) {
          return ""
        }
        else if (driver.driver !== null && driver.id !== 0) {
          return "Connected"
        }
        else if (!driver.status) {
          return "Non-active"
        }
        else return "Active"
      } else {
        return driverc;
      }
    },
    getScheduledLeg(item) {
      // var des = this.getDrivers, lid = "";
      // if (item !== null) {
      //   var ti = this.getparse(item);
      //   if(typeof ti.legs !='undefined'){
      //     if (ti.legs.length > 0) {
      //       Object.values(ti.legs).forEach(function (f) {
      //         var r = des.filter((find) => find.id == f.driver);
      //         if (r.length > 0) {
      //           lid = f.id
      //         } else {
      //           lid = f.id
      //         }
      //       });
      //       this.scheduledLastLegID = lid
      //     }//end length
      //   }
      // }
      this.scheduledLastLegID = item.id;
    },
    handleDriverSelect(driver, item, keyIndex, dispatchSched) {
      let getChedule = this.getClearnDispatch(dispatchSched);
      this.getScheduledLeg(getChedule)
      let temp = this.newContainersData
      let ds = JSON.parse(item.dispatch_schedule)

      let toUpdateLeg = ""
      let index = 0
      ds.legs.map((leg, i) => {
        if (leg.id === this.scheduledLastLegID) {
          index = i
          toUpdateLeg = leg
          toUpdateLeg["driver"] = driver.id
          toUpdateLeg["driver_status"] = "Pending"
        }
      })

      ds.legs.splice(index, 1, toUpdateLeg)
      temp[keyIndex]['dispatch_schedule'] = JSON.stringify(ds)

      let formData = new FormData();
      formData.append("column", 'dispatch_schedule');
      formData.append("value", JSON.stringify(ds));

      axios.post(`${process.env.VUE_APP_PO_URL}/quick-edit/${item.id}`, formData)
        .then(async (response) => {
          console.log(response)
          this.newContainersData.splice(keyIndex, 1, temp[keyIndex])

          this.loadedContainersData[this.currentTab].data[this.getLoadedDataPage()] = temp

          jQuery(`.driver${item.id}`).html(`${driver.name}`)
          jQuery(`#status${item.id}`).addClass('warning--text')
          jQuery(`#status${item.id}`).html(`Pending`)
          jQuery(`.status${item.id}`).html(`Pending`)
          this.notificationCustom('Container updated successfully!')
        })
        .catch((error) => {
          this.notificationErrorCustom('There was an error!')
          console.error("There was an error!", error);
        })
    },
    /**
     *  Delivery On
     *  Scheduled Delivery Date = leg.date
     */
    deliveryon(item) {
      //moment_timezone 
      if (item.dispatch.legs.length > 0) {
        var akoy = this, parsejson = _.first(item.dispatch.legs);

        // const dateon =  new Date(parsejson.delivery_scheduled_date_time);
        // // IF NULL ANG pickup_scheduled_date_time KUHA SA DISPATCH_SCHEDULE 
        // let the_date =  dateon.getUTCFullYear() +'-' + ( dateon.getUTCMonth() + 1)  + '-' + dateon.getDate();
        // let the_time =  dateon.toLocaleTimeString();   

        var s = "", commonP = "commonP ";
        // Object.values(parsejson.legs).forEach(function (f) {
        let date = "", time = "", isDateTimeSeparated = false;
        // if (typeof the_date != "undefined" && the_date != "") {
        //   date = the_date;
        //   time = "";
        //   if (typeof the_time != "undefined" && the_time != "") {
        //     time = the_time;
        //   }
        // }              

        // updated code - p
        if (parsejson.arrival_at_delivery_location_date_time !== null &&
          parsejson.arrival_at_delivery_location_date_time !== "") {
          date = parsejson.arrival_at_delivery_location_date_time;
          isDateTimeSeparated = false;
        } else {
          if (parsejson.delivery_scheduled_date_time !== null &&
            parsejson.delivery_scheduled_date_time !== "") {
            date = parsejson.delivery_scheduled_date_time
            isDateTimeSeparated = false;
          }
        }
        var hasDriver = akoy.findDriverClass(parsejson.driver_id);
        var theDate = "";
        // if (akoy.checkTotime(date) == "" && akoy.fromdate(time) == "") {
        //   theDate = "";
        // } else {
        //   if (akoy.checkTotime(time) == "") {
        //     theDate =
        //       '<span class="greyColor">' + akoy.fromdate(date) + "</span>";
        //   } else {
        //     theDate =
        //       akoy.checkTotime(time) +
        //       ', <span class="greyColor">' +
        //       akoy.fromdate(date) +
        //       "</span>";
        //   }
        // }

        // updated code - p
        let getDateFormatVal = "";
        if (isDateTimeSeparated) {
          // theDate = convertDateTimeToUTC(date, time);
          let dateTogether = date + " " + time;
          getDateFormatVal = moment.utc(dateTogether);
        } else {
          // theDate = moment(date).format("MM/DD/YY hh:mm A");
          getDateFormatVal = moment.utc(date)
        }

        theDate = getDateFormatVal !== "" ? getDateFormatVal.local().format('MMM DD, YYYY') : "";

        var xlenthtaddress =
          parsejson.address.length > 50
            ? parsejson.address.substring(0, 50) + "..."
            : parsejson.address;
        s +=
          '<p  class="' +
          commonP +
          "Assign " +
          hasDriver +
          '"><span>' +
          xlenthtaddress +
          '<span class="greyColor"> <br> <span>' +
          theDate +
          "</p>";
        //});

        return s;

      }
      // if (item !== null) {
      //   var akoy = this,parsejson = this.getparse(item);

      //   if(typeof parsejson.legs !='undefined')
      //   {
      //     if (parsejson.legs.length > 0)
      //     {
      //       var s = "",
      //         commonP = "commonP ";
      //       Object.values(parsejson.legs).forEach(function (f) {
      //         let date = "",
      //           time = "";
      //         if (typeof f.date != "undefined" && f.date != "") {
      //           date = f.date;
      //           time = "";
      //           if (typeof f.time != "undefined" && f.time != "") {
      //             time = f.time;
      //           }
      //         }
      //         var hasDriver = akoy.findDriverClass(f.driver);
      //         var theDate = "";
      //         if (akoy.checkTotime(date) == "--" && akoy.fromdate(time) == "--") {
      //           theDate = "--";
      //         } else {
      //           if (akoy.checkTotime(time) == "--") {
      //             theDate =
      //               '<span class="greyColor">' + akoy.fromdate(date) + "</span>";
      //           } else {
      //             theDate =
      //               akoy.checkTotime(time) +
      //               ', <span class="greyColor">' +
      //               akoy.fromdate(date) +
      //               "</span>";
      //           }
      //         }
      //         var xlenthtaddress =
      //           f.address.length > 50
      //             ? f.address.substring(0, 50) + "..."
      //             : f.address;
      //         s +=
      //           '<p  class="' +
      //           commonP +
      //           "Assign " +
      //           hasDriver +
      //           '"><span>' +
      //           xlenthtaddress +
      //           '<span class="greyColor"> <br> <span>' +
      //           theDate +
      //           "</p>";
      //       });
      //       return s;
      //     }
      //   }
      // }
    },
    /**
     *   Pickup On
     *   @param1 = item or the dispatch_schedule
     *   @param2 = terminal name for the leg one pickup address
     *   Scheduled Pickup Date = leg.from_date
     */
    pickupOn(item, mainItem) {

      if (item !== null) {
        var akoy = this, getfirst = _.first(item.dispatch.legs);
        akoy.lenthDispatch = 0;

        var l = item.dispatch.legs.length
        if (item.dispatch.legs.length > 0) {

          var ii = "", address = '';
          var terminalName = '';
          var starting = '';

            // get the terminal name
            if(getfirst.is_first_leg){

              // IF FIRST LEG CHECK IF CUSTOM STARTING LOCATION 
              if(item.is_custom_starting_location){
                starting = item.starting_location_address
              }else{
                if(mainItem.terminal !==null){
                  Object.keys(mainItem)
                  .forEach(function(key) {
                    if(key=='terminal'){
                      // terminalName = (mainItem['terminal'].name);
                      terminalName = (mainItem['terminal'].nickname);
                    }
                  });
                }
              }
            }else{
              address = getfirst.pickup_location;
            }

          /** Get the legs */
          var datePick = {};
         
          let isDateTimeSeparated = false;

          if (getfirst.arrival_at_pickup_location_date_time !== null) {
            datePick.date = getfirst.arrival_at_pickup_location_date_time;
            isDateTimeSeparated = false;
          } else {
            if (getfirst.pickup_scheduled_date_time !== null) {
              datePick.date = getfirst.pickup_scheduled_date_time
              isDateTimeSeparated = false;
            } else {
              let parsedispatch = JSON.parse(item) // get the dispatch_schedule
              isDateTimeSeparated = true;
              if (parsedispatch.legs.length > 0) {
                const result = parsedispatch.legs.find((e) => e.id = getfirst.unique_id);
                datePick.date = result.from_date;
                datePick.time = result.to_time;
              } else {
                datePick.date = '--';
                datePick.time = '--';
              }
            }
          }

          // address = (counterLeg == 0) ? { address: terminalName,time: f.to_time,date: f.from_date} :
          // 		{ address: lastLeg.address,time: lastLeg.time,date: lastLeg.date};
          if (typeof terminalName === "undefined" || terminalName === null || terminalName === "") {
            terminalName = "";
          }

          address = address.length > 0 ? address : ((starting !='') ? starting :  terminalName);

          //is_first_leg
          var hasDriver = akoy.findDriverClass(getfirst.driver);
          var datedate = "";

        
          let getDateFormatVal = ""

          if (isDateTimeSeparated) {
            // datedate = convertDateTimeToUTC(datePick.date, datePick.time);
            let dateTogether = datePick.date + " " + datePick.time;
            getDateFormatVal = moment.utc(dateTogether);
          } else {
            getDateFormatVal = moment.utc(datePick.date);
          }

          datedate = getDateFormatVal !== "" ? getDateFormatVal.local().format('MMM DD, YYYY') : "";

          var lenthtaddress = address.length > 60 ? address.substring(0, 50) + "..." : address;
          ii += '<p  elsevlength="' + l + '" id="' + hasDriver + '"  class="commonP Assign ' + hasDriver + '"><span>' + lenthtaddress + '<span class="greyColor"> <br> <span>' + datedate + "</p>";
         
          return ii;
          } 
        }      
    },
    checkifstartinglocation(item){
      if(item.is_custom_starting_location){
        return 1;
      }
      return 0;
    },
    checklength_dispatch(e) {
      var parse = _.first(e.dispatch.legs) // the structure returns only the not completed leg
      if (parse.is_first_leg) {
        return 1;
      }
      return 0;

      //var parse = this.getparse(item);     
      // var ret = 0;
      // if(typeof parse.legs !='undefined'){
      //   if(parse.legs.length) {
      //     Object.values(parse.legs).forEach(function(f) {
      //       if(typeof f.x_element != 'undefined') {
      //         ret = 0;
      //       } else {
      //         ret = 1
      //       }
      //     })
      //   }
      //   return ret
      // }
    },
    /* PARSE THE ITEM */
    getparse(item) {
      if (item != "") {
        try {
          return JSON.parse(item);
        } catch (err) {
          // Uncaught SyntaxError: JSON.parse: unexpected character at
          // line 1 column 2 of the JSON data
          return {};
        }
      }
    },
    /* TRANSFORM STRING  */
    nameTransform(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    /**
     *   Convert date format
     *   @param1 = date
     *   @param2 = date filter string
     */
    convertDateformat(data, isDate) {
      if (typeof data != "undefined") {
        var thedate = "";
        if (isDate == "isDate") {
          thedate = this.timeConvert(data);
        }
        return thedate;
      } else {
        // return "--";
        return "";
      }
    },
    // check to time
    checkTotime(data) {
      if (typeof data != "undefined") {
        return this.timeConvert(data);
      } else {
        // return "--";
        return "";
      }
    },
    // time convert
    timeConvert(dateString) {
      const d = dateString;
      const validHHMMstring = (str) =>
        /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(str);
      if (validHHMMstring(d)) {
        const timeString12hr = new Date(
          "1970-01-01T" + d + "Z"
        ).toLocaleTimeString("en-US", {
          timeZone: "UTC",
          hour12: true,
          hour: "numeric",
          minute: "numeric",
        });
        return timeString12hr.replace(/\s/g, "");
      } else {
        // return "--";
        return "";
      }
    },
    // find driver
    findDriverClass(f) {
      var des = this.magdriver,
        c = "",
        r = des.filter((find) => find.id == f);
      if (r.length > 0) {
        c = "hasDriver ";
        this.loopUnassigned = 1;
      } else {
        c = "hasNoDriver ";
        this.loopAssign = 1;
      }
      this.lenthDispatch = this.loopUnassigned + this.loopAssign; // count number of assigned and unassigned
      return c;
    },
    /**
     *	Check if row has one dispatch
     */
    checkIFOneRowdispatch(item) {
      if (item) {
        return "Noclass";
      }
      return "Noclass";
      // if (item != "") {
      //   var naadrivertanan = 0;
      //   var walaydrivertanan = 0;
      //   var ds = this;
      //   var des = this.magdriver,
      //   parsejson = this.getparse(item),
      //   returnme = "";
      //   if(parsejson !== null) {
      //     if (parsejson.legs.length == 1) {
      //       Object.values(parsejson.legs).forEach(function (f) {
      //         const r = des.filter((find) => find.id == f.driver);
      //         if (r.length > 0) {
      //           returnme = ds.naayDriverwala;
      //         } else {
      //           returnme = ds.walayDriver;
      //         }
      //       });
      //     } else {
      //       Object.values(parsejson.legs).forEach(function (f) {
      //         const r = des.filter((find) => find.id == f.driver);
      //         if (r.length > 0) {
      //           naadrivertanan++;
      //         } else {
      //           walaydrivertanan++;
      //         }
      //       });
      //       // multiple dispatch pero walay driver and tanan
      //       if (naadrivertanan == 0 && walaydrivertanan != 0) {
      //         returnme = ds.walayDriver;
      //       }
      //       // multiple dispatch pero naay driver tanan walay unassigned
      //       if (naadrivertanan != 0 && walaydrivertanan == 0) {
      //         returnme = ds.naayDriverwala;
      //       }
      //     }
      //   }
      //   return returnme;
      // } else {
      //   return "Noclass";
      // }
    },
    // Pila na assign driver sa multiple dispatch
    HomanyTtypes(item) {
      if (item != "") {
        var des = this.magdriver,
          parsejson = this.getparse(item),
          unassign = 0,
          assign = 0;
        if (parsejson.legs.length > 1) {
          Object.values(parsejson.legs).forEach(function (f) {
            const r = des.filter((find) => find.id == f.driver);
            if (r.length > 0) {
              unassign = 1;
            } else {
              assign = 1;
            }
          });
        }
        return parseInt(unassign) + parseInt(assign);
      } else {
        return "Noclass";
      }
    },
    capitalize(item) {
      return item.toUpperCase();
    },
    selectedTabData(tab, subTab = this.selected_sub_tab, page) {
      //if (!["not"].includes(tab)) {
      this.shouldLoad = false

      if (tab == "All") {
        if (typeof this.loadedContainersData["all"] !== 'undefined' && this.search === "" && !this.isLegUpdated &&
          !this.isSelectedETARange && !this.isSelectedCustomerFilter && !this.isSelectedActiveInactiveFilter) {
          this.getLoadedData("all", page)
        } else {
          this.getSelectedTabData("all", page);
        }
      } else if (tab == "At Port") {
        if (typeof subTab.label === "undefined") {
          this.subTabIndex = 0
          this.selected_sub_tab = {
            count: 0,
            label: "Pending",
            name: "Pending"
          };
          this.getSelectedTabData("at-port-pending", page);
        } else {
          if (subTab.label == "Pending") {
            if (typeof this.loadedContainersData["at-port-pending"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
              this.getLoadedData("at-port-pending", page)
            } else {
              this.getSelectedTabData("at-port-pending", page);
            }
          } else {
            if (typeof this.loadedContainersData["at-port-available"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
              this.getLoadedData("at-port-available", page)
            } else {
              this.getSelectedTabData("at-port-available", page);
            }
          }
        }
      } else if (tab == "At Yard") {
        if (subTab.name == "full") {
          if (typeof this.loadedContainersData["at-yard-full"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
            this.getLoadedData("at-yard-full", page)
          } else {
            this.getSelectedTabData("at-yard-full", page);
          }
        } else {
          if (typeof this.loadedContainersData["at-yard-empty"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
            this.getLoadedData("at-yard-empty", page)
          } else {
            this.getSelectedTabData("at-yard-empty", page);
          }
        }
      } else if (tab == "At the Customer") {
        if (subTab.name == "full") {
          if (typeof this.loadedContainersData["at-customer-full"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
            this.getLoadedData("at-customer-full", page)
          } else {
            this.getSelectedTabData("at-customer-full", page);
          }
        } else {
          if (typeof this.loadedContainersData["at-customer-empty"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
            this.getLoadedData("at-customer-empty", page)
          } else {
            this.getSelectedTabData("at-customer-empty", page);
          }
        }
      } else if (tab == "Pending billing") {
        if (typeof this.loadedContainersData["pending-billing"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
          this.getLoadedData("pending-billing", page)
        } else {
          this.getSelectedTabData("pending-billing", page);
        }
      } else if (tab == "Completed") {
        if (typeof this.loadedContainersData["completed"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
          this.getLoadedData("completed", page)
        } else {
          this.getSelectedTabData("completed", page);
        }
      } else if (tab == "Cancelled") {
        if (typeof this.loadedContainersData["cancelled"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
          this.getLoadedData("cancelled", page)
        } else {
          this.getSelectedTabData("cancelled", page);
        }
      } else if (tab == "Scheduled") {
        if (subTab == "Assigned" || subTab.name == "Assigned") {
          if (typeof this.loadedContainersData["assigned-schedule"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
            this.getLoadedData("assigned-schedule", page)
          } else {
            this.getSelectedTabData("assigned-schedule", page);
          }
        } else if (subTab == "Unassigned" || subTab.name == "Unassigned") {
          if (typeof this.loadedContainersData["unassigned-schedule"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
            this.getLoadedData("unassigned-schedule", page)
          } else {
            this.getSelectedTabData("unassigned-schedule", page);
          }
        } else if (subTab == "In-Progress" || subTab.name == "In-Progress") {
          if (typeof this.loadedContainersData["in-transit-schedule"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
            this.getLoadedData("in-transit-schedule", page)
          } else {
            this.getSelectedTabData("in-transit-schedule", page);
          }
        } else {
          if (typeof this.loadedContainersData["all-scheduled"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
            this.getLoadedData("all-scheduled", page)
          } else {
            this.getSelectedTabData("all-scheduled", page);
          }
        }
      }
      //} else {
      this.isDisabled = false;
      //}
    },
    async getSelectedTabData(tab, page) {
      this.shouldLoad = true;
      this.oldContainersData = this.newData;
      this.currentTab = tab

      let passedData = {
        method: "get",
        url: `${poBaseUrl}/shipment-tabs/${tab}`,
        params: {
          page,
          customer_id: this.selectedCustomerFilter.id,
          status: this.selectedActiveInactiveFilter.value,
          etaStart: this.selectedEtaRange.length > 0 ? this.selectedEtaRange[0] : '',
          etaEnd: this.selectedEtaRange.length > 0 ? this.selectedEtaRange[1] : '',
        },
        cancelToken: new CancelToken(function executor(c) {
          cancel = c
        }),
      }

      if (this.search !== null && this.search !== "") {
        // await axios
        //   .get(`${poBaseUrl}/shipment-tabs/${tab}`, {
        //     params: {
        //       page: page,
        //       search: this.search,
        //       customer_id: this.selectedCustomerFilter.id,
        //       status: this.selectedActiveInactiveFilter.value,
        //       etaStart : this.selectedEtaRange.length > 0 ? this.selectedEtaRange[0] : '',
        //       etaEnd : this.selectedEtaRange.length > 0 ? this.selectedEtaRange[1] : ''
        //     },
        //   })
        passedData.params.search = this.search

        await axios(passedData)
          .then((response) => {
            this.page = 1;
            this.lastPage = response.data.results.last_page;
            if (response.data.results.last_page > 1) {
              this.pageCount = response.data.results.last_page;
            }

            let datum = typeof this.loadedContainersData[tab] !== 'undefined' ? this.loadedContainersData[tab].data : []
            let pages = typeof this.loadedContainersData[tab] !== 'undefined' ? this.loadedContainersData[tab].page : []
            datum.push(response.data.results.data)
            pages.push(page)

            this.loadedContainersData[tab] = {
              data: datum,
              page: pages,
              pageCount: response.data.results.last_page,
              lastPage: response.data.results.last_page
            }

            this.newContainersData = response.data.results.data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        if (typeof page == 'undefined') {
          page = 1;
        }

        // await axios.get(`${poBaseUrl}/shipment-tabs/${tab}?page=${page}&customer_id=${this.selectedCustomerFilter.id}&status=${this.selectedActiveInactiveFilter.value}&etaStart=${(this.selectedEtaRange.length > 0 ? this.selectedEtaRange[0] : '')}&etaEnd=${(this.selectedEtaRange.length > 0 ? this.selectedEtaRange[1] : '')}`)
        await axios(passedData)
          .then((response) => {
            if (typeof response.data.components !== "undefined") {
              this.magdropterminal = response.data.components.loccationfilter;
            }

            if (typeof response.data.driversDropdown !== "undefined") {
              this.magdriverFilter = response.data.driversDropdown;
            }

            this.page = page;
            this.lastPage = response.data.results.last_page;
            if (this.pageCount !== response.data.results.last_page) {
              this.pageCount = response.data.results.last_page;
            }

            let datum = typeof this.loadedContainersData[tab] !== 'undefined' ? this.loadedContainersData[tab].data : []
            let pages = typeof this.loadedContainersData[tab] !== 'undefined' ? this.loadedContainersData[tab].page : []
            datum.push(response.data.results.data)
            pages.push(page)

            this.loadedContainersData[tab] = {
              data: datum,
              page: pages,
              pageCount: response.data.results.last_page,
              lastPage: response.data.results.last_page
            }

            this.newContainersData = response.data.results.data;
          })
          .catch((error) => {
            console.log(error);
          });
      }

      this.shouldLoad = false;
      this.isDisabled = false;
      this.isLegUpdated = 0;
      this.isSelectedETARange = false;
      this.isSelectedCustomerFilter = false;
      this.isSelectedActiveInactiveFilter = false;
    },
    getLoadedData(tab, page) {
      if (!this.loadedContainersData[tab].page.includes(page)) {
        this.getSelectedTabData(tab, page)
      } else {
        this.currentTab = tab
        this.page = page
        this.newContainersData = this.loadedContainersData[tab].data[this.getLoadedDataPage()]

        setTimeout(() => {
          this.isDisabled = false
        }, 100)
      }
    },
    getPosition(index, field) {
      /**
       * This is for before when the Zoom styling is being used
       */
      // pass el as props
      // let elem = document.getElementById(`${el}`)
      // if(elem !== null) {
      //   let clientBoundaries = elem.getBoundingClientRect()
      //   this.nudgePos = clientBoundaries.top/2.895+"px"
      // }

      let len = this.newContainersData.length - 6

      console.log(len, index)

      if (this.currentSelectTab === 'All') {
        if (field === 'eta') {
          return index > len ? "390px" : "-40px"
        } else {
          return index > len ? "390px" : "-10px"
        }
      }
    },
    handleDateChange(e, item, currentValue, key, index, model) {
      this.containerIndex = index
      if (currentValue) {
        let momentOb = moment(currentValue);

        if (momentOb.isValid()) {
          if (momentOb.year() == '2001') momentOb.set('year', moment().year());

          if (key === 'eta') {
            this.datePicked = momentOb.format('Y-MM-DD');
            if (this.$refs[model][index].value_ !== null) {
              // this.$refs[model][index].value_.setFullYear(moment().year())
              if (this.$refs[model][index].value_.getFullYear() === 2001) {
                this.$refs[model][index].value_.setFullYear(moment().year())
              }
            }
          }
          if (key === 'last_free_date') {
            this.datePicked = momentOb.format('Y-MM-DD');
            if (this.$refs[model][index].value_ !== null) {
              // this.$refs[model][index].value_.setFullYear(moment().year())
              if (this.$refs[model][index].value_.getFullYear() === 2001) {
                this.$refs[model][index].value_.setFullYear(moment().year())
              }
            }
          }
          if (key === 'per_diem_date') {
            this.datePicked = momentOb.format('Y-MM-DD');
            if (this.$refs[model][index].value_ !== null) {
              // this.$refs[model][index].value_.setFullYear(moment().year())
              if (this.$refs[model][index].value_.getFullYear() === 2001) {
                this.$refs[model][index].value_.setFullYear(moment().year())
              }
            }
          }
        }
      }
    },
    handleDblClick(item, keyEdit, index) {
      this.datePicked = ""
      this.dateActivator = ""
      this.lfdActivator = ""
      this.pddActivator = ""
      jQuery('.vc-popover-content .direction-top .vc-container').addClass('d-none')
      this.containerIndex = index

      setTimeout(() => {
        if (keyEdit === 'eta') {
          if (this.dateActivator !== "") {
            // this.notificationError('Please complete the active selection')
          } else {
            this.editedKey = keyEdit
            this.selectedCont = item
            this.datePicked = this.getFormattedDate(item)
            this.dateInput = this.getFormattedDate(item)

            // jQuery(`.dp${item.id}`)[0].childNodes[0].childNodes[0].childNodes[1].click()
            this.dateActivator = item.id
            jQuery(`.text${item.id}`).addClass('d-none')
            jQuery(`.dp${item.id}`).click()
            this.pickerLabel = 'Pick ETA Date'
          }
        }
        else if (keyEdit === 'last_free_date') {
          if (this.lfdActivator !== "") {
            // this.notificationError('Please complete the active selection')
          } else {
            this.editedKey = keyEdit
            this.selectedCont = item
            this.datePicked = this.getFormattedDate(item)
            this.dateInput = this.getFormattedDate(item)

            this.lfdActivator = item.id
            jQuery(`.lfd${item.id}`).addClass('d-none')
            if (this.currentSelectTab !== "At Port" && this.currentSelectTab !== "All") {
              jQuery(`.dp${item.id}`).click()
            } else {
              if (keyEdit === 'eta') {
                jQuery(`.dp${item.id}`).click()
              } else {
                jQuery(`.dp${item.shifl_ref}`).click()
              }
            }
            this.pickerLabel = 'Pick Last Free Date'
          }
        }
        else if (keyEdit === 'per_diem_date') {
          if (this.lfdActivator !== "") {
            // this.notificationError('Please complete the active selection')
          } else {
            this.editedKey = keyEdit
            this.selectedCont = item
            this.datePicked = this.getFormattedDate(item)
            this.dateInput = this.getFormattedDate(item)

            this.pddActivator = item.id
            jQuery(`.pdd${item.id}`).addClass('d-none')
            jQuery(`.dp${item.id}`).click()
            this.pickerLabel = 'Pick Per Diem Date'
          }
        }
      }, 100)
    },
    handleCancel() {
      // setTimeout(() => {
      if (this.editedKey === 'eta') {
        this.dateActivator = ""
        jQuery(`.text${this.selectedCont.id}`).removeClass('d-none')
      }
      else if (this.editedKey === 'last_free_date') {
        this.lfdActivator = ""
        jQuery(`.lfd${this.selectedCont.id}`).removeClass('d-none')
      }
      else if (this.editedKey === 'per_diem_date') {
        this.pddActivator = ""
        jQuery(`.pdd${this.selectedCont.id}`).removeClass('d-none')
      }
      // }, 100)
      this.datePickerOpen = false
    },
    getFormattedDate(item) {
      if (this.editedKey === 'eta') {
        if (item.eta !== "" && item.eta !== null && item.eta !== "-000001-11-30T00:00:00.000000Z") {
          return moment(item.eta).format('YYYY-MM-DD')
        } else {
          // return moment().format('YYYY-MM-DD')
          return null
        }
      }
      if (this.editedKey === 'last_free_date') {
        if (item.last_free_date !== "" && item.last_free_date !== null && item.last_free_date !== "-000001-11-30T00:00:00.000000Z") {
          return moment(item.last_free_date).format('YYYY-MM-DD')
        } else {
          // return moment().format('YYYY-MM-DD')
          return null
        }
      }
      if (this.editedKey === 'per_diem_date') {
        if (item.per_diem_date !== "" && item.per_diem_date !== null && item.per_diem_date !== "-000001-11-30T00:00:00.000000Z") {
          return moment(item.per_diem_date).format('YYYY-MM-DD')
        } else {
          // return moment().format('YYYY-MM-DD')
          return null
        }
      }
    },
    handleSave() {
      let date = this.trySelected
      let formData = new FormData();
      let temp = this.newContainersData

      formData.append("column", this.editedKey);
      if (this.editedKey === 'eta') {
        formData.append("value", date);
        temp.find((cont) => {
          if (this.selectedCont.id === cont.id) {
            cont[this.editedKey] = date + "T00:00:00.000000Z"
          }
        })
      }
      if (this.editedKey === 'last_free_date') {
        formData.append("value", date);
        temp.find((cont) => {
          if (this.selectedCont.id === cont.id) {
            cont[this.editedKey] = date + "T00:00:00.000000Z"
          }
        })
      }
      if (this.editedKey === 'per_diem_date') {
        formData.append("value", date);
        temp.find((cont) => {
          if (this.selectedCont.id === cont.id) {
            cont[this.editedKey] = date + "T00:00:00.000000Z"
          }
        })
      }

      axios.post(`${process.env.VUE_APP_PO_URL}/quick-edit/${this.selectedCont.id}`, formData)
        .then(async (response) => {
          console.log(response)

          // await this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, this.page)
          this.newContainersData.splice(this.containerIndex, 1, temp[this.containerIndex])

          this.loadedContainersData[this.currentTab].data[this.getLoadedDataPage()] = temp

          if (this.editedKey === 'eta') {
            jQuery(`.text${this.selectedCont.id}`).html(moment(date + "T00:00:00.000000Z").format('MMM DD, YYYY'))
            jQuery(`.text${this.selectedCont.id}`).removeClass('d-none')
            this.dateActivator = ""
          }
          if (this.editedKey === 'last_free_date') {
            jQuery(`.lfd${this.selectedCont.id}`).html(moment(date + "T00:00:00.000000Z").format('MMM DD, YYYY'))
            jQuery(`.lfd${this.selectedCont.id}`).removeClass('d-none')
            this.lfdActivator = ""
          }
          if (this.editedKey === 'per_diem_date') {
            jQuery(`.pdd${this.selectedCont.id}`).html(moment(date + "T00:00:00.000000Z").format('MMM DD, YYYY'))
            jQuery(`.pdd${this.selectedCont.id}`).removeClass('d-none')
            this.pddActivator = ""
          }

          this.editedKey = ""
          this.datePicked = ""

          this.notificationCustom('Container updated successfully!')
        })
        .catch((error) => {
          console.error(error)
          if (typeof error.message !== "undefined") {
            this.notificationError(error.message)
          } else {
            this.notificationErrorCustom('There was an error!')
          }
        })
    },
    quickSizeEdit(item, index) {
      console.log(item)
      jQuery(`#size${item.id}`).click()

      this.containerIndex = index
      this.selectedShipment = item
    },
    quickSizeEditCancel(item) {
      console.log(item)
      jQuery(`.size${item.id}`).removeClass('d-none')
      this.selectedShipment = ""
    },
    quickSizeEditSave(size) {
      let formData = new FormData();
      formData.append("column", 'container_size_id');
      formData.append("value", size.id);

      let temp = this.newContainersData

      temp.map((cont, index) => {
        if (index === this.containerIndex) {
          console.log('test')
          cont['container_size_id'] = size.id
        }
      })

      axios.post(`${process.env.VUE_APP_PO_URL}/quick-edit/${this.selectedShipment.id}`, formData)
        .then(async (response) => {
          console.log(response)

          this.newContainersData.splice(this.containerIndex, 1, temp[this.containerIndex])
          jQuery(`.size${this.selectedShipment.id}`).html(size.name)
          this.selectedShipment = ""

          this.loadedContainersData[this.currentTab].data[this.getLoadedDataPage()] = temp

          // await this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, this.page)
          this.notificationCustom('Container updated successfully!')
        })
        .catch((error) => {
          this.notificationErrorCustom('There was an error!')
          console.error("There was an error!", error);
        })
    },
    getLoadedDataPage() {
      let index = this.loadedContainersData[this.currentTab].page.findIndex((p) => {
        return p === this.page
      })

      return index
    }
  },
  updated() {
    if (this.sub_tab.length == 0) {
      let current_tab_item = this.newtabitem.find(
        (f) => f.name == this.currentSelectTab
      );
      if (
        current_tab_item &&
        current_tab_item.sub_tab &&
        current_tab_item.sub_tab.length > 0
      ) {
        this.sub_tab = current_tab_item.sub_tab;

        if (this.selected_sub_tab === undefined) {
          this.selected_sub_tab = current_tab_item.sub_tab[0];
          this.subTabIndex = 0
        }
      }
    }
    /** Alex Timbal Following modifies table tr tab with new attributes */
    if (this.callTrigger) {
      this.setSetFilterActive(this.callTrigger);
    }
    var _ = jQuery;
    var ds = this;
    var __ = `${el}`;
    if (this.currentSelectTab == this.filterTabitem) {
      _(__ + " td div.classTop p:even").css({
        "border-bottom": "1px solid #eee",
      }),
        _(__ + " td.schedule p").css({
          // "margin-left": "15px",
          "margin-left": "8px",
        }),
        _(__ + " td.pickupon p").css({
          // "padding-left": "16px",
          "padding-left": "8px",
        }),
        _(__ + " td.deliveryon p").css({
          // "padding-left": "16px",
          "padding-left": "8px",
        }),
        _(__ + " td.statuson p").css({
          // "padding-left": "12px",
          "padding-left": "8px",
        }),
        _(__ + " td.schedule").css("padding-left", "0px !important"),
        _(".v-data-table__wrapper table td div.classTop p:last-child").css({
          "border-bottom": "0px solid #eee",
        }),
        {},
        _(document)
          .find(
            ".v-data-table__wrapper table tr td.container_customer .classTop"
          )
          .each(function () {
            let nextClass = _(this).children().first(),
              id = nextClass.attr("id"),
              count = nextClass.attr("data-id"),
              numbertype = nextClass.attr(ds.numberAssignType);
            if (typeof count != "undefined") {
              if (count > 0) {
                _(this).closest("tr").addClass(id),
                  _(this).closest("tr").attr("data-count", count),
                  _(this)
                    .closest("tr")
                    .attr("data-numberassigntype", numbertype);
                if (numbertype > 1) {
                  _(this).closest("tr").addClass(ds.hastwotypes);
                }
              }
            }
          }),
        {},
        _(document)
          .find(".v-data-table__wrapper table tr td .classTop p:last-child")
          .each(function () {
            _(this).css({
              "margin-bottom": "0px",
            });
            _(this).addClass("AddlastElementChild");
          });
    }
    this.updatedni = 1;
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/pages_scss/containers/containersTable.scss";
@import "../../../assets/scss/pages_scss/dialog/globalDialog.scss";
@import "../../../assets/scss/pages_scss/containers/containers.scss";
@import "../../../assets/scss/buttons.scss";
@import "../../../assets/scss/vc_calendar.scss";

.conTabledisplay {
  .container-sub-tabs {
    .v-tabs-bar {
      height: 36px;
    }

    .v-tabs-slider-wrapper {
      display: none;
    }

    .v-tab {
      text-transform: capitalize !important;
      letter-spacing: 0 !important;
      padding: 0 12px;
      font-weight: unset !important;
      min-width: 50px !important;
      border: 1px solid #b4cfe0 !important;
      border-right: none;

      span {
        color: #4a4a4a;
        font-family: 'Inter-Medium', sans-serif;
        font-size: 12px !important;

        &.tab-count-value {
          padding: 3px 3px 4px;
          background-color: #819FB2;
          color: #fff;
          border-radius: 50px;
          min-width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 5px;

          span {
            font-size: 11px !important;
          }
        }
      }

      &.v-tab--active {
        background-color: #EBF2F5;
        border-bottom: 1px solid #b4cfe0 !important;

        span {
          color: #0171A1;
          font-family: 'Inter-Medium', sans-serif;

          &.tab-count-value {
            background-color: #0171A1;
            color: #fff;
          }
        }
      }

      &:nth-child(2) {
        border-right: none !important;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-left: none !important;
        border-right: 1px solid #b4cfe0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .classsort {
    background: url("../../../assets/icons/Icon_sort.svg") no-repeat !important;
    margin-left: 4px;
    width: 21px;
    height: 17px;
    display: block;
    position: relative;
    top: 3px;
    left: 1px;
    float: right;
  }
}

.v-tooltip__content {
  &.terminal-at-tooltip {
    background-color: #4a4a4a;
    max-width: 285px;
    padding: 8px 16px;

    // zoom: 0.80;
    p {
      font-size: 12px;
    }
  }
}

.containers-inner-wrapper .v-data-table.containers-table .v-data-table__wrapper table thead tr th span {
  font-size: 14px !important;
  // font-size: 11px !important;
}

.v-list.customer-list-filter-dropdown {
  height: 380px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d3d3d3;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #d3d3d3;
  }
}

.DRIVER_ASSIGN,
.DRIVER_unASSIGN {
  padding: 0;
}

.DRIVER_ASSIGN {
  color: #0171a1;
}

.DRIVER_unASSIGN {
  color: #6d858f;
  font-size: 14px;
}

.v-data-table__wrapper table .tr td.schedule p {
  margin-left: 17px;
}

.greyColor,
.totype {
  color: #6d858f;
}

.commonP {
  border-bottom: 1px solid #eeeeee;
  margin-top: 0 !important;
  padding-bottom: 2px !important;
}

td.statuson div p span {
  position: relative;
  // top:calc(27% + 0%);
}

.hasDriverLastElement {
  border: 0 !important;
}

.referrence div.classTop #container {
  color: #0171a1;
  font-weight: 600;
  position: relative;
  // top: 12px;
}

.referrenceN div.classTop {
  color: #0171a1;
  font-weight: 600;
  position: relative;
  // top: 4px;
}

.v-data-table__wrapper table td p {
  padding-bottom: 0 !important;
}

.bg-redish {
  background: #fff2f2;
}

.bg-yellowish {
  background: #fff9f0;
}

.text-red {
  color: #f93131;
}

.text-yellow {
  color: #d68a1a;
}

.text-dark-gray {
  color: #6d858f;
}

.buttonfilter {
  border-radius: 4px;
  width: 80px;
  height: 40px;
  border: 1px solid #0171a1;
}

.imgtop {
  position: relative;
  top: 2px;
}

.showdropdown {
  position: absolute !important;
  height: 123px;
  background: #fff;
  left: px;
  right: 0;
  margin-top: 2px;
  border-radius: 4px;
  box-shadow: -1px 2px 2px 0 #eee;
  padding: 16px;
  border: 1px solid #eee;
}

.showResultdriver {
  color: #819fb2;
  font-weight: 600;
  font-size: 10px;
}

.driverLabel {
  font-size: 10px;
  line-height: 14px;
  color: #819fb2;
}

.filterButtonApply {
  height: 30px !important;
  width: 49px !important;
  background: #0171a1 !important;
  font-size: 10px !important;
}

.filterButtonCancel {
  height: 30px !important;
  width: 49px !important;
  font-size: 10px !important;
}

.cardDropdown .v-list-item {
  width: 303px !important;
}

.Suncastle {
  display: block;
  border: 1px solid #eee;
  text-align: center;
  padding: 1px;
  border-radius: 15px;
  padding-bottom: 3px;

  display: inline-block;
  position: relative;
  padding-right: 27px;
  padding-left: 9px;
  padding-top: 1px;
  margin-right: 4px;
  margin-bottom: 5px;
}

.closeXbutton {
  position: absolute;
  font-size: 11px;
  right: 10px;
  top: 8.1px;
}

.driverNameTtitle {
  font-size: 12px !important;
}

.cardDropdown .v-input__slot {
  border: 1px solid #eee;
  border-radius: 4px;
}

.cardDropdown .v-select__slot {
  padding-left: 8px;
  padding-right: 6px;
  font-size: 12px;
}

.cardDropdown .v-select__slot .v-icon {
  position: relative;
  top: 3px;
}

.cardDropdown .v-select--is-menu-active {
  background: transparent !important;
}

.v-select--is-menu-active {
  background: transparent !important;
}

.v-input__control>.v-input__slot::before {
  border-color: transparent !important;
}

.initialDropdown {
  margin-top: 8px;
}

.initialDropdown .v-input__slot {
  border: 1px solid #b4cfe0;
  font-size: 14px;
  padding-left: 10px;
  border-radius: 3px;
}

.initialDropdown .v-input__append-inner {
  position: relative;
  top: 3px;
  left: -3px;
}

.initialDropdown .v-input,
.initialDropdown .v-input__control,
.initialDropdown .v-input__slot {
  height: 48px;
}

.filterShowtag {
  background: #fff !important;
  height: 48px !important;
}

.filterShowtag .v-toolbar__content {
  height: 48px !important;
  font-size: 12px;
  background: #fff !important;
}

.wrapno-matching {
  padding-top: 70px;
  display: inline-block;
  padding-bottom: 66px;
}

.nomatching {
  font-size: 20px;
  font-weight: 600;
  padding-top: 30px;
}

.pickup_on {
  // margin-bottom: 15px !important;
}

.buttonCardActions {
  padding-left: 18px;
  padding-bottom: 18px;
  padding-top: 18px;
}

.aside {
  color: #ccc;
}

.my-checkbox {
  position: relative !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 5px;
  box-sizing: content-box;
  overflow: hidden;
  zoom: .9;
}

.my-checkbox:before {
  content: "";
  display: block;
  box-sizing: content-box;
  width: 16px;
  height: 16px;
  border: 1px solid #B4CFE0;
  transition: 0.2s border-color ease;
}

.my-checkbox:checked:before {
  border-color: #0171a1;
  transition: 0.5s border-color ease;
}

.my-checkbox:disabled:before {
  border-color: #ccc;
  background-color: #ccc;
}

.my-checkbox:after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: content-box;
  top: 50%;
  left: 50%;
  transform-origin: 50% 50%;
  background-color: #0171a1;
  width: 16px;
  height: 16px;
  border-radius: 100vh;
  transform: translate(-50%, -50%) scale(0);
}

.my-checkbox[type="checkbox"]:before {
  border-radius: 5px;
}

.my-checkbox[type="checkbox"]:after {
  width: 9.6px;
  height: 16px;
  border-radius: 0;
  transform: translate(-50%, -85%) scale(0) rotate(45deg);
  background-color: transparent;
  box-shadow: 4px 4px 0 0 #0171a1;
}

.my-checkbox[type="checkbox"]:checked:after {
  -webkit-animation: toggleOnCheckbox 0.2s ease forwards;
  animation: toggleOnCheckbox 0.2s ease forwards;
}

.my-checkbox[type="checkbox"].filled:before {
  border-radius: 4px;
  transition: 0.2s border-color ease, 0.2s background-color ease;
}

.my-checkbox[type="checkbox"].filled:checked:not(:disabled):before {
  background-color: #0171a1;
}

.my-checkbox[type="checkbox"].filled:not(:disabled):after {
  box-shadow: 4px 4px 0 0 white;
}

.my-checkbox2 label {
  font-size: 12px !important;
}

.my-checkbox2 {
  margin-top: 0px !important;
  padding-top: 0px !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.my-checkbox2 .v-messages {
  height: 0px !important;
  line-height: 0px !important;
  max-height: 0px !important;
  min-height: 0px;
}

.my-checkbox2 .v-input__slot {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.v-list-item {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 32px;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
}

.v-menu__content .v-list-item .v-list-item__title {
  font-size: 11px !important;
  color: #4A4A4A;
}

@-webkit-keyframes toggleOnCheckbox {
  0% {
    opacity: 0;
    transform: translate(-50%, -85%) scale(0) rotate(45deg);
  }

  70% {
    opacity: 1;
    transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
  }

  100% {
    transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
  }
}

@keyframes toggleOnCheckbox {
  0% {
    opacity: 0;
    transform: translate(-50%, -85%) scale(0) rotate(45deg);
  }

  70% {
    opacity: 1;
    transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
  }

  100% {
    transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
  }
}

/* (1366x768) WXGA Display */
// @media  screen and (max-width: 1366px) {
//     .conTabledisplay table tbody tr td {
//       height: 169px !important;
//     }
// }

.more_btn_sm-custom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 8px !important;
  gap: 4px;
  width: 30px;
  height: 28px !important;
  background: #FFFFFF;
  border: 1px solid #B4CFE0;
  border-radius: 4px;
  min-width: unset !important;

  img {
    width: 15px;
    height: 15px;
  }
}

.v-application .d-show {
  display: initial !important;
}

.date-edit-btn {
  padding: 11px 8px;
  ;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #0171A1;
}

.date-edit-btn:hover {
  padding: 11px 8px;
  ;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #FFFFFF;
  background: #0171A1;
  border-radius: 99px;
  cursor: pointer;
}

.shifl-color {
  color: #819FB2 !important;
}

.gen-color {
  color: #0171A1 !important;
}

// div.v-input__slot {
//     min-height: 40px !important;
//     max-height: 40px !important;
//     width: 100%;
// }
div[role="combobox"] {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 13px;
  background: rgb(255, 255, 255);
  border: 1px solid #B4CFE0 !important;
  border-radius: 4px;
  font-size: 14px;
  box-shadow: none !important;
}

.conTabledisplay .v-slide-group__content .v-tab--active {
  border-bottom: 3px solid #0171A1 !important;
}

.conTabledisplay .v-slide-group__content .v-tabs-slider-wrapper {
  height: 0px !important;
}

.menu-border {
  border-bottom: 1px solid #7777;
}

.editDriver {
  display: none;
}

tr:hover>td>div.classTop>p>span>span.editDriver {
  display: initial;
  position: absolute;
}

.hover-item:hover {
  background: #F7F7F7;
  cursor: pointer;
}

.minHeightDriverDropdown:hover {
  background-color: #F6F6F6;
}

.menuDriverdropdown::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

.menuDriverdropdown::-webkit-scrollbar-thumb:hover {
  background: #d3d3d3;
}

.menuDriverdropdown .v-list {
  padding-top: 0px !important;
}

.menuDriverdropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
}
</style>
