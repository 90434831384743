<template>
  <div class="text-center">
    <v-dialog
      persistent
      v-model="dialogEdit"
      max-width="590px"
      content-class="add-supplier-dialog add-driver-dialog  dialogYard"
      :retain-focus="false"
    >
      <v-card class="add-supplier-card">
        <v-form ref="form" @submit.prevent>
          <v-card-title style="caret-color: transparent">
            <span class="headline">Edit Company Information</span>

            <button icon dark class="btn-close" @click="close">
              <v-icon>mdi-close</v-icon>
            </button>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-row class="edit-profile-Information">
              <v-col cols="2" class="mb-3 mr-4">
                <div
                  class="image"
                  :style="
                    getImgUrl(getCompanyProfile.imageUrlProfile) ==
                    companyIcon
                      ? 'background:#EBF2F5'
                      : ''
                  "
                >
                  <img
                    :src="companyLogo"
                    alt="no"
                    :height="
                      getImgUrl(getCompanyProfile.imageUrlProfile) ==
                      companyIcon
                        ? '50px'
                        : '80px'
                    "
                    :width="
                      getImgUrl(getCompanyProfile.imageUrlProfile) ==
                      companyIcon
                        ? '50px'
                        : '80px'
                    "
                  />
                </div>
              </v-col>

              <v-col cols="9" class="mb-3">
                <div class="upload-logo">
                  <v-btn
                    @click="addLogo"
                    style="font-size: 12px; font-weight: 500"
                    class="btn-white custom-btn"
                  >
                    <span
                      v-if="
                        getImgUrl(getCompanyProfile.imageUrlProfile) ==
                        companyIcon
                      "
                      >Upload Logo</span
                    >
                    <span v-else>Change Logo</span>
                  </v-btn>
                  <p
                    class="mb-0 mt-2"
                    style="color: #B4CFE0;!important;font-style:italic;font-size:12px"
                  >
                    Maximum file size is 5MB. File formats PNG or JPG.
                  </p>
                  <p
                    class="mb-0"
                    style="color: #819FB2;!important;font-size:12px"
                  >
                    (This logo will be used to your title emails and various
                    documents)
                  </p>
                  <input
                    ref="upload_logo_reference"
                    type="file"
                    id="upload_logo_import"
                    @change="(e) => inputChanged(e)"
                    name="documents[]"
                    accept=".png,.jpg,.jpeg"
                    multiple
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                style="color: red"
                v-if="errorFound"
                cols="12"
                sm="12"
                md="12"
                class="pb-3 pt-1"
              >
                {{ errorMessage }}
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-3 pt-1">
                <label class="text-item-label">COMPANY Name</label>
                <v-text-field
                  outlined
                  dense
                  :rules="inputRules"
                  v-model="getCompanyProfile.name"
                  class="text-fields DialogInputfield"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-3 pt-1">
                <label class="text-item-label">Email Address</label>
                <v-text-field
                  outlined
                  dense
                  :rules="inputRules"
                  v-model="getCompanyProfile.general_email"
                  class="text-fields DialogInputfield"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-3 pt-1">
                <label class="text-item-label"
                  >Delivery Order Email Recipients</label
                >
                <vue-tags-input
                  hide-details="auto"
                  allow-edit-tags
                  :rules="arrayNotEmptyRules"
                  :tags="deliveryOrderTag"
                  :style="deliveryOrderToggle ? '' : 'background-color:#EBF2F5'"
                  style="min-width: 100% !important"
                  :add-on-blur="true"
                  :add-on-key="[13, ',']"
                  :validation="tagsValidation"
                  v-model="deliveryOrderEmail"
                  placeholder="Separate multiple email addresses with comma"
                  @tags-changed="
                    (newTags) => {
                      this.deliveryOrderTag = newTags;
                      this.tagsInput.touched = true;
                      this.tagsInput.hasError =
                        this.deliveryOrderTag.length > 0 ? false : true;
                      let el =
                        this.documentProto.getElementsByClassName(
                          'ti-input'
                        )[0];
                      if (typeof el !== 'undifined') {
                        if (this.tagsInput.hasError)
                          el.classList.add('ti-new-tag-input-error');
                        else el.classList.remove('ti-new-tag-input-error');
                      }
                    }
                  "
                />
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-3 pt-1">
                <label class="text-item-label">Dot Number</label>
                <v-text-field
                  outlined
                  dense
                  :rules="numberRules"
                  v-model="getCompanyProfile.dot_number"
                  class="text-fields DialogInputfield"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-3 pt-1">
                <label class="text-item-label">Mc Number</label>
                <v-text-field
                  outlined
                  dense
                  :rules="numberRules"
                  v-model="getCompanyProfile.mc_number"
                  class="text-fields DialogInputfield"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="12" class="pb-3 pt-1">
                <label class="text-item-label">Time Zone</label>

                <v-autocomplete
                  v-model="getCompanyProfile.timezone"
                  class="text-fields DialogInputfield"
                  :items="timeZone"
                  item-text="label"
                  item-value="value"
                  placeholder="Select Time Zone"
                  solo
                  append-icon="ic-chevron-down"
                  dense
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="12" md="12" class="pb-3 pt-0">
                <label class="text-item-label">Phone Number</label>

                <vue-tel-input
                  v-model="getCompanyProfile.phone_number"
                  mode="international"
                  defaultCountry="us"
                  validCharactersOnly
                  :autoDefaultCountry="true"
                  :dropdownOptions="vueTelDropdownOptions"
                  :inputOptions="vueTelInputOptions"
                >
                  <template v-slot:arrow-icon>
                    <v-icon class="ml-1">mdi-chevron-down</v-icon>
                  </template>
                </vue-tel-input>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-3 pt-1">
                <label class="text-item-label">Address</label>

                <v-textarea
                  class="text-fields DialogInputfield"
                  outlined
                  placeholder="7887 Harber Walks"
                  dense
                  v-model="getCompanyProfile.street_address"
                  hide-details="auto"
                  rows="3"
                  row-height="15"
                >
                </v-textarea>
                <!-- <v-textarea
                  outlined
                  v-model="getCompanyProfile.street_address"
                  :rules="inputRules"
                  class="DialogInputfield"
                  name="address"
                  hide-details="auto"
                  rows="2"
                >
                </v-textarea> -->
              </v-col>
              <v-col cols="6" sm="6" md="6" class="pb-3 pt-1">
                <label class="text-item-label">Country</label>
                <v-text-field
                  outlined
                  dense
                  :rules="inputRules"
                  v-model="getCompanyProfile.county"
                  class="text-fields DialogInputfield"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="pb-3 pt-1">
                <label class="text-item-label">State</label>
                <v-text-field
                  outlined
                  dense
                  :rules="inputRules"
                  v-model="getCompanyProfile.state"
                  class="text-fields DialogInputfield"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="pb-3 pt-1">
                <label class="text-item-label">City</label>
                <v-text-field
                  outlined
                  dense
                  :rules="inputRules"
                  v-model="getCompanyProfile.city"
                  class="text-fields DialogInputfield"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="pb-3 pt-1">
                <label class="text-item-label">Zip Code</label>
                <v-text-field
                  outlined
                  dense
                  :rules="inputRules"
                  v-model="getCompanyProfile.zip_code"
                  class="text-fields DialogInputfield"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions
            style="
              padding-left: 11px;
              margin-top: 5px;
              border-top: 3px solid #ebf2f5;
            "
          >
            <!-- <v-col class="text-left buttonActions" style="padding-right: 0px">
              <v-btn @click="updateCompanyProfileApi" :disabled="isSaving" class="btn-blue" text>
                <span class="btn-color">
                  <span color="#fff">
                    <span>
                      <v-progress-circular
                        v-if="isSaving"
                        :size="15"
                        color="#fff"
                        :width="2"
                        indeterminate
                        style="margin-right: 3px"
                      >
                      </v-progress-circular>
                      Update Profile
                    </span>
                  </span>
                </span>
              </v-btn>

              <v-btn class="btn-white" text @click="close"> Cancel </v-btn>
            </v-col> -->
            <div
              class="buttonActions d-flex justify-start align-center pr-0 px-3"
              style="width: 100%"
            >
              <v-btn
                @click="updateCompanyProfileApi"
                :disabled="isSaving"
                class="btn-blue"
                text
              >
                <span class="btn-color">
                  <span color="#fff">
                    <span>
                      <v-progress-circular
                        v-if="isSaving"
                        :size="15"
                        color="#fff"
                        :width="2"
                        indeterminate
                        style="margin-right: 3px"
                      >
                      </v-progress-circular>
                      Update Profile
                    </span>
                  </span>
                </span>
              </v-btn>
              <v-btn class="btn-white" text @click="close"> Cancel </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
// import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
import VueTagsInput from "@johmun/vue-tags-input";
import axios from "axios";
import globalMethods from "../../../../../utils/globalMethods";
import { globalTimeZone } from "./TimeZone";
import companyIcon from "../../../../../assets/icons/import-name-logo.svg"

const APIBaseUrl = process.env.VUE_APP_PO_URL;

export default {
  components: {
    // VueTelInputVuetify,
    VueTagsInput,
    VueTelInput,
  },
  props: ["dialogEdit"],
  data: () => {
    return {
      companyLogo: companyIcon,
      loadingLocationPlaces: false,
      timeZone: globalTimeZone,
      errorFound: 0,
      addressChanged: false,
      places: [],
      errorMessage: "",
      errorContent: "",
      errorSaving: 0,
      searchPlace: "",
      valid: true,
      inputRules: [
        (v) => !!v || "This field is required",
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
      numberRules: [
        (v) => !!v || "Input is required.",
        (v) => /^(?=.*[0-9])[- +()0-9]+$/.test(v) || "Letters are not allowed.",
      ],
      telProps: {
        mode: "international",
        defaultCountry: "US",
        placeholder: "Type phone number",
        label: "Type phone number",
        autocomplete: "off",
        maxLen: 25,
        preferredCountries: ["US", "CN"],
        enabledCountryCode: true,
        dropdownOptions: {
          showDialCodeInSelection: true,
          showFlags: true,
        },
        validCharactersOnly: true,
      },
      arrayNotEmptyRules: [
        (v) => !!v || "Email is required",
        () =>
          this.deliveryOrderTag.length > 0 ||
          "Make sure to supply at least 1 email.",
      ],
      tagsValidation: [
        {
          classes: "t-new-tag-input-text-error",
          rule: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
          disableAdd: true,
        },
      ],
      deliveryOrderNotification: {
        emails: [],
      },
      deliveryOrderToggle: true,
      deliveryOrderTag: [],
      deliveryOrderEmail: "",
      tagsInput: {
        touched: false,
        hasError: false,
        errorMessage:
          'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.',
        //errorMessage: ''
      },
      documentProto: document,
      isSaving: false,
      files: [],
      showImage: null,
      vueTelDropdownOptions: {
        showDialCodeInSelection: true,
        showDialCodeInList: true,
        showFlags: true,
        showSearchBox: true,
      },
      vueTelInputOptions: {
        autocomplete: false,
        placeholder: "Type phone number",
        styleClasses: "tel-input-class",
        required: true,
      },
    };
  },
  computed: {
    ...mapGetters("profile", ["getLoading", "getCompanyProfile"]),
  },
  watch: {
    dialogEdit(c) {
      if (!c || c) {
        this.errorFound = 0;
        this.getCompanyProfileAsync();
      }
    },

    getdetails(newValue) {
      if (newValue.status === 1) {
        this.$emit("close");
      }
    },
    searchPlace(val) {
      if (val) {
        this.getDeliveryLocation(val);
      }
    },
    getCompanyProfile(v) {
      this.fetchCompanyLogo(v.logo);
      if (v.do_email_recipients) {
        let doEmail = JSON.parse(v.do_email_recipients);
        if (doEmail.emails && doEmail.emails.length > 0) {
          let em = [];
          doEmail.emails.map((email) => {
            em.push({
              text: email,
              tiClasses: ["ti-valid"],
            });
          });
          this.deliveryOrderTag = em;
          this.deliveryOrderNotification.emails = doEmail.emails;
        }
      }
    },
  },
  methods: {
    ...mapActions("profile", [
      "updateCompanyApiRequest",
      "getCompanyProfileAsync",
    ]),
    ...globalMethods,
    close() {
      this.files = [];
      this.showImage = null;
      this.$emit("close");
    },
    async getDeliveryLocation(val) {
      if (val.length > 4) {
        this.loadingLocationPlaces = true;

        await fetch(
          `${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/` +
            val +
            `.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}`
        )
          .then((response) => response.json())
          .then((json) => {
            this.loadingLocationPlaces = false;
            const customInput = [
              {
                place_name: val,
                id: Date.now(),
              },
            ];
            this.places = [...json.features, ...customInput];
          })
          .catch((e) => {
            this.loadingLocationPlaces = false;

            const customInput = [
              {
                place_name: val,
                id: Date.now(),
              },
            ];
            this.places = customInput;
            console.log(e);
          });
      }
    },

    async fetchCompanyLogo(logo) {
      const apiUrl = `${APIBaseUrl}/get-company-profile`;
      const requestBody = {
        logo: logo,
      };
      try {
        axios
          .post(apiUrl, requestBody, { responseType: "arraybuffer" })
          .then((response) => {
            const imageBlob = new Blob([response.data], { type: "image/jpeg" });
            let imageUrl = URL.createObjectURL(imageBlob);
            this.companyLogo = imageUrl;
          });
      } catch (error) {
        console.error("Error fetching company logo:", error);
      }
    },

    getImgUrl(pic) {
      //get image url directory from po online
      let imageUrl = "https://";

      //if pic is not null and defined
      if (this.files.length && this.showImage !== null) {
        return this.showImage;
      } else {
        if (typeof pic !== "undefined" && pic !== null) {
          // if(this.getCompanyProfile.imageUrlProfile !== null){
          //     return this.getCompanyProfile.imageUrlProfile
          // }
          if (
            pic.includes(imageUrl) !== "undefined" &&
            !pic.includes(imageUrl)
          ) {
            //concatonate the imageurl with the pic
            let newImage = `${imageUrl}${pic}`;
            return newImage;
          } else return pic;
        }
        // else if(this.getCompanyProfile.imageUrlProfile !== null){
        //     return this.getCompanyProfile.imageUrlProfile
        // }
        else return companyIcon;
      }
    },
    inputChanged(e) {
      const fileSize = e.target.files[0].size / 1024 / 1024;
      const fileTypeCheck = e.target.files[0].type;
      if (fileSize > 5)
        return this.notificationError("Maximum file size exceed");
      else if (
        fileTypeCheck !== "image/png" &&
        fileTypeCheck !== "image/jpg" &&
        fileTypeCheck !== "image/jpeg"
      )
        return this.notificationError("Acceptable File formats are PNG & JPG");
      const preview = e.target.files[0];
      this.showImage = URL.createObjectURL(preview);
      this.companyLogo = this.showImage;
      let files = this.$refs.upload_logo_reference.files;

      if (!files.length) {
        return false;
      }

      for (let i = 0; i < files.length; i++) {
        this.files = files;
      }
    },
    addLogo() {
      this.$refs.upload_logo_reference.click();
    },
    clearSearchPlaces() {
      this.places = [];
      this.selectedPlace = "";
    },

    // TODO: Form validation before submission.
    /* eslint-disable */
    async updateCompanyProfileApi() {
      if (!this.$refs.form.validate()) return;
      this.isSaving = true;
      if (this.$refs.form.validate()) {
        let deliveryOrderNotifEmails = [];
        if (this.deliveryOrderTag.length > 0) {
          this.deliveryOrderTag.map(({ text }) => {
            deliveryOrderNotifEmails.push(text);
          });
        } else {
          this.deliveryOrderToggle = false;
        }
        let deliveryOrderNotifValue = {
          emails: deliveryOrderNotifEmails,
        };

        this.getCompanyProfile.do_email_recipients = JSON.stringify(
          deliveryOrderNotifValue
        );
        // if (this.files.length && this.showImage !== null) {
        //   let formData = new FormData()
        //   formData.append("logo", this.files[0])
        //   formData.append("name", this.getCompanyProfile.name)
        //   formData.append("_method", 'PUT')
        //   try {
        //     await axios.post(`${APIBaseUrl}/update-company-profile/${this.getCompanyProfile.id}`, formData)
        //   } catch (e) {
        //     console.log(e)
        //     this.notificationError(e)
        //     return
        //   }
        // }
        // let sendData = this.getCompanyProfile
        let payload = Object.assign({}, this.getCompanyProfile);
        let sendData = null;
        if (this.files.length && this.showImage !== null) {
          let { imageUrlProfile, ...others } = payload;

          sendData = others;
        } else {
          sendData = payload;
        }
        // let formDataSend = new FormData()
        // if(this.files.length && this.showImage !== null){
        //   let {imageUrlProfile,logo,do_email_recipients,...others} = payload
        //   for(const key in others){
        //     formDataSend.append(key,others[key])
        //   }
        //   formDataSend.append("logo",this.files[0])
        //   formDataSend.append("do_email_recipients",JSON.stringify(
        //     deliveryOrderNotifValue
        // ))
        // }else{
        //   let {do_email_recipients,...others} = payload
        //   for(const key in others){
        //     formDataSend.append(key,others[key])
        //   }
        //   formDataSend.append("do_email_recipients",JSON.stringify(
        //     deliveryOrderNotifValue
        // ))
        // }
        let formData = new FormData();

        Object.keys(sendData).forEach(function (key) {
          formData.append(key, sendData[key]);
        });

        if (this.files.length && this.showImage !== null) {
          console.log(this.files[0]);
          formData.append("logo", this.files[0]);
          formData.append("name", this.getCompanyProfile.name);
        }

        formData.append("_method", "PUT");

        axios
          .post(
            `${APIBaseUrl}/update-company-profile/${this.getCompanyProfile.id}`,
            formData
          )
          .then((res) => {
            if (res.status === 200) {
              this.getCompanyProfileAsync();
              //this.dispatchNotifcation(res.data.message);
              this.notificationMessageCustomSuccess("Company record updated");
              this.showImage = null;
              this.files = [];
              this.$emit("close");
            }
            this.isSaving = false;
          })
          .catch((err) => {
            this.dispatchNotifcationError(err.message);
            this.isSaving = false;
          });
      }
    },
    /* eslint-enable */
  },
};
</script>

<style lang="scss">
@import "../../../../../assets/scss/pages_scss/dialog/globalDialog.scss";
@import "../../../../../assets/scss/pages_scss/drivers/driverAddDialog.scss";

.v-text-field--outlined >>> fieldset {
  border-color: #b4cfe0;
  border-width: 1px;
}

.edit-profile-Information {
  .image {
    width: 80px;
    height: 80px;
    border: 1px solid #b4cfe0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: "blue";
  }

  .upload-logo {
    #upload_logo_import {
      display: none;
    }

    .custom-btn {
      padding: 6px 8px 6px 8px !important;
      height: 30px !important;
      min-height: 30px !important;
    }
  }
}

.custom-autocomplete .v-input__slot {
  padding: 0 12px;
  height: 45px;
  border: 1px solid #b4cfe0 !important;
  border-radius: 3px;
}

.custom-autocomplete-for-time-zone .v-input__slot {
  padding: 0 12px;
  height: 45px !important;
  border: 1px solid #b4cfe0 !important;
  border-radius: 3px;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border: none !important;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot::after {
  border: none !important;
}

// TEXT-FIELD CODE STYLE

div.text-fields > div.v-input__control > div.v-input__control {
  height: 45px;
  background: white;
  font-size: 14px;
}

div.text-fields > div.v-input__control > div.v-input__slot {
  min-height: 45px !important;
  max-height: 45px !important;
  width: 100%;
}

div.text-fields > div.v-input__control > div[role="combobox"] {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 13px;
  background: rgb(255, 255, 255);
  border: 1px solid #b4cfe0 !important;
  border-radius: 4px;
  font-size: 14px;
  box-shadow: none !important;
  margin: auto;
}
</style>
