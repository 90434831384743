<template>
	<div class="supplier-container" v-resize="onResize">	
		<DriverDesktopTable
			:items="suppliers"
			@addDriver="addDriver"
			@editDriver="editDriver"
			@editPassdata="editPassdata"
			@deleteSupplier="deleteSupplier"
			@updatelisting="updatelisting"
			:itemData.sync="theItem"
			:isdeleted.sync="isdeleted"
			:isMobile="isMobile"
			:reloadcomponent="reloadcomponent"
			v-if="renderComponent"
		/>
	</div>
</template>

<script>
import {
	mapActions,
	mapGetters
}
from 'vuex'
import DriverDesktopTable from '../components/Tables/Drivers/DriverDetailsTable.vue'
//import Vue from "vue";
export default {
	name: "Drivers",
	components: {	
		DriverDesktopTable,		
	},
	data: () => ({
		bus: Event,
		renderadddialog: true,
		renderComponent: true,
		tellparent: 0,
		isdeleted: 0,
		notifyupdate: 0,
		theItem: 0,
		currentEditrow: '',
		updatelisting: [],
		isaddeddriver: false,
		dialogEdit: false,
		isRender: 0,
		reloadcomponent: 0,
		page: 1,
		pageCount: 0,
		itemsPerPage: 35,
		dialog: false,
		dialogDelete: false,
		headers: [
			{
				text: "Name",
				align: "start",
				sortable: false,
				value: "company_name",
				width: "25%",
				fixed: true
            },
			{
				text: "Phone",
				align: "start",
				value: "phone",
				sortable: false,
				width: "20%",
				fixed: true
            },
			{
				text: "Address",
				align: "start",
				value: "address",
				sortable: false,
				width: "25%",
				fixed: true
            },
			{
				text: "Email",
				align: "start",
				value: "emails",
				sortable: false,
				width: "20%",
				fixed: true
            },
			{
				text: "",
				align: "center",
				value: "actions",
				sortable: false,
				width: "10%",
				fixed: true
            },
        ],
		editedIndex: -1,
		editedItem: {
			company_name: "",
			phone_number: "",
			address: "",
			emails: null
		},
		defaultItem: {
			company_name: "",
			phone_number: "",
			address: "",
			emails: null
		},
		search: '',
		isMobile: false
	}),
	computed: {
		...mapGetters({
			getDrivers: 'drivers/getDrivers',
		}),
		suppliers() {
			let array = [];
			var response = this.getDrivers;
			// object reading server response changed
			if(Object.keys(response).length !== 0) {
				response.forEach((v) => {
					let m = {
						driver_id: v.id,
						name: v.name,
						last_name: v.last_name || '',
						phone_number: v.phone_number == null ? '' : v.phone_number,
						emails: v.email,
						trucker_id: response.trucker_id
					}
					array.push(m)
				});
			}
			return array
		}
	},
	watch: {
		dialog(val) {
			val || this.close();
			this.renderadddialog = false;
			this.$nextTick(() => {
				this.renderadddialog = true;
			});
		},
		dialogEdit(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.close();
		},
		isdeleted(v) {
			if(v) {
				this.reloadcomponent = 1;
				this.isdeleted = 0;
				this.renderCall(v)
			}
		},
		tellparent(v) {
			if(v) {
				this.reloadcomponent = 1;
				this.msgcontent = 'New driver has been added.'
				this.isaddeddriver = true;
				setTimeout(async () => {
					this.isaddeddriver = false;
				}, 5000)
				this.tellparent = 0;
				this.renderCall(v)
			}
		},
		notifyupdate(v) {
			this.isRender = 1;
			this.msgcontent = 'Driver has beed edited!';
			this.isaddeddriver = true;
			setTimeout(async () => {
				this.isaddeddriver = false;
			}, 5000)
			this.notifyupdate = 0;
			this.renderCall(v)
		},
		isRender(v) {
			if(v) this.reloadcomponent = v;
		}
	},
	created() {
		// this.bus.$on('call', (data) => {
		// 	if(data)
		// 	alert(data)
		// 	alert('naa diri')
		// })
	},
	methods: {
		...mapActions({
			fetchdrivers: 'drivers/fetchDrivers'
		}),
		addDriver() {
			this.dialog = true;
		},
		editDriver(item) {
			this.editedIndex = this.suppliers.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogEdit = true;
		},
		editPassdata(value) {
			this.currentEditrow = value
		},
		deleteSupplier(item) {
			this.editedIndex = this.suppliers.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},
		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},
		updatelistingf(v) {
			this.updatelisting = v
		},
		onResize() {
			if(window.innerWidth < 769) {
				this.isMobile = true
			}
			else {
				this.isMobile = false
			}
		},
		renderCall(c) {
			this.renderComponent = false;
			this.$nextTick(() => {
				this.renderComponent = true;
				this.theItem = c
			});
		},
		setToDefault() {
			this.editedItem = this.defaultItem
			this.close()
			this.dialog = true
		}
	},
	async mounted() {
		//set current page
		this.$store.dispatch("page/setPage", "suppliers");
		await this.fetchdrivers()
	},
	updated() {}
};
</script>

<style lang="scss">
@import '../assets/scss/pages_scss/drivers/driver.scss';
@import '../assets/scss/pages_scss/drivers/driver_css.scss';
@import '../assets/scss/buttons.scss';
@import '../assets/css/dialog_styles/dialogHeader.css';
@import '../assets/css/dialog_styles/dialogBody.css';
@import '../assets/css/dialog_styles/dialogFooter.css';
</style>
