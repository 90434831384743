<template>
    <div class="header-template-wrapper-customized">
        <div class="discharged-header" v-if="field === 'discharged'">
            <img src="@/assets/icons/discharge-icon-header.svg" alt="Discharged" />
        </div>

        <div class="released-header" v-if="field === 'released'">
            <img src="@/assets/icons/submit-doc.svg" alt="Released" />
        </div>

        <div class="gate-fees-header" v-if="field === 'gate-fees'">
            <img src="@/assets/icons/circle-outline-check.svg" alt="Gate Fees" />
        </div>
    </div>
</template>

<script>
export default {
    name: "HeaderTemplate",
    props: ["data", "field"],
    computed: {},
    methods: {}
}
</script>