<template>
  <div class="notification-setting">
    <v-divider color="#EBF2F5 "></v-divider>
    <div class="py-10 px-5" style="background-color: white">
      <p class="sub-heading">Driver Notification</p>

      <div class="item-main mt-4">
        <div class="item">
          <div>
            <p class="item-heading">Driver Accept Load</p>
          </div>
          <div class="item-right-section">
            <template>
              <v-checkbox
                class="notify-type mr-3"
                v-model="getCompanyProfile.email_sc_accepted"
                label="Email"
                @click="
                  showEmaiList(
                    'sc_accepted',
                    getCompanyProfile.email_sc_accepted
                  )
                "
              />
            </template>

            <div class="vertical-separtor" />
            <v-checkbox
              class="ml-3 notify-type"
              :input-value="getCompanyProfile.notifi_sc_accepted !== 'OFF'"
              label="Push Notification"
              @click="driverAcceptMenu()"
            />

            <v-select
              id="test"
              ref="mySelect"
              class="item-notif-settings"
              :items="stateData.driverAccNotif"
              v-model="driverAcceptNotif"
              :class="
                getCompanyProfile.notifi_sc_accepted !== 'OFF' ? 'active' : ''
              "
              :menu-props="{ contentClass: 'notifi-menu-list' }"
              append-icon="ic-chevron-down"
              @click="setDriverAcceptNotifTime"
              @change="updateDriverAccept"
            >
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item
                  class="d-flex justify-start"
                  style="border: none; min-height: 45px; height: 45px"
                  v-on="on"
                  v-bind="attrs"
                >
                  <div
                    v-if="item == 'Custom Time'"
                    @click="openCustomTimeDialog('sc_accepted')"
                  >
                    <p class="name font-regular" style="color: #4a4a4a">
                      {{ item }}
                    </p>
                  </div>
                  <div v-else>
                    <p class="name font-regular" style="color: #4a4a4a">
                      {{ item }}
                    </p>
                  </div>
                </v-list-item>
              </template>
            </v-select>
          </div>
        </div>
        <div
          class="item-description"
          v-if="getCompanyProfile.email_sc_accepted"
        >
          <p class="email-text">Send Email to:</p>
          <div
            class="email-descp"
            v-for="(item, index) in JSON.parse(
              getCompanyProfile.recip_sc_accepted
            )"
            :key="index"
          >
            <p>{{ item }}</p>
          </div>
          <button
            @click="
              showDailog(
                getCompanyProfile.recip_sc_accepted,
                'Driver Accept Load'
              )
            "
          >
            <p>Edit</p>
          </button>
        </div>
      </div>
      <div class="item-main mt-4">
        <div class="item">
          <div>
            <p class="item-heading">Driver Reject Load</p>
          </div>
          <div class="item-right-section">
            <v-checkbox
              class="notify-type mr-3"
              v-model="getCompanyProfile.email_sc_rejected"
              @click="
                showEmaiList('sc_rejected', getCompanyProfile.email_sc_rejected)
              "
              label="Email"
            ></v-checkbox>
            <div class="vertical-separtor" />
            <v-checkbox
              class="ml-3 notify-type"
              :input-value="getCompanyProfile.notifi_sc_rejected !== 'OFF'"
              label="Push Notification"
              @click="driverRejectMenu()"
            />
            <v-select
              ref="driverReject"
              class="item-notif-settings"
              v-model="driverRejectNotif"
              :items="stateData.driverRejectNotif"
              :class="
                getCompanyProfile.notifi_sc_rejected !== 'OFF' ? 'active' : ''
              "
              append-icon="ic-chevron-down"
              :menu-props="{ contentClass: 'notifi-menu-list' }"
              @click="setDriverRejectNotifTime"
              @change="updateDriverReject"
            >
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item
                  class="d-flex justify-start"
                  style="border: none; min-height: 45px; height: 45px"
                  v-on="on"
                  v-bind="attrs"
                >
                  <div
                    v-if="item == 'Custom Time'"
                    @click="openCustomTimeDialog('sc_rejected')"
                  >
                    <p class="name font-regular" style="color: #4a4a4a">
                      {{ item }}
                    </p>
                  </div>
                  <div v-else>
                    <p class="name font-regular" style="color: #4a4a4a">
                      {{ item }}
                    </p>
                  </div>
                </v-list-item>
              </template>
            </v-select>
          </div>
        </div>
        <div
          class="item-description"
          v-if="getCompanyProfile.email_sc_rejected"
        >
          <p class="email-text">Send Email to:</p>
          <div
            class="email-descp"
            v-for="(data, index) in JSON.parse(
              getCompanyProfile.recip_sc_rejected
            )"
            :key="index"
          >
            <p>{{ data }}</p>
          </div>
          <button
            @click="
              showDailog(
                getCompanyProfile.recip_sc_rejected,
                'Driver Reject Load'
              )
            "
          >
            <p>Edit</p>
          </button>
        </div>
      </div>
    </div>

    <!-- Customer Notification -->
    <div class="py-5 px-5" style="background-color: white">
      <p class="sub-heading">Customer Notification</p>

      <div class="item-main mt-4">
        <div class="item">
          <div>
            <p class="item-heading">Low Customer Activity</p>
          </div>
          <div class="item-right-section">
            <v-checkbox
              class="notify-type mr-3"
              v-model="getCompanyProfile.email_low_customer_activity"
              label="Email"
              @click="
                showEmaiList(
                  'low_customer_activity',
                  getCompanyProfile.email_low_customer_activity
                )
              "
            ></v-checkbox>
            <div class="vertical-separtor" />

            <v-checkbox
              class="ml-3 notify-type"
              :input-value="
                getCompanyProfile.notifi_low_customer_activity !== 'OFF'
              "
              label="Push Notification"
              @click="lowCustomerMenu()"
            />
            <v-select
              ref="customerActivity"
              class="item-notif-settings"
              v-model="lowCustomerNotif"
              :items="stateData.lowCustomer"
              :class="
                getCompanyProfile.notifi_low_customer_activity !== 'OFF'
                  ? 'active'
                  : ''
              "
              :menu-props="{ contentClass: 'notifi-menu-list' }"
              append-icon="ic-chevron-down"
              @click="setLowCustomerActivty"
              @change="updateLowCustomer"
            >
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item
                  class="d-flex justify-start"
                  style="border: none; min-height: 45px; height: 45px"
                  v-on="on"
                  v-bind="attrs"
                >
                  <div
                    v-if="item == 'Custom Time'"
                    @click="openCustomTimeDialog('low_customer')"
                  >
                    <p class="name font-regular" style="color: #4a4a4a">
                      {{ item }}
                    </p>
                  </div>
                  <div v-else>
                    <p class="name font-regular" style="color: #4a4a4a">
                      {{ item }}
                    </p>
                  </div>
                </v-list-item>
              </template>
            </v-select>
          </div>
        </div>
        <div
          class="item-description"
          v-if="getCompanyProfile.email_low_customer_activity"
        >
          <p class="email-text">Send Email to:</p>

          <div
            class="email-descp"
            v-for="(data, index) in JSON.parse(
              getCompanyProfile.recip_low_customer_activity
            )"
            :key="index"
          >
            <p>{{ data }}</p>
          </div>
          <button
            @click="
              showDailog(
                getCompanyProfile.recip_low_customer_activity,
                'Low Customer Activity'
              )
            "
          >
            <p>Edit</p>
          </button>
        </div>
      </div>
    </div>

    <!-- ******************************************************** -->

    <!------------------------ Delivery Order  --------------------->

    <div class="py-5 px-5" style="background-color: white">
      <p class="sub-heading">Delivery Order Notification</p>

      <div class="item-main mt-4">
        <div class="item">
          <div>
            <p class="item-heading">DO Recived From Customer</p>
          </div>
          <div class="item-right-section">
            <v-checkbox
              class="notify-type mr-3"
              v-model="getCompanyProfile.email_do_recived_from_customer"
              label="Email"
              @click="
                showEmaiList(
                  'do_recived_from_customer',
                  getCompanyProfile.email_do_recived_from_customer
                )
              "
            ></v-checkbox>
            <div class="vertical-separtor" />

            <v-checkbox
              class="ml-3 notify-type"
              :input-value="
                getCompanyProfile.notifi_do_recived_from_customer !== 'OFF'
              "
              label="Push Notification"
              @click="DORecivedCustomerMenu()"
            />
            <v-select
              ref="DORecivedCustomer"
              class="item-notif-settings"
              v-model="doRecivedFromCustomerNotif"
              :items="stateData.doRecivedFromCustomer"
              :class="
                getCompanyProfile.notifi_do_recived_from_customer !== 'OFF'
                  ? 'active'
                  : ''
              "
              :menu-props="{ contentClass: 'notifi-menu-list' }"
              append-icon="ic-chevron-down"
              @click="setDORecivedCustomerAct"
              @change="updateDORecivedCustomer"
            >
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item
                  class="d-flex justify-start"
                  style="border: none; min-height: 45px; height: 45px"
                  v-on="on"
                  v-bind="attrs"
                >
                  <div
                    v-if="item == 'Custom Time'"
                    @click="openCustomTimeDialog('do_recived_from_customer')"
                  >
                    <p class="name font-regular" style="color: #4a4a4a">
                      {{ item }}
                    </p>
                  </div>
                  <div v-else>
                    <p class="name font-regular" style="color: #4a4a4a">
                      {{ item }}
                    </p>
                  </div>
                </v-list-item>
              </template>
            </v-select>
          </div>
        </div>
        <div
          class="item-description"
          v-if="getCompanyProfile.email_do_recived_from_customer"
        >
          <p class="email-text">Send Email to:</p>
          <div
            class="email-descp"
            v-for="(data, index) in JSON.parse(
              getCompanyProfile.recip_do_recived_from_customer
            )"
            :key="index"
          >
            <p>{{ data }}</p>
          </div>

          <button
            @click="
              showDailog(
                getCompanyProfile.recip_do_recived_from_customer,
                'DO Recived From Customer'
              )
            "
          >
            <p>Edit</p>
          </button>
        </div>
      </div>

      <!-- tt -->
      <div class="item-main mt-4">
        <div class="item">
          <div>
            <p class="item-heading">DO Recived From Shifl</p>
          </div>
          <div class="item-right-section">
            <v-checkbox
              class="notify-type mr-3"
              v-model="getCompanyProfile.email_do_recived_from_shifl"
              label="Email"
              @click="
                showEmaiList(
                  'do_recived_from_shifl',
                  getCompanyProfile.email_do_recived_from_shifl
                )
              "
            ></v-checkbox>
            <div class="vertical-separtor" />

            <v-checkbox
              class="ml-3 notify-type"
              :input-value="
                getCompanyProfile.notifi_do_recived_from_shifl !== 'OFF'
              "
              label="Push Notification"
              @click="DORecivedFromShiflMenu()"
            />
            <v-select
              ref="DORecivedShifl"
              class="item-notif-settings"
              v-model="doRecivedFromShiflNotif"
              :items="stateData.doRecivedFromShifl"
              :class="
                getCompanyProfile.notifi_do_recived_from_shifl !== 'OFF'
                  ? 'active'
                  : ''
              "
              :menu-props="{ contentClass: 'notifi-menu-list' }"
              append-icon="ic-chevron-down"
              @click="setDORecivedFromShiflAct"
              @change="updateDORecivedFromShifl"
            >
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item
                  class="d-flex justify-start"
                  style="border: none; min-height: 45px; height: 45px"
                  v-on="on"
                  v-bind="attrs"
                >
                  <div
                    v-if="item == 'Custom Time'"
                    @click="openCustomTimeDialog('do_recived_from_shifl')"
                  >
                    <p class="name font-regular" style="color: #4a4a4a">
                      {{ item }}
                    </p>
                  </div>
                  <div v-else>
                    <p class="name font-regular" style="color: #4a4a4a">
                      {{ item }}
                    </p>
                  </div>
                </v-list-item>
              </template>
            </v-select>
          </div>
        </div>
        <div
          class="item-description"
          v-if="getCompanyProfile.email_do_recived_from_shifl"
        >
          <p class="email-text">Send Email to:</p>
          <div
            class="email-descp"
            v-for="(data, index) in JSON.parse(
              getCompanyProfile.recip_do_recived_from_shifl
            )"
            :key="index"
          >
            <p>{{ data }}</p>
          </div>

          <button
            @click="
              showDailog(
                getCompanyProfile.recip_do_recived_from_shifl,
                'DO Recived From Shifl'
              )
            "
          >
            <p>Edit</p>
          </button>
        </div>
      </div>
    </div>

    <!-- ******************************************************* -->
    <!-- ----------------------------------------------------------- -->

    <!------------------------ Tracking Notification ------------->
    <ContainerTrackingNotification
      ref="childRef"
      @showEmailDailog="showEmailDailog"
      @showEmaiList="showEmaiList"
      @handleClickOutside="handleClickOutside"
    />

    <!-- ******************************************************* -->

    <EditNotificationUser />
    <CustomTime />
  </div>
</template>

<script>
import EditNotificationUser from "./Dialog/Notification/EditNotificationUser.vue";
import CustomTime from "./Dialog/Notification/CustomTime.vue";
import ContainerTrackingNotification from "./Dialog/Notification/TrackingNotification.vue";
import { stateData } from "./Dialog/Notification/customStaticData";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  props: ["getCompanyProfile"],
  components: {
    EditNotificationUser,
    CustomTime,
    ContainerTrackingNotification,
  },
  data() {
    return {
      stateData,
      lowCustomer: false,
      itemOpen: {
        box: null,
        notification_type: null,
      },
    };
  },
  computed: {
    ...mapGetters("notifications", ["getBoxOpen", "getEmailDailog"]),
    driverAcceptNotif: {
      get() {
        if (this.getCompanyProfile.notifi_sc_accepted == "OFF") {
          return "Push Notification Turned Off";
        } else if (this.getCompanyProfile.notifi_sc_accepted == "AA") {
          return "Active (Anytime)";
        } else if (this.getCompanyProfile.notifi_sc_accepted == "WH") {
          return "Working Hours (8AM-5PM)";
        } else {
          return "Custom Time";
        }
      },
      set(value) {
        return value;
      },
    },
    driverRejectNotif: {
      get() {
        if (this.getCompanyProfile.notifi_sc_rejected == "OFF") {
          return "Push Notification Turned Off";
        } else if (this.getCompanyProfile.notifi_sc_rejected == "AA") {
          return "Active (Anytime)";
        } else if (this.getCompanyProfile.notifi_sc_rejected == "WH") {
          return "Working Hours (8AM-5PM)";
        } else {
          return "Custom Time";
        }
      },
      set(value) {
        return value;
      },
    },
    lowCustomerNotif: {
      get() {
        if (this.getCompanyProfile.notifi_low_customer_activity == "OFF") {
          return "Push Notification Turned Off";
        } else if (
          this.getCompanyProfile.notifi_low_customer_activity == "AA"
        ) {
          return "Active (Anytime)";
        } else if (
          this.getCompanyProfile.notifi_low_customer_activity == "WH"
        ) {
          return "Working Hours (8AM-5PM)";
        } else {
          return "Custom Time";
        }
      },
      set(value) {
        return value;
      },
    },
    doRecivedFromCustomerNotif: {
      get() {
        if (this.getCompanyProfile.notifi_do_recived_from_customer == "OFF") {
          return "Push Notification Turned Off";
        } else if (
          this.getCompanyProfile.notifi_do_recived_from_customer == "AA"
        ) {
          return "Active (Anytime)";
        } else if (
          this.getCompanyProfile.notifi_do_recived_from_customer == "WH"
        ) {
          return "Working Hours (8AM-5PM)";
        } else {
          return "Custom Time";
        }
      },
      set(value) {
        return value;
      },
    },
    doRecivedFromShiflNotif: {
      get() {
        if (this.getCompanyProfile.notifi_do_recived_from_shifl == "OFF") {
          return "Push Notification Turned Off";
        } else if (
          this.getCompanyProfile.notifi_do_recived_from_shifl == "AA"
        ) {
          return "Active (Anytime)";
        } else if (
          this.getCompanyProfile.notifi_do_recived_from_shifl == "WH"
        ) {
          return "Working Hours (8AM-5PM)";
        } else {
          return "Custom Time";
        }
      },
      set(value) {
        return value;
      },
    },
  },
  methods: {
    ...mapActions("notifications", [
      "updateNotificationStatus",
      "updateNotificationTime",
      "updateEmailSettings",
    ]),

    ...mapMutations("notifications", [
      "SET_CURRENT_EDIT_ITEM",
      "SET_CUSTOM_TIME_DAILOG",
      "SET_EMAIL_DAILOG",
      "SET_EMAIL_LIST",
      "CURRENT_EMAIL_RECPIENTS",
      "SET_BOX_OPEN",
    ]),
    showEmaiList(item, status) {
      this.setEmailNotification(item, status);
    },
    updateNullVaue(item) {
      this.getCompanyProfile[item] = "Push Notification Turned Off";
    },
    showDailog(emails, item) {
      this.SET_CURRENT_EDIT_ITEM(item);
      this.SET_EMAIL_DAILOG(true);
      this.CURRENT_EMAIL_RECPIENTS(emails);
    },

    openCustomTimeDialog(notifi) {
      switch (notifi) {
        case "sc_accepted":
          if (this.getCompanyProfile.notifi_sc_accepted == "CT") {
            this.SET_CURRENT_EDIT_ITEM("Driver Accept Load");
            this.SET_CUSTOM_TIME_DAILOG(true);
          }
          break;
        case "sc_rejected":
          if (this.getCompanyProfile.notifi_sc_rejected == "CT") {
            this.SET_CURRENT_EDIT_ITEM("Driver Reject Load");
            this.SET_CUSTOM_TIME_DAILOG(true);
          }
          break;
        case "low_customer":
          if (this.getCompanyProfile.notifi_low_customer_activity == "CT") {
            this.SET_CURRENT_EDIT_ITEM("Low Customer Activity");
            this.SET_CUSTOM_TIME_DAILOG(true);
          }
          break;
        case "do_recived_from_customer":
          if (this.getCompanyProfile.notifi_sc_accepted == "CT") {
            this.SET_CURRENT_EDIT_ITEM("DO Recived From Customer");
            this.SET_CUSTOM_TIME_DAILOG(true);
          }
          break;
        default:
          if (this.getCompanyProfile.notifi_sc_accepted == "CT") {
            this.SET_CURRENT_EDIT_ITEM("DO Recived From Shifl");
            this.SET_CUSTOM_TIME_DAILOG(true);
          }
          break;
      }
    },

    generalClose() {
      const menuComponent = this.$refs[this.getBoxOpen.ref];
      menuComponent.blur();
      setTimeout(() => {
        if (this.stateData[this.getBoxOpen.state].length == 3) {
          const newItem = "Push Notification Turned Off";
          this.stateData[this.getBoxOpen.state].unshift(newItem);
        }
        let payload = {
          show: false,
          ref: null,
          state: null,
          property: null,
        };
        this.SET_BOX_OPEN(payload);
      }, 70);
    },
    driverAcceptMenu() {
      const menuComponent = this.$refs.mySelect;
      if (!this.getBoxOpen.show) {
        let payload = {
          show: true,
          ref: "mySelect",
          state: "driverAccNotif",
          property: "notifi_sc_accepted",
        };
        this.SET_BOX_OPEN(payload);
        if (this.driverAcceptNotif !== "Push Notification Turned Off") {
          if (this.stateData.driverAccNotif.length == 3) {
            const newItem = "Push Notification Turned Off";
            this.stateData.driverAccNotif.unshift(newItem);
          }

          this.updateNotification("Driver Accept Load", "OFF");
          menuComponent.blur();
          return;
        }
        if (this.driverAcceptNotif == "Push Notification Turned Off") {
          if (this.stateData.driverAccNotif.length == 4) {
            this.stateData.driverAccNotif.shift();
          }
          menuComponent.activateMenu();
        }
        return;
      }

      this.generalClose();
    },

    setDriverAcceptNotifTime() {
      const menuComponent = this.$refs.mySelect;
      if (
        this.stateData.driverAccNotif.length == 4 &&
        this.getCompanyProfile.notifi_sc_accepted !== "OFF"
      ) {
        this.stateData.driverAccNotif.shift();
      }
      if (this.getCompanyProfile.notifi_sc_accepted == "OFF") {
        menuComponent.blur();
        return;
      }
    },
    updateDriverAccept(selectedValue) {
      if (selectedValue == "Custom Time") {
        this.SET_CURRENT_EDIT_ITEM("Driver Accept Load");
        this.SET_CUSTOM_TIME_DAILOG(true);
        return;
      }
      this.updateNotification("Driver Accept Load", selectedValue);
    },

    driverRejectMenu() {
      const menuComponent = this.$refs.driverReject;
      if (!this.getBoxOpen.show) {
        let payload = {
          show: true,
          ref: "driverReject",
          state: "driverRejectNotif",
          property: "notifi_sc_rejected",
        };
        this.SET_BOX_OPEN(payload);
        if (this.driverRejectNotif !== "Push Notification Turned Off") {
          if (this.stateData.driverRejectNotif.length == 3) {
            const newItem = "Push Notification Turned Off";
            this.stateData.driverRejectNotif.unshift(newItem);
          }
          menuComponent.blur();
          this.updateNotification("Driver Reject Load", "OFF");
          return;
        }
        if (this.driverRejectNotif == "Push Notification Turned Off") {
          if (this.stateData.driverRejectNotif.length == 4) {
            this.stateData.driverRejectNotif.shift();
          }
          menuComponent.activateMenu();
        }
        return;
      }
      this.generalClose();
    },

    setDriverRejectNotifTime() {
      const menuComponent = this.$refs.driverReject;
      if (
        this.stateData.driverRejectNotif.length == 4 &&
        this.getCompanyProfile.notifi_sc_rejected !== "OFF"
      ) {
        this.stateData.driverRejectNotif.shift();
      }
      if (this.getCompanyProfile.notifi_sc_rejected == "OFF") {
        menuComponent.blur();
        return;
      }
    },
    updateDriverReject(selectedValue) {
      if (selectedValue == "Custom Time") {
        this.SET_CURRENT_EDIT_ITEM("Driver Reject Load");
        this.SET_CUSTOM_TIME_DAILOG(true);
        return;
      }
      this.updateNotification("Driver Reject Load", selectedValue);
    },

    lowCustomerMenu() {
      const menuComponent = this.$refs.customerActivity;
      if (!this.getBoxOpen.show) {
        let payload = {
          show: true,
          ref: "customerActivity",
          state: "lowCustomer",
          property: "notifi_low_customer_activity",
        };
        this.SET_BOX_OPEN(payload);
        if (this.lowCustomerNotif !== "Push Notification Turned Off") {
          if (this.stateData.lowCustomer.length == 3) {
            const newItem = "Push Notification Turned Off";
            this.stateData.lowCustomer.unshift(newItem);
          }
          menuComponent.blur();
          this.updateNotification("Low Customer Activity", "OFF");
          return;
        }
        if (this.lowCustomerNotif == "Push Notification Turned Off") {
          if (this.stateData.lowCustomer.length == 4) {
            this.stateData.lowCustomer.shift();
          }
          menuComponent.activateMenu();
        }
        return;
      }
      this.generalClose();
    },
    setLowCustomerActivty() {
      const menuComponent = this.$refs.customerActivity;
      if (
        this.stateData.lowCustomer.length == 4 &&
        this.getCompanyProfile.notifi_low_customer_activity !== "OFF"
      ) {
        this.stateData.lowCustomer.shift();
      }
      if (this.getCompanyProfile.notifi_low_customer_activity == "OFF") {
        menuComponent.blur();
        return;
      }
    },

    updateLowCustomer(selectedValue) {
      if (selectedValue == "Custom Time") {
        this.SET_CURRENT_EDIT_ITEM("Low Customer Activity");
        this.SET_CUSTOM_TIME_DAILOG(true);
        return;
      }
      this.updateNotification("Low Customer Activity", selectedValue);
    },

    DORecivedCustomerMenu() {
      const menuComponent = this.$refs.DORecivedCustomer;
      if (!this.getBoxOpen.show) {
        let payload = {
          show: true,
          ref: "DORecivedCustomer",
          state: "doRecivedFromCustomer",
          property: "notifi_do_recived_from_customer",
        };
        this.SET_BOX_OPEN(payload);
        if (
          this.doRecivedFromCustomerNotif !== "Push Notification Turned Off"
        ) {
          menuComponent.blur();
          if (this.stateData.doRecivedFromCustomer.length == 3) {
            const newItem = "Push Notification Turned Off";
            this.stateData.doRecivedFromCustomer.unshift(newItem);
          }
          this.updateNotification("DO Recived From Customer", "OFF");
          return;
        }
        if (this.doRecivedFromCustomerNotif == "Push Notification Turned Off") {
          if (this.stateData.doRecivedFromCustomer.length == 4) {
            this.stateData.doRecivedFromCustomer.shift();
          }
          menuComponent.activateMenu();
        }
        return;
      }
      this.generalClose();
    },

    setDORecivedCustomerAct() {
      const menuComponent = this.$refs.DORecivedCustomer;
      if (
        this.stateData.doRecivedFromCustomer.length == 4 &&
        this.getCompanyProfile.notifi_do_recived_from_customer !== "OFF"
      ) {
        this.stateData.doRecivedFromCustomer.shift();
      }
      if (this.getCompanyProfile.notifi_do_recived_from_customer == "OFF") {
        menuComponent.blur();
        return;
      }
    },
    updateDORecivedCustomer(selectedValue) {
      if (selectedValue == "Custom Time") {
        this.SET_CURRENT_EDIT_ITEM("DO Recived From Customer");
        this.SET_CUSTOM_TIME_DAILOG(true);
        return;
      }
      this.updateNotification("DO Recived From Customer", selectedValue);
    },
    DORecivedFromShiflMenu() {
      const menuComponent = this.$refs.DORecivedShifl;
      if (!this.getBoxOpen.show) {
        let payload = {
          show: true,
          ref: "DORecivedShifl",
          state: "doRecivedFromShifl",
          property: "notifi_do_recived_from_shifl",
        };
        this.SET_BOX_OPEN(payload);
        if (this.doRecivedFromShiflNotif !== "Push Notification Turned Off") {
          menuComponent.blur();
          if (this.stateData.doRecivedFromShifl.length == 3) {
            const newItem = "Push Notification Turned Off";
            this.stateData.doRecivedFromShifl.unshift(newItem);
          }
          this.updateNotification("DO Recived From Shifl", "OFF");
          return;
        }
        if (this.doRecivedFromShiflNotif == "Push Notification Turned Off") {
          if (this.stateData.doRecivedFromShifl.length == 4) {
            this.stateData.doRecivedFromShifl.shift();
          }
          menuComponent.activateMenu();
        }
        return;
      }

      this.generalClose();

      //
    },
    setDORecivedFromShiflAct() {
      const menuComponent = this.$refs.DORecivedShifl;
      if (
        this.stateData.doRecivedFromShifl.length == 4 &&
        this.getCompanyProfile.notifi_do_recived_from_shifl !== "OFF"
      ) {
        this.stateData.doRecivedFromShifl.shift();
      }
      if (this.getCompanyProfile.notifi_do_recived_from_shifl == "OFF") {
        menuComponent.blur();
        return;
      }
    },
    updateDORecivedFromShifl(selectedValue) {
      if (selectedValue == "Custom Time") {
        this.SET_CURRENT_EDIT_ITEM("DO Recived From Shifl");
        this.SET_CUSTOM_TIME_DAILOG(true);
        return;
      }
      this.updateNotification("DO Recived From Shifl", selectedValue);
    },

    updateNotification(notification, period) {
      let form = new FormData();
      // let;
      switch (notification) {
        case "Driver Accept Load":
          form.append("type", "sc_accepted");
          if (period == "Active (Anytime)") {
            form.append("active_anytime", true);
          } else if (period == "Working Hours (8AM-5PM)") {
            form.append("working_hours", true);
          } else if (period == "OFF") {
            form.append("off_notification", true);
          } else {
            form.append("custom_time", true);
          }

          break;
        case "Driver Reject Load":
          form.append("type", "sc_rejected");

          if (period == "Active (Anytime)") {
            form.append("active_anytime", true);
          } else if (period == "Working Hours (8AM-5PM)") {
            form.append("working_hours", true);
          } else if (period == "OFF") {
            form.append("off_notification", true);
          } else {
            form.append("custom_time", true);
          }

          break;
        case "Low Customer Activity":
          form.append("type", "low_customer_activity");
          if (period == "Active (Anytime)") {
            form.append("active_anytime", true);
          } else if (period == "Working Hours (8AM-5PM)") {
            form.append("working_hours", true);
          } else if (period == "OFF") {
            form.append("off_notification", true);
          } else {
            form.append("custom_time", true);
          }

          break;
        case "DO Recived From Customer":
          form.append("type", "do_recived_from_customer");

          if (period == "Active (Anytime)") {
            form.append("active_anytime", true);
          } else if (period == "Working Hours (8AM-5PM)") {
            form.append("working_hours", true);
          } else if (period == "OFF") {
            form.append("off_notification", true);
          } else {
            form.append("custom_time", true);
          }

          break;
        default:
          form.append("type", "do_recived_from_shifl");
          if (period == "Active (Anytime)") {
            form.append("active_anytime", true);
          } else if (period == "Working Hours (8AM-5PM)") {
            form.append("working_hours", true);
          } else if (period == "OFF") {
            form.append("off_notification", true);
          } else {
            form.append("custom_time", true);
          }

          break;
      }
      this.updateNotificationTime(form);
    },
    setEmailNotification(notification, status) {
      let form = new FormData();
      form.append("status", status);
      switch (notification) {
        case "sc_accepted":
          form.append("type", "sc_accepted");
          break;
        case "sc_rejected":
          form.append("type", "sc_rejected");
          break;
        case "low_customer_activity":
          form.append("type", "low_customer_activity");
          break;
        case "do_recived_from_customer":
          form.append("type", "do_recived_from_customer");
          break;
        case "do_recived_from_shifl":
          form.append("type", "do_recived_from_shifl");
          break;
        case "container_available":
          form.append("type", "container_available");
          break;
        case "container_discharged":
          form.append("type", "container_discharged");
          break;
        case "LFD_issued":
          form.append("type", "LFD_issued");
          break;
        case "eta_changed_alert":
          form.append("type", "eta_changed_alert");
          break;
        default:
          form.append("type", "LFD_reminder");
      }
      this.updateEmailSettings(form);
    },

    // ---------------------

    showEmailDailog() {
      this.dialog = true;
    },
    handleClickOutside(e) {
      if (
        this.getBoxOpen.ref &&
        e.target.classList[0] !== "v-input--selection-controls__ripple"
      ) {
        let menuComponent = this.$refs[this.getBoxOpen.ref];
        if (!menuComponent) {
          menuComponent = this.$refs.childRef.$refs[this.getBoxOpen.ref];
        }
        menuComponent.blur();
        setTimeout(() => {
          if (this.stateData[this.getBoxOpen.state].length == 3) {
            const newItem = "Push Notification Turned Off";
            this.stateData[this.getBoxOpen.state].unshift(newItem);
          }

          let payload = {
            show: false,
            ref: null,
            state: null,
            property: null,
          };
          this.SET_BOX_OPEN(payload);
        }, 150);
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  created() {},
};
</script>

<style lang="scss">
.v-menu__content {
  &.notifi-menu-list {
    margin-top: 25px;
  }
}
// @import "../../../assets/scss/pages_scss/customers/customerAddDialog.scss";
// @import "../../../assets/scss/pages_scss/dialog/globalDialog.scss";

.notification-setting {
  overflow: hidden !important;
  // scroll-behavior: ;
  .vertical-separtor {
    height: 32px;
    width: 1px;
    background: #d8e7f0;
  }
  .sub-heading {
    color: #4a4a4a;
    font-size: 16px;
    font-family: "Inter-SemiBold", sans-serif;
  }
  .item-main {
    border: 1px solid #d8e7f0;
    border-radius: 4px;
    padding: 0px 20px;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .item-heading {
        font-size: 14px;
        margin-top: 13px;
        color: #4a4a4a;
        font-family: "Inter-SemiBold", sans-serif;
      }
      .item-right-section {
        display: flex;
        align-items: center;
        .notify-type .v-input__slot .v-label {
          color: #4a4a4a;
          font-size: 14px;
          font-family: "Inter-Regular";
        }
        .item-notif-settings {
          border: 1px solid #b4cfe0;
          border-radius: 4px;
          margin-left: 10px;
          width: 220px;
          height: 35px;
          .v-input__control .v-select__slot .v-select__selections {
            font-size: 12px;
            color: #819fb2;
            margin-top: -12px !important;
            font-family: "Inter-Medium" !important;
          }
          .v-input__append-inner .v-input__icon {
            margin-top: -8px;
          }
        }
      }
    }
    .item-description {
      background-color: #f5f9fc;
      display: flex;
      padding: 0px 20px;
      height: 40px;
      width: 100%;
      margin-bottom: 20px;
      .email-text {
        color: #000000;
        font-family: "Inter-Regular";
        font-size: 12px;
        margin-top: 10px;
      }
      button {
        margin-top: 10px;
        margin-left: 15px;

        p {
          color: #0171a1;
          font-family: "Inter-Medium";
          font-size: 12px;
        }
      }
      .email-descp {
        margin-top: 8px;
        background-color: #fff;
        border-radius: 4px;
        height: 25px;
        margin-left: 15px;
        padding: 5px;
        p {
          color: #4a4a4a;
          font-size: 12px;
          font-family: "Inter-Regular";
          margin-top: -3px;
        }
      }
    }
  }
}

.active {
  border: 1px solid #0171a1 !important;
  .v-input__control .v-select__slot .v-select__selections {
    color: #0171a1 !important;
    font-family: "Inter-SemiBold", sans-serif !important;
  }
}
.v-input.item-notif-settings.v-input--is-label-active.v-input--is-dirty.v-input--is-focused.theme--light.v-text-field.v-text-field--is-booted.v-select.v-select--is-menu-active.primary--text.active {
  border: 1px solid #0171a1 !important;
}
</style>
