<template>
  <div class="create-do-form-wrapper">
    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
      scrollable
      transition="dialog-bottom-transition"
      :content-class="`dialogClassDos create-do popupdialogDos`"
      no-click-animation
    >
        <v-card>
            <v-card-title>
                <span class="headline">Add Customer in Bulk</span>
                <button icon dark class="btn-close" @click="handleClose">
                    <v-icon>mdi-close</v-icon>
                </button>
            </v-card-title>

            <v-card-text class="pa-0">
                <div class="pb-0">
                    <v-form ref="form" v-model="bulkCustomer" id="bulkCustomer">
                        <div>
                            <v-data-table
                                class="import-bulk-customer-table px-6 py-6"
                                :headers="headers"
                                :items="getAddedCustomers"
                                item-key="email"
                                hide-default-footer
                                dense
                            >
                                <template v-slot:body="{ items }">
                                    <tr v-for="(item, index) in items" :key="index">
                                        <td
                                            class="text-start"
                                            style="border: 1px solid #ebf2f5; width: 200px"
                                        >
                                            <v-autocomplete
                                                :id="'customer'+index"
                                                :items="getFilteredCustomers"
                                                placeholder="Select QuickBooks Customer"
                                                background-color="white"
                                                item-text="FullyQualifiedName"
                                                no-filter
                                                outlined
                                                hide-details="auto"
                                                dense
                                                hide-no-data
                                                class="bulkCustomerField"
                                                v-model="selected"
                                                @change="handleSelectedCustomer(index, $event)"
                                            ></v-autocomplete>
                                        </td>
                                        <td
                                            class="text-end"
                                            style="border: 1px solid #ebf2f5; border-left: none"
                                        >
                                            <v-text-field
                                                flat
                                                solo
                                                type="email"
                                                placeholder="Enter email address"
                                                class="text-fields bulkCustomerField text-end"
                                                dense
                                                outlined
                                                hide-details="auto"
                                                full-width
                                                :rules="emailRules"
                                                v-model="selectedCustomers[index].email"
                                            >
                                            </v-text-field>
                                        </td>
                                        <td
                                            class="text-end"
                                            style="border: 1px solid #ebf2f5; border-left: none"
                                        >
                                            <v-text-field
                                                flat
                                                solo
                                                placeholder="Enter Phone Number"
                                                class="text-fields bulkCustomerField text-end"
                                                dense
                                                hide-details="auto"
                                                full-width
                                                v-model="selectedCustomers[index].phone_number"
                                            >
                                            </v-text-field>
                                        </td>
                                        <td
                                            class="text-end"
                                            style="border: 1px solid #ebf2f5; border-left: none"
                                        >
                                            <v-text-field
                                                flat
                                                solo
                                                placeholder="Enter Terms"
                                                class="text-fields bulkCustomerField text-end"
                                                dense
                                                hide-details="auto"
                                                full-width
                                                v-model="selectedCustomers[index].billing_terms"
                                            >
                                            </v-text-field>
                                        </td>
                                        <td
                                            class="text-end"
                                            style="border: 1px solid #ebf2f5; border-left: none"
                                        >
                                            <v-text-field
                                                flat
                                                solo
                                                placeholder="Enter Billing Address"
                                                class="text-fields bulkCustomerField text-end"
                                                dense
                                                hide-details="auto"
                                                full-width
                                                v-model="selectedCustomers[index].billing_address"
                                            >
                                            </v-text-field>
                                        </td>
                                        <td
                                            class="text-end"
                                            style="border: 1px solid #ebf2f5; border-left: none"
                                        >
                                            <v-text-field
                                                flat
                                                solo
                                                placeholder="Enter Billing Note"
                                                class="text-fields bulkCustomerField text-end"
                                                dense
                                                hide-details="auto"
                                                full-width
                                                v-model="selectedCustomers[index].billing_note"
                                            >
                                            </v-text-field>
                                        </td>
                                        <td
                                            class="text-center"
                                            style="border: 1px solid #ebf2f5; border-left: none"
                                        >
                                            <v-icon
                                                @click="handleRemoveCustomer(index)"
                                                size="19"
                                                style="cursor: pointer; color:red;"
                                            >mdi-close</v-icon>
                                        </td>
                                    </tr>
                                    <tr class="px-8">
                                        <td 
                                            colspan="7" 
                                            class="px-3 py-2"
                                        >
                                            <span @click="handleAddCustomer" class="add-more-bulk-btn">+ Add More Customer</span>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </div>
                    </v-form>
                </div>
            </v-card-text>

            <v-card-actions
                    class="mt-0"
                    style="border-top: 1px solid #ebf2f5; padding-top: 16px"
                >
                <div class="flex flex-row">
                    <button
                        type="button"
                        style="height: 41px; font-size: 14px"
                        class="btn-blue"
                        :disabled="isLoading"
                        @click="save"
                    >
                        Add Customer
                    </button>
                    <button
                        type="button"
                        class="btn-white cancel-btn"
                        style="color: #f93131; height: 41px; font-size: 14px"
                        @click="handleClose"
                    >
                        <span style="color: #4a4a4a">Cancel</span>
                    </button>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import globalMethods from '../../../../utils/globalMethods';
const APIBaseUrl = process.env.VUE_APP_PO_URL;
export default {
    name: "AddBulkCustomerDialog",
    props: ["dialog"],
    components: {
        // VueTagsInput,
    },
    data: () => ({
        vueTelInputOptions: {
        autocomplete: false,
        placeholder: "Type Phone Number",
        styleClasses: "tel-input-class",
        required: false,
        },
        emailRules: [
        (v) =>
            !v ||
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "E-mail must be valid",
        ],
        customers: [],
        headers: [
            {
                text: "Import Quickbooks Customer",
                align: "start",
                sortable: false,
                value: "customer_name",
                fixed: true,
                width: "20%",
            },
            {
                text: "Email Address",
                align: "start",
                sortable: false,
                value: "email",
                fixed: true,
                width: "12%",
            },
            {
                text: "Phone Number",
                align: "start",
                sortable: false,
                value: "phone_number",
                fixed: true,
                width: "12%",
            },
            {
                text: "Terms",
                align: "start",
                sortable: false,
                value: "billing_terms",
                fixed: true,
                width: "18%",
            },
            {
                text: "Billing Address",
                align: "start",
                sortable: false,
                value: "billing_address",
                fixed: true,
                width: "18%",
            },
            {
                text: "Billing Note",
                align: "start",
                sortable: false,
                value: "billing_note",
                fixed: true,
                width: "18%",
            },
            {
                text: "",
                sortable: false,
                value: "",
                fixed: true,
                width: "4%",
            },
        ],
        rawCustomerFields: [
            {
                customer_name: "",
                email: "",
                phone_number: "",
                billing_terms: "",
                billing_address: "",
                billing_note: "",
                qbCustomer: {}
            },
        ],
        bulkCustomer: true,
        searchCustomer: "",
        selectedCustomer: "",
        selectedCustomers: [],
        selectedQBCustomers: [],
        numberHasError: false,
        isLoading: false,
        selected: ""
    }),
    watch: {
        selectedQBCustomers: {
            handler(nv) {
                this.$forceUpdate()
                console.log('nv >>>', nv)
                this.addedCustomers = this.selectedCustomers
            },
            deep: true
        },
    },
    computed: {
        ...mapGetters({
            getCustomers: "customers/getCustomers",
            getCustomersLoading: "customers/getCustomerLoading",
            getQBCustomers: "customers/getQBCustomers",
            getFilterQBCustomers: "customers/getFilterQBCustomers",
        }),
        getFilteredCustomers: {
            get() {
                if(typeof this.getQBCustomers !== "string") {
                    let filtered = []
                    filtered = this.getQBCustomers.filter(item => {
                        if(!this.getFilterQBCustomers.includes(item.Id)) {
                            return item
                        }
                    })

                    return filtered
                } else {
                    return this.getQBCustomers
                }
            }
        },
        getAddedCustomers: {
            get() {
                return this.selectedCustomers
            }
        }
    },
    methods: {
        ...mapActions({
            fetchCustomers: "customers/fetchCustomers",
            fetchCustomersv2: "customers/fetchCustomersv2",
            createCustomers: "customers/createCustomers",
        }),
        ...globalMethods,
        handleAddCustomer() {
            this.selectedCustomers.push(this.rawCustomerFields)
        },
        handleRemoveCustomer(index) {
            this.selectedCustomers.splice(index, 1)
        },
        handleSelectedCustomer(index, value) {
            let cpSelectedCustomers = this.selectedCustomers
            this.selectedCustomers = []
            this.selected = ""
            
            let selectedCustomer = ""
            this.getQBCustomers.map(customer => {
                if(customer.FullyQualifiedName === value) {
                    selectedCustomer = customer
                }
            })

            if(selectedCustomer !== "") {
                let qb_bill_addr = ""
                if(selectedCustomer.BillAddr !== null) {
                    let line1                   = selectedCustomer.BillAddr.Line1 !== null ?  selectedCustomer.BillAddr.Line1+", " : ""  
                    let city                    = selectedCustomer.BillAddr.City !== null ?  selectedCustomer.BillAddr.City+", " : ""  
                    let countrySubdivisionCode  = selectedCustomer.BillAddr.CountrySubDivisionCode !== null ?  selectedCustomer.BillAddr.CountrySubDivisionCode+", " : ""  
                    let postalCode              = selectedCustomer.BillAddr.PostalCode !== null ?  selectedCustomer.BillAddr.PostalCode : ""  

                    // qb_bill_addr = selectedCustomer.BillAddr.Line1+", "
                    //             +selectedCustomer.BillAddr.City+", "
                    //             +selectedCustomer.BillAddr.CountrySubDivisionCode+", "
                    //             +selectedCustomer.BillAddr.PostalCode
                    qb_bill_addr = line1+city+countrySubdivisionCode+postalCode
                }

                let qb_ship_addr = ""
                if(selectedCustomer.ShipAddr !== null) {
                    qb_ship_addr = selectedCustomer.ShipAddr.Line1+", "
                                +selectedCustomer.ShipAddr.City+", "
                                +selectedCustomer.ShipAddr.CountrySubDivisionCode+", "
                                +selectedCustomer.ShipAddr.PostalCode 
                }

                let billing_address = ""
                if(selectedCustomer.BillAddr !== null) {
                    let line1                   = selectedCustomer.BillAddr.Line1 !== null ?  selectedCustomer.BillAddr.Line1+", " : ""  
                    let city                    = selectedCustomer.BillAddr.City !== null ?  selectedCustomer.BillAddr.City+", " : ""  
                    let countrySubdivisionCode  = selectedCustomer.BillAddr.CountrySubDivisionCode !== null ?  selectedCustomer.BillAddr.CountrySubDivisionCode+", " : ""  
                    let postalCode              = selectedCustomer.BillAddr.PostalCode !== null ?  selectedCustomer.BillAddr.PostalCode : "" 

                    billing_address = line1+city+countrySubdivisionCode+postalCode
                }
                let qbCustomer = {
                    qb_customer_id: selectedCustomer.Id,
                    qb_customer_display_name: selectedCustomer.DisplayName,
                    qb_customer_phone_number: selectedCustomer.PrimaryPhone !== null 
                                            ? selectedCustomer.PrimaryPhone.FreeFormNumber : "",
                    qb_customer_fully_qualified_name: selectedCustomer.FullyQualifiedName,
                    qb_customer_billing_notes: "",
                    qb_customer_billing_address: qb_bill_addr,
                    qb_customer_shipping_address: qb_ship_addr,
                    qb_customer_terms: "",
                    qb_sync_token: 0,
                }

                let customer = {
                    customer_name: selectedCustomer.FullyQualifiedName,
                    email: selectedCustomer.PrimaryEmailAddr !== null 
                            ? selectedCustomer.PrimaryEmailAddr.Address
                            : "",
                    customer_email: selectedCustomer.PrimaryEmailAddr !== null 
                            ? [{
                                email: selectedCustomer.PrimaryEmailAddr.Address
                            }] 
                            : [],
                    phone_number: selectedCustomer.PrimaryPhone !== null ? selectedCustomer.PrimaryPhone.FreeFormNumber : "",
                    billing_terms: "",
                    billing_address: billing_address,
                    billing_note: selectedCustomer.Notes,
                    qbCustomer: qbCustomer
                }

                let checkIfSelected =  []
                if(cpSelectedCustomers.length > 0) {
                    checkIfSelected = cpSelectedCustomers.filter(customer => customer.customer_name === selectedCustomer.FullyQualifiedName)
                }
                if(checkIfSelected.length === 0) {
                    cpSelectedCustomers[index] = customer

                    // let check = []
                    // if(this.selectedQBCustomers.length > 0) {
                    //     check = this.selectedQBCustomers.filter(customer => customer.qb_customer_id === selectedCustomer.Id)
                    // }

                    // console.log('check >>>', check)
                    // if(check.length === 0) {
                    //     this.selectedQBCustomers[index] = qbCustomer
                    // }
                } else {
                    cpSelectedCustomers[index] = this.rawCustomerFields
                    this.notificationRedError('Customer already selected')
                }
                this.selectedCustomers = cpSelectedCustomers
            }
        },
        async save() {
            console.log('customers >>>', this.selectedCustomers)
            let filterSelectedCustomers = this.selectedCustomers.filter((item) => {
                console.log('item >>>', item)
                if(typeof item.length === "undefined") {
                    return item
                }
            })

            console.log('filtered >>>', filterSelectedCustomers)

            if(filterSelectedCustomers.length > 0) {
                let formData = new FormData(document.getElementById('bulkCustomer'))
                // formData.append('selectedCustomers', JSON.stringify(this.selectedCustomers))
                formData.append('selectedCustomers', JSON.stringify(filterSelectedCustomers))
                
                this.isLoading = 1;
                await axios.post(`${APIBaseUrl}/createBulkCustomer`,formData)
                            .then((response) => {
                                console.log(response)
                                this.isLoading = false
                                this.$refs['form'].resetValidation()
                                this.selectedCustomers = []
                                this.selectedQBCustomers = []
                                this.$emit('update:notifysavedparent',1);
                                this.$emit('update:dialog', false)
                                this.notificationMessageCustomSuccess("Customer(s) has been added.")
                                this.fetchCustomersv2(this.getCurrentPage) // fetch updated customer lists
                            })
                            .catch(error => {
                                this.isLoading = false
                                if(typeof error.type !=undefined) this.typeError = error.type
                                if(typeof error.error !=undefined){
                                    this.errorContent = error.msg
                                }
                                if(typeof error.e !== undefined) {
                                    this.notificationError('Please add atleast one Customer')
                                }
                                if(typeof error.error_message !== undefined) {
                                    this.notificationError(error.error_message)
                                }
                            })
            } else {
                this.notificationError('Please add atleast one Customer')
            }
        },
        handleClose() {
            this.selected = ""
            this.selectedQBCustomers = []
            this.selectedCustomers = []
            this.selectedCustomers.push(this.rawCustomerFields)
            this.$emit('update:dialog', false)
        }
    },
    mounted() {
        this.selectedCustomers.push(this.rawCustomerFields)
    },
};
</script>

<style lang="scss" scoped>
#addbulkcustomerheader {
    border-bottom: 2px solid #EBF2F5;

    .v-toolbar__content {
        .v-toolbar__title {
            font-size: 24px;
            font-weight: 600;
            line-height: 34px;
            letter-spacing: 0em;
            text-align: left;
        }
    }
}

.mdi-close {
    font-size: 24px;
}

.section-text {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.section-text-black {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #4A4A4A;
}

.bkgd-faded-blue {
    background: #F5F9FC;
}
.bkgd-white {
    background: #FFF;
}
</style>
<style>
.bulkCustomerField > .v-input__control > .v-input__slot {
    border: none !important
}
.bulkCustomerField.v-text-field--outlined > div > div > fieldset {
    /* border-color: #fff !important;
    border-width: 1px; */
    border: none !important;
    max-height: 40px;
}
.v-data-table.import-bulk-customer-table > .v-data-table__wrapper > table {
    background: #F7F7F7 !important;
    border: 1px solid #EBF2F5 !important;
}
.import-bulk-customer-table.v-data-table > .v-data-table__wrapper > table > thead {
    text-transform: capitalize !important;
}
.v-data-table.import-bulk-customer-table > .v-data-table__wrapper > table > tr > td {
    background: #FFF !important;
}
.v-data-table.import-bulk-customer-table > .v-data-table__wrapper > table > tr.px-8 {
    background: #FFF !important;
    border-top: 1px solid #EBF2F5;
}
/* .text-start .bulkCustomerField.v-text-field--outlined .v-input__control div[role="combobox"] {
    max-height: 40px;
} */
.add-more-bulk-btn {
    color: #0171A1;
    cursor: pointer;
}
</style>
