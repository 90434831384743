export let stateData = {
  driverAccNotif: [
    "Push Notification Turned Off",
    "Active (Anytime)",
    "Working Hours (8AM-5PM)",
    "Custom Time",
  ],
  driverRejectNotif: [
    "Push Notification Turned Off",
    "Active (Anytime)",
    "Working Hours (8AM-5PM)",
    "Custom Time",
  ],
  lowCustomer: [
    "Push Notification Turned Off",
    "Active (Anytime)",
    "Working Hours (8AM-5PM)",
    "Custom Time",
  ],
  doRecivedFromCustomer: [
    "Push Notification Turned Off",
    "Active (Anytime)",
    "Working Hours (8AM-5PM)",
    "Custom Time",
  ],
  doRecivedFromShifl: [
    "Push Notification Turned Off",
    "Active (Anytime)",
    "Working Hours (8AM-5PM)",
    "Custom Time",
  ],
  containerAvailable: [
    "Push Notification Turned Off",
    "Active (Anytime)",
    "Working Hours (8AM-5PM)",
    "Custom Time",
  ],
  containerDischarged: [
    "Push Notification Turned Off",
    "Active (Anytime)",
    "Working Hours (8AM-5PM)",
    "Custom Time",
  ],
  LFDIssued: [
    "Push Notification Turned Off",
    "Active (Anytime)",
    "Working Hours (8AM-5PM)",
    "Custom Time",
  ],
  ETAChangeAlert: [
    "Push Notification Turned Off",
    "Active (Anytime)",
    "Working Hours (8AM-5PM)",
    "Custom Time",
  ],
  LFDReminder: [
    "Push Notification Turned Off",
    "Active (Anytime)",
    "Working Hours (8AM-5PM)",
    "Custom Time",
  ],
};
