<template>
  <div class="new-driver-content-wrapper">
    <div class="new-driver-content">
      <div class="driverHeader">
        <h2>Drivers</h2>
        <v-btn
          color="primary"
          class="btn-blue add-driver-btn"
          @click.stop="addDriver"
        >
          + Add Driver
        </v-btn>
      </div>

      <v-data-table
        :headers="headers"
        mobile-breakpoint="400"
        :items="driversData"
        class="new-driver-table"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        :custom-sort="customSort"
        hide-default-footer
        fixed-header
      >
        <!-- :custom-sort="customSort" -->

        <template v-slot:[`header.name`]="{ header }">
          {{ header.text }}<i class="classsort"></i>
        </template>
        <template v-slot:[`header.updated_at`]="{ header }">
          {{ header.text }}<i class="classsort"></i>
        </template>

        <template v-slot:top>
          <v-toolbar class="headerClass driver-header-tabs" style="" flat>
            <!-- <v-tabs class="btabsclass">
                            <v-tab value="1">Active</v-tab>
                        </v-tabs> -->

            <div class="d-flex align-center">
              <img
                src="../../../assets/icons/archived.svg"
                alt=""
                width="16px"
                height="16px"
              />
              <button
                class="btn-black archived"
                @click="goToArchivedDrivers"
                style="
                  color: #4a4a4a !important;
                  font-family: 'Inter-Medium', sans-serif !important;
                "
              >
                Archived
              </button>
            </div>
          </v-toolbar>

          <v-toolbar
            class="searching"
            style="
              border-bottom: 2px solid #ebf2f5;
              border-top: 2px solid #ebf2f5;
            "
            flat
          >
            <Search
              style="width: 320px !important"
              placeholder="Search Drivers"
              className="search custom-search search-component-wrapper"
              :inputData.sync="search"
              class="searchDos"
            />
          </v-toolbar>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <p class="mb-0">
            {{
              (item.name !== null ? item.name : "") +
              " " +
              (item.last_name !== null ? item.last_name : "")
            }}
          </p>
        </template>

        <template v-slot:[`item.phone_number`]="{ item }">
          <p class="mb-0">
            {{
              item.phone_number !== null && item.phone_number !== ""
                ? item.phone_number
                : "-"
            }}
          </p>
        </template>

        <template v-slot:[`item.email`]="{ item }">
          <p class="mb-0">
            {{ item.email !== "" ? item.email : "-" }}
          </p>
        </template>

        <template v-slot:[`item.updated_at`]="{ item }">
          <div>
            <p class="mb-0" v-if="item.user_id !== null">
              {{ lastseendate(item.driver.updated_at) }}
            </p>

            <p class="mb-0" v-else style="color: #6d858f !important">
              Driver not connected to mobile application
            </p>
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="action-button-wrapper-driver">
            <button
              class="reinvite-invite btn-white"
              @click="inviteDriver(item)"
              v-if="item.user_id === null"
            >
              <span
                v-if="
                  currentDriverInvitationLoadingId !== item.id ||
                  !isInviteLoading
                "
              >
                {{
                  (item.driver_invites && item.driver_invites.length > 0) ||
                  (item.driver_connects && item.driver_connects.length > 0)
                    ? "Reinvite"
                    : "Invite"
                }}
              </span>

              <span v-else-if="isInviteLoading">
                <v-progress-circular :size="20" color="#0171a1" indeterminate>
                </v-progress-circular>
              </span>
            </button>
            <v-menu
              offset-y
              bottom
              left
              min-width="140px"
              content-class="driver-table-menu trucking-menu"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="btn-white">
                  <img src="@/assets/icons/more-blue.svg" alt="" />
                </v-btn>
              </template>
              <v-list class="py-1">
                <v-list-item
                  class="driver-item-wrapper"
                  @click="viewDriver(item, item.id)"
                >
                  <v-list-item-title class="driver-list-item">
                    <span style="padding-right: 3px">
                      <svg
                        style="position: relative; top: 2px"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_9327_10539)">
                          <path
                            d="M1.20537 9.66212L0.800803 10.0621L1.20537 9.66212ZM1.20537 6.33788L0.800803 5.93787L1.20537 6.33788ZM14.7946 9.66212L15.1992 10.0621L14.7946 9.66212ZM14.7946 6.33788L15.1992 5.93787L14.7946 6.33788ZM14.3901 9.26209C12.9464 10.8423 10.5898 12.8387 8 12.8387V14C11.0891 14 13.7319 11.6681 15.1992 10.0621L14.3901 9.26209ZM8 12.8387C5.41023 12.8387 3.0536 10.8423 1.60993 9.26209L0.800803 10.0621C2.26806 11.6681 4.91087 14 8 14V12.8387ZM1.60993 6.73791C3.0536 5.1577 5.41023 3.16129 8 3.16129V2C4.91087 2 2.26806 4.33186 0.800803 5.93787L1.60993 6.73791ZM8 3.16129C10.5898 3.16129 12.9464 5.1577 14.3901 6.73791L15.1992 5.93787C13.7319 4.33186 11.0891 2 8 2V3.16129ZM1.60993 9.26209C0.951732 8.54163 0.951732 7.45837 1.60993 6.73791L0.800803 5.93787C-0.266934 7.10658 -0.266934 8.89342 0.800803 10.0621L1.60993 9.26209ZM15.1992 10.0621C16.2669 8.89342 16.2669 7.10658 15.1992 5.93787L14.3901 6.73791C15.0483 7.45837 15.0483 8.54163 14.3901 9.26209L15.1992 10.0621ZM9.67442 8C9.67442 8.96201 8.92473 9.74194 8 9.74194V10.9032C9.54129 10.9032 10.7907 9.60343 10.7907 8H9.67442ZM8 9.74194C7.07528 9.74194 6.32558 8.96201 6.32558 8H5.2093C5.2093 9.60343 6.45874 10.9032 8 10.9032V9.74194ZM6.32558 8C6.32558 7.03799 7.07528 6.25806 8 6.25806V5.09677C6.45874 5.09677 5.2093 6.39659 5.2093 8H6.32558ZM8 6.25806C8.92473 6.25806 9.67442 7.03799 9.67442 8H10.7907C10.7907 6.39659 9.54129 5.09677 8 5.09677V6.25806Z"
                            fill="#6D858F"
                          />
                        </g>
                      </svg>
                    </span>
                    <span class="ml-1">View</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  class="driver-item-wrapper"
                  @click="editDriver(item)"
                >
                  <v-list-item-title class="driver-list-item">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.4165 0.58353C12.1945 1.36157 12.1945 2.62302 11.4165 3.40106L4.05846 10.7591C3.73929 11.0782 3.33939 11.3047 2.9015 11.4141L0.619328 11.9847C0.254548 12.0759 -0.0758704 11.7455 0.0153245 11.3807L0.585868 9.0985C0.69534 8.66061 0.921762 8.2607 1.24092 7.94154L8.59893 0.58353C9.37697 -0.19451 10.6384 -0.19451 11.4165 0.58353ZM7.89447 2.69657L1.94531 8.64592C1.75381 8.83742 1.61796 9.07736 1.55227 9.3401L1.18306 10.8169L2.6599 10.4477C2.92263 10.382 3.16258 10.2462 3.35407 10.0547L9.30303 4.10513L7.89447 2.69657ZM9.30332 1.28791L8.59875 1.9923L10.0073 3.40085L10.7121 2.69668C11.1011 2.30766 11.1011 1.67693 10.7121 1.28791C10.3231 0.898893 9.69234 0.898893 9.30332 1.28791Z"
                        fill="#6D858F"
                      />
                    </svg>
                    <span style="margin-left: 10px">Edit</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  class="driver-item-wrapper"
                  @click="deactivateDriver(item, true)"
                >
                  <v-list-item-title class="driver-list-item">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.8498 0.800781C14.5954 0.800781 15.1998 1.4052 15.1998 2.15078V3.95078C15.1998 4.53834 14.8244 5.03824 14.3004 5.22374L14.2998 12.0508C14.2998 13.7905 12.8895 15.2008 11.1498 15.2008H4.84981C3.11011 15.2008 1.69981 13.7905 1.69981 12.0508L1.70007 5.22406C1.17559 5.03881 0.799805 4.53868 0.799805 3.95078V2.15078C0.799805 1.4052 1.40422 0.800781 2.14981 0.800781H13.8498ZM13.3998 5.30078H2.59981V12.0508C2.59981 13.2934 3.60717 14.3008 4.84981 14.3008H11.1498C12.3924 14.3008 13.3998 13.2934 13.3998 12.0508V5.30078ZM6.64981 7.10078H9.34981C9.59834 7.10078 9.79981 7.30225 9.79981 7.55078C9.79981 7.7717 9.64062 7.95543 9.4307 7.99353L9.34981 8.00078H6.64981C6.40128 8.00078 6.19981 7.79931 6.19981 7.55078C6.19981 7.32987 6.35899 7.14613 6.56892 7.10803L6.64981 7.10078H9.34981H6.64981ZM13.8498 1.70078H2.14981C1.90128 1.70078 1.69981 1.90225 1.69981 2.15078V3.95078C1.69981 4.19931 1.90128 4.40078 2.14981 4.40078H13.8498C14.0983 4.40078 14.2998 4.19931 14.2998 3.95078V2.15078C14.2998 1.90225 14.0983 1.70078 13.8498 1.70078Z"
                        fill="#6D858F"
                      />
                    </svg>
                    <span style="margin-left: 9px">Archived</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>

        <template v-slot:no-data>
          <div
            class="no-data-preloader mt-4"
            v-if="startfetching"
            style="background-color: #fff !important"
          >
            <v-progress-circular :size="30" color="#0171a1" indeterminate>
            </v-progress-circular>
          </div>

          <div
            style="width: 100%"
            v-if="!startfetching && driversData.length === 0"
            class="pt-4"
          >
            <div style="width: 100%">
              <div style="text-align: center">
                <div
                  class="wrapno-matching"
                  style="padding-top: 70px; display: inline-block"
                >
                  <p
                    class="mb-0"
                    style="font-size: 14px !important; color: #4a4a4a"
                  >
                    No Data
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </v-data-table>

      <Pagination
        v-if="typeof driversData !== 'undefined' && driversData.length > 0"
        :pageData.sync="page"
        :lengthData.sync="lastPage"
        :isMobile="isMobile"
      />
    </div>

    <ArchivedDriverDialog :dialogData.sync="isShowArchivedWarning">
      <template v-slot:dialog_icon>
        <div class="header-wrapper-close">
          <img src="@/assets/icons/icon-delete.svg" alt="alert" />
        </div>
      </template>

      <template v-slot:dialog_title>
        <h2>Archived Driver</h2>
      </template>

      <template v-slot:dialog_content>
        <p>
          Do you want to archive driver
          <span class="name">
            "{{
              currentDriverSelected !== null
                ? (currentDriverSelected.name !== null &&
                  currentDriverSelected.name !== ""
                    ? currentDriverSelected.name
                    : "") +
                  " " +
                  (currentDriverSelected.last_name !== null &&
                  currentDriverSelected.last_name !== ""
                    ? currentDriverSelected.last_name
                    : "")
                : ""
            }}" </span
          >? Once archived, driver will not be able to be assigned to any loads.
        </p>
      </template>

      <template v-slot:dialog_actions>
        <v-btn
          class="btn-blue"
          @click="deactivateDriver(currentDriverSelected, false)"
          text
          :disabled="archivedLoading"
        >
          <span v-if="!archivedLoading">Understood</span>
          <span v-if="archivedLoading">Archiving...</span>
        </v-btn>

        <v-btn
          class="btn-white ml-0"
          text
          @click="closeArchivedDialog"
          :disabled="archivedLoading"
        >
          Cancel
        </v-btn>
      </template>
    </ArchivedDriverDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ArchivedDriverDialog from "./dialog/ArchivedDriverDialog.vue";
import Search from "../../Search.vue";
import Pagination from "../../Pagination.vue";
import moment from "moment";
import axios from "axios";
import globalMethods from "../../../utils/globalMethods";
const poBaseUrl = process.env.VUE_APP_PO_URL;

var cancel;
var CancelToken = axios.CancelToken;

export default {
  name: "NewDriverTable",
  props: [
    "items",
    "isMobile",
    "itemData",
    "reloadcomponent",
    "bus",
    "defaultPage",
  ],
  components: {
    Search,
    Pagination,
    ArchivedDriverDialog,
    // ConnectedTable,
    // Notconnected,
    // Notactive,
  },
  data: () => ({
    counTab: [],
    sortedDsc: false,
    startload: 0,
    lastPage: 0,
    updatedPageCount: 0,
    fetchingstart: 0,
    main: 1,
    newDatafetch: [],
    thecurrent: { page: 0 }, // page:0 == tab1, page:1 == tab2, page:2 == tab3
    DriverData: [],
    //
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 15,
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
        sortable: true,
        width: "25%",
        fixed: true,
      },
      {
        text: "Phone Number",
        align: "start",
        value: "phone_number",
        sortable: false,
        width: "15%",
        fixed: true,
      },
      {
        text: "Email",
        align: "start",
        value: "email",
        sortable: false,
        width: "15%",
        fixed: true,
      },
      {
        text: "Status",
        align: "start",
        value: "updated_at",
        sortable: true,
        width: "25%",
        fixed: true,
      },
      {
        text: "",
        align: "start",
        value: "actions",
        sortable: false,
        width: "15%",
        fixed: true,
      },
    ],
    startfetching: false,
    driversData: [],
    currentDriverInvitationLoadingId: null,
    isShowArchivedWarning: false,
    archivedLoading: false,
    currentDriverSelected: null,
    pageCurrent: 1,
    typingTimeout: 0,
    isInviteLoading: false,
    startFetchingSearch: false,
  }),
  computed: {
    ...mapGetters({
      getDrivers: "drivers/getDrivers",
      getDriverLoading: "drivers/getDriverLoading",
      getBackState: "drivers/getBackState",
    }),
    drivers: {
      get() {
        return this.newDatafetch;
      },
      set(value) {
        this.$emit("items", value);
      },
    },
  },
  watch: {
    page(ov, nv) {
      if (ov !== nv) {
        this.fetchAllDriversNew();
      }
    },
    search() {
      if (cancel !== undefined) {
        cancel("cancel_previous_request");
      }
      this.startfetching = true;
      this.startFetchingSearch = true;
      clearTimeout(this.typingTimeout);
      this.typingTimeout = setTimeout(() => {
        this.page = 1;
        this.fetchAllDriversNew();
      }, 300);
    },
  },
  created() {
    // this.bus.$on("reload", (data) => {
    // 	if (data) this.callexeApi(1);
    // });
  },
  methods: {
    ...mapActions({
      fetchDrivers: "drivers/fetchDrivers",
      passGobackData: "drivers/passGobackData",
      passData: "drivers/passData",
      passLocFrom: "drivers/passLocFrom",
      detailNotification: "drivers/detailNotification",
    }),
    ...globalMethods,
    goToArchivedDrivers() {
      this.$router.push("/driver/archived");
    },
    customSort(items, sortBy) {
      if (!items && !sortBy == "updated_at") return items;
      if (sortBy == "updated_at") {
        const updatedItem = items.sort((a, b) => {
          if (a.driver && !b.driver) {
            return b > a ? 1 : -1;
          }
        });

        if (!this.sortedDsc) {
          this.sortedDsc = true;
          return updatedItem.sort((c, d) => {
            if (c.driver && d.driver) {
              const date1 = moment(c.driver.updated_at);
              const date2 = moment(d.driver.updated_at);
              return date1.isAfter(date2) ? -1 : 1;
            }
          });
        } else {
          this.sortedDsc = false;
          return updatedItem.sort((c, d) => {
            if (c.driver && d.driver) {
              const date1 = moment(c.driver.updated_at);
              const date2 = moment(d.driver.updated_at);
              return date1.isAfter(date2) ? 1 : -1;
            }
          });
        }
      } else if (sortBy == "name") {
        return items.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      } else {
        return items;
      }
    },

    lastseendate(v) {
      if (v) {
        return (
          "Last seen " +
          moment(v).utc(true).format("MMM Do YYYY") +
          " at " +
          moment(v).utc(true).format("h:mm A")
        );
      }
    },
    async sendEmail(id) {
      await axios
        .post(`${poBaseUrl}/sendEmailDriver`, { driverID: id })
        .then(function (response) {
          if (response) {
            console.log(response);
          }
        })
        .catch(() => {
          // console.log(error);
        });
    },
    addDriver() {
      this.$emit("addDriver");
    },
    async fetchAllDriversNew() {
      console.log("getting driver data from api");
      let dis = this;
      dis.startfetching = true;
      dis.driversData = [];

      let passedData = {
        method: "get",
        url: `${poBaseUrl}/active-drivers`,
        params: {
          page: dis.page,
        },
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      };

      if (this.search !== "") {
        passedData.params.search = dis.search;
      }

      await axios(passedData)
        .then(function (response) {
          if (response) {
            dis.startfetching = false;
            dis.driversData = response.data.drivers.data;
            dis.pageCurrent = dis.page;
            dis.lastPage = response.data.drivers.last_page;
            dis.pageCount = response.data.drivers.last_page;
            dis.counTab = JSON.parse(response.data.count);
          }
        })
        .catch((err) => {
          if (err.message !== "cancel_previous_request") {
            dis.startfetching = false;
          }
        });
    },
    async viewDriver(item, id) {
      if (item) {
        localStorage.setItem("fromArchived", false);
        let textConnected =
          item.user_id !== null ? "connected" : "notconnected";
        let numConnected = item.user_id !== null ? "01" : "02";

        await this.passData(item);
        await this.detailNotification(item);
        await this.passLocFrom(textConnected);
        this.$router
          .push("/drivers-details/" + id + `/${numConnected}`)
          .catch(() => {});
      }
    },
    editDriver(item) {
      this.$emit("editDriver", item);
    },
    deactivateDriver(item, isShowModal) {
      if (isShowModal) {
        this.isShowArchivedWarning = true;
        this.currentDriverSelected = item;
      } else {
        this.archivedLoading = true;
        let url = "deactivateDriver";

        axios
          .put(`${poBaseUrl}/` + url + "/" + item.id)
          .then((response) => {
            if (response) {
              this.countTab = response.count;
              this.notificationCustom(`Driver has been successfully archived.`);
              this.archivedLoading = false;
              this.closeArchivedDialog();
              this.fetchAllDriversNew();
            }
          })
          .catch(() => {
            // console.log(error);
            this.archivedLoading = false;
          });
      }
    },
    closeArchivedDialog() {
      this.isShowArchivedWarning = false;
      this.currentDriverSelected = null;
    },
    async inviteDriver(item) {
      this.currentDriverInvitationLoadingId = item.id;
      this.isInviteLoading = true;
      await axios
        .post(`${poBaseUrl}/dispatcher-driver/${item.id}/invite`)
        .then((res) => {
          this.currentDriverInvitationLoadingId = null;
          this.isInviteLoading = false;
          this.notificationMessageCustomSuccess(res.data.message);
          this.$emit("reloadpage", 1);
        })
        .catch((err) => {
          console.log(err);
          this.currentDriverInvitationLoadingId = null;
          this.isInviteLoading = false;
        });
    },
  },
  async mounted() {
    //set current page
    this.$store.dispatch("page/setPage", "drivers");
    this.startfetching = true;
    this.fetchAllDriversNew();
  },
  updated() {},
};
</script>

<style lang="scss">
@import "@/assets/scss/buttons.scss";

.new-driver-content-wrapper {
  .new-driver-content {
    .driverHeader {
      h2 {
        font-family: "Inter-Medium", sans-serif !important;
        font-weight: unset;
        font-size: 24px;
        color: #4a4a4a;
      }
    }

    .v-data-table.new-driver-table {
      box-shadow: none !important;
      border: 1px solid #ebf2f5;

      .driver-header-tabs {
        height: 54px !important;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .v-toolbar__content {
          height: 54px !important;

          .v-tabs {
            .v-tab {
              padding: 0 20px !important;
              letter-spacing: 0 !important;
              font-family: "Inter-SemiBold", sans-serif !important;
              font-weight: unset !important;
              color: #4a4a4a !important;
              text-transform: capitalize !important;

              &.v-tab--active {
                color: #0171a1 !important;
              }
            }
          }
        }
      }

      .searching {
        display: flex;
        justify-content: flex-end !important;
        align-items: center;

        .search-component-wrapper {
          .v-input {
            .v-input__control {
              .v-input__slot {
                width: 320px !important;

                input {
                  font-family: "Inter-Regular", sans-serif !important;
                }
              }
            }
          }
        }
      }

      .v-data-table__wrapper {
        height: calc(100vh - 345px);
        overflow-y: auto;

        table {
          thead {
            tr {
              th {
                box-shadow: none !important;
                border-bottom: 2px solid #ebf2f5 !important;
                height: 40px;
                padding: 8px 10px !important;
                text-transform: capitalize;
                font-size: 14px !important;
                color: #6d858f !important;

                span {
                  font-size: 14px !important;
                  color: #6d858f !important;
                }

                &:first-child {
                  padding-left: 16px !important;
                }
              }
            }
          }

          tbody {
            tr {
              td {
                border-bottom: 1px solid #ebf2f5 !important;
                padding: 10px !important;
                font-size: 14px;

                &:first-child {
                  padding-left: 16px !important;
                }

                .action-button-wrapper-driver {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  padding-right: 8px;

                  .btn-white {
                    height: 34px !important;
                    min-width: 34px !important;
                    padding: 8px !important;
                    margin-left: 8px !important;

                    &.reinvite-invite {
                      padding: 8px 12px !important;
                      font-size: 12px !important;
                    }
                  }
                }
              }

              &.v-data-table__empty-wrapper {
                cursor: unset;

                &:hover {
                  background-color: #fff !important;
                }

                td {
                  border-bottom: none !important;
                }
              }
            }
          }
        }

        &::-webkit-scrollbar {
          width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #d3d3d3;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #d3d3d3;
        }
      }
    }

    .pagination-wrapper {
      border: 1px solid #ebf2f5 !important;
      border-top: none !important;
    }
  }
}

.v-menu__content {
  &.driver-table-menu {
    min-width: 150px !important;

    .driver-item-wrapper {
      min-height: 38px;
      cursor: pointer;

      .v-list-item__title {
        font-size: 14px !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}
</style>
