<template>
  <div class="supplier-container" v-resize="onResize">
    <EquipmentTable />
  </div>
</template>

<script>
import EquipmentTable from "../components/Tables/Equipment/EquipmentTable.vue";
export default {
  components: {
    EquipmentTable,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    onResize() {
      if (window.innerWidth < 769) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  async mounted() {
    this.$store.dispatch("page/setPage", "chassis");
  },
};
</script>

<style lang="scss">
@import "../assets/scss/pages_scss/drivers/driver.scss";
@import "../assets/scss/pages_scss/drivers/driver_css.scss";
@import "../assets/scss/buttons.scss";
@import "../assets/css/dialog_styles/dialogHeader.css";
@import "../assets/css/dialog_styles/dialogBody.css";
@import "../assets/css/dialog_styles/dialogFooter.css";
</style>
