<template>
    <v-dialog persistent v-model="dialog" max-width="480px" content-class="delete-driver-dialog" :retain-focus="false">
        <v-card>
          <v-card-text class="pt-5 pb-0" style="caret-color: transparent;">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.75 8C18.75 7.30964 19.3096 6.75 20 6.75H28C28.6904 6.75 29.25 7.30964 29.25 8V13.25H18.75V8ZM17.25 13.25V8C17.25 6.48122 18.4812 5.25 20 5.25H28C29.5188 5.25 30.75 6.48122 30.75 8V13.25H37.9912H38.0071H40C40.4142 13.25 40.75 13.5858 40.75 14C40.75 14.4142 40.4142 14.75 40 14.75H38.6901L36.7499 38.0325C36.7324 40.6409 34.6125 42.75 32 42.75H16C13.3875 42.75 11.2676 40.6409 11.2501 38.0325L9.3099 14.75H8C7.58579 14.75 7.25 14.4142 7.25 14C7.25 13.5858 7.58579 13.25 8 13.25H9.99286H10.0088H17.25ZM30 14.75H18H10.8151L12.7474 37.9377C12.7491 37.9584 12.75 37.9792 12.75 38C12.75 39.7949 14.2051 41.25 16 41.25H32C33.7949 41.25 35.25 39.7949 35.25 38C35.25 37.9792 35.2509 37.9584 35.2526 37.9377L37.1849 14.75H30ZM20 21.25C20.4142 21.25 20.75 21.5858 20.75 22V34C20.75 34.4142 20.4142 34.75 20 34.75C19.5858 34.75 19.25 34.4142 19.25 34V22C19.25 21.5858 19.5858 21.25 20 21.25ZM28.75 22C28.75 21.5858 28.4142 21.25 28 21.25C27.5858 21.25 27.25 21.5858 27.25 22V34C27.25 34.4142 27.5858 34.75 28 34.75C28.4142 34.75 28.75 34.4142 28.75 34V22Z" fill="#F93131"/>
            </svg>

            <h2 class="mt-2">Delete Contact</h2>
            <p class="mt-2">
                Are you sure to delete <strong>{{ contactToDelete.contact_name }}</strong> from contact list of this consignee? 
                <strong>{{ getNextPrimary.contact_name }}</strong> will be assigned as primary contact automatically in that case.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn class="btn-blue" @click="handleDelete">
                <span>
                    <span>
                        <v-progress-circular
                            :size="15"
                            color="#fff"
                            :width="2"
                            indeterminate
                            v-if="getIsUpdatingConsigneeContacts === ''"
                            style="margin-right:3px"
                        ></v-progress-circular> 
                       Delete
                    </span>
                </span>
            </v-btn>
            <v-btn style="margin-left:0px !important" class="btn-white" text @click="close">
                Cancel
            </v-btn>           
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import globalMethods from '../../../../utils/globalMethods'
export const bus = new Vue();
export default {
    name: 'ContactDeleteDialog',
    props: ['dialog', 'contacts', 'contactToDelete', 'index'],
    components: {
        // components ...
    },
    data: () => ({
        // data ...
    }),
    computed: {
        ...mapGetters({
            // getIsDeleting: 'customers/getIsDeleting'
            getIsUpdatingConsigneeContacts: "customers/getIsUpdatingConsigneeContacts",
        }),       
        dialogDelete: {
            get () {
                return this.dialogData
            },
            set (value) {
                if (!value) {
                    this.$emit('update:dialogData', false)
                } else {
                    this.$emit('update:dialogData', true)
                }
            }
        },
        editedItem: {
            get () {
                return this.editedItemData
            },
            set (value) {
                console.log(value);
            }
        }, 
        getNextPrimary: {
            get() {
                if(this.contacts.length>0) {
                    let primary = this.contacts.find((contact) => contact.email !== this.contactToDelete.email)

                    console.log('primary >>>', primary)
                    return primary
                } 
                else {
                    return ""
                }
            }
        }
    },
    methods: {
        ...mapActions({
            updateConsigneeContacts: "customers/updateConsigneeContacts",
        }),
        ...globalMethods,
        close() {           
            this.$emit('update:dialog', false)
        },
        handleDelete() {
            this.contacts.map(contact => {
                if(contact.email === this.getNextPrimary.email) {
                    contact.primary = true
                }
            })

            let cpContacts = [...this.contacts];
			cpContacts.splice(this.index, 1);
            console.log('cp contacts >>>', cpContacts)
			
			let payload = {
				id: this.$route.params.id,
				form: {
					contacts: JSON.stringify(cpContacts),
				},
				method: 'deleting',
			};

            this.updateConsigneeContacts(payload)
        }
    },
}
</script>

<style lang="scss">
@import '../../../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../../../assets/scss/pages_scss/supplier/supplierAddDialog.scss';

.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}
</style>