<template>
    <v-dialog v-model="dialog" scrollable max-width="58%" content-class="dispatch-preview-dialog" persistent>
		<v-card>
			<v-card-title class="modal-header">
				<span class="headline">Preview POD</span>

                <button icon dark class="btn-close" @click="close">
                    <v-icon>mdi-close</v-icon>
                </button>
			</v-card-title>
				
			<v-card-text class="pa-8" style="min-height: calc(100vh - 300px);">
                <div v-if="isFetching" class="d-flex justify-center" style="width: 100%; padding-top: 50px;">
                    <v-progress-circular
                        :size="35"
                        color="#0171A1"
                        indeterminate
                        v-if="isFetching">
                    </v-progress-circular>
                </div>

                <div v-if="!isFetching" style="width: 100%; height: 100%;">
                    <div class="forthis" v-for="file in filelist_preview" :key="file.name" style="width: 100%; height: 100%;">
                        <!-- <object :data="getFileURL(file)" frameborder="0" width="100%" height="100%"></object>   -->
						<img style="width: 100%; height: 100%;" :src="getFileURL(file)">              
                    </div>                   
                </div>
			</v-card-text>

            <v-card-actions v-if="!isFetching">
                <div class="actions-wrapper d-flex justify-end align-center" style="width: 100%;">
                    <button type="button" class="btn-white" @click="downloadPOD(item)" :disabled="isDownloadingFile">
                        {{ isDownloadingFile ? "Downloading..." : "Download" }}
                    </button>
                    <button type="button" class="btn-white" style="color: #4a4a4a !important;" @click="close" :disabled="isDownloadingFile">
                        Close
                    </button>
                </div>
            </v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import axios from "axios";
// import _ from "lodash"
import globalMethods from '@/utils/globalMethods';
import iziToast from "izitoast"

const poBaseUrl = process.env.VUE_APP_PO_URL;

export default {
	name: "PreviewDoc",
	props: ["item", "dialogData"],
	components: {},
	computed: {
		dialog: {
			get() {
				return this.dialogData;
			},
			set(val) {
				this.$emit("update:dialogData", val)
			}
		},
	},
	watch: {
        dialog(c) {
            if (c) {
                if (this.item != null) {
                    this.previewDoc(this.item);
                }
            }
        }
    },
	data() {
		return {
            isFetching: 0,
            filelist_preview: [],
            isDownloadingFile: false,
            filenameobj: ''
        };
	},
	methods: {
		...globalMethods,
		getFileURL(file) {
			return URL.createObjectURL(file) + "#view=Fit&toolbar=0";
		},
        async downloadPOD(item) {
			if (item !== null) {
				this.isDownloadingFile = true
				let toastData = {
					message: 'Downloading file...',
					timeout: 2000,
					class: 'downloading-file-dispatch-view'
				}
				this.notificationCustomAlert(toastData)
				
				if (item.pod !== null) {
					let dispatch_leg_id = item.pod.leg_id

					axios({
						url: `${poBaseUrl}/driver/download-on-trucking/pod?dispatch_id=${+ dispatch_leg_id}`,
						method: 'GET',
						responseType: 'blob',
					}).then((response) => {
						var fileURL = window.URL.createObjectURL(new Blob([response.data]));
						var fileLink = document.createElement('a');
					
						fileLink.href = fileURL;
						fileLink.setAttribute('download', item.pod.filename);
						document.body.appendChild(fileLink);
					
						fileLink.click();

						var toast = document.querySelector('.iziToast.downloading-file-dispatch-view');
						iziToast.hide({}, toast);
						this.notificationMessageCustomSuccess("File has been downloaded!")
						this.isDownloadingFile = false
					}).catch((e) => {
						console.log(e, 'error');
						this.isDownloadingFile = false
					});
				}
			}
		},
        getBase64StringFromDataURL(dataURL) {
            return dataURL.replace('data:', '').replace(/^.+,/, '');
        },
        previewDoc(item) {
            if (item !== null) {
                this.filelist_preview = [];

                if (item.pod !== null) {
                    this.filenameobj = item.pod.filename
                    let dispatch_leg_id = item.pod.leg_id
                    this.isFetching = 1;
                    let c = [];

                    axios({
                        // url: `${poBaseUrl}/driver/preview/pod?dispatch_id=${+ dispatch_leg_id}`,
						url: `${poBaseUrl}/driver/download-on-trucking/pod?dispatch_id=${+ dispatch_leg_id}`,
                        method: 'GET',
                        responseType: 'blob',
                    }).then((response) => {
						this.filelist_preview = [];
                        this.isFetching = 0;

                        window.URL = window.URL || window.webkitURL;
                        var json = (response.data);

                        c[0] = new File([json], this.filenameobj , { lastModified: new Date().getTime(), type: response.data.type })
                        console.log(c)
                        
                        this.filelist_preview = c;

                        // const file = this.filelist_preview[0]; // this Object holds a reference to the file on disk
                        // const url = URL.createObjectURL(file); // this points to the File object we just created
                        // document.querySelector('img').src = url;
                    }).catch((e) => {
                        this.isFetching = 0;
                        console.log(e);
                    });
                }
            }
        },
        close() {
            this.$emit("close")
			this.isDownloadingFile = false
        }
	},
	mounted() {},
	updated() {}
};
</script>

<style lang="scss">
.v-dialog.dispatch-preview-dialog {
	img {
		height: 100%;
		width: 100%;
	}
}
</style>