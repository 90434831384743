import { render, staticRenderFns } from "./ChassisView.vue?vue&type=template&id=d04c936e&"
import script from "./ChassisView.vue?vue&type=script&lang=js&"
export * from "./ChassisView.vue?vue&type=script&lang=js&"
import style0 from "./ChassisView.vue?vue&type=style&index=0&lang=css&"
import style1 from "./ChassisView.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VDataTable,VProgressCircular,VTab,VTabs,VToolbar})
