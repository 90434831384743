<template>
  <div class="containers-inner-wrapper profile pa-2">
    <edit-company :dialogEdit="dialogEdit" @close="close" />
    <add-user :dialog="addUserDialog" @close="close" />
    <delete-user
      :dialogDelete="setDeleteUserDialog"
      :deleteUserItemList="setUserItemList"
      @close="close"
    />
    <edit-user
      :dialogEdit="editUserDailog"
      :editUserItemList="setUserItemList"
      @close="close"
    />

    <template>
      <v-toolbar height="50">
        <v-tabs
          class="purchase-order2-tabs filterstabs"
          v-model="testmodel"
          v-if="reload"
        >
          <v-tab v-for="(n, i) in tabs" :key="i" @click="getCurrentTab(i)">
            {{ n }}
          </v-tab>
        </v-tabs>
      </v-toolbar>
      <div v-if="compomenentReload">
        <div v-if="checkwhatpage() == 0" class="grey lighten-5">
          <v-divider color="#EBF2F5 "></v-divider>
          <v-card class="py-5" elevation="0" tile>
            <v-row no-gutters class="px-5">
              <v-col cols="12" sm="12">
                <div class="company-profile-title-container mb-1 mt-2">
                  <h3 class="company-profile-title">Company Information</h3>
                  <button
                    class="btn-white float-right"
                    @click="showEditCompanyProfileDialog"
                  >
                    Edit Profile
                  </button>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="mb-2 px-5">
              <v-col
                cols="12"
                sm="2"
                md="1"
                class="company-profile-col first mr-md-4"
              >
                <div
                  :style="
                    companyLogo == companyIcon
                      ? 'background:#EBF2F5;border: 1px solid #d8e7f0; border-radius: 4px'
                      : ''
                  "
                  class="d-flex align-center justify-center mt-3 mr-3 dddddddd"
                >
                  <img
                    :src="companyLogo"
                    style=""
                    @error="handleImageError"
                    alt="image-log"
                    :height="companyLogo == companyIcon ? '50px' : '100%'"
                    :width="companyLogo == companyIcon ? '50px' : '100%'"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="5" md="5" class="company-profile-col">
                <v-row no-gutters>
                  <v-col cols="6" sm="4" class="company-profile-label">
                    Company Name
                  </v-col>
                  <v-col cols="6" sm="8">
                    <p class="company-profile-value mb-0">
                      {{
                        getCompanyProfile.name ? getCompanyProfile.name : "N/A"
                      }}
                    </p>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mt-0">
                  <v-col cols="6" sm="4" class="company-profile-label">
                    Company Key
                  </v-col>
                  <v-col cols="6" sm="8">
                    <p
                      class="company-profile-value mb-0"
                      v-if="getCompanyProfile.trucking_company_key"
                    >
                      <input
                        type="hidden"
                        id="company-key"
                        :value="getCompanyProfile.trucking_company_key"
                      />
                      {{ getCompanyProfile.trucking_company_key }}
                      <span
                        style="vertical-align: middle"
                        class="pl-10 pointer"
                        @click.stop.prevent="copyTestingCode"
                      >
                        <img width="15px" src="@/assets/icons/clipBoard.svg" />
                      </span>
                    </p>
                    <p
                      class="company-profile-value mb-0"
                      v-if="!getCompanyProfile.trucking_company_key"
                    >
                      {{
                        getCompanyProfile.trucking_company_key
                          ? getCompanyProfile.trucking_company_key
                          : "N/A"
                      }}
                    </p>
                  </v-col>
                  <!-- <v-col cols="6" sm="8">
                    <p class="company-profile-value mb-0" v-if="getCompanyProfile.central_customer_key">
                      <input type="hidden" id="company-key" :value="getCompanyProfile.central_customer_key" />
                      {{ getCompanyProfile.central_customer_key }}
                      <span style="vertical-align: middle" class="pl-10 pointer" @click.stop.prevent="copyTestingCode">
                        <img width="15px" src="@/assets/icons/clipBoard.svg" />
                      </span>
                    </p>
                    <p class="company-profile-value mb-0" v-if="!getCompanyProfile.central_customer_key">
                      {{ getCompanyProfile.central_customer_key ? getCompanyProfile.central_customer_key : "N/A" }}
                    </p>
                  </v-col> -->
                </v-row>
                <v-row no-gutters class="mt-0">
                  <v-col cols="6" sm="4" class="company-profile-label">
                    Email Address
                  </v-col>
                  <v-col cols="6" sm="8">
                    <p class="company-profile-value mb-0">
                      {{ getCompanyProfile.general_email || "N/A" }}
                    </p>
                  </v-col>
                </v-row>
                <v-row no-gutters class="mt-0">
                  <v-col cols="6" sm="4" class="company-profile-label">
                    Super Admin
                  </v-col>
                  <v-col cols="6" sm="8">
                    <p class="company-profile-value mb-0">
                      {{ getCompanyProfile.email || "N/A" }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="5" md="5" class="company-profile-col">
                <v-row no-gutters>
                  <v-col cols="6" sm="3" class="company-profile-label">
                    DOT Number
                  </v-col>
                  <v-col cols="6" sm="9">
                    <p class="company-profile-value mb-0">
                      {{
                        getCompanyProfile.dot_number
                          ? getCompanyProfile.dot_number
                          : "N/A"
                      }}
                    </p>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="6" sm="3" class="company-profile-label">
                    MC Number
                  </v-col>
                  <v-col cols="6" sm="9">
                    <p class="company-profile-value mb-0">
                      {{
                        getCompanyProfile.mc_number
                          ? getCompanyProfile.mc_number
                          : "N/A"
                      }}
                    </p>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="6" sm="3" class="company-profile-label">
                    Phone Number
                  </v-col>
                  <v-col cols="6" sm="9">
                    <p class="company-profile-value mb-0">
                      {{
                        getCompanyProfile.phone_number
                          ? (getCompanyProfile.phone_number.substr(0, 2) == "+1"
                              ? ""
                              : "+1 ") + getCompanyProfile.phone_number
                          : "N/A"
                      }}
                    </p>
                  </v-col>
                </v-row>
                <v-row no-gutters class="address-top-margin">
                  <v-col cols="6" sm="3" class="company-profile-label">
                    Address
                  </v-col>
                  <v-col cols="6" sm="9">
                    <p class="company-profile-value mb-0">
                      {{ getCompanyAddress(getCompanyProfile) }}
                      <!-- {{ getCompanyProfile.street_address ? getCompanyProfile.street_address : ""
							}}{{ getCompanyProfile.city ? `, ${getCompanyProfile.city}` : ""
							}}{{ getCompanyProfile.state ? `, ${getCompanyProfile.state}` : ""
							}}{{ getCompanyProfile.zip_code ? `, ${getCompanyProfile.zip_code}` : ""
							}}{{ getCompanyProfile.country_code ? `, ${getCompanyProfile.country_code}` : "" }} -->
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <div class="mb-4">
              <!-- <div class="d-flex justify-space-between px-5">
                <div>
                  <h2
                    style="font-size: 20px; font-weight: 600"
                    class="mb-7 mt-3"
                  >
                    Company Information
                  </h2>
                </div>
                <div>
                  <v-btn
                    @click="showEditCompanyProfileDialog()"
                    class="btn-white"
                    >Edit Profile</v-btn
                  >
                </div>
              </div>
              <div class="px-5">
                <div class="d-flex">

                  <div
                    style="border: 1px solid #d8e7f0; border-radius: 4px"
                    :style="
                      getImgUrl(getCompanyProfile.imageUrlProfile) ==
                      companyIcon
                        ? 'background:#EBF2F5'
                        : ''
                    "
                    class="d-flex align-center justify-center mt-3 mr-3 dddddddd"
                  >

                    <img
                      :src="getImgUrl(getCompanyProfile.imageUrlProfile)"
                      style=""
                      alt="image-log"
                      :height="
                        getImgUrl(getCompanyProfile.imageUrlProfile) ==
                        companyIcon
                          ? '50px'
                          : '100%'
                      "
                      :width="
                        getImgUrl(getCompanyProfile.imageUrlProfile) ==
                        companyIcon
                          ? '50px'
                          : '100%'
                      "
                    />
                  </div>
                  <div style="width: 15%; padding-left: 20px">
                    <p class="headingTxt mb-2">Company Name</p>
                    <p class="headingTxt mb-2">Company Key</p>
                    <p class="headingTxt mb-2">Email Address</p>
                    <p class="headingTxt mb-2">Super Admin</p>
                  </div>
                  <div style="width: 27%">
                    <p class="headingTxtValue mb-2">
                      {{ getCompanyProfile.name || "-----" }}
                    </p>
                    <div style="display: flex">
                      <p class="headingTxtValue mb-2">
                        {{ getCompanyProfile.central_customer_key || "-----" }}
                      </p>
                      <button
                        v-if="getCompanyProfile.central_customer_key"
                        style="margin-top: -12px; margin-left: 10px"
                        title="copy"
                        @click="
                          copyToClipBoard(
                            getCompanyProfile.central_customer_key
                          )
                        "
                      >
                        <img
                          class="custom-clipboard"
                          src="@/assets/icons/clipBoard.svg"
                          alt=""
                        />
                      </button>
                    </div>
                    <p class="headingTxtValue mb-2">
                      {{ getCompanyProfile.general_email || "-----" }}
                    </p>
                    <p class="headingTxtValue mb-2">
                      {{ getCompanyProfile.email || "-----" }}
                    </p>
                  </div>
                  <div style="width: 10%">
                    <p class="headingTxt mb-2">DOT Number</p>
                    <p class="headingTxt mb-2">MC Number</p>
                    <p class="headingTxt mb-2">Phone Number</p>
                    <p class="headingTxt mb-2">Address</p>
                  </div>
                  <div>
                    <p class="headingTxtValue mb-2">
                      {{ getCompanyProfile.dot_number || "-----" }}
                    </p>
                    <p class="headingTxtValue mb-2">
                      {{ getCompanyProfile.mc_number || "-----" }}
                    </p>
                    <p class="headingTxtValue mb-2">
                      {{ getCompanyProfile.phone_number || "-----" }}
                    </p>
                    <div class="headingTxtValue mb-2">
                      <div class="parentDivAddress pl-2">
                        {{getCompanyAddress(getCompanyProfile)}}
                        <span
                          v-if="getCompanyProfile.street_address"
                          @mouseover="SET_TEXT_ADRESS_HOVER(true)"
                          @mouseleave="SET_TEXT_ADRESS_HOVER(false)"
                          style="color: #1c7da9"
                          >... more</span
                        >
                        <div v-if="getTextAddressHover" class="longAddress">
                          <p>
                            {{ getCompanyProfile.street_address }}
                            {{ getCompanyProfile.city }}
                            {{ getCompanyProfile.state }}
                            {{ getCompanyProfile.zip_code }}
                            {{ getCompanyProfile.country_code }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <v-divider class="mt-5" color="#EBF2F5 "></v-divider>
              <div class="px-5 pt-1">
                <div style="" class="d-flex justify-space-between pt-6">
                  <div>
                    <!-- <input
                    type="button"
                    :class="{ 'active-user-grp': usersActiveTable }"
                    class="user-btn left-btn"
                    value="Users"
                    @click="showUsersTable"
                  />
                  <input
                    :class="{ 'active-user-grp': !usersActiveTable }"
                    type="button"
                    class="user-btn right-btn"
                    value="Users Groups"
                  /> -->
                    <v-tabs
                      class="company-profile-user-group-tab"
                      v-model="activeUserGroupTab"
                      background-color=""
                    >
                      <v-tab
                        class="text-capitalize"
                        :class="index == 1 ? 'company-profile-tab-last' : ''"
                        v-for="(tab, index) in UserGroupTab"
                        :key="index"
                      >
                        {{ tab }}
                      </v-tab>
                    </v-tabs>
                  </div>
                  <div class="d-flex justify-content-space-around">
                    <div style="width: 240px">
                      <Search
                        placeholder="Search Users"
                        className="search custom-search-width"
                      />
                    </div>
                    <v-btn
                      @click="showAddUserDialog"
                      style="margin-left: 20px"
                      color="primary"
                      class="btn-blue manage-payment-methods-button"
                    >
                      <span class="btn-name-manage">Add User</span>
                    </v-btn>
                  </div>
                </div>
                <v-divider class="mt-4" color="#EBF2F5 "></v-divider>

                <v-data-table
                  class="user-group-role-class"
                  v-if="usersActiveTable"
                  :headers="getHeaders"
                  :items="getUserWithRoleAssigned"
                  hide-default-footer
                  disable-sort
                  fixed-header
                >
                  <template
                    v-slot:[`item.name`]="{ item }"
                    style="position: fixed"
                  >
                    <div class="d-flex pt-2">
                      <p class="table-txt mb-0">{{ item.name }}</p>
                      <div
                        style="position: relative"
                        v-if="JSON.parse(getUser).email == item.email"
                      >
                        <i
                          @mouseover="SET_USER_MORE_INFO_HOVER(true)"
                          @mouseleave="SET_USER_MORE_INFO_HOVER(false)"
                          style="
                            font-size: 16px;
                            color: #0084bd;
                            position: relative;
                          "
                          class="ic-info mt-4 ml-4"
                        ></i>
                        <div class="d-flex" v-if="getUserMoreInfoHover">
                          <div
                            style="
                              position: absolute;
                              top: 4px;
                              left: 30px;
                              width: 0;
                              height: 0;
                              border-top: 6px solid transparent;
                              border-right: 12px solid #4a4a4a;
                              border-bottom-left-radius: 8px;
                              border-bottom: 6px solid transparent;
                            "
                          />
                          <div class="txt-show d-flex justify-center">
                            <p class="mt-1 white--text">Current User</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template
                    v-slot:[`item.userRole`]="{ item }"
                    style="position: fixed"
                  >
                    <div>
                      <p class="mb-0">
                        {{
                          item.roles.length > 0
                            ? item.roles.length > 1
                              ? item.roles[0].name + " , " + item.roles[1].name
                              : item.roles[0].name
                            : "---"
                        }}
                      </p>
                    </div>
                  </template>
                  <template
                    v-slot:[`item.created_at`]="{ item }"
                    style="position: fixed"
                  >
                    <div class="d-flexs justify-center">
                      <p class="mb-0">
                        {{
                          new Date(item.created_at).toLocaleString("en-us", {
                            month: "short",
                            day: "2-digit",
                            year: "numeric",
                          })
                        }}
                      </p>
                    </div>
                  </template>
                  <template
                    v-slot:[`item.updated_at`]="{ item }"
                    style="position: fixed"
                  >
                    <div class="d-flexs justify-center">
                      <p class="mb-0">
                        {{
                          new Date(item.updated_at).toLocaleString("en-us", {
                            month: "short",
                            day: "2-digit",
                            year: "numeric",
                          })
                        }}
                      </p>
                    </div>
                  </template>
                  <!-- <template
                  v-slot:[`item.actions`]="{ item }"
                  style="position: fixed"
                >
                  <div class="d-flexs justify-center">
                    <v-btn
                      small
                      width="55"
                      style="margin-right: 10px; min-height: 30px !important"
                      @click="showEditUserDailog(item)"
                      class="truckerMoreBtn"
                    >
                      <img
                        class=""
                        src="../../../assets/icons/yards_edit.svg"
                        alt=""
                      />
                    </v-btn>
                    <v-btn
                      @click="showDeleteUserDialog(item)"
                      small
                      class="truckerMoreBtn"
                    >
                      <img src="../../../assets/icons/Delete.svg" alt="" />
                    </v-btn>
                  </div>
                </template> -->
                </v-data-table>
                <v-data-table
                  v-else
                  :headers="getHeadersUserGroup"
                  :items="itemsUserGroup"
                  class="elevation-1"
                  hide-default-footer
                >
                  <template v-slot:[`item.actions`] style="position: fixed">
                    <div class="d-flexs justify-center">
                      <v-btn
                        small
                        width="55"
                        style="margin-right: 10px; min-height: 30px !important"
                        class="truckerMoreBtn"
                      >
                        <img
                          class=""
                          src="../../../assets/icons/visibility.svg"
                          alt=""
                        />
                      </v-btn>
                      <v-btn small class="truckerMoreBtn">
                        <img
                          src="../../../assets/icons/yards_edit.svg"
                          alt=""
                        />
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
              </div>
            </div>
            <v-divider color="#EBF2F5"></v-divider>
          </v-card>
        </div>
        <div v-if="checkwhatpage() == 1">
          <div
            v-if="getGenralLoader"
            style="
              height: 70vh !important;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <v-progress-circular
              class="loader-g"
              :size="50"
              color="#0171a1"
              indeterminate
            >
            </v-progress-circular>
          </div>
          <NotificationsNew v-else :getCompanyProfile="getCompanyProfile" />
        </div>

        <div v-if="checkwhatpage() == 2">
          <Integrations :company="getCompanyProfile" :connected="hasQB" />
        </div>
        <!-- <div v-if="checkwhatpage() == 2">
          <Notifications :checksaving.sync="checksaving" ref="noti" />
        </div> -->
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Search from "../../Search.vue";
import EditCompany from "./Dialog/CompanyProfile/EditCompany.vue";
import AddUser from "./Dialog/CompanyProfile/AddUser.vue";
import DeleteUser from "./Dialog/CompanyProfile/DeleteUser.vue";
import EditUser from "./Dialog/CompanyProfile/EditUser.vue";
import Integrations from "./Integrations.vue";
// import Notifications from "./Notifications.vue";
import NotificationsNew from "./NotificationsNew.vue";
import axios from "axios";
import companyIcon from "../../../assets/icons/import-name-logo.svg";

const baseURL = process.env.VUE_APP_PO_URL;

export default {
  components: {
    Search,
    EditCompany,
    AddUser,
    DeleteUser,
    EditUser,
    Integrations,
    // Notifications,
    NotificationsNew,
  },
  props: ["isDiscard", "lastidchoosen", "lasttabchanges"],
  watch: {
    // add watcher here for discard then reload the tabs to display the last tab
    lasttabchanges(c) {
      this.reload = 0;
      this.$nextTick(() => {
        this.reload = 1;
        this.thepage = c;
      });
    },
    getCompanyProfile(newValue) {
      this.fetchCompanyLogo(newValue.logo);
    },
  },
  data() {
    return {
      companyLogo: companyIcon,
      compomenentReload: 1,
      isApplied: 0,
      discardChanges: 0,
      thepage: 0,
      reload: 1,
      checksaving: 0,
      setUserItemList: {},
      setDeleteUserDialog: false,
      editUserDailog: false,
      addUserDialog: false,
      showEditUserDialog: false,
      dialogEdit: false,
      activeTab: 0,
      currentSelectTab: 0,
      usersActiveTable: true,
      activeGroupClass: false,
      image: "",
      showStatic: true,
      // image: require("@/assets/icons/clipBoard.svg"),
      tabs: ["Company Profile", "Notifications", "Integrations"],
      // tabs: [
      //   "Company Profile",
      //   "Notifications",
      //   "Integrations",
      //   "Notifications New",
      // ],
      activeUserGroupTab: 0,
      UserGroupTab: ["Users", "User Role"],
      itemsUserGroup: [
        {
          userGroup: "Frozen Doe",
          noOfUsers: "2",
          users: "Asim",
          permission: "All Permission",
        },
        {
          user: "Alexa Jackson",
          noOfUsers: "5",
          userGroup: "Manager",
          users: "Asim",
          permission: "Manager",
        },
      ],
      hasQB: false,
      code: "",
      realmID: "",
    };
  },
  computed: {
    ...mapGetters("profile", [
      "getCompanyProfileImage",
      "getShowStatic",
      "getCompanyProfile",
      "getTextAddressHover",
      "getHeaders",
      "getHeadersUserGroup",
      "getUserMoreInfoHover",
      "getUserWithRoleAssigned",
    ]),
    ...mapGetters(["getUser"]),
    ...mapGetters("notifications", ["getGenralLoader"]),

    testmodel: {
      get() {
        return this.thepage;
      },
      set(value) {
        if (value) return [];
      },
    },
  },
  mounted() {
    //alert(this.$store.state.page.currentSettingsTab +'teeee')
    this.checkHasQB();
    if (typeof this.$route.query.code !== "undefined") {
      console.log("params >>>", this.$route.query);
      this.code = this.$route.query.code;
      this.realmID = this.$route.query.realmId;

      if (this.code !== "" && typeof this.code !== "undefined") {
        this.connect();
      }
    }
  },
  methods: {
    handleImageError() {
      this.companyLogo = companyIcon;
    },
    ...mapMutations("profile", [
      "SET_TEXT_ADRESS_HOVER",
      "SET_USER_MORE_INFO_HOVER",
    ]),
    ...mapActions("profile", ["getCompanyUsersFromApi"]),
    checkwhatpage() {
      let hachange;
      if (this.$store.state.page.thecurrentmenuitem) {
        if (typeof this.$refs.noti != "undefined") {
          hachange = this.$refs.noti.getstatus();
          if (
            hachange &&
            this.$store.state.page.currentSettingsTab !== 1 &&
            this.isDiscard == 0
          ) {
            this.$emit("checkifhaschanges", { pageid: 1, status: 1 });
            this.thepage = 1;
          } else {
            this.thepage = this.$store.state.page.currentSettingsTab;
          }
        } else {
          this.thepage = this.$store.state.page.currentSettingsTab;
        }
      }
      return this.thepage;
    },
    getCurrentTab(id) {
      this.currentSelectTab = id;
      let hachange = 0;
      if (typeof this.$refs.noti != "undefined") {
        hachange = this.$refs.noti.getstatus();
      }

      this.$store.dispatch("page/setCurrentSettingsTab", id);
      this.$store.dispatch("page/setAccessMenuitem", 0); // reset if the tab is clicked
      this.$store.state.page.thecurrentmenuitem;
      let pathData =
        typeof this.$router.history.current.query.tab !== "undefined"
          ? this.$router.history.current.query.tab
          : null;

      if (id == 0 && pathData !== null && pathData !== "company-profile") {
        this.$router.push("?tab=company-profile");
      }
      // if (id == 1 && pathData !== null && pathData !== "notifications") {
      // 	this.$router.push("?tab=notifications");
      // }
      if (id == 1 && pathData !== null && pathData !== "notifications") {
        this.$router.push("?tab=notifications");
      }
      if (id == 2 && pathData !== null && pathData !== "integrations") {
        this.$router.push("?tab=integrations");
      }

      // IF HAS CHANGES AND IS DISCARDED
      if (hachange == 1 && this.isDiscard == 0) {
        this.reload = 0;
        this.$nextTick(() => {
          this.reload = 1;
          this.thepage = 1;
          this.$emit("checkifhaschanges", {
            pageid: this.currentSelectTab,
            status: 1,
          });
        });
        return 1;
      } else {
        if (this.isDiscard) {
          if (this.isApplied) {
            this.thepage = id;
          } else {
            this.isApplied = 1;
            this.thepage = this.lastidchoosen;
            this.compomenentReload = 0;
            this.$nextTick(() => {
              this.reload = 1;
              this.compomenentReload = 1;
              this.thepage = this.currentSelectTab;
            });
          }
        } else {
          this.thepage = id;
        }
      }
      this.$emit("tabs", id);
      //  this.thepage = id;
    },
    showUserGroupTable() {
      if (this.usersActiveTable) {
        this.usersActiveTable = false;
      }
    },
    showUsersTable() {
      if (!this.usersActiveTable) {
        this.getCompanyUsersFromApi();
        this.usersActiveTable = true;
      }
    },
    showEditCompanyProfileDialog() {
      this.dialogEdit = true;
    },
    showAddUserDialog() {
      this.addUserDialog = true;
    },
    showDeleteUserDialog(item) {
      this.setUserItemList = item;
      this.setDeleteUserDialog = true;
    },
    showEditUserDailog(item) {
      this.setUserItemList = Object.assign({}, item);
      this.editUserDailog = true;
    },
    close() {
      this.dialogEdit = false;
      this.addUserDialog = false;
      this.setDeleteUserDialog = false;
      this.editUserDailog = false;
    },
    copyToClipBoard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          console.log(text);
        },
        () => {
          alert("error while copying to clipboard");
        }
      );
    },
    check() {
      const tt = JSON.parse(this.getUser);
      console.log(this.getUser);

      console.log(tt.id);
    },

    async fetchCompanyLogo(logo) {
      const apiUrl = `${baseURL}/get-company-profile`;
      const requestBody = {
        logo: logo,
      };
      try {
        axios
          .post(apiUrl, requestBody, { responseType: "arraybuffer" })
          .then((response) => {
            const imageBlob = new Blob([response.data], { type: "image/jpeg" });
            let imageUrl = URL.createObjectURL(imageBlob);
            this.companyLogo = imageUrl;
          });
      } catch (error) {
        console.error("Error fetching company logo:", error);
      }
    },

    async getImgUrl(pic) {
      //get image url directory from po online
      let imageUrl = "https://staging.shifl.com/storage/";
      //if pic is not null and defined

      if (typeof pic !== "undefined" && pic !== null && pic !== "") {
        if (this.getCompanyProfile.imageUrlProfile !== null) {
          return this.getCompanyProfile.imageUrlProfile;
        }
        if (pic.includes(imageUrl) !== "undefined" && !pic.includes(imageUrl)) {
          //concatonate the imageurl with the pic
          let newImage = `${imageUrl}${pic}`;
          return newImage;
        } else return pic;
      } else return companyIcon;
    },
    getCompanyAddress(company) {
      let result1 = "";
      let result2 = "";
      let result3 = "";
      let result4 = "";
      let result5 = "";
      if (
        company.street_address !== null &&
        company.street_address !== undefined
      ) {
        result1 = company.street_address;
      } else {
        result1 = "";
      }
      if (company.city !== null && company.city !== undefined) {
        result2 = company.city;
      } else {
        result2 = "";
      }
      if (company.zip_code !== null && company.zip_code !== undefined) {
        result3 = company.zip_code;
      } else {
        result3 = "";
      }
      if (company.state !== null && company.state !== undefined) {
        result4 = company.state;
      } else {
        result4 = "";
      }
      if (company.country_code !== null && company.country_code !== undefined) {
        result5 = company.country_code;
      } else {
        result5 = "";
      }
      return (
        result1 + " " + result2 + " " + result3 + " " + result4 + " " + result5
      );
    },
    connect() {
      let formData = new FormData();

      formData.append("code", this.code);
      formData.append("realmId", this.realmID);
      formData.append(
        "redirectURI",
        process.env.VUE_APP_QUICKBOOKS_REDIRECT_URI
      );

      axios
        .post(baseURL + "/quick-book/connect", formData)
        .then((response) => {
          console.log(response);
          this.hasQB = response.data.success;
        })
        .catch((error) => {
          console.log("error >>>", error);
        });
    },
    checkHasQB() {
      axios
        .get(baseURL + "/quick-book/check")
        .then((response) => {
          console.log(response);
          this.hasQB = response.data.isConnected;
        })
        .catch((error) => {
          console.log("error >>>", error);
        });
    },
  },

  created() {
    this.getCompanyUsersFromApi();
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/pages_scss/containers/containersTable.scss";
@import "../../../assets/scss/pages_scss/dialog/globalDialog.scss";
@import "../../../assets/scss/pages_scss/containers/containers.scss";
@import "../../../assets/scss/buttons.scss";
.loader-g {
  align-self: center;
  // margin: au !important;
}
.parentDivAddress {
  position: relative;
}

.longAddress {
  position: absolute;
  border-radius: 4px;
  padding: 5px;
  background-color: #354349;
  color: #fff;
  font-weight: 600;
  z-index: 4;
}

.custom-clipboard {
  color: #0171a1;
}

.containers-inner-wrapper.profile span {
  font-size: 14px !important;
}

.v-tab {
  font-size: 14px !important;
  padding: 14px 12px;
  text-transform: capitalize !important;
  letter-spacing: 0;
  font-family: Inter-SemiBold, sans-serif;
  color: #4a4a4a !important;
  min-width: 10px;
  letter-spacing: 0px !important;
}

.v-tab:hover {
  background-color: transparent !important;
}

.v-tab--active {
  color: #0171a1 !important;
}

.headingTxt {
  color: #819fb2;
  //   font-family: "Inter";
  //   font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.headingTxtValue {
  //   font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4a4a4a;
}

.user-btn {
  padding: 8px;
  color: #0171a1;
}

.left-btn {
  border: 1px solid #b4cfe0;
  border-right: none;
  border-left-width: 1px;
  border-radius: 4px 0px 0px 4px;
}

.right-btn {
  border: 1px solid #b4cfe0;
  border-left-width: 1px;
  border-radius: 0px 4px 4px 0px;
}

.active-user-grp {
  background-color: #ebf2f5;
}

.search {
  width: 350px;
  margin-left: 200px;
}

.table-txt {
  font-weight: 400;
  size: 14px;
  line-height: 20px;
}

.txt-show {
  position: absolute;
  background: #4a4a4a;
  top: -5px;
  left: 40px;
  padding: 24px, 24px, 16px, 24px;
  height: 30px;
  width: 100px;
  border-radius: 4px;
}

.dddddddd {
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
}

// .custom-search-width fieldset {
//     border: 1px solid #B3CFE0;
//     font-size: 14px;
// }
// .custom-search-width fieldset:focus {
//     border: 1px solid #B3CFE0;
//     font-size: 14px;
// }
.company-profile-user-group-tab {
  .v-item-group {
    height: 40px;

    .v-tab {
      font-size: 12px;
      letter-spacing: 0;
      font-family: "Inter-Medium", sans-serif !important;
      padding: 6px 12px;
      border-top: 1px solid #b3cfe0;
      border-bottom: 1px solid #b3cfe0;
      border-left: 1px solid #b3cfe0;
      color: #4a4a4a !important;
      border-radius: 4px;
      min-width: 65px;

      &.v-tab--active {
        color: #0171a1 !important;
        background: #ebf2f5;
      }

      &:nth-child(2) {
        border-radius: 4px 0 0 4px;
      }

      &:nth-child(3) {
        border-radius: 0;
      }

      &.company-profile-tab-last {
        border-right: 1px solid #b4cfe0;
        border-radius: 0 4px 4px 0;
        margin-right: 24px;
      }
    }

    .v-tabs-slider {
      background-color: #0171a1;
      height: 0;
    }
  }
}

.user-group-role-class.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  color: #6d858f;
  text-transform: capitalize;
}

.user-group-role-class.theme--light.v-data-table {
  border: none !important;
}

.company-profile-title {
  display: inline-block;
}

.company-profile-col {
  // padding: 0;
  padding: 0 12px;
}

.company-profile-label {
  color: $grey !important;
  font-size: 14px;
  padding-right: 0;
  line-height: 20px;
}

.company-profile-value {
  color: $default-text-color !important;
  font-size: 14px;
  margin-bottom: 0;
  line-height: 25px;
}

.pointer {
  cursor: pointer;
}
</style>
