var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notification-setting"},[_c('v-divider',{attrs:{"color":"#EBF2F5 "}}),_c('div',{staticClass:"py-10 px-5",staticStyle:{"background-color":"white"}},[_c('p',{staticClass:"sub-heading"},[_vm._v("Driver Notification")]),_c('div',{staticClass:"item-main mt-4"},[_c('div',{staticClass:"item"},[_vm._m(0),_c('div',{staticClass:"item-right-section"},[[_c('v-checkbox',{staticClass:"notify-type mr-3",attrs:{"label":"Email"},on:{"click":function($event){return _vm.showEmaiList(
                  'sc_accepted',
                  _vm.getCompanyProfile.email_sc_accepted
                )}},model:{value:(_vm.getCompanyProfile.email_sc_accepted),callback:function ($$v) {_vm.$set(_vm.getCompanyProfile, "email_sc_accepted", $$v)},expression:"getCompanyProfile.email_sc_accepted"}})],_c('div',{staticClass:"vertical-separtor"}),_c('v-checkbox',{staticClass:"ml-3 notify-type",attrs:{"input-value":_vm.getCompanyProfile.notifi_sc_accepted !== 'OFF',"label":"Push Notification"},on:{"click":function($event){return _vm.driverAcceptMenu()}}}),_c('v-select',{ref:"mySelect",staticClass:"item-notif-settings",class:_vm.getCompanyProfile.notifi_sc_accepted !== 'OFF' ? 'active' : '',attrs:{"id":"test","items":_vm.stateData.driverAccNotif,"menu-props":{ contentClass: 'notifi-menu-list' },"append-icon":"ic-chevron-down"},on:{"click":_vm.setDriverAcceptNotifTime,"change":_vm.updateDriverAccept},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"d-flex justify-start",staticStyle:{"border":"none","min-height":"45px","height":"45px"}},'v-list-item',attrs,false),on),[(item == 'Custom Time')?_c('div',{on:{"click":function($event){return _vm.openCustomTimeDialog('sc_accepted')}}},[_c('p',{staticClass:"name font-regular",staticStyle:{"color":"#4a4a4a"}},[_vm._v(" "+_vm._s(item)+" ")])]):_c('div',[_c('p',{staticClass:"name font-regular",staticStyle:{"color":"#4a4a4a"}},[_vm._v(" "+_vm._s(item)+" ")])])])]}}]),model:{value:(_vm.driverAcceptNotif),callback:function ($$v) {_vm.driverAcceptNotif=$$v},expression:"driverAcceptNotif"}})],2)]),(_vm.getCompanyProfile.email_sc_accepted)?_c('div',{staticClass:"item-description"},[_c('p',{staticClass:"email-text"},[_vm._v("Send Email to:")]),_vm._l((JSON.parse(
            _vm.getCompanyProfile.recip_sc_accepted
          )),function(item,index){return _c('div',{key:index,staticClass:"email-descp"},[_c('p',[_vm._v(_vm._s(item))])])}),_c('button',{on:{"click":function($event){return _vm.showDailog(
              _vm.getCompanyProfile.recip_sc_accepted,
              'Driver Accept Load'
            )}}},[_c('p',[_vm._v("Edit")])])],2):_vm._e()]),_c('div',{staticClass:"item-main mt-4"},[_c('div',{staticClass:"item"},[_vm._m(1),_c('div',{staticClass:"item-right-section"},[_c('v-checkbox',{staticClass:"notify-type mr-3",attrs:{"label":"Email"},on:{"click":function($event){return _vm.showEmaiList('sc_rejected', _vm.getCompanyProfile.email_sc_rejected)}},model:{value:(_vm.getCompanyProfile.email_sc_rejected),callback:function ($$v) {_vm.$set(_vm.getCompanyProfile, "email_sc_rejected", $$v)},expression:"getCompanyProfile.email_sc_rejected"}}),_c('div',{staticClass:"vertical-separtor"}),_c('v-checkbox',{staticClass:"ml-3 notify-type",attrs:{"input-value":_vm.getCompanyProfile.notifi_sc_rejected !== 'OFF',"label":"Push Notification"},on:{"click":function($event){return _vm.driverRejectMenu()}}}),_c('v-select',{ref:"driverReject",staticClass:"item-notif-settings",class:_vm.getCompanyProfile.notifi_sc_rejected !== 'OFF' ? 'active' : '',attrs:{"items":_vm.stateData.driverRejectNotif,"append-icon":"ic-chevron-down","menu-props":{ contentClass: 'notifi-menu-list' }},on:{"click":_vm.setDriverRejectNotifTime,"change":_vm.updateDriverReject},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"d-flex justify-start",staticStyle:{"border":"none","min-height":"45px","height":"45px"}},'v-list-item',attrs,false),on),[(item == 'Custom Time')?_c('div',{on:{"click":function($event){return _vm.openCustomTimeDialog('sc_rejected')}}},[_c('p',{staticClass:"name font-regular",staticStyle:{"color":"#4a4a4a"}},[_vm._v(" "+_vm._s(item)+" ")])]):_c('div',[_c('p',{staticClass:"name font-regular",staticStyle:{"color":"#4a4a4a"}},[_vm._v(" "+_vm._s(item)+" ")])])])]}}]),model:{value:(_vm.driverRejectNotif),callback:function ($$v) {_vm.driverRejectNotif=$$v},expression:"driverRejectNotif"}})],1)]),(_vm.getCompanyProfile.email_sc_rejected)?_c('div',{staticClass:"item-description"},[_c('p',{staticClass:"email-text"},[_vm._v("Send Email to:")]),_vm._l((JSON.parse(
            _vm.getCompanyProfile.recip_sc_rejected
          )),function(data,index){return _c('div',{key:index,staticClass:"email-descp"},[_c('p',[_vm._v(_vm._s(data))])])}),_c('button',{on:{"click":function($event){return _vm.showDailog(
              _vm.getCompanyProfile.recip_sc_rejected,
              'Driver Reject Load'
            )}}},[_c('p',[_vm._v("Edit")])])],2):_vm._e()])]),_c('div',{staticClass:"py-5 px-5",staticStyle:{"background-color":"white"}},[_c('p',{staticClass:"sub-heading"},[_vm._v("Customer Notification")]),_c('div',{staticClass:"item-main mt-4"},[_c('div',{staticClass:"item"},[_vm._m(2),_c('div',{staticClass:"item-right-section"},[_c('v-checkbox',{staticClass:"notify-type mr-3",attrs:{"label":"Email"},on:{"click":function($event){return _vm.showEmaiList(
                'low_customer_activity',
                _vm.getCompanyProfile.email_low_customer_activity
              )}},model:{value:(_vm.getCompanyProfile.email_low_customer_activity),callback:function ($$v) {_vm.$set(_vm.getCompanyProfile, "email_low_customer_activity", $$v)},expression:"getCompanyProfile.email_low_customer_activity"}}),_c('div',{staticClass:"vertical-separtor"}),_c('v-checkbox',{staticClass:"ml-3 notify-type",attrs:{"input-value":_vm.getCompanyProfile.notifi_low_customer_activity !== 'OFF',"label":"Push Notification"},on:{"click":function($event){return _vm.lowCustomerMenu()}}}),_c('v-select',{ref:"customerActivity",staticClass:"item-notif-settings",class:_vm.getCompanyProfile.notifi_low_customer_activity !== 'OFF'
                ? 'active'
                : '',attrs:{"items":_vm.stateData.lowCustomer,"menu-props":{ contentClass: 'notifi-menu-list' },"append-icon":"ic-chevron-down"},on:{"click":_vm.setLowCustomerActivty,"change":_vm.updateLowCustomer},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"d-flex justify-start",staticStyle:{"border":"none","min-height":"45px","height":"45px"}},'v-list-item',attrs,false),on),[(item == 'Custom Time')?_c('div',{on:{"click":function($event){return _vm.openCustomTimeDialog('low_customer')}}},[_c('p',{staticClass:"name font-regular",staticStyle:{"color":"#4a4a4a"}},[_vm._v(" "+_vm._s(item)+" ")])]):_c('div',[_c('p',{staticClass:"name font-regular",staticStyle:{"color":"#4a4a4a"}},[_vm._v(" "+_vm._s(item)+" ")])])])]}}]),model:{value:(_vm.lowCustomerNotif),callback:function ($$v) {_vm.lowCustomerNotif=$$v},expression:"lowCustomerNotif"}})],1)]),(_vm.getCompanyProfile.email_low_customer_activity)?_c('div',{staticClass:"item-description"},[_c('p',{staticClass:"email-text"},[_vm._v("Send Email to:")]),_vm._l((JSON.parse(
            _vm.getCompanyProfile.recip_low_customer_activity
          )),function(data,index){return _c('div',{key:index,staticClass:"email-descp"},[_c('p',[_vm._v(_vm._s(data))])])}),_c('button',{on:{"click":function($event){return _vm.showDailog(
              _vm.getCompanyProfile.recip_low_customer_activity,
              'Low Customer Activity'
            )}}},[_c('p',[_vm._v("Edit")])])],2):_vm._e()])]),_c('div',{staticClass:"py-5 px-5",staticStyle:{"background-color":"white"}},[_c('p',{staticClass:"sub-heading"},[_vm._v("Delivery Order Notification")]),_c('div',{staticClass:"item-main mt-4"},[_c('div',{staticClass:"item"},[_vm._m(3),_c('div',{staticClass:"item-right-section"},[_c('v-checkbox',{staticClass:"notify-type mr-3",attrs:{"label":"Email"},on:{"click":function($event){return _vm.showEmaiList(
                'do_recived_from_customer',
                _vm.getCompanyProfile.email_do_recived_from_customer
              )}},model:{value:(_vm.getCompanyProfile.email_do_recived_from_customer),callback:function ($$v) {_vm.$set(_vm.getCompanyProfile, "email_do_recived_from_customer", $$v)},expression:"getCompanyProfile.email_do_recived_from_customer"}}),_c('div',{staticClass:"vertical-separtor"}),_c('v-checkbox',{staticClass:"ml-3 notify-type",attrs:{"input-value":_vm.getCompanyProfile.notifi_do_recived_from_customer !== 'OFF',"label":"Push Notification"},on:{"click":function($event){return _vm.DORecivedCustomerMenu()}}}),_c('v-select',{ref:"DORecivedCustomer",staticClass:"item-notif-settings",class:_vm.getCompanyProfile.notifi_do_recived_from_customer !== 'OFF'
                ? 'active'
                : '',attrs:{"items":_vm.stateData.doRecivedFromCustomer,"menu-props":{ contentClass: 'notifi-menu-list' },"append-icon":"ic-chevron-down"},on:{"click":_vm.setDORecivedCustomerAct,"change":_vm.updateDORecivedCustomer},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"d-flex justify-start",staticStyle:{"border":"none","min-height":"45px","height":"45px"}},'v-list-item',attrs,false),on),[(item == 'Custom Time')?_c('div',{on:{"click":function($event){return _vm.openCustomTimeDialog('do_recived_from_customer')}}},[_c('p',{staticClass:"name font-regular",staticStyle:{"color":"#4a4a4a"}},[_vm._v(" "+_vm._s(item)+" ")])]):_c('div',[_c('p',{staticClass:"name font-regular",staticStyle:{"color":"#4a4a4a"}},[_vm._v(" "+_vm._s(item)+" ")])])])]}}]),model:{value:(_vm.doRecivedFromCustomerNotif),callback:function ($$v) {_vm.doRecivedFromCustomerNotif=$$v},expression:"doRecivedFromCustomerNotif"}})],1)]),(_vm.getCompanyProfile.email_do_recived_from_customer)?_c('div',{staticClass:"item-description"},[_c('p',{staticClass:"email-text"},[_vm._v("Send Email to:")]),_vm._l((JSON.parse(
            _vm.getCompanyProfile.recip_do_recived_from_customer
          )),function(data,index){return _c('div',{key:index,staticClass:"email-descp"},[_c('p',[_vm._v(_vm._s(data))])])}),_c('button',{on:{"click":function($event){return _vm.showDailog(
              _vm.getCompanyProfile.recip_do_recived_from_customer,
              'DO Recived From Customer'
            )}}},[_c('p',[_vm._v("Edit")])])],2):_vm._e()]),_c('div',{staticClass:"item-main mt-4"},[_c('div',{staticClass:"item"},[_vm._m(4),_c('div',{staticClass:"item-right-section"},[_c('v-checkbox',{staticClass:"notify-type mr-3",attrs:{"label":"Email"},on:{"click":function($event){return _vm.showEmaiList(
                'do_recived_from_shifl',
                _vm.getCompanyProfile.email_do_recived_from_shifl
              )}},model:{value:(_vm.getCompanyProfile.email_do_recived_from_shifl),callback:function ($$v) {_vm.$set(_vm.getCompanyProfile, "email_do_recived_from_shifl", $$v)},expression:"getCompanyProfile.email_do_recived_from_shifl"}}),_c('div',{staticClass:"vertical-separtor"}),_c('v-checkbox',{staticClass:"ml-3 notify-type",attrs:{"input-value":_vm.getCompanyProfile.notifi_do_recived_from_shifl !== 'OFF',"label":"Push Notification"},on:{"click":function($event){return _vm.DORecivedFromShiflMenu()}}}),_c('v-select',{ref:"DORecivedShifl",staticClass:"item-notif-settings",class:_vm.getCompanyProfile.notifi_do_recived_from_shifl !== 'OFF'
                ? 'active'
                : '',attrs:{"items":_vm.stateData.doRecivedFromShifl,"menu-props":{ contentClass: 'notifi-menu-list' },"append-icon":"ic-chevron-down"},on:{"click":_vm.setDORecivedFromShiflAct,"change":_vm.updateDORecivedFromShifl},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"d-flex justify-start",staticStyle:{"border":"none","min-height":"45px","height":"45px"}},'v-list-item',attrs,false),on),[(item == 'Custom Time')?_c('div',{on:{"click":function($event){return _vm.openCustomTimeDialog('do_recived_from_shifl')}}},[_c('p',{staticClass:"name font-regular",staticStyle:{"color":"#4a4a4a"}},[_vm._v(" "+_vm._s(item)+" ")])]):_c('div',[_c('p',{staticClass:"name font-regular",staticStyle:{"color":"#4a4a4a"}},[_vm._v(" "+_vm._s(item)+" ")])])])]}}]),model:{value:(_vm.doRecivedFromShiflNotif),callback:function ($$v) {_vm.doRecivedFromShiflNotif=$$v},expression:"doRecivedFromShiflNotif"}})],1)]),(_vm.getCompanyProfile.email_do_recived_from_shifl)?_c('div',{staticClass:"item-description"},[_c('p',{staticClass:"email-text"},[_vm._v("Send Email to:")]),_vm._l((JSON.parse(
            _vm.getCompanyProfile.recip_do_recived_from_shifl
          )),function(data,index){return _c('div',{key:index,staticClass:"email-descp"},[_c('p',[_vm._v(_vm._s(data))])])}),_c('button',{on:{"click":function($event){return _vm.showDailog(
              _vm.getCompanyProfile.recip_do_recived_from_shifl,
              'DO Recived From Shifl'
            )}}},[_c('p',[_vm._v("Edit")])])],2):_vm._e()])]),_c('ContainerTrackingNotification',{ref:"childRef",on:{"showEmailDailog":_vm.showEmailDailog,"showEmaiList":_vm.showEmaiList,"handleClickOutside":_vm.handleClickOutside}}),_c('EditNotificationUser'),_c('CustomTime')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"item-heading"},[_vm._v("Driver Accept Load")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"item-heading"},[_vm._v("Driver Reject Load")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"item-heading"},[_vm._v("Low Customer Activity")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"item-heading"},[_vm._v("DO Recived From Customer")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"item-heading"},[_vm._v("DO Recived From Shifl")])])}]

export { render, staticRenderFns }