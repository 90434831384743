import { calculateFilterExpressionFunction } from './compactViewMethods'
import moment from "moment";

// calculate filter expressions
export function calculateFilterExpressionContainer(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "contains", target, 'trucker_container', this
    )
}

export function calculateFilterExpressionMBL(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "contains", target, 'mbl_num', this
    )
}

export function calculateFilterExpressionSize(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "=", target, 'container_size_id', this
    )
}

export function calculateFilterExpressionCustomer(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "=", target, 'customer_id', this
    )
}

export function calculateFilterExpressionCarrier(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "=", target, 'carrier_id', this
    )
}

export function calculateFilterExpressionDO(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "contains", target, 'deliveryorder.shifl_ref', this
    )
}

export function calculateFilterExpressionRef(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "contains", target, 'shifl_ref', this
    )
}

export function calculateFilterExpressionConsignee(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "=", target, 'deliveryorder.consignee_id', this
    )
}

export function calculateFilterExpressionEta(filterValue, selectedFilterOperations, target) {
    let convertValue = moment(filterValue).format("YYYY-MM-DD")
    return calculateFilterExpressionFunction(
        convertValue, "=", target, 'eta', this
    )
}

export function calculateFilterExpressionLfd(filterValue, selectedFilterOperations, target) {
    let convertValue = moment(filterValue).format("YYYY-MM-DD")
    return calculateFilterExpressionFunction(
        convertValue, "=", target, 'last_free_date', this
    )
}

export function calculateFilterExpressionPdd(filterValue, selectedFilterOperations, target) {
    let convertValue = moment(filterValue).format("YYYY-MM-DD")
    return calculateFilterExpressionFunction(
        convertValue, "=", target, 'per_diem_date', this
    )
}

export function calculateFilterExpressionType(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "=", target, 'is_export', this
    )
}

export function calculateFilterExpressionLocation(filterValue, selectedFilterOperations, target) {			
    let fieldName = "dispatch_schedule"
    let operation = "contains"
    if (this.currentSelectTab !== "At Yard") {
        if (this.currentSelectTab === "Scheduled") {
            fieldName = "dispatch.firstUnarrivedLeg.pickup_location"
        } else {
            fieldName = "terminal_id"
            operation = "="
        }        
    }

    return calculateFilterExpressionFunction(
        filterValue, operation, target, fieldName, this
    )
}

export function calculateFilterExpressionDispatchDriver(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        // filterValue, "=", target, "dispatch.legs.driver_id", this
        filterValue, "=", target, "dispatch.firstUnarrivedLeg.driver_id", this
    )
}

export function calculateFilterExpressionDispatchMode(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        // filterValue, "=", target, "dispatch.legs.mode", this
        filterValue, "=", target, "dispatch.firstUnarrivedLeg.mode", this
    )
}

export function calculateFilterExpressionDispatchDeliveryType(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        // filterValue, "=", target, "dispatch.legs.type", this
        filterValue, "=", target, "dispatch.firstUnarrivedLeg.type", this
    )
}

// not sure with the field names
export function calculateFilterExpressionPickupTime(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "contains", target, 'dispatch.firstUnarrivedLeg.pickup_scheduled_date_time', this
    )
}

export function calculateFilterExpressionDeliveryTime(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "contains", target, 'dispatch.firstUnarrivedLeg.delivery_scheduled_date_time', this
    )
}

export function calculateFilterExpressionDeliveryOn(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "contains", target, 'dispatch.firstUnarrivedLeg.address', this
    )
}

export function calculateFilterExpressionDispatchStatus(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "=", target, 'dispatch.firstUnarrivedLeg.status', this
    )
}

export function calculateFilterExpressionTabStatus(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "=", target, 'tabStatus', this
    )
}

export function calculateFilterExpressionOpened(filterValue, selectedFilterOperations, target) {
    let convertValue = moment(filterValue).format("YYYY-MM-DD")
    return calculateFilterExpressionFunction(
        convertValue, "contains", target, "opened_at", this
    )
}

export function calculateFilterExpressionClosed(filterValue, selectedFilterOperations, target) {
    let convertValue = moment(filterValue).format("YYYY-MM-DD")
    return calculateFilterExpressionFunction(
        convertValue, "contains", target, "closed_at", this
    )
}

export function calculateFilterExpressionArrived(filterValue, selectedFilterOperations, target) {
    let convertValue = moment(filterValue).format("YYYY-MM-DD hh:mm A")
    return calculateFilterExpressionFunction(
        convertValue, "contains", target, "arrived_at", this
    )
}

export function calculateFilterExpressionPickedUp(filterValue, selectedFilterOperations, target) {
    let convertValue = moment(filterValue).format("YYYY-MM-DD hh:mm A")
    return calculateFilterExpressionFunction(
        convertValue, "contains", target, "picked_up", this
    )
}

export function calculateFilterExpressionDelivered(filterValue, selectedFilterOperations, target) {
    let convertValue = moment(filterValue).format("YYYY-MM-DD hh:mm A")
    return calculateFilterExpressionFunction(
        convertValue, "contains", target, "delivered_on", this
    )
}

export function calculateFilterExpressionEmptyReturned(filterValue, selectedFilterOperations, target) {
    let convertValue = moment(filterValue).format("YYYY-MM-DD hh:mm A")
    return calculateFilterExpressionFunction(
        convertValue, "contains", target, "empty_returned", this
    )
}

export function calculateFilterExpressionPrepull(filterValue, selectedFilterOperations, target) {
    return calculateFilterExpressionFunction(
        filterValue, "contains", target, "prepull_status", this
    )
}
