export default {
  dropped_empty_tab: [{
      text: "Container#",
      align: "left",
      sortable: true,
      value: "shifl_ref",
      // width: "7%",
      width: "145px",
      fixed: true
    },
    {
      text: "MBL#",
      align: "start",
      sortable: false,
      value: "mbl_num",
      // width: "7%",
      width: "170px",
      fixed: true
    },
    {
      text: "Size",
      align: "start",
      sortable: false,
      value: "customer.container_size_id",
      // width: "5%",
      width: "70px",
      fixed: true
    },
    {
      text: "Customer",
      align: "start",
      sortable: false,
      value: "customer.company_name",
      // width: "14%",
      width: "160px",
      fixed: true
    },
    {
      text: "Carrier & Per Diem Date",
      align: "start",
      sortable: false,
      value: "carrier_per_diem_date",
      width: "7%",
      fixed: true
    },
    {
      text: "Arrived",
      align: "start",
      sortable: true,
      value: "time_arrival",
      width: "12%",
      fixed: true
    },
    {
      text: "Type",
      align: "start",
      sortable: true,
      value: "type",
      width: "5%",
      fixed: true
    },
    {
      text: "Scheduled",
      align: "start",
      sortable: true,
      value: "scheduled",
      width: "8%",
      fixed: true
    },
  ],
  available_tab: [{
      text: "Container#",
      align: "left",
      sortable: true,
      value: "shifl_ref",
      // width: "8%",
      width: "145px",
      fixed: true
    },
    {
      text: "MBL# / Carrier",
      align: "start",
      sortable: false,
      value: "customer.company_name",
      // width: "10%",
      width: "170px",
      fixed: true
    },
    {
      text: "Size",
      align: "start",
      sortable: false,
      value: "customer.container_size_id",
      // width: "5%",
      width: "70px",
      fixed: true
    },
    {
      text: "Customer",
      align: "start",
      sortable: false,
      value: "customer.company_name",
      // width: "12%",
      width: "160px",
      fixed: true
    },
    {
      text: "ETA",
      align: "start",
      sortable: true,
      value: "ETA",
      width: "9%",
      fixed: true
    },
    {
      text: "LFD",
      align: "start",
      sortable: true,
      value: "last_free_date",
      width: "9%",
      fixed: true
    },
    {
      // text: "Prepull/Direct",
      text: "",
      align: "start",
      sortable: false,
      value: "direct_or_prepul",
      width: "5%",
      fixed: true
    },
    {
      text: "Terminal At",
      align: "start",
      sortable: false,
      value: "terminal.name",
      width: "14%",
      fixed: true
    },
    {
      text: "Scheduled",
      align: "start",
      sortable: true,
      value: "scheduled",
      width: "11%",
      fixed: true
    },
    {
      text: "",
      align: "center",
      sortable: false,
      // width: "4%",
      fixed: true
    },
  ],
  pending_tab: [{
      text: "Container#",
      align: "left",
      sortable: false,
      value: "shifl_ref",
      // width: "6%",
      width: "145px",
      fixed: true
    },
    {
      text: "MBL# / Carrier",
      align: "start",
      sortable: false,
      value: "customer.company_name",
      // width: "10%",
      width: "170px",
      fixed: true
    },    
    {
      text: "Size",
      align: "start",
      sortable: false,
      value: "customer.container_size_id",
      // width: "5%",
      width: "70px",
      fixed: true
    },
    {
      text: "Customer",
      align: "start",
      sortable: false,
      value: "customer.company_name",
      // width: "12%",
      width: "160px",
      fixed: true
    },
    {
      text: "ETA",
      align: "start",
      sortable: true,
      value: "ETA",
      width: "10%",
      fixed: true
    },
    {
      text: "LFD",
      align: "start",
      sortable: true,
      value: "last_free_date",
      width: "10%",
      fixed: true
    },
    {
      // text: "Prepull/Direct",
      text: "",
      align: "start",
      sortable: false,
      value: "direct_or_prepul",
      width: "5%",
      fixed: true
    },
    {
      text: "Terminal At",
      align: "start",
      sortable: false,
      value: "terminal.name",
      width: "15%",
      fixed: true
    },
    {
      text: "Discharged",
      align: "start",
      sortable: false,
      value: "discharged",
      width: "2%",
      fixed: true,
      class: "header-icons-template"
    },
    {
      text: "Pre Gate Fees",
      align: "start",
      sortable: false,
      value: "pier_pass_paid",
      width: "2%",
      fixed: true,
      class: "header-icons-template"
    },
    {
      text: "Released",
      align: "start",
      sortable: false,
      value: "fully_released",
      width: "2%",
      fixed: true,
      class: "header-icons-template"
    },
    {
      text: "",
      align: "end",
      sortable: false,
      // width: "4%",
      fixed: true
    },
  ],
  scheduled_tab: [{
      text: "Container#",
      align: "left",
      sortable: false,
      value: "shifl_ref",
      // width: "12%",
      width: "145px",
      fixed: true
    },
    {
      text: "MBL# / Carrier",
      align: "start",
      sortable: false,
      value: "mbl_num",
      // width: "12%",
      width: "170px",
      fixed: true
    },
    // {
    //   text: "Customer",
    //   align: "start",
    //   sortable: false,
    //   value: "customer.company_name",
    //   width: "9%",
    //   fixed: true
    // },
    {
      text: "Size",
      align: "start",
      sortable: false,
      value: "customer.container_size_id",
      // width: "5%",
      width: "70px",
      fixed: true
    },
    {
      // text: "LFD/PDD",
      text: "LFD P/R",
      align: "start",
      value: "lfd_pdd",
      sortable: true,
      width: "10%",
      fixed: true,
      class: "header-icons-template custom"
    },
    {
      // text: "Scheduled ID/ Type/ Mode",
      text: "Schedule",
      align: "start",
      sortable: false,
      value: "trucker_container",
      width: "12%",
      fixed: true
    },
    {
      text: "Pickup On",
      align: "start",
      sortable: true,
      value: "Pickup_On",
      width: "16%",
      fixed: true,
      class: "header-icons-template custom"
    },
    {
      text: "Delivery On",
      align: "start",
      sortable: true,
      // value: "terminal.name",
      value: "Delivery_On",
      width: "14%",
      fixed: true,
      class: "header-icons-template custom"
    },
    {
      // text: "Assigned",
      text: "Driver",
      align: "start",
      sortable: false,
      value: "deliver_to",
      width: "9%",
      fixed: true
    },
    {
      // text: "Assigned",
      text: "Status",
      align: "center",
      sortable: false,
      value: "status",
      width: "9%",
      fixed: true
    },
    // {
    //   text: "Status",
    //   align: "start",
    //   sortable: false,
    //   value: "legs.driver_status",
    //   width: "5%",
    //   fixed: true
    // },
    {
      text: "",
      align: "center",
      sortable: false,
      width: "6%",
      fixed: true
    },
  ],
  at_the_customer_tab: [{
      text: "Container#",
      align: "left",
      sortable: false,
      value: "shifl_ref",
      // width: "6%",
      width: "145px",
      fixed: true
    },
    {
      text: "MBL#",
      align: "start",
      sortable: false,
      value: "mbl_num",
      // width: "7%",
      width: "170px",
      fixed: true
    },
    {
      text: "Size",
      align: "start",
      sortable: false,
      value: "customer.container_size_id",
      // width: "6%",
      width: "70px",
      fixed: true
    },
    {
      text: "Customer",
      align: "start",
      sortable: false,
      value: "customer.company_name",
      // width: "8%",
      width: "160px",
      fixed: true
    },
    {
      text: "Carrier & Per Diem Date",
      align: "start",
      sortable: false,
      value: "item.carrier",
      width: "15%",
      fixed: true
    },
    {
      text: "Time Arrived",
      align: "start",
      sortable: true,
      value: "ETA",
      width: "16%",
      fixed: true
    },
    {
      text: "Mode",
      align: "start",
      sortable: false,
      value: "terminal.name",
      width: "5%",
      fixed: true
    },
    {
      text: "Empty",
      align: "center  ",
      sortable: false,
      value: "deliver_to",
      width: "4%",
      fixed: true
    },
    {
      text: "Scheduled",
      align: "start",
      sortable: false,
      value: "deliver_to",
      width: "9%",
      fixed: true
    },
    {
      text: "",
      align: "start",
      sortable: false,
      value: "action",
      // width: "6%",
      fixed: true
    },
  ],
  in_yard_full: [{
      text: "Container#",
      align: "left",
      sortable: false,
      value: "shifl_ref",
      // width: "8%",
      width: "145px",
      fixed: true
    },
    {
      text: "MBL#",
      align: "start",
      sortable: false,
      value: "mbl_num",
      // width: "8%",
      width: "170px",
      fixed: true
    },
    {
      text: "Size",
      align: "start",
      sortable: false,
      value: "customer.container_size_id",
      // width: "5%",
      width: "70px",
      fixed: true
    },
    {
      text: "Customer",
      align: "start",
      sortable: false,
      value: "customer.company_name",
      // width: "10%",
      width: "160px",
      fixed: true
    },
    {
      text: "Carrier & Per Diem Date",
      align: "start",
      sortable: false,
      value: "per_diem_date",
      width: "18%",
      fixed: true
    },
    {
      text: "Time Arrived",
      align: "start",
      sortable: true,
      value: "ETA",
      width: "12%",
      fixed: true
    },
    {
      text: "Scheduled",
      align: "start",
      sortable: false,
      value: "deliver_to",
      width: "12%",
      fixed: true
    },
    {
      text: "",
      align: "center",
      sortable: false,
      value: "deliver_to",
      // width: "6%",
      fixed: true
    },
  ],
  all_tab : [
    {
      text: "Container#",
      align: "start",
      sortable: true,
      value: "shifl_ref",
      // width: "15%",
      width: "145px",
      fixed: true
    },
    {
      text: "MBL# / Carrier",
      align: "start",
      sortable: false,
      value: "mbl_num",
      // width: "20%",
      width: "170px",
      fixed: true
    },
    {
      text: "Size",
      align: "start",
      sortable: false,
      value: "customer.container_size_id",
      // width: "10%",
      width: "70px",
      fixed: true
    },
    {
      text: "Customer",
      align: "start",
      sortable: false,
      value: "customer.company_name",
      // width: "20%",
      width: "200px",
      fixed: true
    },
    {
      text: "ETA",
      align: "start",
      sortable: true,
      value: "ETA",
      width: "12%",
      fixed: true
    },
    {
      text: "LFD",
      align: "start",
      sortable: true,
      value: "terminal.name",
      width: "12%",
      fixed: true
    },
    {
      text: "",
      align: "center",
      sortable: false,
      value: "actions",
      // width: "7%",
      fixed: true
    },


  ],
  default: [{
      text: "Container#",
      align: "left",
      sortable: true,
      value: "shifl_ref",
      // width: "9%",
      width: "145px",
      fixed: true
    },
    {
      text: "MBL# / Carrier",
      align: "start",
      sortable: false,
      value: "mbl_num",
      // width: "9%",
      width: "170px",
      fixed: true
    },
    {
      text: "Size",
      align: "start",
      sortable: false,
      value: "customer.container_size_id",
      // width: "7%",
      width: "70px",
      fixed: true
    },
    {
      text: "Customer",
      align: "start",
      sortable: false,
      value: "customer.company_name",
      // width: "13%",
      width: "200px",
      fixed: true
    },
    {
      text: "ETA",
      align: "start",
      sortable: true,
      value: "ETA",
      width: "12%",
      fixed: true
    },
    {
      text: "Terminal At",
      align: "start",
      sortable: false,
      value: "terminal.name",
      width: "16%",
      fixed: true
    },
    {
      text: "",
      align: "center",
      sortable: false,
      value: "actions",
      // width: "4%",
      fixed: true
    },
  ]
}
