<template>
  <div class="containers-wrappers" v-resize="onResize">
    <ViewContainer
      :items="getdata"
      mode="view"
      @saveandanother="saveandAnother"
      :isMobile="isMobile"
      v-if="renderComponent"
    />
  </div>
</template>
<script>
import ViewContainer from "../components/Tables/Containers/View/index.vue";
import { mapGetters, mapActions } from "vuex";
import globalMethods from "../utils/globalMethods";
export default {
  name: "createContainers",
  components: {
    ViewContainer,
  },
  data: () => ({
    renderComponent: true,
    dataFormfield: [],
    isMobile: false,
    dialog: false,
    dialogView: false,
    editedIndex: -1,
    editedItem: {
      invoice_no: "",
      invoice_date: "",
      shipment_reference: "",
      due_date: "",
      amuont: "",
    },
    defaultItem: {
      invoice_no: "",
      invoice_date: "",
      shipment_reference: "",
      due_date: "",
      amuont: "",
    },
    items: [
      {
        invoice_no: "1234567890",
        invoice_date: "Mar 13, 2021",
        shipment_reference: "#76KS091",
        due_date: "Mar 21, 2021",
        amount: "$5,689.00",
        paid: false,
        date_paid: null,
        status: "All Invoices",
        billing_status: ["All Invoices", "Unpaid"],
      },
      {
        invoice_no: "1234567891",
        invoice_date: "Mar 15, 2021",
        shipment_reference: "#76KS091",
        due_date: "Mar 22, 2021",
        amount: "$6,689.00",
        paid: false,
        date_paid: null,
        status: "All Invoices",
        billing_status: ["All Invoices", "Unpaid"],
      },
      {
        invoice_no: "1234567892",
        invoice_date: "Mar 16, 2021",
        shipment_reference: "#76KS091",
        due_date: "Mar 24, 2021",
        amount: "$7,020.00",
        paid: false,
        date_paid: null,
        status: "All Invoices",
        billing_status: ["All Invoices", "Unpaid"],
      },
      {
        invoice_no: "12345678913",
        invoice_date: "Apr 13, 2021",
        shipment_reference: "#76KS091",
        due_date: "Apr 21, 2021",
        amount: "$8,689.00",
        paid: false,
        date_paid: null,
        status: "Unpaid",
        billing_status: ["All Invoices", "Unpaid"],
      },
      {
        invoice_no: "1234567894",
        invoice_date: "May 13, 2021",
        shipment_reference: "#76KS091",
        due_date: "May 21, 2021",
        amount: "$4,000.00",
        paid: false,
        date_paid: null,
        status: "Unpaid",
        billing_status: ["All Invoices", "Unpaid"],
      },
      {
        invoice_no: "1234567895",
        invoice_date: "Jun 13, 2021",
        shipment_reference: "#76KS091",
        due_date: "Jun 21, 2021",
        amount: "$5,689.00",
        paid: true,
        date_paid: "Jun 6, 2021",
        status: "Paid",
        billing_status: ["All Invoices", "Paid"],
      },
    ],
  }),
  computed: {
    ...mapGetters({
      formdetails: "containers/getcontainerFormDetails",
      getPoLoading: "containers/getPoLoading",
    }),
    getdata() {
      return this.formdetails;
    },
  },
  methods: {
    ...mapActions({
      fetchFormdetails: "containers/fetchallformdetails",
    }),
    ...globalMethods,
    onResize() {
      if (window.innerWidth < 769) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    viewPayment(item) {
      this.dialogView = true;
      this.editedItem = Object.assign({}, item);
    },
    saveandAnother() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    closeView() {
      this.dialogView = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },

  mounted() {
    //set current pages
    this.fetchFormdetails();

    this.$store.dispatch("page/setPage", "containers");
  },
};
</script>

<style lang="scss">
@import "../assets/scss/pages_scss/containers/containers.scss";
@import "../assets/scss/buttons.scss";
</style>
