import { render, staticRenderFns } from "./NewDrivers.vue?vue&type=template&id=5a04be1e&"
import script from "./NewDrivers.vue?vue&type=script&lang=js&"
export * from "./NewDrivers.vue?vue&type=script&lang=js&"
import style0 from "./NewDrivers.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
