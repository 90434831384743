<template>
  <div class="text-center">
    <v-dialog
      persistent
      v-model="AddEquipment"
      max-width="590px"
      content-class="add-supplier-dialog add-driver-dialog  dialogYard"
      :retain-focus="false"
    >
      <v-card class="add-supplier-card">
        <v-form
          id="createdriver"
          ref="form"
          v-model="valid"
          action="#"
          @submit.prevent=""
        >
          <v-card-title style="caret-color: transparent">
            <span class="headline">Add Equipment </span>
            <button icon dark class="btn-close" @click="close">
              <v-icon>mdi-close</v-icon>
            </button>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-row>
              <v-col
                style="color: red"
                v-if="errorFound"
                cols="12"
                sm="12"
                md="12"
                class="pb-3 pt-1"
              >
                {{ errorMessage }}
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-3 pt-1">
                <label class="text-item-label">Number</label>
                <v-text-field
                  outlined
                  dense
                  placeholder="Enter Chassis Number"
                  v-on:keypress="ISALPHANUMERIC($event)"
                  :rules="inputRules"
                  v-model="number"
                  name="name"
                  hide-details="auto"
                  style="font-size: 14px !important"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" class="pt-0 pb-0" md="12">
                <label class="text-item-label">Chassis Type</label>
                <v-autocomplete
                  v-model="chassis_type_id"
                  placeholder="Select Chassis Type"
                  item-text="name"
                  item-value="id"
                  :items="getChassisType"
                  background-color="white"
                  :rules="selectRules"
                  outlined
                  hide-details="auto"
                  append-icon="ic-chevron-down"
                  dense
                ></v-autocomplete>
                <!-- <v-autocomplete
                  :rules="selectRules"
                  v-model="chassis_type_id"
                  :items="getChassisType"
                  item-text="name"
                  item-value="id"
                  class="custom-select"
                  placeholder="Select Chassis Type"
                  append-icon="ic-chevron-down"
                >
                </v-autocomplete> -->
              </v-col>
              <v-col
                cols="12"
                sm="12"
                class="pt-0"
                md="12"
                style="margin-top: 0px"
              >
                <label class="text-item-label">Company Owned</label>
                <v-radio-group v-model="trucker_owned" class="mt-0">
                  <v-radio
                    label="Yes"
                    class="radio-btn custom-radio"
                    :value="true"
                  ></v-radio>
                  <v-radio
                    label="No"
                    class="radio-btn custom-radio"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions
            style="
              padding-left: 4px;
              padding-left: 11px;
              margin-top: 5px;
              border-top: 2px solid #ebf2f5;
            "
          >
            <div class="text-right buttonActions" style="padding: 0px">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6 ml-4">
                      <v-btn class="btn-blue" text @click="save">
                        <span>
                          <span color="#fff">
                            <v-progress-circular
                              :size="15"
                              color="#fff"
                              :width="2"
                              indeterminate
                              v-if="getCreateChassisLoading"
                              style="margin-right: 3px"
                            >
                            </v-progress-circular>
                            Add Equipment
                          </span>
                        </span>
                      </v-btn>
                    </div>
                    <div class="col-md-4">
                      <v-btn class="btn-white" @click="close" text>
                        Cancel
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["AddEquipment"],
  data: () => {
    return {
      errorMessage: "",
      errorFound: 0,
      check: "",
      errorContent: "heeee",
      errorSaving: 0,
      valid: true,
      inputRules: [
        (v) => !!v || "This field is required",
        (v) =>
          /^[A-Za-z0-9]+$/.test(v) || "Required with Aphanumeric Characters",
      ],
      selectRules: [(v) => !!v || "This field is required"],
      isFetching: true,
      number: "",
      chassis_type_id: "",
      trucker_owned: 0,
    };
  },
  computed: {
    ...mapGetters({
      getCreateChassisLoading: "chassis/getCreateChassisLoading",
      get_alert_box_text: "chassis/get_alert_box_text",
      getdetails: "chassis/getdetails",
      getChassisType: "chassis/getChassisType",
    }),
  },
  watch: {
    dialog(c) {
      if (!c) {
        this.$refs.form.resetValidation();
      } else {
        this.errorMessage = "";
      }
    },
  },
  methods: {
    ...mapActions("chassis", ["addNewChassis"]),
    close() {
      this.setDefault();
      this.$emit("closeEquipment");
    },
    ISALPHANUMERIC(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z0-9]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    closeForm() {
      if (this.getCreateChassisLoading) {
        this.setDefault();
        this.$emit("close");
      }
    },

    async save() {
      this.errorFound = 0;
      if (this.$refs.form.validate()) {
        this.trucker_owned = this.trucker_owned == true ? 1 : 0;
        const item = {
          number: this.number,
          chassis_type_id: this.chassis_type_id,
          trucker_owned: this.trucker_owned,
        };
        await this.addNewChassis(item);
        if (this.getdetails.status) {
          this.close();
          this.$emit("syncAction", {
            created: 0,
            msg: this.get_alert_box_text,
          });
        } else {
          this.errorFound = 1;
          this.errorMessage = this.getdetails.msg;
        }
      }
    },
    setDefault() {
      this.$refs.form.resetValidation();
      this.number = "";
      this.chassis_type_id = "";
    },
  },
};
</script>

<style>
.custom-radio .v-label {
  font-size: 12px !important;
  color: #4a4a4a;
  font-weight: 500;
}
.custom-radio .v-icon.v-icon {
  font-size: 20px;
}

.v-text-field--outlined >>> fieldset {
  border-color: #b4cfe0;
  border-width: 1px;
}
.v-select--is-menu-active {
  background: none !important;
  border: 0px !important;
}
</style>
